import { Empty } from "antd";
import { FaMagnifyingGlassChart } from "react-icons/fa6";

const EmptyChartCard = (props: any) => {
  const { style } = props;
  return (
    <Empty
      style={{
        height: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
      image={
        <FaMagnifyingGlassChart style={{ fontSize: 120, color: "#0082ba" }} />
      }
      description="No data available"
    />
  );
};

export default EmptyChartCard;
