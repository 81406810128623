export const mapQuantityAtRiskByAction = (data: any) => {
  const mappedData = data
    ?.filter(
      (risk: any) =>
        risk.risk_status === "Accepted" || risk.risk_status === "Rejected"
    )
    ?.map((obj: any) => {
      return {
        action_type: obj.action_type,
        quantity_at_risk: obj.quantity_at_risk,
      };
    })
    ?.reduce((accumulator: any, item: any) => {
      const { action_type, quantity_at_risk } = item;
      const key = `${action_type}`;

      if (!accumulator[key]) {
        accumulator[key] = { action_type, value: 0 };
      }

      accumulator[key].value += parseFloat(quantity_at_risk) || 0;

      return accumulator;
    }, {});
  return Object.values(mappedData);
};
