export const GetChoicesByKeys = async (
  settings: any,
  predefinedActionServices: any,
  setSettingsChoicesList: React.Dispatch<React.SetStateAction<any>>
) => {
  const keys = settingsToKeysList(settings);
  await predefinedActionServices
    .getSettingsChoices(keys)
    .then((res: any) => {
      setSettingsChoicesList(res);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

const settingsToKeysList = (settings: any): string => {
  const uniqueKeys = new Set<string>();
  if (settings) {
    Object.keys(settings)
      .filter((key) => settings[key].choice)
      .forEach((key) => uniqueKeys.add(`name=${key}`));

    return Array.from(uniqueKeys).join("&");
  } else {
    return "";
  }
};
