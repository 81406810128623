import { Cascader, Form, FormInstance } from "antd";
import { t } from "i18next";

import { IOptionDataNeeded } from "./scope.interface";
import { getTranslation } from "../../../../../../utils/transaltion";

interface ISelectScopeKey {
  options: IOptionDataNeeded[];
  value: string | undefined;
  setValue: (value: string) => void;
  form: FormInstance<any>;
}

export function SelectScopeKey(props: ISelectScopeKey) {
  const { options, value, setValue, form } = props;

  const recursivelyDisableInvalidOptions = (
    options: IOptionDataNeeded[]
  ): any => {
    return options.map((option) => {
      const hasChildren = option.children && option.children.length > 0;
      const disabled = hasChildren ? false : !option.is_taggable; // Check if the option is valid
      return {
        ...option,
        disabled,
        children: option.children
          ? recursivelyDisableInvalidOptions(option.children)
          : null,
      };
    });
  };
  return (
    <Form form={form}>
      <Form.Item
        label={t("SCOPE_TABLE_NAME")}
        name="tableName"
        rules={[
          {
            required: true,
            message: t("errors:REQUIRED", { label: t("FIELD") }),
          },
        ]}
        labelCol={{ span: 4 }}
        labelAlign="left"
      >
        <Cascader
          options={recursivelyDisableInvalidOptions(options)}
          defaultValue={[]}
          placeholder={getTranslation("SELECT_SCOPE_KEYS", "labels")}
          displayRender={(labels, selectedOptions = []) => {
            return labels.join(".");
          }}
          style={{ width: "100%" }}
          value={value?.split(".")}
          onChange={(value: any) => {
            setValue(value?.join("."));
          }}
          fieldNames={{ label: "value", value: "value", children: "children" }}
          showSearch={{
            filter: (inputValue, path) =>
              path.some((option) =>
                option.value
                  ?.toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              ),
          }}
        />
      </Form.Item>
    </Form>
  );
}
