import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: "sk-dfya-test-YPPYc2tAgk4SPH7a50MWT3BlbkFJjegLijIq9lPsOjC3Kjkk",
  dangerouslyAllowBrowser: true,
  organization: "org-Pn7uIjbwGVoeFEhS3N1zN4fv",
  project: "proj_gGoDltw8b6veRpXCPa8jj05n",
});

export const retrieveAssistant = async (
  assistant_: string
): Promise<OpenAI.Beta.Assistants.Assistant> => {
  try {
    // Step 1: Retreive my assistant
    const assistant = await openai.beta.assistants.retrieve(
      assistant_ === "MONITOR_ACTIONS"
        ? "asst_eN5RDNgreBtYbGcG5ZVQsqn1"
        : "asst_ad03bNnedrhDq7K6fBTmGSUb"
    );
    return assistant;
  } catch (error: any) {
    console.error("Error with OpenAI API:", error.message);
    return error.message;
  }
};

export const createThread = async () => {
  try {
    // Step 2: Create a Thread
    const thread: OpenAI.Beta.Threads.Thread =
      await openai.beta.threads.create();

    return thread;
  } catch (error: any) {
    console.error("Error with OpenAI API:", error.message);
    return error.message;
  }
};

export const chatWithGPT = async (
  message_: string,
  assistant: OpenAI.Beta.Assistants.Assistant,
  thread: OpenAI.Beta.Threads.Thread
) => {
  try {
    // Step 3: Add a Message to a Thread
    const myThreadMessage: OpenAI.Beta.Threads.Messages.Message =
      await openai.beta.threads.messages.create(thread.id, {
        role: "user",
        content: message_,
      });

    // Step 4: Run the Assistant
    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: assistant.id,
    });

    // Step 5: Periodically retrieve the Run to check on its status to see if it has moved to completed
    const retrieveRun = async () => {
      let keepRetrievingRun;

      while (run.status === "queued" || run.status === "in_progress") {
        keepRetrievingRun = await openai.beta.threads.runs.retrieve(
          thread.id,
          run.id
        );
        console.log(`Run status: ${keepRetrievingRun.status}`);

        if (keepRetrievingRun.status === "completed") {
          console.log("\n");

          // Step 6: Retrieve the Messages added by the Assistant to the Thread
          const allMessages: any = await openai.beta.threads.messages.list(
            thread.id
          );

          console.log(
            "------------------------------------------------------------ \n"
          );

          // console.log("User: ", myThreadMessage.content[0].text.value);
          console.log(allMessages);
          return allMessages?.data
            ?.at(0)
            .content?.map((content: any) => content.text?.value)
            .join("\n");

          break;
        } else if (
          keepRetrievingRun.status === "queued" ||
          keepRetrievingRun.status === "in_progress"
        ) {
          // pass
        } else {
          console.log(`Run status: ${keepRetrievingRun.status}`);
          break;
        }
      }
    };
    const result = await retrieveRun();
    return result;
  } catch (error: any) {
    console.error("Error with OpenAI API:", error.message);
    return error.message;
  }
};

/* 

sk-dfya-test-YPPYc2tAgk4SPH7a50MWT3BlbkFJjegLijIq9lPsOjC3Kjkk
org-Pn7uIjbwGVoeFEhS3N1zN4fv
proj_gGoDltw8b6veRpXCPa8jj05n
Arnaud Morvan
3:41 PM
Monitoring assistant ID: asst_eN5RDNgreBtYbGcG5ZVQsqn1
Support assistant ID: asst_ad03bNnedrhDq7K6fBTmGSUb

*/
