import { useEffect, useState } from "react";
/* antd */
import { Breadcrumb, Layout, MenuProps } from "antd";
/* Layouts */
import HeaderLayout from "./header/HeaderLayout";
import SiderMenuLayout from "./sider_menu/SiderMenu";
/* Constants */
import { SharedContextProvider } from "../pages/confs/context/sharedContext";
import FooterLayout from "./footer/FooterLayout";
import { Outlet, useLocation } from "react-router-dom";
import { pageBreadCrumbs } from "../../constants/breadcrumbs";
import { SharedContextProviderRunning } from "../pages/runnings/context/SharedContext";
import i18n from "../../parameters/i18n";
import LicenseWarnning from "../pages/licenses/LicenseWarnning";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/store";
import { getTranslation } from "../../utils/transaltion";
import SiderSubMenuLayout from "./sider_menu/SiderSubMenu";

interface MainProps {}
type MenuItem = Required<MenuProps>["items"][number];

function MainLayout(props: MainProps) {
  const copilotList: any[] = useSelector(
    (state: RootState) => state.userCopilotReducer
  );

  const [currentPage, setCurrentPage] = useState<string | null>(null);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [menuTitle, setMenuTitle] = useState<MenuItem[]>([]);
  let typedPageBreadCrumbs = pageBreadCrumbs as { [key: string]: string };
  const [breadCrumbs, setBreadCrumbs] = useState<{ [key: string]: string }>(
    typedPageBreadCrumbs
  );

  /** get current path */
  const location = useLocation();
  const currentPath = location.pathname;

  /* menu selection */
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [siderKey, setSiderKey] = useState(1);
  const handleMenuSelect = ({ key }: { key: string }) => {
    console.log(key);
    setSelectedKeys([key]);
  };
  useEffect(() => {
    setSiderKey((prevKey: any) => prevKey + 1);
  }, [selectedKeys]);

  /* Sub menu selection */
  const [subSelectedKeys, setSubSelectedKeys] = useState<string[]>([]);
  const [subSiderKey, setSubSiderKey] = useState(1);
  // Function to handle menu item selection
  const handleSubMenuSelect = ({ key }: { key: string }) => {
    if (key === "0") {
      return;
    }
    setSubSelectedKeys([key]);
  };
  useEffect(() => {
    setSubSiderKey((prevKey) => prevKey + 1);
  }, []);

  // Update the currentPage based on the current path
  const initCopilotBreadCrumbs = () => {
    let copilotsBreads: { [key: string]: string } = {};
    copilotList.forEach((copilot: any) => {
      const name = getTranslation(`${copilot?.name}`, "data");
      copilotsBreads[`/menu/${copilot?.name?.toLowerCase()}`] = i18n.t(
        "labels:BREAD_CRUMBS",
        {
          first: i18n.t(name),
          second: i18n.t("OPEN_ACTIONS"),
          lng: i18n.language,
        }
      );
      copilotsBreads[
        `/menu/${copilot?.name?.toLowerCase()}/action-monitoring`
      ] = i18n.t("labels:BREAD_CRUMBS", {
        first: i18n.t(name),
        second: i18n.t("ACTION_MONITORING"),
        lng: i18n.language,
      });
      copilotsBreads[`/menu/${copilot?.name?.toLowerCase()}/action-analysis`] =
        i18n.t("labels:BREAD_CRUMBS", {
          first: i18n.t(name),
          second: i18n.t("ACTION_ANALYSIS"),
          lng: i18n.language,
        });
      copilotsBreads[`/menu/${copilot?.name?.toLowerCase()}/action-roi`] =
        i18n.t("labels:BREAD_CRUMBS", {
          first: i18n.t(name),
          second: i18n.t("ACTION_ROI"),
          lng: i18n.language,
        });
      copilotsBreads[`/menu/${copilot?.name?.toLowerCase()}/risks_history`] =
        i18n.t("labels:BREAD_CRUMBS", {
          first: i18n.t(name),
          second: i18n.t("Risk History"),
          lng: i18n.language,
        });
    });
    setBreadCrumbs(copilotsBreads);
  };
  useEffect(() => {
    initCopilotBreadCrumbs();
  }, [copilotList]);
  useEffect(() => {
    setCurrentPage(currentPath);
  }, [currentPath]);
  useEffect(() => {
    document.body.style.direction = i18n.language !== "ar-DZ" ? "ltr" : "rtl";
  }, [i18n.language]);

  return (
    <Layout
      style={{
        overflow: "hidden",
      }}
    >
      <HeaderLayout />

      <Layout
        hasSider
        style={{
          height: "calc(100vh - 70px)",
          marginTop: "70px",
        }}
      >
        <SharedContextProvider>
          <SharedContextProviderRunning>
            <SiderMenuLayout
              setCurrentPage={setCurrentPage}
              setMenuItems={(value: MenuItem[]) => {
                setMenuItems(value);
              }}
              setMenuTitle={setMenuTitle}
              selectedKeys={selectedKeys}
              siderKey={siderKey}
              handleMenuSelect={handleMenuSelect}
            />

            <Layout
              style={{
                background: "transparent",
              }}
            >
              <Layout
                onMouseLeave={() => {
                  setMenuItems([]);
                }}
              >
                {menuItems?.length > 0 && (
                  <SiderSubMenuLayout
                    setCurrentPage={setCurrentPage}
                    menuItems={menuItems}
                    menuTitle={menuTitle}
                    setMenuItems={setMenuItems}
                    siderKey={subSiderKey}
                    selectedKeys={subSelectedKeys}
                    handleMenuSelect={handleSubMenuSelect}
                  />
                )}
              </Layout>

              <Layout.Content
                onClick={() => {
                  setMenuItems([]);
                }}
                style={{
                  minHeight: "calc(100vh - 71px)",
                  margin: 0,
                  background: "transparent",
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    borderTop: "1px solid #e8e8e8",
                  }}
                >
                  <LicenseWarnning />
                  {currentPath !== "/menu" && currentPath !== "/" && (
                    <Breadcrumb
                      style={{
                        margin: "16px 0",
                        marginInlineStart: 20,
                        paddingBlockEnd: "12px",
                      }}
                      items={[
                        {
                          title: {
                            ...breadCrumbs,
                            ...typedPageBreadCrumbs,
                          }[currentPage ?? ""],
                        },
                      ]}
                      separator={<>/</>}
                    ></Breadcrumb>
                  )}
                </div>
                <Layout style={{ margin: 24 }}>
                  <Outlet />
                </Layout>
                <FooterLayout />
              </Layout.Content>
            </Layout>
          </SharedContextProviderRunning>
        </SharedContextProvider>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
