export const Languages = {
  // "aa-DJ": ["Afar (Ddjibouti)", "ISO-8859-1"],
  // "aa-ER": ["Afar (Eritrea)", "UTF-8"],
  // "aa-ET": ["Afar (Ethiopia)", "UTF-8"],
  // "af": ["Afrikaans", "UTF-8"],
  // "af-NA": ["Afrikaans (Namibia)", "UTF-8"],
  // "af-ZA": ["Afrikaans (South Africa)", "ISO-8859-1"],
  // "ak": ["Akan", "UTF-8"],
  // "ak-GH": ["Akan (Ghana)", "UTF-8"],
  // "am": ["Amharic", "UTF-8"],
  // "am-ET": ["Amharic (Ethiopia)", "UTF-8"],
  // "an-ES": ["Aragonese (Spain)", "ISO-8859-15"],
  "ar": ["Arabic", "ISO-8859-6"],
  // "ar-AE": ["Arabic (United Arab Emirates)", "ISO-8859-6"],
  // "ar-BH": ["Arabic (Bahrain)", "ISO-8859-6"],
  // "ar-DZ": ["Arabic (Algeria)", "ISO-8859-6"],
  // "ar-EG": ["Arabic (Egypt)", "ISO-8859-6"],
  // "ar-IN": ["Arabic (India)", "UTF-8"],
  // "ar-IQ": ["Arabic (Iraq)", "ISO-8859-6"],
  // "ar-JO": ["Arabic (Jordan)", "ISO-8859-6"],
  // "ar-KW": ["Arabic (Kuwait)", "ISO-8859-6"],
  // "ar-LB": ["Arabic (Lebanon)", "ISO-8859-6"],
  // "ar-LY": ["Arabic (Libya)", "ISO-8859-6"],
  // "ar-MA": ["Arabic (Morocco)", "ISO-8859-6"],
  // "ar-OM": ["Arabic (Oman)", "ISO-8859-6"],
  // "ar-QA": ["Arabic (Qatar)", "ISO-8859-6"],
  // "ar-SA": ["Arabic (Saudi Arabia)", "ISO-8859-6"],
  // "ar-SD": ["Arabic (Sudan)", "ISO-8859-6"],
  // "ar-SY": ["Arabic (Syria)", "ISO-8859-6"],
  // "ar-TN": ["Arabic (Tunisia)", "ISO-8859-6"],
  // "ar-YE": ["Arabic (Yemen)", "ISO-8859-6"],
  // "as": ["Assamese", "UTF-8"],
  // "as-IN": ["Assamese (India)", "UTF-8"],
  // "asa": ["Asu", "UTF-8"],
  // "asa-TZ": ["Asu (Tanzania)", "UTF-8"],
  // "ast-ES": ["Asturian (Spain)", "ISO-8859-15"],
  // "az": ["Azerbaijani", "UTF-8"],
  // "az-AZ": ["Azerbaijani (Azerbaijan)", "UTF-8"],
  // "az-Cyrl": ["Azerbaijani (Cyrillic)", "UTF-8"],
  // "az-Cyrl-AZ": ["Azerbaijani (Cyrillic, Azerbaijan)", "UTF-8"],
  // "az-Latn": ["Azerbaijani (Latin)", "UTF-8"],
  // "az-Latn-AZ": ["Azerbaijani (Latin, Azerbaijan)", "UTF-8"],
  // "be": ["Belarusian", "UTF-8"],
  // "be-BY": ["Belarusian (Belarus)", "UTF-8"],
  // "bem": ["Bemba", "UTF-8"],
  // "bem-ZM": ["Bemba (Zambia)", "UTF-8"],
  // "ber-DZ": ["UTF-8", "UTF-8"],
  // "ber-MA": ["Berber (Morocco)", "UTF-8"],
  // "bez": ["Bena", "UTF-8"],
  // "bez-TZ": ["Bena (Tanzania)", "UTF-8"],
  // "bg": ["Bulgarian", "CP1251"],
  // "bg-BG": ["Bulgarian (Bulgaria)", "CP1251"],
  // "bho-IN": ["Bhojpuri (India)", "UTF-8"],
  // "bm": ["Bambara", "UTF-8"],
  // "bm-ML": ["Bambara (Mali)", "UTF-8"],
  // "bn": ["Bengali", "UTF-8"],
  // "bn-BD": ["Bengali (Bangladesh)", "UTF-8"],
  // "bn-IN": ["Bengali (India)", "UTF-8"],
  // "bo": ["Tibetan", "UTF-8"],
  // "bo-CN": ["Tibetan (China)", "UTF-8"],
  // "bo-IN": ["Tibetan (India)", "UTF-8"],
  // "br-FR": ["Breton (France)", "ISO-8859-15"],
  // "brx-IN": ["Bodo (India)", "UTF-8"],
  // "bs": ["Bosnian", "ISO-8859-2"],
  // "bs-BA": ["Bosnian (Bosnia and Herzegovina)", "ISO-8859-2"],
  // "byn-ER": ["Bilin (Eritrea)", "UTF-8"],
  // "ca": ["Catalan", "ISO-8859-15"],
  // "ca-AD": ["Catalan (Andorra)", "ISO-8859-15"],
  // "ca-ES": ["Catalan (Spain)", "ISO-8859-15"],
  // "ca-FR": ["Catalan (France)", "ISO-8859-15"],
  // "ca-IT": ["Catalan (Italy)", "ISO-8859-15"],
  // "cgg": ["Chiga", "UTF-8"],
  // "cgg-UG": ["Chiga (Uganda)", "UTF-8"],
  // "chr": ["Cherokee", "UTF-8"],
  // "chr-US": ["Cherokee (United States)", "UTF-8"],
  // "crh-UA": ["Crimean Tatar (Ukraine)", "UTF-8"],
  // "cs": ["Czech", "ISO-8859-2"],
  // "cs-CZ": ["Czech (Czech Republic)", "ISO-8859-2"],
  // "csb-PL": ["Kashubian (Poland)", "UTF-8"],
  // "cv-RU": ["Chuvash (Russia)", "UTF-8"],
  // "cy": ["Welsh", "ISO-8859-14"],
  // "cy-GB": ["Welsh (United Kingdom)", "ISO-8859-14"],
  // "da": ["Danish", "ISO-8859-1"],
  // "da-DK": ["Danish (Denmark)", "ISO-8859-1"],
  // "dav": ["Taita", "UTF-8"],
  // "dav-KE": ["Taita (Kenya)", "UTF-8"],
  // "de": ["German", "ISO-8859-15"],
  // "de-AT": ["German (Austria)", "ISO-8859-15"],
  // "de-BE": ["German (Belgium)", "ISO-8859-15"],
  // "de-CH": ["German (Switzerland)", "ISO-8859-1"],
  // "de-DE": ["German (Germany)", "ISO-8859-15"],
  // "de-LI": ["German (Liechtenstein)", "ISO-8859-15"],
  // "de-LU": ["German (Luxembourg)", "ISO-8859-15"],
  // "dv-MV": ["Dhivehi (Maldives)", "UTF-8"],
  // "dz-BT": ["Dzongkha (Buthan)", "UTF-8"],
  // "ebu": ["Embu", "UTF-8"],
  // "ebu-KE": ["Embu (Kenya)", "UTF-8"],
  // "ee": ["Ewe", "UTF-8"],
  // "ee-GH": ["Ewe (Ghana)", "UTF-8"],
  // "ee-TG": ["Ewe (Togo)", "UTF-8"],
  // "el": ["Greek", "ISO-8859-7"],
  // "el-CY": ["Greek (Cyprus)", "ISO-8859-7"],
  // "el-GR": ["Greek (Greece)", "ISO-8859-7"],
  // "en": ["English", "UTF-8"],
  // "en-AG": ["English (Antigua & Barbuda)", "UTF-8"],
  // "en-AS": ["English (American Samoa)", "UTF-8"],
  // "en-AU": ["English (Australia)", "ISO-8859-1"],
  // "en-BE": ["English (Belgium)", "UTF-8"],
  // "en-BW": ["English (Botswana)", "ISO-8859-1"],
  // "en-BZ": ["English (Belize)", "UTF-8"],
  // "en-CA": ["English (Canada)", "ISO-8859-1"],
  // "en-DK": ["English (Denmark)", "ISO-8859-1"],
  // "en-GB": ["English (United Kingdom)", "ISO-8859-1"],
  // "en-GU": ["English (Guam)", "UTF-8"],
  // "en-HK": ["English (Hong Kong SAR China)", "ISO-8859-1"],
  // "en-IE": ["English (Ireland)", "ISO-8859-15"],
  // "en-IN": ["English (India)", "UTF-8"],
  // "en-JM": ["English (Jamaica)", "UTF-8"],
  // "en-MH": ["English (Marshall Islands)", "UTF-8"],
  // "en-MP": ["English (Northern Mariana Islands)", "UTF-8"],
  // "en-MT": ["English (Malta)", "UTF-8"],
  // "en-MU": ["English (Mauritius)", "UTF-8"],
  // "en-NA": ["English (Namibia)", "UTF-8"],
  // "en-NZ": ["English (New Zealand)", "ISO-8859-1"],
  // "en-PH": ["English (Philippines)", "ISO-8859-1"],
  // "en-PK": ["English (Pakistan)", "UTF-8"],
  // "en-SG": ["English (Singapore)", "ISO-8859-1"],
  // "en-TT": ["English (Trinidad and Tobago)", "UTF-8"],
  // "en-UM": ["English (U.S. Minor Outlying Islands)", "UTF-8"],
  "en-US": ["English (United States)", "ISO-8859-1"],
  // "en-VI": ["English (U.S. Virgin Islands)", "UTF-8"],
  // "en-ZA": ["English (South Africa)", "ISO-8859-1"],
  // "en-ZM": ["English (Zambia)", "UTF-8"],
  // "en-ZW": ["English (Zimbabwe)", "ISO-8859-1"],
  // "eo": ["Esperanto", "UTF-8"],
  // "es": ["Spanish", "ISO-8859-1"],
  // "es-419": ["Spanish (Latin America)", "ISO-8859-1"],
  // "es-AR": ["Spanish (Argentina)", "ISO-8859-1"],
  // "es-BO": ["Spanish (Bolivia)", "ISO-8859-1"],
  // "es-CL": ["Spanish (Chile)", "ISO-8859-1"],
  // "es-CO": ["Spanish (Colombia)", "ISO-8859-1"],
  // "es-CR": ["Spanish (Costa Rica)", "ISO-8859-1"],
  // "es-CU": ["Spanish (Cuba)", "UTF-8"],
  // "es-DO": ["Spanish (Dominican Republic)", "ISO-8859-1"],
  // "es-EC": ["Spanish (Ecuador)", "ISO-8859-1"],
  // "es-ES": ["Spanish (Spain)", "ISO-8859-15"],
  // "es-GQ": ["Spanish (Equatorial Guinea)", "ISO-8859-1"],
  // "es-GT": ["Spanish (Guatemala)", "ISO-8859-1"],
  // "es-HN": ["Spanish (Honduras)", "ISO-8859-1"],
  // "es-MX": ["Spanish (Mexico)", "ISO-8859-1"],
  // "es-NI": ["Spanish (Nicaragua)", "ISO-8859-1"],
  // "es-PA": ["Spanish (Panama)", "ISO-8859-1"],
  // "es-PE": ["Spanish (Peru)", "ISO-8859-1"],
  // "es-PR": ["Spanish (Puerto Rico)", "ISO-8859-1"],
  // "es-PY": ["Spanish (Paraguay)", "ISO-8859-1"],
  // "es-SV": ["Spanish (El Salvador)", "ISO-8859-1"],
  // "es-US": ["Spanish (United States)", "ISO-8859-1"],
  // "es-UY": ["Spanish (Uruguay)", "ISO-8859-1"],
  // "es-VE": ["Spanish (Venezuela)", "ISO-8859-1"],
  // "et": ["Estonian", "ISO-8859-15"],
  // "et-EE": ["Estonian (Estonia)", "ISO-8859-15"],
  // "eu": ["Basque", "ISO-8859-15"],
  // "eu-ES": ["Basque (Spain)", "ISO-8859-15"],
  // "fa": ["Persian", "UTF-8"],
  // "fa-AF": ["Persian (Afghanistan)", "UTF-8"],
  // "fa-IR": ["Persian (Iran)", "UTF-8"],
  // "ff": ["Fulah", "UTF-8"],
  // "ff-SN": ["Fulah (Senegal)", "UTF-8"],
  // "fi": ["Finnish", "ISO-8859-15"],
  // "fi-FI": ["Finnish (Finland)", "ISO-8859-15"],
  // "fil": ["Filipino", "UTF-8"],
  // "fil-PH": ["Filipino (Philippines)", "UTF-8"],
  // "fo": ["Faroese", "ISO-8859-1"],
  // "fo-FO": ["Faroese (Faroe Islands)", "ISO-8859-1"],
  // "fr": ["French", "ISO-8859-15"],
  // "fr-BE": ["French (Belgium)", "ISO-8859-15"],
  // "fr-BF": ["French (Burkina Faso)", "ISO-8859-15"],
  // "fr-BI": ["French (Burundi)", "ISO-8859-15"],
  // "fr-BJ": ["French (Benin)", "ISO-8859-15"],
  // "fr-BL": ["French (Saint Barth\u00e9lemy)", "ISO-8859-15"],
  // "fr-CA": ["French (Canada)", "ISO-8859-15"],
  // "fr-CD": ["French (Congo - Kinshasa)", "ISO-8859-15"],
  // "fr-CF": ["French (Central African Republic)", "ISO-8859-15"],
  // "fr-CG": ["French (Congo - Brazzaville)", "ISO-8859-15"],
  // "fr-CH": ["French (Switzerland)", "ISO-8859-1"],
  // "fr-CI": ["French (C\u00f4te d\u2019Ivoire)", "ISO-8859-15"],
  // "fr-CM": ["French (Cameroon)", "ISO-8859-15"],
  // "fr-DJ": ["French (Djibouti)", "ISO-8859-15"],
  "fr-FR": ["French (France)", "ISO-8859-15"],
  // "fr-GA": ["French (Gabon)", "ISO-8859-15"],
  // "fr-GN": ["French (Guinea)", "ISO-8859-15"],
  // "fr-GP": ["French (Guadeloupe)", "ISO-8859-15"],
  // "fr-GQ": ["French (Equatorial Guinea)", "ISO-8859-15"],
  // "fr-KM": ["French (Comoros)", "ISO-8859-15"],
  // "fr-LU": ["French (Luxembourg)", "ISO-8859-15"],
  // "fr-MC": ["French (Monaco)", "ISO-8859-15"],
  // "fr-MF": ["French (Saint Martin)", "ISO-8859-15"],
  // "fr-MG": ["French (Madagascar)", "ISO-8859-15"],
  // "fr-ML": ["French (Mali)", "ISO-8859-15"],
  // "fr-MQ": ["French (Martinique)", "ISO-8859-15"],
  // "fr-NE": ["French (Niger)", "ISO-8859-15"],
  // "fr-RE": ["French (R\u00e9union)", "ISO-8859-15"],
  // "fr-RW": ["French (Rwanda)", "ISO-8859-15"],
  // "fr-SN": ["French (Senegal)", "ISO-8859-15"],
  // "fr-TD": ["French (Chad)", "ISO-8859-15"],
  // "fr-TG": ["French (Togo)", "ISO-8859-15"],
  // "fur-IT": ["Friulian (Italy)", "UTF-8"],
  // "fy-DE": ["Western Frisian (Germany)", "UTF-8"],
  // "fy-NL": ["Western Frisian (Netherlands)", "UTF-8"],
  // "ga": ["Irish", "ISO-8859-15"],
  // "ga-IE": ["Irish (Ireland)", "ISO-8859-15"],
  // "gd-GB": ["Scottish Gaelic (United Kingdom)", "ISO-8859-15"],
  // "gez-ER": ["Geez (Eritrea)", "UTF-8"],
  // "gez-ET": ["Geez (Ethiopia)", "UTF-8"],
  // "gl": ["Galician", "ISO-8859-15"],
  // "gl-ES": ["Galician (Spain)", "ISO-8859-15"],
  // "gsw": ["Swiss German", "UTF-8"],
  // "gsw-CH": ["Swiss German (Switzerland)", "UTF-8"],
  // "gu": ["Gujarati", "UTF-8"],
  // "gu-IN": ["Gujarati (India)", "UTF-8"],
  // "guz": ["Gusii", "UTF-8"],
  // "guz-KE": ["Gusii (Kenya)", "UTF-8"],
  // "gv": ["Manx", "ISO-8859-1"],
  // "gv-GB": ["Manx (United Kingdom)", "ISO-8859-1"],
  // "ha": ["Hausa", "UTF-8"],
  // "ha-Latn": ["Hausa (Latin)", "UTF-8"],
  // "ha-Latn-GH": ["Hausa (Latin, Ghana)", "UTF-8"],
  // "ha-Latn-NE": ["Hausa (Latin, Niger)", "UTF-8"],
  // "ha-Latn-NG": ["Hausa (Latin, Nigeria)", "UTF-8"],
  // "ha-NG": ["Hausa (Nigeria)", "UTF-8"],
  // "haw": ["Hawaiian", "UTF-8"],
  // "haw-US": ["Hawaiian (United States)", "UTF-8"],
  // "he": ["Hebrew", "ISO-8859-8"],
  // "he-IL": ["Hebrew (Israel)", "ISO-8859-8"],
  // "hi": ["Hindi", "UTF-8"],
  // "hi-IN": ["Hindi (India)", "UTF-8"],
  // "hne-IN": ["Chhattisgarhi (India)", "UTF-8"],
  // "hr": ["Croatian", "ISO-8859-2"],
  // "hr-HR": ["Croatian (Croatia)", "ISO-8859-2"],
  // "hsb-DE": ["Upper Sorbian (Germany)", "UTF-8"],
  // "ht-HT": ["Haitian (Haiti)", "UTF-8"],
  // "hu": ["Hungarian", "ISO-8859-2"],
  // "hu-HU": ["Hungarian (Hungary)", "ISO-8859-2"],
  // "hy": ["Armenian", "ARMSCII-8"],
  // "hy-AM": ["Armenian (Armenia)", "ARMSCII-8"],
  // "id": ["Indonesian", "ISO-8859-1"],
  // "id-ID": ["Indonesian (Indonesia)", "ISO-8859-1"],
  // "ig": ["Igbo", "UTF-8"],
  // "ig-NG": ["Igbo (Nigeria)", "UTF-8"],
  // "ii": ["Sichuan Yi", "UTF-8"],
  // "ii-CN": ["Sichuan Yi (China)", "UTF-8"],
  // "ik-CA": ["Inupiaq (Canada)", "UTF-8"],
  // "is": ["Icelandic", "ISO-8859-1"],
  // "is-IS": ["Icelandic (Iceland)", "ISO-8859-1"],
  // "it": ["Italian", "ISO-8859-1"],
  // "it-CH": ["Italian (Switzerland)", "ISO-8859-1"],
  // "it-IT": ["Italian (Italy)", "ISO-8859-15"],
  // "iu-CA": ["Inuktitut (Canada)", "UTF-8"],
  // "iw-IL": ["Hebrew (Israel)", "ISO-8859-8"],
  // "ja": ["Japanese", "UTF-8"],
  // "ja-JP": ["Japanese (Japan)", "UTF-8"],
  // "jmc": ["Machame", "UTF-8"],
  // "jmc-TZ": ["Machame (Tanzania)", "UTF-8"],
  // "ka": ["Georgian", "GEORGIAN-PS"],
  // "ka-GE": ["Georgian (Georgia)", "GEORGIAN-PS"],
  // "kab": ["Kabyle", "UTF-8"],
  // "kab-DZ": ["Kabyle (Algeria)", "UTF-8"],
  // "kam": ["Kamba", "UTF-8"],
  // "kam-KE": ["Kamba (Kenya)", "UTF-8"],
  // "kde": ["Makonde", "UTF-8"],
  // "kde-TZ": ["Makonde (Tanzania)", "UTF-8"],
  // "kea": ["Kabuverdianu", "UTF-8"],
  // "kea-CV": ["Kabuverdianu (Cape Verde)", "UTF-8"],
  // "khq": ["Koyra Chiini", "UTF-8"],
  // "khq-ML": ["Koyra Chiini (Mali)", "UTF-8"],
  // "ki": ["Kikuyu", "UTF-8"],
  // "ki-KE": ["Kikuyu (Kenya)", "UTF-8"],
  // "kk": ["Kazakh", "PT154"],
  // "kk-Cyrl": ["Kazakh (Cyrillic)", "PT154"],
  // "kk-Cyrl-KZ": ["Kazakh (Cyrillic, Kazakhstan)", "PT154"],
  // "kk-KZ": ["Kazakh (Kazakhstan)", "PT154"],
  // "kl": ["Kalaallisut", "ISO-8859-1"],
  // "kl-GL": ["Kalaallisut (Greenland)", "ISO-8859-1"],
  // "kln": ["Kalenjin", "UTF-8"],
  // "kln-KE": ["Kalenjin (Kenya)", "UTF-8"],
  // "km": ["Khmer", "UTF-8"],
  // "km-KH": ["Khmer (Cambodia)", "UTF-8"],
  // "kn": ["Kannada", "UTF-8"],
  // "kn-IN": ["Kannada (India)", "UTF-8"],
  // "ko": ["Korean", "UTF-8"],
  // "ko-KR": ["Korean (South Korea)", "UTF-8"],
  // "kok": ["Konkani", "UTF-8"],
  // "kok-IN": ["Konkani (India)", "UTF-8"],
  // "ks-IN": ["Kashmiri (India)", "UTF-8"],
  // "ku-TR": ["Kurdish (Turkey)", "ISO-8859-9"],
  // "kw": ["Cornish", "ISO-8859-1"],
  // "kw-GB": ["Cornish (United Kingdom)", "ISO-8859-1"],
  // "ky-KG": ["Kirghiz (Kyrgyzstan)", "UTF-8"],
  // "lag": ["Langi", "UTF-8"],
  // "lag-TZ": ["Langi (Tanzania)", "UTF-8"],
  // "lb-LU": ["Luxembourgish (Luxembourg)", "UTF-8"],
  // "lg": ["Ganda", "ISO-8859-10"],
  // "lg-UG": ["Ganda (Uganda)", "ISO-8859-10"],
  // "li-BE": ["Limburgan (Belgium)", "UTF-8"],
  // "li-NL": ["Limburgan (Netherlands)", "UTF-8"],
  // "lij-IT": ["Ligurian (Italy)", "UTF-8"],
  // "lo-LA": ["Lao (Laos)", "UTF-8"],
  // "lt": ["Lithuanian", "ISO-8859-13"],
  // "lt-LT": ["Lithuanian (Lithuania)", "ISO-8859-13"],
  // "luo": ["Luo", "UTF-8"],
  // "luo-KE": ["Luo (Kenya)", "UTF-8"],
  // "luy": ["Luyia", "UTF-8"],
  // "luy-KE": ["Luyia (Kenya)", "UTF-8"],
  // "lv": ["Latvian", "ISO-8859-13"],
  // "lv-LV": ["Latvian (Latvia)", "ISO-8859-13"],
  // "mag-IN": ["Magahi (India)", "UTF-8"],
  // "mai-IN": ["Maithili (India)", "UTF-8"],
  // "mas": ["Masai", "UTF-8"],
  // "mas-KE": ["Masai (Kenya)", "UTF-8"],
  // "mas-TZ": ["Masai (Tanzania)", "UTF-8"],
  // "mer": ["Meru", "UTF-8"],
  // "mer-KE": ["Meru (Kenya)", "UTF-8"],
  // "mfe": ["Morisyen", "UTF-8"],
  // "mfe-MU": ["Morisyen (Mauritius)", "UTF-8"],
  // "mg": ["Malagasy", "ISO-8859-15"],
  // "mg-MG": ["Malagasy (Madagascar)", "ISO-8859-15"],
  // "mhr-RU": ["Eastern Mari (Russia)", "UTF-8"],
  // "mi-NZ": ["Maori (New Zealand)", "ISO-8859-13"],
  // "mk": ["Macedonian", "ISO-8859-5"],
  // "mk-MK": ["Macedonian (Macedonia)", "ISO-8859-5"],
  // "ml": ["Malayalam", "UTF-8"],
  // "ml-IN": ["Malayalam (India)", "UTF-8"],
  // "mn-MN": ["Mongolian (Mongolia)", "UTF-8"],
  // "mr": ["Marathi", "UTF-8"],
  // "mr-IN": ["Marathi (India)", "UTF-8"],
  // "ms": ["Malay", "ISO-8859-1"],
  // "ms-BN": ["Malay (Brunei)", "ISO-8859-1"],
  // "ms-MY": ["Malay (Malaysia)", "ISO-8859-1"],
  // "mt": ["Maltese", "ISO-8859-3"],
  // "mt-MT": ["Maltese (Malta)", "ISO-8859-3"],
  // "my": ["Burmese", "UTF-8"],
  // "my-MM": ["Burmese (Myanmar [Burma])", "UTF-8"],
  // "nan-TW": ["Min Nan Chinese (Taiwan)", "UTF-8"],
  // "naq": ["Nama", "UTF-8"],
  // "naq-NA": ["Nama (Namibia)", "UTF-8"],
  // "nb": ["Norwegian Bokm\u00e5l", "ISO-8859-1"],
  // "nb-NO": ["Norwegian Bokm\u00e5l (Norway)", "ISO-8859-1"],
  // "nd": ["North Ndebele", "UTF-8"],
  // "nd-ZW": ["North Ndebele (Zimbabwe)", "UTF-8"],
  // "nds-DE": ["Low German (German)", "UTF-8"],
  // "nds-NL": ["Low German (Netherlands)", "UTF-8"],
  // "ne": ["Nepali", "UTF-8"],
  // "ne-IN": ["Nepali (India)", "UTF-8"],
  // "ne-NP": ["Nepali (Nepal)", "UTF-8"],
  // "nl": ["Dutch", "UTF-8"],
  // "nl-AW": ["Dutch (Aruba)", "UTF-8"],
  // "nl-BE": ["Dutch (Belgium)", "ISO-8859-15"],
  // "nl-NL": ["Dutch (Netherlands)", "ISO-8859-15"],
  // "nn": ["Norwegian Nynorsk", "ISO-8859-1"],
  // "nn-NO": ["Norwegian Nynorsk (Norway)", "ISO-8859-1"],
  // "nr-ZA": ["South Ndebele (South Africa)", "UTF-8"],
  // "nso-ZA": ["Pedi (South Africa)", "UTF-8"],
  // "nyn": ["Nyankole", "UTF-8"],
  // "nyn-UG": ["Nyankole (Uganda)", "UTF-8"],
  // "oc-FR": ["Occitan (France)", "ISO-8859-1"],
  // "om": ["Oromo", "UTF-8"],
  // "om-ET": ["Oromo (Ethiopia)", "UTF-8"],
  // "om-KE": ["Oromo (Kenya)", "ISO-8859-1"],
  // "or": ["Oriya", "UTF-8"],
  // "or-IN": ["Oriya (India)", "UTF-8"],
  // "os-RU": ["Ossetian (Russia)", "UTF-8"],
  // "pa": ["Punjabi", "UTF-8"],
  // "pa-Arab": ["Punjabi (Arabic)", "UTF-8"],
  // "pa-Arab-PK": ["Punjabi (Arabic, Pakistan)", "UTF-8"],
  // "pa-Guru": ["Punjabi (Gurmukhi)", "UTF-8"],
  // "pa-Guru-IN": ["Punjabi (Gurmukhi, India)", "UTF-8"],
  // "pa-IN": ["Panjabi (India)", "UTF-8"],
  // "pa-PK": ["Panjabi (Pakistan)", "UTF-8"],
  // "pap-AN": ["Papiamento (Netherlands)", "UTF-8"],
  // "pl": ["Polish", "ISO-8859-2"],
  // "pl-PL": ["Polish (Poland)", "ISO-8859-2"],
  // "ps": ["Pashto", "UTF-8"],
  // "ps-AF": ["Pashto (Afghanistan)", "UTF-8"],
  // "pt": ["Portuguese", "ISO-8859-1"],
  // "pt-BR": ["Portuguese (Brazil)", "ISO-8859-1"],
  // "pt-GW": ["Portuguese (Guinea-Bissau)", "ISO-8859-15"],
  // "pt-MZ": ["Portuguese (Mozambique)", "ISO-8859-15"],
  // "pt-PT": ["Portuguese (Portugal)", "ISO-8859-15"],
  // "rm": ["Romansh", "UTF-8"],
  // "rm-CH": ["Romansh (Switzerland)", "UTF-8"],
  // "ro": ["Romanian", "ISO-8859-2"],
  // "ro-MD": ["Romanian (Moldova)", "ISO-8859-2"],
  // "ro-RO": ["Romanian (Romania)", "ISO-8859-2"],
  // "rof": ["Rombo", "UTF-8"],
  // "rof-TZ": ["Rombo (Tanzania)", "UTF-8"],
  // "ru": ["Russian", "ISO-8859-5"],
  // "ru-MD": ["Russian (Moldova)", "ISO-8859-5"],
  // "ru-RU": ["Russian (Russia)", "ISO-8859-5"],
  // "ru-UA": ["Russian (Ukraine)", "KOI8-U"],
  // "rw": ["Kinyarwanda", "UTF-8"],
  // "rw-RW": ["Kinyarwanda (Rwanda)", "UTF-8"],
  // "rwk": ["Rwa", "UTF-8"],
  // "rwk-TZ": ["Rwa (Tanzania)", "UTF-8"],
  // "sa-IN": ["Sanskrit (India)", "UTF-8"],
  // "saq": ["Samburu", "UTF-8"],
  // "saq-KE": ["Samburu (Kenya)", "UTF-8"],
  // "sc-IT": ["Sardinian (Italy)", "UTF-8"],
  // "sd-IN": ["Sindhi (India)", "UTF-8"],
  // "se-NO": ["Northern Sami (Norway)", "UTF-8"],
  // "seh": ["Sena", "UTF-8"],
  // "seh-MZ": ["Sena (Mozambique)", "UTF-8"],
  // "ses": ["Koyraboro Senni", "UTF-8"],
  // "ses-ML": ["Koyraboro Senni (Mali)", "UTF-8"],
  // "sg": ["Sango", "UTF-8"],
  // "sg-CF": ["Sango (Central African Republic)", "UTF-8"],
  // "shi": ["Tachelhit", "UTF-8"],
  // "shi-Latn": ["Tachelhit (Latin)", "UTF-8"],
  // "shi-Latn-MA": ["Tachelhit (Latin, Morocco)", "UTF-8"],
  // "shi-Tfng": ["Tachelhit (Tifinagh)", "UTF-8"],
  // "shi-Tfng-MA": ["Tachelhit (Tifinagh, Morocco)", "UTF-8"],
  // "shs-CA": ["Shuswap (Canada)", "UTF-8"],
  // "si": ["Sinhala", "UTF-8"],
  // "si-LK": ["Sinhala (Sri Lanka)", "UTF-8"],
  // "sid-ET": ["Sidamo (Ethiopia)", "UTF-8"],
  // "sk": ["Slovak", "ISO-8859-2"],
  // "sk-SK": ["Slovak (Slovakia)", "ISO-8859-2"],
  // "sl": ["Slovenian", "ISO-8859-2"],
  // "sl-SI": ["Slovenian (Slovenia)", "ISO-8859-2"],
  // "sn": ["Shona", "UTF-8"],
  // "sn-ZW": ["Shona (Zimbabwe)", "UTF-8"],
  // "so": ["Somali", "ISO-8859-1"],
  // "so-DJ": ["Somali (Djibouti)", "ISO-8859-1"],
  // "so-ET": ["Somali (Ethiopia)", "UTF-8"],
  // "so-KE": ["Somali (Kenya)", "ISO-8859-1"],
  // "so-SO": ["Somali (Somalia)", "ISO-8859-1"],
  // "sq": ["Albanian", "ISO-8859-1"],
  // "sq-AL": ["Albanian (Albania)", "ISO-8859-1"],
  // "sq-MK": ["Albanian (Macedonia)", "UTF-8"],
  // "sr": ["Serbian", "UTF-8"],
  // "sr-Cyrl": ["Serbian (Cyrillic)", "UTF-8"],
  // "sr-Cyrl-BA": ["Serbian (Cyrillic, Bosnia and Herzegovina)", "UTF-8"],
  // "sr-Cyrl-ME": ["Serbian (Cyrillic, Montenegro)", "UTF-8"],
  // "sr-Cyrl-RS": ["Serbian (Cyrillic, Serbia)", "UTF-8"],
  // "sr-Latn": ["Serbian (Latin)", "UTF-8"],
  // "sr-Latn-BA": ["Serbian (Latin, Bosnia and Herzegovina)", "UTF-8"],
  // "sr-Latn-ME": ["Serbian (Latin, Montenegro)", "UTF-8"],
  // "sr-Latn-RS": ["Serbian (Latin, Serbia)", "UTF-8"],
  // "sr-ME": ["Serbian (Montenegro)", "UTF-8"],
  // "sr-RS": ["Serbian (Serbia)", "UTF-8"],
  // "ss-ZA": ["Swati (South Africa)", "UTF-8"],
  // "st-ZA": ["Southern Sotho (South Africa)", "ISO-8859-1"],
  // "sv": ["Swedish", "ISO-8859-15"],
  // "sv-FI": ["Swedish (Finland)", "ISO-8859-15"],
  // "sv-SE": ["Swedish (Sweden)", "ISO-8859-1"],
  // "sw": ["Swahili", "UTF-8"],
  // "sw-KE": ["Swahili (Kenya)", "UTF-8"],
  // "sw-TZ": ["Swahili (Tanzania)", "UTF-8"],
  // "ta": ["Tamil", "UTF-8"],
  // "ta-IN": ["Tamil (India)", "UTF-8"],
  // "ta-LK": ["Tamil (Sri Lanka)", "UTF-8"],
  // "te": ["Telugu", "UTF-8"],
  // "te-IN": ["Telugu (India)", "UTF-8"],
  // "teo": ["Teso", "UTF-8"],
  // "teo-KE": ["Teso (Kenya)", "UTF-8"],
  // "teo-UG": ["Teso (Uganda)", "UTF-8"],
  // "tg-TJ": ["Tajik (Tajikistan)", "KOI8-T"],
  // "th": ["Thai", "TIS-620"],
  // "th-TH": ["Thai (Thailand)", "TIS-620"],
  // "ti": ["Tigrinya", "UTF-8"],
  // "ti-ER": ["Tigrinya (Eritrea)", "UTF-8"],
  // "ti-ET": ["Tigrinya (Ethiopia)", "UTF-8"],
  // "tig-ER": ["Tigre (Eritrea)", "UTF-8"],
  // "tk-TM": ["Turkmen (Turkmenistan)", "UTF-8"],
  // "tl-PH": ["Tagalog (Philippines)", "ISO-8859-1"],
  // "tn-ZA": ["Tswana (South Africa)", "UTF-8"],
  // "to": ["Tonga", "UTF-8"],
  // "to-TO": ["Tonga (Tonga)", "UTF-8"],
  // "tr": ["Turkish", "ISO-8859-9"],
  // "tr-CY": ["Turkish (Cyprus)", "ISO-8859-9"],
  // "tr-TR": ["Turkish (Turkey)", "ISO-8859-9"],
  // "ts-ZA": ["Tsonga (South Africa)", "UTF-8"],
  // "tt-RU": ["Tatar (Russia)", "UTF-8"],
  // "tzm": ["Central Morocco Tamazight", "UTF-8"],
  // "tzm-Latn": ["Central Morocco Tamazight (Latin)", "UTF-8"],
  // "tzm-Latn-MA": ["Central Morocco Tamazight (Latin, Morocco)", "UTF-8"],
  // "ug-CN": ["Uighur (China)", "UTF-8"],
  // "uk": ["Ukrainian", "KOI8-U"],
  // "uk-UA": ["Ukrainian (Ukraine)", "KOI8-U"],
  // "unm-US": ["Unami (America)", "UTF-8"],
  // "ur": ["Urdu", "UTF-8"],
  // "ur-IN": ["Urdu (India)", "UTF-8"],
  // "ur-PK": ["Urdu (Pakistan)", "UTF-8"],
  // "uz": ["Uzbek", "UTF-8"],
  // "uz-Arab": ["Uzbek (Arabic)", "UTF-8"],
  // "uz-Arab-AF": ["Uzbek (Arabic, Afghanistan)", "UTF-8"],
  // "uz-Cyrl": ["Uzbek (Cyrillic)", "UTF-8"],
  // "uz-Cyrl-UZ": ["Uzbek (Cyrillic, Uzbekistan)", "UTF-8"],
  // "uz-Latn": ["Uzbek (Latin)", "UTF-8"],
  // "uz-Latn-UZ": ["Uzbek (Latin, Uzbekistan)", "UTF-8"],
  // "uz-UZ": ["Uzbek (Uzbekistan)", "UTF-8"],
  // "ve-ZA": ["Venda (South Africa)", "UTF-8"],
  // "vi": ["Vietnamese", "UTF-8"],
  // "vi-VN": ["Vietnamese (Vietnam)", "UTF-8"],
  // "vun": ["Vunjo", "UTF-8"],
  // "vun-TZ": ["Vunjo (Tanzania)", "UTF-8"],
  // "wa-BE": ["Walloon (Belgium)", "UTF-8"],
  // "wae-CH": ["Walser (Switzerland)", "UTF-8"],
  // "wal-ET": ["Wolaytta (Ethiopia)", "UTF-8"],
  // "wo-SN": ["Wolof (Senegal)", "UTF-8"],
  // "xh-ZA": ["Xhosa South Africa)", "ISO-8859-1"],
  // "xog": ["Soga", "UTF-8"],
  // "xog-UG": ["Soga (Uganda)", "UTF-8"],
  // "yi-US": ["Yiddish (America)", "CP1255"],
  // "yo": ["Yoruba", "UTF-8"],
  // "yo-NG": ["Yoruba (Nigeria)", "UTF-8"],
  // "yue-HK": ["Yue Chinese (China)", "UTF-8"],
  // "zh": ["Chinese", "GB2312"],
  // "zh-CN": ["Chinese (China)", "GB2312"],
  // "zh-HK": ["Chinese (Hong Kong)", "BIG5-HKSCS"],
  // "zh-Hans": ["Chinese (Simplified Han)", "GB2312"],
  // "zh-Hans-CN": ["Chinese (Simplified Han, China)", "GB2312"],
  // "zh-Hans-HK": ["Chinese (Simplified Han, Hong Kong SAR China)", "BIG5-HKSCS"],
  // "zh-Hans-MO": ["Chinese (Simplified Han, Macau SAR China)", "GB2312"],
  // "zh-Hans-SG": ["Chinese (Simplified Han, Singapore)", "GB2312"],
  // "zh-Hant": ["Chinese (Traditional Han)", "GB2312"],
  // "zh-Hant-HK": [
  //   "Chinese (Traditional Han, Hong Kong SAR China)",
  //   "BIG5-HKSCS",
  // ],
  // "zh-Hant-MO": ["Chinese (Traditional Han, Macau SAR China)", "GB2312"],
  // "zh-Hant-TW": ["Chinese (Traditional Han, Taiwan)", "GB2312"],
  // "zh-SG": ["Chinese (Singapore)", "GB2312"],
  // "zh-TW": ["Chinese (Taiwan)", "BIG5"],
  // "zu": ["Zulu", "ISO-8859-1"],
  // "zu-ZA": ["Zulu (South Africa)", "ISO-8859-1"],
};
