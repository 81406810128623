import { FilterRules, FilterValue } from "../interfaces/FilterRules";

export function getFilterRule(rules: FilterRules | undefined): any {
  if (!rules) {
    return {};
  }
  const filterRules: {
    operator: string;
    value: FilterValue;
    field?: string;
  }[] = [];
  Object.entries(rules)?.forEach(([key, rule]) => {
    if (typeof rule === "object" && "operator" in rule) {
      if (rule.operator === "range") {
        const value = rule.value;
        filterRules.push({
          operator: ">=",
          value: value?.at(0),
          field: key,
        });
        filterRules.push({
          operator: "<=",
          value: value?.at(1),
          field: key,
        });
      } else if (rule.operator === "OR") {
        const values: Array<any> = rule.value;
        filterRules.push({
          operator: "OR",
          value: values?.map((value) => {
            return {
              operator: "=",
              value: value,
              field: key,
            };
          }),
        });
      } else {
        filterRules.push({
          operator: rule.operator || "=",
          value: rule.value,
          field: key,
        });
      }
    } else {
      filterRules.push({
        operator: "=",
        value: rule,
        field: key,
      });
    }
  });

  return filterRules?.filter((rule) => rule.value).length > 0 // Check if there are any valid rules
    ? {
        rule: {
          operator: "and",
          value: filterRules?.filter((rule) => rule.value),
        },
      }
    : {};
}
