import LineChart from "@ant-design/plots/es/components/line";
import { CATEGORY_VIEWS } from "../../../../constants/categoryviews";

export const procurement_Configs = [
  {
    name: "FULL_TRUCK_LOAD",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: [
                "ACTION_DUE_DATE",
                "PLANNED_SHIP_DATE",
                "NUMBER_OF_TRUCKS",
                "RECEIVING_LOCATION_CODE",
                "SHIPPING_LOCATION_CODE",
                "TOTAL_FREIGHT_CO2_EMISSION",
                "TOTAL_FREIGHT_COST",
                "TOTAL_LOADED_PALLETS",
              ],
              data: {
                ACTION_DETAILS: "$.DATA.ACTION_DETAILS",
                IMPACT: "$.DATA.IMPACT",
              },
            },
          ],
          dynamic_table: [
            {
              title: "Number of trucks per load type",
              data: {
                NUMBER_OF_TRUCKS_PER_LOAD_TYPE: {
                  path: "$.DATA.ACTION_DETAILS.NUMBER_OF_TRUCKS_PER_LOAD_TYPE",
                  columns: {
                    TRUCK_NUMBER: "$.TRUCK_NUMBER",
                    LOAD_TYPE: "$.LOAD_TYPE",
                  },
                },
              },

              type: CATEGORY_VIEWS.OTHER,
            },
            {
              title: "Number of trucks per truck type",
              data: {
                NUMBER_OF_TRUCKS_PER_TRUCK_TYPE: {
                  path: "$.DATA.ACTION_DETAILS.NUMBER_OF_TRUCKS_PER_TRUCK_TYPE",
                  columns: {
                    TRUCK_NUMBER: "$.TRUCK_NUMBER",
                    TRUCK_TYPE: "$.TRUCK_TYPE",
                  },
                },
              },

              type: CATEGORY_VIEWS.OTHER,
            },
            {
              title: "Expirable inventory list",
              data: {
                EXPIRABLE_INVENTORY_LIST: {
                  path: "$.DATA.ACTION_DETAILS.EXPIRABLE_INVENTORY_LIST",
                  columns: {
                    BATCH_CODE: "$.BATCH_CODE",
                    ITEM_CODE: "$.ITEM_CODE",
                    LOCATION_CODE: "$.LOCATION_CODE",
                    EXPIRATION_DATE: "$.EXPIRATION_DATE",
                    QUANTITY_ON_HAND: "$.QUANTITY_ON_HAND",
                  },
                },
              },

              type: CATEGORY_VIEWS.OTHER,
            },
            {
              title: "Transfer list fo all locations",
              data: {
                TRANSFER_LIST_FOR_ALL_LOCATIONS: {
                  path: "$.DATA.ACTION_DETAILS.TRANSFER_LIST_FOR_ALL_LOCATIONS",
                  columns: {
                    EXPIRATION_DATE: "$.EXPIRATION_DATE",
                    FREIGHT_CO2_EMISSION: "$.FREIGHT_CO2_EMISSION",
                    FREIGHT_COST: "$.FREIGHT_COST",
                    ITEM_CODE: "$.ITEM_CODE",
                    ITEM_TYPE: "$.ITEM_TYPE",
                    MODE_OF_TRANSPORT: "$.MODE_OF_TRANSPORT",
                    NEAR_EXPIRY_QUANTITY: "$.NEAR_EXPIRY_QUANTITY",
                    NEW_INVENTORY_QUANTITY_AT_SHIPPING_LOCATION:
                      "$.NEW_INVENTORY_QUANTITY_AT_SHIPPING_LOCATION",
                    REBALANCING_PRIORITIZATION_RANK:
                      "$.REBALANCING_PRIORITIZATION_RANK",
                    RECEIVING_LOCATION_CODE: "$.RECEIVING_LOCATION_CODE",
                    SHIPPING_DATE: "$.SHIPPING_DATE",
                    SHIPPING_LOCATION_CODE: "$.SHIPPING_LOCATION_CODE",
                    TRANSFER_QUANTITY_IN_PALLETS:
                      "$.TRANSFER_QUANTITY_IN_PALLETS",
                    TRANSFER_QUANTITY_IN_UNITS: "$.TRANSFER_QUANTITY_IN_UNITS",
                    TRANSFER_VALUE: "$.TRANSFER_VALUE",
                  },
                },
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: [
                "ACTION_DUE_DATE",
                "NUMBER_OF_TRUCKS",
                "TOTAL_FREIGHT_CO2_EMISSION",
                "TOTAL_FREIGHT_COST",
                "TOTAL_LOADED_PALLETS",
              ],
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
          dynamic_table: [
            {
              title: "Truck load ",
              data: {
                TRUCK_PRODUCT_LOAD_LIST: {
                  path: "$.DATA.TRUCK_PRODUCT_LOAD_LIST",
                  columns: {
                    TRUCK_ID: "$.TRUCK_ID",
                    LOAD_TYPE: "$.LOAD_TYPE",
                    TRUCK_TYPE: "$.TRUCK_TYPE",
                    TRUCK_FREIGHT_COST: "$.TRUCK_FREIGHT_COST",
                    MAXIMUM_TRUCK_LOAD_CAPACITY:
                      "$.MAXIMUM_TRUCK_LOAD_CAPACITY",
                    MINIMUM_TRUCK_LOAD_CAPACITY:
                      "$.MINIMUM_TRUCK_LOAD_CAPACITY",

                    TRUCK_LOADED_PALLET_QUANTITY:
                      "$.TRUCK_LOADED_PALLET_QUANTITY",
                    TRUCK_REMAINING_PALLET_CAPACITY:
                      "$.TRUCK_REMAINING_PALLET_CAPACITY",
                  },
                },
              },

              type: CATEGORY_VIEWS.OTHER,
            },
            {
              title: "Product load",
              data: {
                TRUCK_PRODUCT_LOAD_LIST: {
                  path: "$.DATA.TRUCK_PRODUCT_LOAD_LIST",
                  columns: {
                    TRUCK_ID: "$.TRUCK_ID",
                    ITEM_CODE: "$.ITEM_CODE",
                    PRODUCT_FREIGHT_COST: "$.PRODUCT_FREIGHT_COST",

                    PRODUCT_LOADED_PALLET_QUANTITY:
                      "$.PRODUCT_LOADED_PALLET_QUANTITY",
                  },
                },
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
          flow_chart: [
            {
              title: "Transfer order",
              type: "ARRAY",
              data: { TRANSFER_ORDER: "$.DATA.TRANSFER_ORDER" },

              startNodes: [
                {
                  path: {
                    SHIPPING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "SHIPPING_DATE",
              ],
              endNodes: [
                {
                  path: {
                    RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "DELIVERY_DATE",
                // "SHIPPING_DATE",
              ],

              details: ["TRUCK_ID", "ITEM_CODE"],
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          dynamic_table: [
            {
              title: "Transfer product list",
              data: {
                TRANSFER_PRODUCTS_LIST: {
                  path: "$.DATA.TRANSFER_PRODUCTS_LIST",
                  columns: {
                    EXPIRATION_DATE: "$.EXPIRATION_DATE",
                    FREIGHT_CO2_EMISSION: "$.FREIGHT_CO2_EMISSION",
                    FREIGHT_COST: "$.FREIGHT_COST",
                    ITEM_CODE: "$.ITEM_CODE",
                    ITEM_TYPE: "$.ITEM_TYPE",
                    MODE_OF_TRANSPORT: "$.MODE_OF_TRANSPORT",
                    NEAR_EXPIRY_QUANTITY: "$.NEAR_EXPIRY_QUANTITY",
                    NEW_INVENTORY_QUANTITY_AT_SHIPPING_LOCATION:
                      "$.NEW_INVENTORY_QUANTITY_AT_SHIPPING_LOCATION",
                    REBALANCING_PRIORITIZATION_RANK:
                      "$.REBALANCING_PRIORITIZATION_RANK",
                    RECEIVING_LOCATION_CODE: "$.RECEIVING_LOCATION_CODE",
                    SHIPPING_DATE: "$.SHIPPING_DATE",
                    SHIPPING_LOCATION_CODE: "$.SHIPPING_LOCATION_CODE",
                    TRANSFER_QUANTITY_IN_PALLETS:
                      "$.TRANSFER_QUANTITY_IN_PALLETS",
                    TRANSFER_QUANTITY_IN_UNITS: "$.TRANSFER_QUANTITY_IN_UNITS",
                    TRANSFER_VALUE: "$.TRANSFER_VALUE",
                  },
                },
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
          dualAxes_chart: [
            {
              title: "Current Inventory & expirable inventory",
              type: "ARRAY",
              graphByValueField: ["ITEM_CODE", "LOCATION_CODE"],
              dualAxis: true,
              dataSetY1: {
                additionalGroupingField: ["ITEM_CODE", "LOCATION_CODE"],
                data: {
                  CURRENT_AVAILABLE_INVENTORY: {
                    path: "$.DATA.CURRENT_AVAILABLE_INVENTORY",
                    isgroupe: false,
                    seriesFieldY1: ["QUANTITY_ON_HAND"],
                  },
                  EXPIRABLE_INVENTORY: {
                    path: "$.DATA.EXPIRABLE_INVENTORY",

                    isgroupe: false,

                    seriesFieldY1: [
                      // "ON_HAND_VALUE",
                      // "NEW_EXCESS_VALUE",
                      "QUANTITY_ON_HAND",
                    ],
                  },
                },
                yTitle1: "Quantity",
              },
              dataSetY2: {
                additionalGroupingField: ["ITEM_CODE", "LOCATION_CODE"],
                data: {
                  CURRENT_AVAILABLE_INVENTORY: {
                    //groupe: "ITEM_CODE",
                    isgroupe: false,

                    path: "$.DATA.CURRENT_AVAILABLE_INVENTORY",
                    seriesFieldY2: [
                      "SALES_DAYS_OF_SUPPLY",
                      "GLOBAL_SALES_DOS",
                      "GLOBAL_FORECAST_DOS",
                      "FORECAST_DAYS_OF_SUPPLY",
                    ], // can be more then one
                  },
                },

                yTitle2: "D.O.S",
              },
            },
          ],
          line_chart: [
            {
              title: "inventory list",
              type: "ARRAY",
              data: {
                INVENTORY_LIST: "$.DATA.ACTION_DETAILS.INVENTORY_LIST",
              },
              colorConfig: [{ value: "INVENTORY_LIST", color: "#008BC7" }],
              xField: "DATE",
              yField: "QUANTITY_ON_HAND",
              multiple: false,
            },
          ],
          map: [
            {
              title: "Internal routes",
              type: "ARRAY",
              data: { INTERNAL_ROUTES: "$.DATA.INTERNAL_ROUTES" },
              receiving_location: {
                RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                SHIPPING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "LOCATION_CITY",
              details: [
                "LEAD_TIME_DAYS",
                "TRANSPORTATION_MODE",
                "DISTANCE_KM",
                "ROUTE_DESCRIPTION",
                "ROUTE_CODE",
                "COST_PER_UNIT_CURRENCY",
                "CO2_EMISSION_PER_KG_SHIPPED",
              ],
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: " ",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: {
                  path: "$.DATA.ERRORS.ERROR",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: { ALL: true, path: "$.DETAILS.TEXT" },
                    MODULE: "$.MODULE",
                  },
                },
                INFO: {
                  path: "$.DATA.ERRORS.INFO",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: { ALL: true, path: "$.DETAILS.TEXT" },
                    MODULE: "$.MODULE",
                  },
                },
                WARNING: {
                  path: "$.DATA.ERRORS.WARNING",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: { ALL: true, path: "$.DETAILS.TEXT" },
                    MODULE: "$.MODULE",
                  },
                },
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: false,
    },
  },
  {
    name: "FULL_CONTAINER_DEPLOYMENT",
    category: {
      ACTION_DETAILS: {
        title: "Action details",
        views: {
          inputs: [
            {
              title: "Details",
              column: [
                "CONTAINER_NUMBER",
                "DESTINATION_LOCATION_CODE",
                "ORIGIN_LOCATION_CODE",
                "FREIGHT_COST",
                "FREIGHT_CO2_EMISSION",
                "SHIPPING_DATE",
                "RECEIVING_DATE",
              ],
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
            {
              title: "Container information",
              column: [
                "SUPPLIER_CODE",
                "TOTAL_CONTAINER_VALUE",
                "TOTAL_QUANTITY_IN_UNITS",
                "VESSEL_NUMBER",
              ],
              data: { ACTION_DETAILS: "$.DATA.CONTAINER_INFORMATION" },
            },
          ],
          dynamic_table: [
            {
              title: "Products list at destination",
              data: {
                PRODUCT_LIST_AT_DESTINATION: {
                  path: "$.DATA.ACTION_DETAILS.PRODUCT_LIST_AT_DESTINATION",
                  columns: {
                    ITEM_DESCRIPTION: "$.ITEM_DESCRIPTION",
                    DEMAND_USED: "$.DEMAND_USED",
                    NEW_INVENTORY_QUANTITY: "$.NEW_INVENTORY_QUANTITY",
                    TRANSFER_QUANTITY: "$.TRANSFER_QUANTITY",
                  },
                },
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
          flow_chart: [
            {
              title: "Transfer order",
              type: "OBJECT",
              data: { TRANSFER_ORDER: "$.DATA.TRANSFER_ORDER" },

              startNodes: [
                {
                  path: {
                    ORIGIN_LOCATION_CODE: `$.DATA.MASTER.ORIGIN_LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "SHIPPING_DATE_AT_ORIGIN",
              ],
              endNodes: [
                {
                  path: {
                    DESTINATION_LOCATION_CODE: `$.DATA.MASTER.DESTINATION_LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "RECEIVING_DATE_AT_DESTINATION",
              ],

              details: ["CONTAINER_NUMBER", "TRANSPORTATION_MODE"],
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          dynamic_table: [
            {
              title: "Products list",
              data: {
                CONTAINER_PRODUCTS_LIST: {
                  path: "$.DATA.CONTAINER_PRODUCTS_LIST",
                  columns: {
                    CONTAINER_NUMBER: "$.CONTAINER_NUMBER",
                    ITEM_CODE: "$.ITEM_CODE",
                    ORDER_VALUE: "$.ORDER_VALUE",
                    SUPPLIER_CODE: "$.SUPPLIER_CODE",
                    QUANTITY_IN_UNITS: "$.QUANTITY_IN_UNITS",
                  },
                },
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
          dualAxes_chart: [
            {
              title: "Current Inventory & new inventory",
              type: "ARRAY",
              //  graphByValueField: "ITEM_CODE",
              dualAxis: true,
              dataSetY1: {
                // additionalGroupingField: "DEMAND",

                data: {
                  CURRENT_AVAILABLE_INVENTORY: {
                    path: "$.DATA.CURRENT_AVAILABLE_INVENTORY",

                    isgroupe: false,
                    seriesFieldY1: [
                      // "ON_HAND_VALUE",
                      // "SHORTAGE_VALUE",
                      "QUANTITY_ON_HAND",
                    ],
                  },
                  NEW_AVAILABLE_INVENTORY: {
                    path: "$.DATA.NEW_AVAILABLE_INVENTORY",

                    isgroupe: false,

                    seriesFieldY1: [
                      // "ON_HAND_VALUE",
                      // "NEW_EXCESS_VALUE",
                      "QUANTITY_ON_HAND",
                    ],
                  },
                },
                yTitle1: "Quantity",
              },
              dataSetY2: {
                data: {
                  CURRENT_AVAILABLE_INVENTORY: {
                    //groupe: "ITEM_CODE",
                    isgroupe: false,

                    path: "$.DATA.CURRENT_AVAILABLE_INVENTORY",
                    seriesFieldY2: ["SALES_DAYS_OF_SUPPLY", "GLOBAL_SALES_DOS"], // can be more then one
                  },
                  NEW_AVAILABLE_INVENTORY: {
                    //groupe: "ITEM_CODE",
                    isgroupe: false,

                    path: "$.DATA.NEW_AVAILABLE_INVENTORY",
                    seriesFieldY2: ["DAYS_OF_SUPPLY"],
                  },
                },

                yTitle2: "D.O.S",
              },
            },
            {
              title: "Other inventories list",
              type: "ARRAY",
              graphByValueField: ["ITEM_CODE", "DATE"],
              dualAxis: true,
              dataSetY1: {
                additionalGroupingField: ["ITEM_CODE", "DATE"],
                data: {
                  OTHER_INVENTORIES_LIST: {
                    path: "$.DATA.OTHER_INVENTORIES_LIST",
                    groupe: "LOCATION_CODE",
                    isgroupe: false,
                    seriesFieldY1: [
                      // "ON_HAND_VALUE",
                      "NEW_QUANTITY_ON_HAND",
                      "CURRENT_QUANTITY_ON_HAND",
                      // "DEMAND",
                    ],
                  },
                },
                yTitle1: "Quantity",
              },
              dataSetY2: {
                additionalGroupingField: ["ITEM_CODE", "DATE"],
                data: {
                  OTHER_INVENTORIES_LIST: {
                    groupe: "LOCATION_CODE",

                    isgroupe: false,

                    path: "$.DATA.OTHER_INVENTORIES_LIST",
                    seriesFieldY2: ["NEW_DAYS_OF_SUPPLY", "CURRENT_SALES_DOS"], // can be more then one
                  },
                },

                yTitle2: "D.O.S",
              },
            },
          ],

          map: [
            {
              title: "Deployment routes",
              type: "ARRAY",
              data: { DEPLOYMENT_ROUTE: "$.DATA.DEPLOYMENT_ROUTE" },
              receiving_location: {
                ORIGIN_LOCATION_CODE: `$.DATA.MASTER.ORIGIN_LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                DESTINATION_LOCATION_CODE: `$.DATA.MASTER.DESTINATION_LOCATION_MASTER.LOCATION_ID`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "LOCATION_CITY",
              details: [
                "LEAD_TIME_DAYS",
                "TRANSPORTATION_MODE",
                "DISTANCE_KM",
                "ROUTE_CODE",
                "COST_PER_UNIT_CURRENCY",
                "CO2_EMISSION_PER_KG_SHIPPED",
              ],
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: " ",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: {
                  path: "$.DATA.ERRORS.ERROR",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: {
                      ALL: true,
                      path: { TEXT: "$.DETAILS.TEXT", DATA: "$.DETAILS.DATA" },
                    },
                    MODULE: "$.MODULE",
                  },
                },
                INFO: {
                  path: "$.DATA.ERRORS.INFO",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: {
                      ALL: true,
                      path: { TEXT: "$.DETAILS.TEXT", DATA: "$.DETAILS.DATA" },
                    },
                    MODULE: "$.MODULE",
                  },
                },
                WARNING: {
                  path: "$.DATA.ERRORS.WARNING",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: {
                      ALL: true,
                      path: { TEXT: "$.DETAILS.TEXT", DATA: "$.DETAILS.DATA" },
                    },
                    MODULE: "$.MODULE",
                  },
                },
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: false,
    },
  },
  {
    name: "STOCK_REBALANCING",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "details",
              // column: ["DELIVERY_DATE", "FREIGHT_COST"],
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },
      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
          flow_chart: [
            {
              title: "Transfer order",
              type: "OBJECT",
              data: { TRANSFER_ORDER: "$.DATA.TRANSFER_ORDER" },
              // startNodes: ["RECEIVING_LOCATION", "DELIVERY_DATE"],
              startNodes: [
                {
                  path: {
                    SHIPPING_LOCATION: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "SHIP_DATE",
              ],
              endNodes: [
                {
                  path: {
                    RECEIVING_LOCATION: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "DELIVERY_DATE",
              ],
              // endNodes: ["SHIPPING_LOCATION", "SHIP_DATE"],
              details: ["ITEM", "QUANTITY"],
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          line_chart: [
            {
              title: "inventory & new inventory",
              type: "ARRAY",
              data: {
                INVENTORY: "$.DATA.INVENTORY",
                NEW_INVENTORY_PLAN: "$.DATA.NEW_INVENTORY_PLAN",
              },
              colorConfig: [
                { value: "INVENTORY", color: "#008BC7" },
                { value: "NEW_INVENTORY_PLAN", color: "#c70046" },
              ],
              xField: "DATE",
              yField: "QUANTITY_ON_HAND",
              multiple: true,
            },
          ],
          // column_chart: [
          //   {
          //     title: "inventory ",
          //     type: "ARRAY",
          //     data: {
          //       INVENTORY: "$.DATA.INVENTORY",
          //     },
          //     colorConfig: [{ value: "INVENTORY", color: "#008BC7" }],
          //     xField: "DATE",
          //     yField: "QUANTITY_ON_HAND",
          //   },
          // ],
          // bar_chart: [
          //   {
          //     title: "inventory & new inventory",
          //     type: "ARRAY",
          //     data: {
          //       INVENTORY: "$.DATA.INVENTORY",
          //       NEW_INVENTORY_PLAN: "$.DATA.NEW_INVENTORY_PLAN",
          //     },
          //     colorConfig: [
          //       { value: "INVENTORY", color: "#008BC7" },
          //       { value: "NEW_INVENTORY_PLAN", color: "#c70046" },
          //     ],
          //     yieldField: "DATE",
          //     xField: "QUANTITY_ON_HAND",
          //     dual: true,
          //     grouped: true,
          //   },
          // ],
          map: [
            {
              title: "internal routes",
              type: "OBJECT",
              data: { INTERNAL_ROUTE: "$.DATA.INTERNAL_ROUTE" },
              receiving_location: {
                RECEIVING_LOCATION: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                SHIPPING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "LOCATION_CITY",
              details: [
                "CO2_EMISSION_PER_KG_SHIPPED",
                "COST_PER_UNIT_CURRENCY",
                "DISTANCE_KM",
                "MODE_OF_TRANSPORTATION",
                "RECEIVING_LOCATION",
                "ROUTE_CODE",
                "ROUTE_DESCRIPTION",
              ],
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: {
                  path: "$.DATA.ERRORS.ERROR",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
                INFO: {
                  path: "$.DATA.ERRORS.INFO",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.NAME",
                    MODULE: "$.MODULE",
                  },
                },
                WARNING: {
                  path: "$.DATA.ERRORS.WARNING",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },

      OTHER_OPTIONS: true,
    },
  },
  {
    name: "ALTERNATE_SUPPLIER",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "details",
              column: null,

              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },
      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact details",
              column: null,

              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
          flow_chart: [
            {
              type: "OBJECT",
              title: "New purchase Order",

              data: { NEW_PURCHASE_ORDER: "$.DATA.NEW_PURCHASE_ORDER" },
              startNodes: [
                {
                  path: {
                    SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
                  },
                  valueField: "SUPPLIER_CITY",
                },
                "SHIPPING_DATE",
              ],
              endNodes: ["RECEIVING_LOCATION_CODE", "DELIVERY_DATE"],
              details: ["ITEM_CODE", "QUANTITY", "ALTERNATE_SUPPLIER_CODE"],
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_context_view",
        views: {
          flow_chart: [
            {
              title: "Purchase order",

              type: "OBJECT",
              data: { TRANSFER_ORDER: "$.DATA.PURCHASE_ORDER" },
              startNodes: [
                {
                  path: {
                    SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
                  },
                  valueField: "SUPPLIER_CITY",
                },
                "SHIPPING_DATE",
              ],
              endNodes: ["RECEIVING_LOCATION_CODE", "DELIVERY_DATE"],
              details: ["ITEM_CODE", "QUANTITY", "LINE_NUMBER"],
            },
          ],

          map: [
            {
              title: "Procurement shipping condition",

              type: "OBJECT",
              data: {
                PROCUREMENT_SHIPPING_CONDITION:
                  "$.DATA.PROCUREMENT_SHIPPING_CONDITION",
              },
              receiving_location: {
                RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "SUPPLIER_CITY",
              details: [
                "CO2_EMISSION",
                "CONDITION_DESCRIPTION",
                "COST_PER_UNIT_CURRENCY",
                "LEAD_TIME_DAYS",
                "MODE_OF_TRANSPORTATION",
                "CONDITION_CODE",
              ],
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: {
                  path: "$.DATA.ERRORS.ERROR",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
                INFO: {
                  path: "$.DATA.ERRORS.INFO",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
                WARNING: {
                  path: "$.DATA.ERRORS.WARNING",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },

      OTHER_OPTIONS: true,
    },
  },

  {
    name: "SUBSTITUTION",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "details",
              column: null,

              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },
      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact details",
              column: null,

              data: { IMPACT: "$.DATA.IMPACT" },
            },
            {
              title: "New Purchase Order",
              column: null,

              data: { NEW_PURCHASE_ORDER: "$.DATA.NEW_PURCHASE_ORDER" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          flow_chart: [
            {
              title: "Purchase Order",

              type: "OBJECT",
              data: {
                PURCHASE_ORDER: "$.DATA.PURCHASE_ORDER",
              },
              startNodes: [
                {
                  path: {
                    SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
                  },
                  valueField: "SUPPLIER_CITY",
                },
                "SHIPPING_DATE",
              ],
              endNodes: ["RECEIVING_LOCATION_CODE", "DELIVERY_DATE"],
              details: ["ITEM_CODE", "QUANTITY", "LINE_NUMBER"],
            },
            {
              type: "OBJECT",
              title: "Purchase Order Conditions",
              // missing
              data: { PO_CONDITION: "$.DATA.PO_CONDITION" },

              startNodes: [
                {
                  path: {
                    SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
                  },
                  valueField: "SUPPLIER_CITY",
                },
              ],
              endNodes: [
                {
                  path: {
                    RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
              ],

              details: [
                "CONDITION_CODE",
                //"CONDITION_DESCRIPTION",
                "LEAD_TIME_DAYS",
              ],
            },
          ],

          map: [
            {
              type: "OBJECT",
              title: "Shipping conditions",

              data: {
                PROCUREMENT_SHIPPING_CONDITION:
                  "$.DATA.PROCUREMENT_SHIPPING_CONDITION",
              },
              receiving_location: {
                RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "SUPPLIER_CITY",
              details: [
                "CO2_EMISSION",
                "CONDITION_DESCRIPTION",
                "COST_PER_UNIT_CURRENCY",
                "LEAD_TIME_DAYS",
                "TRANSPORTATION_MODE",
                "CONDITION_CODE",
              ],
            },
          ],
          inputs: [
            {
              title: " Substitution details",
              column: null,
              data: {
                PROCUREMENT_SUBSTITUTION: "$.DATA.PROCUREMENT_SUBSTITUTION",
              },
            },
            {
              title: "Updated Purchase Order",
              column: null,

              data: { UPDATED_PURCHASE_ORDER: "$.DATA.UPDATED_PURCHASE_ORDER" },
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: {
                  path: "$.DATA.ERRORS.ERROR",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
                INFO: {
                  path: "$.DATA.ERRORS.INFO",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
                WARNING: {
                  path: "$.DATA.ERRORS.WARNING",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },

      OTHER_OPTIONS: true,
    },
  },

  {
    name: "EXPRESS_DELIVERY",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "details",
              column: null,

              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },
      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact details",
              column: null,

              data: { IMPACT: "$.DATA.IMPACT" },
            },
            {
              title: "Updated Purchase Order",
              column: null,

              data: { UPDATED_PURCHASE_ORDER: "$.DATA.UPDATED_PURCHASE_ORDER" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          flow_chart: [
            {
              type: "OBJECT",
              title: "Purchase Order",
              data: { PURCHASE_ORDER: "$.DATA.PURCHASE_ORDER" },
              startNodes: [
                {
                  path: {
                    SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
                  },
                  valueField: "SUPPLIER_CITY",
                },
                "SHIPPING_DATE",
              ],
              endNodes: ["RECEIVING_LOCATION_CODE", "DELIVERY_DATE"],
              details: ["ITEM_CODE", "QUANTITY", "STANDARD_INBOUND_ROUTE"],
            },
            {
              type: "OBJECT",
              title: "Purchase Order condition",

              data: {
                PURCHASE_ORDER_CONDITION: "$.DATA.PURCHASE_ORDER_CONDITION",
              },
              startNodes: ["SUPPLIER_CODE"],
              endNodes: ["RECEIVING_LOCATION_CODE"],
              details: [
                "CONDITION_CODE",
                "CONDITION_DESCRIPTION",
                "LEAD_TIME_DAYS",
              ],
            },
          ],

          map: [
            {
              type: "OBJECT",
              title: "shipping conditions",

              data: {
                PROCUREMENT_SHIPPING_CONDITION:
                  "$.DATA.PROCUREMENT_SHIPPING_CONDITION",
              },
              receiving_location: {
                RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "SUPPLIER_CITY",
              details: [
                "CO2_EMISSION",
                "CONDITION_DESCRIPTION",
                "COST_PER_UNIT_CURRENCY",
                "LEAD_TIME_DAYS",
                "MODE_OF_TRANSPORTATION",
                "CONDITION_CODE",
              ],
            },
          ],
          inputs: [
            {
              title: "Purchase Order",
              column: null,

              data: { UPDATED_PURCHASE_ORDER: "$.DATA.UPDATED_PURCHASE_ORDER" },
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: {
                  path: "$.DATA.ERRORS.ERROR",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
                INFO: {
                  path: "$.DATA.ERRORS.INFO",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
                WARNING: {
                  path: "$.DATA.ERRORS.WARNING",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },

  {
    name: "PARTIAL_DELIVERY",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "details",
              column: null,

              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },
      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact details",
              column: null,

              data: { IMPACT: "$.DATA.IMPACT" },
            },
            {
              title: "New purchase order",
              column: null,

              data: { NEW_PURCHASE_ORDER: "$.DATA.NEW_PURCHASE_ORDER" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          flow_chart: [
            {
              title: "Purchase Order",
              type: "OBJECT",
              data: { PURCHASE_ORDER: "$.DATA.PURCHASE_ORDER" },

              startNodes: [
                {
                  path: {
                    SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
                  },
                  valueField: "SUPPLIER_CITY",
                },
                "SHIPPING_DATE",
              ],
              endNodes: [
                {
                  path: {
                    RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
                  },
                  valueField: "LOCATION_CITY",
                },
                "DELIVERY_DATE",
              ],

              details: [
                "ITEM_CODE",
                "QUANTITY",

                //  "PURCHASE_COST_PER_UNIT",
                "LINE_NUMBER",
                //"STANDARD_INBOUND_ROUTE",
                // "UNIT_OF_MEASURE",
              ],
            },
          ],
          line_chart: [
            {
              title: "inventory & new inventory",
              type: "ARRAY",
              data: {
                INVENTORY: "$.DATA.INVENTORY",
                NEW_INVENTORY_PLAN: "$.DATA.NEW_INVENTORY_PLAN",
              },
              colorConfig: [
                { value: "INVENTORY", color: "#008BC7" },
                { value: "NEW_INVENTORY_PLAN", color: "#c70046" },
              ],
              xField: "DATE",
              yField: "ON_HAND_VALUE",
              multiple: true,
            },
          ],
          dualAxes_chart: [
            {
              title: "Inventory & New inventory plan",
              type: "ARRAY",
              dualAxis: true,
              dataSetY1: {
                data: {
                  INVENTORY: {
                    path: "$.DATA.INVENTORY",
                    seriesFieldY1: ["ON_HAND_VALUE", , "DEMAND"],
                  },
                  NEW_INVENTORY_PLAN: {
                    path: "$.DATA.NEW_INVENTORY_PLAN",
                    seriesFieldY1: ["ON_HAND_VALUE", "QUANTITY_ON_HAND"],
                  },
                },
                yTitle1: "Value",
              },
              dataSetY2: {
                data: {
                  INVENTORY: {
                    path: "$.DATA.INVENTORY",
                    seriesFieldY2: ["DAYS_OF_SUPPLY"], // can be more then one
                  },
                  NEW_INVENTORY_PLAN: {
                    path: "$.DATA.NEW_INVENTORY_PLAN",
                    seriesFieldY2: ["DAYS_OF_SUPPLY"],
                  },
                },

                yTitle2: "D.O.S",
              },
            },
          ],
          map: [
            {
              type: "OBJECT",
              title: "Shipping conditions map",
              data: {
                PROCUREMENT_SHIPPING_CONDITION:
                  "$.DATA.PROCUREMENT_SHIPPING_CONDITION",
              },
              receiving_location: {
                RECEIVING_LOCATION_CODE: `$.DATA.MASTER.LOCATION_MASTER.LOCATION_ID`,
              },
              shipping_location: {
                SUPPLIER_CODE: `$.DATA.MASTER.SUPPLIER_MASTER.SUPPLIER_CODE`,
              },
              receiving_location_city: "LOCATION_CITY",
              shipping_location_city: "SUPPLIER_CITY",
              details: [
                "CO2_EMISSION",
                "CONDITION_DESCRIPTION",
                "COST_PER_UNIT_CURRENCY",
                "LEAD_TIME_DAYS",
                "MODE_OF_TRANSPORTATION",
                "CONDITION_CODE",
              ],
            },
          ],
          inputs: [
            {
              title: "Updated Purchase Order",
              column: null,

              data: { UPDATED_PURCHASE_ORDER: "$.DATA.UPDATED_PURCHASE_ORDER" },
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: {
                  path: "$.DATA.ERRORS.ERROR",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
                INFO: {
                  path: "$.DATA.ERRORS.INFO",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
                WARNING: {
                  path: "$.DATA.ERRORS.WARNING",
                  columns: {
                    CODE: "$.CODE",
                    DETAILS: "$.DETAILS.DATA",
                    MODULE: "$.MODULE",
                  },
                },
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },

      OTHER_OPTIONS: true,
    },
  },
];
