import styled from "styled-components";

export const SSection = styled.div`
  height: 100vh;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.8), #fefefe),
    url("./img/bg.png");

  @media only screen and (max-width: 768px) {
    height: 200vh;
  }
`;

export const SContainer = styled.div`
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;

  height: 100%;
  scroll-snap-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-inline: 40px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-inline: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const SLeft = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    flex: 1;
    align-items: center;
  }
`;

export const STitle = styled.h1`
  padding-top: 70px;
  color: #004c97;
  font-size: 5.8vw;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const SWhatWeDo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SLine = styled.img`
  height: 5px;
`;

export const SSubtitle = styled.h2`
  color: #da4ea2;
`;

export const SDesc = styled.p`
  font-size: 24px;
  color: lightgray;
  @media only screen and (max-width: 768px) {
    padding: 20px;
    text-align: center;
  }
`;

export const SButton = styled.button`
  background-color: #ff671f;
  margin-inline-end: 10px;
  margin-block-end: 10px;
  color: white;
  font-weight: 500;
  padding: 10px;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center; /* Center the text vertically */
  justify-content: center;
`;

export const SRight = styled.div`
  flex: 4;
  position: relative;
  @media only screen and (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
`;

export const SImg = styled.img`
  width: 80%;
  height: 80%;
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: animate 2s infinite ease alternate;

  @keyframes animate {
    to {
      transform: translateY(20px);
    }
  }
`;
