import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";

const resource = { name: "copilots", isPublic: true };

class CopilotsService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(resource, context, setContext, path);
  }

  async getUserCopilots(): Promise<any> {
    const data = await this.get(
      `/organizations/${this?.context?.orga?.id}/copilots/?limit=1000&offset=0&sort=created_at&order=desc`
    );
    return data;
  }
}

export default CopilotsService;
