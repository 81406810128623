import { ProColumns } from "@ant-design/pro-components";
import { SharedContext } from "../../context/sharedContext";
import { useContext, useEffect } from "react";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/riskLogic";
import { RisksItem } from "../../../../../interfaces/risks";
import MenuOptions from "../../../../common/menu/MenuOptions";
import { WORK_MODE } from "../../../../../constants/workModes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../utils/transaltion";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";

export const RisksTableDef: any = (
  valueEnumPredefinedRisk: any,
  valueEnumCopilot: any
) => {
  const { setWorkModeRisk } = useContext(SharedContext);
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /** local vars */
  const { t } = useTranslation();
  const navigate = useNavigate();
  /**shared logic */
  const { goToRiskConfiguration, Deleterisk } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;

  /**confs shared context */
  const columns: ProColumns<RisksItem>[] = [
    {
      title: t("RISK"),
      dataIndex: "name",
      width: "30%",
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "descend",
      sorter: true,
      render: (text, record) => {
        return <span>{record?.name}</span>;
      },
    },
    {
      title: t("COPILOT"),
      dataIndex: "copilot",
      width: "30%",
      valueType: "select",
      valueEnum: valueEnumCopilot,
      fieldProps: {
        showSearch: true, // Enable search in filter
        optionFilterProp: "label", // Search by label
        filterOption: (input: any, option: any) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      },
      render: (text, record) => {
        return getTranslation(record?.model?.copilot?.name || "", "data");
      },
    },

    {
      title: t("RISK_PREDEFINED"),
      dataIndex: "model",
      width: "20%",
      valueType: "select",
      valueEnum: valueEnumPredefinedRisk,
      fieldProps: {
        showSearch: true, // Enable search in filter
        optionFilterProp: "label", // Search by label
        filterOption: (input: any, option: any) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      },
      hideInTable: false,
      render: (text, record) => {
        return getTranslation(record?.model?.name || "", "data");
      },
    },
    isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)
      ? {
          title: t("MORE_ACTIONS"),
          valueType: "option",
          key: "option",
          width: "20%",
          align: "center",
          render: (text, record) => (
            <span>
              <MenuOptions
                viewRecord={async () => {
                  setWorkModeRisk(WORK_MODE.VIEW);
                  goToRiskConfiguration(
                    navigate,
                    record,
                    WORK_MODE.VIEW,
                    `/menu/configurations/risks/${record?.id}`
                  );
                }}
                editRecord={async () => {
                  setWorkModeRisk(WORK_MODE.UPDATE);
                  goToRiskConfiguration(
                    navigate,
                    record,
                    WORK_MODE.UPDATE,
                    `/menu/configurations/risks/${record?.id}`
                  );
                }}
                deleteRecord={() => {
                  Deleterisk(record.id);
                }}
                title={t("errors:CONFIRM_DELETE_RISK_ON_CASCADE")}
              />
            </span>
          ),
        }
      : {},
  ];

  return columns;
};
