import React, { ReactNode, createContext, useState } from "react";
import { ColorPickerProps, Form, FormInstance, GetProp } from "antd";
import { EnumItem } from "../../../../interfaces/enums";
type dynamicF = {
  key: number;
  name?: string;
  type?: string;
  defaultValue?: any;
  position?: number;
  suffix?: string;
  choice?: any;
};
export type dynamicFDateNeeded = {
  key?: number;
  dataName?: string;
  dataType?: string;
  defaultValue?: any;
  position?: number;
  suffix?: string;
  choice?: any;
};
type Color = GetProp<ColorPickerProps, "value">;
// Define the context
type ContextType = {
  isAuto: boolean;
  setIsAuto: React.Dispatch<React.SetStateAction<boolean>>;
  isDisable: boolean;
  setIsDisable: React.Dispatch<React.SetStateAction<boolean>>;
  Loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  List: any[];
  setList: React.Dispatch<React.SetStateAction<any[]>>;
  ListFilter: any[];
  setListFilter: React.Dispatch<React.SetStateAction<any[]>>;
  total: number;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
  getLoading: boolean;
  setGetLoading: React.Dispatch<React.SetStateAction<boolean>>;
  workMode: string;
  setWorkMode: React.Dispatch<React.SetStateAction<string>>;
  Record: any;
  setRecord: React.Dispatch<React.SetStateAction<any>>;
  valueEnumCurrency: EnumItem;
  setValueEnumCurrency: React.Dispatch<React.SetStateAction<EnumItem>>;
  dynamicFields: dynamicFDateNeeded[];
  setDynamicFields: React.Dispatch<React.SetStateAction<dynamicFDateNeeded[]>>;
  formSettings: FormInstance<any>;
  form: FormInstance<any>;
  formDataNeeded: FormInstance<any>;
  actionColor: Color;
  setActionColor: React.Dispatch<React.SetStateAction<Color>>;
  executionColor: string;
  setExecutionColor: React.Dispatch<React.SetStateAction<string>>;
  valueEnumRoles: EnumItem;
  setValueEnumRoles: React.Dispatch<React.SetStateAction<EnumItem>>;
  valueEnumPreExecutions: EnumItem;
  setValueEnumPreExecutions: React.Dispatch<React.SetStateAction<EnumItem>>;
  dynamicFieldsSettings: dynamicF[];
  setDynamicFieldsSettings: React.Dispatch<React.SetStateAction<dynamicF[]>>;

  valueEnumCopilot: EnumItem;
  setValueEnumCopilot: React.Dispatch<React.SetStateAction<EnumItem>>;
  valueEnumPreActions: EnumItem;
  setValueEnumPreActions: React.Dispatch<React.SetStateAction<EnumItem>>;
  dataNeededURL: any;
  setDataNeededURL: React.Dispatch<React.SetStateAction<any>>;
  settingsChoicesList: any;
  setSettingsChoicesList: React.Dispatch<React.SetStateAction<any>>;
};
export const SharedContext = createContext<ContextType>({
  actionColor: "",
  setActionColor: () => {},
  executionColor: "",
  setExecutionColor: () => {},
  Loading: true,
  setLoading: () => {},
  List: [],
  setList: () => {},
  ListFilter: [],
  setListFilter: () => {},
  total: 0,
  setTotal: () => {},
  getLoading: false,
  setGetLoading: () => {},
  workMode: "",
  setWorkMode: () => {},
  Record: {},
  setRecord: () => {},
  valueEnumCurrency: {},
  setValueEnumCurrency: () => {},
  dynamicFields: [],
  setDynamicFields: () => {},
  form: {} as FormInstance<any>,
  formSettings: {} as FormInstance<any>,
  formDataNeeded: {} as FormInstance<any>,

  /**action */
  dynamicFieldsSettings: [],
  setDynamicFieldsSettings: () => {},
  isAuto: false,
  setIsAuto: () => {},
  isDisable: false,
  setIsDisable: () => {},
  valueEnumRoles: {},
  setValueEnumRoles: () => {},
  valueEnumPreExecutions: {},
  setValueEnumPreExecutions: () => {},
  /**risk */
  valueEnumCopilot: {},
  setValueEnumCopilot: () => {},
  valueEnumPreActions: {},
  setValueEnumPreActions: () => {},
  dataNeededURL: {},
  setDataNeededURL: () => {},
  settingsChoicesList: {},
  setSettingsChoicesList: () => {},
});

// Create a provider component
export const SharedContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [workMode, setWorkMode] = useState("");
  const [Loading, setLoading] = useState(true);
  const [ListFilter, setListFilter] = useState<any[]>([]);
  const [List, setList] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [getLoading, setGetLoading] = useState(false);
  const [dynamicFields, setDynamicFields] = useState<dynamicFDateNeeded[]>([
    { key: 1 },
  ]);
  const [Record, setRecord] = useState<any>();
  /* enums  for the filters */
  const [valueEnumCurrency, setValueEnumCurrency] = useState<{
    [key: string]: { text: string };
  }>({});
  const [form] = Form.useForm();
  const [formSettings] = Form.useForm();

  /**Action specific vars */
  const [dynamicFieldsSettings, setDynamicFieldsSettings] = useState<
    dynamicF[]
  >([{ key: 1 }]);
  const [dataNeededURL, setDataNeededURL] = useState<any>({});
  const [isAuto, setIsAuto]: any = useState(false);
  const [isDisable, setIsDisable]: any = useState(false);
  const [actionColor, setActionColor] = useState<Color>("#DBE7F1");
  const [executionColor, setExecutionColor] = useState<string>("#DBE7F1");
  const [valueEnumRoles, setValueEnumRoles] = useState<{
    [key: string]: { text: string };
  }>({});
  const [valueEnumPreExecutions, setValueEnumPreExecutions] = useState<{
    [key: string]: { text: string };
  }>({});
  /**Risk */
  const [valueEnumCopilot, setValueEnumCopilot] = useState<{
    [key: string]: { text: string };
  }>({});
  const [valueEnumPreActions, setValueEnumPreActions] = useState<{
    [key: string]: { text: string };
  }>({});
  const [formDataNeeded] = Form.useForm();
  //choices settings
  const [settingsChoicesList, setSettingsChoicesList] = useState<any>({});

  return (
    <SharedContext.Provider
      value={{
        settingsChoicesList,
        setSettingsChoicesList,
        dataNeededURL,
        setDataNeededURL,
        formDataNeeded,
        dynamicFieldsSettings,
        setDynamicFieldsSettings,
        valueEnumCopilot,
        setValueEnumCopilot,
        valueEnumPreActions,
        setValueEnumPreActions,
        valueEnumPreExecutions,
        setValueEnumPreExecutions,
        valueEnumRoles,
        setValueEnumRoles,
        actionColor,
        setActionColor,
        executionColor,
        setExecutionColor,
        form,
        formSettings,
        dynamicFields,
        setDynamicFields,
        ListFilter,
        setListFilter,
        valueEnumCurrency,
        setValueEnumCurrency,
        Record,
        setRecord,
        workMode,
        setWorkMode,
        Loading,
        setLoading,
        List,
        setList,
        total,
        setTotal,
        getLoading,
        setGetLoading,
        isAuto,
        setIsAuto,
        isDisable,
        setIsDisable,
      }}
    >
      {children}
    </SharedContext.Provider>
  );
};
