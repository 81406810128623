import { PageHeader } from "@ant-design/pro-components";
import { Button, Card, Popconfirm } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { WORK_MODE } from "../../../../../constants/workModes";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/riskLogic";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { SharedContext } from "../../context/sharedContext";
import { useContext } from "react";

const RiskPageHeader = (props: any) => {
  const { t } = useTranslation();
  /**props  */
  const { form, record } = props;
  /**local vars */
  const navigate = useNavigate();
  const { setWorkModeRisk, workModeRisk } = useContext(SharedContext);

  /** get shared logic */
  const { goToRiskTable } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  return (
    <div>
      <PageHeader
        title={
          <span style={{ alignContent: "start", marginLeft: 0 }}>
            <Popconfirm
              placement="top"
              key="delete"
              style={{ zIndex: 9999999 }}
              disabled={false}
              title={t("errors:LEAVING_PAGE_MESSAGE")}
              onConfirm={async () => {
                await goToRiskTable();
              }}
              okText={t("NO")}
              cancelText={t("YES")}
            >
              <Link
                style={{ fontSize: 16, color: "#0082BA" }}
                to="/menu/configurations"
                onClick={(e) => {
                  if (
                    form.isFieldsTouched() &&
                    workModeRisk !== WORK_MODE.VIEW
                  ) {
                    e.preventDefault();
                  } else {
                    goToRiskTable();
                  }
                }}
              >
                <ArrowLeftOutlined
                  style={{
                    marginInlineStart: 0,
                    marginInlineEnd: 8,
                    color: "#0082BA",
                    transform:
                      getComputedStyle(document.body).direction === "rtl"
                        ? "scaleX(-1)"
                        : "none",
                  }}
                />
                {t("EXOBRAIN_BACK_TO")}
              </Link>
            </Popconfirm>
          </span>
        }
        style={{ marginInlineStart: -20 }}
      />

      <div
        style={{
          height: "3%",
          marginTop: -10,
          width: "100%",
          marginBottom: 10,
        }}
      >
        <Card>
          <p
            style={{
              fontWeight: "bold",
              fontSize: 16,
              color: "#2A333B",
            }}
          >
            {t("RISK_CONFIGURATION")}
            {workModeRisk === WORK_MODE.VIEW && (
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  marginRight: 10,
                }}
                onClick={() => {
                  setWorkModeRisk(WORK_MODE.UPDATE);
                  navigate(window.location.pathname, {
                    state: {
                      record: record,
                      workModeRisk: WORK_MODE.UPDATE,
                    },
                  });
                }}
                icon={<EditOutlined style={{ color: "#004C97" }} />}
              ></Button>
            )}
          </p>
        </Card>
      </div>
    </div>
  );
};

export default RiskPageHeader;
