import { Pie, PieConfig } from "@ant-design/plots";

const RejectionByReasonCode = (props: any) => {
  let { data } = props;

  const config: PieConfig = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "rejection_code",
    color: [
      "#004C97",
      "#0082BA",
      "#5BC2E7",
      "#DBE7F1",
      "#B9C7D4",
      "#7B97AF",
      "#0C6FD1",
      "#0AACF1",
      "#76C2DD",
      "#4E7FA8",
      "#A4C6E6",
      "#0275E6",
      "#9AE0FF",
    ],
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    radius: 0.6,
    label: {
      type: "spider",
      labelHeight: 28,
      content: ({ value }) => `${value}\n risks`,
      style: {
        fontSize: 12,
        textAlign: "center",
      },
    },
    tooltip: {
      shared: true,
      showMarkers: false,
      formatter: ({ rejection_code, value }: any) => ({
        name: rejection_code,
        value: `${value} risks`,
      }),
    },
    legend: {
      position: "bottom",
    },
    style: {
      marginInline: -18,
    },
  };

  return <Pie {...config} height={300} />;
};

export default RejectionByReasonCode;
