import { ProColumns } from "@ant-design/pro-components";
import MenuOptions from "../../../common/menu/MenuOptions";
import { CODIFICATION_TYPES } from "../../../../constants/condificationTypes";
import { WORK_MODE } from "../../../../constants/workModes";
import { Checkbox, Form, Input, Tag } from "antd";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/codificationLogic";
import { useEffect, useState } from "react";
import SaveCancel from "../../../common/SaveCancel";
import { MessageInstance } from "antd/es/message/interface";
import FixedHeightCell from "../../../common/FixedHeightCell";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/store";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../utils/transaltion";
import { isNameInUse } from "../../../../utils/functions";
import { isPermission } from "../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../constants/permissions";
import CustomIcon from "../components/CustomIcon";
import IconSelect from "../components/CodificationsSelectIcons";

export const CodifTableDef: any = (
  type: string,
  messageApi: MessageInstance
) => {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  const [form] = Form.useForm();
  const [loadingSave, setloadingSave] = useState(false);
  const {
    DeleteCodif,
    handleEditRow,
    handleCancelEditRow,
    UpdateCodif,
    getColumnSearchProps,
    isCopilotdeleteable,
  } = useSharedContextFunctions() as SharedContextFunctionsInterface;
  const [editingRow, setEditingRow] = useState<any>(null);
  const [name, setName] = useState<string>("");
  const [isSystem, setIsSystem] = useState(false);
  const [iconName, setIconName] = useState<string | null>(null);
  const copilotList = useSelector((state: RootState) => state.copilotReducer);
  const { t } = useTranslation();
  const rolesList = useSelector((state: RootState) => state.roleReducer);
  const reasonsActionList = useSelector(
    (state: RootState) => state.reasonsActionsReducer
  );
  const reasonsExecutionList = useSelector(
    (state: RootState) => state.reasonsExecutionsReducer
  );
  const reasonsRisksList = useSelector(
    (state: RootState) => state.reasonsRisksReducer
  );
  const columns: ProColumns<any>[] = [
    {
      title:
        type === CODIFICATION_TYPES.ACTION_REASONS
          ? t("CODIF_REASONS_CODE_ACTION_NAME")
          : type === CODIFICATION_TYPES.EXECUTION_REASONS
          ? t("CODIF_REASONS_CODE_EXECUTION_NAME")
          : type === CODIFICATION_TYPES.COPILOTS
          ? t("CODIF_COPILOT_NAME")
          : type === CODIFICATION_TYPES.ROLES
          ? t("CODIF_ROLE_NAME")
          : type === CODIFICATION_TYPES.RISK_REASONS &&
            t("CODIF_REASONS_CODE_RISK_NAME"),

      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      ...getColumnSearchProps("name", type),
      render: (text: any, record: any) => {
        const isEditing = editingRow && editingRow.id === record.id;
        if (isEditing) {
          return (
            <>
              <FixedHeightCell>
                {" "}
                <Form form={form} layout="horizontal">
                  <Form.Item
                    label={null}
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message: t("errors:REQUIRED", {
                          label: t("labels:NAME_FIELD"),
                        }),
                      },
                      {
                        validator: (_, value) => {
                          let valuesNames;

                          switch (type) {
                            case CODIFICATION_TYPES.ACTION_REASONS:
                              valuesNames = reasonsActionList;
                              break;

                            case CODIFICATION_TYPES.EXECUTION_REASONS:
                              valuesNames = reasonsExecutionList;
                              break;
                            case CODIFICATION_TYPES.RISK_REASONS:
                              valuesNames = reasonsRisksList;
                              break;

                            case CODIFICATION_TYPES.COPILOTS:
                              valuesNames = copilotList;
                              break;

                            case CODIFICATION_TYPES.ROLES:
                              valuesNames = rolesList;
                              break;

                            default:
                              valuesNames = [];
                              break;
                          }
                          if (isNameInUse(valuesNames, record, value)) {
                            return Promise.reject(t("NAME_USED"));
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    {" "}
                    <Input
                      readOnly={false}
                      defaultValue={getTranslation(record?.name, "data")}
                      style={{ width: "70%" }}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Form>
              </FixedHeightCell>
            </>
          );
        }
        return <span>{getTranslation(record?.name, "data")}</span>;
      },
    },
    //
    ...(type === CODIFICATION_TYPES.COPILOTS
      ? [
          {
            title: t("ICON"),
            dataIndex: "uiConfiguration",
            key: "uiConfiguration",
            width: "20%",
            render: (text: any, record: any) => {
              const isEditing = editingRow && editingRow?.id === record.id;
              if (isEditing) {
                // setIconName(record?.uiConfiguration?.iconName || "");
                return (
                  <>
                    <FixedHeightCell>
                      {" "}
                      <Form form={form} layout="horizontal">
                        <Form.Item>
                          {" "}
                          <IconSelect
                            size="28px"
                            style={{ width: "80%" }}
                            setIconName={setIconName}
                            workMode={WORK_MODE.UPDATE}
                            iconName={record?.uiConfiguration?.iconName || ""}
                          />
                        </Form.Item>
                      </Form>
                    </FixedHeightCell>
                  </>
                );
              }
              return (
                <CustomIcon
                  name={record?.uiConfiguration?.iconName}
                  size="28px"
                />
              );
            },
          },
        ]
      : []),
    ...(type === CODIFICATION_TYPES.EXECUTION_REASONS ||
    type === CODIFICATION_TYPES.RISK_REASONS ||
    type === CODIFICATION_TYPES.ACTION_REASONS
      ? [
          {
            title: t("SYSTEM"),
            dataIndex: "is_system",
            key: "is_system",
            ...getColumnSearchProps("is_system", type),
            render: (text: any, record: any) => {
              const isEditing = editingRow && editingRow?.id === record.id;

              if (isEditing) {
                return (
                  <Checkbox
                    checked={isSystem}
                    onChange={(e) => {
                      setIsSystem(e.target.checked);
                    }}
                  />
                );
              }

              return (
                <span>
                  <Tag color={record.is_system ? "red" : "blue"}>
                    {record.is_system ? t("YES") : t("NO")}
                  </Tag>
                </span>
              );
            },
          },
        ]
      : []),
    isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
      ? {
          title: t("MORE_ACTIONS"),
          valueType: "option",
          key: "option",
          align: "center",
          fixed: "right",
          width: "30%",
          render: (text, record) => (
            <span>
              {editingRow && editingRow.id === record.id ? (
                <FixedHeightCell>
                  <SaveCancel
                    confirmLoading={loadingSave}
                    onClickSave={() => {
                      UpdateCodif(
                        type,
                        messageApi,
                        record,
                        {
                          name: name,
                          id: record.id,
                          is_system: isSystem,
                          iconName,
                        },
                        setloadingSave,
                        setEditingRow
                      );
                    }}
                    onClickCancel={() => {
                      handleCancelEditRow(setEditingRow);
                    }}
                    className="save-cancel-groupe"
                  />
                </FixedHeightCell>
              ) : (
                <FixedHeightCell>
                  <MenuOptions
                    editRecord={async () => {
                      handleEditRow(record, setEditingRow);
                      setName(record.name);
                      setIsSystem(record.is_system);
                    }}
                    deleteRecord={
                      type === CODIFICATION_TYPES.COPILOTS
                        ? () => {
                            isCopilotdeleteable(record.id) &&
                              DeleteCodif(record.id, type);
                          }
                        : undefined
                    }
                    title={t("errors:CONFIRM_DELETE_MESSAGE")}
                    mode={WORK_MODE.CODIF}
                  />
                </FixedHeightCell>
              )}
            </span>
          ),
        }
      : null,
  ];

  return columns;
};
