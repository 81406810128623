import { Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import FilterHeader from "../common/filter-header/FilterHeader";
import ChartContent from "../common/charts/chart-content/ChartContent";
import StatContent from "../common/statistics/StatContent";
import { FilterConf } from "../common/interfaces/FilterConf";
import {
  ACTION_FIELDS,
  CHART_CONFIGS,
  RISK_FIELDS,
  STATS_CONFIGS,
} from "../common/constants/configs";
import { StatConf } from "../common/interfaces/StatConf";
import { RootState } from "../../../../redux/store/store";

import { MainContext } from "../../../../utils/context";
import ActionAnalysis from "../../dashboards-demo/action_analysis/ActionAnalysis";
import ActionMonitoring from "../../dashboards-demo/action_monitoring/ActionMonitoring";
import { extractDataNeededFromURL } from "../../organization/logic/OrganizationLogic";

export default function ActionAnalysis2() {
  const globalContext = useContext(MainContext);
  const orgaFormatNumber =
    globalContext?.context?.orga?.formatNumber || "en-US";
  const orgaLanguage =
    globalContext?.context?.user?.preferredLanguage || "en-US";
  const orgaCurrency = globalContext?.context?.orga?.currency || "USD";
  const orgaDateFormat =
    globalContext?.context?.orga?.formatDate || "DD/MM/YYYY";

  const { copilotName } = useParams();
  /** get predefined risks from redux */
  const predefinedRiskList = useSelector(
    (state: RootState) => state.predefinedRiskOrgaReducer
  );
  const copilotPredefinedrisks = predefinedRiskList?.filter(
    (predRisk: any) =>
      predRisk?.copilot?.name === copilotName?.toLocaleUpperCase()
  );
  const predefinedActions = useSelector(
    (state: RootState) => state.predefinedActionOrgaReducer
  );
  const copilotContextFields = copilotPredefinedrisks?.reduce(
    (acc: any, obj: any) => {
      if (obj.policyData) {
        acc = { ...acc, ...obj.policyData };
      }
      return acc;
    },
    {}
  );
  const copilotDataneededFields = extractDataNeededFromURL(predefinedActions, [
    "CATEGORY",
  ]);

  /* Filter section */
  const [filterParams, setFilterParams] = useState<any>();
  const [filterConfigs, setFilterConfigs] = useState<FilterConf[]>([]);

  /* Statistics section */
  const [statConfigs, setStatConfigs] = useState<StatConf[]>(STATS_CONFIGS);

  /* Charts section */
  const [chartConfigs, setChartConfigs] = useState(CHART_CONFIGS);
  const [chartParams, setChartParams] = useState({
    dateFormat: orgaDateFormat,
    numberFormat: orgaFormatNumber,
    currency: orgaCurrency,
    language: orgaLanguage,
  });

  useEffect(() => {
    /* handle when filter header params changes */
    console.log(filterParams);
  }, [filterParams]);

  useEffect(() => {
    /* handle when stat configs changes */
    console.log(statConfigs);
  }, [statConfigs]);

  useEffect(() => {
    /* handle when chart configs changes */
    console.log(chartConfigs);
  }, [chartConfigs]);

  if (copilotName?.toLocaleLowerCase() === "procurement") {
    return <ActionAnalysis copilot={"Procurement"} />;
  } else if (copilotName?.toLocaleLowerCase() === "fulfillment") {
    return <ActionAnalysis copilot={"Fulfillement"} />;
  } else if (copilotName?.toLocaleLowerCase() === "demand_matching") {
    return <ActionAnalysis copilot={"Demand Matching"} />;
  } else if (copilotName?.toLocaleLowerCase() === "supply_matching") {
    return <ActionAnalysis copilot={"Supply Matching"} />;
  } else if (
    copilotName?.toLocaleLowerCase() === "inbound_freight_management"
  ) {
    return <ActionAnalysis copilot={"Inbound Freight"} />;
  }

  return (
    <>
      <Row>
        <FilterHeader
          copilotContextFields={copilotContextFields}
          setFilterParams={setFilterParams}
          filterConfigs={filterConfigs}
          setFilterConfigs={setFilterConfigs}
        />
      </Row>
      <StatContent
        copilotDataneededFields={RISK_FIELDS}
        statConfigs={statConfigs}
        setStatConfigs={setStatConfigs}
        data={[]}
      />
      <ChartContent
        copilotDataneededFields={RISK_FIELDS}
        chartConfigs={chartConfigs}
        setChartConfigs={setChartConfigs}
        data={[]}
        maxCards={12}
        params={chartParams}
      />
    </>
  );
}
