// import React, { useEffect, useState } from "react";
// import "leaflet-routing-machine";
// import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
// import { useMap } from "react-leaflet";
// import L from "leaflet";
// import { dakar_uk, dammam_said, r, r2, said_istanbul, uk_rot } from "./mapView";
// interface Coordinate {
//   lat: number;
//   lng: number;
// }

// const LeafletRoutingMachine = (props: any) => {
//   const map = useMap();
//   const { InputList } = props;

//   const [route, setRoute] = useState([]);
//   const [route2, setRoute2] = useState([]);
//   const fetchData = async (a: Coordinate, b: Coordinate, setRoute: any) => {
//     try {
//       const url = `https://api.searoutes.com/route/v2/sea/${a.lat},${a.lng};${b.lat},${b.lng}?continuousCoordinates=true&allowIceAreas=false&avoidHRA=false&avoidSeca=false`;

//       const response = await fetch(url, {
//         method: "GET",
//         headers: {
//           Accept: "application/json",
//           "x-api-key": "do3gft32pyaRvrCWmeZjV4XlWHdLbk1X17ZjZx16",
//         },
//       });
//       const data = await response.json();
//       console.log("data.features.geometry.coordinates");
//       console.log(data?.features?.geometry?.coordinates);
//     } catch (error) {
//       console.error("Error fetching route:", error);
//     }
//   };

//   let Inline_DefaultIcon = L.icon({
//     iconUrl: require("./icon/location-pin.png"),
//     iconSize: [50, 50],
//   });

//   let Current_DefaultIcon = L.icon({
//     iconUrl: require("./icon/port-blue.png"),
//     iconSize: [55, 55],
//   });

//   useEffect(() => {
//     // console.log("-------InputList");
//     // console.log(InputList);
//     InputList.forEach((route: any) => {
//       const length = route.waypoints.length;

//       const startMarker = L.marker(route.waypoints[0], {
//         icon: Inline_DefaultIcon,
//       }).addTo(map);

//       const endMarker = L.marker(route.waypoints[length - 1], {
//         icon: Inline_DefaultIcon,
//       }).addTo(map);

//       /* fetchData(route.waypoints[0], route.waypoints[1], setRoute);
//       fetchData(route.waypoints[1], route.waypoints[length - 1], setRoute); */

//       let currentMarker: any;
//       if (length === 3) {
//         currentMarker = L.marker(route.waypoints[1], {
//           icon: Current_DefaultIcon,
//         }).addTo(map);
//       }

//       // console.log("startMarker.getLatLng()");
//       // console.log(startMarker.getLatLng());
//       L.polyline(
//         length === 3
//           ? startMarker.getLatLng().lat === 51.4631174
//             ? [
//                 ...[...r, ...r2].map((coord) => {
//                   return { lng: coord[0], lat: coord[1] };
//                 }),
//               ]
//             : startMarker.getLatLng().lat === 43.2573529
//             ? [
//                 ...[...dammam_said, ...said_istanbul].map((coord) => {
//                   return { lng: coord[0], lat: coord[1] };
//                 }),
//               ]
//             : startMarker.getLatLng().lat === 14.693425
//             ? [
//                 ...[...dakar_uk, ...uk_rot].map((coord) => {
//                   return { lng: coord[0], lat: coord[1] };
//                 }),
//               ]
//             : [
//                 startMarker.getLatLng(),
//                 currentMarker.getLatLng(),
//                 endMarker.getLatLng(),
//               ]
//           : [startMarker.getLatLng(), endMarker.getLatLng()],
//         {
//           color: "#004c97",
//           weight: 4,
//           opacity: 0.7,
//         }
//       ).addTo(map);

//       length! > 2 &&
//         endMarker.on("click", () => {
//           const popupContent = route.popupContent.split(",").join("<br>");
//           L.popup()
//             .setLatLng(endMarker.getLatLng())
//             .setContent(popupContent)
//             .openOn(map);
//         });
//       length > 2 &&
//         currentMarker.on("click", () => {
//           const popupContent = route.popupContent.split(",").join("<br>");
//           L.popup()
//             .setLatLng(currentMarker.getLatLng())
//             .setContent(popupContent)
//             .openOn(map);
//         });
//     });
//   }, [map, InputList, Inline_DefaultIcon]);
//   return null;
// };

// export default LeafletRoutingMachine;

import React, { useEffect } from "react";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { dakar_uk, dammam_said, r, r2, said_istanbul, uk_rot } from "./mapView";

interface Coordinate {
  lat: number;
  lng: number;
}

interface Route {
  waypoints: Coordinate[];
  popupContent: string;
}

interface LeafletRoutingMachineProps {
  InputList: Route[];
  color: string;
}

const LeafletRoutingMachine = ({ InputList }: LeafletRoutingMachineProps) => {
  const map = useMap();

  const Inline_DefaultIcon = L.icon({
    iconUrl: require("./icon/location-pin.png"),
    iconSize: [50, 50],
  });

  const Current_DefaultIcon = L.icon({
    iconUrl: require("./icon/port-blue.png"),
    iconSize: [55, 55],
  });

  useEffect(() => {
    map.eachLayer((layer) => {
      if (layer instanceof L.Marker || layer instanceof L.Polyline) {
        map.removeLayer(layer);
      }
    });
    InputList.forEach((route: Route) => {
      const length = route.waypoints.length;
      // Create a function to handle marker and line click events

      const startMarker = L.marker(route.waypoints[0], {
        icon: Inline_DefaultIcon,
      }).addTo(map);

      const endMarker = L.marker(route.waypoints[length - 1], {
        icon: Inline_DefaultIcon,
      }).addTo(map);

      let currentMarker: L.Marker | null = null;
      if (length === 3) {
        currentMarker = L.marker(route.waypoints[1], {
          icon: Current_DefaultIcon,
        }).addTo(map);
      }

      // Choose the route based on the start point
      let polylineCoordinates: Coordinate[] = [];

      switch (startMarker.getLatLng().lat) {
        case 51.4631174:
          polylineCoordinates = [...r, ...r2].map((coord) => ({
            lat: coord[1],
            lng: coord[0],
          }));
          break;
        case 41.006381:
          polylineCoordinates = [...dammam_said, ...said_istanbul].map(
            (coord) => ({ lat: coord[1], lng: coord[0] })
          );
          break;
        case 41.0766019:
          polylineCoordinates = [...dammam_said, ...said_istanbul].map(
            (coord) => ({ lat: coord[1], lng: coord[0] })
          );
          break;

        case 43.2573529:
          polylineCoordinates = [...dammam_said, ...said_istanbul].map(
            (coord) => ({ lat: coord[1], lng: coord[0] })
          );
          break;
        case 14.693425:
          polylineCoordinates = [...dakar_uk, ...uk_rot].map((coord) => ({
            lat: coord[1],
            lng: coord[0],
          }));
          break;
        default:
          polylineCoordinates = [
            startMarker.getLatLng(),
            endMarker.getLatLng(),
          ];
          break;
      }

      // Draw the polyline using the selected coordinates
      L.polyline(polylineCoordinates, {
        color: "#004c97",
        weight: 4,
        opacity: 0.7,
      }).addTo(map);

      // Popups for markers
      endMarker.on("click", () => {
        const popupContent = route.popupContent.split(",").join("<br>");

        L.popup()
          .setLatLng(endMarker.getLatLng())
          .setContent(popupContent)
          .openOn(map);
      });
      currentMarker?.on("click", () => {
        const formattedContent = route.popupContent
          .split(",")
          .map((item) => {
            // Split by colon
            const [key, value] = item.split(":").map((part) => part.trim());
            if (value) {
              return `<div><strong class="key">${key}:</strong> <span class="value">${value}</span></div>`;
            }
            return item; // In case there's no value after the colon
          })
          .join("");

        const popupContent = `<div class="custom-popup">${formattedContent}</div>`;
        const latLng = currentMarker?.getLatLng(); // This will be undefined if currentMarker is null

        if (latLng) {
          L.popup()
            .setLatLng(latLng) // Set LatLng safely
            .setContent(popupContent)
            .openOn(map);
        }
      });
    });

    // Cleanup markers and polylines on unmount
    return () => {
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker || layer instanceof L.Polyline) {
          map.removeLayer(layer);
        }
      });
    };
  }, [map, InputList]);

  return null;
};

export default LeafletRoutingMachine;
