import React from "react";
import { Form, Input, Select, Collapse, FormInstance } from "antd";
import { WORK_MODE } from "../../../../../constants/workModes";
import { useTranslation } from "react-i18next";
import { languages } from "../../../../../constants/languages";
import { getTranslation } from "../../../../../utils/transaltion";

const { Panel } = Collapse;

interface UserDetailsProps {
  workModeUser: string;
  record: any;
  globalContext: any;
  formDetailsUser: FormInstance<any>;
}

const UserDetails: React.FC<UserDetailsProps> = ({
  workModeUser,
  record,
  globalContext,
  formDetailsUser,
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        marginTop: 20,
        width: "100%",
        marginBottom: "20px",
      }}
    >
      <Collapse
        className="panel-style"
        accordion
        expandIconPosition="end"
        size="large"
        destroyInactivePanel={true}
        defaultActiveKey={["user-panel"]}
      >
        <Panel
          className="panel-style"
          header={<span> {t("DETAILS", { item: t("") })} </span>}
          key="user-panel"
        >
          <Form
            form={formDetailsUser}
            initialValues={
              workModeUser === WORK_MODE.CREATE
                ? {
                    firstname: "",
                    lastname: "",
                    email: "",
                    preferredLanguage: "en-US",
                  }
                : workModeUser === WORK_MODE.UPDATE ||
                  workModeUser === WORK_MODE.VIEW
                ? {
                    firstname: record?.firstname,
                    lastname: record?.lastname,
                    email: record?.email,
                    preferredLanguage: record?.preferredLanguage,
                  }
                : {
                    firstname: globalContext?.context?.user?.firstname,
                    lastname: globalContext?.context?.user?.lastname,
                    email: globalContext?.context?.user?.email,
                    preferredLanguage:
                      globalContext?.context?.user?.preferredLanguage,
                  }
            }
          >
            <Form.Item
              {...{ labelCol: { style: { width: 100 } } }}
              label={t("USER_FIRST_NAME")}
              name="firstname"
            >
              <Input readOnly={true} disabled={true} />
            </Form.Item>
            <Form.Item
              {...{ labelCol: { style: { width: 100 } } }}
              label={t("USER_LAST_NAME")}
              name="lastname"
            >
              <Input readOnly={true} disabled={true} />
            </Form.Item>
            <Form.Item
              {...{ labelCol: { style: { width: 100 } } }}
              label={t("EMAIL")}
              name="email"
              rules={[
                {
                  required: true,
                  message: t(`errors:REQUIRED`, {
                    label: t(`labels:EMAIL`),
                  }),
                },
              ]}
            >
              <Input
                readOnly={workModeUser === WORK_MODE.VIEW}
                disabled={workModeUser === WORK_MODE.CREATE ? false : true}
              />
            </Form.Item>
            <Form.Item
              {...{ labelCol: { style: { width: 100 } } }}
              label={t("LANGUAGE")}
              name="preferredLanguage"
              rules={[{ required: true }]}
            >
              <Select disabled={workModeUser === WORK_MODE.VIEW}>
                {Object.entries(languages).map(([id, name]) => (
                  <Select.Option key={id} value={id}>
                    {getTranslation(name, "labels")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
    </div>
  );
};

export default UserDetails;
