import "./runningCss.css";
import { useParams } from "react-router-dom";
import RunningRiskList from "./view_tables/RunningRiskList";
import { createKey } from "../../../utils/transaltion";
import RunningRiskListDemo from "../runnings-demo/view_tables/RunningRiskListDemo";
import { SharedContextProviderRunningDemo } from "../runnings-demo/context/SharedContext";

const RunningComponent = (props: any) => {
  const { menu } = props;
  /**local vars */

  const { copilotName } = useParams();

  return (
    <>
      {createKey(copilotName || "") === createKey("fulfillment") ||
      createKey(copilotName || "") === createKey("demand matching") ||
      createKey(copilotName || "") === createKey("supply matching") ||
      createKey(copilotName || "") ===
        createKey("inbound freight management") ? (
        <SharedContextProviderRunningDemo>
          {" "}
          <RunningRiskListDemo copilotName={copilotName} menu={menu} />
        </SharedContextProviderRunningDemo>
      ) : (
        <RunningRiskList copilotName={copilotName} menu={menu} />
      )}
    </>
  );
};

export default RunningComponent;
