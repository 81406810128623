import React, { useEffect, useState } from "react";
import {
  LayersControl,
  MapContainer,
  Polyline,
  TileLayer,
  useMap,
} from "react-leaflet";
import LeafletRoutingMachine from "./LeafletRoutingMachine";
import "./map.css";
import "leaflet/dist/leaflet.css";

interface Coordinate {
  lat: number;
  lng: number;
}



function MapComponent(props: any) {
  const { InputList } = props;
  const [center,setCenter]=useState(InputList?.at(0)?.waypoints[1])
  useEffect(()=>{
    console.log("change in map",InputList)
    setCenter(InputList?.at(0)?.waypoints[1])
  },[InputList])
  return (
    <MapContainer center={center} zoom={6}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
            <MapCenterUpdater center={center} />
      <LeafletRoutingMachine
        InputList={InputList}
        color="blue"
      />
    </MapContainer>
  );
}
// Component to handle updating the map center
function MapCenterUpdater({ center }: { center: [number, number] }) {
  const map = useMap();

  useEffect(() => {
    if (center) {
      map.setView(center);
    }
  }, [center, map]);

  return null;
}
export default MapComponent;
export const r = [
  [4.47917, 51.9225],
  [4.478634687128303, 51.908246449485944],
  [4.475538997734738, 51.90267219362463],
  [4.469704494195403, 51.901026048911376],
  [4.465813074788718, 51.90017774686677],
  [4.463864739514683, 51.900127287490804],
  [4.459762689862302, 51.90016700991023],
  [4.453506925831576, 51.90029691412503],
  [4.445581955539517, 51.900992810004425],
  [4.435987778986124, 51.902254697548415],
  [4.429068660936707, 51.90266107661308],
  [4.424824601391265, 51.90221194719842],
  [4.41993583781575, 51.901453181019846],
  [4.414402370210162, 51.90038477807737],
  [4.409426261616343, 51.89938825072292],
  [4.4050075120342935, 51.8984635989565],
  [4.4010066512017385, 51.89771622822104],
  [4.397423679118678, 51.89714613851654],
  [4.392884921303526, 51.89696782371233],
  [4.387390377756283, 51.89718128380842],
  [4.383746110085113, 51.897390515620145],
  [4.381952118290016, 51.8975955191475],
  [4.377094837220349, 51.89814905335594],
  [4.369174266876111, 51.899051118245445],
  [4.362735269049497, 51.89937055645076],
  [4.357777843740508, 51.89910736797189],
  [4.353942852550354, 51.89868063417272],
  [4.351230295479034, 51.89809035505327],
  [4.3472776033762415, 51.89700677774045],
  [4.342084776241975, 51.89542990223426],
  [4.334936787722739, 51.894483219985844],
  [4.325833637818533, 51.894166730995195],
  [4.318878062007101, 51.89418651140016],
  [4.314070060288441, 51.89454256120073],
  [4.305896457372325, 51.895215084910646],
  [4.2943572532587515, 51.896204082529906],
  [4.280942148725536, 51.89987348061356],
  [4.265651143772679, 51.9062232791616],
  [4.252717574862636, 51.91127134675938],
  [4.242141441995407, 51.91501768340688],
  [4.233537442675333, 51.91976715972703],
  [4.226905576902413, 51.92551977571983],
  [4.204701046424606, 51.93698615056599],
  [4.16692385124191, 51.95416628426553],
  [4.143449871842407, 51.965198372194344],
  [4.134279108226096, 51.970082414352426],
  [4.122671228562702, 51.9733908607507],
  [4.108626232852224, 51.97512371138916],
  [4.080755803472393, 51.98170893501923],
  [4.039059940423211, 51.993146531640896],
  [4.003398873484408, 51.995711607084466],
  [3.973772602655986, 51.98940416134994],
  [3.941526520100951, 51.97809745393001],
  [3.906660625819304, 51.961791484824694],
  [3.878704567698992, 51.946436843056304],
  [3.8576583457400155, 51.932033528624835],
  [3.85779869774863, 51.90695078595808],
  [3.879125623724835, 51.87118861505604],
  [3.8737361417961793, 51.84970654433041],
  [3.8416302519626626, 51.84250457378119],
  [3.8175410383506403, 51.836065855574894],
  [3.8014685009601124, 51.83039038971152],
  [3.775063618100461, 51.82187504495006],
  [3.738326389771686, 51.81051982129053],
  [3.7101994493316326, 51.795787600452925],
  [3.690682796780301, 51.77767838243726],
  [3.6803504513111704, 51.76364952383076],
  [3.6792024129242407, 51.75370102463342],
  [3.6803504513111704, 51.7448168982871],
  [3.68379456647196, 51.736997144791786],
  [3.68580363364687, 51.73113182214122],
  [3.686377652835901, 51.727220930335406],
  [3.6846555952555065, 51.72206493395579],
  [3.680637460905686, 51.71566383300238],
  [3.6766193265558442, 51.70783866535246],
  [3.672601192205981, 51.698589431006035],
  [3.669157077049597, 51.69040612927737],
  [3.6662869810866923, 51.68328876016647],
  [3.6631298755292576, 51.676170272013024],
  [3.659685760377293, 51.669050664817036],
  [3.655093606838406, 51.662820274071414],
  [3.6493534149125963, 51.65747909977616],
  [3.6467703285442212, 51.6517810860861],
  [3.6473443477332808, 51.64572623300124],
  [3.652159700826388, 51.636624671457994],
  [3.6612163878235435, 51.62447640145636],
  [3.6604388562119183, 51.61658985202824],
  [3.6498271059915126, 51.61296502317363],
  [3.639776183099613, 51.60967918710353],
  [3.63028608753622, 51.606732343817946],
  [3.617674250003887, 51.604793591390475],
  [3.6019406705026142, 51.60386292982112],
  [3.5874557877904465, 51.60277712831627],
  [3.5742196018673837, 51.601536186875926],
  [3.5599844585145846, 51.60029521152115],
  [3.544750357732049, 51.59905420225194],
  [3.529516256949506, 51.59657198023083],
  [3.5142821561669564, 51.592848545457805],
  [3.4965506618138775, 51.588736845636],
  [3.4763217738902696, 51.58423688076541],
  [3.457216713076093, 51.579193196610234],
  [3.439235479371348, 51.57360579317047],
  [3.4254111119466186, 51.568990221787146],
  [3.415743610801904, 51.56534648246026],
  [3.418628817978351, 51.5503087194876],
  [3.4340667334759583, 51.52387693286919],
  [3.4556142684185716, 51.50429577966999],
  [3.4832714228061903, 51.491565259889995],
  [3.5037084857835312, 51.47709066705955],
  [3.5169254573505935, 51.46087200117864],
  [3.530876488025987, 51.45121201103345],
  [3.545561577809712, 51.44811069662396],
  [3.5595403112369723, 51.443046005773866],
  [3.572812688307769, 51.43601793848319],
  [3.5865295815694873, 51.43313139501009],
  [3.600690991022127, 51.43438637535459],
  [3.6127787718113353, 51.43531039914513],
  [3.622792923937112, 51.43590346638171],
  [3.6375910782982412, 51.43753055867692],
  [3.657173234894724, 51.44019167603075],
  [3.6783065680311586, 51.43509094398483],
  [3.700991077707546, 51.42222836253915],
  [3.7212318290172206, 51.4115543136631],
  [3.739028821960183, 51.40306879735668],
  [3.7605453790297574, 51.39451057037725],
  [3.7857815002259443, 51.38587963272481],
  [3.805921866182409, 51.37974663729733],
  [3.8209664768991516, 51.376111584094815],
  [3.834311862913111, 51.37284038726437],
  [3.8459580242242875, 51.369933046806],
  [3.859305328961002, 51.370585481598575],
  [3.874353777123254, 51.37479769164211],
  [3.8851525647549394, 51.378220335993035],
  [3.891701691856059, 51.38085341465135],
  [3.898276107741154, 51.38536645596889],
  [3.904875812410224, 51.391759459945646],
  [3.9114041276479488, 51.400586108239175],
  [3.9178610534543266, 51.41184640084947],
  [3.9244859207085243, 51.423141027916515],
  [3.9312787294105416, 51.4344699894403],
  [3.939067059852083, 51.441609795069326],
  [3.947850912033148, 51.444560444803564],
  [3.9561253183097236, 51.44573329548147],
  [3.9638902786818093, 51.44512834710303],
  [3.977967390743519, 51.44272195787621],
  [3.9983566544948514, 51.43851412780101],
  [4.014520840472957, 51.43329844106935],
  [4.026459948677836, 51.42707489768123],
  [4.038243512329068, 51.42012301082893],
  [4.049871531426653, 51.41244278051245],
  [4.061639537043725, 51.405092307761684],
  [4.073547529180281, 51.398071592576635],
  [4.0838852366412794, 51.39047806858573],
  [4.092652659426719, 51.38231173578897],
  [4.099223841398052, 51.375731590832004],
  [4.103598782555281, 51.37073763371485],
  [4.126533890042765, 51.3734163103346],
  [4.168029163860507, 51.38376762069126],
  [4.1937031996801455, 51.3881680543161],
  [4.203555997501682, 51.386617611209125],
  [4.213399597954761, 51.379525374580716],
  [4.223234001039385, 51.36689134443088],
  [4.234855051704008, 51.35752274778631],
  [4.248262749948629, 51.35141958464701],
  [4.25723423032313, 51.34745001274604],
  [4.261769492827508, 51.34561403208338],
  [4.265223170510884, 51.34420123874497],
  [4.267595263373259, 51.343211632730785],
  [4.268983286289552, 51.34172473775325],
  [4.269387239259763, 51.33974055381235],
  [4.271295993623444, 51.3299890027997],
  [4.274709549380595, 51.312470084715294],
  [4.287570466663055, 51.299140122064145],
  [4.309878745470825, 51.28999911484625],
  [4.321946488908195, 51.28483205574994],
  [4.323773696975167, 51.28363894477519],
  [4.328379653432075, 51.28065920806526],
  [4.3357643582789205, 51.27589284562015],
  [4.343028935043632, 51.270105590156454],
  [4.35017338372621, 51.26329744167418],
  [4.355099839105442, 51.25849058508326],
  [4.357808301181329, 51.25568502038367],
  [4.359542770942518, 51.252486694627834],
  [4.360303248389009, 51.24889560781574],
  [4.366798139819771, 51.247110084159175],
  [4.379027445234804, 51.24713012365814],
  [4.386152586991578, 51.24704739908415],
  [4.388173565090092, 51.24686191043722],
  [4.393422170339867, 51.2457294151724],
  [4.401898402740903, 51.2436499132897],
  [4.404112818967099, 51.24182381353904],
  [4.400065419018456, 51.24025111592044],
  [4.3998062892831005, 51.23492357533335],
  [4.4051, 51.2213],
];
export const r2 = [
  [4.4051, 51.2213],
  [4.3998062892831005, 51.23492357533335],
  [4.400065419018456, 51.24025111592044],
  [4.404112818967099, 51.24182381353904],
  [4.401898402740903, 51.2436499132897],
  [4.393422170339867, 51.2457294151724],
  [4.388173565090092, 51.24686191043722],
  [4.386152586991578, 51.24704739908415],
  [4.379027445234804, 51.24713012365814],
  [4.366798139819771, 51.247110084159175],
  [4.360303248389009, 51.24889560781574],
  [4.359542770942518, 51.252486694627834],
  [4.357808301181329, 51.25568502038367],
  [4.355099839105442, 51.25849058508326],
  [4.35017338372621, 51.26329744167418],
  [4.343028935043632, 51.270105590156454],
  [4.3357643582789205, 51.27589284562015],
  [4.328379653432075, 51.28065920806526],
  [4.323773696975167, 51.28363894477519],
  [4.321946488908195, 51.28483205574994],
  [4.309878745470825, 51.28999911484625],
  [4.287570466663055, 51.299140122064145],
  [4.274709549380595, 51.312470084715294],
  [4.271295993623444, 51.3299890027997],
  [4.269387239259763, 51.33974055381235],
  [4.268983286289552, 51.34172473775325],
  [4.267595263373259, 51.343211632730785],
  [4.265223170510884, 51.34420123874497],
  [4.261769492827508, 51.34561403208338],
  [4.25723423032313, 51.34745001274604],
  [4.248262749948629, 51.35141958464701],
  [4.234855051704008, 51.35752274778631],
  [4.223234001039385, 51.36689134443088],
  [4.213399597954761, 51.379525374580716],
  [4.203555997501682, 51.386617611209125],
  [4.1937031996801455, 51.3881680543161],
  [4.168029163860507, 51.38376762069126],
  [4.126533890042765, 51.3734163103346],
  [4.103598782555281, 51.37073763371485],
  [4.099223841398052, 51.375731590832004],
  [4.092652659426719, 51.38231173578897],
  [4.0838852366412794, 51.39047806858573],
  [4.073547529180281, 51.398071592576635],
  [4.061639537043725, 51.405092307761684],
  [4.049871531426653, 51.41244278051245],
  [4.038243512329068, 51.42012301082893],
  [4.026459948677836, 51.42707489768123],
  [4.014520840472957, 51.43329844106935],
  [3.9983566544948514, 51.43851412780101],
  [3.977967390743519, 51.44272195787621],
  [3.9638902786818093, 51.44512834710303],
  [3.9561253183097236, 51.44573329548147],
  [3.947850912033148, 51.444560444803564],
  [3.939067059852083, 51.441609795069326],
  [3.9312787294105416, 51.4344699894403],
  [3.9244859207085243, 51.423141027916515],
  [3.9178610534543266, 51.41184640084947],
  [3.9114041276479488, 51.400586108239175],
  [3.904875812410224, 51.391759459945646],
  [3.898276107741154, 51.38536645596889],
  [3.891701691856059, 51.38085341465135],
  [3.8851525647549394, 51.378220335993035],
  [3.874353777123254, 51.37479769164211],
  [3.859305328961002, 51.370585481598575],
  [3.8459580242242875, 51.369933046806],
  [3.834311862913111, 51.37284038726437],
  [3.8209664768991516, 51.376111584094815],
  [3.805921866182409, 51.37974663729733],
  [3.7857815002259443, 51.38587963272481],
  [3.7605453790297574, 51.39451057037725],
  [3.739028821960183, 51.40306879735668],
  [3.7212318290172206, 51.4115543136631],
  [3.700991077707546, 51.42222836253915],
  [3.6783065680311586, 51.43509094398483],
  [3.657173234894724, 51.44019167603075],
  [3.6375910782982412, 51.43753055867692],
  [3.622792923937112, 51.43590346638171],
  [3.6127787718113353, 51.43531039914513],
  [3.600690991022127, 51.43438637535459],
  [3.5865295815694873, 51.43313139501009],
  [3.572812688307769, 51.43601793848319],
  [3.5595403112369723, 51.443046005773866],
  [3.545561577809712, 51.44811069662396],
  [3.530876488025987, 51.45121201103345],
  [3.5169254573505935, 51.46087200117864],
  [3.5037084857835312, 51.47709066705955],
  [3.4832714228061903, 51.491565259889995],
  [3.4556142684185716, 51.50429577966999],
  [3.4340667334759583, 51.52387693286919],
  [3.418628817978351, 51.5503087194876],
  [3.358926461097237, 51.58814488945585],
  [3.254959662832617, 51.63738544277393],
  [3.0828291951294173, 51.69426393835815],
  [2.8425350579876376, 51.75878037620849],
  [2.712529605622371, 51.795295020513166],
  [2.692812838033617, 51.803807871272156],
  [2.6515454015866595, 51.81887388549658],
  [2.5887272962814993, 51.840493063186436],
  [2.399429407393076, 51.798947304976814],
  [2.0836517349213906, 51.69423661086771],
  [1.8933330513442075, 51.63445503035609],
  [1.8284733566615265, 51.619602563441944],
  [1.7868664680063986, 51.608284847941164],
  [1.7685123853788236, 51.600501883853745],
  [1.7549041038865203, 51.59582927868384],
  [1.7460416235294887, 51.59426703243144],
  [1.7371133356569248, 51.59203862984277],
  [1.7281192402688283, 51.589144070917826],
  [1.634766812509934, 51.559091338375694],
  [1.4570560523802414, 51.50188043221636],
  [1.3528744143833469, 51.475794003864216],
  [1.3222218985192504, 51.48083205331926],
  [1.2424396428466644, 51.48500225260725],
  [1.1135276473655888, 51.48830460172819],
  [1.0152537372187882, 51.487566832216494],
  [0.9476179124062627, 51.48278894407217],
  [0.8954249999999999, 51.482225],
  [0.8586750000000001, 51.485875],
  [0.8216300013003256, 51.48792660763101],
  [0.7842900039009766, 51.48837982289302],
  [0.7509900039009766, 51.489529822893026],
  [0.7071, 51.4923],
  [0.6525430866058811, 51.50530481131392],
  [0.5545692388319577, 51.50345096620628],
  [0.5143660987849996, 51.50109668549277],
  [0.49844025631632394, 51.49992818992991],
  [0.4933906647436114, 51.49916071927987],
  [0.4846255153491423, 51.497160757936165],
  [0.46590445452480367, 51.492312079880094],
  [0.44203091754357615, 51.45754607043679],
  [0.41244118156890863, 51.448536096313575],
  [0.3613316376203386, 51.448326540877645],
  [0.35856, 51.46248],
];
export const dammam_said = [
  [50.15618865472411, 26.631358806327626],
  [50.16188653532196, 26.626265305853224],
  [50.18672281564875, 26.62238958092124],
  [50.23069749570447, 26.61973163153167],
  [50.29810694596669, 26.618185287430425],
  [50.388951166435405, 26.6177505486175],
  [50.4507026571663, 26.616645936161],
  [50.48336141815939, 26.614871450060928],
  [50.51601916130306, 26.613089508661684],
  [50.54867588659732, 26.61130011196327],
  [50.58133158575043, 26.609503261594877],
  [50.613986258762395, 26.607698957556508],
  [50.64663989734885, 26.6058872014906],
  [50.67929250150979, 26.604067993397152],
  [50.7119440629684, 26.602241334931854],
  [50.74459458172467, 26.6004072260947],
  [50.777244049509335, 26.5985656685546],
  [50.809892466322395, 26.596716662311554],
  [50.84253982390214, 26.594860209047678],
  [50.87518612224858, 26.592996308762974],
  [50.907831353107696, 26.591124963152772],
  [50.940475516479495, 26.589246172217067],
  [50.973118604117644, 26.587359937664385],
  [51.005760616022144, 26.585466259494716],
  [51.038401543954436, 26.583565139429766],
  [51.071041387914505, 26.581656577469538],
  [51.11229790601439, 26.56728595338704],
  [51.16217109825409, 26.540453267182276],
  [51.21202096852738, 26.513603249621717],
  [51.26184751683428, 26.48673590070537],
  [51.31165078967376, 26.459851277252696],
  [51.36143078704583, 26.43294937926371],
  [51.41118755561769, 26.406030263463848],
  [51.460921095389324, 26.379093929853116],
  [51.51063145319449, 26.352140435062815],
  [51.56031862903319, 26.32516977909295],
  [51.609982669903644, 26.29818201848056],
  [51.65962357580585, 26.271177153225647],
  [51.70924139390115, 26.244155239770883],
  [51.75883612418954, 26.217116278116265],
  [51.80840781399337, 26.190060324609988],
  [51.85795646331263, 26.16298737925206],
  [51.90748211962918, 26.13589749829608],
  [51.95698478294301, 26.108790681742065],
  [52.00646450089367, 26.081666985748946],
  [52.055921273481154, 26.054526410316733],
  [52.116747508062424, 26.047936463017336],
  [52.18894320463748, 26.061897143850764],
  [52.261156072966315, 26.075821897557013],
  [52.33338611304893, 26.089710724136083],
  [52.40563326530662, 26.103563562375275],
  [52.47789752973938, 26.117380412274592],
  [52.55017884620821, 26.13116121284724],
  [52.622477214713115, 26.14490596409322],
  [52.69479257455549, 26.158614605253412],
  [52.767124925735345, 26.172287136327817],
  [52.839474206995135, 26.185923496786735],
  [52.91184041833486, 26.199523686630165],
  [52.9842234979387, 26.213087645559572],
  [53.05662344580665, 26.22661537357496],
  [53.12904019956548, 26.240106810610712],
  [53.20147375921519, 26.25356195666683],
  [53.27392406182568, 26.266980751912374],
  [53.346391107396954, 26.28036319634734],
  [53.41887483244314, 26.29370923037721],
  [53.49137523696423, 26.307018854001985],
  [53.60669407941858, 26.299030249360776],
  [53.764831359806195, 26.269743416453593],
  [53.88532492922127, 26.252019597278164],
  [53.968174787663806, 26.245858791834493],
  [54.05787919159049, 26.218085194228028],
  [54.15443814100132, 26.168698804458764],
  [54.27779083120095, 26.15507095534426],
  [54.4279372621894, 26.177201646884512],
  [54.570080591992934, 26.180307494363884],
  [54.771290934920856, 26.156428999491617],
  [55.015126662942855, 26.137746596156823],
  [55.2894, 26.1146],
  [55.45034881792489, 26.16041463474294],
  [55.538798817924885, 26.18666463474294],
  [55.60839960597497, 26.208621544914312],
  [55.695770310704816, 26.237461796533033],
  [55.80091093211445, 26.273185389599092],
  [55.89823830666717, 26.306128523329384],
  [55.98775243436298, 26.336291197723895],
  [56.11967343763369, 26.391657085508186],
  [56.29400131647932, 26.472226186682253],
  [56.40484572208775, 26.522263296682038],
  [56.475887120644614, 26.551520974920294],
  [56.548029759410646, 26.54669418226719],
  [56.59612431796062, 26.489138434408446],
  [56.63189764251808, 26.43936724655223],
  [56.671381252599666, 26.378195369412293],
  [56.79002211175512, 26.19639102237442],
  [57.08671927409909, 25.742735796970717],
  [57.136587417105346, 25.66575113986175],
  [57.24508209641767, 25.543990391082772],
  [57.37073931248631, 25.45670240061495],
  [57.444646195642434, 25.410846785740716],
  [57.508688484575586, 25.377327549637453],
  [57.58546830197223, 25.310615582502354],
  [57.674985647832365, 25.210710884335413],
  [57.764502993692496, 25.110806186168475],
  [57.85402033955263, 25.01090148800153],
  [57.970881214588715, 24.911105622000818],
  [58.11508561880074, 24.81141858816634],
  [58.259290023012774, 24.711731554331855],
  [58.4034944272248, 24.612044520497378],
  [58.48532375578626, 24.546034563654924],
  [58.50477800869716, 24.513701683804502],
  [58.524222260891236, 24.48136631408147],
  [58.5436565123685, 24.44902845448583],
  [58.563080801737414, 24.416688115011056],
  [58.58249512899798, 24.38434529565714],
  [58.601899532650236, 24.352000006385985],
  [58.62129401269419, 24.319652247197578],
  [58.64067860752198, 24.287302028022395],
  [58.66005331713359, 24.254949348860443],
  [58.67941817981364, 24.22259421961091],
  [58.698773195562126, 24.190236640273806],
  [58.7181184025568, 24.15787662071723],
  [58.73745380079765, 24.125514160941176],
  [58.75677942835583, 24.093149270782796],
  [58.77609528523132, 24.060781950242085],
  [58.79540140938937, 24.028412209125396],
  [58.814697800829975, 23.99604004743273],
  [58.83398449741271, 23.963665474939795],
  [58.853261499137574, 23.931288491646598],
  [58.87201356454307, 23.90574959249954],
  [58.89024069362919, 23.887048777498627],
  [58.908462556556515, 23.86834581748556],
  [58.92667915332504, 23.849640712460353],
  [58.94489049505316, 23.830933467442055],
  [58.96309658174087, 23.81222408243067],
  [58.98129742449473, 23.793512562436966],
  [58.99949302331473, 23.774798907460944],
  [59.01768338929557, 23.7560831225051],
  [59.035868522437255, 23.73736520756944],
  [59.054048433822516, 23.7186451676482],
  [59.07222312345135, 23.699923002741393],
  [59.090392602394644, 23.681198717835027],
  [59.10855687065239, 23.66247231292911],
  [59.126715939283685, 23.64374379300145],
  [59.14486980828852, 23.625013158052045],
  [59.163018488714044, 23.606280413050513],
  [59.18116198056026, 23.587545557996847],
  [59.199300294862525, 23.568808597852513],
  [59.21743343162085, 23.550069532617506],
  [59.275150000000004, 23.446375],
  [59.37245, 23.257725],
  [59.422700000000006, 23.153675],
  [59.4259, 23.134225],
  [59.5411950283749, 23.00840763455348],
  [59.88228011349961, 22.660130538213913],
  [59.97682962417403, 22.544801525594167],
  [59.981928734221285, 22.42564426064531],
  [59.83187132817778, 22.049718879246768],
  [59.528357109392594, 21.377306293082256],
  [59.27725, 20.8776],
  [59.07855, 20.5506],
  [58.981525, 20.2891],
  [58.986175, 20.0931],
  [58.96736030998974, 19.967578967011292],
  [58.92508092996921, 19.912536901033867],
  [58.88283091667009, 19.85748486993811],
  [58.840610270092355, 19.802422873724005],
  [58.79841878396951, 19.74735098595255],
  [58.75625645830155, 19.692269206623735],
  [58.71412308754023, 19.637177608975783],
  [58.67201867168555, 19.582076193008692],
  [58.62994300590276, 19.526965031640604],
  [58.58789609019186, 19.471844124871517],
  [58.54587772042672, 19.416713545302176],
  [58.503887896607324, 19.361573292932576],
  [58.46192641531127, 19.30642344004874],
  [58.41999327653855, 19.251263986650663],
  [58.37808827756575, 19.196095004712276],
  [58.33621141839288, 19.140916494233576],
  [58.29436249699046, 19.08572852687904],
  [58.252541513358494, 19.030531102648673],
  [58.210748266156884, 18.975324292900115],
  [58.16898275538563, 18.92010809763337],
  [58.123890769544886, 18.87060522579172],
  [58.07547230863466, 18.82681567737517],
  [58.0270790438517, 18.7830136566432],
  [57.97871097519601, 18.739199163595817],
  [57.93036796463127, 18.695372273600835],
  [57.88205001215749, 18.651532986658257],
  [57.8337569799441, 18.607681377906392],
  [57.78548886799109, 18.56381744734524],
  [57.73724553867359, 18.51994126988493],
  [57.6890269919916, 18.476052845525462],
  [57.640833090525746, 18.432152248950125],
  [57.59266383427604, 18.388239480158916],
  [57.54451908602857, 18.344314613609594],
  [57.49639884578335, 18.300377649302163],
  [57.44830297653175, 18.25642866147019],
  [57.40023147827378, 18.21246765011368],
  [57.35218421420592, 18.168494689243335],
  [57.304161184328166, 18.124509778859156],
  [57.25616225204197, 18.0805129927503],
  [57.20818741734732, 18.03650433091677],
  [57.14663317955416, 17.97887833827967],
  [57.071499538662486, 17.907635014839013],
  [56.99642612295182, 17.836362960122724],
  [56.92141293242216, 17.765062174130808],
  [56.84645940676327, 17.693732946982028],
  [56.771565545975164, 17.622375278676383],
  [56.69673079122353, 17.55098945793662],
  [56.62195514250837, 17.479575484762737],
  [56.547238042466006, 17.408133646495468],
  [56.47257949109644, 17.336663943134816],
  [56.39797893250099, 17.26516666065345],
  [56.32343636667966, 17.193641799051367],
  [56.24895123919268, 17.122089642947095],
  [56.17452355004005, 17.05051019234063],
  [56.1001527462349, 16.978903730510197],
  [56.02583882777722, 16.907270257455785],
  [55.95158124312658, 16.835610055129134],
  [55.87737999228298, 16.763923123530237],
  [55.80323452514589, 16.69220974329809],
  [55.729144841715296, 16.620469914432697],
  [55.49773524030756, 16.49537843227792],
  [55.10900572092269, 16.316935296833762],
  [54.720982421239825, 16.13778707268447],
  [54.33366534125898, 15.957933759830048],
  [53.94704083794164, 15.777394308029507],
  [53.56110891128782, 15.596168717282852],
  [53.175855584895714, 15.414275836620392],
  [52.79128085876533, 15.231715666042131],
  [52.40737043949417, 15.048506952492225],
  [52.024124327082234, 14.864649695970677],
  [51.64152792697038, 14.68016254032065],
  [51.25958123915862, 14.495045485542143],
  [50.87826938325401, 14.30931707169124],
  [50.497592359256544, 14.122977298767935],
  [50.117535015740486, 13.936044602670265],
  [49.738097352705836, 13.748518983398228],
  [49.35926396197411, 13.560418772622446],
  [48.9810348435453, 13.371743970342926],
  [48.60339434625039, 13.182512804221926],
  [48.22634247008937, 12.992725274259445],
  [47.95589173221915, 12.87395163600511],
  [47.79204213263973, 12.82619188945892],
  [47.62825471234689, 12.778330973169158],
  [47.464529471340626, 12.730368887135828],
  [47.300866140824496, 12.68230649098415],
  [47.1372647207985, 12.634143784714125],
  [46.97372493510204, 12.585881629110759],
  [46.810246783735124, 12.537520024174054],
  [46.646829983241474, 12.489059831811412],
  [46.48347453362109, 12.440501052022835],
  [46.32018014419103, 12.391844547801398],
  [46.15694681495128, 12.343090319147095],
  [45.993774248061186, 12.294239230102582],
  [45.83066244352074, 12.245291280667857],
  [45.66761109640095, 12.19624733589969],
  [45.504620206701816, 12.147107395798082],
  [45.34168946247581, 12.097872326399102],
  [45.17881886372294, 12.048542127702753],
  [45.016008091546965, 11.999117666690221],
  [44.85325714594789, 11.949598943361512],
  [44.56954433781777, 11.90994634111124],
  [44.16486966715659, 11.880159859939408],
  [43.85160639769212, 12.022566753112223],
  [43.62975452942439, 12.337167020629686],
  [43.52406584826674, 12.50573210861091],
  [43.534540354219175, 12.528262017055894],
  [43.494691126693596, 12.563859130414887],
  [43.35943168518818, 12.636855607824382],
  [43.14005165167988, 13.091648739200844],
  [43.055501702913055, 13.250875080042256],
  [43.03265516105161, 13.266132340807438],
  [42.95242156380333, 13.328142740006415],
  [42.745990584850674, 13.491288046455571],
  [42.6533135230369, 13.566770245954388],
  [42.612977417269384, 13.660083848192334],
  [42.46816935431735, 13.792695962048084],
  [42.290341435531836, 14.152287294445374],
  [42.03122430659551, 14.78306188333612],
  [41.875190715539816, 15.141229182950472],
  [41.82224066236476, 15.226789193288429],
  [41.76924740792194, 15.312336751018563],
  [41.716210952211355, 15.397871856140881],
  [41.66313072821677, 15.483394341516373],
  [41.610006735938185, 15.568904207145042],
  [41.556838405567845, 15.654401284934632],
  [41.50362573710575, 15.739885574885145],
  [41.4503681579144, 15.825356907936833],
  [41.397065667993786, 15.910815284089695],
  [41.343717691837526, 15.996260533302147],
  [41.290324229445616, 16.081692655574184],
  [41.236884702404154, 16.167111479867877],
  [41.18339911071314, 16.25251700618322],
  [41.12986687301154, 16.337909062471308],
  [41.076287989299345, 16.423287648732142],
  [41.02266187522915, 16.50865259189107],
  [40.96898853080094, 16.59400389194809],
  [40.915267368641025, 16.679341374787924],
  [40.861498388749396, 16.764665040410563],
  [40.50922872206369, 17.36923882550844],
  [39.858458368583904, 18.493062730081554],
  [39.292439745751516, 19.47247566797068],
  [38.81117285356653, 20.307477639175815],
  [38.53384886822454, 20.791308050597813],
  [38.460467789725534, 20.923966902236675],
  [38.33005859047132, 21.140631128128966],
  [38.14262127046188, 21.441300728274683],
  [37.95440268709751, 21.74175876875833],
  [37.765402840378215, 22.042005249579894],
  [37.57559205380994, 22.342031818758233],
  [37.38497032739269, 22.64183847629334],
  [37.193507278366326, 22.941416641427654],
  [37.00120290673084, 23.240766314161178],
  [36.808026094090934, 23.539878674113176],
  [36.61397684044661, 23.838753721283656],
  [36.41902326157751, 24.13738238437525],
  [36.22316535748362, 24.43576466338796],
  [36.026370447092916, 24.73389122434492],
  [35.828638530405385, 25.03176206724612],
  [35.62993609761995, 25.329367583179312],
  [35.4302631487366, 25.626707772144485],
  [35.22958531248557, 25.92377273752056],
  [35.02790258886685, 26.22056247930753],
  [34.8251797115321, 26.51706679985897],
  [34.62141668048133, 26.813285699174873],
  [34.42287373394695, 27.10493353897001],
  [34.13288944091998, 27.53554870938157],
  [33.8426283845597, 27.74126583708488],
  [33.72128291530626, 27.867766593699457],
  [33.46173023208254, 28.07998519976497],
  [33.36699436046866, 28.160485422437638],
  [33.350557739390055, 28.18000679707263],
  [33.27124360347504, 28.294718935966966],
  [33.05795612734793, 28.60957329069748],
  [32.63666468932248, 29.45924977493815],
  [32.57188695885495, 29.592667235640292],
  [32.5466068710993, 29.77463839338037],
  [32.55338350038646, 29.82832236167829],
  [32.55305567990508, 29.863844169887738],
  [32.545295589173776, 29.916725626218167],
  [32.577692960948156, 29.945481508960967],
  [32.58508624281563, 29.958550920103708],
  [32.586379235011805, 29.9756411008028],
  [32.585642867336304, 29.985766943494085],
  [32.576581090573825, 30.034126003558214],
  [32.57236631067559, 30.061853387667085],
  [32.570680398721635, 30.09978354112735],
  [32.57017663532176, 30.12402045077347],
  [32.56815153655688, 30.191958789257484],
  [32.5645063587622, 30.204210720463024],
  [32.536154975913604, 30.24620576274104],
  [32.52238430424359, 30.255652175406027],
  [32.519399248469995, 30.257566052854173],
  [32.456937461040155, 30.277266886169045],
  [32.44649163310993, 30.281501128474034],
  [32.44094894890429, 30.28499884300517],
  [32.37720808051216, 30.356398570284753],
  [32.375367581891595, 30.36104390635488],
  [32.369729586654984, 30.379806283350078],
  [32.357044097321676, 30.438328692926405],
  [32.35402374270163, 30.4438838927622],
  [32.3507241446122, 30.44675521055204],
  [32.33866407851099, 30.507731783680597],
  [32.333840052085776, 30.518814329111635],
  [32.30993701705237, 30.547816385659946],
  [32.30489589348352, 30.56174725586918],
  [32.30353987540332, 30.5722660867953],
  [32.305796985847486, 30.5827849177214],
  [32.324191999209916, 30.61642460041324],
  [32.34509611377004, 30.707024063151067],
  [32.34107609173631, 30.72084856613168],
  [32.33952962704943, 30.730311849053244],
  [32.31772901757271, 30.8065162283622],
  [32.315261236136635, 30.8830665989633],
  [32.30803233657551, 31.10384329866025],
  [32.305391350072924, 31.193250292960826],
  [32.304673870752815, 31.23346923082055],
  [32.30476263008696, 31.235784521845805],
  [32.30462763990543, 31.240395583396413],
  [32.305706015633156, 31.251146981521607],
];
export const said_istanbul = [
  [32.305706015633156, 31.251146981521607],
  [32.36805740879942, 31.321992398712617],
  [32.36810870600418, 31.43459455072504],
  [32.3661980774405, 31.502751782809575],
  [32.360414894544704, 31.59462132705076],
  [32.366656190907534, 31.731493885491716],
  [32.2654491210386, 31.77663064796864],
  [32.05887411705882, 31.77565580062852],
  [31.807007549205373, 31.90846391597356],
  [31.509849417478236, 32.17505499400376],
  [31.315873427153583, 32.3487314200792],
  [31.225079578231416, 32.42949319419989],
  [31.13412269437609, 32.510189568004854],
  [31.04300277558761, 32.59082054149409],
  [30.95171848460464, 32.67138547065548],
  [30.86026982142718, 32.751884355489025],
  [30.76865544255668, 32.83231654629478],
  [30.676875347993132, 32.91268204307272],
  [30.58492818803751, 32.99298019038069],
  [30.49281396268981, 33.073210988218676],
  [30.400531316090024, 33.15337377534761],
  [30.308080248238156, 33.2334685517675],
  [30.21545939715591, 33.31349465038764],
  [30.12266876284329, 33.39345207120803],
  [30.029706977249646, 33.473340141231375],
  [29.93657404037498, 33.55315886045766],
  [29.84326857814534, 33.63290754992792],
  [29.749790590560735, 33.71258620964214],
  [29.656138697576324, 33.79219415462444],
  [29.562312899192108, 33.87173138487481],
  [29.480676321241216, 33.959167206671154],
  [29.41122896372365, 34.054501620013475],
  [29.34186550226351, 34.13406263887576],
  [29.27258593686079, 34.197850263258005],
  [29.203201325609513, 34.26159880987653],
  [29.133711668509676, 34.32530827873131],
  [29.06411627215536, 34.38897836339619],
  [28.994415136546564, 34.45260906387114],
  [28.924607565255968, 34.516200071463174],
  [28.85469355828357, 34.57975138617228],
  [28.784672416186254, 34.64326269702034],
  [28.714544138964015, 34.70673400400733],
  [28.64430802416365, 34.77016499385159],
  [28.573964071785156, 34.83355566655311],
  [28.503511576372233, 34.89690570650819],
  [28.43295053792488, 34.96021511371683],
  [28.362280247990867, 35.023483570234774],
  [28.29150070657019, 35.08671107606203],
  [28.220611202223154, 35.14989731089512],
  [28.149611734949758, 35.21304227473405],
  [28.078501590331573, 35.276145644897476],
  [28.0072807683686, 35.33920742138539],
  [27.823280189301467, 35.51085134921359],
  [27.526499853130183, 35.791077428382074],
  [27.326105838600608, 35.97979493033618],
  [27.22209814571275, 36.07700385507593],
  [27.14774001368684, 36.145618613226056],
  [27.103031442522877, 36.18563920478657],
  [26.996525052622545, 36.27900096422891],
  [26.828220843985836, 36.42570389155307],
  [26.747129593711367, 36.573801649122665],
  [26.753251301799132, 36.72329423693768],
  [26.7204050743754, 36.856347198655044],
  [26.64859091144017, 36.97296053427476],
  [26.514843760781286, 37.091919139856024],
  [26.31916362239875, 37.21322301539885],
  [26.13784126916452, 37.326026492924996],
  [25.9708767010786, 37.430329572434474],
  [25.872732439622766, 37.52096477286363],
  [25.84340848479702, 37.597932094212474],
  [25.78632228278444, 37.687814881597276],
  [25.701473833585027, 37.790613135018035],
  [25.64736268978072, 37.85283918072125],
  [25.623988851371525, 37.8744930187069],
  [25.600601261396946, 37.89614222984542],
  [25.57719991985698, 37.917786814136775],
  [25.55378479498839, 37.9394267589081],
  [25.53035588679117, 37.961062064159385],
  [25.506913163446484, 37.98269271718273],
  [25.48345662495433, 38.00431871797812],
  [25.459986239440326, 38.02594005380255],
  [25.436502006904476, 38.047556724656005],
  [25.413003895416864, 38.06916871776019],
  [25.38949190497749, 38.09077603311513],
  [25.365966003600846, 38.11237865790719],
  [25.342426191286933, 38.13397659213639],
  [25.318872435994734, 38.15556982295366],
  [25.29530473772425, 38.17715835035899],
  [25.271723064378847, 38.19874216146777],
  [25.24812741595852, 38.220321256280016],
  [25.22451776031123, 38.24189562187544],
  [25.200894097436972, 38.26346525825406],
  [25.171748206795044, 38.29452942537066],
  [25.137080088385453, 38.33508812322526],
  [25.090719216971806, 38.39503993381875],
  [25.032665592554114, 38.474384857151136],
  [25.046179484273544, 38.586139705965806],
  [25.1312608921301, 38.730304480262745],
  [25.221073282615297, 38.877466027346884],
  [25.315616655729137, 39.02762434721819],
  [25.408156203091202, 39.17611828342137],
  [25.498691924701493, 39.32294783595639],
  [25.63015157590551, 39.52065799608607],
  [25.80253515670325, 39.769248763810396],
  [25.897585061129845, 39.89812432751893],
  [25.915301289185297, 39.90728468721165],
  [25.936872232000564, 39.92041619165529],
  [25.975010718363194, 39.94607016544711],
  [25.996171825198502, 39.96527037560563],
  [26.0376303202072, 39.98148704195819],
  [26.064405598244548, 39.988435863908535],
  [26.09895434409188, 39.998361526333895],
  [26.132639371290622, 40.00431623139383],
  [26.14742004966404, 40.00734621151618],
  [26.194947450434505, 40.01676556074016],
  [26.258075119864564, 40.02724597192527],
  [26.309539816696557, 40.059488109481144],
  [26.393787350577412, 40.14048590508847],
  [26.393765403195175, 40.19800160123822],
  [26.4773479209933, 40.22369573745022],
  [26.571588700482575, 40.28608152662303],
  [26.659699834193823, 40.355686714626124],
  [26.685894110131372, 40.384953829364626],
  [26.696901204300502, 40.391554800736685],
  [26.717871181504393, 40.402962008743515],
  [26.727031144067013, 40.41033165768019],
  [26.733253005434293, 40.41467424308498],
  [26.73959008646044, 40.41844631460345],
  [26.746330436276473, 40.42278826593482],
  [26.753474054882396, 40.42770009707909],
  [26.764419922105446, 40.4325238772406],
  [26.779168037945624, 40.437259606419346],
  [26.798294500672164, 40.44313480543602],
  [26.833551715091517, 40.45365680871791],
  [26.884248363272008, 40.47048814829492],
  [26.923653485277868, 40.48521211079114],
  [26.932179739740008, 40.488366825485514],
  [26.957642115826022, 40.48973161004725],
  [27.20378510432944, 40.572447211000934],
  [27.513627515239325, 40.674955606035525],
  [27.62464960870983, 40.71230729126768],
  [27.764305232787553, 40.7300170884201],
  [28.26343737795274, 40.78706248052844],
  [28.6943254825037, 40.83604118717278],
  [28.868203029773895, 40.86262634393299],
  [28.960448743806758, 40.930203463501684],
  [28.97873973557643, 40.938952543993956],
  [28.98749848521336, 40.94505803604754],
  [29.000615237748526, 40.97145843557254],
  [29.00042319000584, 40.99202368934875],
  [28.999390466664973, 41.005126060492294],
  [28.99648434438505, 41.02386792014673],
];
export const dakar_uk = [
  [-17.4902, 14.7397],
  [-17.44442002189201, 14.70011061950851],
  [-17.42853457046228, 14.684842460984994],
  [-17.427283653008146, 14.680699064265617],
  [-17.417564189816886, 14.679012364896561],
  [-17.3993761808885, 14.679782362877825],
  [-17.385609474432634, 14.675962396410661],
  [-17.376264070449288, 14.66755246549507],
  [-17.374308055659533, 14.648837891710716],
  [-17.37974143006337, 14.619818675057598],
  [-17.417014378504298, 14.607306994156477],
  [-17.48612690098232, 14.611302849007355],
  [-17.551110058909046, 14.635168064760357],
  [-17.611963852284475, 14.678902641415483],
  [-17.668668061729143, 14.868102447307285],
  [-17.721222687243046, 15.202767482435762],
  [-17.74530176255568, 15.507267949619582],
  [-17.740905287667047, 15.781603848858747],
  [-17.73649677176755, 16.055939658631136],
  [-17.732076214857187, 16.33027537893675],
  [-17.727643108955988, 16.60461100600115],
  [-17.72319745406395, 16.878946539824334],
  [-17.718738731787397, 17.15328197655448],
  [-17.71426694212633, 17.42761731619159],
  [-17.70978155575267, 17.70195255480259],
  [-17.70528257266642, 17.976287692387466],
  [-17.70076945206435, 18.250622724927887],
  [-17.69624219394646, 18.524957652423844],
  [-17.69170024547219, 18.79929247076754],
  [-17.68714360664154, 19.07362717995898],
  [-17.682571711991557, 19.347961775796563],
  [-17.677984561522237, 19.6222962582803],
  [-17.673381576539015, 19.89663062311026],
  [-17.66876275704189, 20.170964870286447],
  [-17.664127510469996, 20.445298995405906],
  [-17.659475836823333, 20.719632998468633],
  [-17.64808292628433, 20.888851347362746],
  [-17.62994877885299, 20.95295404208824],
  [-17.611799054595878, 21.017054814406393],
  [-17.59363375351299, 21.081153664317213],
  [-17.57545274519805, 21.145250575609882],
  [-17.557256029651057, 21.209345548284404],
  [-17.539043475727794, 21.273438566035942],
  [-17.52081508342826, 21.3375296288645],
  [-17.50257072086268, 21.401618720370223],
  [-17.484310388031048, 21.465705840553117],
  [-17.46603395229002, 21.529790972917272],
  [-17.44774141363959, 21.593874117462697],
  [-17.429432638675053, 21.657955257596413],
  [-17.411107627396404, 21.72203439331842],
  [-17.39276624562956, 21.786111507937648],
  [-17.374408493374517, 21.850186601454094],
  [-17.356034235679658, 21.914259657077512],
  [-17.337643472544983, 21.978330674807907],
  [-17.319236068233234, 22.042399637754826],
  [-17.30081202274441, 22.106466545918277],
  [-17.275646226716972, 22.176698870928263],
  [-17.243738680150923, 22.253096612784795],
  [-17.21179621511692, 22.32948810461801],
  [-17.179818831614966, 22.40587334642792],
  [-17.14780619257735, 22.482252276845262],
  [-17.115758298004067, 22.558624895870032],
  [-17.083674808536728, 22.63499114169239],
  [-17.05155572417533, 22.71135101431232],
  [-17.019400703243605, 22.787704451473907],
  [-16.987209745741552, 22.864051453177137],
  [-16.954982507647316, 22.940391956714432],
  [-16.922718988960895, 23.01672596208579],
  [-16.89041884328674, 23.09305340612636],
  [-16.858082070624846, 23.16937428883613],
  [-16.825708322177917, 23.245688546587296],
  [-16.79329759794595, 23.321996179379852],
  [-16.760849546701166, 23.39829712311724],
  [-16.728364168443562, 23.47459137779947],
  [-16.695841109486068, 23.550878878855436],
  [-16.66328036982869, 23.627159626285145],
  [-16.61296674024353, 23.761864740768154],
  [-16.544900220730582, 23.95499422230447],
  [-16.476628315566842, 24.148093427495787],
  [-16.4081510247523, 24.341162356342117],
  [-16.339463411331366, 24.53420027123178],
  [-16.27056547530404, 24.727207172164775],
  [-16.201452186135157, 24.920182307012116],
  [-16.13212354382472, 25.113125675773798],
  [-16.062574421343385, 25.306036511336856],
  [-15.992804818691155, 25.498914813701283],
  [-15.922809509340567, 25.69175980028837],
  [-15.85258849329162, 25.884571471098113],
  [-15.782136441422125, 26.077349027588518],
  [-15.711453353732082, 26.270092469759593],
  [-15.640533795311796, 26.46280098259215],
  [-15.569377766161267, 26.655474566086188],
  [-15.497979722290552, 26.848112388214386],
  [-15.42633966369965, 27.040714448976736],
  [-15.354451933921787, 27.23327989878903],
  [-15.28231653295696, 27.42580873765126],
  [-15.238456645056962, 27.53577719038966],
  [-15.222872270221792, 27.56318525700422],
  [-15.207280104928117, 27.59059158245954],
  [-15.191680149175937, 27.6179961667556],
  [-15.176072377974265, 27.645399004084606],
  [-15.1604567913231, 27.672800094446558],
  [-15.144833364164015, 27.700199432016344],
  [-15.12920209649701, 27.72759701679397],
  [-15.113562963195903, 27.754992842936947],
  [-15.097915964260693, 27.78238691044528],
  [-15.082261074497296, 27.80977921345903],
  [-15.066598293905713, 27.837169751978195],
  [-15.050927597223506, 27.864558520125314],
  [-15.035248984450675, 27.89194551790038],
  [-15.019562430256343, 27.919330739408323],
  [-15.00386793464051, 27.946714184649153],
  [-14.988165472203491, 27.9740958477101],
  [-14.972455042945285, 28.001475728591174],
  [-14.956736621397146, 28.028853821361857],
  [-14.93314700064004, 28.069918278352304],
  [-14.91699402361039, 28.115943549566403],
  [-14.906219202300633, 28.146626221194573],
  [-14.895438211034218, 28.177308050308184],
  [-14.884651027152927, 28.207989033766694],
  [-14.87385765065676, 28.23866917157011],
  [-14.863058058811276, 28.269348460566963],
  [-14.852252251616477, 28.300026900757256],
  [-14.841440206261296, 28.33070448897854],
  [-14.830621922745735, 28.36138122523082],
  [-14.81979737818179, 28.392057106340594],
  [-14.80896657256946, 28.422732132307875],
  [-14.798129482943324, 28.453406299948057],
  [-14.78728610930338, 28.48407960926114],
  [-14.776436428606388, 28.51475205705139],
  [-14.765580440852347, 28.54542364331879],
  [-14.754718122919996, 28.57609436485639],
  [-14.743849474809338, 28.606764221664182],
  [-14.732974473320429, 28.63743321052393],
  [-14.722093118453268, 28.668101331435643],
  [-14.648670030493998, 28.92462741746681],
  [-14.512705209442618, 29.407011468617437],
  [-14.375424922393364, 29.889254125465413],
  [-14.236829169346237, 30.371355388010734],
  [-14.09683981173444, 30.8533067668875],
  [-13.955456849557976, 31.33510826209571],
  [-13.812597529528716, 31.816750871608107],
  [-13.668261851646662, 32.2982345954247],
  [-13.522362054845331, 32.779549874183985],
  [-13.374898139124724, 33.26069670788595],
  [-13.225776905176275, 33.74166493076551],
  [-13.074998352999984, 34.222454542822646],
  [-12.922463372913029, 34.703054717901715],
  [-12.76817196491541, 35.1834654560027],
  [-12.612018590347787, 35.66367521108352],
  [-12.45400324921016, 36.143683983144186],
  [-12.29401340337688, 36.623479440580205],
  [-12.132049052847947, 37.10306158339158],
  [-11.967990031532882, 37.58241722179133],
  [-11.801836339431686, 38.06154635577947],
  [-11.70687799422363, 38.33693993056072],
  [-11.683114995908713, 38.408597946135096],
  [-11.659304720738987, 38.48025114330677],
  [-11.63544716871445, 38.55189952207574],
  [-11.611541913429505, 38.62354303832628],
  [-11.587588954884154, 38.69518169205838],
  [-11.5635878622426, 38.766815438686656],
  [-11.53953863550484, 38.838444278211114],
  [-11.51544083934516, 38.91006816557021],
  [-11.49129447376356, 38.98168710076395],
  [-11.46709909888358, 39.05330103824799],
  [-11.44285471470522, 39.124909978022345],
  [-11.41856087673952, 39.196513874053146],
  [-11.394217584986478, 39.2681127263404],
  [-11.369824390281906, 39.33970648835386],
  [-11.345381292625802, 39.41129516009354],
  [-11.320887838115283, 39.48287869452585],
  [-11.296344026750347, 39.55445709165079],
  [-11.271749399824461, 39.626030303924395],
  [-11.247103957337623, 39.69759833134665],
  [-11.207495529368197, 39.806507895935326],
  [-11.152924115916182, 39.95275899769044],
  [-11.098117752010609, 40.09898428147896],
  [-11.043076437651479, 40.24518374730091],
  [-10.987795758530979, 40.39135690070097],
  [-10.932275714649109, 40.537503741679146],
  [-10.87651179452999, 40.683623764558604],
  [-10.82050399817362, 40.82971696933937],
  [-10.764247714190844, 40.97578283879423],
  [-10.70774294258166, 41.1218213729232],
  [-10.650984969209247, 41.26783204260856],
  [-10.593973794073605, 41.41381484785031],
  [-10.536704597363382, 41.55976924728847],
  [-10.479177379078578, 41.70569524092305],
  [-10.42138721071052, 41.85159227478782],
  [-10.363334092259208, 41.99746034888279],
  [-10.305012983396352, 42.143298896258976],
  [-10.246423884121953, 42.28910791691637],
  [-10.187561639062464, 42.43488683053354],
  [-10.128426248217888, 42.58063563711049],
  [-10.0599124356979, 42.68755068927579],
  [-9.982020201502502, 42.75563198702943],
  [-9.943611910465169, 42.80174215774089],
  [-9.945225388646264, 42.8379507232448],
  [-9.939807747813006, 43.23788482195195],
  [-9.753531340922763, 43.49149195053967],
  [-9.628646346894811, 43.66042743851961],
  [-9.503060630099071, 43.82922699542103],
  [-9.376759000534662, 43.99788744810088],
  [-9.249741458201584, 44.16640879655917],
  [-9.121992447744105, 44.33478778074137],
  [-8.993511969162228, 44.50302440064749],
  [-8.864284091984842, 44.67111530644449],
  [-8.73430881621195, 44.83906049813236],
  [-8.603569826279966, 45.00685653313033],
  [-8.47206712218889, 45.174503411438394],
  [-8.339783993089725, 45.34199759465311],
  [-8.206720438982472, 45.509339082774474],
  [-8.072859343325831, 45.67652423839189],
  [-7.938200706119801, 45.843553061505375],
  [-7.802726994512312, 46.0104218123993],
  [-7.666438208503365, 46.1771304910737],
  [-7.529316388103991, 46.343675252092766],
  [-7.39136153331419, 46.510056095456505],
  [-7.220816358035983, 46.71704550566001],
  [-7.017680862269371, 46.96464348270328],
  [-6.784734423787725, 47.26509406736646],
  [-6.521977042591047, 47.61839725964957],
  [-6.354573763994531, 47.83988664184334],
  [-6.282524587998177, 47.92956221394778],
  [-6.175075, 48.071075],
  [-6.032225, 48.264425],
  [-5.9434249999999995, 48.37475],
  [-5.908675000000001, 48.40205],
  [-5.88675, 48.422],
  [-5.87765, 48.4346],
  [-5.8607499999999995, 48.454375],
  [-5.83605, 48.481325],
  [-5.809656648203222, 48.516305311467555],
  [-5.781569944609666, 48.55931593440267],
  [-5.726896534571802, 48.625927586368405],
  [-5.605006359848543, 48.761246607862944],
  [-4.433454747551307, 49.216526744021984],
  [-4.045840352914252, 49.414058554916075],
  [-4.0516459718382976, 49.50560208593157],
  [-4.059392331653635, 49.58206625470919],
  [-4.069079432360266, 49.64345106124893],
  [-4.0803948465781215, 49.72111464250825],
  [-4.093338574307204, 49.81505699848712],
  [-4.10786922536715, 49.907134208296746],
  [-4.123986799757961, 49.99734627193712],
  [-4.144233006666414, 50.10835557363808],
  [-4.16860784609251, 50.240162113399634],
  [-4.179050653247742, 50.31089286396901],
  [-4.175561428132113, 50.3205478253462],
  [-4.1683052643046645, 50.32977948785569],
  [-4.157282161765397, 50.33858785149745],
  [-4.149039842921563, 50.34535425213842],
  [-4.143578307773162, 50.3500786897786],
  [-4.14393909814315, 50.35448271544393],
  [-4.150122214031526, 50.358566329134426],
  [-4.152140119912699, 50.36447153454418],
  [-4.14891916372365, 50.3760617302377],
];
export const uk_rot = [
  [-4.14891916372365, 50.3760617302377],
  [-4.152140119912699, 50.36447153454418],
  [-4.150122214031526, 50.358566329134426],
  [-4.14393909814315, 50.35448271544393],
  [-4.143578307773162, 50.3500786897786],
  [-4.149039842921563, 50.34535425213842],
  [-4.157282161765397, 50.33858785149745],
  [-4.1683052643046645, 50.32977948785569],
  [-4.175561428132113, 50.3205478253462],
  [-4.179050653247742, 50.31089286396901],
  [-4.16860784609251, 50.240162113399634],
  [-4.144233006666414, 50.10835557363808],
  [-4.123986799757961, 49.99734627193712],
  [-4.10786922536715, 49.907134208296746],
  [-3.8949762731205744, 49.856185757546974],
  [-3.4853079430182357, 49.844500919687796],
  [-3.171270648860241, 49.835259907859964],
  [-2.8436612615397663, 49.82506412916522],
  [-2.3889941873101175, 49.90349425226527],
  [-1.8951302794081486, 49.98156482913126],
  [-1.4754748628601069, 50.0531739146663],
  [-0.9654066350320023, 50.14434503449242],
  [-0.5495175495810194, 50.21801475777589],
  [-0.22780760650715792, 50.27418308451674],
  [0.04633858661909766, 50.32305503284047],
  [0.3862122513870724, 50.38541838770039],
  [0.47253568476440444, 50.40243780295372],
  [0.5067370241478619, 50.408828096042456],
  [0.5729, 50.4225],
  [0.6145384466557706, 50.42872527725953],
  [0.7647726424575273, 50.457597071992794],
  [0.812156037313929, 50.46474297105633],
  [0.861, 50.4721],
  [0.9104, 50.4846],
  [0.9664804483415, 50.49484618777237],
  [1.0284861391426847, 50.52225233506476],
  [1.0500037174906192, 50.534815971428],
  [1.1389406040322048, 50.581503431802005],
  [1.2273783338705995, 50.63150002622297],
  [1.2839039392786953, 50.65843867697325],
  [1.3558, 50.7072],
  [1.4552, 50.9118],
  [1.5143097626982471, 50.95849717205491],
  [1.6107170389701677, 51.0228913905645],
  [1.6469448847308519, 51.04578817305556],
  [1.6546870248447625, 51.08041518760811],
  [1.6834372671801816, 51.110232184245376],
  [1.6941278899138013, 51.118621210505154],
  [1.7101638240345096, 51.133158581671125],
  [1.7244179876793169, 51.147691377659186],
  [1.7422356922488973, 51.16613026161963],
  [1.7894526093595573, 51.1985201413861],
  [1.8215244775874169, 51.22475042091321],
  [1.8714140503713281, 51.25598401488469],
  [1.8984443812388254, 51.27256591366352],
  [1.9355577868271894, 51.30223766008368],
  [1.958978381835014, 51.322236739022514],
  [1.9907136622310588, 51.34255191013756],
  [2.0326723370809248, 51.36902449425662],
  [2.072035629979979, 51.40141907163152],
  [2.1149567697191856, 51.422172322946096],
  [2.1609172541589032, 51.45032941456674],
  [2.203459927503279, 51.481923713],
  [2.23619735798448, 51.51103032987763],
  [2.273441198826333, 51.53420365781429],
  [2.3122698839521547, 51.55884319016579],
  [2.3795996490406544, 51.593068967224085],
  [2.5833348652140407, 51.74480190473719],
  [2.6580495569829403, 51.792248698673035],
  [2.692812838033617, 51.803807871272156],
  [2.712529605622371, 51.795295020513166],
  [2.8052437604870537, 51.80644386157094],
  [2.970955302627665, 51.837254394445495],
  [3.083171224648482, 51.85786305423491],
  [3.1712516775000164, 51.87347323429136],
  [3.2248247727085655, 51.882822576110556],
  [3.307571578268837, 51.910633703654874],
  [3.3814599390642783, 51.92060024895863],
  [3.6027206541625603, 51.95453592155992],
  [3.6452682879246234, 51.96119230241928],
  [3.673134656307734, 51.96590035331515],
  [3.7005023038992455, 51.97087886786734],
  [3.7387959058620446, 51.97723171251064],
  [3.7880154621961304, 51.98495888724505],
  [3.8640219324970353, 51.99133318844713],
  [3.966815316764758, 51.99635461611686],
  [4.029727523545033, 51.99480861383312],
  [4.052758552837861, 51.9866951815959],
  [4.06229211894108, 51.977012447582446],
  [4.056346273311497, 51.96013439389793],
];
const A = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        distance: 816336,
        mode: "sea",
        departure: 1708001348757,
        arrival: 1708071319757,
        duration: 69971000,
        speed: 42.0,
        areas: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                id: 21120,
                name: "North Sea SECA",
                alternatives: [],
              },
              geometry: {
                type: "Point",
                coordinates: [4.1835169836905335, 55.863787276691106],
              },
            },
          ],
          properties: null,
        },
        details: [],
        secaIntersection: 816336,
        hraIntersection: 0,
        speedInKts: 22.678,
        intersectsIceArea: false,
        vessel: {
          imo: 8677225,
          name: "KSL PRIDE",
          length: 83.0,
          width: 15.0,
          maxDraft: 4.2,
          draft: 4.2,
        },
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [9.965629577636719, 53.53296196255539],
          [9.96561996394217, 53.53293232241184],
          [9.962217954137689, 53.534024834980556],
          [9.952021538418792, 53.537332012830255],
          [9.94664244983364, 53.54248021015489],
          [9.92740156707279, 53.541558018788805],
          [9.899944968893692, 53.54189687915343],
          [9.870087929880809, 53.5446781684259],
          [9.793746802417843, 53.55512040937518],
          [9.66914366040914, 53.56688042398741],
          [9.646204178073045, 53.57640494716307],
          [9.5792, 53.6047],
          [9.551470299156298, 53.61512888837808],
          [9.516018127427433, 53.64945880998556],
          [9.502268837152112, 53.696084636688575],
          [9.404, 53.7539],
          [9.371273882258691, 53.81248403693656],
          [9.2921, 53.8637],
          [9.139630206013736, 53.878614336356065],
          [9.113484144015016, 53.87976724217441],
          [8.952771036003755, 53.8520418105436],
          [8.872180662013436, 53.84308483293138],
          [8.81814198604031, 53.84365449879415],
          [8.7726936451576, 53.85291106418734],
          [8.735835639365309, 53.87085452911095],
          [8.688146824758851, 53.900430439928],
          [8.629627201338227, 53.94163879663851],
          [8.559233717066519, 53.967994578690124],
          [8.476966371943725, 53.97949778608285],
          [8.426434601252765, 53.98671414234723],
          [8.407638404993637, 53.989643647483256],
          [8.35585421958783, 53.99538631072819],
          [8.271082045035342, 54.00394213208206],
          [8.19205534702689, 54.011062851993515],
          [8.082133514830266, 54.01959127969709],
          [7.9959804632048375, 54.05169448082161],
          [7.84696807692872, 54.09728147685661],
          [7.6063786721267705, 54.16705333484359],
          [7.467315924676293, 54.20283759892034],
          [7.411011789527785, 54.205532604170116],
          [7.14639676176231, 54.20796167273774],
          [6.851041196845467, 54.19798116831027],
          [6.470577497552377, 54.1899481908586],
          [6.313658002650975, 54.19004240191692],
          [5.9857311502137325, 54.160053283248445],
          [5.644499307961276, 54.13629309547248],
          [5.261, 54.10736841795313],
          [5.137299581746455, 54.09442806293325],
          [4.97473240347018, 54.082127887512044],
          [4.932266682603229, 54.021485756],
          [4.832986546115224, 53.87300848796613],
          [4.76141840728635, 53.76647069654538],
          [4.645994127287735, 53.59533459031698],
          [4.6132, 53.5309],
          [4.5952, 53.5079],
          [4.573639637707818, 53.447294997934364],
          [4.4903, 53.2914],
          [4.389631908616309, 53.164996733659564],
          [4.1948, 53.0644],
          [4.154515365780758, 53.0441756934827],
          [4.0069473101258115, 52.96643871629764],
          [3.931807705428554, 52.93173263968305],
          [3.816106213822735, 52.85150871618417],
          [3.7625264047738316, 52.80484567129635],
          [3.6282043158329995, 52.7106150223054],
          [3.498922635098507, 52.61076519799626],
          [3.3847745894362316, 52.5272634018697],
          [3.2506912394879834, 52.41817047624813],
          [3.1889900252634518, 52.365597882941394],
          [3.154976496779888, 52.33318131340897],
          [3.1295432603806574, 52.30921347761394],
          [3.1126903160657613, 52.2936943755563],
          [3.085522882931235, 52.26857611839561],
          [3.0293, 52.2165],
          [2.9766, 52.1853],
          [2.8303362401254164, 52.079881955675745],
          [2.725515801032646, 51.99638261868358],
          [2.613384096096828, 51.89966264091544],
          [2.399429407393076, 51.798947304976814],
          [2.0836517349213906, 51.69423661086771],
          [1.8933330513442075, 51.63445503035609],
          [1.8284733566615265, 51.619602563441944],
          [1.7868664680063986, 51.608284847941164],
          [1.7685123853788236, 51.600501883853745],
          [1.7549041038865203, 51.59582927868384],
          [1.7460416235294887, 51.59426703243144],
          [1.7371133356569248, 51.59203862984277],
          [1.7281192402688283, 51.589144070917826],
          [1.634766812509934, 51.559091338375694],
          [1.4570560523802414, 51.50188043221636],
          [1.3528744143833469, 51.475794003864216],
          [1.3222218985192504, 51.48083205331926],
          [1.2424396428466644, 51.48500225260725],
          [1.1135276473655888, 51.48830460172819],
          [1.0152537372187882, 51.487566832216494],
          [0.9476179124062627, 51.48278894407217],
          [0.8954249999999999, 51.482225],
          [0.8586750000000001, 51.485875],
          [0.8216300013003256, 51.48792660763101],
          [0.7842900039009766, 51.48837982289302],
          [0.7509900039009766, 51.489529822893026],
          [0.7071, 51.4923],
          [0.6525430866058811, 51.50530481131392],
          [0.5545692388319577, 51.50345096620628],
          [0.5143660987849996, 51.50109668549277],
          [0.49844025631632394, 51.49992818992991],
          [0.4933906647436114, 51.49916071927987],
          [0.4808236380155488, 51.499842600918576],
          [0.45069694519042963, 51.503039451809734],
        ],
      },
    },
  ],
  properties: {
    distance: 816336,
    mode: "sea",
    departure: 1708001348757,
    arrival: 1708071319757,
    duration: 69971000,
    speed: 42.0,
    areas: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: { id: 21120, name: "North Sea SECA", alternatives: [] },
          geometry: {
            type: "Point",
            coordinates: [4.1835169836905335, 55.863787276691106],
          },
        },
      ],
      properties: null,
    },
    details: [],
    secaIntersection: 816336,
    hraIntersection: 0,
    speedInKts: 22.678,
    intersectsIceArea: false,
    vessel: {
      imo: 8677225,
      name: "KSL PRIDE",
      length: 83.0,
      width: 15.0,
      maxDraft: 4.2,
      draft: 4.2,
    },
  },
};
