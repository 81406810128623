import { Bar, BarConfig } from "@ant-design/plots";

const Top10Rejected = (props: any) => {
  let { data } = props;

  const config: BarConfig = {
    data,
    xField: "value",
    yField: "action_type",
    seriesField: "risk_location",
    color: [
      "#EE2737",
      "#FF671F",
      "#EA7600",
      "#506170",
      "#7B97AF",
      "#B9C7D4",
      "#DBE7F1",
      "#5BC2E7",
      "#0082BA",
      "#004C97",
    ],
    /* color: ({ execution_stage }) => {
      return executionsColors(execution_stage);
    }, */
    yAxis: {
      nice: true,
      label: {
        formatter: (action_type) => {
          const words = action_type.split(" ");
          words.splice(2, 0, "\n");
          return words.join(" ");
        },
      },
    },
    isStack: true,
    legend: {
      position: "bottom",
    },
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    tooltip: {
      shared: true,
      showMarkers: false,
      formatter: ({ risk_location, value }: any) => ({
        name: risk_location,
        value: `${value} risks`,
      }),
    },
    barWidthRatio: 0.8,
  };

  return <Bar {...config} height={300} />;
};

export default Top10Rejected;
