import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  FormInstance,
  Input,
  Space,
  message,
  notification,
} from "antd";
import { RootState } from "../../../../redux/store/store";
import { MainContext } from "../../../../utils/context";
import { CODIFICATION_TYPES } from "../../../../constants/condificationTypes";
import {
  setCopilots,
  addCopilot,
  updateCopilot,
} from "../../../../redux/reducers/copilot.Reducer";
import { MessageInstance } from "antd/es/message/interface";
import { SearchOutlined } from "@ant-design/icons";
import { SharedContext } from "../context/sharedContext";
import {
  addRole,
  setRoles,
  updateRole,
} from "../../../../redux/reducers/roles.Reducer";
import {
  addReasonsActions,
  setReasonsActions,
  updateReasonsActions,
} from "../../../../redux/reducers/reasonsActions.Reducer";
import Highlighter from "react-highlight-words";
import {
  addReasonsExecutions,
  setReasonsExecutions,
  updateReasonsExecutions,
} from "../../../../redux/reducers/reasonsExecutions.Reducer";
import { useTranslation } from "react-i18next";
import { createKey, getTranslation } from "../../../../utils/transaltion";
import {
  addUserCopilot,
  setUserCopilot,
  updateUserCopilot,
} from "../../../../redux/reducers/userCopilot.Reducer";
import {
  addReasonsRisks,
  setReasonsRisks,
  updateReasonsRisks,
} from "../../../../redux/reducers/reasonsRisks.Reducer";
import CopilotsService from "../../../../services/copilots.service";
import RiskReasonsService from "../../../../services/risk-reasons.service";
import ActionReasonsService from "../../../../services/action-reasons.service";
import RolesService from "../../../../services/roles.service";
import ExecutionReasonsService from "../../../../services/execution-reasons.service";
import { REQUEST } from "../../../../services/functions/handle-api-errors/const";
import handleApiError from "../../../../services/functions/handle-api-errors/handleApiError";

export interface SharedContextFunctionsInterface {
  isCopilotdeleteable: (idCopilot: string) => boolean;
  isIconAlreadyUsed: (iconName: string) => boolean;
  isUnusedIcon: (type: string, value: string, record: any) => Promise<void>;
  handleCollapseChange: (
    keys: string | string[],
    setCollapseActiveKey: React.Dispatch<
      React.SetStateAction<string | string[]>
    >,
    form: FormInstance<any>,
    setIsSystem: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  getColumnSearchProps: (dataIndex: any, typeCodif: string) => any;
  UpdateCodif: (
    type: string,
    messageApi: MessageInstance,
    record: any,
    values: any,
    setloadingSave: React.Dispatch<React.SetStateAction<boolean>>,
    setEditingRow: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  handleEditRow: (
    record: any,
    setEditingRow: React.Dispatch<any>
  ) => Promise<void>;
  handleCancelEditRow: (setEditingRow: React.Dispatch<any>) => Promise<void>;
  DeleteCodif: (id: string, type: string) => Promise<void>;
  CreateCodif: (
    type: string,
    form: FormInstance<any>,
    isSystem: boolean,
    iconName: string | null
  ) => Promise<void>;
  getCodifList: (
    type: string,
    setRows: React.Dispatch<React.SetStateAction<never[]>>
  ) => Promise<void>;
}

export const useSharedContextFunctions = () => {
  const { t } = useTranslation();
  const {
    searchText,
    setSearchText,
    searchedColumn,
    setSearchedColumn,
    searchInput,
  } = useContext(SharedContext);
  const copilotUserList: any[] = useSelector(
    (state: RootState) => state.userCopilotReducer
  );
  /** get data from redux */
  const copilotList = useSelector((state: RootState) => state.copilotReducer);
  /** get predefined risks from redux */
  const predefinedRiksList = useSelector(
    (state: RootState) => state.predefinedRiskReducer
  );
  const roless = useSelector((state: RootState) => state.roleReducer);
  const reasonsAction = useSelector(
    (state: RootState) => state.reasonsActionsReducer
  );
  const reasonsExecution = useSelector(
    (state: RootState) => state.reasonsExecutionsReducer
  );
  const reasonsRisks = useSelector(
    (state: RootState) => state.reasonsRisksReducer
  );
  const dispatch = useDispatch();
  const globalContext = useContext(MainContext);
  if (globalContext) {
    const rolesService = new RolesService(
      globalContext.context,
      globalContext.setContext
    );
    const copilotService = new CopilotsService(
      globalContext.context,
      globalContext.setContext
    );
    const reasonsActionsService = new ActionReasonsService(
      globalContext.context,
      globalContext.setContext
    );
    const reasonsExecutionsService = new ExecutionReasonsService(
      globalContext.context,
      globalContext.setContext
    );
    const reasonsRisksService = new RiskReasonsService(
      globalContext.context,
      globalContext.setContext
    );

    // Function to validate if the name already exists
    const isUnusedIcon = async (type: string, value: string, record: any) => {
      const valuesNames =
        type === CODIFICATION_TYPES.ACTION_REASONS
          ? reasonsAction
          : type === CODIFICATION_TYPES.EXECUTION_REASONS
          ? reasonsExecution
          : type === CODIFICATION_TYPES.RISK_REASONS
          ? reasonsRisks
          : type === CODIFICATION_TYPES.COPILOTS
          ? copilotList
          : roless;
      const dataNameValues = valuesNames?.map((item: any) => item.name);

      // Exclude the name from the record in the validation
      const excludedName = record?.name;
      const filteredDataNameValues = dataNameValues.filter(
        (name: any) => name !== excludedName
      );

      if (filteredDataNameValues.includes(value)) {
        return Promise.reject(t("NAME_USED"));
      }

      return Promise.resolve();
    };
    const isIconAlreadyUsed = (iconName: string) => {
      return !copilotList?.some(
        (config: any) => config?.uiConfiguration?.iconName === iconName
      );
    };
    const isCopilotdeleteable = (idCopilot: string) => {
      if (
        predefinedRiksList?.some((risk: any) => risk?.copilot?.id === idCopilot)
      ) {
        message.error(t("errors:COPILOT_NOT_DELETEABLE"));
        return false;
      } else {
        return true;
      }
    };
    /**codif table  functions  */
    const getCodifList = async (
      type: string,
      setRows: React.Dispatch<React.SetStateAction<never[]>>
    ): Promise<void> => {
      if (type === CODIFICATION_TYPES.COPILOTS) {
        setRows(copilotList);
      }
      if (type === CODIFICATION_TYPES.ROLES) {
        setRows(roless);
      }
      if (type === CODIFICATION_TYPES.ACTION_REASONS) {
        setRows(reasonsAction);
      }
      if (type === CODIFICATION_TYPES.EXECUTION_REASONS) {
        setRows(reasonsExecution);
      }
      if (type === CODIFICATION_TYPES.RISK_REASONS) {
        setRows(reasonsRisks);
      }
    };
    const DeleteCodif = async (id: string, type: string): Promise<void> => {
      if (type === CODIFICATION_TYPES.COPILOTS) {
        DeleteCopilot(id);
      }
      if (type === CODIFICATION_TYPES.ROLES) {
        DeleteRoles(id);
      }
      if (type === CODIFICATION_TYPES.ACTION_REASONS) {
        DeleteActionReasons(id);
      }
      if (type === CODIFICATION_TYPES.EXECUTION_REASONS) {
        DeleteExecutionReasons(id);
      }
      if (type === CODIFICATION_TYPES.RISK_REASONS) {
        DeleteRisksReasons(id);
      }
    };

    const CreateCodif = async (
      type: string,
      form: FormInstance<any>,
      isSystem: boolean,
      iconName: string | null
    ): Promise<void> => {
      form
        .validateFields()
        .then(async (values) => {
          values["name"] = createKey(values?.name);
          if (type === CODIFICATION_TYPES.COPILOTS) {
            delete values["is_system"];
            values["uiConfiguration"] = iconName;
            CreateCopilot(values, form);
          }
          if (type === CODIFICATION_TYPES.ROLES) {
            delete values["is_system"];
            CreateRoles(values, form);
          }
          if (type === CODIFICATION_TYPES.ACTION_REASONS) {
            values["is_system"] = isSystem;
            if (values.name.toLowerCase() === "other") {
              values.name = "Other";
            }
            CreateActionReasons(values, form);
          }
          if (type === CODIFICATION_TYPES.EXECUTION_REASONS) {
            values["is_system"] = isSystem;
            if (values.name.toLowerCase() === "other") {
              values.name = "Other";
            }
            CreateExecutionReasons(values, form);
          }
          if (type === CODIFICATION_TYPES.RISK_REASONS) {
            values["is_system"] = isSystem;
            if (values.name.toLowerCase() === "other") {
              values.name = "Other";
            }
            CreateRisksReasons(values, form);
          }
        })
        .catch((err) => {
          if (err?.code === "ERR_NETWORK") {
            notification.error({
              message: t("errors:NETWORK_ERROR"),
              placement: "bottomRight",
            });
          } else {
            message.error(t("errors:CREATE_FAILED"));
          }
        });
    };
    const UpdateCodif = async (
      type: string,
      messageApi: MessageInstance,
      record: any,
      values: any,
      setloadingSave: React.Dispatch<React.SetStateAction<boolean>>,
      setEditingRow: React.Dispatch<React.SetStateAction<boolean>>
    ): Promise<void> => {
      let values_ = JSON.parse(JSON.stringify(values));
      if (
        (type === CODIFICATION_TYPES.ROLES && record.name === values.name) ||
        (record?.uiConfiguration?.iconName === values?.iconName &&
          record.name === values.name &&
          type === CODIFICATION_TYPES.COPILOTS)
      ) {
        setloadingSave(false);
        messageApi.open({
          type: "warning",
          content: "No changes found",
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
      } else {
        setloadingSave(true);
        const id = values_.id;
        delete values["id"];
        values["name"] = createKey(values?.name);

        if (type === CODIFICATION_TYPES.COPILOTS) {
          delete values["is_system"];
          UpdateCopilot(id, values, setloadingSave, setEditingRow);
        }

        if (type === CODIFICATION_TYPES.ROLES) {
          delete values["uiConfiguration"];
          UpdateRoles(id, values, setloadingSave, setEditingRow);
        }
      }
      if (
        (type === CODIFICATION_TYPES.ACTION_REASONS ||
          type === CODIFICATION_TYPES.RISK_REASONS ||
          type === CODIFICATION_TYPES.EXECUTION_REASONS) &&
        record.name === values.name &&
        values?.is_system === record?.is_system
      ) {
        setloadingSave(false);
        messageApi.open({
          type: "warning",
          content: "No changes found",
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
      } else {
        if (
          type === CODIFICATION_TYPES.ACTION_REASONS ||
          type === CODIFICATION_TYPES.RISK_REASONS ||
          type === CODIFICATION_TYPES.EXECUTION_REASONS
        ) {
          setloadingSave(true);
          const id = values_.id;
          delete values["id"];
          values["name"] = createKey(values?.name);
          delete values["iconName"];
          delete values["uiConfiguration"];
          if (type === CODIFICATION_TYPES.ACTION_REASONS) {
            UpdateActionReasons(id, values, setloadingSave, setEditingRow);
          }
          if (type === CODIFICATION_TYPES.EXECUTION_REASONS) {
            UpdateExecutionReasons(id, values, setloadingSave, setEditingRow);
          }
          if (type === CODIFICATION_TYPES.RISK_REASONS) {
            UpdateRisksReasons(id, values, setloadingSave, setEditingRow);
          }
        }
      }
    };
    /** copilot functions */
    const CreateCopilot = async (values: any, form: FormInstance<any>) => {
      const iconName = JSON.parse(JSON.stringify(values["uiConfiguration"]));
      values["uiConfiguration"] = { iconName: iconName };
      await copilotService
        .createResource(values)
        .then((res) => {
          form.resetFields();
          notification.success({
            message: t(`errors:CREATE_SUCCESS`),
            placement: "bottomRight",
          });
          dispatch(addCopilot(res?.data));
          // dispatch(addUserCopilot(res?.data));
        })
        .catch((err) => {
          handleApiError(err, REQUEST.CREATE);
        });
    };

    const DeleteCopilot = async (id: any) => {
      await copilotService
        .deleteResource(id)
        .then((res) => {
          notification.success({
            message: t("errors:DELETE_SUCCESS"),
            placement: "bottomRight",
          });
          const updatedCopilotList = copilotList.filter(
            (copilot: any) => copilot.id !== id
          );
          dispatch(setCopilots(updatedCopilotList));
          dispatch(setUserCopilot(updatedCopilotList));
        })
        .catch((err) => {
          handleApiError(err, REQUEST.DELETE);
        });
    };

    const UpdateCopilot = async (
      id: string,
      values: any,
      setloadingSave: React.Dispatch<React.SetStateAction<boolean>>,
      setEditingRow: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      const values_ = JSON.parse(JSON.stringify(values));
      const iconName = values["iconName"];
      values_["uiConfiguration"] = { iconName: iconName };
      delete values_["iconName"];
      await copilotService
        .updateResource(id, values_)
        .then((res: any) => {
          setloadingSave(false);
          notification.success({
            message: t("errors:UPDATE_SUCCESS"),
            placement: "bottomRight",
          });
          setEditingRow(false);
          if (copilotUserList?.some((obj) => obj.id === res?.data?.data?.id)) {
            dispatch(updateUserCopilot(res?.data?.data));
          }
          dispatch(updateCopilot(res?.data?.data));
        })
        .catch((err) => {
          setloadingSave(false);
          handleApiError(err, REQUEST.UPDATE);
        });
    };

    /** roles functions */

    const CreateRoles = async (values: any, form: FormInstance<any>) => {
      await rolesService
        .createResource(values)
        .then((res) => {
          form.resetFields();
          notification.success({
            message: t(`errors:CREATE_SUCCESS`),
            placement: "bottomRight",
          });
          dispatch(addRole(res?.data));
        })
        .catch((err) => {
          handleApiError(err, REQUEST.CREATE);
        });
    };

    const DeleteRoles = async (id: any) => {
      await rolesService
        .deleteResource(id)
        .then((res) => {
          notification.success({
            message: t("errors:DELETE_SUCCESS"),
            placement: "bottomRight",
          });
          const updatedRolesList = roless.filter((item: any) => item.id !== id);
          dispatch(setRoles(updatedRolesList));
        })
        .catch((err) => {
          handleApiError(err, REQUEST.DELETE);
        });
    };

    const UpdateRoles = async (
      id: string,
      values: any,
      setloadingSave: React.Dispatch<React.SetStateAction<boolean>>,
      setEditingRow: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      await rolesService
        .updateResource(id, values)
        .then((res: any) => {
          setloadingSave(false);

          notification.success({
            message: t("errors:UPDATE_SUCCESS"),
            placement: "bottomRight",
          });
          setEditingRow(false);
          dispatch(updateRole(res.data.data));
        })
        .catch((err) => {
          setloadingSave(false);
          handleApiError(err, REQUEST.UPDATE);
        });
    };
    /**Risk reasons code */
    const CreateRisksReasons = async (values: any, form: FormInstance<any>) => {
      await reasonsRisksService
        .createResource(values)
        .then((res) => {
          form.resetFields();
          notification.success({
            message: t(`errors:CREATE_SUCCESS`),
            placement: "bottomRight",
          });
          dispatch(addReasonsRisks(res?.data));
        })
        .catch((err) => {
          handleApiError(err, REQUEST.CREATE);
        });
    };

    const DeleteRisksReasons = async (id: any) => {
      await reasonsRisksService
        .deleteResource(id)
        .then((res) => {
          notification.success({
            message: t("errors:DELETE_SUCCESS"),
            placement: "bottomRight",
          });
          const updatedRisksReasons = reasonsRisks?.filter(
            (item: any) => item.id !== id
          );
          dispatch(setReasonsRisks(updatedRisksReasons));
        })
        .catch((err) => {
          handleApiError(err, REQUEST.DELETE);
        });
    };

    const UpdateRisksReasons = async (
      id: string,
      values: any,
      setloadingSave: React.Dispatch<React.SetStateAction<boolean>>,
      setEditingRow: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      await reasonsRisksService
        .updateResource(id, values)
        .then((res: any) => {
          setloadingSave(false);

          notification.success({
            message: t("errors:UPDATE_SUCCESS"),
            placement: "bottomRight",
          });
          setEditingRow(false);
          dispatch(updateReasonsRisks(res?.data?.data));
        })
        .catch((err) => {
          setloadingSave(false);
          handleApiError(err, REQUEST.UPDATE);
        });
    };

    /**Action reasons code */
    const CreateActionReasons = async (
      values: any,
      form: FormInstance<any>
    ) => {
      await reasonsActionsService
        .createResource(values)
        .then((res) => {
          form.resetFields();
          notification.success({
            message: t(`errors:CREATE_SUCCESS`),
            placement: "bottomRight",
          });
          dispatch(addReasonsActions(res?.data));
        })
        .catch((err) => {
          handleApiError(err, REQUEST.CREATE);
        });
    };

    const DeleteActionReasons = async (id: any) => {
      await reasonsActionsService
        .deleteResource(id)
        .then((res) => {
          notification.success({
            message: t("errors:DELETE_SUCCESS"),
            placement: "bottomRight",
          });
          const updatedActionsReasons = reasonsAction.filter(
            (item: any) => item.id !== id
          );
          dispatch(setReasonsActions(updatedActionsReasons));
        })
        .catch((err) => {
          handleApiError(err, REQUEST.DELETE);
        });
    };

    const UpdateActionReasons = async (
      id: string,
      values: any,
      setloadingSave: React.Dispatch<React.SetStateAction<boolean>>,
      setEditingRow: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      await reasonsActionsService
        .updateResource(id, values)
        .then((res: any) => {
          setloadingSave(false);

          notification.success({
            message: t("errors:UPDATE_SUCCESS"),
            placement: "bottomRight",
          });
          setEditingRow(false);
          dispatch(updateReasonsActions(res?.data?.data));
        })
        .catch((err) => {
          setloadingSave(false);
          handleApiError(err, REQUEST.UPDATE);
        });
    };
    /** Executions reasons code functions */
    const CreateExecutionReasons = async (
      values: any,
      form: FormInstance<any>
    ) => {
      await reasonsExecutionsService
        .createResource(values)
        .then((res) => {
          form.resetFields();
          notification.success({
            message: t(`errors:CREATE_SUCCESS`),
            placement: "bottomRight",
          });
          dispatch(addReasonsExecutions(res?.data));
        })
        .catch((err) => {
          handleApiError(err, REQUEST.CREATE);
        });
    };

    const DeleteExecutionReasons = async (id: any) => {
      await reasonsExecutionsService
        .deleteResource(id)
        .then((res) => {
          notification.success({
            message: t("errors:DELETE_SUCCESS"),
            placement: "bottomRight",
          });
          const updatedreasonsExecution = reasonsExecution.filter(
            (item: any) => item.id !== id
          );
          dispatch(setReasonsExecutions(updatedreasonsExecution));
        })
        .catch((err) => {
          handleApiError(err, REQUEST.DELETE);
        });
    };

    const UpdateExecutionReasons = async (
      id: string,
      values: any,
      setloadingSave: React.Dispatch<React.SetStateAction<boolean>>,
      setEditingRow: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      await reasonsExecutionsService
        .updateResource(id, values)
        .then((res: any) => {
          setloadingSave(false);

          notification.success({
            message: t("errors:UPDATE_SUCCESS"),
            placement: "bottomRight",
          });
          setEditingRow(false);
          dispatch(updateReasonsExecutions(res?.data?.data));
        })
        .catch((err) => {
          setloadingSave(false);
          handleApiError(err, REQUEST.UPDATE);
        });
    };

    /** */
    const handleEditRow = (record: any, setEditingRow: React.Dispatch<any>) => {
      setEditingRow(record);
    };

    const handleCancelEditRow = (setEditingRow: React.Dispatch<any>) => {
      setEditingRow(null);
    };

    /** handle filters  */
    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters: any) => {
      clearFilters();
      setSearchText("");
    };
    const getColumnSearchProps = (dataIndex: any, typeCodif: string) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
        searchInput,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
        close: any;
        searchInput: React.MutableRefObject<null>;
      }) => {
        const isSystemColumn = dataIndex === "is_system";
        return (
          <div
            style={{
              padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            {isSystemColumn ? (
              <Checkbox
                style={{ padding: 10, margin: 20 }}
                checked={selectedKeys[0]}
                onChange={(e) => setSelectedKeys([e.target.checked])}
              ></Checkbox>
            ) : (
              <Input
                ref={searchInput}
                placeholder={`${t("SEARCH")} ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  handleSearch(selectedKeys, confirm, dataIndex)
                }
                style={{
                  marginBottom: 8,
                  display: "block",
                }}
              />
            )}
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: "auto",
                }}
              >
                {t("SEARCH")}
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 90,
                }}
              >
                {t("RESET_TEXT_BUTTON")}
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  close();
                }}
              >
                {t("CLOSE")}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: any) => (
        <SearchOutlined
          style={{
            color: filtered ? "#1890ff" : undefined,
          }}
        />
      ),

      onFilter: (value: any, record: any) => {
        if (dataIndex === "is_system") {
          const filterValue = value;
          const dataIndexValue = record[dataIndex];
          return dataIndexValue === filterValue;
        } else {
          const filterValue = value.toLowerCase();
          const dataIndexValue = translateValue(record[dataIndex], typeCodif);
          return dataIndexValue.includes(filterValue);
        }
      },

      onFilterDropdownOpenChange: (visible: any) => {
        if (visible) {
          setTimeout(
            () => (searchInput.current as HTMLInputElement | null)?.select(),
            100
          );
        }
      },
      render: (text: any) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });

    const handleCollapseChange = (
      keys: string | string[],
      setCollapseActiveKey: React.Dispatch<
        React.SetStateAction<string | string[]>
      >,
      form: FormInstance<any>,
      setIsSystem: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      setCollapseActiveKey(keys);
      if (!keys.includes("1")) {
        form.resetFields();
        setIsSystem(false);
      }
    };

    const translateValue = (value: string, type: string): string => {
      switch (type) {
        case CODIFICATION_TYPES.COPILOTS:
          return getTranslation(value, "data").toString().toLowerCase();
        case CODIFICATION_TYPES.ROLES:
          return getTranslation(value, "data").toString().toLowerCase();
        case CODIFICATION_TYPES.ACTION_REASONS:
          return getTranslation(value, "data").toString().toLowerCase();
        case CODIFICATION_TYPES.EXECUTION_REASONS:
          return getTranslation(value, "data").toString().toLowerCase();
        default:
          return value.toLowerCase();
      }
    };
    return {
      UpdateCodif,
      handleCancelEditRow,
      handleEditRow,
      isUnusedIcon,
      getCodifList,
      CreateCodif,
      DeleteCodif,
      getColumnSearchProps,
      handleCollapseChange,
      isIconAlreadyUsed,
      isCopilotdeleteable,
    };
  }
};
