import { ProColumns } from "@ant-design/pro-components";
import MenuOptions from "../../../common/menu/MenuOptions";
import { WORK_MODE } from "../../../../constants/workModes";
import { useContext, useState } from "react";
import { SharedContext } from "../context/sharedContext";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/PredefinedsLogic";
import { PREDEFINED_TYPES } from "../../../../constants/predefinedTypes";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../utils/transaltion";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/store";
import { isPermission } from "../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../constants/permissions";
import { isValidPredefinedType } from "../../../../utils/datatypesFunctions";

const PredefinedsTableDef: any = (props: any) => {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  const { typeComponent } = props;
  const [confirmDeleteLoading, setConfirmDeleteLoading] = useState(false);
  /** Logic */
  const {
    goToConfig,
    handleUpdateAction,
    handleUpdateExecution,
    handleUpdateRisk,
  } = useSharedContextFunctions() as SharedContextFunctionsInterface;

  /** Shared vars */
  const {
    setRecord,
    valueEnumPreExecutions,
    valueEnumRoles,
    valueEnumCopilot,
    valueEnumPreActions,
    setWorkMode,
  } = useContext(SharedContext);
  const { t } = useTranslation();
  const predefinedActionsRedux = useSelector(
    (state: RootState) => state.predefinedActionGlobalReducer
  );
  /** get predefined risks from redux */
  const predefinedriksRedux = useSelector(
    (state: RootState) => state.predefinedRiskGlobalReducer
  );
  const getActionNameByExecutionOrRisk = (record: any) => {
    if (isValidPredefinedType(typeComponent)) {
      if (typeComponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED) {
        return predefinedActionsRedux?.filter((action: any) =>
          action?.executions?.some(
            (execution: any) => execution?.id === record?.id
          )
        );
      } else {
        return record?.actions;
      }
    } else {
      // invalid  typeComponent
      return [];
    }
  };
  const getPredefinedRiskNameByAction = (ActionId: any) => {
    return predefinedriksRedux?.filter((risk: any) =>
      risk?.actions?.some((action: any) => action?.id === ActionId)
    );
  };

  // Define columns
  const columns: ProColumns<any>[] = [
    {
      title: () => {
        switch (typeComponent) {
          case PREDEFINED_TYPES.RISK_PREDEFINED:
            return t("PREDEFINFIED_RISK_NAME");
          case PREDEFINED_TYPES.ACTION_PREDEFINED:
            return t("PREDEFINED_ACTION_NAME");
          case PREDEFINED_TYPES.EXECUTION_PREDEFINED:
            return t("PREDEFINFIED_EXECUTION_NAME");
          default:
            return "";
        }
      },
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      valueType: "text",
      render: (text: any, record: any, index: any) => {
        let translatedName = text;

        switch (typeComponent) {
          case PREDEFINED_TYPES.RISK_PREDEFINED:
          case PREDEFINED_TYPES.ACTION_PREDEFINED:
          case PREDEFINED_TYPES.EXECUTION_PREDEFINED:
            translatedName = getTranslation(record?.name, "data");
            break;
          default:
            translatedName = text;
        }

        return <span>{translatedName}</span>;
      },
    },

    ...(typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED
      ? [
          {
            title: t("EXECUTIONS_PREDEFINED"),
            dataIndex: "execution",
            valueType: "select" as const,
            valueEnum: valueEnumPreExecutions,
            fieldProps: {
              showSearch: true, // Enable search in filter
              optionFilterProp: "label", // Search by label
              filterOption: (input: any, option: any) =>
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
            },
            render: (text: any, record: any, index: any) => {
              return (
                <span>
                  {record?.executions
                    ?.map((obj: any) => getTranslation(obj?.name, "data"))
                    .join(", ")}
                </span>
              );
            },
          },
        ]
      : []),
    ...(typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED ||
    typeComponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED
      ? [
          {
            title: t("ROLES"),
            dataIndex: "role",
            valueType: "select" as const,

            valueEnum: valueEnumRoles,
            fieldProps: {
              showSearch: true,
              optionFilterProp: "label",
              filterOption: (input: any, option: any) =>
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
            },
            render: (text: any, record: any, index: any) => {
              return (
                <span>
                  {record?.roles
                    ?.map((obj: any) => getTranslation(obj?.name, "data"))
                    .join(", ")}
                </span>
              );
            },
          },
        ]
      : []),
    ...(typeComponent === PREDEFINED_TYPES.RISK_PREDEFINED
      ? [
          {
            title: t("COPILOT"),
            dataIndex: "copilot",
            valueType: "select" as const,
            hideInSearch: typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED,
            valueEnum: valueEnumCopilot,
            fieldProps: {
              showSearch: true,
              optionFilterProp: "label",
              filterOption: (input: any, option: any) =>
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
            },
            render: (text: any, record: any, index: any) => {
              return (
                <span>{getTranslation(record?.copilot?.name, "data")}</span>
              );
            },
          },
        ]
      : []),
    ...(typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED
      ? [
          {
            title: t("RISK_PREDEFINED"),
            dataIndex: "predefined_risk",
            valueType: "select" as const,
            hideInSearch: typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED,
            valueEnum: valueEnumCopilot,
            fieldProps: {
              showSearch: true,
              optionFilterProp: "label",
              filterOption: (input: any, option: any) =>
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
            },
            render: (text: any, record: any, index: any) => {
              return (
                <span>
                  {getPredefinedRiskNameByAction(record?.id)
                    ?.map((obj: any) => getTranslation(obj?.name, "data"))
                    .join(", ")}
                </span>
              );
            },
          },
        ]
      : []),

    ...(typeComponent === PREDEFINED_TYPES.RISK_PREDEFINED ||
    typeComponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED
      ? [
          {
            title: t("ACTIONS_PREDEFINED"),
            dataIndex: "action",
            valueType: "select" as const,
            hideInSearch:
              typeComponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED,
            valueEnum: valueEnumPreActions,
            fieldProps: {
              showSearch: true,
              optionFilterProp: "label",
              filterOption: (input: any, option: any) =>
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
            },
            render: (text: any, record: any, index: any) => {
              const Actions = getActionNameByExecutionOrRisk(record);
              return (
                <span>
                  {Actions?.map((obj: any) =>
                    getTranslation(obj?.name, "data")
                  ).join(", ")}
                </span>
              );
            },
          },
        ]
      : []),

    ...(typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED ||
    typeComponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED ||
    typeComponent === PREDEFINED_TYPES.RISK_PREDEFINED
      ? [
          {
            title: t("ENABLED"),
            dataIndex: "enabled",
            valueType: "select" as const,
            valueEnum: {
              true: { text: t("YES"), status: "Success" },
              false: { text: t("NO"), status: "Error" },
            },
            fieldProps: {
              showSearch: true,
              optionFilterProp: "label",
              filterOption: (input: any, option: any) =>
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
            },
            render: (text: any, record: any, index: any) => {
              return (
                <span>
                  <Tag color={record.enabled ? "green" : "red"}>
                    {record.enabled ? t("YES") : t("NO")}
                  </Tag>
                </span>
              );
            },
          },
        ]
      : []),

    isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
      ? {
          title: t("MORE_ACTIONS"),
          valueType: "option",
          key: "option",
          align: "center",
          render: (text, record) => (
            <span>
              <MenuOptions
                viewRecord={() => {
                  setWorkMode(WORK_MODE.VIEW);
                  setRecord(record);
                  goToConfig(typeComponent, record, WORK_MODE.VIEW);
                }}
                editRecord={() => {
                  setWorkMode(WORK_MODE.UPDATE);
                  setRecord(record);
                  typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED &&
                    handleUpdateAction(record);
                  typeComponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED &&
                    handleUpdateExecution(record);
                  typeComponent === PREDEFINED_TYPES.RISK_PREDEFINED &&
                    handleUpdateRisk(record);
                  goToConfig(typeComponent, record, WORK_MODE.UPDATE);
                }}
                deleteRecord={
                  // () => {
                  //   Delete(typeComponent, record.id, setConfirmDeleteLoading);
                  // }
                  undefined
                }
                title={t("errors:CONFIRM_DELETE_MESSAGE")}
                confirmDeleteLoading={confirmDeleteLoading}
              />
            </span>
          ),
        }
      : {},
  ];

  return columns;
};

export default PredefinedsTableDef;
