import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../utils/context";
import { CURRENT_HOST, SERVER } from "../../../constants/server";
import { Button, Spin } from "antd";
import ExoFullIcon from "../../../assets/images/icons/exo-text+logo.png";
import "./Login.css";
import { DfyaLoginText } from "../../../assets/images";
import { useTranslation } from "react-i18next";

interface LoginProps {}

function Login(props: LoginProps) {
  const globalContext = useContext(MainContext);
  const { t } = useTranslation();
  const [getLoading, setGetLoading] = useState(false);

  const openAuthWindow = () => {
    setGetLoading(true);

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const windowWidth = 400;
    const windowHeight = 400;
    const left = Math.max(0, (screenWidth - windowWidth) / 2);
    const top = Math.max(0, (screenHeight - windowHeight) / 2);

    const params = `height=${windowHeight},width=${windowWidth},left=${left},top=${top}`;

    let newWindow: Window | null = window.open(
      `${SERVER}/auth/connect?opener=${CURRENT_HOST}`,
      "Login",
      params
    );

    const checkWindowClosed = setInterval(() => {
      if (newWindow?.closed) {
        clearInterval(checkWindowClosed);
        onWindowClosed();
      }
    }, 500); // Check every 500ms

    const onWindowClosed = () => {
      setGetLoading(false);
    };

    window.addEventListener(
      "message",
      (event) => {
        if (event.origin !== SERVER) {
          return;
        }
        if (event?.data?.login) {
          globalContext?.setContext({
            ...globalContext.context,
            loggedIn: true,
          });
        } else {
          globalContext?.setContext({
            ...globalContext.context,
            loggedIn: false,
          });
        }

        if (newWindow) {
          newWindow.close();
          clearInterval(checkWindowClosed);
          setGetLoading(false);
        }
      },
      false
    );
  };

  useEffect(() => {
    if (globalContext?.context?.loggedIn === true) {
      setGetLoading(false);
      window.location.href = "/";
    }
  }, [globalContext?.context?.loggedIn]);

  return (
    <>
      <Spin spinning={getLoading} size="large" tip="Loading...">
        <div className="login-container">
          <div className="login-left">
            <div className="image-container">
              <img src={ExoFullIcon} alt="Logo" />
            </div>
          </div>

          <div className="login-right">
            <div style={{ height: "5%" }}></div>
            <div className="login-right-inner">
              <p style={{ fontSize: 30, color: "white" }}>
                {t("WELCOME_BACK")}
              </p>
              <p style={{ fontSize: 18, color: "white" }}>
                {t("LOGIN_IN_TO_YOUR_ACCOUNT")}
              </p>
            </div>
            <div
              style={{
                marginTop: -5,
                marginBottom: -5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                className="microsoft-button"
                type="dashed"
                onClick={openAuthWindow}
              >
                <p style={{ fontSize: 18 }} className="white-text">
                  {t("SIGN_IN_MICROSOFT")}
                </p>
              </Button>
            </div>
            <div className="dfya-logo">
              <img
                src={DfyaLoginText}
                alt="Logo"
                style={{ marginTop: -70, width: "60px", height: "60px" }}
              />{" "}
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
}

export default Login;
