import { useEffect, useState } from "react";
import { Card, Col, ConfigProvider, Row } from "antd";
import { FilterData, getData } from "../common/FilterData";
import FilterHeader from "../common/filter_header/FilterHeader";
import ChartCard from "../common/ChartCard";
import {
  CostImpactByAction,
  DaysOfSupplyImpact,
  QuantityAtRiskByAction,
  QuantityMitigated,
  RevenueAtRiskByAction,
  RevenueMitigated,
  SupplyCostAtRiskByAction,
  SupplyCostMitigated,
} from "./components/charts";
import {
  mapCostImpactByAction,
  mapDaysOfSupplyImpact,
  mapQuantityAtRiskByAction,
  mapQuantityMitigated,
  mapRevenueAtRiskByAction,
  mapRevenueMitigated,
  mapSupplyCostAtRiskByAction,
  mapSupplyCostMitigated,
} from "./components/data_mapping";
import { DASHBOARDS } from "../../../../constants/dashboards";
import { COPILOT_TYPES } from "../../../../constants/copilottypes";

const ActionROI = (props: any) => {
  const { copilot } = props;
  const [filteredData, setFilteredData]: any = useState([]);
  const [filterOptions, setFilterOptions]: any = useState({});
  /* sum */
  const [quantityMitigated, setQuantityMitigated] = useState(0);
  const [revenueMitigated, setRevenueMitigated] = useState(0);
  const [costImpact, setCostImpact] = useState(0);
  useEffect(() => {
    const sorted_data = getData(copilot);
    sorted_data.sort((a, b) => a.risk_date - b.risk_date);

    FilterData(filterOptions, sorted_data, setFilteredData);
  }, [filterOptions, copilot]);

  useEffect(() => {
    setQuantityMitigated(
      filteredData
        ?.filter(
          (risk: any) =>
            risk.risk_status === "Accepted" || risk.risk_status === "Rejected"
        )
        ?.reduce(
          (total: any, obj: any) =>
            total + parseFloat(obj.quantity_mitigated || 0),
          0
        )
    );
    setRevenueMitigated(
      copilot !== COPILOT_TYPES.SUPPLY_MATCHING
        ? filteredData
            ?.filter(
              (risk: any) =>
                risk.risk_status === "Accepted" ||
                risk.risk_status === "Rejected"
            )
            ?.reduce(
              (total: any, obj: any) =>
                total + parseFloat(obj.revenue_mitigated || 0),
              0
            )
        : filteredData
            ?.filter(
              (risk: any) =>
                risk.risk_status === "Accepted" ||
                risk.risk_status === "Rejected"
            )
            ?.reduce(
              (total: any, obj: any) =>
                total + parseFloat(obj.supply_cost_mitigated || 0),
              0
            )
    );
    setCostImpact(
      filteredData
        ?.filter(
          (risk: any) =>
            risk.risk_status === "Accepted" || risk.risk_status === "Rejected"
        )
        ?.reduce(
          (total: any, obj: any) => total + parseFloat(obj.cost_impact || 0),
          0
        )
    );
  }, [filteredData, copilot]);

  return (
    <ConfigProvider>
      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          marginBottom: 15,
        }}
      >
        <FilterHeader
          setFilterOptions={setFilterOptions}
          dashboard={DASHBOARDS.ACTION_ROI}
          copilot={copilot}
        />
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: 15,
        }}
      >
        <Col sm={24} lg={8} xl={8}>
          <Card title={"Total Quantity Mitigated"} size="small">
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "16px" }}
            >
              {quantityMitigated.toLocaleString("us-US")}
            </span>
          </Card>
        </Col>
        <Col sm={24} lg={8} xl={8}>
          <Card
            title={
              copilot !== COPILOT_TYPES.SUPPLY_MATCHING
                ? "Total Revenue Mitigated"
                : "Total Supply cost mitigated"
            }
            size="small"
          >
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "16px" }}
            >
              {revenueMitigated.toLocaleString("us-US", {
                style: "currency",
                currency: "EUR",
              })}
            </span>
          </Card>
        </Col>
        <Col sm={24} lg={8} xl={8}>
          <Card title={"Total Cost Impact"} size="small">
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "16px" }}
            >
              {costImpact.toLocaleString("us-US", {
                style: "currency",
                currency: "EUR",
              })}
            </span>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col sm={24} lg={12} xl={8}>
          <ChartCard
            title="Quantity at Risk by Action"
            chart={
              <QuantityAtRiskByAction
                data={mapQuantityAtRiskByAction(filteredData)}
              />
            }
          ></ChartCard>
        </Col>
        <Col sm={24} lg={12} xl={8}>
          {copilot !== COPILOT_TYPES.SUPPLY_MATCHING ? (
            <ChartCard
              title="Revenue at Risk by Action"
              chart={
                <RevenueAtRiskByAction
                  data={mapRevenueAtRiskByAction(filteredData)}
                />
              }
            ></ChartCard>
          ) : (
            <ChartCard
              title="Supply Cost At Risk By Action"
              chart={
                <SupplyCostAtRiskByAction
                  data={mapSupplyCostAtRiskByAction(filteredData)}
                />
              }
            ></ChartCard>
          )}
        </Col>
        <Col sm={24} lg={12} xl={8}>
          <ChartCard
            title="Cost Impact by Action"
            chart={
              <CostImpactByAction data={mapCostImpactByAction(filteredData)} />
            }
          ></ChartCard>
        </Col>
        <Col sm={24} lg={12} xl={8}>
          <ChartCard
            title="Quantity Mitigated"
            chart={
              <QuantityMitigated data={mapQuantityMitigated(filteredData)} />
            }
          ></ChartCard>
        </Col>
        <Col sm={24} lg={12} xl={8}>
          {copilot !== COPILOT_TYPES.SUPPLY_MATCHING ? (
            <ChartCard
              title="Revenue Mitigated"
              chart={
                <RevenueMitigated data={mapRevenueMitigated(filteredData)} />
              }
            ></ChartCard>
          ) : (
            <ChartCard
              title="Supply Cost Mitigated"
              chart={
                <SupplyCostMitigated
                  data={mapSupplyCostMitigated(filteredData)}
                />
              }
            ></ChartCard>
          )}
        </Col>
        {copilot !== COPILOT_TYPES.SUPPLY_MATCHING &&
          copilot !== COPILOT_TYPES.DEMAND_MATCHING && (
            <Col sm={24} lg={12} xl={8}>
              <ChartCard
                title="Days of Supply Impact  "
                chart={
                  <DaysOfSupplyImpact
                    data={mapDaysOfSupplyImpact(filteredData)}
                  />
                }
              ></ChartCard>
            </Col>
          )}
      </Row>
    </ConfigProvider>
  );
};

export default ActionROI;
