import { Col, List, Row, Spin, Tag, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { STATUS } from "../../../../../../constants/status";
import { Reason } from "../../../../../../interfaces/reasons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store/store";
import { useContext, useEffect, useState } from "react";
import { SharedContext } from "../../../context/SharedContext";
import moment from "moment";
import { getTranslation } from "../../../../../../utils/transaltion";
import ExpandCollapseArrow from "./ExpandIcon";
import { ExecutionExpandableSection } from "./ExecutionExpandableSection";
const { Text } = Typography;

export const ActionsExpandableSection = (props: any) => {
  const {
    getRiskLoading,
    handleRowClick,
    currentActionId,
    menu,
    orgaDateFormat,
    isSecondColumnOpen,
  } = props;
  const { t } = useTranslation();
  const [expandedRowExecution, setExpandedRowExecution] = useState<
    number | null
  >(null);
  const reasonsRisk: Reason[] = useSelector(
    (state: RootState) => state.reasonsRisksReducer
  );
  /** reasons actions from redux */
  const reasonsActions: Reason[] = useSelector(
    (state: RootState) => state.reasonsActionsReducer
  );
  const { setCurrentAction, selectedRisk, currentAction } =
    useContext(SharedContext);

  const getStatus = (record: any) => {
    if (record?.status === STATUS.COMPLETE) {
      if (record?.contexts?.at(0)?.reason?.id) {
        const reasonsName = reasonsRisk?.find(
          (obj: any) => obj.id === record?.risk_reason?.id
        )?.name;
        if (reasonsName === "RISK_STOPPED") {
          return (
            <Tag
              className="tag-red"
              style={{
                marginInlineEnd: 0,
                whiteSpace: "normal",
                wordBreak: "break-word",
                overflowWrap: "break-word",
              }}
            >
              Stopped
            </Tag>
          );
        } else {
          return (
            <Tag
              className="tag-red"
              style={{
                marginInlineEnd: 0,
                whiteSpace: "normal",
                wordBreak: "break-word",
                overflowWrap: "break-word",
              }}
            >
              Rejected
            </Tag>
          );
        }
      } else {
        return (
          <Tag
            className="tag-green"
            style={{
              marginInlineEnd: 0,
              whiteSpace: "normal",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            Accepted
          </Tag>
        );
      }
    }

    if (record?.status === STATUS.WAIT_FOR_EXECUTION) {
      return <Tag className="tag-blue">In progress</Tag>;
    }
    if (record?.status === STATUS.OPEN) {
      if (currentActionId === record?.id) {
        return (
          <Tag
            className="tag-blue"
            style={{
              marginInlineEnd: 0,
              whiteSpace: "normal",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            In progress
          </Tag>
        );
      } else {
        return (
          <Tag
            color="grey"
            style={{
              marginInlineEnd: 0,
              whiteSpace: "normal",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            Waiting
          </Tag>
        );
      }
    }
  };
  const handleExpandExecution = (index: number, riskID: string) => {
    setExpandedRowExecution(expandedRowExecution === index ? null : index);
  };
  useEffect(() => {
    setExpandedRowExecution(null);
  }, [menu]);
  return (
    <Spin
      spinning={getRiskLoading}
      size="large"
      tip="Loading..."
      style={{ width: "100%" }}
    >
      <List
        style={{
          width: "100%",
          marginInlineStart: 30,
        }}
        header={
          <>
            <Row
              style={{
                marginBlock: "2px",
              }}
            >
              <Col span={2}>
                <Text>{""}</Text>
              </Col>
              <Col span={5}>
                <Text>{t("Date")}</Text>
              </Col>
              <Col span={6}>
                <Text>{t("Action name")}</Text>
              </Col>
              <Col span={5}>
                <Text>{t("Status")}</Text>
              </Col>
              <Col span={6}>
                <Text>{t("Reason")}</Text>
              </Col>
            </Row>
          </>
        }
        size="small"
        bordered
        dataSource={selectedRisk?.actions
          ?.map((action: any) => {
            return {
              ...action,
              risk_reason: selectedRisk?.reason,
            };
          })
          ?.sort(
            (a: any, b: any) => a.action_conf_priority - b.action_conf_priority
          )}
        renderItem={(action: any, index: any) => (
          <List.Item
            style={{ width: "100%" }}
            className={`list-item ${
              currentAction?.id === action?.id ? "list-item-selected" : ""
            }`}
            onClick={() => {
              if (currentAction?.id !== action?.id) {
                setExpandedRowExecution(null);
              }
              setCurrentAction(action);
              handleRowClick(true);
            }}
          >
            <List.Item.Meta
              title={null}
              description={
                <>
                  {/* Data Row */}
                  <Row
                    gutter={[16, 16]}
                    style={{
                      // marginInlineEnd: -10,
                      marginInlineStart: -10,
                      marginBlock: "0",
                      marginTop: 0,
                      marginBottom: -15,
                    }}
                  >
                    <Col xs={2} sm={1} md={1} lg={1}>
                      <Tooltip title={t("EXPAND_EXECUTIONS")}>
                        <span>
                          <ExpandCollapseArrow
                            title={""}
                            type="execution"
                            onToggle={(
                              isExpanded: boolean,
                              event: React.MouseEvent<HTMLSpanElement>
                            ) => {
                              event.stopPropagation();
                              handleExpandExecution(index, action?.id);
                              setCurrentAction(action);
                              handleRowClick(true);
                            }}
                            defaultExpanded={expandedRowExecution === index}
                          />
                        </span>
                      </Tooltip>
                    </Col>

                    <Col xs={12} sm={8} md={5} lg={5}>
                      <div className="card-item-expanded">
                        <Text className="item-value">
                          {action?.contexts?.at(0)?.decision_time
                            ? moment(
                                action?.contexts?.at(0)?.decision_time
                              ).format(orgaDateFormat || "DD/MM/YYYY")
                            : "-"}
                        </Text>
                      </div>
                    </Col>

                    <Col xs={24} sm={12} md={6} lg={6}>
                      <div className="card-item">
                        <Text className="item-value">
                          {action?.action_conf_name}
                        </Text>
                      </div>
                    </Col>
                    <Col xs={24} sm={8} md={4} lg={4}>
                      <div
                        style={{
                          marginInlineEnd: -8,

                          backgroundColor: "#F5F5F5",
                          borderRadius: 6,
                          marginTop: 1,
                          paddingTop: 3,
                          paddingBottom: 3,
                        }}
                      >
                        <span
                          className={
                            isSecondColumnOpen
                              ? "responsive-tag-container-collapsed"
                              : "responsive-tag-container"
                          }
                          style={{
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                          }}
                        >
                          {action?.contexts?.at(0)?.computed && (
                            <Tag
                              className="tag-light-blue"
                              style={{
                                marginInlineEnd: 0,
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                                overflowWrap: "break-word",
                              }}
                            >
                              Automated
                            </Tag>
                          )}

                          {getStatus(action)}
                        </span>
                      </div>
                    </Col>

                    <Col xs={24} sm={12} md={6} lg={6}>
                      <div
                        style={{
                          marginInlineEnd: -20,
                          marginBlockStart: 10,
                          backgroundColor: "#F5F5F5",
                          borderRadius: 6,
                          marginTop: 0,
                          paddingTop: 3,
                          paddingBottom: 3,
                          paddingInlineEnd: 3,
                        }}
                      >
                        {action?.status === STATUS.COMPLETE &&
                        action?.contexts?.at(0)?.reason?.id ? (
                          reasonsActions?.find(
                            (Reason) =>
                              Reason?.id === action?.contexts?.at(0)?.reason?.id
                          )?.name === "OTHER" ? (
                            <>
                              <Tag
                                className="tag-red"
                                style={{
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {getTranslation(
                                  reasonsActions?.find(
                                    (Reason) =>
                                      Reason?.id ===
                                      action?.contexts?.at(0)?.reason?.id
                                  )?.name || "",
                                  "data"
                                )}
                              </Tag>
                              <Tag
                                className="tag-red"
                                style={{
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {getTranslation(
                                  action?.contexts?.at(0)?.reason_comment,
                                  "data"
                                )}
                              </Tag>
                            </>
                          ) : (
                            <>
                              <Tag
                                className="tag-red"
                                style={{
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {getTranslation(
                                  reasonsActions?.find(
                                    (Reason) =>
                                      Reason?.id ===
                                      action?.contexts?.at(0)?.reason?.id
                                  )?.name || "",
                                  "data"
                                )}
                              </Tag>
                            </>
                          )
                        ) : (
                          <Text className="item-value">{"  "}</Text>
                        )}
                      </div>
                    </Col>
                  </Row>

                  {/* expanded executions */}
                  <Row
                    gutter={[16, 16]}
                    style={{
                      marginTop: 20,
                      width: "100%",
                    }}
                  >
                    <Col span={24}>
                      {expandedRowExecution === index && (
                        <ExecutionExpandableSection
                          getRiskLoading={getRiskLoading}
                          handleRowClick={handleRowClick}
                          currentActionId={currentActionId}
                          orgaDateFormat={orgaDateFormat}
                          dataSource={action?.executions || []}
                        />
                      )}
                    </Col>
                  </Row>
                </>
              }
            />
          </List.Item>
        )}
      />
    </Spin>
  );
};
