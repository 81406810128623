/** @format */
import { useContext, useEffect, useState } from "react";
import { SharedContext } from "../context/SharedContext";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../logic/runningShared.logic";
import { RUNNING_MENU } from "../../../../constants/riskMenus";
import { useLocation } from "react-router-dom";
import { PAGES_SIZE } from "../../../../constants/pagination";
import CurrentActionDetails from "../sideBar/CurrentActionDetails";
import { MainContext } from "../../../../utils/context";
import "../runningCss.css";
import { RunningsVirtualList } from "./RunningsVirtualList";

const RunningRiskList = (props: any) => {
  /**props */
  const { copilotName, menu } = props;
  const [acceptRejectState, setAcceptRejectState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentActionId, setCurrentActionId] = useState("");
  const [sortingOrder, setSortingOrder] = useState<string>("desc");
  const [sortingField, setSortingField] = useState<string>("created_at");
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isSecondColumnOpen, setIsSecondColumnOpen] = useState(false);

  const globalContext = useContext(MainContext);
  const orgaFormatNumber =
    globalContext?.context?.orga?.formatNumber || "en-US";
  const orgaCurrency = globalContext?.context?.orga?.currency || "EUR";
  const orgaDateFormat =
    globalContext?.context?.orga?.formatDate || "DD/MM/YYYY";
  const location = useLocation();

  /**shared context */
  const { setRunningRisks, selectedRisk, setSelectedRisk, setGetLoading } =
    useContext(SharedContext);
  /**shared logic */
  const { filterRunningRisksHistory, filterRunningOpenActionsAppend } =
    useSharedContextFunctions() as SharedContextFunctions;

  const getQueryParamsFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const params: { [key: string]: string } = {};

    searchParams.forEach((value, key) => {
      // Exclude 'current' and 'pageSize' from the params
      if (key !== "current" && key !== "pageSize") {
        try {
          // Try to parse the value as JSON (when the field's value is an object, not a string)
          params[key] = JSON.parse(value);
        } catch (e) {
          // If parsing fails, keep the value as a string
          params[key] = value;
        }
      }
    });
    return params;
  };
  const [filterparams, setFilterparams] = useState<any>(
    getQueryParamsFromUrl() || {}
  );

  const appendData = (runningRisk: any[], params: any) => {
    setAcceptRejectState(false);
    setGetLoading(true);
    if (menu === RUNNING_MENU.RISKS_HISTORY) {
      filterRunningRisksHistory(
        copilotName,
        {
          order: sortingOrder,
          sort: sortingField,
          current: Math.floor(runningRisk.length / PAGES_SIZE) + 1,
          pageSize: PAGES_SIZE,
        },
        { ...params },
        // ["model", "risk_conf"]
        ["model"],
        runningRisk
      );
    } else {
      filterRunningOpenActionsAppend(
        copilotName,
        {
          order: sortingOrder,
          sort: sortingField,
          current: Math.floor(runningRisk.length / PAGES_SIZE) + 1,
          pageSize: PAGES_SIZE,
        },
        { status: { value: "WAIT_FOR", operator: "HAS" }, ...params },

        ["current_action", "current_execution", "contexts"],
        runningRisk
      );
    }
  };

  // Handle toggling the second column visibility
  const handleRowClick = (state: boolean) => {
    setIsSecondColumnOpen(state);
  };

  useEffect(() => {
    if (menu !== RUNNING_MENU.RISKS_HISTORY) {
      setIsSecondColumnOpen(true);
    } else {
      setIsSecondColumnOpen(false);
    }
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      appendData([], filterparams);
    }

    setRunningRisks([]);
  }, [sortingOrder, sortingField, location, copilotName, menu]);

  useEffect(() => {
    if (selectedRisk) {
      setCurrentActionId(selectedRisk?.current_action?.id);
    }
  }, [selectedRisk]);

  return (
    <div className="row-container">
      <div
        className={`column first-column ${
          isSecondColumnOpen ? "collapsed" : ""
        }`}
      >
        <RunningsVirtualList
          isSecondColumnOpen={isSecondColumnOpen}
          copilotName={copilotName}
          menu={menu}
          currentActionId={currentActionId}
          appendData={appendData}
          handleRowClick={handleRowClick}
          isFirstLoad={isFirstLoad}
          filterparams={filterparams}
          setFilterparams={setFilterparams}
          setSortingOrder={setSortingOrder}
          sortingOrder={sortingOrder}
          setAcceptRejectState={setAcceptRejectState}
          setIsSecondColumnOpen={setIsSecondColumnOpen}
          setLoading={setLoading}
          setSortingField={setSortingField}
          sortingField={sortingField}
        />
      </div>
      {/* action details  */}
      <div
        className={`column second-column ${isSecondColumnOpen ? "open" : ""}`}
      >
        <CurrentActionDetails
          acceptRejectState={acceptRejectState}
          formatNmber={orgaFormatNumber}
          formatDate={orgaDateFormat}
          currency={orgaCurrency}
          currentActionId={selectedRisk?.current_action?.id}
          currentRiskId={selectedRisk?.id}
          setSelectedRisk={setSelectedRisk}
          menu={menu}
          copilotName={copilotName}
          loading={loading}
          setLoading={setLoading}
          selectedRisk={selectedRisk}
          handleRowClick={handleRowClick}
          setAcceptRejectState={setAcceptRejectState}
        />
      </div>
    </div>
  );
};
export default RunningRiskList;
