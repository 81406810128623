import { useState, useContext, Dispatch, SetStateAction } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../../logic/runningShared.logic";
import { SharedContext } from "../../context/SharedContext";
import ReasonCodeSelection from "./ReasonCodeSelection";
import SaveCancel from "../../../../common/SaveCancel";
import { commentPattern } from "../../../../../constants/regExpValidation";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../utils/transaltion";
import { COPILOT_TYPES } from "../../../../../constants/copilottypes";
import { RUNNING_MENU } from "../../../../../constants/riskMenus";

function AddReasonCodeModal(props: any) {
  const { currentRiskId, menu, copilotName } = props;

  const {
    setSelectedRisk,
    setAcceptRejectState,
  }: {
    setSelectedRisk: Dispatch<any>;
    setAcceptRejectState: Dispatch<SetStateAction<boolean>>;
  } = props;

  /**local vars */
  const [form_ReasonCode] = Form.useForm();
  const [loadingReject, setLoadingReject] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [inputOtherVisible, setInputOtherVisible] = useState(false);

  /** shared context vars */
  const {
    currentActionExecutionReasons,
    currentActionExecutionRejectArguments,
    setOpenAddReasonCodeModal,
    typeReject,
  } = useContext(SharedContext);

  /** shared logic */
  const { rejectActionOrExecution, closeAddReasonCodeModal } =
    useSharedContextFunctions() as SharedContextFunctions;
  const { t } = useTranslation();

  /** form validation before rejection */
  const onFinish = async () => {
    setLoadingSave(true);
    form_ReasonCode
      .validateFields()
      .then(async (values) => {
        const args = {
          context: currentActionExecutionRejectArguments?.contexts?.id,
          reason: values?.reason?.at(0),
          reason_comment: values?.reason_comment,
        };
        const id = currentActionExecutionRejectArguments?.id;
        rejectActionOrExecution(
          copilotName,
          currentRiskId,
          menu,
          values?.reason?.at(0),
          args,
          typeReject,
          setLoadingReject,
          setAcceptRejectState
        );
        closeAddReasonCodeModal(setOpenAddReasonCodeModal);
        setLoadingSave(false);
      })
      .catch((err) => {});
  };

  return (
    <>
      <Form
        // onFinish={onFinish}
        form={form_ReasonCode}
        name="wrap"
        {...{ labelCol: { style: { width: 120 } } }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
      >
        <div style={{ marginTop: 50 }}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <ReasonCodeSelection
                copilotName={copilotName}
                currentActionExecutionReasons={currentActionExecutionReasons}
                setInputOtherVisible={setInputOtherVisible}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {inputOtherVisible && (
                <Form.Item
                  label={getTranslation("Other Comment", "labels")}
                  name="reason_comment"
                  rules={[
                    {
                      required: inputOtherVisible ? true : false,
                      message: t("errors:REQUIRED", {
                        label: t("COMMENT"),
                      }),
                    },
                    {
                      pattern: commentPattern,
                      message: t("errors:INVALID", {
                        label: t("COMMENT"),
                      }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={"  " + getTranslation("COMMENT", "labels")}
                name="reason_comment"
              >
                <Input.TextArea rows={4} placeholder="Enter a description" />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Row>
          <Col
            span={24}
            style={{ marginTop: 20, marginBottom: 0, textAlign: "right" }}
          >
            <Form.Item>
              <Button
                type="primary"
                className="save-button"
                style={{
                  backgroundColor: "#004C97",
                  borderColor: "#004C97",
                  marginRight: "8px",
                }}
                loading={loadingReject}
                onClick={() => {
                  setLoadingReject(true);
                  onFinish();
                }}
              >
                {getTranslation("SAVE_&_CONTINUE", "labels")}
              </Button>
              <Button
                type="default"
                className="custom-cancel"
                // style={{
                //   background: "transparent",
                //   color: "#1f1e1e",
                //   border: "0.5px",
                //   borderColor: "black",
                // }}
                onClick={() => {
                  setAcceptRejectState(false);
                }}
              >
                {getTranslation("CANCEL", "labels")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default AddReasonCodeModal;
