import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Select,
  DatePicker,
  Input,
  Button,
  notification,
} from "antd";
import RisksConfsService from "../../../services/risk-confs.service";
import { MainContext } from "../../../utils/context";
import dayjs from "dayjs";
import RunningRisksServices from "../../../services/running-risks.service";
import { createKey } from "../../../utils/transaltion";
import { SharedContext } from "./context/SharedContext";
import { Fulfillement_Start_Risk } from "./data/fulfillment";
import { Supply_Matching_Start_Risk } from "./data/supplyMatching";
import { Demand_Matching_Start_Risk } from "./data/demandMatching";
import { Inbound_Freight_start_Risk } from "./data/inboundFreight";
import { useLocation } from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;

interface ModalFormProps {
  copilotName: string;
  visible: boolean;
  onClose: () => void;
}

const StartRisk: React.FC<ModalFormProps> = ({
  visible,
  onClose,
  copilotName,
}) => {
  const [form] = Form.useForm();
  const [riskConf, setRiskConf] = useState<any>([]);
  const globalContext = useContext(MainContext);
  const path = window.location.pathname;
  const copilotNameUrl = path.substring(path.indexOf('/menu') + 6, path.indexOf('/', path.indexOf('/menu') + 6) === -1 ? undefined : path.indexOf('/', path.indexOf('/menu') + 6)) || "";
  const location =useLocation()
  useEffect(() => {
    console.log("copilotNameUrl",copilotNameUrl)
    getRiskConf();
    form.resetFields()
    form.setFieldsValue(
      createKey(copilotName) === createKey("fulfillment")||createKey(copilotNameUrl)=== createKey("fulfillment")
        ? {
            due_date: dayjs("2024-11-08 00:00:00"),
            context: `"Item_Code": "SKU-213",\n"Customer": "C0001",\n"Quantity": 1896`,
          }
        : createKey(copilotName) === createKey("supply matching")||createKey(copilotNameUrl)=== createKey("supply matching")
        ? {
            due_date: dayjs("2024-11-26 00:00:00"),
            context: `"Item_Code": "SKU-346",\n"Location_code": "Riyadh",\n"Excess_Quantity": 102`,
          }
        : createKey(copilotName) === createKey("demand matching")||createKey(copilotNameUrl)=== createKey("demand matching")
        ? {
            due_date: dayjs("2024-11-26 00:00:00"),
            context: `"Item_Code": "SKU-995",\n"Supplier": "5",\n"Quantity": 20`,
          }
        : createKey(copilotName) === createKey("inbound freight management")||createKey(copilotNameUrl)=== createKey("inbound freight management")
        ? {
            due_date: dayjs("2024-12-04 00:00:00"),
            context: `"BOL": "BL745291",\n"Port of Destination": "Dammam",\n"Quantity": 1,235,400`,
          }
        : {
            due_date: dayjs("2024-05-31 00:00:00"),
            context: `"ITEM_CODE": "SKU-233",\n"LOCATION_CODE": "245",\n"QUANTITY_AT_RISK": 3000`,
          }
    );
  }, [copilotName,location,visible]);


  const {
    setRunningRisks,
    setRunningRisksFilter,
    setTotalRunningRisk,
    setCurrentAction,
    setSelectedRisk,
    runningRisks,
  } = useContext(SharedContext);

  if (!globalContext) {
    return <></>;
  }
  const risksServices = new RisksConfsService(
    globalContext?.context,
    globalContext?.setContext
  );

  const RunningRisksService = new RunningRisksServices(
    globalContext?.context,
    globalContext?.setContext,
    ""
  );

  const handleOk = () => {
    if (createKey(copilotName) === createKey("fulfillment")) {
      const updatedStartRiskObject=updateIds(Fulfillement_Start_Risk)
      setRunningRisks((prevRisks: any) => {
        return prevRisks
          ? [updatedStartRiskObject, ...prevRisks]
          : [updatedStartRiskObject];
      });
      setRunningRisksFilter((prevRisks: any) => {
        return prevRisks
          ? [updatedStartRiskObject, ...prevRisks]
          : [updatedStartRiskObject];
      });
      onClose();
      setTotalRunningRisk(runningRisks.length + 1);
      if (runningRisks.length + 1 > 0) {
        setCurrentAction(updatedStartRiskObject?.current_action);
        setSelectedRisk(updatedStartRiskObject);
      } else {
        setCurrentAction(null);
        setSelectedRisk(null);
      }
      notification.success({
        message: `Start Risk successful `,
        placement: "bottomRight",
      });
    } else {
      if (createKey(copilotName) === createKey("supply matching")) {
        const updatedStartRiskObject=updateIds(Supply_Matching_Start_Risk)

        setRunningRisks((prevRisks: any) => {
          return prevRisks
            ? [updatedStartRiskObject, ...prevRisks]
            : [updatedStartRiskObject];
        });
        setRunningRisksFilter((prevRisks: any) => {
          return prevRisks
            ? [updatedStartRiskObject, ...prevRisks]
            : [updatedStartRiskObject];
        });
        onClose();

        setTotalRunningRisk(runningRisks.length + 1);
        if (runningRisks.length + 1 > 0) {
          setCurrentAction(updatedStartRiskObject?.current_action);
          setSelectedRisk(updatedStartRiskObject);
        } else {
          setCurrentAction(null);
          setSelectedRisk(null);
        }
        notification.success({
          message: `Start Risk successful `,
          placement: "bottomRight",
        });
      } else {
        if (createKey(copilotName) === createKey("demand matching")) {
          const updatedStartRiskObject=updateIds(Demand_Matching_Start_Risk)

          setRunningRisks((prevRisks: any) => {
            return prevRisks
              ? [updatedStartRiskObject, ...prevRisks]
              : [updatedStartRiskObject];
          });
          setRunningRisksFilter((prevRisks: any) => {
            return prevRisks
              ? [updatedStartRiskObject, ...prevRisks]
              : [updatedStartRiskObject];
          });
          onClose();

          setTotalRunningRisk(runningRisks.length + 1);
          if (runningRisks.length + 1 > 0) {
            setCurrentAction(updatedStartRiskObject?.current_action);
            setSelectedRisk(updatedStartRiskObject);
          } else {
            setCurrentAction(null);
            setSelectedRisk(null);
          }
          notification.success({
            message: `Start Risk successful `,
            placement: "bottomRight",
          });
        } else {
          if (
            createKey(copilotName) === createKey("inbound freight management")
          ) {
            const updatedStartRiskObject=updateIds(Inbound_Freight_start_Risk)

            setRunningRisks((prevRisks: any) => {
              return prevRisks
                ? [updatedStartRiskObject, ...prevRisks]
                : [updatedStartRiskObject];
            });
            setRunningRisksFilter((prevRisks: any) => {
              return prevRisks
                ? [updatedStartRiskObject, ...prevRisks]
                : [updatedStartRiskObject];
            });
            onClose();

            setTotalRunningRisk(runningRisks.length + 1);
            if (runningRisks.length + 1 > 0) {
              setCurrentAction(updatedStartRiskObject?.current_action);
              setSelectedRisk(updatedStartRiskObject);
            } else {
              setCurrentAction(null);
              setSelectedRisk(null);
            }
            notification.success({
              message: `Start Risk successful `,
              placement: "bottomRight",
            });
          } else {
            form
              .validateFields()
              .then(async (values) => {
                // Transform the context string into JSON object
                const contextObject: Record<string, any> = {};
                values.context.split(",").forEach((pair: string) => {
                  const [key, value] = pair.trim().split(":");
                  const trimmedKey = key.replace(/"/g, "").trim();
                  const trimmedValue = value.trim();
                  contextObject[trimmedKey] = isNaN(Number(trimmedValue))
                    ? trimmedValue.replace(/"/g, "").trim()
                    : Number(trimmedValue);
                });
                const transformedValues = {
                  ...values,
                  context: contextObject,
                  due_date: values.due_date.toISOString(),
                };
                console.log("*** start risk args", transformedValues);
                await RunningRisksService.startRisk(transformedValues)
                  .then((res) => {
                  
                    notification.success({
                      message: `Start Risk successful , Risk Status :${res?.status}`,
                      placement: "bottomRight",
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                    notification.error({
                      message: "Failed to start risk",
                      placement: "bottomRight",
                    });
                  });

                form.resetFields();
                onClose();
              })
              .catch((errorInfo) => {
                console.error("Validation failed:", errorInfo);
              });
          }
        }
      }
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const getRiskConf = async () => {
    await risksServices
      .filterResource(
        { current: 1, pageSize: 10000 },
        [],
        ["model", "copilot"]
      )
      .then((response: any) => {
        if (createKey(copilotName) === createKey("fulfillment")) {
          setRiskConf([
            ...response?.items,
            ...[{ id: "123456987", name: "Fulfillment Shortage" }],
          ]);
        } else {
          if (createKey(copilotName) === createKey("supply matching")) {
            setRiskConf([
              ...response?.items,
              ...[{ id: "1dss23456987", name: "Supply Risk" }],
            ]);
          } else {
            if (createKey(copilotName) === createKey("demand matching")) {
              setRiskConf([
                ...response?.items,
                ...[{ id: "1dss234s56987", name: "Demand Fulfillment Risk" }],
              ]);
            } else {
              if (
                createKey(copilotName) ===
                createKey("inbound freight management")
              ) {
                setRiskConf([
                  ...response?.items,
                  ...[
                    { id: "1dss234s5699987", name: "Inbound Delivery delay" },
                  ],
                ]);
              } else {
                setRiskConf(response?.items);
              }
            }
          }
        }
      });
  };

  const validateContext = (_: any, value: string) => {
    const pattern =
      /^\s*"?\w+"?\s*:\s*(?:"[^"]*"|\d+)\s*(,\s*"?\w+"?\s*:\s*(?:"[^"]*"|\d+)\s*)*$/;

    try {
      // Remove new lines and trim spaces
      const cleanedValue = value.replace(/\n/g, "").trim();
      if (pattern.test(cleanedValue)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Invalid context format"));
    } catch (err) {
      console.error("Error parsing context:", err);
      return Promise.reject(new Error("Invalid context format"));
    }
  };
  const generateRandomId = (): string => {
    return Math.random().toString(36).slice(2, 11); 
  };

  const updateIds = (obj: any): any => {
    
    const updatedObject: any = {
      ...obj,
      id: generateRandomId(),
      current_action: {
        ...obj?.current_action,
        id: generateRandomId(),
        executions: obj?.current_action?.executions?.map((execution:any) => ({
          ...execution,
          id: generateRandomId(),
        })),
        actions: obj?.actions.map((action:any) => ({
          ...action,
          id: generateRandomId(),
        })),
      },
    };
  
    return updatedObject;
  };
  return (
    <Modal
      title="Start Risk"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
      
      >
        <Form.Item name="risk_conf_src_id" label="Risk Conf" required>
          <Select placeholder="Select an option">
            {riskConf?.map((item: any) => (
              <Option key={item?.id} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="due_date" label="Due date" required>
          <DatePicker showTime defaultValue={dayjs("2024-05-31 00:00:00")} />
        </Form.Item>
        <Form.Item
          name="context"
          label="Context"
          required
          rules={[
            {
              validator: validateContext,
            },
          ]}
        >
          <TextArea rows={6} placeholder="Enter text here" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StartRisk;
