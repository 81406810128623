import { useState } from "react";
import { Col, Modal, Row } from "antd";

import ChartCard from "./components/ChartCard";
import NewChartCard from "./components/new-chart/NewChartCard";
import NewChartModal from "./components/new-chart/NewChartModal";
import { WORK_MODE } from "../../../../../../constants/workModes";
import { ChartConf } from "../../interfaces/ChartConf";
import ChartDetailsDrawer from "./components/ChartDrawer";
import { ActionFields } from "../../constants/configs";
import { ChartParams } from "../charts/functions/ChartParams.interface";

interface ChartContentProps {
  copilotDataneededFields: any;
  chartConfigs: ChartConf[];
  setChartConfigs: (value: ChartConf[]) => void;
  data: any[];
  maxCards: number;
  style?: any;
  params: ChartParams;
}

export default function ChartContent(props: ChartContentProps) {
  /* charts configs */
  const {
    copilotDataneededFields,
    chartConfigs,
    setChartConfigs,
    maxCards,
    style,

    params,
  } = props;
  /* Add chart Modal */
  const [openChartConfModal, setOpenChartConfModal] = useState(false);
  const [workModeChartConfModal, setWorkModeChartConfModal] = useState(
    WORK_MODE.CREATE
  );
  const [recordChartConfModal, setRecordChartConfModal] = useState();
  const [recordIndexChartConfModal, setRecordIndexChartConfModal] = useState();
  /* Details Drawer */
  const [openDrawer, setOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState(false);

  const showDrawer = (data: any) => {
    setDataDrawer(data);
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const updateChartConf = (e_config: any, e_index: any) => {
    setChartConfigs(
      chartConfigs?.map((config, index) => {
        return e_index === index ? e_config : config;
      })
    );
    closeChartConfModal();
  };
  const deleteChartConf = (i: number) => {
    const categories = chartConfigs?.filter(
      (conf, index: number) => i !== index
    );
    setChartConfigs(categories);
  };
  const addChartConf = (n_config: ChartConf) => {
    setChartConfigs([...chartConfigs, n_config]);
    closeChartConfModal();
  };

  /* Open Chart Modal  */
  const addChartConfModal = () => {
    setWorkModeChartConfModal(WORK_MODE.CREATE);
    setOpenChartConfModal(true);
  };
  const updateChartModal = (conf: any, index: any) => {
    setRecordIndexChartConfModal(index);
    setRecordChartConfModal(conf);
    setWorkModeChartConfModal(WORK_MODE.UPDATE);
    setOpenChartConfModal(true);
  };
  const closeChartConfModal = () => {
    setOpenChartConfModal(false);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        {chartConfigs?.map((config, index) => {
          return (
            <Col key={index} sm={24} lg={12} xl={8}>
              <ChartCard
                index={index}
                config={config}
                viewChartModal={updateChartModal}
                updateChartModal={updateChartModal}
                deleteChartConfig={deleteChartConf}
                showDrawer={showDrawer}
                data={[]}
                style={style}
                fields={config?.fields}
                params={params}
              ></ChartCard>
            </Col>
          );
        })}
        {chartConfigs?.length < maxCards && (
          <Col sm={24} lg={12} xl={8}>
            <NewChartCard
              addChartConfModal={addChartConfModal}
              closeChartConfModal={closeChartConfModal}
            />
          </Col>
        )}
      </Row>

      <Modal
        destroyOnClose={true}
        title={
          workModeChartConfModal === WORK_MODE.CREATE
            ? "Add new chart"
            : "Update config chart"
        }
        open={openChartConfModal}
        onCancel={closeChartConfModal}
        width={1300}
        footer={null}
      >
        <NewChartModal
          copilotContextFields={copilotDataneededFields}
          workModeChartConfModal={workModeChartConfModal}
          recordChartConfModal={recordChartConfModal}
          recordIndexChartConfModal={recordIndexChartConfModal}
          addChartConfig={addChartConf}
          updateChartConfig={updateChartConf}
          closeChartConfModal={closeChartConfModal}
          params={params}
        />
      </Modal>
      <ChartDetailsDrawer
        openDrawer={openDrawer}
        onCloseDrawer={onCloseDrawer}
        data={dataDrawer}
      />
    </>
  );
}
