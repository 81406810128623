import { useContext, useEffect, useState } from "react";
import { Col, Form, notification, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import SaveCancel from "../../../../common/SaveCancel";
import { MainContext } from "../../../../../utils/context";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/userLogic";
import { SelectScopeKey } from "./scope/SelectScopeKey";
import SelectWithAddScopeValue from "./scope/SelectWithAddScopeValue";
import { IOptionDataNeeded } from "./scope/scope.interface";
import { getTranslation } from "../../../../../utils/transaltion";
import { RootState } from "../../../../../redux/store/store";
import ScopesService from "../../../../../services/scopes.service";
import { Scope } from "../../../../../interfaces/scope";
import { SharedContext } from "../../context/sharedContext";
import { extractDataNeededFromURL } from "../../../predefineds/logic/PredefinedsLogic";

const INPUT = "INPUT";
const OUTPUT = "OUTPUT";
type IScopeType = typeof INPUT | typeof OUTPUT;

function ConfigScopesModal(props: any) {
  /** global app context */
  const globalContext = useContext(MainContext);
  /** users shared logic */
  const { closeScopeModal, handleCreateUpdateScope } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  const { userScopes } = useContext(SharedContext);
  /** get predefined actions from redux */
  const predefinedActions: any[] = useSelector(
    (state: RootState) => state.predefinedActionOrgaReducer
  )?.filter((item: any) => item?.enabled === true);

  const { t } = useTranslation();
  /** local vars */
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [formScope] = Form.useForm();
  /* manage scope key */
  const [scopeType, setScopeType] = useState<IScopeType>(INPUT);
  const [scopeKey, setScopeKey] = useState<string>();
  const [scopeValues, setScopeValues] = useState<string[]>([]);
  const [suggestedScopeValues, setSuggestedScopeValues] = useState<string[]>(
    []
  );
  const [isScopeValueLoading, setIsScopeValueLoading] = useState(false);

  // Concatenate lists without redundant elements
  function concatenateDataNeededLists(
    lists: IOptionDataNeeded[][]
  ): IOptionDataNeeded[] {
    const uniqueList: IOptionDataNeeded[] = [];
    lists?.forEach((list) => {
      list?.forEach((item) => {
        if (!uniqueList.find((data) => data.value === item.value)) {
          uniqueList.push(item);
        }
      });
    });
    return uniqueList;
  }

  const dataNeeded: {
    INPUT: IOptionDataNeeded[];
    OUTPUT: IOptionDataNeeded[];
  } = {
    INPUT: concatenateDataNeededLists(
      predefinedActions?.map((action) => {
        return extractDataNeededFromURL(action?.dataNeeded, ["CATEGORY"])
          ?.INPUT;
      })
    ),
    OUTPUT: concatenateDataNeededLists(
      predefinedActions?.map((action) => {
        return extractDataNeededFromURL(action?.dataNeeded, ["CATEGORY"])
          ?.OUTPUT;
      })
    ),
  };

  useEffect(() => {
    console.log(dataNeeded);
    //console.log(extractDataNeededFromURL(dataNeeded));
    scopeKey && getValuesByKey(scopeKey);
  }, [scopeKey]);

  if (!globalContext) {
    return <></>;
  }
  const scopesService = new ScopesService(
    globalContext.context,
    globalContext.setContext
  );

  const getValuesByKey = async (key: string, value?: string) => {
    setIsScopeValueLoading(true);
    const filters = value
      ? { key: { value: key, operator: "=" } }
      : {
          key: { value: key, operator: "=" },
          value: { value: value, operator: "has" },
        };
    await scopesService
      .filterResource(
        {
          pageSize: 300,
          sort: false,
        },
        filters
      )
      .then((response) => {
        setSuggestedScopeValues(
          response?.items
            ?.map((scope: Scope) => scope?.value)
            .concat(
              /* if all not used add to list */
              response?.items?.find((scope: Scope) => scope?.all) ? [] : [""]
            )
        );
        setIsScopeValueLoading(false);
      })
      .catch((err) => {
        notification.error({
          message: `${t("errors:Error happened while getting scope values")}`,
          placement: "bottomRight",
        });
        setIsScopeValueLoading(false);
      });
  };

  return (
    <Form
      name="wrap"
      form={formScope}
      {...{ labelCol: { span: 4 } }}
      labelAlign="left"
      labelWrap
      initialValues={{ inputOutput: scopeType }}
    >
      <div
        style={{
          marginTop: 10,
          display: "flex",
          marginBottom: 5,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ marginTop: 10 }}>
          {t("DETAILS", { item: t("SCOPE") })}
        </div>
      </div>
      <div style={{ marginInline: "50px" }}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={getTranslation("INPUT_/_OUTPOUT", "labels")}
              name="inputOutput"
              rules={[
                {
                  required: true,
                  message: t("errors:REQUIRED", { label: t("FIELD") }),
                },
              ]}
            >
              <Select
                style={{ width: 120 }}
                value={scopeType}
                defaultValue={scopeType}
                onChange={(value: IScopeType) => {
                  setScopeType(value);
                  formScope.resetFields(["tableName", "items"]);
                }}
                options={[
                  { value: "INPUT", label: "Input" },
                  { value: "OUTPUT", label: "Output" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* TableName */}
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <SelectScopeKey
              options={dataNeeded[scopeType]}
              value={scopeKey}
              setValue={(value: string) => {
                setScopeKey(value);
              }}
              form={formScope}
            />
          </Col>
        </Row>
        <Row>
          {/* Items  */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <SelectWithAddScopeValue
              options={suggestedScopeValues}
              userScopes={userScopes}
              scopeValues={scopeValues}
              scopeKey={scopeKey}
              setValues={setScopeValues}
              isScopeValueLoading={isScopeValueLoading}
              form={formScope}
            />
          </Col>
        </Row>
      </div>
      {
        <Row>
          <Form.Item>
            <SaveCancel
              confirmLoading={confirmLoading}
              onClickSave={() => {
                formScope
                  .validateFields()
                  .then(async (values) => {
                    handleCreateUpdateScope(
                      [
                        ...scopeValues?.map((value) => {
                          return {
                            key: scopeKey,
                            value: value,
                            all: !!!value,
                          };
                        }),
                      ],
                      scopeKey,
                      setConfirmLoading
                    );
                  })
                  .catch((err) => {});
              }}
              onClickCancel={() => {
                closeScopeModal();
              }}
            />
          </Form.Item>
        </Row>
      }
    </Form>
  );
}
export default ConfigScopesModal;
