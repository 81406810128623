import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormInstance, message, notification } from "antd";
import { MainContext } from "../../../../utils/context";
import { WORK_MODE } from "../../../../constants/workModes";
import {
  constructActionParametersObject,
  filterProperties,
} from "../../../../utils/functions";
import { Currencies } from "../data/data.currency";
import { PREDEFINED_TYPES } from "../../../../constants/predefinedTypes";
import { OrganisationItem } from "../../../../interfaces/organisation";

import { RootState } from "../../../../redux/store/store";

import { SharedContext } from "../context/sharedContext";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../utils/transaltion";
import {
  IDataNeeded,
  IOptionDataNeeded,
} from "../../../../interfaces/dataNeededURL";
import { GetChoicesByKeys } from "../../../../utils/ChoicesFunction";

import { LicenseItem } from "../../../../interfaces/license";
import { isPermission } from "../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../constants/permissions";
import OrganizationsService from "../../../../services/organizations.service";
import { setOrganizations } from "../../../../redux/reducers/organizations.Reducer";
import handleApiError from "../../../../services/functions/handle-api-errors/handleApiError";
import { REQUEST } from "../../../../services/functions/handle-api-errors/const";
import PredefinedActionsService from "../../../../services/predefined-actions.service";

export interface SharedContextFunctionsInterface {
  getDeletedItemNames: (
    recordItems: any[],
    originalArray: any[],
    id: string
  ) => Promise<void>;

  getActionParameters: (mode: string, record: any) => Promise<void>;
  getEnumsOrganization: () => Promise<void>;
  handleFilterOrganization: (params: any) => Promise<void>;
  getListOrganization: () => Promise<void>;
  goToConfig: (
    record: any,
    mode: string,
    fromExpiredLicense?: boolean
  ) => Promise<void>;
  goToTable: () => Promise<void>;
  onFinish: (form: FormInstance<any>, id?: string) => Promise<void>;
  Delete: (
    id: any,
    setConfirmDeleteLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
}

export const useSharedContextFunctions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  /**confs shared context */
  const {
    isDisable,
    workMode,
    List,
    setList,
    setTotal,
    listToFilter,
    setListToFilter,
    setGetLoading,
    setValueEnumCurrency,
    setDynamicFieldsSettings,
    dynamicFieldsSettings,
    setSettingsChoicesList,
  } = useContext(SharedContext);
  /** roles from redux */
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );

  const copilotList: any[] = useSelector(
    (state: RootState) => state.userCopilotReducer
  );
  /** get predefined actions from redux */
  const predefinedActionList = useSelector(
    (state: RootState) => state.predefinedActionOrgaReducer
  );
  const currenciesList = Object.entries(Currencies).map(([id, name]) => ({
    id,
    name,
  }));
  /**global context */
  const globalContext = useContext(MainContext);
  if (!globalContext) {
    return <></>;
  }
  const goToConfig = async (
    record: any,
    mode: string,
    fromExpiredLicense?: boolean
  ): Promise<void> => {
    let myOrga = JSON.parse(JSON.stringify(record));
    if (fromExpiredLicense) {
      const orgaLicenses: LicenseItem[] =
        globalContext?.context?.orga?.licenses;
      myOrga.licenses = orgaLicenses?.map((license) => {
        return {
          ...license,
          copilots: license?.copilots?.map((copilot) => {
            return copilotList?.find((c) => c?.id === copilot.id);
          }),
        };
      });
      navigate("/menu/view_organization", {
        state: {
          record: myOrga,
          mode: mode,
          fromExpiredLicense: fromExpiredLicense,
        },
      });
    } else {
      navigate("/menu/add_organization", {
        state: {
          record: record,
          mode: mode,
          fromExpiredLicense: fromExpiredLicense,
        },
      });
    }
  };

  const goToTable = async () => {
    navigate("/menu/organization");
  };

  const Delete = async (
    id: any,
    setConfirmDeleteLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setConfirmDeleteLoading(true);
    await predefinedOrganisationServices
      .deleteResource(id)
      .then((res) => {
        const updatedOrganisationList: Array<OrganisationItem> = List.filter(
          (Organisation: OrganisationItem) => Organisation.id !== id
        );
        getListOrganization();
        setConfirmDeleteLoading(false);
        notification.success({
          message: t("errors:DELETE_SUCCESS"),
          placement: "bottomRight",
        });
      })
      .catch((err) => {
        setConfirmDeleteLoading(false);
        handleApiError(err, REQUEST.DELETE);
      });
  };

  const predefinedActionServices = new PredefinedActionsService(
    globalContext.context,
    globalContext.setContext,
    ""
  );

  const predefinedOrganisationServices = new OrganizationsService(
    globalContext.context,
    globalContext.setContext
  );

  const getListOrganization = async () => {
    setGetLoading(true);
    await predefinedOrganisationServices
      .getResources({ pageSize: 1000 })
      .then((res) => {
        setGetLoading(false);
        dispatch(setOrganizations(res?.items));
        setList(res?.items);
        setListToFilter(res?.items);
        setTotal(res?.total);
      })
      .catch((err) => {
        setGetLoading(false);
        handleApiError(err);
      });
    setGetLoading(false);
  };

  const onFinish = async (form: FormInstance<any>, id?: string) => {
    setGetLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        values["enabled"] = isDisable;

        if (workMode === WORK_MODE.CREATE) {
          let args = filterProperties(values);
          args["actionSettings"] = {};
          await predefinedOrganisationServices
            .createResource(args)
            .then((res) => {
              form.resetFields();
              getListOrganization();
              notification.success({
                message: t(`errors:CREATE_SUCCESS`),
                placement: "bottomRight",
              });
              setGetLoading(false);
              navigate("/menu/organization");
            })
            .catch((err) => {
              setGetLoading(false);
              handleApiError(err, REQUEST.CREATE);
            });
        } else {
          let argsSettings = JSON.parse(
            JSON.stringify(filterProperties(values))
          );
          let args = filterProperties(values);
          const argsActionSetting = constructActionParametersObject(
            dynamicFieldsSettings
          );

          argsSettings["actionSettings"] = argsActionSetting;
          delete argsSettings["name"];
          delete argsSettings["description"];
          delete argsSettings["emailAdmin"];
          delete argsSettings["enabled"];
          if (isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)) {
            await predefinedOrganisationServices
              .updateResource(id || "", args)
              .then(async (res: any) => {
                form.resetFields();
                if (
                  !(
                    globalContext.context.orga.id === id &&
                    isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)
                  )
                ) {
                  setGetLoading(false);
                  goToTable();
                  notification.success({
                    message: t("errors:UPDATE_SUCCESS"),
                    placement: "bottomRight",
                  });
                }

                if (
                  globalContext.context.orga.id === id &&
                  isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)
                ) {
                  await predefinedOrganisationServices
                    .updateSettingsOrganisation(id || "", argsSettings)
                    .then((res: any) => {
                      navigate(-1);
                      setGetLoading(false);
                      notification.success({
                        message: t("errors:UPDATE_SUCCESS"),
                        placement: "bottomRight",
                      });
                      //  setWorkMode(WORK_MODE.VIEW);
                    })
                    .catch((err: any) => {
                      navigate(-1);
                      setGetLoading(false);
                      handleApiError(err, REQUEST.UPDATE);
                    });
                }
              })
              .catch((err) => {
                setGetLoading(false);
                if (err?.code === "ERR_NETWORK") {
                  notification.error({
                    message: "Network Error",
                    placement: "bottomRight",
                  });
                } else {
                  notification.error({
                    message: t("errors:UPDATE_FAILED"),
                    placement: "bottomRight",
                  });
                }
              });
          } else {
            if (isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)) {
              await predefinedOrganisationServices
                .updateSettingsOrganisation(id || "", argsSettings)
                .then(async (res: any) => {
                  //  await get_orga();
                  navigate(-1);
                  setGetLoading(false);
                  notification.success({
                    message: t("errors:UPDATE_SUCCESS"),
                    placement: "bottomRight",
                  });
                  //  setWorkMode(WORK_MODE.VIEW);
                })
                .catch((err: any) => {
                  navigate(-1);
                  setGetLoading(false);
                  handleApiError(err, REQUEST.UPDATE);
                });
            }
          }
        }
      })
      .catch((err) => {
        setGetLoading(false);
        if (err?.code === "ERR_NETWORK") {
          notification.error({
            message: t("errors:NETWORK_ERROR"),
            placement: "bottomRight",
          });
        } else {
          if (err?.errorFields) {
            message.error(t("errors:REQUIRED_FIELDS"));
          } else {
            message.error(t("errors:CREATE_FAILED") + " " + err);
          }
        }
      });
  };

  const getEnumsOrganization = async (): Promise<void> => {
    const updatedEnumCopliot: { [key: string]: { text: string } } = {};
    currenciesList?.forEach((curr: any) => {
      updatedEnumCopliot[curr.id] = {
        text: getTranslation(curr.name, "data"),
      };
    });

    setValueEnumCurrency(updatedEnumCopliot);
  };

  const handleFilterOrganization = (params: any) => {
    let { name, description, currency } = params;

    // trim all  spaces at the beginning and end
    params = Object.fromEntries(
      Object.entries(params).map(([key, value]) => [
        key,
        typeof value === "string" ? value.trim() : value,
      ])
    );
    if (!name && !description && !currency) {
      getListOrganization();
    } else {
      if (!listToFilter) {
        console.error("ListFilter is not defined.");
        return;
      }

      let OrganizationListFiltered = listToFilter
        ?.filter((item: any) => {
          return (
            (!name ||
              item?.name?.toLowerCase()?.includes(name.toLowerCase())) &&
            (!description ||
              item?.description
                ?.toLowerCase()
                .includes(description?.toLowerCase())) &&
            (!currency || item?.currency?.includes(currency))
          );
        })
        .map((filteredItem: any) => {
          return {
            ...filteredItem,
          };
        });
      setTotal(OrganizationListFiltered.length);

      setList(OrganizationListFiltered);
    }
  };
  const findFirstParameterByKey = (
    PRedefinedActionList: any[],
    searchKey: string
  ): Record<string, any> | null => {
    for (const item of PRedefinedActionList) {
      const parameters = item.actionParameters || {};
      if (parameters.hasOwnProperty(searchKey)) {
        return parameters[searchKey]; // Return the first matched object
      }
    }

    return null;
  };
  const getActionParameters = async (mode: string, record: any) => {
    setDynamicFieldsSettings([]);
    if (mode === WORK_MODE.VIEW) {
      await predefinedOrganisationServices
        .getOrganizationExistingSettings(record.id)
        .then((response) => {
          const updatedRecord = { ...response };

          const dynamicFieldsSettingsValues = Object.keys(
            updatedRecord?.actionSettings || {}
          ).map((key, index) => {
            const defaultValue = response?.actionSettings[key];
            const parameter = findFirstParameterByKey(
              predefinedActionList,
              key
            );

            return {
              key: index + 1,
              defaultValue: defaultValue
                ? defaultValue
                : parameter?.defaultValue || "",
              name: parameter?.name || "",
              type: parameter?.type || "STRING",
              suffix: key,
              choice: parameter?.choice || false,
            };
          });

          if (dynamicFieldsSettingsValues.length === 0) {
            setDynamicFieldsSettings([]);
          } else {
            setDynamicFieldsSettings(dynamicFieldsSettingsValues);
          }
        })
        .catch((err) => {
          setDynamicFieldsSettings([]);
          handleApiError(err);
        });
    } else {
      if (mode === WORK_MODE.CREATE) {
        setDynamicFieldsSettings([]);
      } else {
        if (mode === WORK_MODE.UPDATE) {
          await predefinedOrganisationServices
            .getOrganizationPossibleSettings(record?.id)
            .then(async (res: any) => {
              await predefinedOrganisationServices
                .getOrganizationExistingSettings(record.id)
                .then((response) => {
                  const currentSettingsOrga = response;
                  // get settings choices
                  GetChoicesByKeys(
                    res,
                    predefinedActionServices,
                    setSettingsChoicesList
                  );
                  // update
                  // take  what is in record +predefined action
                  const mergedObject: any = { ...(res || {}) };

                  Object.keys(
                    currentSettingsOrga?.actionSettings || {}
                  ).forEach((key) => {
                    const recordItem = currentSettingsOrga?.actionSettings[key];
                    const resItem = res[key];
                    if (!resItem) {
                      // If the key is not in res, skip it
                      return;
                    }
                    // Take  value from record and update the mergedObject
                    mergedObject[key] = {
                      ...resItem,
                      type: mergedObject[key].type,
                      defaultValue: recordItem,
                      choice: mergedObject[key].choice ?? false,
                    };
                  });
                  setDynamicFieldsSettings(mergedObject);
                  const dynamicFieldsSettingsValues = Object.keys(
                    mergedObject
                  ).map((key, index) => ({
                    key: index + 1,
                    defaultValue: mergedObject[key].defaultValue,
                    name: mergedObject[key].name,
                    type: mergedObject[key].type,
                    suffix: key,
                    choice: mergedObject[key].choice ?? false,
                  }));
                  if (dynamicFieldsSettingsValues.length !== 0) {
                    setDynamicFieldsSettings(dynamicFieldsSettingsValues);
                  } else {
                    setDynamicFieldsSettings([]);
                  }
                })
                .catch((err) => {
                  setDynamicFieldsSettings([]);
                  handleApiError(err);
                });
            })
            .catch((err: any) => {
              setGetLoading(false);
              handleApiError(err);
            });
        }
      }
    }
  };

  return {
    getActionParameters,
    handleFilterOrganization,
    getEnumsOrganization,
    Delete,
    onFinish,
    goToConfig,
    goToTable,
    getListOrganization,
  };
};
export const extractDataNeededFromURL = (
  dataNeeded: any,
  hide?: string[]
): IDataNeeded => {
  const result: IDataNeeded = {
    INPUT: [],
    OUTPUT: [],
  };

  const transformAttributes = (data: any, output: IOptionDataNeeded[]) => {
    for (const key in data) {
      const obj = data[key];
      const attribute: IOptionDataNeeded = {
        value: key,
        type: obj.TYPE || "UNKNOWN",
        is_taggable: obj.IS_TAGGABLE || false,
      };

      // If it's an array, process its attributes recursively
      if (obj.TYPE === "ARRAY" || obj.TYPE === "OBJECT") {
        const children: IOptionDataNeeded[] = [];
        for (const subKey in obj) {
          if (
            subKey !== "TYPE" &&
            subKey !== "IS_TAGGABLE" &&
            (!hide || !hide.includes(subKey))
          ) {
            children.push({
              value: subKey,
              type: obj[subKey].TYPE || "UNKNOWN",
              is_taggable: obj[subKey].IS_TAGGABLE || false,
            });
          }
        }
        attribute.children = children;
      }

      output.push(attribute);
    }
  };

  transformAttributes(dataNeeded.INPUTS, result.INPUT);
  transformAttributes(dataNeeded.OUTPUTS, result.OUTPUT);
  return result;
};
