import React, { useContext } from "react";
import { Button, Space, Popconfirm, FormInstance } from "antd";
import { WORK_MODE } from "../../../../../constants/workModes";
import { Form } from "antd";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/riskLogic";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";
import { SharedContext } from "../../context/sharedContext";

const FooterComponent: React.FC<{
  workModeRisk: any;
  form: FormInstance<any>;
  onFinish: () => Promise<void>;
  loadingSave: boolean;
}> = (props) => {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  const { t } = useTranslation();
  const { form, onFinish, loadingSave } = props;
  const { workModeRisk } = useContext(SharedContext);
  /** get shared logic */
  const { goToRiskTable } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  return (
    <div
      style={{
        marginInlineStart: -23,
        backgroundColor: "white",
        zIndex: 99,
        position: "fixed",
        bottom: 0,
        width: "100%",
      }}
    >
      <div
        style={{
          marginBottom: 15,
          marginTop: 5,
          alignSelf: "left",
          marginInlineStart: 5,
          width: "30%",
          zIndex: 99999,
          height: 25,
        }}
      >
        <Space size={"small"}>
          {workModeRisk !== WORK_MODE.VIEW &&
            isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) && (
              <Popconfirm
                placement="top"
                key="delete"
                style={{ zIndex: 9999999 }}
                disabled={false}
                title={t("errors:CANCEL_LEAVING_PAGE_MESSAGE")}
                onConfirm={async () => {
                  await goToRiskTable();
                }}
                okText={t("YES")}
                cancelText={t("NO")}
              >
                <Button
                  className="cancel-button"
                  onClick={() => {
                    if (
                      form.isFieldsTouched() &&
                      workModeRisk !== WORK_MODE.VIEW
                    ) {
                    } else {
                      goToRiskTable();
                    }
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </Popconfirm>
            )}
          {workModeRisk !== WORK_MODE.VIEW &&
            isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) && (
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                className="save-button"
                loading={loadingSave}
                onClick={onFinish}
              >
                {t("SAVE")}
              </Button>
            )}
        </Space>
      </div>

      <div style={{ marginBottom: 5, alignSelf: "right" }}></div>
    </div>
  );
};

export default FooterComponent;
