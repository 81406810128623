import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { PreDefinedExecutionItem } from "../../interfaces/pre-defined-execution";

const initialState: Array<PreDefinedExecutionItem> = [];

export const predefinedExecutionGlobalSlice = createSlice({
  name: "predefined_Executions_Global",
  initialState,
  reducers: {
    addPredefinedExecutionGlobal: (
      state,
      action: PayloadAction<PreDefinedExecutionItem>
    ) => {
      state.push(action.payload);
    },
    setPredefinedExecutionGlobal: (
      state,
      action: PayloadAction<Array<PreDefinedExecutionItem>>
    ) => {
      return action.payload;
    },
    updatePreDefinedExecutionGlobal: (
      state,
      action: PayloadAction<PreDefinedExecutionItem>
    ) => {
      console.warn(action.payload);
      const { id } = action.payload;
      const index = state.findIndex(
        (preDefinedExecution) => preDefinedExecution.id === id
      );

      if (index !== -1) {
        const updatedState = state.map((item, i) =>
          i === index ? { ...item, ...action.payload } : item
        );

        // console.warn("Updated State:", updatedState);
        return updatedState;
      }

      return state;
    },
  },
});
export const {
  addPredefinedExecutionGlobal,
  setPredefinedExecutionGlobal,
  updatePreDefinedExecutionGlobal,
} = predefinedExecutionGlobalSlice.actions;

export const PredefinedExecutionGlobalSelector = (state: RootState) =>
  state.predefinedExecutionGlobalReducer;

export default predefinedExecutionGlobalSlice.reducer;
