import { useContext, useEffect, useRef } from "react";
import { Button, Spin } from "antd";
import type { ActionType } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { MainContext } from "../../../../utils/context";
import { WORK_MODE } from "../../../../constants/workModes";
import { SharedContext } from "../context/sharedContext";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/PredefinedsLogic";
import { PREDEFINED_TYPES } from "../../../../constants/predefinedTypes";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import PredefinedsTableDef from "../columnDef/PredefinedsTableDef";

interface Props {
  typeComponent: string;
}

const ParametersTableComponent: React.FC<Props> = ({
  typeComponent,
}: Props) => {
  const globalContext = useContext(MainContext);
  const { t } = useTranslation();
  const actionRef = useRef<ActionType>();
  const {
    getList,
    goToConfig,
    handleFilterAction,
    handleFilterExecution,
    handleFilterRisk,
    getEnums,
  } = useSharedContextFunctions() as SharedContextFunctionsInterface;
  const {
    setWorkMode,
    getLoading,
    List,
    setList,
    total,
    setDynamicFieldsSettings,
    setDynamicFields,
    setDataNeededURL,
    form,
  } = useContext(SharedContext);

  useEffect(() => {
    if (!globalContext || !globalContext.context.orga) {
      return;
    }
    setList([]);
    getEnums(typeComponent);
  }, [globalContext, typeComponent]);

  const columns = PredefinedsTableDef({ typeComponent });

  return (
    <Spin spinning={getLoading} size="large" tip="Loading...">
      <ProTable
        key={typeComponent}
        columns={columns}
        actionRef={actionRef}
        cardBordered
        dataSource={List}
        request={(params = {}): any => {
          const {
            name,

            role,
            copilot,
            action,
            execution,
            enabled,
          } = params;

          switch (typeComponent) {
            case PREDEFINED_TYPES.EXECUTION_PREDEFINED:
              handleFilterExecution({ name, role, enabled });
              break;
            case PREDEFINED_TYPES.ACTION_PREDEFINED:
              handleFilterAction({
                name,
                execution,
                role,
                enabled,
              });
              break;
            case PREDEFINED_TYPES.RISK_PREDEFINED:
              handleFilterRisk({
                name,
                copilot,
                action,
                enabled,
              });
              break;
            default:
              break;
          }
        }}
        onReset={() => {
          getList(typeComponent);
        }}
        editable={{
          type: "multiple",
        }}
        columnsState={{
          persistenceKey: "pro-table-singe-demos",
          persistenceType: "localStorage",
        }}
        rowKey="id"
        search={{
          span: { xs: 24, sm: 12, md: 12, lg: 10, xl: 9, xxl: 8 },
          searchText: t("SEARCH_TEXT_BUTTON"),
          resetText: t("RESET_TEXT_BUTTON"),
          className: "custom-search-form",
          labelWidth: "auto",
        }}
        options={{
          setting: {
            listsHeight: 400,
          },
        }}
        form={{
          syncToUrl: (values, type) => {
            if (type === "get") {
              return {
                ...values,
              };
            }
            return values;
          },
        }}
        pagination={{
          pageSize: 20,
          total: total,
        }}
        toolBarRender={() => [
          <Button
            type="primary"
            className="button-add-codif"
            icon={<PlusOutlined />}
            onClick={() => {
              setDynamicFields([]);
              setDynamicFieldsSettings([]);
              form.resetFields();
              setWorkMode(WORK_MODE.CREATE);
              setDataNeededURL({});
              goToConfig(typeComponent, {}, WORK_MODE.CREATE);
            }}
          >
            {typeComponent === PREDEFINED_TYPES.RISK_PREDEFINED
              ? t("ADD_PREDEFINED_RISK")
              : typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED
              ? t("ADD_PREDEFINED_ACTION")
              : typeComponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED &&
                t("ADD_PREDEFINED_EXECUTION")}
          </Button>,
        ]}
        dateFormatter="string"
        headerTitle={t(typeComponent)}
      />
    </Spin>
  );
};

export default ParametersTableComponent;
