export const mapTop10Accepted = (data: any) => {
  const mappedData = data
    ?.map((obj: any) => {
      return {
        action_type: obj.action_type,
        risk_status: obj.risk_status,
        risk_location: obj.risk_location,
      };
    })
    ?.filter((obj: any) => obj.risk_status === "Accepted")
    ?.reduce((accumulator: any, item: any) => {
      const { action_type, risk_location } = item;
      const key = `${action_type}_${risk_location}`;

      if (!accumulator[key]) {
        accumulator[key] = {
          action_type,
          risk_location: risk_location.toString(),
          value: 0,
        };
      }

      accumulator[key].value += 1;

      return accumulator;
    }, {});
  return Object.values(mappedData);
};
