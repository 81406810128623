import React from "react";
import NotFound from "../components/result/404.component";
import { isPermission } from "./permissionsFunctions";
import { Permission } from "../interfaces/permissions";

interface ProtectedRouteProps {
  permissionsList: Permission[];
  permissionsRequired: string;
  element: React.ReactElement<any, any> | null;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  permissionsList,
  permissionsRequired,
  element,
}) => {
  return isPermission(permissionsList, permissionsRequired) ? (
    element
  ) : (
    <NotFound />
  );
};

export default ProtectedRoute;
