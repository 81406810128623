import React, { useContext } from "react";
import { Layout, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import "./chat.css";
import { MainContext } from "../../../utils/context";
import { t } from "i18next";
import { HAS_GPT } from "../../../constants/server";
import GPTChatComponent from "./GPTChatComponent";
import StandardChatComponent from "./StandardChatComponent";

const { Text } = Typography;

const ChatComponent: React.FC = () => {
  const globalContext = useContext(MainContext);

  return (
    <div>
      <Layout className="app-container">
        <Content style={{ padding: "60px" }}>
          <Row gutter={[16, 16]} justify="center">
            <div
              className="custom-text"
              style={{
                textAlign: "center",
                fontFamily: "Century Gothic Paneuropean",
                gap: "12px",
              }}
            >
              <Text
                style={{
                  marginBlockEnd: "10px",
                  height: "84px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <span className="gradient-text">
                  {`${t("HELLO")} ${
                    globalContext?.context?.user?.displayname || ""
                  }!`}
                </span>
              </Text>
              <Text
                style={{
                  marginBlockEnd: "10px",
                  height: "84px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <span className="sub-title">
                  {t("LANDING_PAGE_HOW_CAN_I_ASSIST_YOU")}
                </span>
              </Text>
            </div>
          </Row>
          <br></br>
          {HAS_GPT ? <GPTChatComponent /> : <StandardChatComponent />}
        </Content>
      </Layout>
    </div>
  );
};

export default ChatComponent;
