import { useState } from "react";
import { Collapse, Form, Button, Input, Row, Col, Checkbox } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { CODIFICATION_TYPES } from "../../../../constants/condificationTypes";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/codificationLogic";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/store";
import { Reason } from "../../../../interfaces/reasons";
import { useTranslation } from "react-i18next";
import { createKey } from "../../../../utils/transaltion";
import { isPermission } from "../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../constants/permissions";
import IconSelect from "./CodificationsSelectIcons";
import { WORK_MODE } from "../../../../constants/workModes";

const CodificationCreation = (props: any) => {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const [isSystem, setIsSystem] = useState(false);
  const [iconName, setIconName] = useState<string | null>(null);
  const [collapseActiveKey, setCollapseActiveKey] = useState<string | string[]>(
    []
  );
  const { codificationtype } = props;
  const mdColSaveButton =
    codificationtype === CODIFICATION_TYPES.EXECUTION_REASONS ||
    codificationtype === CODIFICATION_TYPES.RISK_REASONS ||
    codificationtype === CODIFICATION_TYPES.ACTION_REASONS
      ? 14
      : codificationtype === CODIFICATION_TYPES.COPILOTS
      ? 9
      : 16;
  /** get shared logic */
  const { CreateCodif, handleCollapseChange } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  const handleAddButtonClick = () => {
    setCollapseActiveKey(collapseActiveKey ? [] : ["1"]);
  };
  /** codif lists from redux */
  const Roles = useSelector((state: RootState) => state.roleReducer);
  const copilotRedux = useSelector((state: RootState) => state.copilotReducer);
  /** reasons from redux */
  const reasonsAction: Reason[] = useSelector(
    (state: RootState) => state.reasonsActionsReducer
  );
  const reasonsExec: Reason[] = useSelector(
    (state: RootState) => state.reasonsExecutionsReducer
  );
  const reasonsRisks: Reason[] = useSelector(
    (state: RootState) => state.reasonsRisksReducer
  );
  const valuesNames =
    codificationtype === CODIFICATION_TYPES.ACTION_REASONS
      ? reasonsAction
      : codificationtype === CODIFICATION_TYPES.EXECUTION_REASONS
      ? reasonsExec
      : codificationtype === CODIFICATION_TYPES.RISK_REASONS
      ? reasonsRisks
      : codificationtype === CODIFICATION_TYPES.COPILOTS
      ? copilotRedux
      : Roles;
  return (
    <Collapse
      bordered={false}
      collapsible="header"
      expandIcon={({ isActive }) => (
        <CloseOutlined
          style={!isActive ? { color: "white" } : { color: "grey" }}
        />
      )}
      style={{ background: "#ffffff" }}
      activeKey={collapseActiveKey}
      onChange={(keys: string | string[]) =>
        // to reset the fields
        handleCollapseChange(keys, setCollapseActiveKey, form, setIsSystem)
      }
    >
      <Panel
        header={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {!collapseActiveKey.length ? (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="button-add-codif"
                onClick={handleAddButtonClick}
              >
                {codificationtype === CODIFICATION_TYPES.ACTION_REASONS ||
                codificationtype === CODIFICATION_TYPES.RISK_REASONS ||
                codificationtype === CODIFICATION_TYPES.EXECUTION_REASONS
                  ? t("ADD_REASON_CODE")
                  : codificationtype === CODIFICATION_TYPES.COPILOTS
                  ? t("ADD_COPILOT")
                  : t("ADD_ROLE")}{" "}
              </Button>
            ) : null}
          </div>
        }
        key="1"
      >
        <Form form={form} layout="horizontal">
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label={t("NAME_FIELD")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("errors:REQUIRED", {
                      label: t("labels:NAME_FIELD"),
                    }),
                  },
                  { max: 40 },
                  {
                    validator: (_, value) => {
                      const dataNameValues = valuesNames?.map(
                        (item: any) => item.name
                      );

                      if (dataNameValues.includes(createKey(value))) {
                        return Promise.reject(t("NAME_USED"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {codificationtype === CODIFICATION_TYPES.COPILOTS && (
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  label={t("ICONS")}
                  name="uiConfiguration"
                  {...{ labelCol: { style: { width: 50 } } }}
                >
                  <IconSelect
                    size="28px"
                    setIconName={setIconName}
                    workMode={WORK_MODE.CREATE}
                  />
                </Form.Item>
              </Col>
            )}

            {(codificationtype === CODIFICATION_TYPES.EXECUTION_REASONS ||
              codificationtype === CODIFICATION_TYPES.RISK_REASONS ||
              codificationtype === CODIFICATION_TYPES.ACTION_REASONS) && (
              <Col xs={24} sm={12} md={2}>
                <Form.Item label={t("SYSTEM")} name="is_system">
                  <Checkbox
                    checked={isSystem}
                    onChange={(e) => {
                      setIsSystem(e.target.checked);
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            {isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN) && (
              <Col xs={24} sm={24} md={mdColSaveButton}>
                <Form.Item>
                  <Button
                    type="text"
                    className="save-button"
                    onClick={() =>
                      CreateCodif(codificationtype, form, isSystem, iconName)
                    }
                  >
                    {t("SAVE")}
                  </Button>
                  <Button
                    className="cancel-button-codif"
                    onClick={() => {
                      form.resetFields();
                      setCollapseActiveKey(collapseActiveKey ? [] : ["1"]);
                      setIsSystem(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
};

export default CodificationCreation;
