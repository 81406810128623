import { Table, TableProps, Tag } from "antd";
import { t } from "i18next";
import moment from "moment";
import { getTranslation } from "../../../../../utils/transaltion";
import { PAGES_SIZE } from "../../../../../constants/pagination";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";

export default function OrganizationLicensesList(props: any) {
  const { orga } = props;
  const copilotList = useSelector(
    (state: RootState) => state.userCopilotReducer
  );
  const columns: TableProps<any>["columns"] = [
    {
      title: t("Start date"),
      dataIndex: "start",
      render: (text, record) => {
        const start = new Date(record.start);
        return moment(start).format(orga?.formatDate);
      },
    },
    {
      title: t("Expiry date"),
      dataIndex: "expiry",
      align: "left",
      render: (text, record) => {
        return <>{moment(record?.expiry).format(orga?.formatDate)}</>;
      },
    },
    {
      title: getTranslation("STATUS", "labels"),
      dataIndex: "status",
      render: (text, record) => {
        const startDate = moment(record?.start).startOf("day");
        const expiryDate = moment(record?.expiry).endOf("day");
        const nowDate = moment().endOf("day");
        return (
          <>
            {expiryDate.isBefore(nowDate) ? (
              <Tag color="red" style={{ marginInlineStart: "8px" }}>
                {getTranslation("EXPIRED", "labels")}
              </Tag>
            ) : startDate.isBefore(nowDate) ? (
              <Tag
                style={{
                  marginInlineStart: "8px",
                  color: "rgb(38, 177, 77)",
                  borderColor: "rgb(38, 177, 77, 0.6)",
                  backgroundColor: "rgb(128, 200, 148, 0.12)",
                }}
              >
                {getTranslation("ACTIVE", "labels")}
              </Tag>
            ) : (
              <Tag color="blue" style={{ marginInlineStart: "8px" }}>
                {getTranslation("UPCOMING", "labels")}
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: t("Copilots"),
      dataIndex: "copilots",
      render: (text, record) => {
        const copilotIds = record?.copilots?.map((copilot: any) => copilot?.id);
        const copilotNames = copilotList
          ?.filter((copilotObj: any) => copilotIds?.includes(copilotObj?.id))
          ?.map((copilotObj: any) => getTranslation(copilotObj.name, "data"))
          .join(", ");

        return copilotNames;
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={orga?.licenses}
      pagination={{ pageSize: PAGES_SIZE }}
    />
  );
}
