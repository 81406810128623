import { Card, Empty } from "antd";
import { CreateChart } from "../../../../../../../../assets/images";

const NewChartCard = (props: any) => {
  const { addChartConfModal } = props;

  return (
    <Card style={{ height: "100%" }} onClick={addChartConfModal}>
      <Empty
        style={{
          height: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        image={CreateChart}
        description={<span style={{ color: "#0082ba" }}>Add new chart</span>}
      />
    </Card>
  );
};

export default NewChartCard;
