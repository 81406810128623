import React, { useContext, useEffect } from "react";
import { Collapse, Form, FormInstance } from "antd";
import { SharedContext } from "../../context/sharedContext";
import {
  SharedContextFunctionsInterface,
  extractDataNeededFromURL,
  useSharedContextFunctions,
} from "../../logic/PredefinedsLogic";
import SettingsDynamicFields from "../dynamic-fields/SettingDynamicFields";
import { PREDEFINED_TYPES } from "../../../../../constants/predefinedTypes";
import { useTranslation } from "react-i18next";
import DataNeededTree from "../dynamic-fields/DataNeededURL";
import EmptyChartCard from "../../../../common/charts/EmptyChartCard";

const PredefinedActionSettings: React.FC<{
  formAction: FormInstance<any>;
  actionParameters: any;
}> = (props) => {
  /**props */
  const { formAction, actionParameters } = props;
  const { t } = useTranslation();
  const { workMode, Record, dataNeededURL, setDataNeededURL } =
    useContext(SharedContext);
  /** shared logic */
  const { handleUpdateAction } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;

  useEffect(() => {
    handleUpdateAction(Record);
  }, [workMode]);

  return (
    <>
      <div
        style={{
          marginTop: 40,
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <Collapse
          className="panel-style"
          accordion
          expandIconPosition="end"
          size="large"
          destroyInactivePanel={true}
          defaultActiveKey={"settings"}
        >
          <Collapse.Panel
            className="panel-style"
            header={
              <span style={{ color: "#2A333B" }}>{t("DATA_NEEDED")}</span>
            }
            key="settings"
          >
            {dataNeededURL && Object.keys(dataNeededURL).length !== 0 ? (
              <DataNeededTree
                workMode={workMode}
                dataNeededURL={extractDataNeededFromURL(dataNeededURL)}
                setDataNeededURL={setDataNeededURL}
                actionParameters={actionParameters}
              />
            ) : (
              <EmptyChartCard />
            )}
          </Collapse.Panel>
        </Collapse>
      </div>
      <div
        style={{
          marginTop: 40,
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <Collapse
          className="panel-style"
          accordion
          expandIconPosition="end"
          size="large"
          destroyInactivePanel={true}
          defaultActiveKey={"settings"}
        >
          <Collapse.Panel
            className="panel-style"
            header={
              <span style={{ color: "#2A333B" }}>
                {t(`labels:SETTINGS`, {
                  item: t("ACTION"),
                })}
              </span>
            }
            key="settings"
          >
            <Form
              form={formAction}
              name="wrap"
              {...{ labelCol: { style: { width: 200 } } }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
            >
              {dataNeededURL && Object.keys(dataNeededURL).length !== 0 ? (
                <SettingsDynamicFields
                  typeComponent={PREDEFINED_TYPES.ACTION_PREDEFINED}
                />
              ) : (
                <EmptyChartCard />
              )}
            </Form>
          </Collapse.Panel>
        </Collapse>
      </div>
    </>
  );
};

export default PredefinedActionSettings;
