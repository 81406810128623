import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

type Copilot = {
  id: string;
  name: string;
  uiConfiguration: any;
};
const initialState: Array<Copilot> = [];

export const copilotSlice = createSlice({
  name: "copilots",
  initialState,
  reducers: {
    addCopilot: (state, action: PayloadAction<Copilot>) => {
      state.push(action.payload);
    },
    setCopilots: (state, action: PayloadAction<Array<Copilot>>) => {
      return action.payload;
    },
    updateCopilot: (state, action: PayloadAction<Copilot>) => {
      const { id, name } = action.payload;
      const existingCopilot = state.find((copilot) => copilot.id === id);
      if (existingCopilot) {
        console.log(action.payload.uiConfiguration?.iconName);
        existingCopilot.name = name;
        existingCopilot["uiConfiguration"].iconName =
          action.payload?.uiConfiguration?.iconName;
      }
    },
  },
});
export const { addCopilot, setCopilots, updateCopilot } = copilotSlice.actions;

export const CopilotSelector = (state: RootState) => state.copilotReducer;

export default copilotSlice.reducer;
