import { message } from "antd";
import React, { useState, useEffect } from "react";
import { CiImageOff } from "react-icons/ci";
import { getTranslation } from "../../../../utils/transaltion";

interface IconProps {
  name: string;
  title: string;
  size?: string;
}

const CustomIcon: React.FC<IconProps> = ({ name, title, size = "210px" }) => {
  const [path, setPath] = useState<string | false>(false);

  const importIcon = async () => {
    try {
      const { default: _path } = await import(
        `../../../../assets/images/svg/${name}.svg`
      );
      setPath(_path);
    } catch (err) {
      message.error(getTranslation("ERROR_IMPORTING_IMAGE", "errors"));
    }
  };

  useEffect(() => {
    importIcon();
  }, [name]);

  return (
    <>
      {path ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img
            src={path}
            alt={`Icon: ${name}`}
            style={{ width: size, height: size }}
          />
          <p style={{ textAlign: "center", marginBlockStart: "-20px" }}>
            {title}
          </p>
        </div>
      ) : (
        <CiImageOff size={210} />
      )}
    </>
  );
};

export default CustomIcon;
