import { Waterfall, WaterfallConfig } from "@ant-design/plots";
import EmptyChartCard from "../../../../../common/charts/EmptyChartCard";
import { actionTypesColors } from "../../../../dashboards/common/chartColors";
import onChartClickEvent from "./functions/onChartClickEvent";
import { CHART_COLOR_TYPES } from "../chart-content/components/new-chart/NewChartModal";
import { colors } from "../../../../runnings-demo/data/colors";
import { dfyaPalette } from "../../../../../../constants/dfyaColorPallette";
import { getTranslation } from "../../../../../../utils/transaltion";
import { formatData } from "./functions/formatData";
import { ChartParams } from "./functions/ChartParams.interface";

interface MyColumnProps {
  data: any;
  fields: any;
  params: ChartParams;
  config?: any;

  showDrawer?: (v: any) => void;
}

const MyWaterfFallChart: React.FC<MyColumnProps> = ({
  data,
  fields,
  config,
  params,
  showDrawer,
}) => {
  if (data?.length === 0) {
    return <EmptyChartCard />;
  }
  const totalValue = data.reduce((sum: number, dataPoint: any) => {
    return sum + dataPoint["valueField"];
  }, 0);

  const my_config: WaterfallConfig = {
    appendPadding: [0, 0, 20, 0],
    data: data,
    xField: "categoryField",
    yField: "valueField",
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
        formatter: (categoryField) => {
          const words = categoryField.split(" ");
          words.splice(1, 0, "\n");
          words.splice(3, 0, "\n");
          return words.join(" ");
        },
      },
    },
    tooltip: {
      title(title, datum) {
        return (
          getTranslation(fields?.categoryField?.at(0)?.value, "data") +
          ":    " +
          formatData(datum?.categoryField, fields?.categoryField?.at(0), params)
            .data
        );
      },
      formatter: (datum: any) => {
        // Find the corresponding data point based on xField value
        const correspondingDataPoint = data?.find(
          (dataPoint: any) => dataPoint["categoryField"] === datum
        );
        return {
          name: getTranslation(fields?.valueField?.value, "data"),
          value: correspondingDataPoint
            ? formatData(
                correspondingDataPoint["valueField"],
                fields?.valueField,
                {
                  unit: config?.unit,
                  ...params,
                }
              ).data
            : datum === "Total" && totalValue,
        };
      },
    },

    total: {
      style: {
        fill: "#96a6a6",
      },
    },

    color:
      config?.pallet_type === CHART_COLOR_TYPES.PALLET
        ? dfyaPalette?.map((color: any) => color)
        : ({ categoryField: valueField }) =>
            config?.color_pallet?.find(
              (color: any) => color?.value === valueField
            )?.color,
    label: false,
    meta: {
      value: {
        formatter: (v) =>
          `${(v / (v > 99999 ? 1000000 : 1000)).toFixed(2)}${
            v > 99999 ? " m" : " K"
          }`,
      },
    },
    waterfallStyle: ({ action_type }) => {
      return {
        radius: 5,
        //stroke: actionTypesColors(action_type),
        lineWidth: 3,
        //fillOpacity: 0.5,
      };
    },
    columnWidthRatio: 0.8,
    legend: false,
    ...(showDrawer
      ? {
          onEvent: (chart, event) =>
            onChartClickEvent(chart, event, showDrawer),
        }
      : {}),
    style: {
      height: 250,
    },
    ...config,
  };

  return <Waterfall {...my_config} height={250} style={{ direction: "ltr" }} />;
};

export default MyWaterfFallChart;
