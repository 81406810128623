import { AppInsights } from "../parameters/microsoftInsightsSetup";

export const logError = (
  error: Error,
  customProperties?: Record<string, string>
): void => {
  try {
    // console.warn("logging error in AppInsights : ", error, customProperties);
    AppInsights.trackException({
      exception: error,
      properties: customProperties,
    });
  } catch (err) {
    console.error(" logging error failed");
    console.log(err);
  }
};
