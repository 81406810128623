import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";

const resource = { name: "predefined/actions", isPublic: true };

class PredefinedActionsService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(resource, context, setContext, path);
  }

  async getPredefinedAction() {
    const { data } = await this.get(
      `/organizations/${this?.context?.orga?.id}/predefined/actions?limit=500&offset=0&full=roles&full=reasons&full=executions&order=desc&sort=created_at `
    );
    return data;
  }
  async getPredefinedActionGlobal() {
    const { data } = await this.get(
      `/predefined/actions/?limit=500&offset=0&full=roles&full=reasons&full=executions&order=desc&sort=created_at`
    );
    return data;
  }
  async getSettingsChoices(args: string) {
    const { data } = await this.get(`predefined/actions/choices?${args}`);
    return data;
  }
  async deletePredefinedAction(args: string) {
    const { data } = await this.delete(`/predefined/actions/` + args);
    return data;
  }

  async createPredefinedAction(args: any) {
    const { data } = await this.post(`/predefined/actions/`, args);
    return { data };
  }

  async updatePredefinedAction(id: string, args: any) {
    let data = await this.patch(`/predefined/actions/` + id, args);
    return { data };
  }
  async syncPredefinedActionFromURL(args: any) {
    const { data } = await this.post(`/predefined/actions/data`, args);
    return { data };
  }
}

export default PredefinedActionsService;
