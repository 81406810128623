import {
  getWeek,
  daytoDateSinceReference,
} from "../../../../../../utils/myDatetime";

export const mapActionTypeOverTime = (data: any) => {
  const mappedData = data
    ?.map((obj: any) => {
      return {
        action_type: obj.action_type,
        risk_date: getWeek(
          daytoDateSinceReference(obj.risk_date)
        ).toLocaleDateString(), //obj.risk_date,
      };
    })
    ?.reduce((accumulator: any, item: any) => {
      const { action_type, risk_date } = item;
      const key = `${action_type}_${risk_date}`;

      if (!accumulator[key]) {
        accumulator[key] = { action_type, risk_date, value: 0 };
      }

      accumulator[key].value += 1;

      return accumulator;
    }, {});
  return Object.values(mappedData);
};
