import { NavigateFunction, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MainContext } from "../../../../../utils/context";
import { deepEqual } from "../../../../../utils/functions";
import { SharedContext } from "../../context/sharedContext";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { message, notification } from "antd";
import { WORK_MODE } from "../../../../../constants/workModes";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../utils/transaltion";
import RisksConfsService from "../../../../../services/risk-confs.service";
import handleApiError from "../../../../../services/functions/handle-api-errors/handleApiError";
import { REQUEST } from "../../../../../services/functions/handle-api-errors/const";

export interface SharedContextFunctionsInterface {
  handleKeysActionDnd: (workModeRisk: string) => Promise<void>;
  onFinishSaveRisk: (
    values: any,
    workModeRisk: string,
    record: any,
    setLoadingSave: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  goToRiskConfiguration: (
    navigate: NavigateFunction,
    record: any,
    mode: string,
    path: string
  ) => Promise<void>;
  goToRiskTable: () => Promise<void>;
  //getRisksList: (page?: any, pageSize?: any) => Promise<void>;
  getEnums: (
    setValueEnumPredefinedRisk: React.Dispatch<React.SetStateAction<any>>,
    setValueEnumCopilot: React.Dispatch<React.SetStateAction<any>>
  ) => Promise<void>;
  Deleterisk: (id: string) => Promise<void>;
  filterListeRisks: (params: any, filters?: any) => Promise<void>;
  getRiskById: (
    id: string,
    setRiskDetails: React.Dispatch<any>
  ) => Promise<void>;
}

export const useSharedContextFunctions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  /** get predefined risks from redux */
  const predefinedRList = useSelector(
    (state: RootState) => state.predefinedRiskOrgaReducer
  );
  const copilotList = useSelector(
    (state: RootState) => state.userCopilotReducer
  );
  /**confs shared context */
  const {
    setWorkModeRisk,
    setGetLoadingRisk,
    setTotalRisk,
    setRiskList,
    setIsReadyForKey,
    riskActions,
    setRiskActions,
    setPredefinedRiskId,
    riskRules,
  } = useContext(SharedContext);
  /**global context */
  const globalContext = useContext(MainContext);
  if (!globalContext) {
    return <></>;
  }
  const risksServices = new RisksConfsService(
    globalContext?.context,
    globalContext?.setContext
  );

  /** functions */
  const goToRiskConfiguration = async (
    navigate: NavigateFunction,
    record: any,
    mode: string,
    path: string
  ): Promise<void> => {
    setWorkModeRisk(mode);
    navigate(path, {
      state: {
        record: record,
        workModeRisk: mode,
      },
    });
  };

  /**Risk table  functions  */

  const getEnums = async (
    setValueEnumPredefinedRisk: React.Dispatch<React.SetStateAction<any>>,
    setValueEnumCopilot: React.Dispatch<React.SetStateAction<any>>
  ): Promise<void> => {
    // predefined risk enum
    const updatedEnumPredefinedRisk: { [key: string]: { text: string } } = {};
    predefinedRList?.forEach((curr: any) => {
      updatedEnumPredefinedRisk[curr.id] = {
        text: getTranslation(curr.name, "data"),
      };
    });
    setValueEnumPredefinedRisk(updatedEnumPredefinedRisk);
    // copilot enum
    const updatedCopilotList: { [key: string]: { text: string } } = {};
    copilotList?.forEach((curr: any) => {
      updatedCopilotList[curr.id] = {
        text: getTranslation(curr.name, "data"),
      };
    });
    setValueEnumCopilot(updatedCopilotList);
  };

  const Deleterisk = async (id: string): Promise<void> => {
    await risksServices
      .deleteRisks(id)
      .then((res) => {
        message.success(t("errors:DELETE_SUCCESS"));
        filterListeRisks({
          pageSize: 20,
        });
      })
      .catch((err: any) => {
        handleApiError(err, REQUEST.DELETE);
      });
  };
  /** filter function */
  const filterListeRisks = async (
    params: any,
    filters?: any
  ): Promise<void> => {
    setGetLoadingRisk(true);
    await risksServices
      .filterResource(params, filters, ["model", "copilot"])
      .then((response: any) => {
        setGetLoadingRisk(false);
        setRiskList(response?.items);
        setTotalRisk(response?.total);
      })
      .catch((err: any) => {
        setGetLoadingRisk(false);
        handleApiError(err);
      });
  };

  const getRiskById = async (
    id: string,
    setRiskDetails: React.Dispatch<any>
  ) => {
    risksServices
      .getResource(id, ["actions", "executions", "model", "copilot"])
      .then(async (res) => {
        const result = res?.data?.data;
        setRiskDetails(result);
        setRiskActions(result?.actions);
        setPredefinedRiskId(result?.model?.id);
        setIsReadyForKey(true);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          navigate("/*");
          setGetLoadingRisk(false);
        }
      });
  };
  /** go back to risk table  */
  const goToRiskTable = async () => {
    navigate("/menu/configurations");
  };
  const onFinishSaveRisk = async (
    values: any,
    workModeRisk: string,
    record: any,
    setLoadingSave: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    let record_ = JSON.parse(JSON.stringify(record));
    setLoadingSave(true);

    values.actions = riskActions;
    values.policy = { policy: riskRules };
    delete values["copilot_name"];
    for (let [index, item] of values.actions.entries()) {
      item["priority"] = index + 1;
      item["roles"] = item.roles?.map((r: any) => r.id);
      item["reasons"] = item.reasons?.map((r: any) => r.id);
      item.model = item.model?.id;
      delete item["key"];
      for (const [index, exec] of item.executions.entries()) {
        exec["priority"] = index + 1;
        exec["roles"] = exec.roles.map((r: any) => r?.id);
        exec["reasons"] = exec.reasons?.map((r: any) => r?.id);
        exec.model = exec.model?.id;

        // Create a new object without the 'key' property
        const { key, ...rest } = exec; // Destructure to exclude 'key'
        item.executions[index] = rest; // Reassign the updated object back to the array
      }
    }
    // check the mode create or update
    if (workModeRisk === "create") {
      await risksServices
        .addRisk(values)
        .then((response) => {
          message.success(t("errors:CREATE_SUCCESS"));
          setLoadingSave(false);
          goToRiskTable();
        })
        .catch((err) => {
          setLoadingSave(false);
          handleApiError(err, REQUEST.CREATE);
        });
    } else {
      // update mode
      if (workModeRisk === "update") {
        values["id"] = record_.id;
        // check if there is an update and if patch or save need to be used
        const equal = deepEqual(record, values);
        if (!equal) {
          // here there's an update
          const equalAction = deepEqual(record_.actions, values.actions);
          if (equalAction) {
            delete values["actions"];
            delete values["model"];
            delete values["id"];
            // here there's no upadat in  Action section -> use  patch
            await risksServices
              .updateResource(record_.id, values)
              .then((res) => {
                message.success(t("errors:UPDATE_SUCCESS"));
                setLoadingSave(false);
                goToRiskTable();
              })
              .catch((err) => {
                setLoadingSave(false);
                handleApiError(err, REQUEST.UPDATE);
              });
          } else {
            // update in actions section ->use save
            for (let item of values.actions) {
              if (item.id) {
                delete item["model"];
              }
              delete item["risk"];
              delete item["created_at"];
              delete item["created_by"];
              delete item["updated_at"];
              delete item["updated_by"];
              for (const exec of item.executions) {
                if (exec.id) {
                  delete exec["model"];
                }

                delete exec["action"];
                delete exec["created_at"];
                delete exec["created_by"];
                delete exec["updated_at"];
                delete exec["updated_by"];
              }
            }

            delete values["model"];
            delete values["created_at"];
            delete values["created_by"];
            delete values["updated_at"];
            delete values["updated_by"];
            await risksServices
              .addRisk(values)
              .then((response) => {
                message.success(t("errors:UPDATE_SUCCESS"));
                setLoadingSave(false);
                goToRiskTable();
              })
              .catch((err) => {
                setLoadingSave(false);
                handleApiError(err, REQUEST.UPDATE);
              });
          }
        } else {
          message.error(t("errors:NO_CHANGES_FOUND"));
        }
      }
    }
  };
  /** handle open Action menu */
  const handleKeysActionDnd = async (workModeRisk: string) => {
    // give  keys to list action for the dnd component

    if (workModeRisk === WORK_MODE.UPDATE || workModeRisk === WORK_MODE.VIEW) {
      setRiskActions(
        riskActions
          ?.sort((a: any, b: any) => a.priority - b.priority)
          ?.map((obj: any, index: any) => ({
            ...obj,
            key: index + 1,
          }))
      );
    }
    setGetLoadingRisk(false);
  };
  return {
    goToRiskConfiguration,
    // getRisksList,
    getEnums,
    filterListeRisks,
    Deleterisk,
    getRiskById,
    goToRiskTable,
    onFinishSaveRisk,
    handleKeysActionDnd,
  };
};
