import axios, { AxiosError } from "axios";
import { message, notification } from "antd";
import { handelResponseError } from "./handleResponseError";
import { getTranslation } from "../../../utils/transaltion";
import { REQUEST } from "./const";

// Function to handle Axios errors
export const handleApiError = (error: AxiosError, method = REQUEST.GET) => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      const method = error.config?.method?.toUpperCase();
      message.error(
        getTranslation(
          method === "GET"
            ? "ERROR_GETTING_DATA"
            : method === "POST"
            ? "CREATE_FAILED"
            : method === "PUT"
            ? "UPDATE_FAILED"
            : method === "DELETE"
            ? "DELETE_FAILED"
            : "",
          "errors"
        )
      );
      handelResponseError(error);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Network Error:", error.request);
      notification.error({
        message: "Network Error",
        description:
          "No response received from the server. Please check your network connection and try again later.",
        placement: "bottomRight",
      });
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      notification.error({
        message: "Error",
        description: error.message,
        placement: "bottomRight",
      });
    }
  } else {
    // Non-Axios error
    console.error("Unexpected Error:", error);
    notification.error({
      message: "Unexpected Error",
      description: "An unexpected error occurred. Please try again later.",
      placement: "bottomRight",
    });
  }
};

export default handleApiError;
