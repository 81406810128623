import { Bar, BarConfig } from "@ant-design/plots";
import { executionsColors } from "../../../common/chartColors";

const RiskStatusByExecutionStage = (props: any) => {
  let { data } = props;

  const config: BarConfig = {
    data,
    xField: "value",
    yField: "action_type",
    seriesField: "execution_stage",
    color: ({ execution_stage }) => {
      return executionsColors(execution_stage);
    },
    yAxis: {
      label: {
        formatter: (action_type) => {
          const words = action_type.split(" ");
          words.splice(2, 0, "\n");
          return words.join(" ");
        },
      },
    },
    isStack: true,
    legend: {
      position: "bottom",
    },
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    tooltip: {
      shared: true,
      showMarkers: false,
      formatter: ({ execution_stage, value }: any) => ({
        name: execution_stage,
        value: `${value} risks`,
      }),
    },
    barWidthRatio: 0.8,
  };

  return <Bar {...config} height={300} />;
};

export default RiskStatusByExecutionStage;
