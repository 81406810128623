import { Collapse } from "antd";

const { Panel } = Collapse;

export function MyCollaps(props: any) {
  const { title, childs } = props;
  return (
    <Collapse
      className="panel-style"
      accordion
      expandIconPosition="end"
      /* items={[
        {
          key: "1",
          label: <p>{title}</p>,
          children: <span style={{ margin: "-40px" }}>{childs}</span>,
        },
      ]} */
      style={{
        //border: "1px solid #ccc",
        borderRadius: "8px",
        marginInlineEnd: "50px",
      }}
    >
      <Panel header={title} key="1">
        <div style={{ marginBlockStart: "-15px" }}>{childs}</div>
      </Panel>
    </Collapse>
  );
}
