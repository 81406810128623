import {
  Cascader,
  Checkbox,
  Col,
  ColorPicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { CHART_TYPES } from "../../../../constants/charts";
import SaveCancel from "../../../../../../../common/SaveCancel";
import { ActionFields, OPERATOR_FIELDS } from "../../../../constants/configs";
import { WORK_MODE } from "../../../../../../../../constants/workModes";
import ChartCard from "../ChartCard";
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import ChartFilterModal from "../../../chart-filter/ChartFilterModal";
import { stringFormulaComponent } from "../../../chart-filter/StringFormula";
import { CHART_CONF_FIELD } from "../../../../constants/chartConfFields";
import { extractDataNeededFromURL } from "../../../../../../predefineds/logic/PredefinedsLogic";
import { MdFilterAlt } from "react-icons/md";
import { IoColorPalette } from "react-icons/io5";
import { defaultChartConf } from "../../../../interfaces/ChartConf";

export const CHART_COLOR_TYPES = {
  PALLET: "Pallet",
  BY_VALUE: "By value",
};

export default function NewChartModal(props: any) {
  const {
    copilotContextFields,
    workModeChartConfModal,
    recordChartConfModal,
    recordIndexChartConfModal,
    addChartConfig,
    updateChartConfig,
    closeChartConfModal,

    fields,
    params,
  } = props;

  const [currentRecord, setCurrentRecord] = useState<any>(
    workModeChartConfModal === WORK_MODE.CREATE
      ? defaultChartConf
      : recordChartConfModal
  );
  const [valueField, setValueField] = useState<any>(
    workModeChartConfModal === WORK_MODE.CREATE
      ? defaultChartConf?.config?.value_field
      : recordChartConfModal?.config?.value_field
  );
  const [categoryField, setCategoryField] = useState<any[]>(
    workModeChartConfModal === WORK_MODE.CREATE
      ? defaultChartConf?.config?.category_field
      : recordChartConfModal?.config?.category_field ||
          defaultChartConf?.config?.category_field
  );
  const [groupField, setGroupField] = useState<any>(
    workModeChartConfModal === WORK_MODE.CREATE
      ? defaultChartConf?.config?.group_field
      : recordChartConfModal?.config?.group_field
  );
  const [chartColorPallet, setChartColorPallet] = useState<any>(
    workModeChartConfModal === WORK_MODE.CREATE
      ? [
          {
            value: "",
            color: "#008BC7",
            index: Date.now(),
          },
        ]
      : recordChartConfModal?.color_pallet || [
          {
            value: "",
            color: "#008BC7",
            index: Date.now(),
          },
        ]
  );

  const [isDataOperator, setIsDataOperator] = useState<any>(true);
  const [isGrouped, setIsGrouped] = useState<any>(currentRecord?.grouped);
  const [isDual, setIsDual] = useState<any>(currentRecord?.dual);
  const [categoryIndex, setCategoryIndex] = useState<number>(0);
  const [colorType, setColorType] = useState<string>(
    workModeChartConfModal === WORK_MODE.CREATE
      ? CHART_COLOR_TYPES.PALLET
      : recordChartConfModal?.pallet_type
  );

  const [onChangeFilterField, setOnChangeFilterField] = useState<string>("");
  const [filterField, setFilterField] = useState<any>("");

  const [openChartFilterModal, setOpenChartFilterModal] = useState(false);
  const [openChartColorModal, setOpenChartColorModal] = useState(false);

  const onChangeConf = (conf: any) => {
    const new_conf = { ...currentRecord, ...conf };
    setCurrentRecord(new_conf);
  };
  const onChangeValueField = (conf: any) => {
    const new_conf = { ...valueField, ...conf };
    setValueField(new_conf);
  };
  const onChangeGroupField = (conf: any) => {
    const new_conf = { ...groupField, ...conf };
    setGroupField(new_conf);
  };
  const onChangeCategoryField = (new_conf: any, i: number) => {
    const categories = categoryField?.map((conf, index: number) => {
      if (index === i) {
        return { ...conf, ...new_conf };
      } else {
        return conf;
      }
    });
    setCategoryField(categories);
  };
  const addCategoryField = () => {
    setCategoryField([
      ...categoryField,
      {
        field: "",
        sort: "",
        filter: "",
      },
    ]);
  };
  const deleteFilterConfig = (i: number) => {
    const categories = categoryField?.filter(
      (conf, index: number) => i !== index
    );
    setCategoryField(categories);
  };
  const addChartColor = () => {
    setChartColorPallet([
      ...chartColorPallet,
      {
        value: "",
        color: "",
        id: Date.now(),
      },
    ]);
  };
  const deleteChartColor = (i: number) => {
    const colors = chartColorPallet?.filter(
      (color: any, index: number) => i !== color?.id
    );
    setChartColorPallet(colors);
  };
  const updateChartColor = (new_color: string, i: number) => {
    const colors = chartColorPallet?.map((color: any, index: number) =>
      i !== color?.id ? color : { ...color, color: new_color }
    );
    setChartColorPallet(colors);
  };
  const updateChartColorValue = (value: string, i: number) => {
    const colors = chartColorPallet?.map((color: any, index: number) =>
      i !== color?.id ? color : { ...color, value: value }
    );
    setChartColorPallet(colors);
  };

  const closeChartFilterModal = () => {
    setOpenChartFilterModal(false);
  };
  const callChartFilterModal = () => {
    setOpenChartFilterModal(true);
  };
  const closeChartColorModal = () => {
    setOpenChartColorModal(false);
  };
  const callChartColorModal = () => {
    setOpenChartColorModal(true);
  };

  useEffect(() => {
    setCurrentRecord({
      ...currentRecord,
      ...{
        config: {
          value_field: valueField,
          group_field: groupField,
          category_field: categoryField,
        },
      },
    });
    console.log({
      ...currentRecord,
      ...{
        config: {
          value_field: valueField,
          group_field: groupField,
          category_field: categoryField,
        },
      },
    });
  }, [valueField, groupField, categoryField]);

  const [form_execution] = Form.useForm();
  return (
    <>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={16}
          style={{ overflow: "auto", maxHeight: "540px" }}
        >
          <Form
            name="wrap"
            form={form_execution}
            labelAlign="left"
            labelWrap
            style={{ marginInlineStart: "20px" }}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
              chart_type: currentRecord?.type,
              chart_title: currentRecord?.title,
            }}
            colon={false}
          >
            <p style={{ color: "lightgray", marginBlockEnd: "5px" }}>
              Chart configurations :
            </p>
            <Col>
              {/* Chart type */}
              <Row>
                <Col xs={24} sm={24} md={18} lg={12} xl={12}>
                  <Form.Item
                    label={"Chart type"}
                    name="chart_type"
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: "95%" }}
                      // defaultValue={currentRecord?.type}
                      value={currentRecord?.type}
                      onChange={(val) => {
                        onChangeConf({ type: val });
                        onChangeValueField(defaultChartConf.config.value_field);
                        setCategoryField([
                          defaultChartConf.config.category_field,
                        ]);
                        onChangeGroupField(defaultChartConf.config.group_field);
                        setIsGrouped(false);
                        setIsDual(false);
                      }}
                      dropdownStyle={{ width: "30%" }}
                    >
                      {CHART_TYPES?.map((item: any) => (
                        <Select.Option
                          key={item?.name}
                          value={item?.name}
                          disabled={
                            item?.name === "MAP_VIEW" ||
                            item?.name === "TABLE_VIEW"
                          }
                        >
                          <span style={{ marginInlineEnd: "8px" }}>
                            {item?.label}
                          </span>
                          {item.config?.dual && <Tag color="blue">Dual-X</Tag>}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {/* Chart Title */}
              <Row>
                <Col xs={24} sm={24} md={18} lg={12} xl={12}>
                  <Form.Item
                    label={"Title"}
                    name="chart_title"
                    rules={[{ required: true }]}
                  >
                    <Input
                      style={{ width: "95%" }}
                      // defaultValue={currentRecord?.title}
                      value={currentRecord?.title}
                      onChange={(e) => {
                        const v = e.target.value.toString();
                        onChangeConf({
                          title: v,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                {/* Chart Unit */}
                <Col xs={24} sm={24} md={18} lg={12} xl={12}>
                  <Form.Item label={"Unit"}>
                    <Input
                      style={{ width: "95%" }}
                      defaultValue={currentRecord?.unit}
                      value={currentRecord?.unit}
                      onChange={(e) => {
                        const v = e.target.value.toString();
                        onChangeConf({
                          unit: v,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                {/* Chart Color */}
                <Col xs={24} sm={24} md={18} lg={12} xl={12}>
                  <Form.Item label={"Colors"} name="color">
                    <IoColorPalette
                      style={{ cursor: "pointer" }}
                      size={22}
                      onClick={() => {
                        callChartColorModal();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Gauge indexes */}
              {currentRecord?.type === "GAUGE_CHART" && (
                <Row>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Form.Item name={"gauge_l1"} label={"Gauge index 1"}>
                      <Input
                        type="number"
                        defaultValue={currentRecord?.gauge_l1}
                        value={currentRecord?.gauge_l1}
                        onChange={(e) => {
                          const v = e.target.value.toString();
                          onChangeConf({
                            gauge_l1: v,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Form.Item name={"gauge_l2"} label={"Gauge index 2"}>
                      <Input
                        type="number"
                        defaultValue={currentRecord?.gauge_l2}
                        value={currentRecord?.gauge_l2}
                        onChange={(e) => {
                          const v = e.target.value.toString();
                          onChangeConf({
                            gauge_l2: v,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>
            <>
              <Row style={{ color: "lightgray", marginBlockEnd: "5px" }}>
                Data configurations : <br /> Data field :
              </Row>
              <Row style={{ color: "lightgray", marginBlockEnd: "5px" }}></Row>
              <Row>
                {/* Operator */}
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <Form.Item
                    label={"Operator"}
                    name="operator"
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: "95%" }}
                      defaultValue={valueField?.operator}
                      value={valueField?.operator}
                      onChange={(val: string) => {
                        onChangeValueField({ operator: val, field: "" });
                      }}
                    >
                      {Object.entries(OPERATOR_FIELDS)?.map(([item, value]) => (
                        <Select.Option key={item} value={item}>
                          <span>
                            {item} <Tag color="blue">{value.TYPE}</Tag>
                          </span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  {CHART_TYPES.find(
                    (chart) => chart?.name === currentRecord?.type
                  )?.config.dual && (
                    <Form.Item id="auto">
                      <Checkbox
                        disabled={workModeChartConfModal === WORK_MODE.VIEW}
                        defaultChecked={isDual}
                        checked={isDual}
                        onChange={() => {
                          setCategoryField([
                            {
                              field: "",
                              sort: "",
                              filter: "",
                            },
                          ]);
                          onChangeGroupField({
                            field: "",
                            sort: "",
                            filter: "",
                            limit: "",
                          });
                          onChangeConf({ dual: !isDual });
                          setIsDual(!isDual);
                        }}
                      >
                        {"Dual ?"}
                      </Checkbox>
                    </Form.Item>
                  )}
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  {CHART_TYPES.find(
                    (chart) => chart?.name === currentRecord?.type
                  )?.config.group && (
                    <>
                      {currentRecord?.type !== "LINE_CHART" && (
                        <Row>
                          <Form.Item id="group">
                            <Checkbox
                              disabled={
                                workModeChartConfModal === WORK_MODE.VIEW
                              }
                              defaultChecked={isGrouped}
                              checked={isGrouped}
                              onChange={() => {
                                onChangeGroupField({
                                  field: "",
                                  sort: "",
                                  filter: "",
                                  limit: "",
                                });
                                onChangeConf({ grouped: isGrouped });
                                setIsGrouped(!isGrouped);
                              }}
                            >
                              {"Grouped ?"}
                            </Checkbox>
                          </Form.Item>
                        </Row>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              {/* Data conf */}
              <Row>
                {valueField?.operator !== "COUNT" && (
                  <>
                    {/* Value field */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <Form.Item
                        label={"Field"}
                        name="value"
                        rules={[{ required: true }]}
                      >
                        <Cascader
                          style={{ width: "95%" }}
                          options={copilotContextFields}
                          displayRender={(labels, selectedOptions = []) => {
                            return labels.join(".");
                          }}
                          value={valueField?.field?.split(".")}
                          defaultValue={valueField?.field?.split(".")}
                          onChange={(value, option: any) => {
                            const value_ = value?.join(".");
                            onChangeValueField({ field: value_, filter: "" });
                          }}
                          fieldNames={{
                            label: "value",
                            value: "value",
                            children: "children",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {/* Date range */}
                    {copilotContextFields[valueField?.field]?.TYPE ===
                      "DATE" && (
                      <Col xs={24} sm={24} md={12} lg={5} xl={5}>
                        <Form.Item
                          label={"Range"}
                          name="dateRange"
                          rules={[{ required: true }]}
                        >
                          <Select
                            style={{ width: "95%" }}
                            defaultValue={valueField?.range}
                            value={valueField?.range}
                            onChange={(val) => {
                              onChangeValueField({ range: val });
                            }}
                          >
                            {["Day", "Week", "Month"]?.map((item) => (
                              <Select.Option key={item} value={item}>
                                <span>{item}</span>
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    {/* sort field */}
                    <Col xs={24} sm={24} md={12} lg={5} xl={5}>
                      <Form.Item
                        label={"Sort"}
                        name="sort"
                        rules={[{ required: true }]}
                      >
                        <Select
                          style={{ width: "95%" }}
                          defaultValue={valueField?.sort}
                          value={valueField?.sort}
                          onChange={(val) => {
                            onChangeValueField({ sort: val });
                          }}
                        >
                          {["/", "Dsc", "Asc"]?.map((item) => (
                            <Select.Option key={item} value={item}>
                              <span>{item}</span>
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* filter field */}
                    <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                      <Form.Item label={"Filter"} name="filter">
                        <MdFilterAlt
                          style={{ cursor: "pointer" }}
                          size={22}
                          onClick={() => {
                            setFilterField(valueField);
                            setOnChangeFilterField(
                              CHART_CONF_FIELD.VALUE_FIELD
                            );
                            callChartFilterModal();
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>

              {/* Category conf */}
              <Row style={{ color: "lightgray", marginBlockEnd: "5px" }}>
                Category field :
              </Row>
              {categoryField?.map((category: any, index: number) => (
                <Row>
                  {isDual && (
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                      {categoryField?.length < 3 &&
                        categoryField?.length - 1 === index && (
                          <PlusCircleOutlined
                            style={{
                              fontSize: "20px",
                              color: "#1677ff",
                              margin: "5px",
                            }}
                            onClick={() => {
                              addCategoryField();
                            }}
                          />
                        )}
                    </Col>
                  )}
                  {/* Type field */}
                  <Col xs={24} sm={24} md={12} lg={24} xl={8}>
                    <Form.Item
                      label={"Field"}
                      name={"type" + index}
                      rules={[{ required: true }]}
                    >
                      <Cascader
                        style={{ width: "95%" }}
                        options={copilotContextFields}
                        displayRender={(labels, selectedOptions = []) => {
                          return labels.join(".");
                        }}
                        defaultValue={category?.field?.split(".")}
                        value={category?.field?.split(".")}
                        onChange={(value, option: any) => {
                          const value_ = value?.join(".");
                          onChangeCategoryField(
                            { field: value_, filter: "" },
                            index
                          );
                        }}
                        fieldNames={{
                          label: "value",
                          value: "value",
                          children: "children",
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                    <Form.Item
                      label={"Sort"}
                      name={"sortttt" + index}
                      rules={[{ required: true }]}
                    >
                      <Select
                        style={{ width: "95%" }}
                        /* defaultValue={category?.sort || "/"}*/
                        value={category?.sort}
                        onChange={(val) => {
                          onChangeCategoryField({ sort: val }, index);
                        }}
                      >
                        {["/", "Dsc", "Asc"]?.map((item) => (
                          <Select.Option key={item} value={item}>
                            <span>{item}</span>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                    <Form.Item label={"Limit"} name={"limit" + index}>
                      <Input style={{ width: "95%" }} type="number" />
                    </Form.Item>
                  </Col>
                  {/* filter field */}
                  <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                    <Form.Item label={"Filter"} name="filter">
                      <MdFilterAlt
                        style={{ cursor: "pointer" }}
                        size={22}
                        onClick={() => {
                          setFilterField(categoryField?.at(index));
                          setCategoryIndex(index);
                          setOnChangeFilterField(
                            CHART_CONF_FIELD.CATEGORY_FIELD
                          );
                          callChartFilterModal();
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                    {categoryField?.length > 1 && (
                      <MinusCircleOutlined
                        style={{
                          fontSize: "20px",
                          color: "#ff671f",
                          marginBlock: "5px",
                          marginInlineStart: "-20px",
                        }}
                        onClick={() => {
                          deleteFilterConfig(index);
                        }}
                      />
                    )}
                  </Col>
                </Row>
              ))}

              {isGrouped && (
                <>
                  <Row style={{ color: "lightgray", marginBlockEnd: "5px" }}>
                    Grouped by :
                  </Row>
                  <Row>
                    {/* Series field */}
                    <Col xs={24} sm={24} md={12} lg={24} xl={8}>
                      <Form.Item
                        label={
                          currentRecord?.type === "LINE_CHART"
                            ? "Date field"
                            : "Field"
                        }
                        name="category"
                        rules={[{ required: true }]}
                      >
                        <Cascader
                          style={{ width: "95%" }}
                          options={copilotContextFields}
                          displayRender={(labels, selectedOptions = []) => {
                            return labels.join(".");
                          }}
                          value={groupField?.field?.split(".")}
                          defaultValue={groupField?.field?.split(".")}
                          onChange={(value, option: any) => {
                            const value_ = value?.join(".");
                            onChangeGroupField({ field: value_, filter: "" });
                          }}
                          fieldNames={{
                            label: "value",
                            value: "value",
                            children: "children",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {currentRecord?.type !== "LINE_CHART" && (
                      <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                        <Form.Item label={"Limit"} name="max">
                          <Input style={{ width: "95%" }} type="number" />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </>
              )}
            </>
          </Form>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={8}
          xl={8}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChartCard
            config={currentRecord}
            index={-1}
            viewChartModal={() => {}}
            updateChartModal={() => {}}
            deleteChartConfig={() => {}}
            showDrawer={() => {}}
            data={[]}
            fields={fields}
            params={params}
          ></ChartCard>
        </Col>
      </Row>
      <Form name="wrap" form={form_execution}>
        <Row>
          <Form.Item>
            <SaveCancel
              confirmLoading={false}
              onClickSave={() => {
                onChangeConf({ dual: isDual, grouped: isGrouped });
                if (workModeChartConfModal === WORK_MODE.CREATE) {
                  addChartConfig(currentRecord);
                } else {
                  console.log(currentRecord);
                  updateChartConfig(currentRecord, recordIndexChartConfModal);
                }
              }}
              onClickCancel={() => {
                closeChartConfModal();
              }}
            />
          </Form.Item>
        </Row>
      </Form>
      <Modal
        destroyOnClose={true}
        title={"Chart filter"}
        open={openChartFilterModal}
        onCancel={closeChartFilterModal}
        width={800}
        footer={null}
      >
        <ChartFilterModal
          onChangeFilterField={
            onChangeFilterField === CHART_CONF_FIELD.VALUE_FIELD
              ? onChangeValueField
              : onChangeFilterField === CHART_CONF_FIELD.CATEGORY_FIELD
              ? (conf_: any) => {
                  onChangeCategoryField(conf_, categoryIndex);
                }
              : onChangeFilterField === CHART_CONF_FIELD.GROUP_FIELD
              ? onChangeGroupField
              : () => {}
          }
          dataNeeded={copilotContextFields}
          filterField={filterField}
          closeChartFilterModal={closeChartFilterModal}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        title={"Chart colors"}
        open={openChartColorModal}
        onCancel={() => {
          setColorType(currentRecord?.pallet_type);
          setChartColorPallet(currentRecord?.color_pallet);
          closeChartColorModal();
        }}
        onOk={() => {
          onChangeConf({
            color_pallet: chartColorPallet,
            pallet_type: colorType,
          });
          closeChartColorModal();
        }}
        width={800}
        //footer={null}
      >
        <Form>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={11}>
              <Form.Item
                label={"Color type"}
                name="sort"
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: "95%" }}
                  defaultValue={colorType}
                  value={colorType}
                  onChange={(val) => {
                    setColorType(val);
                  }}
                >
                  {[CHART_COLOR_TYPES.PALLET, CHART_COLOR_TYPES.BY_VALUE]?.map(
                    (item) => (
                      <Select.Option key={item} value={item}>
                        <span>{item}</span>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {chartColorPallet?.map((color: any) => (
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                {
                  <PlusCircleOutlined
                    style={{
                      fontSize: "20px",
                      color: "#1677ff",
                      margin: "5px",
                    }}
                    onClick={() => {
                      addChartColor();
                    }}
                  />
                }
              </Col>
              {/* Type field */}
              {colorType === CHART_COLOR_TYPES.BY_VALUE && (
                <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                  <Form.Item
                    label={"Value"}
                    name={"value" + color.id}
                    rules={[{ required: true }]}
                  >
                    <Input
                      style={{ width: "95%" }}
                      defaultValue={color.value}
                      value={color.value}
                      onChange={(e) => {
                        const v = e.target.value.toString();
                        updateChartColorValue(v, color.id);
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} sm={24} md={12} lg={10} xl={8}>
                <Form.Item
                  label={"Color"}
                  name={"color" + color.id}
                  rules={[{ required: true }]}
                >
                  <ColorPicker
                    defaultValue={color.color}
                    showText={(color) => (
                      <span>color ({color.toHexString()})</span>
                    )}
                    onChange={(value, hex) => {
                      updateChartColor(hex, color.id);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                {chartColorPallet?.length > 1 && (
                  <MinusCircleOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ff671f",
                      marginBlock: "5px",
                      marginInlineStart: "-20px",
                    }}
                    onClick={() => {
                      deleteChartColor(color?.id);
                    }}
                  />
                )}
              </Col>
            </Row>
          ))}
        </Form>
      </Modal>
    </>
  );
}
