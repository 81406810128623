import React, { useContext, useEffect, useState } from "react";
import { Col, DatePicker, Form, Input, Row, Select, Spin } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { MainContext } from "../../../../../utils/context";
import { WORK_MODE } from "../../../../../constants/workModes";
import {
  DATA_NEEDED_TYPE,
  DATA_NEEDED_TYPES,
} from "../../../../../constants/DataNeededTypes";
import { SharedContext } from "../../context/sharedContext";
import { PREDEFINED_TYPES } from "../../../../../constants/predefinedTypes";
import { selectFilterOption } from "../../../../../utils/functions";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../utils/transaltion";
import { ORGA_DEFAULT_SETTINGS } from "../../../../../constants/orgaDefaultSettings";

const SettingsDynamicFields: React.FC<any> = (props) => {
  const { typeComponent } = props;
  const globalContext = useContext(MainContext);
  const formatDate =
    globalContext?.context?.orga?.formatDate ||
    ORGA_DEFAULT_SETTINGS?.DEFAULT_DATE;
  const { t } = useTranslation();
  const {
    dynamicFieldsSettings,
    setDynamicFieldsSettings,
    workMode,
    formSettings,
    settingsChoicesList,
  } = useContext(SharedContext);
  const [loading, setLoading] = useState(true);

  const handleInputChange = (key: number, fieldType: string, value: any) => {
    // Update the dynamicFields array based on the changed value
    let updatedFields: any;
    if (fieldType === DATA_NEEDED_TYPE.DATE) {
      updatedFields = dynamicFieldsSettings.map((fieldItem) =>
        fieldItem.key === key
          ? {
              ...fieldItem,
              [fieldType]: dayjs(value.split("T")[0]).format(formatDate),
            }
          : fieldItem
      );
    } else {
      updatedFields = dynamicFieldsSettings.map((fieldItem) =>
        fieldItem.key === key ? { ...fieldItem, [fieldType]: value } : fieldItem
      );
      if (fieldType === "type") {
        if (value === DATA_NEEDED_TYPE.BOOLEAN) {
          // Set default value to "true" for boolean type
          updatedFields = updatedFields.map((fieldItem: any) =>
            fieldItem.key === key
              ? { ...fieldItem, defaultValue: "true" }
              : fieldItem
          );
        } else if (value === DATA_NEEDED_TYPE.DATE) {
          const formattedDate = dayjs(); //
          formSettings.setFieldsValue({ [`Value${key}`]: formattedDate });
          updatedFields = updatedFields.map((fieldItem: any) =>
            fieldItem.key === key
              ? {
                  ...fieldItem,
                  defaultValue: formattedDate,
                }
              : fieldItem
          );
        }
      }
    }

    setDynamicFieldsSettings(updatedFields);
  };

  useEffect(() => {
    if (dynamicFieldsSettings.length > 0) {
      const dynamicFieldName = Object.fromEntries(
        dynamicFieldsSettings.map((field: any) => [
          `Name${field.key}`,
          getTranslation(field?.name || "", "data"),
        ])
      );
      const dynamicFieldTypeValues = Object.fromEntries(
        dynamicFieldsSettings.map((field: any) => [
          `Type${field.key}`,
          field.type || DATA_NEEDED_TYPE.STRING,
        ])
      );
      const dynamicFieldSuffixValues = Object.fromEntries(
        dynamicFieldsSettings.map((field: any) => [
          `Suffix${field.key}`,
          field.suffix || "",
        ])
      );
      const dynamicFieldChoiceValues = Object.fromEntries(
        dynamicFieldsSettings.map((field: any) => [
          `Choice${field.key}`,
          field.choice || false,
        ])
      );

      const dynamicFieldValues = Object.fromEntries(
        dynamicFieldsSettings.map((field: any) => [
          `Value${field.key}`,
          field.type === DATA_NEEDED_TYPE.DATE
            ? field.defaultValue
              ? dayjs(field.defaultValue)
              : undefined
            : //if the value has choices
            field.choice === true
            ? getTranslation(field?.defaultValue || "", "data")
            : field?.defaultValue,
        ])
      );
      formSettings.setFieldsValue({
        ...dynamicFieldName,
        ...dynamicFieldTypeValues,
        ...dynamicFieldValues,
        ...dynamicFieldSuffixValues,
        ...dynamicFieldChoiceValues,
      });
      setLoading(false);
    }
  }, [dynamicFieldsSettings, settingsChoicesList]);
  return (
    <>
      <Spin spinning={loading}>
        <Form form={formSettings}>
          {dynamicFieldsSettings?.map((field, index) => (
            <Row key={field.key} gutter={16}>
              <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                <Form.Item
                  {...{ labelCol: { style: { width: 100 } } }}
                  name={`Name${field.key}`}
                  label={`${t("NAME_FIELD")}`}
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:FIELD`),
                      }),
                    },
                    {
                      validator: (_, value) => {
                        const formValues = formSettings.getFieldsValue();
                        const dataNameValues = Object.entries(formValues)
                          .filter(
                            ([key, val]) =>
                              key.startsWith("Name") &&
                              key !== `Name${field.key}` &&
                              val !== ""
                          )
                          .map(([key, val]) => val);

                        if (dataNameValues.includes(value)) {
                          return Promise.reject(t("NAME_USED"));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    disabled={true}
                    value={field?.name || ""}
                    defaultValue={field?.name || ""}
                    onChange={(e) => {
                      handleInputChange(field.key, "name", e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              {field.hasOwnProperty("choice") && (
                <Col xs={0} sm={0} md={0} lg={0} xl={0}>
                  <Form.Item
                    {...{ labelCol: { style: { width: 100 } } }}
                    name={`Choice${field.key}`}
                  ></Form.Item>
                </Col>
              )}
              {field.hasOwnProperty("suffix") &&
                typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED && (
                  <Col xs={0} sm={0} md={0} lg={0} xl={0}>
                    <Form.Item
                      {...{ labelCol: { style: { width: 100 } } }}
                      name={`Suffix${field.key}`}
                      label={`${t("SUFFIX")}`}
                      rules={[
                        {
                          required: true,
                          message: t(`errors:REQUIRED`, {
                            label: t(`labels:FIELD`),
                          }),
                        },
                        {
                          validator: (_, value) => {
                            const formValues = formSettings.getFieldsValue();
                            const dataNameValues = Object.entries(formValues)
                              .filter(
                                ([key, val]) =>
                                  key.startsWith("Suffix") &&
                                  key !== `Suffix${field.key}` &&
                                  val !== ""
                              )
                              .map(([key, val]) => val);

                            if (dataNameValues.includes(value)) {
                              return Promise.reject(t("NAME_USED"));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        disabled={true}
                        value={field?.suffix || ""}
                        defaultValue={field?.suffix || ""}
                        onChange={(e) => {
                          handleInputChange(
                            field.key,
                            "suffix",
                            e.target.value
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                )}
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <Form.Item
                  {...{ labelCol: { style: { width: 100 } } }}
                  name={`Type${field.key}`}
                  label={`${t("TYPE")}`}
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:FIELD`),
                      }),
                    },
                  ]}
                >
                  <Select
                    disabled={true}
                    filterOption={(input, option) =>
                      selectFilterOption(input, option)
                    }
                    onChange={(value) => {
                      handleInputChange(field.key, "type", value);
                    }}
                    value={field?.type ? String(field.type) : ""}
                    defaultValue={field?.type ? String(field.type) : ""}
                  >
                    {DATA_NEEDED_TYPES.map((type) => (
                      <Select.Option key={type.value} value={type.value}>
                        {type.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  {...{ labelCol: { style: { width: 133 } } }}
                  name={`Value${field.key}`}
                  label={`${t("VALUE")}`}
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:FIELD`),
                      }),
                    },
                  ]}
                >
                  {(field?.type === DATA_NEEDED_TYPE.STRING || !field?.type) &&
                    field.choice === true &&
                    !(
                      !settingsChoicesList ||
                      Object.keys(settingsChoicesList).length === 0
                    ) &&
                    field.suffix &&
                    settingsChoicesList[field.suffix] && (
                      // workMode !== WORK_MODE.VIEW &&
                      // typeComponent === PREDEFINED_TYPES.ORGANIZATION &&
                      <Select
                        disabled={workMode === WORK_MODE.VIEW}
                        filterOption={(input, option) =>
                          selectFilterOption(input, option)
                        }
                        onChange={(value) => {
                          handleInputChange(field.key, "defaultValue", value);
                        }}
                        value={getTranslation(field?.defaultValue, "data")}
                        defaultValue={getTranslation(
                          field?.defaultValue,
                          "data"
                        )}
                      >
                        {field.suffix &&
                          settingsChoicesList[field.suffix]?.map(
                            (choice: any) => (
                              <Select.Option key={choice} value={choice}>
                                {getTranslation(choice, "data")}
                              </Select.Option>
                            )
                          )}
                      </Select>
                    )}{" "}
                  {(field?.type === DATA_NEEDED_TYPE.STRING || !field?.type) &&
                    field.suffix &&
                    !settingsChoicesList[field.suffix] && (
                      <Input
                        readOnly={
                          workMode === WORK_MODE.VIEW ||
                          typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED
                        }
                        disabled={
                          workMode === WORK_MODE.VIEW ||
                          typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED
                        }
                        value={
                          field?.defaultValue ? String(field.defaultValue) : ""
                        }
                        defaultValue={
                          field?.defaultValue ? String(field.defaultValue) : ""
                        }
                        onChange={(e) => {
                          handleInputChange(
                            field.key,
                            "defaultValue",
                            e.target.value
                          );
                        }}
                      />
                    )}
                  {(field?.type === DATA_NEEDED_TYPE.STRING || !field?.type) &&
                    field.suffix &&
                    field.choice === false &&
                    !(
                      !settingsChoicesList ||
                      Object.keys(settingsChoicesList).length === 0
                    ) &&
                    field.suffix &&
                    settingsChoicesList[field.suffix] && (
                      <Input
                        readOnly={
                          workMode === WORK_MODE.VIEW ||
                          typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED
                        }
                        disabled={
                          workMode === WORK_MODE.VIEW ||
                          typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED
                        }
                        value={
                          field?.defaultValue ? String(field.defaultValue) : ""
                        }
                        defaultValue={
                          field?.defaultValue ? String(field.defaultValue) : ""
                        }
                        onChange={(e) => {
                          handleInputChange(
                            field.key,
                            "defaultValue",
                            e.target.value
                          );
                        }}
                      />
                    )}
                  {field?.type === DATA_NEEDED_TYPE.NUMBER && (
                    <Input
                      type="number"
                      disabled={
                        typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED ||
                        workMode === WORK_MODE.VIEW
                          ? true
                          : false
                      }
                      value={
                        field?.defaultValue ? Number(field.defaultValue) : 0
                      }
                      defaultValue={
                        field?.defaultValue ? Number(field.defaultValue) : 0
                      }
                      onChange={(e) => {
                        handleInputChange(
                          field.key,
                          "defaultValue",
                          Number(e.target.value)
                        );
                      }}
                    />
                  )}
                  {field?.type === DATA_NEEDED_TYPE.BOOLEAN && (
                    <Select
                      disabled={
                        typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED
                          ? true
                          : false
                      }
                      value={
                        field?.defaultValue
                          ? String(field.defaultValue)
                          : "true"
                      }
                      filterOption={(input, option) =>
                        selectFilterOption(input, option)
                      }
                      defaultValue={"true"}
                      onChange={(value) => {
                        handleInputChange(field.key, "defaultValue", value);
                      }}
                    >
                      <Select.Option value="true">{t("TRUE")}</Select.Option>
                      <Select.Option value="false">{t("FALSE")}</Select.Option>
                    </Select>
                  )}
                  {field?.type === DATA_NEEDED_TYPE.DATE && (
                    <DatePicker
                      style={{ width: "95%" }}
                      disabledDate={(current) => {
                        return (
                          current &&
                          dayjs(current).isBefore(dayjs().startOf("day"))
                        );
                      }}
                      // showTime={{ format: "HH:mm:ss" }}
                      format={formatDate}
                      onChange={(value) => {
                        handleInputChange(
                          field.key,
                          "defaultValue",
                          value ? value.toISOString() : undefined
                        );
                      }}
                      value={
                        field.defaultValue
                          ? dayjs(field.defaultValue)
                          : undefined
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          ))}
        </Form>
      </Spin>
    </>
  );
};

export default SettingsDynamicFields;
