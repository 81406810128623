import React, { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { SharedContext } from "../../context/sharedContext";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../../logic/PredefinedsLogic";
import { WORK_MODE } from "../../../../../constants/workModes";
import { selectFilterOption } from "../../../../../utils/functions";
import { useTranslation } from "react-i18next";
import { createKey, getTranslation } from "../../../../../utils/transaltion";
import { PREDEFINED_TYPES } from "../../../../../constants/predefinedTypes";
import DataNeededWithPositions from "../dynamic-fields/DataNeededWithPositions";
import { LockOutlined } from "@ant-design/icons";

const PreRiskDetails: React.FC<{
  typeComponent: string;
}> = (props) => {
  /**props */
  const { typeComponent } = props;
  /** translation */
  const { t } = useTranslation();
  /** get predefined actions from redux */
  const predefinedActions = useSelector(
    (state: RootState) => state.predefinedActionGlobalReducer
  )?.filter((item: any) => item?.enabled === true);
  const { isDisable, setIsDisable, workMode, form, Record } =
    useContext(SharedContext);
  /** shared logic */
  const { handleUpdateRisk } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  const copilotListRedux = useSelector(
    (state: RootState) => state.copilotReducer
  );
  /** get predefined risks from redux */
  const predefinedRiksRedux = useSelector(
    (state: RootState) => state.predefinedRiskGlobalReducer
  );
  const reasonsRisksRedux = useSelector(
    (state: RootState) => state.reasonsRisksReducer
  );
  const [initialValRisk, setInitialRisk] = useState<any>({});
  useEffect(() => {
    handleUpdateRisk(Record);
    if (workMode === WORK_MODE.CREATE || !workMode) {
      setIsDisable(true);
    }

    const initialValue =
      workMode === WORK_MODE.CREATE || !workMode
        ? {
            name: "",
            model: "",
            copilot: "",
            reasons:
              reasonsRisksRedux
                ?.filter((reason: any) => reason?.is_system)
                .map((reason: any) => reason.id) || [],
            enabled: true,
          }
        : {
            name: Record?.name,
            roles: Record?.roles?.map((role: any) => role.id),
            reasons: [
              ...(Record?.reasons?.map((reason: any) => reason.id) || []),
            ],
            enabled: Record?.enabled,
            copilot: Record?.copilot?.id,
            actions: Record?.actions?.map((reason: any) => reason.id),
          };
    form.setFieldsValue(initialValue);
    setInitialRisk(initialValue);
  }, [workMode, Record, form, typeComponent]);

  return (
    <div style={{ marginTop: 20, width: "100%", marginBottom: "20px" }}>
      <Collapse
        className="panel-style"
        accordion
        expandIconPosition="end"
        size="large"
        defaultActiveKey="menu"
      >
        <Collapse.Panel
          className="panel-style"
          header={
            <span>
              {t(`labels:DETAILS`, {
                item: t(typeComponent),
              })}
            </span>
          }
          key="menu"
        >
          <Form
            preserve={false}
            form={form}
            name="wrap"
            {...{ labelCol: { style: { width: 200 } } }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            initialValues={initialValRisk}
          >
            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t(`labels:NAME`, {
                    item: t(typeComponent),
                  })}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:NAME`, {
                          item: t(typeComponent),
                        }),
                      }),
                    },
                    {
                      validator: (_, value) => {
                        const dataNameValues = predefinedRiksRedux?.map(
                          (item: any) => item.name
                        );

                        if (
                          dataNameValues.includes(createKey(value)) &&
                          value !== getTranslation(Record?.name, "data") &&
                          Record?.name !== value
                        ) {
                          return Promise.reject(t("NAME_USED"));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    readOnly={workMode === WORK_MODE.VIEW}
                    disabled={workMode === WORK_MODE.VIEW}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t("COPILOT")}
                  name="copilot"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:COPILOT`),
                      }),
                    },
                  ]}
                >
                  <Select
                    disabled={
                      workMode === WORK_MODE.CREATE || !workMode ? false : true
                    }
                    showSearch
                    filterOption={(input, option) =>
                      selectFilterOption(input, option)
                    }
                  >
                    {copilotListRedux?.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {getTranslation(item.name, "data")}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t("ACTIONS_PREDEFINED")}
                  name="actions"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:ACTIONS_PREDEFINED`),
                      }),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      selectFilterOption(input, option)
                    }
                    disabled={workMode === WORK_MODE.VIEW}
                    mode="multiple"
                  >
                    {predefinedActions?.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {getTranslation(item.name, "data")}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t("RISK_REASONS_CODES")}
                  name="reasons"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:RISK_REASONS_CODES`),
                      }),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      selectFilterOption(input, option)
                    }
                    disabled={workMode === WORK_MODE.VIEW}
                    mode="multiple"
                    tagRender={(props: any) => {
                      const { label, value, onClose } = props;
                      const is_sys = reasonsRisksRedux?.find(
                        (obj: any) => obj.id === value
                      )?.is_system;
                      return (
                        <Tag
                          color={is_sys ? "red" : "blue"}
                          closable={workMode !== WORK_MODE.VIEW && !is_sys}
                          onClose={onClose}
                        >
                          {label}
                          {is_sys ? (
                            <LockOutlined style={{ marginInline: "4px" }} />
                          ) : (
                            ""
                          )}
                        </Tag>
                      );
                    }}
                  >
                    {reasonsRisksRedux?.map((item: any) => (
                      <Select.Option
                        key={item.id}
                        value={item.id}
                        disabled={item.is_system}
                      >
                        {getTranslation(item?.name, "data")}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {/* Disable */}
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Form.Item name="enabled">
                  <Checkbox
                    disabled={true}
                    checked={isDisable}
                    defaultChecked={false}
                    onChange={() => {
                      setIsDisable(!isDisable);
                    }}
                  >
                    {t("ENABLED")}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <div style={{ marginTop: 40, width: "100%", marginBottom: "20px" }}>
        <Collapse
          className="panel-style"
          accordion
          expandIconPosition="end"
          size="large"
          destroyInactivePanel={true}
          defaultActiveKey={
            workMode === WORK_MODE.VIEW || workMode === WORK_MODE.UPDATE
              ? "settings"
              : ""
          }
        >
          <Collapse.Panel
            className="panel-style"
            header={
              <span>
                {" "}
                {t(`labels:SETTINGS`, {
                  item: t(typeComponent),
                })}{" "}
              </span>
            }
            key="settings"
          >
            <Form
              preserve={false}
              form={form}
              name="wrap"
              {...{ labelCol: { style: { width: 200 } } }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
            >
              <Typography className="typography-setting">
                {t("RISK_CONTEXT_DATA")}
              </Typography>
              <DataNeededWithPositions
                PredefinedType={PREDEFINED_TYPES.RISK_PREDEFINED}
              />
            </Form>
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default PreRiskDetails;
