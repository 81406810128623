import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

type ReasonsActions = {
  id: string;
  is_system: boolean;
  name: string;
};
const initialState: Array<ReasonsActions> = [];

export const reasonsActionsSlice = createSlice({
  name: "reasonsActions",
  initialState,
  reducers: {
    addReasonsActions: (state, action: PayloadAction<ReasonsActions>) => {
      state.push(action.payload);
    },
    setReasonsActions: (
      state,
      action: PayloadAction<Array<ReasonsActions>>
    ) => {
      return action.payload;
    },
    updateReasonsActions: (state, action: PayloadAction<ReasonsActions>) => {
      const { id, name, is_system } = action.payload;
      const existingReasonsActions = state.find(
        (ReasonsActions) => ReasonsActions.id === id
      );
      if (existingReasonsActions) {
        existingReasonsActions.name = name;
        existingReasonsActions.is_system = is_system;
      }
    },
  },
});
export const { addReasonsActions, setReasonsActions, updateReasonsActions } =
  reasonsActionsSlice.actions;

export const ReasonsActionsSelector = (state: RootState) =>
  state.reasonsActionsReducer;

export default reasonsActionsSlice.reducer;
