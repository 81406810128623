import type { MenuProps } from "antd";
import { Avatar, Dropdown, Space, notification } from "antd";
import { MainContext } from "../../../utils/context";
import React, { useContext, useEffect } from "react";
import styles from "../../../assets/style/css/MainLayout.module.css";
import { HiUserCircle } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { WORK_MODE } from "../../../constants/workModes";
import AuthService from "../../../services/auth.service";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setPermissions } from "../../../redux/reducers/permissions.Reducer";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../../pages/organization/logic/OrganizationLogic";
import { FaAngleDown } from "react-icons/fa6";
import { getInitials } from "../../../utils/initials";

const UserProfile: React.FC = () => {
  const { goToConfig } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalContext = useContext(MainContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (!globalContext || !globalContext.context.orga) {
      return;
    }
  }, [globalContext]);
  if (!globalContext) {
    return <></>;
  }
  const handleLogoutClick = () => {
    onLogout();
  };
  const authService = new AuthService(
    globalContext?.context,
    globalContext?.setContext,
    ""
  );

  const onLogout = async () => {
    await authService
      .disconnectToken()
      .then(async (response) => {
        dispatch(setPermissions([]));
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        if (err?.code === "ERR_NETWORK") {
          notification.error({
            message: t("errors:NETWORK_ERROR"),
            placement: "bottomRight",
          });
        } else {
          notification.error({
            message: t("errors:LOGOUT_FAILED"),
            placement: "bottomRight",
          });
        }
      });
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      //danger: true,
      style: { color: "red", marginInlineStart: 0 },
      label: (
        <>
          {" "}
          <Link
            to="/menu/users/config"
            state={{ workModeUser: WORK_MODE.VIEWPROFILE }}
          />
          {t("errors:VIEW_MY_PROFILE")}
        </>
      ),
    },
    {
      key: "2",
      //danger: true,
      style: { color: "red", marginInlineStart: 0 },
      label: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            goToConfig(globalContext.context.orga, WORK_MODE.VIEW, true)
          }
        >
          {t("VIEW_MY_ORGA_PROFILE")}
        </div>
      ),
    },
    {
      key: "3",

      label: (
        <div
          style={{
            cursor: "pointer",
            marginInlineStart: 0,
          }}
          onClick={handleLogoutClick}
        >
          {t("LOGOUT")}
        </div>
      ),
    },
  ];
  return (
    <Dropdown
      menu={{ items }}
      dropdownRender={(menu) => (
        <div>
          {React.cloneElement(menu as React.ReactElement, {
            style: UserProfile,
          })}
        </div>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <Avatar
            className={styles.avatarIcon}
            style={{
              marginBlockStart: "-24px",
              color: "#fefefe",
              marginInlineStart: "4px",
            }}
          >
            {getInitials(globalContext?.context?.user?.displayname)}
          </Avatar>
          <div
            className={styles.avatarText}
            style={{ marginBlockStart: "-24px" }}
          >
            <span>{globalContext?.context?.user?.displayname}</span>
            <span style={{ color: "#7E7E7E" }}>
              {globalContext?.context?.orga?.name}
            </span>
          </div>
          <div style={{ marginBlockStart: "-24px" }}>
            <FaAngleDown color="#848383" />
          </div>
        </Space>
      </a>
    </Dropdown>
  );
};

export default UserProfile;
