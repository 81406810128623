import { useState } from "react";
import { Tabs } from "antd";
import { CODIFICATION_TYPES } from "../../../constants/condificationTypes";
import CodificationTable from "./components/CodificationTable";
import { useTranslation } from "react-i18next";
interface CodificationItem {
  label: string;
  key: string;
  children?: JSX.Element;
}

function Codifications() {
  const [currentTab, setCurrentTab] = useState<string>("Copilots");
  const { t } = useTranslation();
  const items: CodificationItem[] = [
    {
      label: t("COPILOT"),
      key: "Copilots",
      children: (
        <CodificationTable codificationtype={CODIFICATION_TYPES.COPILOTS} />
      ),
    },
    {
      label: t("ROLES"),
      key: "Roles",
      children: (
        <CodificationTable codificationtype={CODIFICATION_TYPES.ROLES} />
      ),
    },
    {
      label: t("RISK_REASONS_CODES"),
      key: "riskReason",
      children: (
        <CodificationTable codificationtype={CODIFICATION_TYPES.RISK_REASONS} />
      ),
    },
    {
      label: t("ACTION_REASONS_CODES"),
      key: "actionReason",
      children: (
        <CodificationTable
          codificationtype={CODIFICATION_TYPES.ACTION_REASONS}
        />
      ),
    },
    {
      label: t("EXECUTION_REASONS_CODES"),
      key: "execReason",
      children: (
        <CodificationTable
          codificationtype={CODIFICATION_TYPES.EXECUTION_REASONS}
        />
      ),
    },
  ];

  const onChangeTab = (key: string) => {
    setCurrentTab(key);
  };

  return (
    <div>
      <Tabs onChange={onChangeTab} activeKey={currentTab} tabBarGutter={10}>
        {items.map((item) => (
          <Tabs.TabPane key={item.key} tab={<span>{item.label}</span>}>
            {item.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default Codifications;
