export const mapRejectionByReasonCodeData = (data: any) => {
  const mappedData = data
    ?.map((obj: any) => {
      return {
        rejection_code: obj.rejection_code,
        risk_status: obj.risk_status,
      };
    })
    ?.filter((obj: any) => obj.risk_status === "Rejected")
    ?.reduce((accumulator: any, item: any) => {
      const { rejection_code } = item;
      const key = `${rejection_code}`;

      if (!accumulator[key]) {
        accumulator[key] = { rejection_code, value: 0 };
      }

      accumulator[key].value += 1;

      return accumulator;
    }, {});
  return Object.values(mappedData);
};
