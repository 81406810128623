import { CATEGORY_VIEWS } from "../../../../constants/categoryviews";

export const fulfillement_Configs = [
  {
    name: "Alternative DC",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
          dynamic_table: [
            {
              title: "Truck load ",
              data: {
                TRUCK_PRODUCT_LOAD_LIST: "$.DATA.TRUCK_PRODUCT_LOAD_LIST",
              },
              columns: {
                TRUCK_ID: "$.TRUCK_ID",
                LOAD_TYPE: "$.LOAD_TYPE",
                TRUCK_TYPE: "$.TRUCK_TYPE",
                MAXIMUM_TRUCK_LOAD_CAPACITY: "$.MAXIMUM_TRUCK_LOAD_CAPACITY",
                MINIMUM_TRUCK_LOAD_CAPACITY: "$.MINIMUM_TRUCK_LOAD_CAPACITY",

                TRUCK_LOADED_PALLET_QUANTITY: "$.TRUCK_LOADED_PALLET_QUANTITY",
                TRUCK_REMAINING_PALLET_CAPACITY:
                  "$.TRUCK_REMAINING_PALLET_CAPACITY",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
            {
              title: "Product load",
              data: {
                TRUCK_PRODUCT_LOAD_LIST: "$.DATA.TRUCK_PRODUCT_LOAD_LIST",
              },
              columns: {
                TRUCK_ID: "$.TRUCK_ID",
                ITEM_CODE: "$.ITEM_CODE",
                FREIGHT_COST: "$.FREIGHT_COST",

                PRODUCT_LOADED_PALLET_QUANTITY:
                  "$.PRODUCT_LOADED_PALLET_QUANTITY",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
          flow_chart: [
            {
              title: "Customer order",
              type: "ARRAY",
              data: { Customer_Order: "$.DATA.Customer_Order" },

              startNodes: ["Shipping_Location", "Ship_Date"],
              endNodes: ["Receiving_Location", "Delivery_Date"],

              details: ["Quantity", "line", "Customer"],
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          map: [
            {
              title: "delivery route",
              type: "OBJECT",
              data: {
                Procurement_Shipping_Conditions:
                  "$.DATA.Procurement_Shipping_Conditions",
              },
              receiving_location: {
                Receiving_Location: `$.DATA.Procurement_Shipping_Conditions.Receiving_Location`,
              },
              shipping_location: {
                Shipping_Location: `$.DATA.Procurement_Shipping_Conditions.Shipping_Location`,
              },
              receiving_location_city: "Receiving_Location",
              shipping_location_city: "Shipping_Location",
              details: [
                "Customer",
                "Route_Name",
                "Mode_of_Transport",
                "Distance",
                "Lead_time_in_days",
                "Cost_per_unit_shipped",
                "CO2_emission",
              ],
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
  {
    name: "Item substitution",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
          dynamic_table: [
            {
              title: "Truck load ",
              data: {
                TRUCK_PRODUCT_LOAD_LIST: "$.DATA.TRUCK_PRODUCT_LOAD_LIST",
              },
              columns: {
                TRUCK_ID: "$.TRUCK_ID",
                LOAD_TYPE: "$.LOAD_TYPE",
                TRUCK_TYPE: "$.TRUCK_TYPE",
                MAXIMUM_TRUCK_LOAD_CAPACITY: "$.MAXIMUM_TRUCK_LOAD_CAPACITY",
                MINIMUM_TRUCK_LOAD_CAPACITY: "$.MINIMUM_TRUCK_LOAD_CAPACITY",

                TRUCK_LOADED_PALLET_QUANTITY: "$.TRUCK_LOADED_PALLET_QUANTITY",
                TRUCK_REMAINING_PALLET_CAPACITY:
                  "$.TRUCK_REMAINING_PALLET_CAPACITY",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
            {
              title: "Product load",
              data: {
                TRUCK_PRODUCT_LOAD_LIST: "$.DATA.TRUCK_PRODUCT_LOAD_LIST",
              },
              columns: {
                TRUCK_ID: "$.TRUCK_ID",
                ITEM_CODE: "$.ITEM_CODE",
                FREIGHT_COST: "$.FREIGHT_COST",

                PRODUCT_LOADED_PALLET_QUANTITY:
                  "$.PRODUCT_LOADED_PALLET_QUANTITY",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
          flow_chart: [
            {
              title: "Customer order",
              type: "ARRAY",
              data: { Customer_Order: "$.DATA.Customer_Order" },

              startNodes: ["Shipping_Location", "Ship_Date"],
              endNodes: ["Receiving_Location", "Delivery_Date"],

              details: ["Quantity", "line", "Customer"],
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          map: [
            {
              title: "delivery route",
              type: "OBJECT",
              data: {
                Procurement_Shipping_Conditions:
                  "$.DATA.Procurement_Shipping_Conditions",
              },
              receiving_location: {
                Receiving_Location: `$.DATA.Procurement_Shipping_Conditions.Receiving_Location`,
              },
              shipping_location: {
                Shipping_Location: `$.DATA.Procurement_Shipping_Conditions.Shipping_Location`,
              },
              receiving_location_city: "Receiving_Location",
              shipping_location_city: "Shipping_Location",
              details: [
                "Customer",
                "Route_Name",
                "Mode_of_Transport",
                "Distance",
                "Lead_time_in_days",
                "Cost_per_unit_shipped",
                "CO2_emission",
              ],
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
  {
    name: "Partial Delivery",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
          dynamic_table: [
            {
              title: "Truck load ",
              data: {
                TRUCK_PRODUCT_LOAD_LIST: "$.DATA.TRUCK_PRODUCT_LOAD_LIST",
              },
              columns: {
                TRUCK_ID: "$.TRUCK_ID",
                LOAD_TYPE: "$.LOAD_TYPE",
                TRUCK_TYPE: "$.TRUCK_TYPE",
                MAXIMUM_TRUCK_LOAD_CAPACITY: "$.MAXIMUM_TRUCK_LOAD_CAPACITY",
                MINIMUM_TRUCK_LOAD_CAPACITY: "$.MINIMUM_TRUCK_LOAD_CAPACITY",

                TRUCK_LOADED_PALLET_QUANTITY: "$.TRUCK_LOADED_PALLET_QUANTITY",
                TRUCK_REMAINING_PALLET_CAPACITY:
                  "$.TRUCK_REMAINING_PALLET_CAPACITY",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
            {
              title: "Product load",
              data: {
                TRUCK_PRODUCT_LOAD_LIST: "$.DATA.TRUCK_PRODUCT_LOAD_LIST",
              },
              columns: {
                TRUCK_ID: "$.TRUCK_ID",
                ITEM_CODE: "$.ITEM_CODE",
                FREIGHT_COST: "$.FREIGHT_COST",

                PRODUCT_LOADED_PALLET_QUANTITY:
                  "$.PRODUCT_LOADED_PALLET_QUANTITY",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
          flow_chart: [
            {
              title: "Customer order",
              type: "ARRAY",
              data: { Customer_Order: "$.DATA.Customer_Order" },

              startNodes: ["Shipping_Location", "Ship_Date"],
              endNodes: ["Receiving_Location", "Delivery_Date"],

              details: ["Quantity", "line", "Customer"],
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          line_chart: [
            {
              title: "inventory ",
              type: "ARRAY",
              data: {
                Inventory: "$.DATA.Inventory",
              },
              colorConfig: [{ value: "Inventory", color:  "#EA7600" }],
              xField: "Date",
              yField: "Units",
              multiple: true,
            },
          ],
          map: [
            {
              title: "delivery route",
              type: "OBJECT",
              data: {
                Procurement_Shipping_Conditions:
                  "$.DATA.Procurement_Shipping_Conditions",
              },
              receiving_location: {
                Receiving_Location: `$.DATA.Procurement_Shipping_Conditions.Receiving_Location`,
              },
              shipping_location: {
                Shipping_Location: `$.DATA.Procurement_Shipping_Conditions.Shipping_Location`,
              },
              receiving_location_city: "Receiving_Location",
              shipping_location_city: "Shipping_Location",
              details: [
                "Customer",
                "Route_Name",
                "Mode_of_Transport",
                "Distance",
                "Lead_time_in_days",
                "Cost_per_unit_shipped",
                "CO2_emission",
              ],
            },
          ],
        
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
  {
    name: "Express Delivery",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
          dynamic_table: [
            {
              title: "Truck load ",
              data: {
                TRUCK_PRODUCT_LOAD_LIST: "$.DATA.TRUCK_PRODUCT_LOAD_LIST",
              },
              columns: {
                TRUCK_ID: "$.TRUCK_ID",
                LOAD_TYPE: "$.LOAD_TYPE",
                TRUCK_TYPE: "$.TRUCK_TYPE",
                MAXIMUM_TRUCK_LOAD_CAPACITY: "$.MAXIMUM_TRUCK_LOAD_CAPACITY",
                MINIMUM_TRUCK_LOAD_CAPACITY: "$.MINIMUM_TRUCK_LOAD_CAPACITY",

                TRUCK_LOADED_PALLET_QUANTITY: "$.TRUCK_LOADED_PALLET_QUANTITY",
                TRUCK_REMAINING_PALLET_CAPACITY:
                  "$.TRUCK_REMAINING_PALLET_CAPACITY",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
            {
              title: "Product load",
              data: {
                TRUCK_PRODUCT_LOAD_LIST: "$.DATA.TRUCK_PRODUCT_LOAD_LIST",
              },
              columns: {
                TRUCK_ID: "$.TRUCK_ID",
                ITEM_CODE: "$.ITEM_CODE",
                FREIGHT_COST: "$.FREIGHT_COST",

                PRODUCT_LOADED_PALLET_QUANTITY:
                  "$.PRODUCT_LOADED_PALLET_QUANTITY",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
          flow_chart: [
            {
              title: "Customer order",
              type: "ARRAY",
              data: { Customer_Order: "$.DATA.Customer_Order" },

              startNodes: ["Shipping_Location", "Ship_Date"],
              endNodes: ["Receiving_Location", "Delivery_Date"],

              details: ["Quantity", "line", "Customer"],
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          map: [
            {
              title: "delivery route",
              type: "OBJECT",
              data: {
                Procurement_Shipping_Conditions:
                  "$.DATA.Procurement_Shipping_Conditions",
              },
              receiving_location: {
                Receiving_Location: `$.DATA.Procurement_Shipping_Conditions.Receiving_Location`,
              },
              shipping_location: {
                Shipping_Location: `$.DATA.Procurement_Shipping_Conditions.Shipping_Location`,
              },
              receiving_location_city: "Receiving_Location",
              shipping_location_city: "Shipping_Location",
              details: [
                "Customer",
                "Route_Name",
                "Mode_of_Transport",
                "Distance",
                "Lead_time_in_days",
                "Cost_per_unit_shipped",
                "CO2_emission",
              ],
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
];

export const demand_Matching_Configs = [
  {
    name: "Reallocate demand to markets",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          mixed_chart: [
            {
              line_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [{ value: "INVENTORY", color: "#008BC7" }],
                  xField: "date",
                  yField: "Quantity_in_units",
                  multiple: false,
                },
              ],
              column_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Supply_Plan",
                },
              ],
              area_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Delta_Quantity",
                },
              ],
              title: "Demand supply matching",
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
  {
    name: "Increase Supply",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          mixed_chart: [
            {
              line_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [{ value: "INVENTORY", color: "#008BC7" }],
                  xField: "date",
                  yField: "Quantity_in_units",
                  multiple: false,
                },
              ],
              column_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Supply_Plan",
                },
              ],
              area_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Delta_Quantity",
                },
              ],
              title: "Demand supply matching",
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
  {
    name: "Expedite partial Deployment",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          mixed_chart: [
            {
              line_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [{ value: "INVENTORY", color: "#008BC7" }],
                  xField: "date",
                  yField: "Quantity_in_units",
                  multiple: false,
                },
              ],
              column_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Supply_Plan",
                },
              ],
              area_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Delta_Quantity",
                },
              ],
              title: "Demand supply matching",
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
  /**Expedite deployment  */
  {
    name: "Expedite deployment",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          mixed_chart: [
            {
              line_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [{ value: "INVENTORY", color: "#008BC7" }],
                  xField: "date",
                  yField: "Quantity_in_units",
                  multiple: false,
                },
              ],
              column_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Supply_Plan",
                },
              ],
              area_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Delta_Quantity",
                },
              ],
              title: "Demand supply matching",
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
];

export const supply_Matching_Configs = [
  {
    name: "Delay Supply",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          waterfall_chart: [
            {
              title: "Stock Situaton",
              type: "ARRAY",
              data: {
                Stock_Situaton: "$.DATA.Stock_Situaton",
              },
              colorConfig: [{ value: "Stock_Situation", color: "#008BC7" }],
              yField: "Quantity",
              xField: "Stock_Situation",
            },
          ],
          mixed_chart: [
            {
              line_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [{ value: "INVENTORY", color: "#008BC7" }],
                  xField: "date",
                  yField: "Quantity_in_units",
                  multiple: false,
                },
              ],
              column_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Supply_Plan",
                },
              ],
              area_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Delta_Quantity",
                },
              ],
              title: "Demand supply matching",
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
  {
    name: "Decrease Supply",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          waterfall_chart: [
            {
              title: "Stock Situaton",
              type: "ARRAY",
              data: {
                Stock_Situaton: "$.DATA.Stock_Situaton",
              },
              colorConfig: [{ value: "Stock_Situation", color: "#008BC7" }],
              yField: "Quantity",
              xField: "Stock_Situation",
            },
          ],
          mixed_chart: [
            {
              line_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [{ value: "INVENTORY", color: "#008BC7" }],
                  xField: "date",
                  yField: "Quantity_in_units",
                  multiple: false,
                },
              ],
              column_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Supply_Plan",
                },
              ],
              area_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Delta_Quantity",
                },
              ],
              title: "Demand supply matching",
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
  {
    name: "Delay Deployment",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          waterfall_chart: [
            {
              title: "Stock Situaton",
              type: "ARRAY",
              data: {
                Stock_Situaton: "$.DATA.Stock_Situaton",
              },
              colorConfig: [{ value: "Stock_Situation", color: "#008BC7" }],
              yField: "Quantity",
              xField: "Stock_Situation",
            },
          ],
          mixed_chart: [
            {
              line_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [{ value: "INVENTORY", color: "#008BC7" }],
                  xField: "date",
                  yField: "Quantity_in_units",
                  multiple: false,
                },
              ],
              column_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Supply_Plan",
                },
              ],
              area_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Demand_Supply_Matching: "$.DATA.Demand_Supply_Matching",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Delta_Quantity",
                },
              ],
              title: "Demand supply matching",
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
];

export const inbound_Freight_Configs = [
  {
    name: "Early request custom clearance",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
          dynamic_table: [
            {
              title: "",
              data: {
                Table: "$.DATA.Table",
              },
              columns: {
                Item_Code: "$.Item_Code",
                GOOD_VALUE: "$.GOOD_VALUE",
                HTS: "$.HTS",
                TYPE: "$.TYPE",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          map_sea: [
            {
              title: "Early Request Custom clearance",
              type: "OBJECT",
              data: {
                Early_Request_Custom_clearance:
                  "$.DATA.Early_Request_Custom_clearance",
              },
              receiving_location: {
                Receiving_Location: `$.DATA.Early_Request_Custom_clearance.Receiving_Location`,
              },
              shipping_location: {
                Shipping_Location: `$.DATA.Early_Request_Custom_clearance.Shipping_Location`,
              },
              receiving_location_city: "Receiving_Location",
              shipping_location_city: "Shipping_Location",
              is_route: true,
              details: [
                "Current_Location",
                "Route_Name",
                "Planned_Boat_ETA",
                "Clearance",
                "Customer",
              ],
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
  {
    name: "Defer custom clearance",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
          dynamic_table: [
            {
              title: "",
              data: {
                Table: "$.DATA.Table",
              },
              columns: {
                Item_Code: "$.Item_Code",
                GOOD_VALUE: "$.GOOD_VALUE",
                HTS: "$.HTS",
                TYPE: "$.TYPE",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          map_sea: [
            {
              title: "Early Request Custom clearance",
              type: "OBJECT",
              data: {
                Early_Request_Custom_clearance:
                  "$.DATA.Early_Request_Custom_clearance",
              },
              receiving_location: {
                Receiving_Location: `$.DATA.Early_Request_Custom_clearance.Receiving_Location`,
              },
              shipping_location: {
                Shipping_Location: `$.DATA.Early_Request_Custom_clearance.Shipping_Location`,
              },
              receiving_location_city: "Receiving_Location",
              shipping_location_city: "Shipping_Location",
              is_route: true,
              details: [
                "Current_Location",
                "Route_Name",
                "Planned_Boat_ETA",
                "Clearance",
                "Customer",
              ],
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
  {
    name: "Expedite custom clearance request",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
          dynamic_table: [
            {
              title: "",
              data: {
                Table: "$.DATA.Table",
              },
              columns: {
                Item_Code: "$.Item_Code",
                GOOD_VALUE: "$.GOOD_VALUE",
                HTS: "$.HTS",
                TYPE: "$.TYPE",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          map_sea: [
            {
              title: "Early Request Custom clearance",
              type: "OBJECT",
              data: {
                Early_Request_Custom_clearance:
                  "$.DATA.Early_Request_Custom_clearance",
              },
              receiving_location: {
                Receiving_Location: `$.DATA.Early_Request_Custom_clearance.Receiving_Location`,
              },
              shipping_location: {
                Shipping_Location: `$.DATA.Early_Request_Custom_clearance.Shipping_Location`,
              },
              receiving_location_city: "Receiving_Location",
              shipping_location_city: "Shipping_Location",
              is_route: true,
              details: [
                "Current_Location",
                "Route_Name",
                "Planned_Boat_ETA",
                "Clearance",
                "Customer",
              ],
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
  {
    name: "Expedite transfer",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
          dynamic_table: [
            {
              title: "",
              data: {
                Table: "$.DATA.Table",
              },
              columns: {
                Item_Code: "$.Item_Code",
                NUMBER_OF_PALLETS: "$.NUMBER_OF_PALLETS",
                NUMBER_OF_UNITS: "$.NUMBER_OF_UNITS",
                TOTAL_WEIGHT: "$.TOTAL_WEIGHT",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          mixed_chart: [
            {
              line_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Inbound_Inventory: "$.DATA.Inbound_Inventory",
                  },
                  colorConfig: [{ value: "INVENTORY", color: "#008BC7" }],
                  xField: "date",
                  yField: "Supply",
                  multiple: false,
                },
              ],
              column_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Inbound_Inventory: "$.DATA.Inbound_Inventory",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Demand",
                },
              ],
              area_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Inbound_Inventory: "$.DATA.Inbound_Inventory",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Quantity",
                },
              ],
              title: "Inbound Inventory",
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
  {
    name: "Reroute transfer",
    category: {
      ACTION_DETAILS: {
        title: "ACTION_DETAILS",
        views: {
          inputs: [
            {
              title: "Details",
              column: null,
              data: { ACTION_DETAILS: "$.DATA.ACTION_DETAILS" },
            },
          ],
          dynamic_table: [
            {
              title: "",
              data: {
                Table: "$.DATA.Table",
              },
              columns: {
                Item_Code: "$.Item_Code",
                NUMBER_OF_PALLETS: "$.NUMBER_OF_PALLETS",
                NUMBER_OF_UNITS: "$.NUMBER_OF_UNITS",
                TOTAL_WEIGHT: "$.TOTAL_WEIGHT",
              },

              type: CATEGORY_VIEWS.OTHER,
            },
          ],
        },
      },

      RISK_IMPACTS: {
        title: "RISK_IMPACT",
        views: {
          inputs: [
            {
              title: "Impact",
              column: null,
              data: { IMPACT: "$.DATA.IMPACT" },
            },
          ],
        },
      },
      ACTION_CONTEXT_VIEW: {
        title: "Action_details",
        views: {
          mixed_chart: [
            {
              line_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Inbound_Inventory: "$.DATA.Inbound_Inventory",
                  },
                  colorConfig: [{ value: "INVENTORY", color: "#008BC7" }],
                  xField: "date",
                  yField: "Supply",
                  multiple: false,
                },
              ],
              column_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Inbound_Inventory: "$.DATA.Inbound_Inventory",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Demand",
                },
              ],
              area_chart: [
                {
                  type: "ARRAY",
                  data: {
                    Inbound_Inventory: "$.DATA.Inbound_Inventory",
                  },
                  colorConfig: [
                    { value: "Demand_Supply_Matching", color: "#008BC7" },
                  ],
                  xField: "date",
                  yField: "Quantity",
                },
              ],
              title: "Inbound Inventory",
            },
          ],
        },
      },
      ERROR_WARNING_VIEW: {
        title: "ERRORS_WARNING",
        views: {
          dynamic_table: [
            {
              title: "Errors & warnings",
              data: {
                ERROR: "$.DATA.ERRORS.ERROR",
                INFO: "$.DATA.ERRORS.INFO",
                WARNING: "$.DATA.ERRORS.WARNING",
              },
              columns: {
                CODE: "$.CODE",
                DETAILS: "$.DETAILS.DATA",
                MODULE: "$.MODULE",
              },

              type: CATEGORY_VIEWS.ERROR_WARNING_VIEW,
            },
          ],
        },
      },
      OTHER_OPTIONS: true,
    },
  },
];
