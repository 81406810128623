import { Card } from "antd";
import GetChartByConfig from "../functions/GetChartByConfig";
import MenuOptions from "../../../../../../common/menu/MenuOptions";
import { ChartConf } from "../../../interfaces/ChartConf";
import { ChartParams } from "../../charts/functions/ChartParams.interface";

interface ChartCardProps {
  config: ChartConf;
  index: number;
  viewChartModal: (config: ChartConf, value: number) => void;
  updateChartModal: (config: ChartConf, value: number) => void;
  deleteChartConfig: (value: number) => void;
  showDrawer: (value: any) => void;
  data: any[];
  style?: any;

  fields: any;
  params: ChartParams;
}

const ChartCard = (props: ChartCardProps) => {
  const {
    index,
    config,
    fields,
    params,
    data,
    viewChartModal,
    updateChartModal,
    deleteChartConfig,
    showDrawer,
    // data,
    style,
  } = props;

  return (
    <Card
      title={config?.title}
      style={{ ...style }}
      extra={
        index >= 0 ? (
          <MenuOptions
            viewRecord={() => {
              viewChartModal(config, index);
            }}
            editRecord={() => {
              updateChartModal(config, index);
            }}
            deleteRecord={() => {
              deleteChartConfig(index);
            }}
          />
        ) : null
      }
    >
      {GetChartByConfig(config, fields, params, data, showDrawer)}
    </Card>
  );
};

export default ChartCard;
