export const Demand_Matching_Risk = [
  /** Risk 2 Reallocate demand to markets*/
  {
    id: 1,
    risk_conf_name: "Demand Fulfillment Risk",
    due_date: "2024-11-25 00:00:00",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge25255645", name: "Action Error" },
      { id: "fgfergger25255645", name: "Execution Error" },
    ],
    current_action: {
      action_conf: null,
      action_conf_name: "Reallocate demand to markets",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Location_code: "Riyadh",
                Item_Code: "SKU-346",
                Date_of_risk: "2024-11-29",
                Quantity_in_Excess: "480",
                Other_stock_Value: "26,400€",
              },
              OTHER_OPTIONS: [],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Quantity_In_Excess: "480",
                Revenue_loss_risk: "6,800€",
                Delivery_Lead_Time: "3 Days",
                Impact_on_Freight_CO2_emissions: "5.4 KG",
                Source_Location: "Ryadh",
                Destination_Location: "Dubai",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },

              Demand_Supply_Matching: [
                {
                  date: "2024-11-31",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-01",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 560,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -10,
                  Delta_value: 0,
                  Supply_Plan: 560,
                },
                {
                  date: "2024-12-02",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 480,
                  Delta_value: 26400,
                  Supply_Plan: 960,
                },
                {
                  date: "2024-12-03",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -140,
                  Delta_value: 7700,
                  Supply_Plan: 460,
                },
                {
                  date: "2024-12-04",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 380,
                },
                {
                  date: "2024-12-05",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 500,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -20,
                  Delta_value: 1100,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-06",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 10,
                  Delta_value: 0,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-07",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -40,
                  Delta_value: 2200,
                  Supply_Plan: 360,
                },
                {
                  date: "2024-12-08",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 300,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 64,
                  Delta_value: 0,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-09",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 71,
                  Delta_value: 0,
                  Supply_Plan: 600,
                },
            ]
            ,
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "436c7d5b58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Market Validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bddddafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
     
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    status: "WAIT_FOR_ACTION",
    copilot: "",
    model: "Demand Fulfillment Risk",
    context: {
      Supplier: "7",
      Location_code: "Riyadh",
      Item_Code: "SKU-346",
      Date_of_risk: "2024-11-29",
      Excess_Quantity: "480",
      Monthly_Excess_Stock_Cost: "1,400€",
    },
    impact: "1,400€",

    actions:[

      {
        action_conf: null,
        action_conf_name: "Reallocate demand to markets",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Location_code: "Riyadh",
                  Item_Code: "SKU-346",
                  Date_of_risk: "2024-11-29",
                  Quantity_in_Excess: "480",
                  Other_stock_Value: "26,400€",
                },
                OTHER_OPTIONS: [],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_In_Excess: "480",
                  Revenue_loss_risk: "6,800€",
                  Delivery_Lead_Time: "3 Days",
                  Impact_on_Freight_CO2_emissions: "5.4 KG",
                  Source_Location: "Ryadh",
                  Destination_Location: "Dubai",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Demand_Supply_Matching: [
                  {
                    date: "2024-11-31",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 560,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 0,
                    Supply_Plan: 560,
                  },
                  {
                    date: "2024-12-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 480,
                    Delta_value: 26400,
                    Supply_Plan: 960,
                  },
                  {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -140,
                    Delta_value: 7700,
                    Supply_Plan: 460,
                  },
                  {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 380,
                  },
                  {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 500,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 1100,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 10,
                    Delta_value: 0,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -40,
                    Delta_value: 2200,
                    Supply_Plan: 360,
                  },
                  {
                    date: "2024-12-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 64,
                    Delta_value: 0,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 71,
                    Delta_value: 0,
                    Supply_Plan: 600,
                  },
              ]
              ,
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "436c7d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Market Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bddddafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
       
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Increase Supply",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Location_code: "Dubai",
                  Item_Code: "SKU-995",
                  Date_of_risk: "2024-11-30",
                  Quantity: "20",
                  Revenue_At_Risk: "1,100€",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-995",
                  Date_of_risk: "2024-11-30",
                  Quantity: "20",
                  Impact_on_Freight_CO2_emissions: "5.4 KG",
                  Impact_on_Freight_Cost: "950€",
                  Supply_Date: "2024-11-10",
                  Supply_Order: "100304000",
                  Source: "Breda",
                  Destination: " Dubai",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Demand_Supply_Matching: [
                  {
                      date: "2023-11-01",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 200,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -36,
                      Delta_value: 0,
                      Supply_Plan: 200,
                  },
                  {
                      date: "2023-11-02",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 280,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -50,
                      Delta_value: 0,
                      Supply_Plan: 280,
                  },
                  {
                      date: "2023-11-03",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 300,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 54,
                      Delta_value: 0,
                      Supply_Plan: 300,
                  },
                  {
                      date: "2023-11-04",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 180,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -20,
                      Delta_value: 645.2,
                      Supply_Plan: 160,
                  },
                  {
                      date: "2023-11-05",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 240,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -20,
                      Delta_value: 645.2,
                      Supply_Plan: 220,
                  },
                  {
                      date: "2023-11-06",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 240,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -10,
                      Delta_value: 322.6,
                      Supply_Plan: 230,
                  },
                  {
                      date: "2023-11-07",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 300,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -5,
                      Delta_value: 0,
                      Supply_Plan: 300,
                  }
              ]
              ,
              OTHER_OPTIONS: [
                {
                  Action_Name: "Reallocate demand to markets",
                  Reason_Code: "capacity limitation",
                  Execution_Stage: "Demand Planner",
                  User: "Exobrain",
                },
              ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c367d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Supply Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ccebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
      
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite partial Deployment",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Item_Code: "SKU-213",
                  Date_of_risk: "2024-11-30",
                  Quantity: "25",
                  Impact_on_Freight_CO2_emissions: "230 kg",
                  Impact_on_Freight_Cost: "2,000€",
                  Mode_of_Transport: "Air",
                  Supply_Date: "2024-11-15",
                  Supply_Order: "1004459000",
                  Source: "Breda",
                  Destination: " Dubai",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 9127,
                  Revenue_at_risk: 229909.13,
                  Penalty_risk: "9,886€",
                  Express_delivery_cost: "6,758€",
                  Express_delivery_value_ratio: 0.029,
                  Delivery_CO2_emission_impact: 140.56,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Reallocate demand to markets",
                    Reason_Code: "freight budget",
                    Execution_Stage: "Demand Planner",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Increase Supply",
                    Reason_Code: "capacity limitation",
                    Execution_Stage: "Supply Planning",
                    User: "Exobrain",
                  },
                ],
                Demand_Supply_Matching: [
                  {
                      date: "2024-11-01",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 880,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -49,
                      Delta_value: 0,
                      Supply_Plan: 880,
                  },
                  {
                      date: "2024-11-02",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1200,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -40,
                      Delta_value: 12904,
                      Supply_Plan: 800,
                  },
                  {
                      date: "2024-11-03",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1100,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -4,
                      Delta_value: 0,
                      Supply_Plan: 1100,
                  },
                  {
                      date: "2024-11-04",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1280,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -160,
                      Delta_value: 11613.6,
                      Supply_Plan: 920,
                  },
                  {
                      date: "2024-11-05",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 980,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -120,
                      Delta_value: 3871,
                      Supply_Plan: 860,
                  },
                  {
                      date: "2024-11-06",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1000,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 50,
                      Delta_value: 3226,
                      Supply_Plan: 900,
                  },
                  {
                      date: "2024-11-07",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 980,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 5,
                      Delta_value: 0,
                      Supply_Plan: 980,
                  },
                  {
                      date: "2024-11-08",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1000,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -40,
                      Delta_value: 0,
                      Supply_Plan: 1000,
                  },
                  {
                      date: "2024-11-09",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 600,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -19,
                      Delta_value: 0,
                      Supply_Plan: 600,
                  },
                  {
                      date: "2024-11-10",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1000,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 22,
                      Delta_value: 0,
                      Supply_Plan: 1000,
                  }
              ]
              ,
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c447d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Supply Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebfpp9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Manufacturing Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebfoo9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Logistics",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebssf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite deployment",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Location_code: "Riyadh",
                  Item_Code: "SKU-792",
                  Date_of_risk: "2024-11-28",
                  Quantity: "10",
                  Revenue_At_Risk: "10,199€",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-792",
                  Date_of_risk: "2024-11-28",
                  Quantity: "10",
                  Impact_on_Freight_CO2_emissions: "150 kg",
                  Impact_on_Freight_Cost: "1,000€",
                  Mode_of_Transport: "Air",
                  Supply_Date: "2024-11-14",
                  Supply_Order: "1006649900",
                  Source: "Breda",
                  Destination: " Riyadh",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                OTHER_OPTIONS: [],
                Demand_Supply_Matching: [ 
                  {
                      date: "2024-11-28",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 80,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -1,
                      Delta_value: 0,
                      Supply_Plan: 80,
                  },
                  {
                      date: "2024-11-29",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 70,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -10,
                      Delta_value: 10199,
                      Supply_Plan: 60,
                  },
                  {
                      date: "2024-11-30",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 20,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -10,
                      Delta_value: 10199,
                      Supply_Plan: 10,
                  },
                  {
                      date: "2024-12-01",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 100,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -20,
                      Delta_value: 20398,
                      Supply_Plan: 80,
                  },
                  {
                      date: "2024-12-02",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 80,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -6,
                      Delta_value: 6119.4,
                      Supply_Plan: 74,
                  },
                  {
                      date: "2024-12-03",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 40,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -2,
                      Delta_value: 2039.8,
                      Supply_Plan: 38,
                  },
                  {
                      date: "2024-12-04",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 80,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: 30,
                      Delta_value: 0,
                      Supply_Plan: 80,
                  },
                  {
                      date: "2024-12-05",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 10,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: 33,
                      Delta_value: 0,
                      Supply_Plan: 10,
                  },
                  {
                      date: "2024-12-06",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 10,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: 15,
                      Delta_value: 0,
                      Supply_Plan: 10,
                  },
                  {
                      date: "2024-12-07",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 20,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -5,
                      Delta_value: 0,
                      Supply_Plan: 20,
                  }
              ]
    ,            
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d5kkb58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Supply Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Manufacturing Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Logistics",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf49bafe-b462-4809-93fd-2f5d5f1149611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      }
    ]
    
  },

  /** Risk 4 Increase Supply*/
  {
    id: 4,
    risk_conf_name: "Demand Fulfillment Risk",
    due_date: "2024-11-26 00:00:00",
    copilot: "Procurement	",
    model: "Fulfilment Shortage",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge25255645", name: "Action Error" },
      { id: "fgfergger25255645", name: "Execution Error" },
    ],
    status: "WAIT_FOR_ACTION",
    context: {
      Supplier: "5",
      Location_code: "Dubai",
      Item_Code: "SKU-995",
      Date_of_risk: "2024-11-30",
      Quantity: "20",
      Revenue_At_Risk: "1,100€",
    },
    impact: "1,100€",
    current_action: {
      action_conf: null,
      action_conf_name: "Increase Supply",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Location_code: "Dubai",
                Item_Code: "SKU-995",
                Date_of_risk: "2024-11-30",
                Quantity: "20",
                Revenue_At_Risk: "1,100€",
              },

              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Item_Code: "SKU-995",
                Date_of_risk: "2024-11-30",
                Quantity: "20",
                Impact_on_Freight_CO2_emissions: "5.4 KG",
                Impact_on_Freight_Cost: "950€",
                Supply_Date: "2024-11-10",
                Supply_Order: "100304000",
                Source: "Breda",
                Destination: " Dubai",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              Demand_Supply_Matching: [
                {
                    date: "2023-11-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 200,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -36,
                    Delta_value: 0,
                    Supply_Plan: 200,
                },
                {
                    date: "2023-11-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 280,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -50,
                    Delta_value: 0,
                    Supply_Plan: 280,
                },
                {
                    date: "2023-11-03",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: 54,
                    Delta_value: 0,
                    Supply_Plan: 300,
                },
                {
                    date: "2023-11-04",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 180,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -20,
                    Delta_value: 645.2,
                    Supply_Plan: 160,
                },
                {
                    date: "2023-11-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 240,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -20,
                    Delta_value: 645.2,
                    Supply_Plan: 220,
                },
                {
                    date: "2023-11-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 240,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -10,
                    Delta_value: 322.6,
                    Supply_Plan: 230,
                },
                {
                    date: "2023-11-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -5,
                    Delta_value: 0,
                    Supply_Plan: 300,
                }
            ]
            ,
            OTHER_OPTIONS: [
              {
                Action_Name: "Reallocate demand to markets",
                Reason_Code: "capacity limitation",
                Execution_Stage: "Demand Planner",
                User: "Exobrain",
              },
            ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c367d5b58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Supply Planning",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ccebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
    
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    actions:[

      {
        action_conf: null,
        action_conf_name: "Reallocate demand to markets",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Location_code: "Riyadh",
                  Item_Code: "SKU-346",
                  Date_of_risk: "2024-11-29",
                  Quantity_in_Excess: "480",
                  Other_stock_Value: "26,400€",
                },
                OTHER_OPTIONS: [],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_In_Excess: "480",
                  Revenue_loss_risk: "6,800€",
                  Delivery_Lead_Time: "3 Days",
                  Impact_on_Freight_CO2_emissions: "5.4 KG",
                  Source_Location: "Ryadh",
                  Destination_Location: "Dubai",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Demand_Supply_Matching: [
                  {
                    date: "2024-11-31",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 560,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 0,
                    Supply_Plan: 560,
                  },
                  {
                    date: "2024-12-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 480,
                    Delta_value: 26400,
                    Supply_Plan: 960,
                  },
                  {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -140,
                    Delta_value: 7700,
                    Supply_Plan: 460,
                  },
                  {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 380,
                  },
                  {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 500,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 1100,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 10,
                    Delta_value: 0,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -40,
                    Delta_value: 2200,
                    Supply_Plan: 360,
                  },
                  {
                    date: "2024-12-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 64,
                    Delta_value: 0,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 71,
                    Delta_value: 0,
                    Supply_Plan: 600,
                  },
              ]
              ,
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "436c7d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Market Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bddddafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
       
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Increase Supply",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Location_code: "Dubai",
                  Item_Code: "SKU-995",
                  Date_of_risk: "2024-11-30",
                  Quantity: "20",
                  Revenue_At_Risk: "1,100€",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-995",
                  Date_of_risk: "2024-11-30",
                  Quantity: "20",
                  Impact_on_Freight_CO2_emissions: "5.4 KG",
                  Impact_on_Freight_Cost: "950€",
                  Supply_Date: "2024-11-10",
                  Supply_Order: "100304000",
                  Source: "Breda",
                  Destination: " Dubai",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Demand_Supply_Matching: [
                  {
                      date: "2023-11-01",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 200,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -36,
                      Delta_value: 0,
                      Supply_Plan: 200,
                  },
                  {
                      date: "2023-11-02",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 280,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -50,
                      Delta_value: 0,
                      Supply_Plan: 280,
                  },
                  {
                      date: "2023-11-03",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 300,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 54,
                      Delta_value: 0,
                      Supply_Plan: 300,
                  },
                  {
                      date: "2023-11-04",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 180,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -20,
                      Delta_value: 645.2,
                      Supply_Plan: 160,
                  },
                  {
                      date: "2023-11-05",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 240,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -20,
                      Delta_value: 645.2,
                      Supply_Plan: 220,
                  },
                  {
                      date: "2023-11-06",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 240,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -10,
                      Delta_value: 322.6,
                      Supply_Plan: 230,
                  },
                  {
                      date: "2023-11-07",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 300,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -5,
                      Delta_value: 0,
                      Supply_Plan: 300,
                  }
              ]
              ,
              OTHER_OPTIONS: [
                {
                  Action_Name: "Reallocate demand to markets",
                  Reason_Code: "capacity limitation",
                  Execution_Stage: "Demand Planner",
                  User: "Exobrain",
                },
              ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c367d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Supply Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ccebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
      
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite partial Deployment",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Item_Code: "SKU-213",
                  Date_of_risk: "2024-11-30",
                  Quantity: "25",
                  Impact_on_Freight_CO2_emissions: "230 kg",
                  Impact_on_Freight_Cost: "2,000€",
                  Mode_of_Transport: "Air",
                  Supply_Date: "2024-11-15",
                  Supply_Order: "1004459000",
                  Source: "Breda",
                  Destination: " Dubai",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 9127,
                  Revenue_at_risk: 229909.13,
                  Penalty_risk: "9,886€",
                  Express_delivery_cost: "6,758€",
                  Express_delivery_value_ratio: 0.029,
                  Delivery_CO2_emission_impact: 140.56,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Reallocate demand to markets",
                    Reason_Code: "freight budget",
                    Execution_Stage: "Demand Planner",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Increase Supply",
                    Reason_Code: "capacity limitation",
                    Execution_Stage: "Supply Planning",
                    User: "Exobrain",
                  },
                ],
                Demand_Supply_Matching: [
                  {
                      date: "2024-11-01",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 880,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -49,
                      Delta_value: 0,
                      Supply_Plan: 880,
                  },
                  {
                      date: "2024-11-02",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1200,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -40,
                      Delta_value: 12904,
                      Supply_Plan: 800,
                  },
                  {
                      date: "2024-11-03",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1100,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -4,
                      Delta_value: 0,
                      Supply_Plan: 1100,
                  },
                  {
                      date: "2024-11-04",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1280,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -160,
                      Delta_value: 11613.6,
                      Supply_Plan: 920,
                  },
                  {
                      date: "2024-11-05",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 980,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -120,
                      Delta_value: 3871,
                      Supply_Plan: 860,
                  },
                  {
                      date: "2024-11-06",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1000,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 50,
                      Delta_value: 3226,
                      Supply_Plan: 900,
                  },
                  {
                      date: "2024-11-07",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 980,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 5,
                      Delta_value: 0,
                      Supply_Plan: 980,
                  },
                  {
                      date: "2024-11-08",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1000,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -40,
                      Delta_value: 0,
                      Supply_Plan: 1000,
                  },
                  {
                      date: "2024-11-09",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 600,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -19,
                      Delta_value: 0,
                      Supply_Plan: 600,
                  },
                  {
                      date: "2024-11-10",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1000,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 22,
                      Delta_value: 0,
                      Supply_Plan: 1000,
                  }
              ]
              ,
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c447d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Supply Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebfpp9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Manufacturing Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebfoo9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Logistics",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebssf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite deployment",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Location_code: "Riyadh",
                  Item_Code: "SKU-792",
                  Date_of_risk: "2024-11-28",
                  Quantity: "10",
                  Revenue_At_Risk: "10,199€",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-792",
                  Date_of_risk: "2024-11-28",
                  Quantity: "10",
                  Impact_on_Freight_CO2_emissions: "150 kg",
                  Impact_on_Freight_Cost: "1,000€",
                  Mode_of_Transport: "Air",
                  Supply_Date: "2024-11-14",
                  Supply_Order: "1006649900",
                  Source: "Breda",
                  Destination: " Riyadh",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                OTHER_OPTIONS: [],
                Demand_Supply_Matching: [ 
                  {
                      date: "2024-11-28",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 80,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -1,
                      Delta_value: 0,
                      Supply_Plan: 80,
                  },
                  {
                      date: "2024-11-29",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 70,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -10,
                      Delta_value: 10199,
                      Supply_Plan: 60,
                  },
                  {
                      date: "2024-11-30",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 20,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -10,
                      Delta_value: 10199,
                      Supply_Plan: 10,
                  },
                  {
                      date: "2024-12-01",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 100,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -20,
                      Delta_value: 20398,
                      Supply_Plan: 80,
                  },
                  {
                      date: "2024-12-02",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 80,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -6,
                      Delta_value: 6119.4,
                      Supply_Plan: 74,
                  },
                  {
                      date: "2024-12-03",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 40,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -2,
                      Delta_value: 2039.8,
                      Supply_Plan: 38,
                  },
                  {
                      date: "2024-12-04",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 80,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: 30,
                      Delta_value: 0,
                      Supply_Plan: 80,
                  },
                  {
                      date: "2024-12-05",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 10,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: 33,
                      Delta_value: 0,
                      Supply_Plan: 10,
                  },
                  {
                      date: "2024-12-06",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 10,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: 15,
                      Delta_value: 0,
                      Supply_Plan: 10,
                  },
                  {
                      date: "2024-12-07",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 20,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -5,
                      Delta_value: 0,
                      Supply_Plan: 20,
                  }
              ]
    ,            
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d5kkb58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Supply Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Manufacturing Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Logistics",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf49bafe-b462-4809-93fd-2f5d5f1149611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      }
    ]
    
  },

  /** Risk 6 Expedite Partial Deployment*/
  {
    id: 6,
    risk_conf_name: "Demand Fulfillment Risk",
    due_date: "2024-11-26 00:00:00",
    copilot: "Procurement	",
    model: "Demand Fulfillment Risk",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge455645", name: "Action Error" },
      { id: "fgfergger545545645", name: "Execution Error" },
    ],
    status: "WAIT_FOR_ACTION",
    context: {
      Supplier: "5",
      Location_code: "Dubai",
      Item_Code: "SKU-213",
      Date_of_risk: "2024-11-30",
      Quantity: "400",
      Revenue_At_Risk: "12,904€",
    },
    impact: "12,904€",

    current_action: {
      action_conf: null,
      action_conf_name: "Expedite partial Deployment",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Item_Code: "SKU-213",
                Date_of_risk: "2024-11-30",
                Quantity: "25",
                Impact_on_Freight_CO2_emissions: "230 kg",
                Impact_on_Freight_Cost: "2,000€",
                Mode_of_Transport: "Air",
                Supply_Date: "2024-11-15",
                Supply_Order: "1004459000",
                Source: "Breda",
                Destination: " Dubai",
              },

              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Quantity_at_risk: 9127,
                Revenue_at_risk: 229909.13,
                Penalty_risk: "9,886€",
                Express_delivery_cost: "6,758€",
                Express_delivery_value_ratio: 0.029,
                Delivery_CO2_emission_impact: 140.56,
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },

              OTHER_OPTIONS: [
                {
                  Action_Name: "Reallocate demand to markets",
                  Reason_Code: "freight budget",
                  Execution_Stage: "Demand Planner",
                  User: "Exobrain",
                },
                {
                  Action_Name: "Increase Supply",
                  Reason_Code: "capacity limitation",
                  Execution_Stage: "Supply Planning",
                  User: "Exobrain",
                },
              ],
              Demand_Supply_Matching: [
                {
                    date: "2024-11-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 880,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -49,
                    Delta_value: 0,
                    Supply_Plan: 880,
                },
                {
                    date: "2024-11-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 1200,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -40,
                    Delta_value: 12904,
                    Supply_Plan: 800,
                },
                {
                    date: "2024-11-03",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 1100,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -4,
                    Delta_value: 0,
                    Supply_Plan: 1100,
                },
                {
                    date: "2024-11-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 1280,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -160,
                    Delta_value: 11613.6,
                    Supply_Plan: 920,
                },
                {
                    date: "2024-11-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 980,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -120,
                    Delta_value: 3871,
                    Supply_Plan: 860,
                },
                {
                    date: "2024-11-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 1000,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: 50,
                    Delta_value: 3226,
                    Supply_Plan: 900,
                },
                {
                    date: "2024-11-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 980,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: 5,
                    Delta_value: 0,
                    Supply_Plan: 980,
                },
                {
                    date: "2024-11-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 1000,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -40,
                    Delta_value: 0,
                    Supply_Plan: 1000,
                },
                {
                    date: "2024-11-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -19,
                    Delta_value: 0,
                    Supply_Plan: 600,
                },
                {
                    date: "2024-11-10",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 1000,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: 22,
                    Delta_value: 0,
                    Supply_Plan: 1000,
                }
            ]
            ,
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c447d5b58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Supply Planning",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebfpp9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Manufacturing Planning",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebfoo9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Logistics",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebssf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    actions:[

      {
        action_conf: null,
        action_conf_name: "Reallocate demand to markets",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Location_code: "Riyadh",
                  Item_Code: "SKU-346",
                  Date_of_risk: "2024-11-29",
                  Quantity_in_Excess: "480",
                  Other_stock_Value: "26,400€",
                },
                OTHER_OPTIONS: [],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_In_Excess: "480",
                  Revenue_loss_risk: "6,800€",
                  Delivery_Lead_Time: "3 Days",
                  Impact_on_Freight_CO2_emissions: "5.4 KG",
                  Source_Location: "Ryadh",
                  Destination_Location: "Dubai",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Demand_Supply_Matching: [
                  {
                    date: "2024-11-31",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 560,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 0,
                    Supply_Plan: 560,
                  },
                  {
                    date: "2024-12-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 480,
                    Delta_value: 26400,
                    Supply_Plan: 960,
                  },
                  {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -140,
                    Delta_value: 7700,
                    Supply_Plan: 460,
                  },
                  {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 380,
                  },
                  {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 500,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 1100,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 10,
                    Delta_value: 0,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -40,
                    Delta_value: 2200,
                    Supply_Plan: 360,
                  },
                  {
                    date: "2024-12-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 64,
                    Delta_value: 0,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 71,
                    Delta_value: 0,
                    Supply_Plan: 600,
                  },
              ]
              ,
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "436c7d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Market Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bddddafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
       
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Increase Supply",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Location_code: "Dubai",
                  Item_Code: "SKU-995",
                  Date_of_risk: "2024-11-30",
                  Quantity: "20",
                  Revenue_At_Risk: "1,100€",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-995",
                  Date_of_risk: "2024-11-30",
                  Quantity: "20",
                  Impact_on_Freight_CO2_emissions: "5.4 KG",
                  Impact_on_Freight_Cost: "950€",
                  Supply_Date: "2024-11-10",
                  Supply_Order: "100304000",
                  Source: "Breda",
                  Destination: " Dubai",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Demand_Supply_Matching: [
                  {
                      date: "2023-11-01",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 200,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -36,
                      Delta_value: 0,
                      Supply_Plan: 200,
                  },
                  {
                      date: "2023-11-02",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 280,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -50,
                      Delta_value: 0,
                      Supply_Plan: 280,
                  },
                  {
                      date: "2023-11-03",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 300,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 54,
                      Delta_value: 0,
                      Supply_Plan: 300,
                  },
                  {
                      date: "2023-11-04",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 180,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -20,
                      Delta_value: 645.2,
                      Supply_Plan: 160,
                  },
                  {
                      date: "2023-11-05",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 240,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -20,
                      Delta_value: 645.2,
                      Supply_Plan: 220,
                  },
                  {
                      date: "2023-11-06",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 240,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -10,
                      Delta_value: 322.6,
                      Supply_Plan: 230,
                  },
                  {
                      date: "2023-11-07",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 300,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -5,
                      Delta_value: 0,
                      Supply_Plan: 300,
                  }
              ]
              ,
              OTHER_OPTIONS: [
                {
                  Action_Name: "Reallocate demand to markets",
                  Reason_Code: "capacity limitation",
                  Execution_Stage: "Demand Planner",
                  User: "Exobrain",
                },
              ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c367d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Supply Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ccebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
      
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite partial Deployment",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Item_Code: "SKU-213",
                  Date_of_risk: "2024-11-30",
                  Quantity: "25",
                  Impact_on_Freight_CO2_emissions: "230 kg",
                  Impact_on_Freight_Cost: "2,000€",
                  Mode_of_Transport: "Air",
                  Supply_Date: "2024-11-15",
                  Supply_Order: "1004459000",
                  Source: "Breda",
                  Destination: " Dubai",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 9127,
                  Revenue_at_risk: 229909.13,
                  Penalty_risk: "9,886€",
                  Express_delivery_cost: "6,758€",
                  Express_delivery_value_ratio: 0.029,
                  Delivery_CO2_emission_impact: 140.56,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Reallocate demand to markets",
                    Reason_Code: "freight budget",
                    Execution_Stage: "Demand Planner",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Increase Supply",
                    Reason_Code: "capacity limitation",
                    Execution_Stage: "Supply Planning",
                    User: "Exobrain",
                  },
                ],
                Demand_Supply_Matching: [
                  {
                      date: "2024-11-01",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 880,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -49,
                      Delta_value: 0,
                      Supply_Plan: 880,
                  },
                  {
                      date: "2024-11-02",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1200,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -40,
                      Delta_value: 12904,
                      Supply_Plan: 800,
                  },
                  {
                      date: "2024-11-03",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1100,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -4,
                      Delta_value: 0,
                      Supply_Plan: 1100,
                  },
                  {
                      date: "2024-11-04",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1280,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -160,
                      Delta_value: 11613.6,
                      Supply_Plan: 920,
                  },
                  {
                      date: "2024-11-05",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 980,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -120,
                      Delta_value: 3871,
                      Supply_Plan: 860,
                  },
                  {
                      date: "2024-11-06",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1000,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 50,
                      Delta_value: 3226,
                      Supply_Plan: 900,
                  },
                  {
                      date: "2024-11-07",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 980,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 5,
                      Delta_value: 0,
                      Supply_Plan: 980,
                  },
                  {
                      date: "2024-11-08",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1000,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -40,
                      Delta_value: 0,
                      Supply_Plan: 1000,
                  },
                  {
                      date: "2024-11-09",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 600,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -19,
                      Delta_value: 0,
                      Supply_Plan: 600,
                  },
                  {
                      date: "2024-11-10",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1000,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 22,
                      Delta_value: 0,
                      Supply_Plan: 1000,
                  }
              ]
              ,
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c447d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Supply Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebfpp9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Manufacturing Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebfoo9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Logistics",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebssf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite deployment",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Location_code: "Riyadh",
                  Item_Code: "SKU-792",
                  Date_of_risk: "2024-11-28",
                  Quantity: "10",
                  Revenue_At_Risk: "10,199€",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-792",
                  Date_of_risk: "2024-11-28",
                  Quantity: "10",
                  Impact_on_Freight_CO2_emissions: "150 kg",
                  Impact_on_Freight_Cost: "1,000€",
                  Mode_of_Transport: "Air",
                  Supply_Date: "2024-11-14",
                  Supply_Order: "1006649900",
                  Source: "Breda",
                  Destination: " Riyadh",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                OTHER_OPTIONS: [],
                Demand_Supply_Matching: [ 
                  {
                      date: "2024-11-28",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 80,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -1,
                      Delta_value: 0,
                      Supply_Plan: 80,
                  },
                  {
                      date: "2024-11-29",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 70,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -10,
                      Delta_value: 10199,
                      Supply_Plan: 60,
                  },
                  {
                      date: "2024-11-30",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 20,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -10,
                      Delta_value: 10199,
                      Supply_Plan: 10,
                  },
                  {
                      date: "2024-12-01",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 100,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -20,
                      Delta_value: 20398,
                      Supply_Plan: 80,
                  },
                  {
                      date: "2024-12-02",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 80,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -6,
                      Delta_value: 6119.4,
                      Supply_Plan: 74,
                  },
                  {
                      date: "2024-12-03",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 40,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -2,
                      Delta_value: 2039.8,
                      Supply_Plan: 38,
                  },
                  {
                      date: "2024-12-04",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 80,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: 30,
                      Delta_value: 0,
                      Supply_Plan: 80,
                  },
                  {
                      date: "2024-12-05",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 10,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: 33,
                      Delta_value: 0,
                      Supply_Plan: 10,
                  },
                  {
                      date: "2024-12-06",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 10,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: 15,
                      Delta_value: 0,
                      Supply_Plan: 10,
                  },
                  {
                      date: "2024-12-07",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 20,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -5,
                      Delta_value: 0,
                      Supply_Plan: 20,
                  }
              ]
    ,            
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d5kkb58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Supply Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Manufacturing Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Logistics",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf49bafe-b462-4809-93fd-2f5d5f1149611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      }
    ]
    
  },

  /** Risk 6 Expedite deployment */
  {
    id: 5,
    risk_conf_name: "Demand Fulfillment Risk",
    due_date: "2024-11-24 00:00:00",
    copilot: "Procurement	",
    model: "Demand Fulfillment Risk",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge455645", name: "Action Error" },
      { id: "fgfergger545545645", name: "Execution Error" },
    ],
    status: "WAIT_FOR_ACTION",
    context: {
      Supplier: "3",
      Location_code: "Riyadh",
      Item_Code: "SKU-792",
      Date_of_risk: "2024-11-28",
      Quantity: "10",
      Revenue_At_Risk: "10,199€",
    },
    impact: "10,199€",

    current_action: {
      action_conf: null,
      action_conf_name: "Expedite deployment",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Location_code: "Riyadh",
                Item_Code: "SKU-792",
                Date_of_risk: "2024-11-28",
                Quantity: "10",
                Revenue_At_Risk: "10,199€",
              },

              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Item_Code: "SKU-792",
                Date_of_risk: "2024-11-28",
                Quantity: "10",
                Impact_on_Freight_CO2_emissions: "150 kg",
                Impact_on_Freight_Cost: "1,000€",
                Mode_of_Transport: "Air",
                Supply_Date: "2024-11-14",
                Supply_Order: "1006649900",
                Source: "Breda",
                Destination: " Riyadh",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },

              OTHER_OPTIONS: [],
              Demand_Supply_Matching: [ 
                {
                    date: "2024-11-28",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 80,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -1,
                    Delta_value: 0,
                    Supply_Plan: 80,
                },
                {
                    date: "2024-11-29",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 70,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 10199,
                    Supply_Plan: 60,
                },
                {
                    date: "2024-11-30",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 20,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 10199,
                    Supply_Plan: 10,
                },
                {
                    date: "2024-12-01",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 100,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 20398,
                    Supply_Plan: 80,
                },
                {
                    date: "2024-12-02",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 80,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -6,
                    Delta_value: 6119.4,
                    Supply_Plan: 74,
                },
                {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 40,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -2,
                    Delta_value: 2039.8,
                    Supply_Plan: 38,
                },
                {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 80,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 30,
                    Delta_value: 0,
                    Supply_Plan: 80,
                },
                {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 10,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 33,
                    Delta_value: 0,
                    Supply_Plan: 10,
                },
                {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 10,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 15,
                    Delta_value: 0,
                    Supply_Plan: 10,
                },
                {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 20,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -5,
                    Delta_value: 0,
                    Supply_Plan: 20,
                }
            ]
,            
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c7d5kkb58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Supply Planning",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Manufacturing Planning",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf339bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Logistics",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf49bafe-b462-4809-93fd-2f5d5f1149611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    actions:[

      {
        action_conf: null,
        action_conf_name: "Reallocate demand to markets",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Location_code: "Riyadh",
                  Item_Code: "SKU-346",
                  Date_of_risk: "2024-11-29",
                  Quantity_in_Excess: "480",
                  Other_stock_Value: "26,400€",
                },
                OTHER_OPTIONS: [],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_In_Excess: "480",
                  Revenue_loss_risk: "6,800€",
                  Delivery_Lead_Time: "3 Days",
                  Impact_on_Freight_CO2_emissions: "5.4 KG",
                  Source_Location: "Ryadh",
                  Destination_Location: "Dubai",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Demand_Supply_Matching: [
                  {
                    date: "2024-11-31",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 560,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 0,
                    Supply_Plan: 560,
                  },
                  {
                    date: "2024-12-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 480,
                    Delta_value: 26400,
                    Supply_Plan: 960,
                  },
                  {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -140,
                    Delta_value: 7700,
                    Supply_Plan: 460,
                  },
                  {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 380,
                  },
                  {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 500,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 1100,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 10,
                    Delta_value: 0,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -40,
                    Delta_value: 2200,
                    Supply_Plan: 360,
                  },
                  {
                    date: "2024-12-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 64,
                    Delta_value: 0,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 71,
                    Delta_value: 0,
                    Supply_Plan: 600,
                  },
              ]
              ,
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "436c7d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Market Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bddddafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
       
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Increase Supply",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Location_code: "Dubai",
                  Item_Code: "SKU-995",
                  Date_of_risk: "2024-11-30",
                  Quantity: "20",
                  Revenue_At_Risk: "1,100€",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-995",
                  Date_of_risk: "2024-11-30",
                  Quantity: "20",
                  Impact_on_Freight_CO2_emissions: "5.4 KG",
                  Impact_on_Freight_Cost: "950€",
                  Supply_Date: "2024-11-10",
                  Supply_Order: "100304000",
                  Source: "Breda",
                  Destination: " Dubai",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Demand_Supply_Matching: [
                  {
                      date: "2023-11-01",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 200,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -36,
                      Delta_value: 0,
                      Supply_Plan: 200,
                  },
                  {
                      date: "2023-11-02",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 280,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -50,
                      Delta_value: 0,
                      Supply_Plan: 280,
                  },
                  {
                      date: "2023-11-03",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 300,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 54,
                      Delta_value: 0,
                      Supply_Plan: 300,
                  },
                  {
                      date: "2023-11-04",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 180,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -20,
                      Delta_value: 645.2,
                      Supply_Plan: 160,
                  },
                  {
                      date: "2023-11-05",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 240,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -20,
                      Delta_value: 645.2,
                      Supply_Plan: 220,
                  },
                  {
                      date: "2023-11-06",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 240,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -10,
                      Delta_value: 322.6,
                      Supply_Plan: 230,
                  },
                  {
                      date: "2023-11-07",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0002",
                      Item_code: "SKU-995",
                      Quantity_in_units: 300,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -5,
                      Delta_value: 0,
                      Supply_Plan: 300,
                  }
              ]
              ,
              OTHER_OPTIONS: [
                {
                  Action_Name: "Reallocate demand to markets",
                  Reason_Code: "capacity limitation",
                  Execution_Stage: "Demand Planner",
                  User: "Exobrain",
                },
              ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c367d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Supply Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ccebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
      
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite partial Deployment",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Item_Code: "SKU-213",
                  Date_of_risk: "2024-11-30",
                  Quantity: "25",
                  Impact_on_Freight_CO2_emissions: "230 kg",
                  Impact_on_Freight_Cost: "2,000€",
                  Mode_of_Transport: "Air",
                  Supply_Date: "2024-11-15",
                  Supply_Order: "1004459000",
                  Source: "Breda",
                  Destination: " Dubai",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 9127,
                  Revenue_at_risk: 229909.13,
                  Penalty_risk: "9,886€",
                  Express_delivery_cost: "6,758€",
                  Express_delivery_value_ratio: 0.029,
                  Delivery_CO2_emission_impact: 140.56,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Reallocate demand to markets",
                    Reason_Code: "freight budget",
                    Execution_Stage: "Demand Planner",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Increase Supply",
                    Reason_Code: "capacity limitation",
                    Execution_Stage: "Supply Planning",
                    User: "Exobrain",
                  },
                ],
                Demand_Supply_Matching: [
                  {
                      date: "2024-11-01",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 880,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -49,
                      Delta_value: 0,
                      Supply_Plan: 880,
                  },
                  {
                      date: "2024-11-02",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1200,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -40,
                      Delta_value: 12904,
                      Supply_Plan: 800,
                  },
                  {
                      date: "2024-11-03",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1100,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -4,
                      Delta_value: 0,
                      Supply_Plan: 1100,
                  },
                  {
                      date: "2024-11-04",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1280,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -160,
                      Delta_value: 11613.6,
                      Supply_Plan: 920,
                  },
                  {
                      date: "2024-11-05",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 980,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -120,
                      Delta_value: 3871,
                      Supply_Plan: 860,
                  },
                  {
                      date: "2024-11-06",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1000,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 50,
                      Delta_value: 3226,
                      Supply_Plan: 900,
                  },
                  {
                      date: "2024-11-07",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 980,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 5,
                      Delta_value: 0,
                      Supply_Plan: 980,
                  },
                  {
                      date: "2024-11-08",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1000,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -40,
                      Delta_value: 0,
                      Supply_Plan: 1000,
                  },
                  {
                      date: "2024-11-09",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 600,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: -19,
                      Delta_value: 0,
                      Supply_Plan: 600,
                  },
                  {
                      date: "2024-11-10",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0003",
                      Item_code: "SKU-213",
                      Quantity_in_units: 1000,
                      Planned_Shipping_Location: 101,
                      Delta_Quantity: 22,
                      Delta_value: 0,
                      Supply_Plan: 1000,
                  }
              ]
              ,
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c447d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Supply Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebfpp9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Manufacturing Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebfoo9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Logistics",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebssf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite deployment",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Location_code: "Riyadh",
                  Item_Code: "SKU-792",
                  Date_of_risk: "2024-11-28",
                  Quantity: "10",
                  Revenue_At_Risk: "10,199€",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-792",
                  Date_of_risk: "2024-11-28",
                  Quantity: "10",
                  Impact_on_Freight_CO2_emissions: "150 kg",
                  Impact_on_Freight_Cost: "1,000€",
                  Mode_of_Transport: "Air",
                  Supply_Date: "2024-11-14",
                  Supply_Order: "1006649900",
                  Source: "Breda",
                  Destination: " Riyadh",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                OTHER_OPTIONS: [],
                Demand_Supply_Matching: [ 
                  {
                      date: "2024-11-28",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 80,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -1,
                      Delta_value: 0,
                      Supply_Plan: 80,
                  },
                  {
                      date: "2024-11-29",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 70,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -10,
                      Delta_value: 10199,
                      Supply_Plan: 60,
                  },
                  {
                      date: "2024-11-30",
                      Forecast_Status: "Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 20,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -10,
                      Delta_value: 10199,
                      Supply_Plan: 10,
                  },
                  {
                      date: "2024-12-01",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 100,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -20,
                      Delta_value: 20398,
                      Supply_Plan: 80,
                  },
                  {
                      date: "2024-12-02",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 80,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -6,
                      Delta_value: 6119.4,
                      Supply_Plan: 74,
                  },
                  {
                      date: "2024-12-03",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 40,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -2,
                      Delta_value: 2039.8,
                      Supply_Plan: 38,
                  },
                  {
                      date: "2024-12-04",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 80,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: 30,
                      Delta_value: 0,
                      Supply_Plan: 80,
                  },
                  {
                      date: "2024-12-05",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 10,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: 33,
                      Delta_value: 0,
                      Supply_Plan: 10,
                  },
                  {
                      date: "2024-12-06",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 10,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: 15,
                      Delta_value: 0,
                      Supply_Plan: 10,
                  },
                  {
                      date: "2024-12-07",
                      Forecast_Status: "Non-Firmed",
                      Market_Location: "C0001",
                      Item_code: "SKU-792",
                      Quantity_in_units: 20,
                      Planned_Shipping_Location: 100,
                      Delta_Quantity: -5,
                      Delta_value: 0,
                      Supply_Plan: 20,
                  }
              ]
    ,            
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d5kkb58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Supply Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Manufacturing Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Logistics",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf49bafe-b462-4809-93fd-2f5d5f1149611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      }
    ]
    
  },
];

export const Demand_Matching_Start_Risk = 
{
  id: 14,
  risk_conf_name: "Demand Fulfillment Risk",
  due_date: "2024-11-26 00:00:00",
  copilot: "Procurement	",
  model: "Fulfilment Shortage",
  reason: [
    { id: "fgfergger545645", name: "Missing Data" },
    { id: "fgfergge25255645", name: "Action Error" },
    { id: "fgfergger25255645", name: "Execution Error" },
  ],
  status: "WAIT_FOR_ACTION",
  context: {
    Supplier: "5",
    Location_code: "Dubai",
    Item_Code: "SKU-995",
    Date_of_risk: "2024-11-30",
    Quantity: "20",
    Revenue_At_Risk: "1,100€",
  },
  impact: "1,100€",
  current_action: {
    action_conf: null,
    action_conf_name: "Increase Supply",
    action_conf_policy: {},
    action_conf_priority: 1,
    action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
    action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
    contexts: [
      {
        computed: false,
        created_at: "2024-08-14T17:25:07.443005+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        data: {
          DATA: {
            ACTION_DETAILS: {
              Location_code: "Dubai",
              Item_Code: "SKU-995",
              Date_of_risk: "2024-11-30",
              Quantity: "20",
              Revenue_At_Risk: "1,100€",
            },

            ERRORS: {
              ERROR: [
                {
                  CODE: "MISSING_DATA",
                  DETAILS: {
                    DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                  },
                  MODULE: "Exobrain-actions",
                },
                {
                  CODE: "MISSING_DATA",
                  DETAILS: {
                    DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                  },
                  MODULE: "Exobrain-actions",
                },
              ],
              WARNING: [
                {
                  CODE: "UNPROCESSABLE_DATA",
                  DETAILS: {
                    DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                  },
                  MODULE: "Exobrain-actions",
                },
                {
                  CODE: "UNPROCESSABLE_DATA",
                  DETAILS: {
                    DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                  },
                  MODULE: "Exobrain-actions",
                },
              ],
            },
            IMPACT: {
              Item_Code: "SKU-995",
              Date_of_risk: "2024-11-30",
              Quantity: "20",
              Impact_on_Freight_CO2_emissions: "5.4 KG",
              Impact_on_Freight_Cost: "950€",
              Supply_Date: "2024-11-10",
              Supply_Order: "100304000",
              Source: "Breda",
              Destination: " Dubai",
            },
            MASTER: {
              ITEM_MASTER: [
                {
                  ITEM_CODE: "VGPSLWNL003",
                  ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                  ITEM_TYPE: "F",
                  UNIT_PER_PALLET: null,
                  WEIGHT_PER_UNIT_IN_KG: null,
                },
              ],
              LOCATION_ITEM_MASTER: [
                {
                  DAYS_OF_SUPPLY_THRESHOLD: null,
                  ITEM_CODE: "VGPSLWNL003",
                  LOCATION_CODE: "264",
                  STANDARD_COST_PER_UNIT: null,
                },
              ],
              LOCATION_MASTER: [
                {
                  APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                  LOCATION_CITY: "JEDDAH",
                  LOCATION_CONTACT_NAME: null,
                  LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                  LOCATION_DESCRIPTION: "JEDDAH PORT",
                  LOCATION_ID: "JEDDAH PORT",
                  LOCATION_PRIMARY_LANGUAGE: null,
                  LOCATION_TYPE: null,
                  LOCATION_ZIP_CODE: null,
                },
                {
                  APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                  LOCATION_CITY: "   RIYADH",
                  LOCATION_CONTACT_NAME: null,
                  LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                  LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                  LOCATION_ID: "264",
                  LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                  LOCATION_TYPE: "BRANCH",
                  LOCATION_ZIP_CODE: null,
                },
              ],
            },
            Demand_Supply_Matching: [
              {
                  date: "2023-11-01",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0002",
                  Item_code: "SKU-995",
                  Quantity_in_units: 200,
                  Planned_Shipping_Location: 101,
                  Delta_Quantity: -36,
                  Delta_value: 0,
                  Supply_Plan: 200,
              },
              {
                  date: "2023-11-02",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0002",
                  Item_code: "SKU-995",
                  Quantity_in_units: 280,
                  Planned_Shipping_Location: 101,
                  Delta_Quantity: -50,
                  Delta_value: 0,
                  Supply_Plan: 280,
              },
              {
                  date: "2023-11-03",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0002",
                  Item_code: "SKU-995",
                  Quantity_in_units: 300,
                  Planned_Shipping_Location: 101,
                  Delta_Quantity: 54,
                  Delta_value: 0,
                  Supply_Plan: 300,
              },
              {
                  date: "2023-11-04",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0002",
                  Item_code: "SKU-995",
                  Quantity_in_units: 180,
                  Planned_Shipping_Location: 101,
                  Delta_Quantity: -20,
                  Delta_value: 645.2,
                  Supply_Plan: 160,
              },
              {
                  date: "2023-11-05",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0002",
                  Item_code: "SKU-995",
                  Quantity_in_units: 240,
                  Planned_Shipping_Location: 101,
                  Delta_Quantity: -20,
                  Delta_value: 645.2,
                  Supply_Plan: 220,
              },
              {
                  date: "2023-11-06",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0002",
                  Item_code: "SKU-995",
                  Quantity_in_units: 240,
                  Planned_Shipping_Location: 101,
                  Delta_Quantity: -10,
                  Delta_value: 322.6,
                  Supply_Plan: 230,
              },
              {
                  date: "2023-11-07",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0002",
                  Item_code: "SKU-995",
                  Quantity_in_units: 300,
                  Planned_Shipping_Location: 101,
                  Delta_Quantity: -5,
                  Delta_value: 0,
                  Supply_Plan: 300,
              }
          ]
          ,
            OTHER_OPTIONS: [],
          },
        },
        decision_time: "2024-06-11T12:57:28.367528+00:00",
        id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
        inputs_data: null,
        is_deleted: false,
        note: "",
        outputs_data: null,
        reason: null,
        reason_comment: "",
        role: "",

        updated_at: "2024-09-03T12:57:28.367542+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
        user: {
          id: "602d5335-2be1-485f-a694-313d9428fafa",
        },
      },
    ],
    created_at: "2024-08-14T17:25:07.456366+00:00",
    created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
    current_execution: {
      action: {
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
      },
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.384630+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {},
          decision_time: null,
          id: "69fd7354-d60b-41bc-aa96-56515831adb3",
          inputs_data: {},
          is_deleted: false,
          note: "",
          outputs_data: {},
          reason: null,
          reason_comment: "",
          role: "",
          updated_at: "2024-08-14T17:25:07.384634+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          user: {
            id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.393646+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      execution_conf: null,
      execution_conf_name: "Truck Booking",
      execution_conf_policy: {
        automation: [],
      },
      execution_conf_priority: 1,
      execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
      execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
      id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
      is_deleted: false,
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      status: "OPEN",
      updated_at: "2024-08-14T17:25:07.393649+00:00",
      updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
    },
    executions: [
      {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.413239+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.413244+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.422537+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Demand Planner",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
        execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
        id: "4c367d5b58-95c3-41ab-86e9-df259b36e9ca",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
          },
          {
            id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.422541+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Supply Planning",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 2,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ccebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
    ],
    feasible: true,
    id: "ca0iht745136-804b-4718-8ac1-ca1705a5c8e9",
    is_deleted: false,
    risk: {
      id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
    },
  
    // "status": "WAIT_FOR_EXECUTION",
    status: "OPEN",
    updated_at: "2024-09-03T12:57:28.379118+00:00",
    updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
  },
  actions:[

    {
      action_conf: null,
      action_conf_name: "Reallocate demand to markets",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Location_code: "Riyadh",
                Item_Code: "SKU-346",
                Date_of_risk: "2024-11-29",
                Quantity_in_Excess: "480",
                Other_stock_Value: "26,400€",
              },
              OTHER_OPTIONS: [],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Quantity_In_Excess: "480",
                Revenue_loss_risk: "6,800€",
                Delivery_Lead_Time: "3 Days",
                Impact_on_Freight_CO2_emissions: "5.4 KG",
                Source_Location: "Ryadh",
                Destination_Location: "Dubai",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
  
              Demand_Supply_Matching: [
                {
                  date: "2024-11-31",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-01",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 560,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -10,
                  Delta_value: 0,
                  Supply_Plan: 560,
                },
                {
                  date: "2024-12-02",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 480,
                  Delta_value: 26400,
                  Supply_Plan: 960,
                },
                {
                  date: "2024-12-03",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -140,
                  Delta_value: 7700,
                  Supply_Plan: 460,
                },
                {
                  date: "2024-12-04",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 380,
                },
                {
                  date: "2024-12-05",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 500,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -20,
                  Delta_value: 1100,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-06",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 10,
                  Delta_value: 0,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-07",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -40,
                  Delta_value: 2200,
                  Supply_Plan: 360,
                },
                {
                  date: "2024-12-08",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 300,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 64,
                  Delta_value: 0,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-09",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 71,
                  Delta_value: 0,
                  Supply_Plan: 600,
                },
            ]
            ,
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "436c7d5b58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Market Validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bddddafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
     
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    {
      action_conf: null,
      action_conf_name: "Increase Supply",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Location_code: "Dubai",
                Item_Code: "SKU-995",
                Date_of_risk: "2024-11-30",
                Quantity: "20",
                Revenue_At_Risk: "1,100€",
              },
  
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Item_Code: "SKU-995",
                Date_of_risk: "2024-11-30",
                Quantity: "20",
                Impact_on_Freight_CO2_emissions: "5.4 KG",
                Impact_on_Freight_Cost: "950€",
                Supply_Date: "2024-11-10",
                Supply_Order: "100304000",
                Source: "Breda",
                Destination: " Dubai",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              Demand_Supply_Matching: [
                {
                    date: "2023-11-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 200,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -36,
                    Delta_value: 0,
                    Supply_Plan: 200,
                },
                {
                    date: "2023-11-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 280,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -50,
                    Delta_value: 0,
                    Supply_Plan: 280,
                },
                {
                    date: "2023-11-03",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: 54,
                    Delta_value: 0,
                    Supply_Plan: 300,
                },
                {
                    date: "2023-11-04",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 180,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -20,
                    Delta_value: 645.2,
                    Supply_Plan: 160,
                },
                {
                    date: "2023-11-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 240,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -20,
                    Delta_value: 645.2,
                    Supply_Plan: 220,
                },
                {
                    date: "2023-11-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 240,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -10,
                    Delta_value: 322.6,
                    Supply_Plan: 230,
                },
                {
                    date: "2023-11-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0002",
                    Item_code: "SKU-995",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -5,
                    Delta_value: 0,
                    Supply_Plan: 300,
                }
            ]
            ,
            OTHER_OPTIONS: [
              {
                Action_Name: "Reallocate demand to markets",
                Reason_Code: "capacity limitation",
                Execution_Stage: "Demand Planner",
                User: "Exobrain",
              },
            ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c367d5b58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Supply Planning",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ccebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
    
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    {
      action_conf: null,
      action_conf_name: "Expedite partial Deployment",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Item_Code: "SKU-213",
                Date_of_risk: "2024-11-30",
                Quantity: "25",
                Impact_on_Freight_CO2_emissions: "230 kg",
                Impact_on_Freight_Cost: "2,000€",
                Mode_of_Transport: "Air",
                Supply_Date: "2024-11-15",
                Supply_Order: "1004459000",
                Source: "Breda",
                Destination: " Dubai",
              },
  
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Quantity_at_risk: 9127,
                Revenue_at_risk: 229909.13,
                Penalty_risk: "9,886€",
                Express_delivery_cost: "6,758€",
                Express_delivery_value_ratio: 0.029,
                Delivery_CO2_emission_impact: 140.56,
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
  
              OTHER_OPTIONS: [
                {
                  Action_Name: "Reallocate demand to markets",
                  Reason_Code: "freight budget",
                  Execution_Stage: "Demand Planner",
                  User: "Exobrain",
                },
                {
                  Action_Name: "Increase Supply",
                  Reason_Code: "capacity limitation",
                  Execution_Stage: "Supply Planning",
                  User: "Exobrain",
                },
              ],
              Demand_Supply_Matching: [
                {
                    date: "2024-11-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 880,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -49,
                    Delta_value: 0,
                    Supply_Plan: 880,
                },
                {
                    date: "2024-11-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 1200,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -40,
                    Delta_value: 12904,
                    Supply_Plan: 800,
                },
                {
                    date: "2024-11-03",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 1100,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -4,
                    Delta_value: 0,
                    Supply_Plan: 1100,
                },
                {
                    date: "2024-11-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 1280,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -160,
                    Delta_value: 11613.6,
                    Supply_Plan: 920,
                },
                {
                    date: "2024-11-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 980,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -120,
                    Delta_value: 3871,
                    Supply_Plan: 860,
                },
                {
                    date: "2024-11-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 1000,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: 50,
                    Delta_value: 3226,
                    Supply_Plan: 900,
                },
                {
                    date: "2024-11-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 980,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: 5,
                    Delta_value: 0,
                    Supply_Plan: 980,
                },
                {
                    date: "2024-11-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 1000,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -40,
                    Delta_value: 0,
                    Supply_Plan: 1000,
                },
                {
                    date: "2024-11-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: -19,
                    Delta_value: 0,
                    Supply_Plan: 600,
                },
                {
                    date: "2024-11-10",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0003",
                    Item_code: "SKU-213",
                    Quantity_in_units: 1000,
                    Planned_Shipping_Location: 101,
                    Delta_Quantity: 22,
                    Delta_value: 0,
                    Supply_Plan: 1000,
                }
            ]
            ,
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c447d5b58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Supply Planning",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebfpp9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Manufacturing Planning",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebfoo9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Logistics",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebssf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    {
      action_conf: null,
      action_conf_name: "Expedite deployment",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Location_code: "Riyadh",
                Item_Code: "SKU-792",
                Date_of_risk: "2024-11-28",
                Quantity: "10",
                Revenue_At_Risk: "10,199€",
              },
  
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Item_Code: "SKU-792",
                Date_of_risk: "2024-11-28",
                Quantity: "10",
                Impact_on_Freight_CO2_emissions: "150 kg",
                Impact_on_Freight_Cost: "1,000€",
                Mode_of_Transport: "Air",
                Supply_Date: "2024-11-14",
                Supply_Order: "1006649900",
                Source: "Breda",
                Destination: " Riyadh",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
  
              OTHER_OPTIONS: [],
              Demand_Supply_Matching: [ 
                {
                    date: "2024-11-28",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 80,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -1,
                    Delta_value: 0,
                    Supply_Plan: 80,
                },
                {
                    date: "2024-11-29",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 70,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 10199,
                    Supply_Plan: 60,
                },
                {
                    date: "2024-11-30",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 20,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 10199,
                    Supply_Plan: 10,
                },
                {
                    date: "2024-12-01",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 100,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 20398,
                    Supply_Plan: 80,
                },
                {
                    date: "2024-12-02",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 80,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -6,
                    Delta_value: 6119.4,
                    Supply_Plan: 74,
                },
                {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 40,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -2,
                    Delta_value: 2039.8,
                    Supply_Plan: 38,
                },
                {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 80,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 30,
                    Delta_value: 0,
                    Supply_Plan: 80,
                },
                {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 10,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 33,
                    Delta_value: 0,
                    Supply_Plan: 10,
                },
                {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 10,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 15,
                    Delta_value: 0,
                    Supply_Plan: 10,
                },
                {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0001",
                    Item_code: "SKU-792",
                    Quantity_in_units: 20,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -5,
                    Delta_value: 0,
                    Supply_Plan: 20,
                }
            ]
  ,            
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c7d5kkb58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Supply Planning",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Manufacturing Planning",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf339bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Logistics",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf49bafe-b462-4809-93fd-2f5d5f1149611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    }
  ]
}

export const Demand_Matching_reasons = [
  { id: "customer request", name: "customer request" },
  { id: "supply constraint", name: "supply constraint" },
  {
    id: "production capacity constraint",
    name: "production capacity constraint",
  },
  { id: "freight budget", name: "freight budget" },
  { id: "capacity limitation", name: "capacity limitation" },
];






