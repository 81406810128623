import React from "react";
/* antd */
import { Image, Layout, theme } from "antd";
/* react-icons */
/* assets */
import styles from "../../../assets/style/css/MainLayout.module.css";
import UserProfile from "../dropdown/UserProfile";
import { PiBell } from "react-icons/pi";
import { ExoBrainIcon } from "../../../assets/images";
import { Link } from "react-router-dom";

const { Header } = Layout;

const HeaderLayout: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Header
      className={styles.navbar}
      style={{
        backgroundColor: colorBgContainer,
      }}
    >
      {/* Logo */}
      <h2
        className={styles.logo}
        style={{
          width: "80px",
          backgroundColor: "#0056A6",
          height: "100%",
          display: "flex",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <Link to={"/menu"}>
          <span
            style={{
              width: "48px",
              height: "48px",
              backgroundColor: "#fff",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              pointerEvents: "none",
              backgroundImage: `url(${ExoBrainIcon})`,
              backgroundSize: "98%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
            onClick={() => {
              console.log("hii");
            }}
          ></span>
        </Link>
      </h2>

      {/* Navbar right elements */}
      <div style={{ height: "70px" }}>
        {/* Avatar */}
        <div
          className={styles.avatar}
          style={{
            direction: "ltr",
            paddingInline: "20px",
            height: "44px",
            width: "44px",
            background: "linear-gradient(to bottom right, #FF0019, #FFA723)",
            borderRadius: "8px",
          }}
        >
          <span className={styles.NotifsIcon}>
            <PiBell
              size={24}
              style={{
                float: "left",
                marginInlineStart: "-10px",
              }}
            />
          </span>
        </div>

        {/* Avatar */}
        <div
          style={{
            direction: "ltr",
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
            height: "44px",
            marginInlineStart: "10px",
            paddingInlineEnd: "8px",
            marginInlineEnd: "24px",
          }}
        >
          <UserProfile />
        </div>
      </div>
    </Header>
  );
};

export default HeaderLayout;
