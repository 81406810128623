import { Column, ColumnConfig } from "@ant-design/plots";
import onChartClickEvent from "./functions/onChartClickEvent";
import { CHART_COLOR_TYPES } from "../chart-content/components/new-chart/NewChartModal";
import { ChartParams } from "./functions/ChartParams.interface";
import { getTranslation } from "../../../../../../utils/transaltion";
import { formatData } from "./functions/formatData";

interface MyColumnProps {
  config: any;
  fields: any;
  params: ChartParams;
  data: any[];
  showDrawer?: (v: any) => void;
}

const data = [
  {
    year: "1991",
    value: 3,
    type: "Lon",
  },
  {
    year: "1992",
    value: 4,
    type: "Lon",
  },
  {
    year: "1993",
    value: 3.5,
    type: "Lon",
  },
  {
    year: "1994",
    value: 5,
    type: "Lon",
  },
  {
    year: "1995",
    value: 4.9,
    type: "Lon",
  },
  {
    year: "1996",
    value: 6,
    type: "Lon",
  },
  {
    year: "1997",
    value: 7,
    type: "Lon",
  },
  {
    year: "1998",
    value: 9,
    type: "Lon",
  },
  {
    year: "1999",
    value: 13,
    type: "Lon",
  },
  {
    year: "1991",
    value: 3,
    type: "Bor",
  },
  {
    year: "1992",
    value: 4,
    type: "Bor",
  },
  {
    year: "1993",
    value: 3.5,
    type: "Bor",
  },
  {
    year: "1994",
    value: 5,
    type: "Bor",
  },
  {
    year: "1995",
    value: 4.9,
    type: "Bor",
  },
  {
    year: "1996",
    value: 6,
    type: "Bor",
  },
  {
    year: "1997",
    value: 7,
    type: "Bor",
  },
  {
    year: "1998",
    value: 9,
    type: "Bor",
  },
  {
    year: "1999",
    value: 13,
    type: "Bor",
  },
];

const MyColumn: React.FC<MyColumnProps> = ({
  config,
  fields,
  params,
  data,
  showDrawer,
}) => {
  const columnStyle = (datum: any, index: any) => {
    if (index === 1) {
      return { radius: [5, 5, 0, 0] };
    }
  };

  const my_config: ColumnConfig = {
    ...config,
    data: data,
    isStack: true,
    // isGroup: true,
    xField: "categoryField",
    yField: "valueField",
    seriesField: "seriesField",
    stack: {
      groupBy: ["x", "seriesField"],
      series: false,
    },
    meta: {
      valueField: {
        formatter: (v) => formatData(v, fields?.valueField, params).data,
      },
      groupField: {
        formatter: (v) => formatData(v, fields?.groupField, params).data,
      },
      categoryField: {
        formatter: (v) =>
          formatData(v, fields?.categoryField?.at(0), params).data,
      },
    },

    tooltip: {
      title(title, datum) {
        return (
          getTranslation(fields?.categoryField?.at(0)?.value, "data") +
          ":    " +
          formatData(datum?.categoryField, fields?.categoryField?.at(0), params)
            .data
        );
      },
      shared: true,
      showMarkers: false,
      formatter: ({ categoryField, valueField }: any) => ({
        name: getTranslation(fields?.valueField?.value, "data"),
        value: valueField
          ? `${
              formatData(valueField, fields?.valueField, {
                unit: config?.unit,
                ...params,
              }).data
            }`
          : "Not set",
      }),
    },

    legend: {
      position: "top",
      itemName: {
        formatter: (datum: any) => {
          return ` ${getTranslation(datum, "labels")}`;
        },
      },
    },
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
    style: { maxHeight: 250 },
    columnStyle: { columnStyle },
    ...(showDrawer
      ? {
          onEvent: (chart, event) =>
            onChartClickEvent(chart, event, showDrawer),
        }
      : {}),
    color:
      config?.pallet_type === CHART_COLOR_TYPES.PALLET
        ? config?.color_pallet?.map((color: any) => color?.color)
        : ({ categoryField: valueField }) =>
            config?.color_pallet?.find(
              (color: any) => color?.value === valueField
            )?.color,
  };
  return <Column {...my_config} />;
};

export default MyColumn;
