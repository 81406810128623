import { ProTable } from "@ant-design/pro-components";
import { DefineTableColumns } from "./columns";
import { t } from "i18next";

const FilterHeader = (props: any) => {
  const { setFilterOptions, dashboard, copilot } = props;
  const columns = DefineTableColumns({ dashboard, copilot });
  return (
    <ProTable<any>
      columns={columns}
      cardBordered
      style={{ width: "100%" }}
      columnsState={{
        persistenceKey: "pro-table-singe-demos",
        persistenceType: "localStorage",
      }}
      rowKey="id"
      search={{
        span: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6, xxl: 6 },
        labelWidth: "auto",
        searchText: t("SEARCH_TEXT_BUTTON"),
        resetText: t("RESET_TEXT_BUTTON"),
        className: "custom-search-form",
      }}
      onReset={() => {}}
      request={(params): any => {
        setFilterOptions(params);
      }}
      tableRender={() => null}
      headerTitle={null}
      toolBarRender={false}
      dateFormatter="string"
    />
  );
};

export default FilterHeader;
