export const mapSupplyCostMitigated = (data: any) => {
  const mappedData = data
    ?.filter(
      (risk: any) =>
        risk.risk_status === "Accepted" || risk.risk_status === "Rejected"
    )
    ?.map((obj: any) => {
      return {
        action_type: obj.action_type,
        supply_cost_mitigated: obj.supply_cost_mitigated,
      };
    })
    ?.reduce((accumulator: any, item: any) => {
      const { action_type, supply_cost_mitigated } = item;
      const key = `${action_type}`;

      if (!accumulator[key]) {
        accumulator[key] = { action_type, value: 0 };
      }

      accumulator[key].value += parseFloat(supply_cost_mitigated) || 0;

      return accumulator;
    }, {});
  return Object.values(mappedData);
};
