/** @format */
import { PlusOutlined } from "@ant-design/icons";
import type { ActionType } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { Button, ConfigProvider, Spin, TableProps } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { SharedContext } from "../../context/sharedContext";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/riskLogic";
import { MainContext } from "../../../../../utils/context";
import { RisksTableDef } from "../columnsDef/RisksTable.def";
import { RisksItem } from "../../../../../interfaces/risks";
import { WORK_MODE } from "../../../../../constants/workModes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";
import { RootState } from "../../../../../redux/store/store";
import { useSelector } from "react-redux";

const RisksComponent: React.FC = () => {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /**global appcontext */
  const globalContext = useContext(MainContext);
  /** translation  */
  const { t } = useTranslation();
  /**confs shared context */
  const { riskList, totalRisk, getLoadingRisk, setWorkModeRisk } =
    useContext(SharedContext);
  /**local vars */
  const riskRef = useRef<ActionType>();
  const [valueEnumPredefinedRisk, setValueEnumPredefinedRisk] = useState<{
    [key: string]: { text: string };
  }>({});
  const [valueEnumCopilot, setValueEnumCopilot] = useState<{
    [key: string]: { text: string };
  }>({});
  const navigate = useNavigate();

  /** get risk logic */
  const { goToRiskConfiguration, getEnums, filterListeRisks } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  /** columns def */
  const columns = RisksTableDef(valueEnumPredefinedRisk, valueEnumCopilot);

  /**useEffect */
  useEffect(() => {
    if (!globalContext || !globalContext.context.orga) {
      return;
    }
    // filterListeRisks({ current: pageStateRisk, pageSize: 20 }, "desc");
    getEnums(setValueEnumPredefinedRisk, setValueEnumCopilot);
  }, [globalContext]);
  if (!globalContext) {
    return <></>;
  }

  return (
    <Spin spinning={getLoadingRisk} size="large" tip="Loading...">
      <ProTable<RisksItem>
        columns={columns}
        actionRef={riskRef}
        cardBordered
        dataSource={riskList}
        request={(params = {}, sort, filter): any => {
          const sortOrder = sort.name === "descend" ? "desc" : "asc";
          const { name, copilot, model, current, pageSize } = params;
          filterListeRisks(
            { current, pageSize, order: sortOrder },
            {
              name: { value: name, operator: "HAS" },
              copilot,
              model,
            }
          );

          return;
        }}
        editable={{
          type: "multiple",
        }}
        columnsState={{
          persistenceKey: "pro-table-singe-demos",
          persistenceType: "localStorage",
        }}
        rowKey="id"
        search={{
          labelWidth: 120,
          span: { xs: 24, sm: 12, md: 9, lg: 9, xl: 9, xxl: 8 },
          filterType: "query",
          searchText: t("SEARCH_TEXT_BUTTON"),
          resetText: t("RESET_TEXT_BUTTON"),
          className: "custom-search-form",
        }}
        toolBarRender={() => [
          isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) && (
            <Button
              type="primary"
              className="button-add-risk"
              icon={<PlusOutlined />}
              onClick={() => {
                setWorkModeRisk(WORK_MODE.CREATE);
                goToRiskConfiguration(
                  navigate,
                  "",
                  WORK_MODE.CREATE,
                  "/menu/configurations/risks"
                );
              }}
            >
              {t("ADD_BUTTON_TEXT")}
              {t("RISK")}
            </Button>
          ),
        ]}
        options={{
          density: true,
          setting: {
            draggable: true,
            showListItemOption: true,
            listsHeight: 400,
          },
        }}
        form={{
          syncToUrl: (values, type) => {
            if (type === "get") {
              return {
                ...values,
              };
            }
            return values;
          },
        }}
        pagination={{
          pageSize: 20,
          total: totalRisk,
          onChange(current, pageSize) {},
        }}
        dateFormatter="string"
        headerTitle={t("RISKS")}
      />
    </Spin>
  );
};
export default RisksComponent;
