export const riskStatusColors = (riskStatus: any): string => {
  switch (riskStatus) {
    case "Open":
      return "#5BC2E7";
    case "In progress":
      return "#2D8BBA";
    case "Accepted":
      return "#00BA77";
    case "Rejected":
      return "#EE2737";
    default:
      return "#DBE7F1";
  }
};

export const executionsColors = (riskStatus: any): string => {
  switch (riskStatus) {
    case "Freight":
      return "#5BC2E7";
    case "Planner validation":
      return "#41B8D5";
    case "Vendor validation":
      return "#004C97";
    case "Buyer validation":
      return "#0082BA";
    case "Customer validation":
      return "#257BCF";
    case "production validation":
      return "#0082BA";
    case "regional sales validation":
      return "#004C97";

    /* Inbound */
    case "Custom clearance confirmed":
      return "#5BC2E7";
    case "Truck booking":
      return "#004C97";
    case "Truck loading":
      return "#0082BA";
    case "Truck transfer":
      return "#257BCF";
    case "Custom clearance validation":
      return "#41B8D5";
    default:
      return "#DBE7F1";
  }
};

export const actionTypesColors = (riskStatus: any): string => {
  switch (riskStatus) {
    case "Alternate Supplier":
      return "#004C97";
    case "Substitution":
      return "#0082BA";
    case "Stock Rebalancing":
      return "#5BC2E7";
    case "Express Delivery":
      return "#FF671F";
    case "Partial Delivery":
      return "#EA7600";
    case "Expedite production":
      return "#004C97";
    case "Expedite deployment":
      return "#0082BA";
    case "Reallocate demand to markets":
      return "#5BC2E7";
    case "Delay production":
      return "#FF671F";
    case "Delay deployment":
      return "#EA7600";
    case "Alternate DC":
      return "#004C97";
    case "No action":
      return "#EE2737";

    /* Inbound Actions */
    case "Early custom clearance":
      return "#004C97";
    case "Expedited custom clearance":
      return "#0082BA";
    case "Deferred custom clearance":
      return "#5BC2E7";
    case "Expedited truck transfer":
      return "#FF671F";
    case "Rerouted truck transfer":
      return "#EA7600";
    default:
      return "#DBE7F1";
  }
};
