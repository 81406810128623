import { notification } from "antd";
import { AxiosError } from "axios";
import HttpStatus from "../../../constants/HttpStatus";
import { RESPONSE_CODE } from "./const";
import { getTranslation } from "../../../utils/transaltion";

interface ErrorResponse {
  message: string;
  CODE: string;
  DETAILS: {
    DATA: any;
    TEXT: string;
  };
  SERVICE: string;
  SEVERITY: string;
}

interface CustomError {
  code: number;
  description: string;
}

export const handelResponseError = (
  error: AxiosError,
  customs?: CustomError[]
) => {
  const m = error.message;
  if (!error.response) return;

  const responseData = error.response.data as ErrorResponse;
  const status = error.response.status;
  var message,
    description,
    details = "";

  //
  // handle 404/NOT_FOUND Error
  //
  if (status === HttpStatus.NOT_FOUND) {
    const custom_err = customs?.find((c) => c.code === HttpStatus.NOT_FOUND);
    message = `${error.response.status}`;
    description = custom_err?.description || "Entity not found!";
  } else if (status === HttpStatus.BAD_REQUEST) {
    //
    // handle 400/BAD_REQUEST Error
    //
    const custom_err = customs?.find((c) => c.code === HttpStatus.BAD_REQUEST);
    if (responseData?.CODE === RESPONSE_CODE.MISSING_DATA) {
      // *** MISSING DATA CASE
      message = `${RESPONSE_CODE.MISSING_DATA}`;
      description =
        custom_err?.description ||
        `${responseData?.DETAILS?.TEXT} "${responseData?.DETAILS?.DATA?.DATA}"`;
    } else if (responseData?.CODE === RESPONSE_CODE.INVALID_DATA) {
      // *** INVALID DATA CASE
      message = `${RESPONSE_CODE.INVALID_DATA}`;
      description =
        custom_err?.description ||
        `${responseData?.DETAILS?.TEXT} ${
          responseData?.DETAILS?.DATA?.FIELD || ""
        }` ||
        "Entity not found!";
    }
  } else if (status === HttpStatus.INTERNAL_SERVER_ERROR) {
    const custom_err = customs?.find(
      (c) => c.code === HttpStatus.INTERNAL_SERVER_ERROR
    );
    message = `${error.response.status}`;
    description = custom_err?.description || `${responseData?.DETAILS?.TEXT}`;
  }

  notification.error({
    message:
      `${getTranslation(
        responseData?.SEVERITY || "",
        "notifications"
      )} : ${getTranslation(message || "", "notifications")}` ||
      m ||
      `${error.response.status}`,
    description:
      getTranslation(description || "", "notifications") ||
      responseData?.message ||
      "An unexpected server error occurred." + details,
    placement: "bottomRight",
  });
};
