import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { Permission } from "../../interfaces/permissions";

export const permissionSlice = createSlice({
  name: "Permissions",
  initialState: [] as Permission[],
  reducers: {
    addPermission: (state, action: PayloadAction<Permission>) => {
      state.push(action.payload);
    },
    setPermissions: (state, action: PayloadAction<Array<Permission>>) => {
      state.splice(0, state.length, ...action.payload); // Replace existing state with new permissions
    },
    updatePermission: (state, action: PayloadAction<Permission>) => {
      const { id, name } = action.payload;
      const existingPermission = state.find(
        (permission) => permission.id === id
      );
      if (existingPermission) {
        existingPermission.name = name;
      }
    },
  },
});

export const { addPermission, setPermissions, updatePermission } =
  permissionSlice.actions;

export const PermissionSelector = (state: RootState) => state.permissionReducer;

export default permissionSlice.reducer;
