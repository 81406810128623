import { setRoles } from "../../reducers/roles.Reducer";

/** dispatch predefined risk */
export const InitRoles = async (rolesServices: any, dispatch: any) => {
  return rolesServices
    .getResources({ pageSize: 1000, sort: false })
    .then(async (res: any) => {
      dispatch(setRoles(res?.items));
    })
    .catch((err: any) => {
      console.log(err);
    });
};
