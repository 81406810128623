export const RESPONSE_CODE = {
  MISSING_DATA: "MISSING_DATA",
  INVALID_DATA: "INVALID_DATA",
};
export const REQUEST = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  GET: "GET",
};
