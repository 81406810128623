export const mapDaysOfSupplyImpact = (data: any) => {
  const mappedData = data
    ?.filter(
      (risk: any) =>
        risk.risk_status === "Accepted" || risk.risk_status === "Rejected"
    )
    .map((obj: any) => {
      return {
        action_type: obj.action_type,
        days_of_supply_impact: obj.days_of_supply_impact,
      };
    })
    ?.reduce((accumulator: any, item: any) => {
      const { action_type, days_of_supply_impact } = item;
      const key = `${action_type}`;

      if (!accumulator[key]) {
        accumulator[key] = {
          action_type,
          days_of_supply_impact,
          value: 0,
          count: 0,
          total: 0,
        };
      }

      accumulator[key].total += parseFloat(days_of_supply_impact);
      accumulator[key].count += 1;

      accumulator[key].value = accumulator[key].total / accumulator[key].count;

      return accumulator;
    }, {});
  return Object.values(mappedData);
};
