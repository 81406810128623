import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";

export default function BackLink(props: any) {
  const { link, title } = props;
  return (
    <span style={{ alignContent: "start", marginLeft: 0 }}>
      <Link style={{ fontSize: 16, color: "#0082BA" }} to={link}>
        <span
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          {title}
          <ArrowLeftOutlined
            style={{
              marginInlineStart: 0,
              marginInlineEnd: 8,
              color: "#0082BA",
              transform:
                getComputedStyle(document.body).direction === "rtl"
                  ? "scaleX(-1)"
                  : "none", // Flip arrow for RTL
            }}
          />
        </span>
      </Link>
    </span>
  );
}
