function max(a: number, b: number) {
  return a >= b ? a : b;
}

function min(a: number, b: number) {
  return a <= b ? a : b;
}

export const mapSupplyCostAtRiskByAction = (data: any) => {
  const mappedData = data
    ?.filter(
      (risk: any) =>
        risk.risk_status === "Accepted" || risk.risk_status === "Rejected"
    )
    ?.map((obj: any) => {
      return {
        action_type: obj.action_type,
        supply_cost_at_risk: obj.supply_cost_at_risk,
      };
    })
    ?.reduce((accumulator: any, item: any) => {
      const { action_type, supply_cost_at_risk } = item;
      const key = `${action_type}`;

      if (supply_cost_at_risk) {
        if (!accumulator[key]) {
          accumulator[key] = {
            action_type,
            value: 0,
          };
        }

        accumulator[key].value += parseFloat(supply_cost_at_risk);
      }
      return accumulator;
    }, {});
  return Object.values(mappedData);
};
