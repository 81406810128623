import Api from "./main/api";
import { SERVER } from "../constants/server";
import { Context } from "../interfaces/context";
const API_HOSTNAME = process.env.REACT_APP_API_HOSTNAME;
const API_URL = API_HOSTNAME;

export default class AuthService extends Api {
  constructor(context: Context, setContext: (c: Context) => void, path = "") {
    super(context, setContext, path);
  }
  async getUsers(orgId: any) {
    const { data } = await this.get(`${SERVER}/organizations/${orgId}/users/`);
    return data;
  }
  async filterHistoriques(args: any) {
    const { data } = await this.get(API_URL + "filter/", { params: args });
    return data;
  }

  async disconnectToken() {
    await this.get(`${SERVER}/auth/disconnect`)
      .then((res) => {
        if (this.setContext) {
          this.setContext({
            ...this.context,
            loggedIn: false,
            orga: null,
            user: null,
          });
        }
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async disconnectRefreshToken(refreshToken: string) {
    const { data } = await this.get(`${SERVER}/auth/refresh/disconnect`);
    return data;
  }
}
