import { useState } from "react";
import { Col, Form, Input, message, Row, Select, Tag } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { t } from "i18next";

import SaveCancel from "../../../../common/SaveCancel";
import { FilterConf, defaultFilterConf } from "../interfaces/FilterConf";
import { ActionFields } from "../constants/configs";
import { toLowerCase } from "../../../../../utils/functions";

interface EditFiltersModalProps {
  copilotContextFields: ActionFields;
  filterConfigs: FilterConf[];
  updateFilterConfigs: (value: any) => void;
  closeFilterConfModal: () => void;
}

const defaultsFilterConfigs: FilterConf[] = [
  { title: "", field: "ACTION_TYPE", type: "" },
  { title: "", field: "RISK_STATUS", type: "" },
  { title: "", field: "DUE_DATE", type: "" },
  { title: "", field: "EXECUTION_TYPE", type: "" },
];

export default function EditFiltersModal(props: EditFiltersModalProps) {
  const {
    copilotContextFields,
    filterConfigs,
    updateFilterConfigs,
    closeFilterConfModal,
  } = props;

  const [currentFilterConfigs, setCurrentFilterConfigs] = useState<
    FilterConf[]
  >(filterConfigs?.length > 0 ? filterConfigs : [defaultFilterConf]);

  const addFilterConfig = () => {
    setCurrentFilterConfigs([...currentFilterConfigs, defaultFilterConf]);
  };
  const deleteFilterConfig = (i: number) => {
    setCurrentFilterConfigs(
      currentFilterConfigs?.filter((conf, index: number) => i !== index)
    );
  };
  const onChangeFilterConfig = (n_conf: any, i: number) => {
    console.log(n_conf);
    setCurrentFilterConfigs(
      currentFilterConfigs?.map((conf, index: number) =>
        i !== index ? conf : { ...conf, ...n_conf }
      )
    );
  };
  const onFinish = () => {
    form_filters
      .validateFields()
      .then(() => {
        updateFilterConfigs(currentFilterConfigs);
      })
      .catch((err: any) => {
        message.warning(t("errors:REQUIRED_FIELDS"));
      });
  };

  const [form_filters] = Form.useForm();
  const initialValues: any = {};
  currentFilterConfigs.forEach((item, index) => {
    initialValues[`title${index}`] = item.title;
    initialValues[`field${index}`] = item.field;
  });
  return (
    <Form
      name="wrap"
      form={form_filters}
      labelAlign="left"
      labelWrap
      style={{ marginInlineStart: "20px" }}
      initialValues={initialValues}
    >
      <p style={{ color: "#b0b0b0", marginBlockEnd: "5px" }}>
        Filter configurations :
      </p>

      {currentFilterConfigs?.map((filterConfig: any, index: number) => {
        return (
          <Row key={"form" + index.toString()}>
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Form.Item
                label={"Field"}
                name={`field${index}`}
                rules={[{ required: true }]}
              >
                <Select
                  defaultValue={filterConfig?.field}
                  value={filterConfig?.field}
                  onChange={(val, option) => {
                    const filter_field = val.split(".");
                    const title = toLowerCase(
                      filter_field[filter_field.length - 1]
                    );
                    onChangeFilterConfig(
                      {
                        field: val,
                        type: copilotContextFields[val]?.TYPE,
                        title: title,
                      },
                      index
                    );
                    form_filters.setFieldsValue({
                      [`title${index}`]: title,
                    });
                  }}
                >
                  {Object.entries(copilotContextFields)?.map(
                    ([item, value]) => (
                      <Select.Option
                        key={item}
                        value={item}
                        dtype={value.TYPE}
                        disabled={[
                          ...currentFilterConfigs,
                          ...defaultsFilterConfigs,
                        ]?.find((CItem) => CItem?.field === item)}
                      >
                        <span>
                          {item} <Tag color="blue">{value.TYPE}</Tag>
                        </span>
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label={"Title"}
                name={`title${index}`}
                rules={[{ required: true }]}
              >
                <Input
                  defaultValue={filterConfig?.title}
                  value={filterConfig?.title}
                  onBlur={(e) => {
                    const v = e.target.value.toString();
                    onChangeFilterConfig({ title: v }, index);
                  }}
                ></Input>
              </Form.Item>
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1}>
              {index === currentFilterConfigs?.length - 1 &&
                currentFilterConfigs?.length <
                  Object.entries(copilotContextFields)?.length && (
                  <PlusCircleOutlined
                    style={{
                      fontSize: "20px",
                      color: "#1677ff",
                      margin: "5px",
                    }}
                    onClick={() =>
                      form_filters
                        .validateFields()
                        .then(() => {
                          addFilterConfig();
                        })
                        .catch((err) => {})
                    }
                  />
                )}
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1}>
              {currentFilterConfigs?.length > 1 && (
                <MinusCircleOutlined
                  style={{
                    fontSize: "20px",
                    color: "#ff671f",
                    margin: "5px",
                  }}
                  onClick={() => {
                    deleteFilterConfig(index);
                  }}
                />
              )}
            </Col>
          </Row>
        );
      })}
      <Row>
        <Form.Item>
          <SaveCancel
            confirmLoading={false}
            onClickSave={() => {
              onFinish();
            }}
            onClickCancel={() => {
              closeFilterConfModal();
            }}
          />
        </Form.Item>
      </Row>
    </Form>
  );
}
