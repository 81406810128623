import { Column, ColumnConfig } from "@ant-design/plots";

const QuantityAtRiskByAction = (props: any) => {
  let { data } = props;

  const config: ColumnConfig = {
    appendPadding: [0, 0, 20, 0],
    data,
    xField: "action_type",
    yField: "value",
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
        formatter: (action_type) => {
          const words = action_type.split(" ");
          words.splice(1, 0, "\n");
          words.splice(3, 0, "\n");
          return words.join(" ");
        },
      },
    },
    meta: {
      value: {
        formatter: (v) =>
          `${(v / (v > 99999 ? 1000000 : 1000)).toFixed(2)}${
            v > 99999 ? " m" : " K"
          }€`,
      },
    },
    isStack: true,
    legend: {
      position: "bottom",
    },
    color: "#EE2737",
    columnStyle: {
      radius: [5, 5, 0, 0],
    },
    columnWidthRatio: 0.8,
  };

  return <Column {...config} height={300} />;
};

export default QuantityAtRiskByAction;
