import { useContext, useEffect, useState } from "react";
import { ProTable } from "@ant-design/pro-components";
import { Modal } from "antd";

import { DefineTableColumns } from "./columns";
import EditFiltersModal from "./EditFiltersModal";
import { FilterConf } from "../interfaces/FilterConf";
import { ActionFields } from "../constants/configs";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../../../runnings/logic/runningShared.logic";
import { MainContext } from "../../../../../utils/context";
import { t } from "i18next";

interface FilterHeaderProps {
  copilotContextFields: ActionFields;
  filterConfigs: FilterConf[];
  setFilterParams: (value: any) => void;
  setFilterConfigs: (value: FilterConf[]) => void;
}

const FilterHeader = (props: FilterHeaderProps) => {
  const {
    copilotContextFields,
    filterConfigs,
    setFilterParams,
    setFilterConfigs,
  } = props;
  const globalContext = useContext(MainContext);

  const { getEnums } = useSharedContextFunctions() as SharedContextFunctions;
  /* enums actions for the filters */
  const [valueEnumPredefinedAction, setValueEnumPredefinedAction] = useState<{
    [key: string]: { text: string };
  }>({});
  /* enums executions for the filters */
  const [valueEnumPredefinedExecutions, setValueEnumPredefinedExecutions] =
    useState<{
      [key: string]: { text: string };
    }>({});

  useEffect(() => {
    if (!globalContext || !globalContext.context.orga) {
      return;
    }
    getEnums(setValueEnumPredefinedAction, setValueEnumPredefinedExecutions);
  }, [globalContext]);

  const [openFilterConfModal, setOpenFilterConfModal] = useState(false);

  /* FilterConfModal open modes */
  const closeFilterConfModal = () => {
    setOpenFilterConfModal(false);
  };
  const updateFilterConfigsModal = () => {
    setOpenFilterConfModal(true);
  };
  const updateFilterConfigs = (n_configs: FilterConf[]) => {
    setFilterConfigs(n_configs);
    closeFilterConfModal();
  };

  const [columns, setColumns] = useState(
    DefineTableColumns(
      updateFilterConfigsModal,
      filterConfigs,
      valueEnumPredefinedAction,
      valueEnumPredefinedExecutions
    )
  );
  useEffect(() => {
    console.log(filterConfigs);
    setColumns(
      DefineTableColumns(
        updateFilterConfigsModal,
        filterConfigs,
        valueEnumPredefinedAction,
        valueEnumPredefinedExecutions
      )
    );
  }, [filterConfigs, valueEnumPredefinedAction, valueEnumPredefinedExecutions]);

  return (
    <>
      <ProTable<any>
        columns={columns}
        cardBordered
        style={{ width: "100%" }}
        columnsState={{
          persistenceKey: "pro-table-singe-demos",
          persistenceType: "localStorage",
        }}
        rowKey="id"
        search={{
          filterType: "query",
          span: { xs: 24, sm: 12, md: 8, lg: 8, xl: 8, xxl: 8 },
          labelWidth: "auto",
          searchText: t("SEARCH_TEXT_BUTTON"),
          resetText: t("RESET_TEXT_BUTTON"),
          className: "custom-search-form",
        }}
        onReset={() => {}}
        request={(params): any => {
          setFilterParams(params);
        }}
        tableRender={() => null}
        headerTitle={null}
        toolBarRender={false}
        dateFormatter="string"
      />

      <Modal
        destroyOnClose={true}
        title={"Update filters"}
        open={openFilterConfModal}
        onCancel={closeFilterConfModal}
        width={800}
        footer={null}
      >
        <EditFiltersModal
          copilotContextFields={copilotContextFields}
          filterConfigs={filterConfigs}
          updateFilterConfigs={updateFilterConfigs}
          closeFilterConfModal={closeFilterConfModal}
        />
      </Modal>
    </>
  );
};

export default FilterHeader;
