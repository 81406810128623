import { Button, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

interface SaveCancelProps {
  confirmLoading: boolean;
  onClickSave: () => void;
  onClickCancel: () => void;
  className?: string;
}
function SaveCancel(props: SaveCancelProps) {
  const { t } = useTranslation();
  const { confirmLoading, onClickSave, onClickCancel, className } = props;
  return (
    <div className={className ? "className" : "button-group"}>
      <Space>
        <Button
          className="cancel-button"
          onClick={() => {
            onClickCancel();
          }}
        >
          {t("CANCEL")}
        </Button>
        <Button
          htmlType="submit"
          type="text"
          className="save-button"
          loading={confirmLoading}
          onClick={() => {
            onClickSave();
          }}
        >
          {t("SAVE")}
        </Button>
      </Space>
    </div>
  );
}
export default SaveCancel;
