import axios from "axios";
import L from "leaflet";
import { CATEGORY_VIEWS } from "../../../../constants/categoryviews";
import { getTranslation, TranslateErrors } from "../../../../utils/transaltion";
import {
  checkTypeAttribute,
  formatDataNeededFromURLToList,
  getDescriptorsForDataNeededFields,
  getFormatedValue,
  getLastJsonPathSegment,
} from "../../../../utils/datatypesFunctions";
import { logError } from "../../../../utils/logsFunctions";
import { VIEW_TYPES } from "../../../../constants/viewtypes";

const jsonpath = require("jsonpath");
const _ = require("lodash"); // Ensure lodash is required
interface StartNode {
  path?: { [key: string]: string };
  valueField?: string;
}

export async function MappingSideBarfunction(
  currentAction: any,
  currentPredefinedAction: any,
  currentActionConfiguration: any,
  orgaSettings: any,
  CurrentActionSettings: any,
  descriptorsListUnique: any
): Promise<any> {
  // construct a list of all dataNeeded attribute with the type and unit if there is one
  const DataNeededFormattedToList = formatDataNeededFromURLToList(
    currentPredefinedAction?.dataNeeded || []
  );
  const descriptorsList = getDescriptorsForDataNeededFields(
    DataNeededFormattedToList,
    CurrentActionSettings
  );

  const result: { [key: string]: any } = {};

  for (const viewKey in currentActionConfiguration?.category) {
    const viewConfig = currentActionConfiguration?.category[viewKey];
    if (viewKey === CATEGORY_VIEWS.OTHER_OPTIONS) {
      result[viewKey] = currentActionConfiguration?.category[viewKey];
    } else {
      result[viewKey] = await processCharts(
        currentActionConfiguration?.name, // predefinedActionName:
        currentAction?.action_conf_name, // curent action config name
        viewConfig?.views,
        currentAction?.contexts?.at(0)?.data,
        descriptorsList,
        orgaSettings,
        descriptorsListUnique
      );
    }
  }

  return result;
}
async function processCharts(
  predefinedActionName: string,
  currentActionConfigName: string,
  charts: any,
  contextData: any,
  descriptorsList: any,
  orgaSettings: any,
  descriptorsListUnique: any
) {
  const result: any[] = [];

  for (const chartKey in charts) {
    // Use for...of loop to properly handle async/await
    for (const chartConfig of charts[chartKey]) {
      switch (chartKey) {
        case "waterfall_chart":
          result.push({
            data: processColumnChart(
              predefinedActionName,
              currentActionConfigName,
              chartConfig,
              contextData,
              descriptorsList,
              orgaSettings
            ),
            type: "waterfall_chart",
            config: {
              color_pallet: chartConfig?.colorConfig,
              pallet_type: "Pallet",
              unit: descriptorsList[`${chartConfig?.yField}`]?.unit || "",
            },
            fields: {
              valueField: {
                value: chartConfig?.yField,
                type: descriptorsListUnique[`${chartConfig?.yField}`]?.type,
              },
              categoryField: [
                {
                  value: chartConfig?.xField,
                  type: descriptorsListUnique[`${chartConfig?.yField}`]?.type,
                },
              ],
            },
            title: chartConfig?.title,
          });
          break;

        case "bar_chart":
          result.push({
            data: processBarChart(
              predefinedActionName,
              currentActionConfigName,
              chartConfig,
              contextData,
              descriptorsList,
              orgaSettings
            ),
            type: "bar_chart",
            config: {
              dual: chartConfig?.dual,
              color_pallet: chartConfig?.colorConfig,
              pallet_type: "By value",
              unit: descriptorsListUnique[`${chartConfig?.yField}`]?.unit || "",
            },
            fields: {
              valueField: {
                value: chartConfig?.yField,
                type: descriptorsListUnique[`${chartConfig?.yField}`]?.type,
              },
              categoryField: [
                {
                  value: chartConfig?.xField,
                  type: descriptorsListUnique[`${chartConfig?.xField}`]?.type,
                },
              ],
            },
            title: chartConfig?.title,
          });
          break;

        case "column_chart":
          result.push({
            data: processColumnChart(
              predefinedActionName,
              currentActionConfigName,
              chartConfig,
              contextData,
              descriptorsList,
              orgaSettings
            ),
            type: "column_chart",
            config: {
              color_pallet: chartConfig?.colorConfig,
              pallet_type: "By value",
              unit: descriptorsListUnique[`${chartConfig?.yField}`]?.unit || "",
            },
            fields: {
              valueField: {
                value: chartConfig?.yField,
                type: descriptorsListUnique[`${chartConfig?.yField}`]?.type,
              },
              categoryField: [
                {
                  value: chartConfig?.xField,
                  type: descriptorsListUnique[`${chartConfig?.xField}`]?.type,
                },
              ],
            },
            title: chartConfig?.title,
          });
          break;

        case "line_chart":
          result.push({
            data: processLineChart(
              predefinedActionName,
              currentActionConfigName,
              chartConfig,
              contextData,
              descriptorsList,
              orgaSettings
            ),
            type: "line_chart",
            config: {
              color_pallet: chartConfig?.colorConfig,
              pallet_type: "By value",
              unit: descriptorsListUnique[`${chartConfig?.yField}`]?.unit || "",
            },
            fields: {
              valueField: {
                value: chartConfig?.yField,
                type: descriptorsListUnique[`${chartConfig?.yField}`]?.type,
              },
              categoryField: [{ value: "", type: "STRING" }],
              groupField: {
                value: chartConfig?.xField,
                type: descriptorsListUnique[`${chartConfig?.xField}`]?.type,
              },
            },
            title: chartConfig?.title,
          });
          break;

        case "dualAxes_chart":
          if (chartConfig?.graphByValueField) {
            const data = processDualAxesChartByField(
              predefinedActionName,
              currentActionConfigName,
              chartConfig,
              contextData,
              descriptorsList,
              chartConfig.graphByValueField,
              orgaSettings
            );

            const transformDataSets = transformDataSetsDualAxesChartByField(
              chartConfig,
              data
            );

            transformDataSets?.map((item: any) => {
              result.push(item);
            });
          } else {
            result.push({
              data: processDualAxesChart(
                predefinedActionName,
                currentActionConfigName,
                chartConfig,
                contextData,
                descriptorsList,
                orgaSettings
              ),
              type: "dualAxes_chart",
              config: {
                // color_pallet: chartConfig?.colorConfig,
                // unit: descriptorsList[chartConfig?.yField]?.unit || "",
              },
              yTitles: {
                yTitle1: chartConfig?.dataSetY1?.yTitle1,
                yTitle2: chartConfig?.dataSetY2?.yTitle2,
              },
              title: chartConfig?.title,
            });
          }

          break;

        case "map":
          result.push({
            data: await processMap(
              predefinedActionName,
              currentActionConfigName,
              chartConfig,
              contextData,
              descriptorsList,
              orgaSettings
            ),
            type: "map",
            title: chartConfig?.title,
          });
          break;

        case "dynamic_table":
          result.push({
            data: processDynamicTable(
              predefinedActionName,
              currentActionConfigName,
              chartConfig,
              contextData,
              descriptorsList,
              orgaSettings
            ),
            type: "dynamic_table",
            title: chartConfig?.title,
          });
          break;

        case "flow_chart":
          result.push({
            data: processFlowChart(
              predefinedActionName,
              currentActionConfigName,
              chartConfig,
              contextData,
              descriptorsList,
              orgaSettings
            ),
            type: "flow_chart",
            title: chartConfig?.title,
          });
          break;
        case "mixed_chart":
          result.push({
            data: processMixedChart(
              predefinedActionName,
              currentActionConfigName,
              chartConfig,
              contextData,
              descriptorsList,
              orgaSettings
            ),
            type: VIEW_TYPES.mix_charts,
            title: chartConfig?.title,
          });
          break;
        case "inputs":
          result.push({
            data: processInputs(
              predefinedActionName,
              currentActionConfigName,
              chartConfig,
              contextData,
              descriptorsList,
              orgaSettings
            ),
            type: "inputs",
            title: chartConfig?.title,
          });
          break;

        default:
          console.warn(`Unknown chart type: ${chartKey}`);
          break;
      }
    }
  }

  return result;
}

function processDualAxesChartByField(
  predefinedActionName: string,
  currentActionConfigName: string,
  config: any,
  contextData: any,
  descriptorsList: any,
  graphByValueField: any,
  orgaSettings: any
) {
  const extractDataByField = (
    dataConfig: any,
    seriesFieldKey: string,
    yFieldKey: string
  ) => {
    const dataSets: any = [];

    for (const key in dataConfig?.data) {
      if (dataConfig?.data.hasOwnProperty(key) && contextData) {
        const jsonPathExpression = dataConfig?.data[key]?.path;
        const extractedContent = jsonpath.query(
          contextData,
          jsonPathExpression
        )[0];

        if (!extractedContent) {
          let error = new Error(
            `Error in predefined Action: ${predefinedActionName}, action config: ${currentActionConfigName}, view: dualAxes (${dataConfig?.title}), data is missing in ${jsonPathExpression}`
          );

          logError(error, {
            module: "Runnings",
            component: "Open actions",
          });
        } else {
          extractedContent.forEach((item: any) => {
            // `graphByValueField` is now an array of strings
            const graphByValues = graphByValueField?.map((field: string) =>
              getFormatedValue(
                item[field],
                field + "." + key,
                orgaSettings,
                descriptorsList,
                false
              )
            );

            // Check if any of the fields in `graphByValueField` are missing
            const missingFields = graphByValues.some((value: any) => !value);

            if (missingFields) {
              let error = new Error(
                `Error in predefined Action: ${predefinedActionName}, action config: ${currentActionConfigName}, view: dualAxes chart (${dataConfig?.title}), one or more values for ${graphByValueField} are missing in ${jsonPathExpression}`
              );

              logError(error, {
                module: "Runnings",
                component: "Open actions",
              });
            } else {
              // Concatenate the values of `graphByValueField` with '-'
              const concatenatedGraphByValue =
                graphByValues.length === 1
                  ? graphByValues[0]
                  : graphByValues.join(" / ");

              let allFalsy = true;
              for (const key of graphByValueField) {
                if (item[key]) {
                  allFalsy = false;
                  break;
                }
              }

              if (allFalsy) {
                dataSets[concatenatedGraphByValue] = [];
              }

              // Iterate through `seriesFieldKey` values and perform operations
              dataConfig?.data[key]?.[seriesFieldKey]?.forEach(
                (seriesField: string) => {
                  if (!item.hasOwnProperty(seriesField)) {
                    let error = new Error(
                      `Error in predefined Action: ${predefinedActionName}, action config: ${currentActionConfigName}, view: dualAxes chart (${dataConfig?.title}), key: ${seriesField} is missing in ${jsonPathExpression}`
                    );

                    logError(error, {
                      module: "Runnings",
                      component: "Open actions",
                    });
                  } else {
                    const error = checkTypeAttribute(
                      config.title,
                      item[seriesField],
                      seriesField + "." + key,
                      true,
                      descriptorsList
                    );

                    if (error) {
                      let categoryfield;

                      const groupeValue = item[dataConfig?.data[key]?.groupe];
                      const isGroupe = dataConfig?.data[key]?.isgroupe;
                      const groupeKey = dataConfig?.data[key]?.groupe;

                      if (groupeKey && groupeValue) {
                        categoryfield = isGroupe
                          ? `${key}\n${groupeKey}: ${groupeValue}`
                          : `${groupeKey}: ${groupeValue}`;
                      } else if (!groupeKey && !isGroupe) {
                        categoryfield = key;
                      }

                      if (!dataSets[concatenatedGraphByValue]) {
                        dataSets[concatenatedGraphByValue] = [];
                      }

                      // Push a single entry into `dataSets` with the concatenated value of `graphByValues`
                      dataSets[concatenatedGraphByValue].push({
                        categoryField: categoryfield,
                        [yFieldKey]: item[seriesField],
                        [seriesFieldKey]: seriesField,
                      });
                    }
                  }
                }
              );
            }
          });

          // extractedContent.forEach((item: any) => {
          //   const graphByValue = item[graphByValueField];

          //   if (!graphByValue) {
          //     let error = new Error(
          //       `Error in predefined Action: ${predefinedActionName}, action config: ${currentActionConfigName}, view: dualAxes chart (${dataConfig?.title}), the value for ${graphByValueField} is missing in ${jsonPathExpression}`
          //     );

          //     logError(error, {
          //       module: "Runnings",
          //       component: "Open actions",
          //     });
          //   } else {
          //     if (!dataSets[graphByValue]) {
          //       dataSets[graphByValue] = [];
          //     }

          //     dataConfig?.data[key]?.[seriesFieldKey]?.forEach(
          //       (seriesField: string) => {
          //         if (!item.hasOwnProperty(seriesField)) {
          //           let error = new Error(
          //             `Error in predefined Action: ${predefinedActionName}, action config: ${currentActionConfigName}, view: dualAxes chart (${dataConfig?.title}), key: ${seriesField} is missing in ${jsonPathExpression}`
          //           );

          //           logError(error, {
          //             module: "Runnings",
          //             component: "Open actions",
          //           });
          //         } else {
          //           const error = checkTypeAttribute(
          //             config.title,
          //             item[seriesField],
          //             seriesField + "." + key,
          //             true,
          //             descriptorsList
          //           );
          //           if (error) {
          //             let categoryfield;

          //             const groupeValue = item[dataConfig?.data[key]?.groupe];
          //             const isGroupe = dataConfig?.data[key]?.isgroupe;
          //             const groupeKey = dataConfig?.data[key]?.groupe;

          //             if (groupeKey && groupeValue) {
          //               categoryfield = isGroupe
          //                 ? `${key}\n${groupeKey}: ${groupeValue}`
          //                 : `${groupeKey}: ${groupeValue}`;
          //             } else if (!groupeKey && !isGroupe) {
          //               categoryfield = key;
          //             }
          //             dataSets[graphByValue].push({
          //               categoryField: categoryfield,
          //               [yFieldKey]: item[seriesField],
          //               [seriesFieldKey]: seriesField,
          //             });
          //             // const categoryField = key;

          //             // dataSets[graphByValue].push({
          //             //   categoryField: categoryField,
          //             //   [yFieldKey]: item[seriesField],
          //             //   [seriesFieldKey]: seriesField,
          //             // });
          //           }
          //         }
          //       }
          //     );
          //   }
          // });
        }
      }
    }

    return dataSets;
  };

  const dataSetsY1 = extractDataByField(
    config?.dataSetY1,
    "seriesFieldY1",
    "yField1"
  );
  const dataSetsY2 = config?.dualAxis
    ? extractDataByField(config?.dataSetY2, "seriesFieldY2", "yField2")
    : null;

  return config?.dualAxis ? { dataSetsY1, dataSetsY2 } : { dataSetsY1 };
}

function transformDataSetsDualAxesChartByField(
  chartConfig: any,
  inputData: any
) {
  const result: any = [];

  // Get all unique keys from dataSetsY1 and dataSetsY2
  const allKeys = new Set([
    ...Object.keys(inputData.dataSetsY1),
    ...Object.keys(inputData.dataSetsY2),
  ]);

  // Iterate over each key to create the output structure
  allKeys.forEach((key) => {
    result.push({
      title: chartConfig?.title + " ( " + key + " )",
      data: {
        DataSetY1: inputData.dataSetsY1[key] || [],
        DataSetY2: inputData.dataSetsY2[key] || [],
      },
      type: "dualAxes_chart",
      yTitles: {
        yTitle1: chartConfig?.dataSetY1?.yTitle1,
        yTitle2: chartConfig?.dataSetY2?.yTitle2,
      },
    });
  });

  return result?.map((config: any) => {
    if (chartConfig.dataSetY1.additionalGroupingField) {
      const groupingField = chartConfig.dataSetY1.additionalGroupingField;

      // Update DataSetY1 based on additionalGroupingField
      const updatedDataSetY1 = updateCategoryField(
        config.data.DataSetY1,
        groupingField
      );

      return {
        ...config,
        data: {
          ...config.data,
          DataSetY1: updatedDataSetY1,
        },
      };
    }
    if (chartConfig.dataSetY2.additionalGroupingField) {
      const groupingField = chartConfig.dataSetY2.additionalGroupingField;

      // Update DataSetY1 based on additionalGroupingField
      const updatedDataSetY2 = updateCategoryField(
        config.data.DataSetY2,
        groupingField
      );

      return {
        ...config,
        data: {
          ...config.data,
          DataSetY2: updatedDataSetY2,
        },
      };
    }
    return config;
  });
}

function updateCategoryField(dataSet: any[], groupingField: string): any[] {
  // Filter modified and unmodified items
  const modifiedItems = dataSet
    .filter((item) => item.seriesFieldY1 === groupingField)
    .map((item) => ({
      ...item,
      categoryField: groupingField,
    }));

  const unmodifiedItems = dataSet.filter(
    (item) => item.seriesFieldY1 !== groupingField
  );

  // Concatenate unmodified items with modified items
  return [...unmodifiedItems, ...modifiedItems];
}
function processDualAxesChart(
  predefinedActionName: string,
  currentActionConfigName: string,
  config: any,
  contextData: any,
  descriptorsList: any,
  orgaSettings: any
) {
  const extractData = (
    dataConfig: any,
    seriesFieldKey: string,
    yFieldKey: string
  ) => {
    const dataSet: any[] = [];
    for (const key in dataConfig?.data) {
      if (dataConfig?.data.hasOwnProperty(key) && contextData) {
        const jsonPathExpression = dataConfig?.data[key]?.path;
        const extractedContent = jsonpath.query(
          contextData,
          jsonPathExpression
        )[0];

        if (!extractedContent) {
          let error = new Error(
            ` error in predefined Action : ${predefinedActionName}, action config  :${currentActionConfigName} ,view :dualAxes (${dataConfig?.title})  ,data is missing in ${jsonPathExpression}`
          );

          logError(error, {
            module: "Runnings",
            component: "Open actions",
          });
        } else {
          dataConfig?.data[key]?.[seriesFieldKey]?.map(
            (seriesField: string) => {
              extractedContent?.map((item: any) => {
                if (!item.hasOwnProperty(seriesField)) {
                  // key do not exist in jsonPathExpression

                  let error = new Error(
                    ` error in predefined Action : ${predefinedActionName}, action config  :${currentActionConfigName}  ,view :dualAxes chart (${dataConfig?.title})  ,this key : ${seriesField} is missing in ${jsonPathExpression}`
                  );

                  logError(error, {
                    module: "Runnings",
                    component: "Open actions",
                  });
                } else {
                  if (!item[seriesField] && item[seriesField] !== 0) {
                    // value of key do not exist
                    let error = new Error(
                      ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName}  ,view :dualAxes chart  (${dataConfig?.title}) , the value of this key : ${seriesField} is missing in ${jsonPathExpression}`
                    );

                    logError(error, {
                      module: "Runnings",
                      component: "Open actions",
                    });
                  } else {
                    const error = checkTypeAttribute(
                      config.title,
                      item[seriesField],
                      seriesField + "." + key,
                      true,
                      descriptorsList
                    );
                    if (error) {
                      let categoryfield;

                      const groupeValue = item[dataConfig?.data[key]?.groupe];
                      const isGroupe = dataConfig?.data[key]?.isgroupe;
                      const groupeKey = dataConfig?.data[key]?.groupe;

                      if (groupeKey && groupeValue) {
                        categoryfield = isGroupe
                          ? `${key}\n${groupeKey}: ${groupeValue}`
                          : `${groupeKey}: ${groupeValue}`;
                      } else if (!groupeKey && !isGroupe) {
                        categoryfield = key;
                      }
                      dataSet.push({
                        categoryField: categoryfield,
                        [yFieldKey]: item[seriesField],
                        [seriesFieldKey]: seriesField,
                      });
                    }
                  }
                }
              });
            }
          );
        }
      }
    }

    return dataSet;
  };

  const DataSetY1 = extractData(config?.dataSetY1, "seriesFieldY1", "yField1");
  const DataSetY2 = config?.dualAxis
    ? extractData(config?.dataSetY2, "seriesFieldY2", "yField2")
    : null;

  return DataSetY1.length === 0 && DataSetY2?.length === 0
    ? {}
    : config?.dualAxis
    ? { DataSetY1, DataSetY2 }
    : { DataSetY1 };
}

function processLineChart(
  predefinedActionName: string,
  currentActionConfigName: string,
  config: any,
  contextData: any,
  descriptorsList: any,
  orgaSettings: any
) {
  const jsonpath = require("jsonpath");
  const listlines: any = [];

  for (const key in config.data) {
    let errorLine: boolean = false;
    if (config.data.hasOwnProperty(key) && contextData) {
      const jsonPathExpression = config.data[key];

      const extractedContent = jsonpath.query(
        contextData,
        jsonPathExpression
      )[0];
      if (!extractedContent) {
        let error = new Error(
          ` error in predefined Action : ${predefinedActionName}, action config  :${currentActionConfigName}  ,view :Line chart (${config?.title}) ,data is missing in ${jsonPathExpression}`
        );

        logError(error, {
          module: "Runnings",
          component: "Open actions",
        });
        errorLine = true;
      } else {
        const reducedData: any = extractedContent?.map((item: any) => {
          if (!item.hasOwnProperty(config?.xField)) {
            // key does not exist in jsonPathExpression
            let error = new Error(
              ` error in predefined Action : ${predefinedActionName}, action config  :${currentActionConfigName}  ,view :Line chart (${config?.title}), the key ;${config?.xField} is missing in ${jsonPathExpression}`
            );

            logError(error, {
              module: "Runnings",
              component: "Open actions",
            });
            errorLine = true;
          } else {
            if (!item.hasOwnProperty(config?.yField)) {
              let error = new Error(
                ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName}  ,view :line chart (${config?.title}) , the key ;${config?.yField} is missing in ${jsonPathExpression}`
              );

              logError(error, {
                module: "Runnings",
                component: "Open actions",
              });
              errorLine = true;
            } else {
              if (!item[config?.xField]) {
                // value of key do not exist
                let error = new Error(
                  ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName}  ,view :Line chart (${config?.title}) ,value of  the key ;${config?.xField} is missing in ${jsonPathExpression}`
                );

                logError(error, {
                  module: "Runnings",
                  component: "Open actions",
                });
                errorLine = true;
              } else {
                if (!item[config?.xField]) {
                  // value of key do not exist
                  let error = new Error(
                    ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName}  ,view :Line chart (${config?.title}) ,value of  the key ;${config?.yField} is missing in ${jsonPathExpression}`
                  );

                  logError(error, {
                    module: "Runnings",
                    component: "Open actions",
                  });
                  errorLine = true;
                } else {
                  // check data  types
                  const errorsX = checkTypeAttribute(
                    "Column chart",
                    item[config?.xField],
                    config?.xField + "." + key,
                    true,
                    descriptorsList
                  );
                  const errorsY = checkTypeAttribute(
                    "Column chart",
                    item[config?.yField],
                    config?.yField + "." + key,
                    true,
                    descriptorsList
                  );
                  // errorX= false mean  the data type do not correspond
                  if (errorsX && errorsY) {
                    return {
                      categoryField: key,
                      groupField: item[config?.xField],
                      valueField: item[config?.yField],
                    };
                  }
                }
              }
            }
          }
        });
        if (!errorLine) {
          listlines.push(...reducedData);
        }
      }
    }
  }

  return listlines;
}

function processBarChart(
  predefinedActionName: string,
  currentActionConfigName: string,
  config: any,
  contextData: any,
  descriptorsList: any,
  orgaSettings: any
) {
  const jsonpath = require("jsonpath");
  const listBars: any = [];

  if (
    !config.dual &&
    config.grouped &&
    "dual" in config &&
    "grouped" in config
  ) {
    for (const key in config.data) {
      if (config.data.hasOwnProperty(key) && contextData) {
        const jsonPathExpression = config.data[key];
        const extractedContent = jsonpath.query(
          contextData,
          jsonPathExpression
        )[0];
        if (!extractedContent) {
          //  data missing in jsonPathExpression
        } else {
          const reducedData: any = extractedContent?.map((item: any) => {
            if (!item.hasOwnProperty(config?.xField)) {
              // key does not exist in jsonPathExpression
              let error = new Error(
                ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName} ,view :bar chart (${config?.title}) , the key ;${config?.xField} is missing in ${jsonPathExpression}`
              );

              logError(error, {
                module: "Runnings",
                component: "Open actions",
              });
            } else {
              if (!item.hasOwnProperty(config?.yField)) {
                let error = new Error(
                  ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName}  ,view :bar chart (${config?.title}) , the key ;${config?.yField} is missing in ${jsonPathExpression}`
                );

                logError(error, {
                  module: "Runnings",
                  component: "Open actions",
                });
              } else {
                if (!item[config?.xField]) {
                  // value of key do not exist
                  let error = new Error(
                    ` error in predefined Action : ${predefinedActionName}, action config  :${currentActionConfigName}  ,view :bar chart (${config?.title}) ,value of  the key ;${config?.xField} is missing in ${jsonPathExpression}`
                  );

                  logError(error, {
                    module: "Runnings",
                    component: "Open actions",
                  });
                } else {
                  if (!item[config?.xField]) {
                    // value of key do not exist
                    let error = new Error(
                      ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName}  ,view :bar chart (${config?.title}) ,value of  the key ;${config?.yField} is missing in ${jsonPathExpression}`
                    );

                    logError(error, {
                      module: "Runnings",
                      component: "Open actions",
                    });
                  } else {
                    // check data  types
                    const errorsX = checkTypeAttribute(
                      "Column chart",
                      item[config?.xField],
                      config?.xField + "." + key,
                      true,
                      descriptorsList
                    );
                    const errorsY = checkTypeAttribute(
                      "Column chart",
                      item[config?.yField],
                      config?.yField + "." + key,
                      true,
                      descriptorsList
                    );
                    if (errorsX && errorsY) {
                      return {
                        categoryField: item[config?.yField],
                        valueField: item[config?.xField],
                        ...(!config?.dual && config.grouped
                          ? { groupField: key }
                          : {}),
                      };
                    }
                  }
                }
              }
            }
          });

          listBars.push(...reducedData);
        }
      }
    }
  } else {
    const firstKey = Object.keys(config.data)[0];
    if (firstKey) {
      const jsonPathExpression = config.data[firstKey];
      const extractedContent = jsonpath.query(
        contextData,
        jsonPathExpression
      )[0];

      if (!extractedContent) {
        let error = new Error(
          ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName} ,view :bar chart (${config?.title}) ,data is missing in ${jsonPathExpression}`
        );

        logError(error, {
          module: "Runnings",
          component: "Open actions",
        });
      } else {
        const reducedData: any = extractedContent?.map((item: any) => {
          if (!item.hasOwnProperty(config?.xField)) {
            // key does not exist in jsonPathExpression
            let error = new Error(
              ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName} ,view :bar chart (${config?.title}) , the key ;${config?.xField} is missing in ${jsonPathExpression}`
            );

            logError(error, {
              module: "Runnings",
              component: "Open actions",
            });
          } else {
            if (!item.hasOwnProperty(config?.yField)) {
              let error = new Error(
                ` error in predefined Action : ${predefinedActionName}, action config  :${currentActionConfigName}  ,view :bar chart  (${config?.title}), the key ;${config?.yField} is missing in ${jsonPathExpression}`
              );

              logError(error, {
                module: "Runnings",
                component: "Open actions",
              });
            } else {
              if (!item[config?.xField]) {
                // value of key do not exist
                let error = new Error(
                  ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName}  ,view :bar chart (${config?.title}) ,value of  the key ;${config?.xField} is missing in ${jsonPathExpression}`
                );

                logError(error, {
                  module: "Runnings",
                  component: "Open actions",
                });
              } else {
                if (!item[config?.xField]) {
                  // value of key do not exist
                  let error = new Error(
                    ` error in predefined Action : ${predefinedActionName}, action config:  ${currentActionConfigName}  ,view :bar chart (${config?.title}) ,value of  the key ;${config?.yField} is missing in ${jsonPathExpression}`
                  );

                  logError(error, {
                    module: "Runnings",
                    component: "Open actions",
                  });
                } else {
                  // check data  types
                  const errorsX = checkTypeAttribute(
                    "Column chart",
                    item[config?.xField],
                    config?.xField + "." + firstKey,
                    true,
                    descriptorsList
                  );
                  const errorsY = checkTypeAttribute(
                    "Column chart",
                    item[config?.yField],
                    config?.yField + "." + firstKey,
                    true,
                    descriptorsList
                  );
                  // errorX= false mean  the data type do not correspond
                  if (errorsX && errorsY) {
                    return {
                      categoryField: item[config?.yField],
                      valueField: item[config?.xField],
                    };
                  }
                }
              }
            }
          }
        });

        listBars.push(...reducedData);
      }
    }
  }

  return listBars;
}

function processColumnChart(
  predefinedActionName: string,
  currentActionConfigName: string,
  config: any,
  contextData: any,
  descriptorsList: any,
  orgaSettings: any
) {
  const jsonpath = require("jsonpath");
  const listlines: any = [];

  for (const key in config.data) {
    if (config.data.hasOwnProperty(key) && contextData) {
      const jsonPathExpression = config.data[key];

      const extractedContent = jsonpath.query(
        contextData,
        jsonPathExpression
      )[0];

      if (!extractedContent) {
        let error = new Error(
          ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :column (${config?.title}),data is missing in ${jsonPathExpression}`
        );

        logError(error, {
          module: "Runnings",
          component: "Open actions",
        });
      } else {
        const reducedData: any = extractedContent?.map((item: any) => {
          if (!item.hasOwnProperty(config?.xField)) {
            // key does not exist in jsonPathExpression
            let error = new Error(
              ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :column (${config?.title}), the key ;${config?.xField} is missing in ${jsonPathExpression}`
            );

            logError(error, {
              module: "Runnings",
              component: "Open actions",
            });
          } else {
            if (!item.hasOwnProperty(config?.yField)) {
              let error = new Error(
                ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :column (${config?.title}), the key ;${config?.yField} is missing in ${jsonPathExpression}`
              );

              logError(error, {
                module: "Runnings",
                component: "Open actions",
              });
            } else {
              if (!item[config?.xField]) {
                // value of key do not exist
                let error = new Error(
                  ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :column (${config?.title}) ,value of  the key ;${config?.xField} is missing in ${jsonPathExpression}`
                );

                logError(error, {
                  module: "Runnings",
                  component: "Open actions",
                });
              } else {
                if (!item[config?.xField]) {
                  // value of key do not exist
                  let error = new Error(
                    ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :column (${config?.title}),value of  the key ;${config?.yField} is missing in ${jsonPathExpression}`
                  );

                  logError(error, {
                    module: "Runnings",
                    component: "Open actions",
                  });
                } else {
                  // check data  types
                  const errorsX = checkTypeAttribute(
                    "Column chart",
                    item[config?.xField],
                    config?.xField + "." + key,
                    true,
                    descriptorsList
                  );
                  const errorsY = checkTypeAttribute(
                    "Column chart",
                    item[config?.yField],
                    config?.yField + "." + key,
                    true,
                    descriptorsList
                  );
                  // errorX= false mean  the data type do not correspond
                  if (errorsX && errorsY) {
                    return {
                      categoryField: item[config?.xField],
                      seriesField: key,
                      valueField: item[config?.yField],
                    };
                  }
                }
              }
            }
          }
        });

        listlines.push(...reducedData);
      }
    }
  }

  return listlines;
}

async function processMap(
  predefinedActionName: string,
  currentActionConfigName: string,
  config: any,
  contextData: any,
  descriptorsList: any,
  orgaSettings: any
) {
  const list: any = [];
  try {
    for (const key in config.data) {
      if (config.data.hasOwnProperty(key) && contextData) {
        const jsonPathExpression = config.data[key];
        const extractedContent = jsonpath.query(
          contextData,
          jsonPathExpression
        )[0];
        if (!extractedContent) {
          let error = new Error(
            ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :map (${config?.title}),data is missing in ${jsonPathExpression}`
          );

          logError(error, {
            module: "Runnings",
            component: "Open actions",
          });
        } else {
          const processItem = async (extractedContentItem: any) => {
            const receiving_location_id =
              extractedContentItem[Object.keys(config?.receiving_location)[0]];

            if (!receiving_location_id) {
              throw new Error(
                ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :map (${
                  config?.title
                }),data  : ${
                  Object.keys(config?.receiving_location)[0]
                } is missing in  ${extractedContentItem} `
              );
            }
            const shipping_location_id =
              extractedContentItem[Object.keys(config?.shipping_location)[0]];
            if (!shipping_location_id) {
              throw new Error(
                ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :map (${
                  config?.title
                }),data  : ${
                  Object.keys(config?.shipping_location)[0]
                } is missing  ${extractedContentItem}`
              );
            }
            const receiving_location_path =
              config?.receiving_location[
                Object.keys(config?.receiving_location)[0]
              ];

            const shipping_location_path =
              config?.shipping_location[
                Object.keys(config?.shipping_location)[0]
              ];

            const receiving_location_city_record = getValueFieldData(
              contextData,
              receiving_location_path,
              receiving_location_id
            );
            if (!receiving_location_city_record) {
              throw new Error(
                ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :map (${config?.title}),data  : ${receiving_location_path} is missing `
              );
            }
            const receiving_location_city =
              receiving_location_city_record &&
              receiving_location_city_record.hasOwnProperty(
                config?.receiving_location_city || ""
              )
                ? receiving_location_city_record[config.receiving_location_city]
                : "";
            if (!receiving_location_city) {
              throw new Error(
                ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :map (${config?.title}),data  : ${config.receiving_location_city} is missing `
              );
            }
            const shipping_location_city_record = getValueFieldData(
              contextData,
              shipping_location_path,
              shipping_location_id
            );
            if (!shipping_location_city_record) {
              throw new Error(
                ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :map (${config?.title}),data  : ${shipping_location_path} is missing `
              );
            }
            const shipping_location_city =
              shipping_location_city_record &&
              shipping_location_city_record.hasOwnProperty(
                config?.shipping_location_city || ""
              )
                ? shipping_location_city_record[config.shipping_location_city]
                : "";
            if (!shipping_location_city) {
              throw new Error(
                ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :map (${config?.title}),data  : ${config.shipping_location_city} is missing `
              );
            }

            const shipping = await geocodeCity(shipping_location_city || "");
            const receiving = await geocodeCity(receiving_location_city || "");

            if (shipping && receiving) {
              let waypoints = createWaypoints(shipping, receiving);

              let popupContent = config.details
                .map((detailKey: string) => {
                  if (extractedContentItem[detailKey] && detailKey) {
                    return `${getTranslation(
                      detailKey,
                      "data"
                    )} : ${getFormatedValue(
                      extractedContentItem[detailKey],
                      detailKey + "." + key,
                      orgaSettings,
                      descriptorsList
                    )}`;
                  }
                  return null;
                })
                .filter(Boolean)
                .join("; ");

              list.push({
                waypoints,
                popupContent,
                shipping_location_city: shipping_location_city,
                receiving_location_city: receiving_location_city,
              });
            }
          };

          if (Array.isArray(extractedContent)) {
            await Promise.all(extractedContent.map(processItem));
          } else if (
            typeof extractedContent === "object" &&
            extractedContent !== null
          ) {
            await processItem(extractedContent);
          }
        }
      }
    }
    return list;
  } catch (error: any) {
    logError(error, {
      module: "Runnings",
      component: "Open actions",
    });
    return list;
  }
}
function removeDuplicate(array: any[]) {
  // Check if all objects have the key TRUCK_ID
  const allHaveTruckId = array?.every((item: any) =>
    item.hasOwnProperty("TRUCK_ID")
  );

  if (!allHaveTruckId) {
    return false; // or handle the error as needed
  }

  // Create a frequency map for TRUCK_IDs
  const truckIdCount: { [key: string]: number } = {};

  array.forEach((item) => {
    const truckId = item.TRUCK_ID;
    truckIdCount[truckId] = (truckIdCount[truckId] || 0) + 1;
  });

  // Filter out objects that have duplicates, keeping only one occurrence
  const uniqueTrucks = new Set<string>();
  return array.filter((item) => {
    const truckId = item.TRUCK_ID;
    if (truckIdCount[truckId] > 1) {
      // If there are duplicates, only keep the first occurrence
      if (!uniqueTrucks.has(truckId)) {
        uniqueTrucks.add(truckId);
        return true; // Keep the first occurrence
      }
      return false; // Remove further duplicates
    }
    return true; // Keep items with unique TRUCK_IDs
  });
}

function processDynamicTable(
  predefinedActionName: string,
  currentActionConfigName: string,
  config: any,
  contextData: any,
  descriptorsList: any,
  orgaSettings: any
) {
  const tableRows: any = [];
  for (const key in config.data) {
    if (config.data.hasOwnProperty(key) && contextData) {
      const jsonPathExpression = config?.data[key]?.path || "";
      const extractedContent = jsonpath.query(
        contextData,
        jsonPathExpression
      )[0];

      if (extractedContent && extractedContent.length > 0) {
        extractedContent?.map((extractedContentItem: any) => {
          const rowData: any = {};

          // Map columns in config  with extracted content key
          for (const columnKey in config?.data[key]?.columns) {
            if (config?.data[key]?.columns.hasOwnProperty(columnKey)) {
              //column key contains either a string === all  to be concatenated
              //or a string path to  a single value
              // or an array of strings, each of them contains a path to a value to be concatenated
              let columnJsonPath = config?.data[key]?.columns[columnKey];
              let columnValue;

              // Check if columnJsonPath is a string (JSON path)
              if (typeof columnJsonPath === "string") {
                columnValue = jsonpath.query(
                  extractedContentItem,
                  columnJsonPath
                )[0];
              }
              // Check if columnJsonPath is an object and contains the ALL property
              else if (
                typeof columnJsonPath === "object" &&
                columnJsonPath.ALL === true &&
                typeof columnJsonPath.path !== "object"
              ) {
                columnJsonPath = config?.data[key]?.columns[columnKey]?.path;
                const valueObject = jsonpath.query(
                  extractedContentItem,
                  columnJsonPath
                )[0];
                // check if the object is not empty and check if it actually contains keys and value
                // Concatenate each key and value into the format 'key: value' followed by \n
                if (typeof valueObject === "object" && valueObject !== null) {
                  columnValue = Object.entries(valueObject)
                    .map(([key, value]) => `${key}: ${value}`)
                    .join("  ,  ");
                } else {
                  columnValue = valueObject; // In case the value isn't an object
                }
              } else if (
                typeof columnJsonPath === "object" &&
                columnJsonPath.ALL !== true
              ) {
                const valueEntries = Object.entries(columnJsonPath.path); // Extract keys and JSON paths
                const valueArray = valueEntries.map(([key, jsonPath]) => {
                  const value = jsonpath.query(
                    extractedContentItem,
                    jsonPath
                  )[0]; // Retrieve value from each JSON path
                  return `${key}: ${value}`; // Concatenate key and value
                });

                columnValue = valueArray.join("  ,  ");
              } else if (
                typeof columnJsonPath === "object" &&
                columnJsonPath.ALL === true &&
                typeof columnJsonPath.path === "object"
              ) {
                const valueEntries = Object.entries(columnJsonPath.path);
                const detailValues: any = {};
                valueEntries.map(([key, jsonPath]) => {
                  const value = jsonpath.query(
                    extractedContentItem,
                    jsonPath
                  )[0];
                  detailValues[key] = value;
                });
                columnValue = TranslateErrors(detailValues);
              }
              // check data type
              if (config?.type !== CATEGORY_VIEWS.ERROR_WARNING_VIEW) {
                const errors = checkTypeAttribute(
                  "Dynamic table ",
                  columnValue,
                  columnKey + "." + key,
                  true,
                  descriptorsList
                );
                if (errors) {
                  rowData[columnKey] = getFormatedValue(
                    columnValue,
                    columnKey + "." + key,
                    orgaSettings,
                    descriptorsList,
                    false
                  );
                }
              } else {

                if (columnKey !== "DETAILS") {
                  rowData[columnKey] = getTranslation(
                    getFormatedValue(
                      columnValue,
                      columnKey + "." + key,
                      orgaSettings,
                      descriptorsList
                    ),
                    "data"
                  );
                } else {
                  rowData[columnKey] = getFormatedValue(
                    columnValue,
                    columnKey + "." + key,
                    orgaSettings,
                    descriptorsList
                  );
                }

                const pathSegments = jsonPathExpression.split(".");
                const extractedSeverity = pathSegments[pathSegments.length - 1];
                rowData["SEVERITY"] = extractedSeverity;
              }
            }
          }
          if (Object.keys(rowData).length !== 0) {
            tableRows.push(rowData);
          }
        });
      } else {
        if (config?.type !== CATEGORY_VIEWS.ERROR_WARNING_VIEW) {
          let error = new Error(
            ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Dynamic table (${config?.title})  ,data in: ${jsonPathExpression} is missing`
          );

          logError(error, {
            module: "Runnings",
            component: "Open actions",
          });
        }
      }
    }
  }

  const result = removeDuplicate(tableRows);
  if (!result) {
    return tableRows;
  } else {
    return result;
  }
}

function processFlowChart(
  predefinedActionName: string,
  currentActionConfigName: string,
  config: any,
  contextData: any,
  descriptorsList: any,
  orgaSettings: any
) {
  const jsonpath = require("jsonpath");
  let nodes: any = [];
  let edges: any = [];
  let flowList: any[] = [];

  for (const key in config.data) {
    if (config.data.hasOwnProperty(key) && contextData) {
      let errorFlow: boolean = false;
      const jsonPathExpression = config.data[key];

      const extractedContent = jsonpath.query(
        contextData,
        jsonPathExpression
      )[0];

      if (extractedContent) {
        if (
          config?.startNodes?.length > 0 &&
          config?.endNodes?.length > 0 &&
          config?.details?.length > 0
        ) {
          if (Array.isArray(extractedContent)) {
            // here it's an array
            extractedContent?.map((item: any, index: number) => {
              errorFlow = false;

              /****************************** start nodes  */
              // Constructing startNode text dynamically
              let startNodeText = "";

              config.startNodes.forEach((startNode: string | StartNode) => {
                if (typeof startNode === "string") {
                  // check if the key exists
                  if (!item.hasOwnProperty(startNode)) {
                    //log mising key
                    let error = new Error(
                      ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${config?.title}) ,The key : ${startNode}  in ${jsonPathExpression}is missing`
                    );

                    logError(error, {
                      module: "Runnings",
                      component: "Open actions",
                    });
                    errorFlow = true;
                  } else {
                    if (!item[startNode]) {
                      // missing value of the key
                      let error = new Error(
                        ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${config?.title}),the value of this  key : ${startNode}  is missing in  ${jsonPathExpression}`
                      );

                      logError(error, {
                        module: "Runnings",
                        component: "Open actions",
                      });
                      errorFlow = true;
                    } else {
                      //nothing is missing continue

                      //check data type
                      const errors = checkTypeAttribute(
                        "FlowChart",
                        item[startNode],
                        startNode + "." + key,
                        true,
                        descriptorsList
                      );
                      if (!errors) {
                        errorFlow = true;
                      }

                      startNodeText += ` ${getFormatedValue(
                        item[startNode],
                        startNode + "." + key,
                        orgaSettings,
                        descriptorsList
                      )} \n `;
                    }
                  }
                } else if (typeof startNode === "object") {
                  // Handle the object case

                  const { path, valueField } = startNode;

                  if (path && valueField) {
                    const path_id = item[Object.keys(path)[0]];

                    const Value = getValueFieldData(
                      contextData,
                      path[Object.keys(path)[0]],
                      path_id
                    );

                    if (!Value) {
                      //missing value in path
                      let error = new Error(
                        ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                          config?.title
                        }),The value in this path  : ${
                          path[Object.keys(path)[0]]
                        } correponding to this id ${path_id} is missing`
                      );

                      logError(error, {
                        module: "Runnings",
                        component: "Open actions",
                      });
                      errorFlow = true;
                    } else {
                      if (!Value.hasOwnProperty(valueField)) {
                        // key do not exist in value
                        let error = new Error(
                          ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                            config?.title
                          }),This key : ${valueField}  is missing in ${
                            path[Object.keys(path)[0]]
                          }`
                        );

                        logError(error, {
                          module: "Runnings",
                          component: "Open actions",
                        });
                        errorFlow = true;
                      } else {
                        // check value of that key
                        if (!Value[valueField]) {
                          // value do not exist
                          let error = new Error(
                            ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                              config?.title
                            }),The value of this key : ${valueField}  is missing in ${
                              path[Object.keys(path)[0]]
                            }`
                          );

                          logError(error, {
                            module: "Runnings",
                            component: "Open actions",
                          });
                          errorFlow = true;
                        } else {
                          //key and value exist
                          // check data type
                          const errors = checkTypeAttribute(
                            "FlowChart",
                            Value[valueField],
                            valueField + "." + key,
                            true,
                            descriptorsList
                          );
                          if (!errors) {
                            errorFlow = true;
                          }

                          startNodeText += ` ${
                            Value
                              ? // valueField +
                                //   " " +
                                getFormatedValue(
                                  Value[valueField],
                                  valueField + "." + key,
                                  orgaSettings,
                                  descriptorsList
                                )
                              : ""
                          } \n `;
                        }
                      }
                    }
                  }
                }
              });

              const startNode = {
                id: `startNode` + index,
                value: {
                  text: startNodeText.trim(),
                },
              };
              /****************************** end nodes  */
              // Constructing endNode text dynamically
              let endNodeText = "";
              config.endNodes.forEach((endNode: string | StartNode) => {
                if (typeof endNode === "string") {
                  if (!item.hasOwnProperty(endNode)) {
                    //key endNode missing
                    let error = new Error(
                      ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${config?.title}),This key  : ${endNode} is missing in in ${jsonPathExpression}`
                    );

                    logError(error, {
                      module: "Runnings",
                      component: "Open actions",
                    });
                    errorFlow = true;
                  } else {
                    if (!item[endNode]) {
                      // value of that key is missing
                      let error = new Error(
                        ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${config?.title}),The valule of this key  : ${endNode} is missing in in ${jsonPathExpression}`
                      );

                      logError(error, {
                        module: "Runnings",
                        component: "Open actions",
                      });
                      errorFlow = true;
                    } else {
                      // key and value exists
                      // check datatype
                      const errors = checkTypeAttribute(
                        "FlowChart",
                        item[endNode],
                        endNode + "." + key,
                        true,
                        descriptorsList
                      );
                      if (!errors) {
                        errorFlow = true;
                      }
                      endNodeText += ` ${getFormatedValue(
                        item[endNode],
                        endNode + "." + key,
                        orgaSettings,
                        descriptorsList
                      )} \n `;
                    }
                  }
                } else if (typeof endNode === "object") {
                  // Handle the object case

                  const { path, valueField } = endNode;
                  if (path && valueField) {
                    if (!item.hasOwnProperty(Object.keys(path)[0])) {
                      // this key Object.keys(path)[0] do not exist in jsonPathExpression
                      let error = new Error(
                        ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                          config?.title
                        }), this key  :${
                          Object.keys(path)[0]
                        } is missing in :${jsonPathExpression} `
                      );

                      logError(error, {
                        module: "Runnings",
                        component: "Open actions",
                      });
                      errorFlow = true;
                    } else {
                      const path_id = item[Object.keys(path)[0]];
                      if (!path_id) {
                        // value of this key do not exist in jsonPathExpression
                        let error = new Error(
                          ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                            config?.title
                          }),The value of this key  :${
                            Object.keys(path)[0]
                          } is missing in :${jsonPathExpression} `
                        );

                        logError(error, {
                          module: "Runnings",
                          component: "Open actions",
                        });
                        errorFlow = true;
                      } else {
                        //check type of value path_id , key Object.keys(path)[0]
                        const errors = checkTypeAttribute(
                          "FlowChart",
                          path_id,
                          Object.keys(path)[0] + "." + key,
                          true,
                          descriptorsList
                        );
                        if (!errors) {
                          return [];
                        }
                        const Value = getValueFieldData(
                          contextData,
                          path[Object.keys(path)[0]],
                          path_id
                        );
                        if (!Value) {
                          // value do not exist in path[Object.keys(path)[0]] with the correspondig value= path_id
                          let error = new Error(
                            ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                              config?.title
                            }),The valuecorrespondig to this id : ${path_id} is missing in  : ${
                              path[Object.keys(path)[0]]
                            }`
                          );

                          logError(error, {
                            module: "Runnings",
                            component: "Open actions",
                          });
                          errorFlow = true;
                        } else {
                          if (!Value.hasOwnProperty(valueField)) {
                            //key valueField do not exist in path[Object.keys(path)[0]]
                            let error = new Error(
                              ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                                config?.title
                              }), this key : ${valueField} is missing in  : ${
                                path[Object.keys(path)[0]]
                              }`
                            );

                            logError(error, {
                              module: "Runnings",
                              component: "Open actions",
                            });
                            errorFlow = true;
                          } else {
                            if (!Value[valueField]) {
                              // value of the key valueField do not exist in path[Object.keys(path)[0]]
                              let error = new Error(
                                ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                                  config?.title
                                }),The value of this key : ${valueField} is missing in  : ${
                                  path[Object.keys(path)[0]]
                                }`
                              );

                              logError(error, {
                                module: "Runnings",
                                component: "Open actions",
                              });
                              errorFlow = true;
                            } else {
                              // check data type of value=Value[valueField] key=valueField
                              const errors = checkTypeAttribute(
                                "FlowChart",
                                Value[valueField],
                                valueField + "." + key,
                                true,
                                descriptorsList
                              );
                              if (!errors) {
                                errorFlow = true;
                              }
                              endNodeText += ` ${
                                Value
                                  ? getFormatedValue(
                                      Value[valueField],
                                      valueField + "." + key,

                                      orgaSettings,
                                      descriptorsList
                                    )
                                  : ""
                              } \n `;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });

              const endNode = {
                id: `endNode` + index,
                value: {
                  text: endNodeText.trim(), // Remove trailing newline
                },
              };

              /******************************************** edges */
              // Constructing edge text dynamically
              let edgeText = "";
              let edgeSubText = "";

              config.details.forEach((detailKey: string, index: number) => {
                if (!item.hasOwnProperty(detailKey)) {
                  let error = new Error(
                    `error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${config?.title}), this key :${detailKey}  is missing in  : ${jsonPathExpression}`
                  );

                  logError(error, {
                    module: "Runnings",
                    component: "Open actions",
                  });
                  return [];
                } else {
                  if (!item[detailKey]) {
                    let error = new Error(
                      `error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${config?.title}), The value of the key :${detailKey}  is missing in  : ${jsonPathExpression}`
                    );

                    logError(error, {
                      module: "Runnings",
                      component: "Open actions",
                    });
                    return [];
                  } else {
                    const errors = checkTypeAttribute(
                      "FlowChart",
                      item[detailKey],
                      detailKey + "." + key,
                      true,
                      descriptorsList
                    );
                    if (!errors) {
                      return [];
                    }

                    const formattedValue = `${getTranslation(
                      detailKey,
                      "data"
                    )}: ${getFormatedValue(
                      item[detailKey],
                      detailKey + "." + key,
                      orgaSettings,
                      descriptorsList
                    )} \n`;

                    edgeSubText += formattedValue;
                  }
                }
              });

              const edge = {
                source: `startNode` + index,
                target: `endNode` + index,
                value: {
                  text: edgeText,
                  subText: edgeSubText + `\n \n\n`,
                },
              };
              //

              if (!errorFlow) {
                flowList.push({ nodes: [startNode, endNode], edges: [edge] });
              }
            });
            return flowList;
          } else {
            /****************************** start nodes  */
            // Constructing startNode text dynamically
            let startNodeText = "";

            config.startNodes.forEach((startNode: string | StartNode) => {
              if (typeof startNode === "string") {
                // check if the key exists
                if (!extractedContent.hasOwnProperty(startNode)) {
                  //log mising key
                  let error = new Error(
                    ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${config?.title}) ,The key : ${startNode}  in ${jsonPathExpression}is missing`
                  );

                  logError(error, {
                    module: "Runnings",
                    component: "Open actions",
                  });
                  errorFlow = true;
                } else {
                  if (!extractedContent[startNode]) {
                    // missing value of the key
                    let error = new Error(
                      ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${config?.title}),the value of this  key : ${startNode}  is missing in  ${jsonPathExpression}`
                    );

                    logError(error, {
                      module: "Runnings",
                      component: "Open actions",
                    });
                    errorFlow = true;
                  } else {
                    //nothing is missing continue
                    //check data type
                    const errors = checkTypeAttribute(
                      "FlowChart",
                      extractedContent[startNode],
                      startNode + "." + key,
                      true,
                      descriptorsList
                    );
                    if (!errors) {
                      errorFlow = true;
                    }

                    startNodeText += ` ${getFormatedValue(
                      extractedContent[startNode],
                      startNode + "." + key,
                      orgaSettings,
                      descriptorsList
                    )} \n `;
                  }
                }
              } else if (typeof startNode === "object") {
                // Handle the object case

                const { path, valueField } = startNode;

                if (path && valueField) {
                  const path_id = extractedContent[Object.keys(path)[0]];

                  const Value = getValueFieldData(
                    contextData,
                    path[Object.keys(path)[0]],
                    path_id
                  );

                  if (!Value) {
                    //missing value in path
                    let error = new Error(
                      ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                        config?.title
                      }),The value in this path  : ${
                        path[Object.keys(path)[0]]
                      } correponding to this id ${path_id} is missing`
                    );

                    logError(error, {
                      module: "Runnings",
                      component: "Open actions",
                    });
                    errorFlow = true;
                  } else {
                    if (!Value.hasOwnProperty(valueField)) {
                      // key do not exist in value
                      let error = new Error(
                        ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                          config?.title
                        }),This key : ${valueField}  is missing in ${
                          path[Object.keys(path)[0]]
                        }`
                      );

                      logError(error, {
                        module: "Runnings",
                        component: "Open actions",
                      });
                      errorFlow = true;
                    } else {
                      // check value of that key
                      if (!Value[valueField]) {
                        // value do not exist
                        let error = new Error(
                          ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                            config?.title
                          }),The value of this key : ${valueField}  is missing in ${
                            path[Object.keys(path)[0]]
                          }`
                        );

                        logError(error, {
                          module: "Runnings",
                          component: "Open actions",
                        });
                        errorFlow = true;
                      } else {
                        //key and value exist
                        // check data type
                        const errors = checkTypeAttribute(
                          "FlowChart",
                          Value[valueField],
                          valueField + "." + key,
                          true,
                          descriptorsList
                        );
                        if (!errors) {
                          errorFlow = true;
                        }

                        startNodeText += ` ${
                          Value
                            ? // valueField +
                              //   " " +
                              getFormatedValue(
                                Value[valueField],
                                valueField + "." + key,
                                orgaSettings,
                                descriptorsList
                              )
                            : ""
                        } \n `;
                      }
                    }
                  }
                }
              }
            });

            const startNode = {
              id: `startNode`,
              value: {
                text: startNodeText.trim(),
              },
            };

            /****************************** end nodes  */
            // Constructing endNode text dynamically
            let endNodeText = "";
            config.endNodes.forEach((endNode: string | StartNode) => {
              if (typeof endNode === "string") {
                if (!extractedContent.hasOwnProperty(endNode)) {
                  //key endNode missing
                  let error = new Error(
                    ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${config?.title}),This key  : ${endNode} is missing in in ${jsonPathExpression}`
                  );

                  logError(error, {
                    module: "Runnings",
                    component: "Open actions",
                  });
                  errorFlow = true;
                } else {
                  if (!extractedContent[endNode]) {
                    // value of that key is missing
                    let error = new Error(
                      ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${config?.title}),The valule of this key  : ${endNode} is missing in in ${jsonPathExpression}`
                    );

                    logError(error, {
                      module: "Runnings",
                      component: "Open actions",
                    });
                    errorFlow = true;
                  } else {
                    // key and value exists
                    // check datatype
                    const errors = checkTypeAttribute(
                      "FlowChart",
                      extractedContent[endNode],
                      endNode + "." + key,
                      true,
                      descriptorsList
                    );
                    if (!errors) {
                      errorFlow = true;
                    }
                    endNodeText += ` ${getFormatedValue(
                      extractedContent[endNode],
                      endNode + "." + key,
                      orgaSettings,
                      descriptorsList
                    )} \n `;
                  }
                }
              } else if (typeof endNode === "object") {
                // Handle the object case

                const { path, valueField } = endNode;
                if (path && valueField) {
                  if (!extractedContent.hasOwnProperty(Object.keys(path)[0])) {
                    // this key Object.keys(path)[0] do not exist in jsonPathExpression
                    let error = new Error(
                      ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                        config?.title
                      }), this key  :${
                        Object.keys(path)[0]
                      } is missing in :${jsonPathExpression} `
                    );

                    logError(error, {
                      module: "Runnings",
                      component: "Open actions",
                    });
                    errorFlow = true;
                  } else {
                    const path_id = extractedContent[Object.keys(path)[0]];
                    if (!path_id) {
                      // value of this key do not exist in jsonPathExpression
                      let error = new Error(
                        ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                          config?.title
                        }),The value of this key  :${
                          Object.keys(path)[0]
                        } is missing in :${jsonPathExpression} `
                      );

                      logError(error, {
                        module: "Runnings",
                        component: "Open actions",
                      });
                      errorFlow = true;
                    } else {
                      //check type of value path_id , key Object.keys(path)[0]
                      const errors = checkTypeAttribute(
                        "FlowChart",
                        path_id,
                        Object.keys(path)[0] + "." + key,
                        true,
                        descriptorsList
                      );
                      if (!errors) {
                        return [];
                      }
                      const Value = getValueFieldData(
                        contextData,
                        path[Object.keys(path)[0]],
                        path_id
                      );
                      if (!Value) {
                        // value do not exist in path[Object.keys(path)[0]] with the correspondig value= path_id
                        let error = new Error(
                          ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                            config?.title
                          }),The valuecorrespondig to this id : ${path_id} is missing in  : ${
                            path[Object.keys(path)[0]]
                          }`
                        );

                        logError(error, {
                          module: "Runnings",
                          component: "Open actions",
                        });
                        errorFlow = true;
                      } else {
                        if (!Value.hasOwnProperty(valueField)) {
                          //key valueField do not exist in path[Object.keys(path)[0]]
                          let error = new Error(
                            ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                              config?.title
                            }), this key : ${valueField} is missing in  : ${
                              path[Object.keys(path)[0]]
                            }`
                          );

                          logError(error, {
                            module: "Runnings",
                            component: "Open actions",
                          });
                          errorFlow = true;
                        } else {
                          if (!Value[valueField]) {
                            // value of the key valueField do not exist in path[Object.keys(path)[0]]
                            let error = new Error(
                              ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${
                                config?.title
                              }),The value of this key : ${valueField} is missing in  : ${
                                path[Object.keys(path)[0]]
                              }`
                            );

                            logError(error, {
                              module: "Runnings",
                              component: "Open actions",
                            });
                            errorFlow = true;
                          } else {
                            // check data type of value=Value[valueField] key=valueField
                            const errors = checkTypeAttribute(
                              "FlowChart",
                              Value[valueField],
                              valueField + "." + key,
                              true,
                              descriptorsList
                            );
                            if (!errors) {
                              errorFlow = true;
                            }
                            endNodeText += ` ${
                              Value
                                ? getFormatedValue(
                                    Value[valueField],
                                    valueField + "." + key,

                                    orgaSettings,
                                    descriptorsList
                                  )
                                : ""
                            } \n `;
                          }
                        }
                      }
                    }
                  }
                }
              }
            });

            const endNode = {
              id: `endNode`,
              value: {
                text: endNodeText.trim(), // Remove trailing newline
              },
            };

            /******************************************** edges */
            // Constructing edge text dynamically
            let edgeText = "";
            let edgeSubText = "";

            config.details.forEach((detailKey: string, index: number) => {
              if (!extractedContent.hasOwnProperty(detailKey)) {
                let error = new Error(
                  `error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${config?.title}), this key :${detailKey}  is missing in  : ${jsonPathExpression}`
                );

                logError(error, {
                  module: "Runnings",
                  component: "Open actions",
                });
                return [];
              } else {
                if (!extractedContent[detailKey]) {
                  let error = new Error(
                    `error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${config?.title}), The value of the key :${detailKey}  is missing in  : ${jsonPathExpression}`
                  );

                  logError(error, {
                    module: "Runnings",
                    component: "Open actions",
                  });
                  return [];
                } else {
                  const errors = checkTypeAttribute(
                    "FlowChart",
                    extractedContent[detailKey],
                    detailKey + "." + key,
                    true,
                    descriptorsList
                  );
                  if (!errors) {
                    return [];
                  }

                  const formattedValue = `${getTranslation(
                    detailKey,
                    "data"
                  )}: ${getFormatedValue(
                    extractedContent[detailKey],
                    detailKey + "." + key,
                    orgaSettings,
                    descriptorsList
                  )}\n`;

                  edgeSubText += formattedValue;
                }
              }
            });

            const edge = {
              source: `startNode`,
              target: `endNode`,
              value: {
                text: edgeText,
                subText: edgeSubText + `\n \n\n`,
              },
            };

            nodes.push(startNode, endNode);
            edges.push(edge);

            if (errorFlow) {
              return [];
            } else {
              return [{ nodes, edges }];
            }
          }
        }
      } else {
        let error = new Error(
          ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :Flow Chart (${config?.title}),The value in this path  : ${jsonPathExpression} is missing`
        );

        logError(error, {
          module: "Runnings",
          component: "Open actions",
        });
        // missing extractedContent log it
      }

      return [];
    }
  }
}

function processInputs(
  predefinedActionName: string,
  currentActionConfigName: string,
  config: any,
  contextData: any,
  descriptorsList: any,
  orgaSettings: any
) {
  const jsonpath = require("jsonpath");
  const inputView: any = [];

  for (const key in config.data) {
    if (config.data.hasOwnProperty(key) && contextData) {
      const jsonPathExpression = config.data[key];
      const origin = getLastJsonPathSegment(jsonPathExpression);
      const extractedContent = jsonpath.query(
        contextData,
        jsonPathExpression
      )[0];

      if (extractedContent) {
        const keysToProcess = config?.column
          ? config.column
          : extractedContent
          ? Object.keys(extractedContent)
          : [];

        for (const key of keysToProcess) {
          if (Object.prototype.hasOwnProperty.call(extractedContent, key)) {
            if (!extractedContent[key] && extractedContent[key] !== 0) {
              //value of key= key do not exist
              let error = new Error(
                ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :fields (${config?.title}), the value of This key ${key} does not exist (undefined or null) in this path  : ${jsonPathExpression}`
              );

              logError(error, {
                module: "Runnings",
                component: "Open actions",
              });
            } else {
              // check data type
              const errors = checkTypeAttribute(
                "Fields view",
                extractedContent[key],
                key + "." + origin,
                true,
                descriptorsList
              );

              if (errors) {
                inputView.push({
                  value: getFormatedValue(
                    extractedContent[key],
                    key + "." + origin,
                    orgaSettings,
                    descriptorsList,
                    false
                  ),
                  AttributeName: key,
                });
              }
            }
          } else {
            // key=key  do not exist in jsonPathExpression
            let error = new Error(
              ` error in predefined Action : ${predefinedActionName}, action config   : ${currentActionConfigName} ,view :fields (${config?.title}),This key ${key} does not exist in this path  : ${jsonPathExpression}`
            );

            logError(error, {
              module: "Runnings",
              component: "Open actions",
            });
          }
        }
      } else {
        let error = new Error(
          ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName}  ,view :Fields view (${config?.title}),Data missing in  : ${jsonPathExpression} `
        );

        logError(error, {
          module: "Runnings",
          component: "Open actions",
        });
      }
    }
  }
  const uniqueRsults = removeRedundancies(inputView);
  return uniqueRsults;
}
function removeRedundancies(inputArray: any[]): any[] {
  const uniqueEntries: { [key: string]: any } = {};

  inputArray.forEach((entry) => {
    if (!uniqueEntries[entry.AttributeName]) {
      uniqueEntries[entry.AttributeName] = entry;
    }
  });

  return Object.values(uniqueEntries);
}

const getValueFieldData = (data: any, path: string, id: string) => {
  // Extract the base path and the key dynamically
  const pathSegments = path.split(".");
  const basePath = pathSegments.slice(0, -1).join(".");
  const key = pathSegments[pathSegments.length - 1];

  // Use jsonpath to query the base path
  const basePathData = jsonpath.query(data, basePath)[0];
  // Check if basePathData is an array
  if (Array.isArray(basePathData) && basePathData) {
    return basePathData.find((data: any) => data[key] === id);
  }

  // If it's an object, check if basePathData[key] === id
  if (typeof basePathData === "object" && basePathData !== null) {
    if (basePathData && basePathData[key] === id) {
      return basePathData;
    } else {
      return null;
    }
  }

  // Return null if basePathData is neither an array nor an object
  return null;
};

export const geocodeCity = async (city: string) => {
  try {
    const encodedCity = encodeURIComponent(city);

    const response = await axios.get(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodedCity}`
    );

    if (response.data.length > 0) {
      const result = response.data[0];
      const latitude = parseFloat(result.lat);
      const longitude = parseFloat(result.lon);
      return [latitude, longitude];
    } else {
      throw new Error(
        "City not found or an error occurred while getting lattitude and longitude of this city"
      );
    }
  } catch (error) {
    throw new Error("City not found or an error occurred");
  }
};

export const createWaypoints = (
  shipping: number[] | undefined,
  receiving: number[] | undefined
) => {
  if (!shipping || !receiving) {
    return [];
  }

  const shippingLatLng = L.latLng(shipping[0], shipping[1]);
  const receivingLatLng = L.latLng(receiving[0], receiving[1]);
  return [shippingLatLng, receivingLatLng];
};

function processMixedChart(
  predefinedActionName: string,
  currentActionConfigName: string,
  config: any,
  contextData: any,
  descriptorsList: any,
  orgaSettings: any
) {
  const jsonpath = require("jsonpath");
  const resultLine: any = [];
  const resultColumn: any = [];
  const resultArea: any = [];

  Object.keys(config).forEach((key) => {
    if (key !== "title") {
      config[key].forEach((chartConfig: any) => {
        switch (key) {
          case "line_chart":
            resultLine.push({
              data: processLineMixedChart(
                predefinedActionName,
                currentActionConfigName,
                chartConfig,
                contextData,
                descriptorsList,
                orgaSettings
              ),
              type: "line_chart",
              config: {
                color_pallet: chartConfig?.colorConfig,
                pallet_type: "By value",
                unit: descriptorsList[chartConfig?.yField]?.unit || "",
              },
              fields: {
                valueField: {
                  value: chartConfig?.yField,
                  type: descriptorsList[chartConfig?.yField]?.type,
                },
                categoryField: [{ value: "", type: "STRING" }],
                groupField: {
                  value: chartConfig?.xField,
                  type: descriptorsList[chartConfig?.xField]?.type,
                },
              },
              title: chartConfig?.title,
            });
            break;
          case "column_chart":
            // Handle column chart logic here
            resultColumn.push({
              data: processColumnChart(
                predefinedActionName,
                currentActionConfigName,
                chartConfig,
                contextData,
                descriptorsList,
                orgaSettings
              ),
              type: "column_chart",
              config: {
                color_pallet: chartConfig?.colorConfig,
                pallet_type: "By value",
                unit: descriptorsList[chartConfig?.yField]?.unit || "",
              },
              fields: {
                valueField: {
                  value: chartConfig?.yField,
                  type: descriptorsList[chartConfig?.yField]?.type,
                },
                categoryField: [
                  {
                    value: chartConfig?.xField,
                    type: descriptorsList[chartConfig?.xField]?.type,
                  },
                ],
              },
              title: chartConfig?.title,
            });
            break;
          case "area_chart":
            resultArea.push({
              data: processColumnChart(
                predefinedActionName,
                currentActionConfigName,
                chartConfig,
                contextData,
                descriptorsList,
                orgaSettings
              ),
              type: "area_chart",
              config: {
                color_pallet: chartConfig?.colorConfig,
                pallet_type: "By value",
                unit: descriptorsList[chartConfig?.yField]?.unit || "",
              },
              fields: {
                valueField: {
                  value: chartConfig?.yField,
                  type: descriptorsList[chartConfig?.yField]?.type,
                },
                categoryField: [
                  {
                    value: chartConfig?.xField,
                    type: descriptorsList[chartConfig?.xField]?.type,
                  },
                ],
              },
              title: chartConfig?.title,
            });

            break;
          default:
            let error = new Error(
              `Error in action details configuration , Unknown chart type ${key}`
            );

            logError(error, {
              module: "Runnings",
              component: "Open actions",
            });
            break;
        }
      });
    }
  });
  return { resultLine, resultColumn, resultArea };
}

function processLineMixedChart(
  predefinedActionName: string,
  currentActionConfigName: string,
  config: any,
  contextData: any,
  descriptorsList: any,
  orgaSettings: any
) {
  const jsonpath = require("jsonpath");
  const listlines: any = [];

  for (const key in config.data) {
    let errorLine: boolean = false;
    if (config.data.hasOwnProperty(key) && contextData) {
      const jsonPathExpression = config.data[key];

      const extractedContent = jsonpath.query(
        contextData,
        jsonPathExpression
      )[0];
      if (!extractedContent) {
        let error = new Error(
          ` error in predefined Action : ${predefinedActionName}, action config  :${currentActionConfigName}  ,view :Line chart (${config?.title}) ,data is missing in ${jsonPathExpression}`
        );

        logError(error, {
          module: "Runnings",
          component: "Open actions",
        });
        errorLine = true;
      } else {
        const reducedData: any = extractedContent?.map((item: any) => {
          if (!item.hasOwnProperty(config?.xField)) {
            // key does not exist in jsonPathExpression
            let error = new Error(
              ` error in predefined Action : ${predefinedActionName}, action config  :${currentActionConfigName}  ,view :Line chart (${config?.title}), the key ;${config?.xField} is missing in ${jsonPathExpression}`
            );

            logError(error, {
              module: "Runnings",
              component: "Open actions",
            });
            errorLine = true;
          } else {
            if (!item.hasOwnProperty(config?.yField)) {
              let error = new Error(
                ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName}  ,view :line chart (${config?.title}) , the key ;${config?.yField} is missing in ${jsonPathExpression}`
              );

              logError(error, {
                module: "Runnings",
                component: "Open actions",
              });
              errorLine = true;
            } else {
              if (!item[config?.xField]) {
                // value of key do not exist
                let error = new Error(
                  ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName}  ,view :Line chart (${config?.title}) ,value of  the key ;${config?.xField} is missing in ${jsonPathExpression}`
                );

                logError(error, {
                  module: "Runnings",
                  component: "Open actions",
                });
                errorLine = true;
              } else {
                if (!item[config?.xField]) {
                  // value of key do not exist
                  let error = new Error(
                    ` error in predefined Action : ${predefinedActionName}, action config : ${currentActionConfigName}  ,view :Line chart (${config?.title}) ,value of  the key ;${config?.yField} is missing in ${jsonPathExpression}`
                  );

                  logError(error, {
                    module: "Runnings",
                    component: "Open actions",
                  });
                  errorLine = true;
                } else {
                  // check data  types???

                  return {
                    seriesField: config?.yField,
                    categoryField: item[config?.xField],
                    valueField: item[config?.yField],
                  };
                }
              }
            }
          }
        });
        if (!errorLine) {
          listlines.push(...reducedData);
        }
      }
    }
  }

  return listlines;
}
