import { useEffect, useState } from "react";
import { Col, ConfigProvider, Row } from "antd";
import ChartCard from "../common/ChartCard";
import { FilterData, getData } from "../common/FilterData";
import FilterHeader from "../common/filter_header/FilterHeader";
import {
  AcceptanceRateOverTime,
  ActionTypeOverTime,
  CurrentStatus,
  OpenRiskByActionType,
  RiskStatusByExecutionStage,
  StatusByAction,
} from "./components/charts";
import {
  mapAcceptanceRateOverTime,
  mapActionTypeOverTime,
  mapCurrentStatusData,
  mapOpenRiskByActionType,
  mapRiskStatusByExecutionStage,
  mapStatusByAction,
} from "./components/data_mapping";
import { DASHBOARDS } from "../../../../constants/dashboards";

const ActionMonitoring = (props: any) => {
  const { copilot } = props;
  const [filteredData, setFilteredData]: any = useState([]);
  const [filterOptions, setFilterOptions]: any = useState({});

  useEffect(() => {
    const sorted_data = getData(copilot);
    sorted_data.sort((a, b) => a.risk_date - b.risk_date);

    FilterData(filterOptions, sorted_data, setFilteredData);
  }, [filterOptions, copilot]);

  return (
    <ConfigProvider>
      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          marginBottom: 15,
        }}
      >
        <FilterHeader
          setFilterOptions={setFilterOptions}
          dashboard={DASHBOARDS.ACTION_MONITORING}
          copilot={copilot}
        />
      </Row>
      <Row gutter={[16, 16]}>
        <Col sm={24} lg={12} xl={9}>
          <ChartCard
            title="Status by Action"
            chart={<StatusByAction data={mapStatusByAction(filteredData)} />}
          ></ChartCard>
        </Col>
        <Col sm={24} lg={12} xl={6}>
          <ChartCard
            title="Current Status"
            chart={<CurrentStatus data={mapCurrentStatusData(filteredData)} />}
          ></ChartCard>
        </Col>
        <Col sm={24} lg={12} xl={9}>
          <ChartCard
            title="Risk Status  by Current Execution Stage"
            chart={
              <RiskStatusByExecutionStage
                data={mapRiskStatusByExecutionStage(filteredData)}
              />
            }
          ></ChartCard>
        </Col>
        <Col sm={24} lg={12} xl={9}>
          <ChartCard
            title="Acceptance Rate Over Time"
            chart={
              <AcceptanceRateOverTime
                data={mapAcceptanceRateOverTime(filteredData)}
              />
            }
          ></ChartCard>
        </Col>
        <Col sm={24} lg={12} xl={6}>
          <ChartCard
            title="Open Risk by Action Type"
            chart={
              <OpenRiskByActionType
                data={mapOpenRiskByActionType(filteredData)}
              />
            }
          ></ChartCard>
        </Col>
        <Col sm={24} lg={12} xl={9}>
          <ChartCard
            title="Action Type Over time"
            chart={
              <ActionTypeOverTime data={mapActionTypeOverTime(filteredData)} />
            }
          ></ChartCard>
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default ActionMonitoring;
