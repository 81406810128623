import { Locale } from "antd/lib/locale";
import enUS from "antd/locale/en_US";
import frFR from "antd/locale/fr_FR";
import arEG from "antd/locale/ar_EG";

export const antDesignLocales: Record<string, Locale> = {
  "en-US": enUS,
  "fr-FR": frFR,
  "ar-DZ": arEG,
};
