import { daytoDateSinceReference } from "../../../../../../utils/myDatetime";

export const mapAcceptanceRateOverTime = (data: any) => {
  const mappedData = data
    ?.map((obj: any) => {
      return {
        risk_status: obj.risk_status,
        risk_date: daytoDateSinceReference(obj.risk_date).toLocaleDateString(),
      };
    })
    ?.reduce((accumulator: any, item: any) => {
      const { risk_date, risk_status } = item;
      const key = `${risk_date}`;

      if (!accumulator[key]) {
        accumulator[key] = {
          risk_date,
          accepted_nb: null,
          total: null,
          value: null,
        };
      }

      if (risk_status === "Accepted" || risk_status === "Rejected") {
        accumulator[key].total += 1;
        if (risk_status === "Accepted") accumulator[key].accepted_nb += 1;
        accumulator[key].value = accumulator[key].accepted_nb
          ? (100 * accumulator[key].accepted_nb) / accumulator[key].total
          : null;
      }

      return accumulator;
    }, {});
  return Object.values(mappedData);
};
