import React, { useState, useEffect } from "react";

interface IconProps {
  name: string;
  size?: string;
}

const CustomIcon: React.FC<IconProps> = ({ name, size = "24px" }) => {
  const [path, setPath] = useState<string | false>(false);

  useEffect(() => {
    const importIcon = async () => {
      try {
        const { default: _path } = await import(
          `../../../../assets/images/svg/${name}.svg`
        );
        setPath(_path);
      } catch (err) {}
    };

    importIcon();
  }, [name]);

  return (
    <>
      {path && (
        <img
          src={path}
          alt={`Icon :${name}`}
          style={{ width: size, height: size }}
        />
      )}
    </>
  );
};

export default CustomIcon;
