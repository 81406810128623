declare global {
  interface Window {
    SERVER_URL: string;
    DEBUG_MODE: string;
    HAS_GPT: string;
    INSTRUMENTATION_KEY: string;
  }
}

let SERVER_URL: string = "https://exobrain-staging.azurewebsites.net";
let DEBUG_MODE_ENV: boolean = true;
let HAS_GPT_ENV: boolean = true;
let INSTRUMENTATION_KEY_ENV: string = "70192f5c-cb7a-487b-890d-1c0e727e9f42";

if (window.SERVER_URL && window.SERVER_URL !== "__SERVER_URL__") {
  SERVER_URL = window.SERVER_URL;
}
export const SERVER = SERVER_URL;

if (window.DEBUG_MODE === "1" || window.DEBUG_MODE === "__DEBUG_MODE__") {
  DEBUG_MODE_ENV = true;
} else {
  DEBUG_MODE_ENV = false;
}
export const DEBUG_MODE: boolean = DEBUG_MODE_ENV;

if (window.HAS_GPT === "1" || window.HAS_GPT === "__HAS_GPT__") {
  HAS_GPT_ENV = true;
} else {
  HAS_GPT_ENV = false;
}
export const HAS_GPT: boolean = HAS_GPT_ENV;

if (window.INSTRUMENTATION_KEY && window.INSTRUMENTATION_KEY !== "__INSTRUMENTATION_KEY__") {
  INSTRUMENTATION_KEY_ENV = window.INSTRUMENTATION_KEY;
}
export const INSTRUMENTATION_KEY = INSTRUMENTATION_KEY_ENV;

export const API_VERSION = "1.0";

let currentHost = "";
if (
  !window.location.port ||
  (window.location.protocol === "http:" && window.location.port === "80") ||
  (window.location.protocol === "https:" && window.location.port === "443")
) {
  currentHost = `${window.location.protocol}//${window.location.hostname}`;
} else {
  currentHost = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
}

export const CURRENT_HOST = currentHost;
