/**fulfillement */
export const Fulfillement_Risk = [
  /** Risk 1 Alternative DC */
  {
    id: 2,
    risk_conf_name: "Fulfillment Shortage",
    due_date: "2024-11-08 00:00:00",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge25255645", name: "Action Error" },
      { id: "fgfergger25255645", name: "Execution Error" },
    ],
    current_action: {
      action_conf: null,
      action_conf_name: "Alternative DC",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Fulfillment_option: "Alternative DC",
                Alternate_shipping_DC: 101,
                Alternate_route: "R101C3",
                New_mode_of_transportation: "Road",
                New_delivery_lead_time: 2,
                Delivery_Date: "2024-11-12",
              },
              OTHER_OPTIONS: [],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Alternative_Source_Description:
                  "Finished Goods Descrip SKU-346 Alternative DC",
                SKU_Description: "SKU-346",
                Standard_price_per_unit: "18.91€",
                Primary_DC: 100,
                Alternative_DC: 101,
                Alternative_DC_City: "Orléans",
                Alternative_Cost_per_unit_shipped: "16.89€",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },

              Procurement_Shipping_Conditions: {
                Route_Name: "101-Customer 3 Route",
                Shipping_Location: "Warsaw",
                Receiving_Location: "Berlin",
                Customer: "C0003",
                Mode_of_Transport: "Road",
                Distance: 1100,
                Lead_time_in_days: 2,
                Cost_per_unit_shipped: 0.69,
                CO2_emission: 0.08,
              },

              Customer_Order: {
                Customer: "C0003",
                Shipping_Location: 101,
                Receiving_Location: 303,
                Ship_Date: "12/4/2024",
                Delivery_Date: "12/6/2024",
                Quantity: 950,
                line: "3",
              },
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Planner Validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c7d995b58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Customer validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9ba454fe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Delivery",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "968ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    status: "WAIT_FOR_ACTION",
    copilot: "",
    model: "Fulfilment Shortage",
    context: {
      Date_of_risk: "2024-11-12",

      Item_Code: "SKU-346",
      Location_code: "warsaw",
      Customer: "C0003",
      SO_Number: "SO-000000038",
      Quantity: "950",
      Revenue_at_risk: "27,730€",
      Penalty_risk: "1,441€",
      Impact_on_DOS: " 0.08",
    },
    impact: "1,441€",

    actions:[
      {
        action_conf: null,
        action_conf_name: "Alternative DC",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Alternative DC",
                  Alternate_shipping_DC: 101,
                  Alternate_route: "R101C3",
                  New_mode_of_transportation: "Road",
                  New_delivery_lead_time: 2,
                  Delivery_Date: "2024-11-12",
                },
                OTHER_OPTIONS: [],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Alternative_Source_Description:
                    "Finished Goods Descrip SKU-346 Alternative DC",
                  SKU_Description: "SKU-346",
                  Standard_price_per_unit: "18.91€",
                  Primary_DC: 100,
                  Alternative_DC: 101,
                  Alternative_DC_City: "Orléans",
                  Alternative_Cost_per_unit_shipped: "16.89€",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Procurement_Shipping_Conditions: {
                  Route_Name: "101-Customer 3 Route",
                  Shipping_Location: "Warsaw",
                  Receiving_Location: "Berlin",
                  Customer: "C0003",
                  Mode_of_Transport: "Road",
                  Distance: 1100,
                  Lead_time_in_days: 2,
                  Cost_per_unit_shipped: 0.69,
                  CO2_emission: 0.08,
                },
    
                Customer_Order: {
                  Customer: "C0003",
                  Shipping_Location: 101,
                  Receiving_Location: 303,
                  Ship_Date: "12/4/2024",
                  Delivery_Date: "12/6/2024",
                  Quantity: 950,
                  line: "3",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Planner Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d995b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Customer validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9ba454fe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "968ebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Item substitution",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Item substitution",
                  Substitute_item: "SKU-995",
                  New_delivery_quantity: 1896,
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 1896,
                  Revenue_at_risk: "60463€",
                  Penalty_risk: "3,144€",
                  Impact_on_DOS: 0.08,
                  Delivery_CO2_emission_impact: 37.54,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Alternative_options: {
                  Original_Item: "SKU-213",
                  Standard_Cost_per_unit_shipped: "16.13€",
                  Substitute_Item: "SKU-995",
                  Cost_per_unit_shipped: "12.31€",
                  Substitute_Ratio: "1:1",
                  Customer_for_Substitution: "C0001",
                  Location_for_Substitution: 101,
                },
                Procurement_Shipping_Conditions: {
                  Route_Name: "R101C1",
                  Shipping_Location: "Orléans",
                  Receiving_Location: "Lyon",
                  Customer: "C0001",
                  Mode_of_Transport: "Road",
                  Distance: 500,
                  Lead_time_in_days: 1,
                  Cost_per_unit_shipped: 0.51,
                  CO2_emission: 0.09,
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Alternative DC",
                    Reason_Code: "No alternative DC",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                ],
                Customer_Order: {
                  Customer: "C0001",
                  Shipping_Location: 101,
                  Receiving_Location: 301,
                  Ship_Date: "2024-11-12",
                  Delivery_Date: "2024-12-12",
                  Quantity: 1896,
                  line: "4",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Planner Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4grgr58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Customer validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-95862-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf475afe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Partial Delivery",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Partial delivery",
                  Partial_delivery_quantity: 3760,
                  New_delivery_date: "2024-11-13",
                  Delivery_time: "12:00 PM",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 3760,
                  Revenue_at_risk: "105,994€",
                  Penalty_risk: "4,557€",
                  Delivery_delay: 8,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Inventory: [
                  { Date: "2024-11-03", Units: 394114 },
                  { Date: "2024-11-04", Units: 517622 },
                  { Date: "2024-11-05", Units: 378039 },
                  { Date: "2024-11-06", Units: 310736 },
                  { Date: "2024-11-07", Units: 404995 },
                  { Date: "2024-11-08", Units: 435398 },
                  { Date: "2024-11-09", Units: 390844 },
                  { Date: "2024-11-010", Units: 545550 },
                ],
                Procurement_Shipping_Conditions: {
                  Route_Name: "R101C4",
                  Shipping_Location: "Orléans",
                  Receiving_Location: "Valencia",
                  Customer: "C0004",
                  Mode_of_Transport: "Road",
                  Distance: 1200,
                  Lead_time_in_days: 3,
                  Cost_per_unit_shipped: "0.76€",
                  CO2_emission: 0.08,
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Alternative DC",
                    Reason_Code: "No alternative DC",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Substitute",
                    Reason_Code: "No finished goods substitute",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Express Delivery",
                    Reason_Code: "No route for express delivery",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                ],
                Customer_Order: {
                  Customer: "C0004",
                  Shipping_Location: 101,
                  Receiving_Location: 304,
                  Ship_Date: "2024-11-10",
                  Delivery_Date: "2024-11-13",
                  Quantity: 3760,
                  line: "3",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-212599119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Customer validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf8526xafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-dfre-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "cwas9836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Express Delivery",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Express delivery",
                  Alternate_route: "E101C4",
                  Express_delivery_quantity: 9127,
                  New_mode_of_transportation: "Express",
                  New_delivery_lead_time: 1,
                  Delivery_Date: "2024-11-05",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 9127,
                  Revenue_at_risk: "229909.13€",
                  Penalty_risk: "9,886€",
                  Express_delivery_cost: "6,758€",
                  Express_delivery_value_ratio: 0.029,
                  Delivery_CO2_emission_impact: 140.56,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Procurement_Shipping_Conditions: {
                  Route_Name: "E101C4",
                  Shipping_Location: "Orléans",
                  Receiving_Location: "Valencia",
                  Customer: "C0004",
                  Mode_of_Transport: "Express",
                  Distance: 1100,
                  Lead_time_in_days: 1,
                  Cost_per_unit_shipped: "1.23€",
                  CO2_emission: 0.07,
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Alternative DC",
                    Reason_Code: "No alternative DC",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Substitute",
                    Reason_Code: "No finished goods substitute",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                ],
                Customer_Order: {
                  Customer: "C0004",
                  Shipping_Location: 101,
                  Receiving_Location: 304,
                  Ship_Date: "2024-11-05",
                  Delivery_Date: "2024-11-06",
                  Quantity: 9127,
                  line: "3",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebpl859bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "caplok36-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      }
     
     ]
  },

  /** Risk 4 Item substitution*/
  {
    id: 4,
    risk_conf_name: "Fulfilment Shortage",
    due_date: "2024-11-08 00:00:00",
    copilot: "Procurement	",
    model: "Fulfilment Shortage",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge25255645", name: "Action Error" },
      { id: "fgfergger25255645", name: "Execution Error" },
    ],
    status: "WAIT_FOR_ACTION",
    context: {
      Date_of_risk: "2024-11-12",
      Item_Code: "SKU-213",
      Location_code: "Orléans",
      Customer: "C0001",
      SO_Number: "SO-000000036",
      Quantity: "1896",
      Revenue_at_risk: "60,463€",
      Penalty_risk: "3,144€",
      Impact_on_DOS: "0.08",
    },
    impact: "3,144€",
    current_action: {
      action_conf: null,
      action_conf_name: "Item substitution",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Fulfillment_option: "Item substitution",
                Substitute_item: "SKU-995",
                New_delivery_quantity: 1896,
              },

              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Quantity_at_risk: 1896,
                Revenue_at_risk: "60463€",
                Penalty_risk: "3,144€",
                Impact_on_DOS: 0.08,
                Delivery_CO2_emission_impact: 37.54,
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              Alternative_options: {
                Original_Item: "SKU-213",
                Standard_Cost_per_unit_shipped: "16.13€",
                Substitute_Item: "SKU-995",
                Cost_per_unit_shipped: "12.31€",
                Substitute_Ratio: "1:1",
                Customer_for_Substitution: "C0001",
                Location_for_Substitution: 101,
              },
              Procurement_Shipping_Conditions: {
                Route_Name: "R101C1",
                Shipping_Location: "Orléans",
                Receiving_Location: "Lyon",
                Customer: "C0001",
                Mode_of_Transport: "Road",
                Distance: 500,
                Lead_time_in_days: 1,
                Cost_per_unit_shipped: 0.51,
                CO2_emission: 0.09,
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "Alternative DC",
                  Reason_Code: "No alternative DC",
                  Execution_Stage: "Recommendation Validation",
                  User: "Exobrain",
                },
              ],
              Customer_Order: {
                Customer: "C0001",
                Shipping_Location: 101,
                Receiving_Location: 301,
                Ship_Date: "2024-11-12",
                Delivery_Date: "2024-12-12",
                Quantity: 1896,
                line: "4",
              },
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Planner Validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4grgr58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Customer validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-95862-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Delivery",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf475afe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    actions:[
      {
        action_conf: null,
        action_conf_name: "Alternative DC",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Alternative DC",
                  Alternate_shipping_DC: 101,
                  Alternate_route: "R101C3",
                  New_mode_of_transportation: "Road",
                  New_delivery_lead_time: 2,
                  Delivery_Date: "2024-11-12",
                },
                OTHER_OPTIONS: [],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Alternative_Source_Description:
                    "Finished Goods Descrip SKU-346 Alternative DC",
                  SKU_Description: "SKU-346",
                  Standard_price_per_unit: "18.91€",
                  Primary_DC: 100,
                  Alternative_DC: 101,
                  Alternative_DC_City: "Orléans",
                  Alternative_Cost_per_unit_shipped: "16.89€",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Procurement_Shipping_Conditions: {
                  Route_Name: "101-Customer 3 Route",
                  Shipping_Location: "Warsaw",
                  Receiving_Location: "Berlin",
                  Customer: "C0003",
                  Mode_of_Transport: "Road",
                  Distance: 1100,
                  Lead_time_in_days: 2,
                  Cost_per_unit_shipped: 0.69,
                  CO2_emission: 0.08,
                },
    
                Customer_Order: {
                  Customer: "C0003",
                  Shipping_Location: 101,
                  Receiving_Location: 303,
                  Ship_Date: "12/4/2024",
                  Delivery_Date: "12/6/2024",
                  Quantity: 950,
                  line: "3",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Planner Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d995b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Customer validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9ba454fe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "968ebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Item substitution",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Item substitution",
                  Substitute_item: "SKU-995",
                  New_delivery_quantity: 1896,
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 1896,
                  Revenue_at_risk: "60463€",
                  Penalty_risk: "3,144€",
                  Impact_on_DOS: 0.08,
                  Delivery_CO2_emission_impact: 37.54,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Alternative_options: {
                  Original_Item: "SKU-213",
                  Standard_Cost_per_unit_shipped: "16.13€",
                  Substitute_Item: "SKU-995",
                  Cost_per_unit_shipped: "12.31€",
                  Substitute_Ratio: "1:1",
                  Customer_for_Substitution: "C0001",
                  Location_for_Substitution: 101,
                },
                Procurement_Shipping_Conditions: {
                  Route_Name: "R101C1",
                  Shipping_Location: "Orléans",
                  Receiving_Location: "Lyon",
                  Customer: "C0001",
                  Mode_of_Transport: "Road",
                  Distance: 500,
                  Lead_time_in_days: 1,
                  Cost_per_unit_shipped: 0.51,
                  CO2_emission: 0.09,
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Alternative DC",
                    Reason_Code: "No alternative DC",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                ],
                Customer_Order: {
                  Customer: "C0001",
                  Shipping_Location: 101,
                  Receiving_Location: 301,
                  Ship_Date: "2024-11-12",
                  Delivery_Date: "2024-12-12",
                  Quantity: 1896,
                  line: "4",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Planner Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4grgr58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Customer validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-95862-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf475afe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Partial Delivery",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Partial delivery",
                  Partial_delivery_quantity: 3760,
                  New_delivery_date: "2024-11-13",
                  Delivery_time: "12:00 PM",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 3760,
                  Revenue_at_risk: "105,994€",
                  Penalty_risk: "4,557€",
                  Delivery_delay: 8,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Inventory: [
                  { Date: "2024-11-03", Units: 394114 },
                  { Date: "2024-11-04", Units: 517622 },
                  { Date: "2024-11-05", Units: 378039 },
                  { Date: "2024-11-06", Units: 310736 },
                  { Date: "2024-11-07", Units: 404995 },
                  { Date: "2024-11-08", Units: 435398 },
                  { Date: "2024-11-09", Units: 390844 },
                  { Date: "2024-11-010", Units: 545550 },
                ],
                Procurement_Shipping_Conditions: {
                  Route_Name: "R101C4",
                  Shipping_Location: "Orléans",
                  Receiving_Location: "Valencia",
                  Customer: "C0004",
                  Mode_of_Transport: "Road",
                  Distance: 1200,
                  Lead_time_in_days: 3,
                  Cost_per_unit_shipped: "0.76€",
                  CO2_emission: 0.08,
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Alternative DC",
                    Reason_Code: "No alternative DC",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Substitute",
                    Reason_Code: "No finished goods substitute",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Express Delivery",
                    Reason_Code: "No route for express delivery",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                ],
                Customer_Order: {
                  Customer: "C0004",
                  Shipping_Location: 101,
                  Receiving_Location: 304,
                  Ship_Date: "2024-11-10",
                  Delivery_Date: "2024-11-13",
                  Quantity: 3760,
                  line: "3",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-212599119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Customer validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf8526xafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-dfre-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "cwas9836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Express Delivery",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Express delivery",
                  Alternate_route: "E101C4",
                  Express_delivery_quantity: 9127,
                  New_mode_of_transportation: "Express",
                  New_delivery_lead_time: 1,
                  Delivery_Date: "2024-11-05",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 9127,
                  Revenue_at_risk: "229909.13€",
                  Penalty_risk: "9,886€",
                  Express_delivery_cost: "6,758€",
                  Express_delivery_value_ratio: 0.029,
                  Delivery_CO2_emission_impact: 140.56,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Procurement_Shipping_Conditions: {
                  Route_Name: "E101C4",
                  Shipping_Location: "Orléans",
                  Receiving_Location: "Valencia",
                  Customer: "C0004",
                  Mode_of_Transport: "Express",
                  Distance: 1100,
                  Lead_time_in_days: 1,
                  Cost_per_unit_shipped: "1.23€",
                  CO2_emission: 0.07,
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Alternative DC",
                    Reason_Code: "No alternative DC",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Substitute",
                    Reason_Code: "No finished goods substitute",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                ],
                Customer_Order: {
                  Customer: "C0004",
                  Shipping_Location: 101,
                  Receiving_Location: 304,
                  Ship_Date: "2024-11-05",
                  Delivery_Date: "2024-11-06",
                  Quantity: 9127,
                  line: "3",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebpl859bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "caplok36-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      }
     
     ]
  },
  /** risk 15 partial delivery */
  {
    id: 17,
    risk_conf_name: "Fulfilment Shortage",
    due_date: "2024-11-08 00:00:00",
    copilot: "Procurement	",
    model: "Fulfilment Shortage",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge455645", name: "Action Error" },
      { id: "fgfergger545545645", name: "Execution Error" },
    ],
    status: "WAIT_FOR_ACTION",
    context: {
      Date_of_risk: "2024-11-12",
      Item_Code: "SKU-792",
      Location_code: "Orléans",
      Customer: "C0004",
      SO_Number: "SO-000000039",
      Quantity: "3760",
      Revenue_at_risk: " 105,994€",
      Penalty_risk: "4,557€",
      Delivery_delay: "8",
    },
    impact: "9,886€",

    current_action: {
      action_conf: null,
      action_conf_name: "Partial Delivery",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Fulfillment_option: "Partial delivery",
                Partial_delivery_quantity: 3760,
                New_delivery_date: "2024-11-13",
                Delivery_time: "12:00 PM",
              },

              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Quantity_at_risk: 3760,
                Revenue_at_risk: "105,994€",
                Penalty_risk: "4,557€",
                Delivery_delay: 8,
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              Inventory: [
                { Date: "2024-11-03", Units: 394114 },
                { Date: "2024-11-04", Units: 517622 },
                { Date: "2024-11-05", Units: 378039 },
                { Date: "2024-11-06", Units: 310736 },
                { Date: "2024-11-07", Units: 404995 },
                { Date: "2024-11-08", Units: 435398 },
                { Date: "2024-11-09", Units: 390844 },
                { Date: "2024-11-010", Units: 545550 },
              ],
              Procurement_Shipping_Conditions: {
                Route_Name: "R101C4",
                Shipping_Location: "Orléans",
                Receiving_Location: "Valencia",
                Customer: "C0004",
                Mode_of_Transport: "Road",
                Distance: 1200,
                Lead_time_in_days: 3,
                Cost_per_unit_shipped: "0.76€",
                CO2_emission: 0.08,
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "Alternative DC",
                  Reason_Code: "No alternative DC",
                  Execution_Stage: "Recommendation Validation",
                  User: "Exobrain",
                },
                {
                  Action_Name: "Substitute",
                  Reason_Code: "No finished goods substitute",
                  Execution_Stage: "Recommendation Validation",
                  User: "Exobrain",
                },
                {
                  Action_Name: "Express Delivery",
                  Reason_Code: "No route for express delivery",
                  Execution_Stage: "Recommendation Validation",
                  User: "Exobrain",
                },
              ],
              Customer_Order: {
                Customer: "C0004",
                Shipping_Location: 101,
                Receiving_Location: 304,
                Ship_Date: "2024-11-10",
                Delivery_Date: "2024-11-13",
                Quantity: 3760,
                line: "3",
              },
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-212599119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Customer validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf8526xafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Delivery",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-dfre-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "cwas9836-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    actions:[
      {
        action_conf: null,
        action_conf_name: "Alternative DC",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Alternative DC",
                  Alternate_shipping_DC: 101,
                  Alternate_route: "R101C3",
                  New_mode_of_transportation: "Road",
                  New_delivery_lead_time: 2,
                  Delivery_Date: "2024-11-12",
                },
                OTHER_OPTIONS: [],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Alternative_Source_Description:
                    "Finished Goods Descrip SKU-346 Alternative DC",
                  SKU_Description: "SKU-346",
                  Standard_price_per_unit: "18.91€",
                  Primary_DC: 100,
                  Alternative_DC: 101,
                  Alternative_DC_City: "Orléans",
                  Alternative_Cost_per_unit_shipped: "16.89€",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Procurement_Shipping_Conditions: {
                  Route_Name: "101-Customer 3 Route",
                  Shipping_Location: "Warsaw",
                  Receiving_Location: "Berlin",
                  Customer: "C0003",
                  Mode_of_Transport: "Road",
                  Distance: 1100,
                  Lead_time_in_days: 2,
                  Cost_per_unit_shipped: 0.69,
                  CO2_emission: 0.08,
                },
    
                Customer_Order: {
                  Customer: "C0003",
                  Shipping_Location: 101,
                  Receiving_Location: 303,
                  Ship_Date: "12/4/2024",
                  Delivery_Date: "12/6/2024",
                  Quantity: 950,
                  line: "3",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Planner Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d995b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Customer validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9ba454fe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "968ebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Item substitution",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Item substitution",
                  Substitute_item: "SKU-995",
                  New_delivery_quantity: 1896,
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 1896,
                  Revenue_at_risk: "60463€",
                  Penalty_risk: "3,144€",
                  Impact_on_DOS: 0.08,
                  Delivery_CO2_emission_impact: 37.54,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Alternative_options: {
                  Original_Item: "SKU-213",
                  Standard_Cost_per_unit_shipped: "16.13€",
                  Substitute_Item: "SKU-995",
                  Cost_per_unit_shipped: "12.31€",
                  Substitute_Ratio: "1:1",
                  Customer_for_Substitution: "C0001",
                  Location_for_Substitution: 101,
                },
                Procurement_Shipping_Conditions: {
                  Route_Name: "R101C1",
                  Shipping_Location: "Orléans",
                  Receiving_Location: "Lyon",
                  Customer: "C0001",
                  Mode_of_Transport: "Road",
                  Distance: 500,
                  Lead_time_in_days: 1,
                  Cost_per_unit_shipped: 0.51,
                  CO2_emission: 0.09,
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Alternative DC",
                    Reason_Code: "No alternative DC",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                ],
                Customer_Order: {
                  Customer: "C0001",
                  Shipping_Location: 101,
                  Receiving_Location: 301,
                  Ship_Date: "2024-11-12",
                  Delivery_Date: "2024-12-12",
                  Quantity: 1896,
                  line: "4",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Planner Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4grgr58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Customer validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-95862-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf475afe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Partial Delivery",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Partial delivery",
                  Partial_delivery_quantity: 3760,
                  New_delivery_date: "2024-11-13",
                  Delivery_time: "12:00 PM",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 3760,
                  Revenue_at_risk: "105,994€",
                  Penalty_risk: "4,557€",
                  Delivery_delay: 8,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Inventory: [
                  { Date: "2024-11-03", Units: 394114 },
                  { Date: "2024-11-04", Units: 517622 },
                  { Date: "2024-11-05", Units: 378039 },
                  { Date: "2024-11-06", Units: 310736 },
                  { Date: "2024-11-07", Units: 404995 },
                  { Date: "2024-11-08", Units: 435398 },
                  { Date: "2024-11-09", Units: 390844 },
                  { Date: "2024-11-010", Units: 545550 },
                ],
                Procurement_Shipping_Conditions: {
                  Route_Name: "R101C4",
                  Shipping_Location: "Orléans",
                  Receiving_Location: "Valencia",
                  Customer: "C0004",
                  Mode_of_Transport: "Road",
                  Distance: 1200,
                  Lead_time_in_days: 3,
                  Cost_per_unit_shipped: "0.76€",
                  CO2_emission: 0.08,
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Alternative DC",
                    Reason_Code: "No alternative DC",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Substitute",
                    Reason_Code: "No finished goods substitute",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Express Delivery",
                    Reason_Code: "No route for express delivery",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                ],
                Customer_Order: {
                  Customer: "C0004",
                  Shipping_Location: 101,
                  Receiving_Location: 304,
                  Ship_Date: "2024-11-10",
                  Delivery_Date: "2024-11-13",
                  Quantity: 3760,
                  line: "3",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-212599119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Customer validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf8526xafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-dfre-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "cwas9836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Express Delivery",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Express delivery",
                  Alternate_route: "E101C4",
                  Express_delivery_quantity: 9127,
                  New_mode_of_transportation: "Express",
                  New_delivery_lead_time: 1,
                  Delivery_Date: "2024-11-05",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 9127,
                  Revenue_at_risk: "229909.13€",
                  Penalty_risk: "9,886€",
                  Express_delivery_cost: "6,758€",
                  Express_delivery_value_ratio: 0.029,
                  Delivery_CO2_emission_impact: 140.56,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Procurement_Shipping_Conditions: {
                  Route_Name: "E101C4",
                  Shipping_Location: "Orléans",
                  Receiving_Location: "Valencia",
                  Customer: "C0004",
                  Mode_of_Transport: "Express",
                  Distance: 1100,
                  Lead_time_in_days: 1,
                  Cost_per_unit_shipped: "1.23€",
                  CO2_emission: 0.07,
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Alternative DC",
                    Reason_Code: "No alternative DC",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Substitute",
                    Reason_Code: "No finished goods substitute",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                ],
                Customer_Order: {
                  Customer: "C0004",
                  Shipping_Location: 101,
                  Receiving_Location: 304,
                  Ship_Date: "2024-11-05",
                  Delivery_Date: "2024-11-06",
                  Quantity: 9127,
                  line: "3",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebpl859bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "caplok36-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      }
     
     ]
  },
  /** Risk 6 Express Delivery*/
  {
    id: 6,
    risk_conf_name: "Fulfilment Shortage",
    due_date: "2024-11-01 00:00:00",
    copilot: "Procurement	",
    model: "Fulfilment Shortage",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge455645", name: "Action Error" },
      { id: "fgfergger545545645", name: "Execution Error" },
    ],
    status: "WAIT_FOR_ACTION",
    context: {
      Date_of_risk: "2024-11-05",

      Item_Code: "SKU-213",
      Location_code: "Orléans",
      Customer: "C0004",
      SO_Number: "SO-000000040",
      Quantity: "9127",
      Revenue_at_risk: "229,909€",
      Penalty_risk: "9,886€",
      Express_delivery_cost: " 6,758€",
      Express_delivery_value_ratio: " 0.029",
    },
    impact: "9,886€",

    current_action: {
      action_conf: null,
      action_conf_name: "Express Delivery",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Fulfillment_option: "Express delivery",
                Alternate_route: "E101C4",
                Express_delivery_quantity: 9127,
                New_mode_of_transportation: "Express",
                New_delivery_lead_time: 1,
                Delivery_Date: "2024-11-05",
              },

              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Quantity_at_risk: 9127,
                Revenue_at_risk: "229909.13€",
                Penalty_risk: "9,886€",
                Express_delivery_cost: "6,758€",
                Express_delivery_value_ratio: 0.029,
                Delivery_CO2_emission_impact: 140.56,
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },

              Procurement_Shipping_Conditions: {
                Route_Name: "E101C4",
                Shipping_Location: "Orléans",
                Receiving_Location: "Valencia",
                Customer: "C0004",
                Mode_of_Transport: "Express",
                Distance: 1100,
                Lead_time_in_days: 1,
                Cost_per_unit_shipped: "1.23€",
                CO2_emission: 0.07,
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "Alternative DC",
                  Reason_Code: "No alternative DC",
                  Execution_Stage: "Recommendation Validation",
                  User: "Exobrain",
                },
                {
                  Action_Name: "Substitute",
                  Reason_Code: "No finished goods substitute",
                  Execution_Stage: "Recommendation Validation",
                  User: "Exobrain",
                },
              ],
              Customer_Order: {
                Customer: "C0004",
                Shipping_Location: 101,
                Receiving_Location: 304,
                Ship_Date: "2024-11-05",
                Delivery_Date: "2024-11-06",
                Quantity: 9127,
                line: "3",
              },
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Delivery",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebpl859bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "caplok36-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    actions:[
      {
        action_conf: null,
        action_conf_name: "Alternative DC",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Alternative DC",
                  Alternate_shipping_DC: 101,
                  Alternate_route: "R101C3",
                  New_mode_of_transportation: "Road",
                  New_delivery_lead_time: 2,
                  Delivery_Date: "2024-11-12",
                },
                OTHER_OPTIONS: [],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Alternative_Source_Description:
                    "Finished Goods Descrip SKU-346 Alternative DC",
                  SKU_Description: "SKU-346",
                  Standard_price_per_unit: "18.91€",
                  Primary_DC: 100,
                  Alternative_DC: 101,
                  Alternative_DC_City: "Orléans",
                  Alternative_Cost_per_unit_shipped: "16.89€",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Procurement_Shipping_Conditions: {
                  Route_Name: "101-Customer 3 Route",
                  Shipping_Location: "Warsaw",
                  Receiving_Location: "Berlin",
                  Customer: "C0003",
                  Mode_of_Transport: "Road",
                  Distance: 1100,
                  Lead_time_in_days: 2,
                  Cost_per_unit_shipped: 0.69,
                  CO2_emission: 0.08,
                },
    
                Customer_Order: {
                  Customer: "C0003",
                  Shipping_Location: 101,
                  Receiving_Location: 303,
                  Ship_Date: "12/4/2024",
                  Delivery_Date: "12/6/2024",
                  Quantity: 950,
                  line: "3",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Planner Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d995b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Customer validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9ba454fe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "968ebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Item substitution",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Item substitution",
                  Substitute_item: "SKU-995",
                  New_delivery_quantity: 1896,
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 1896,
                  Revenue_at_risk: "60463€",
                  Penalty_risk: "3,144€",
                  Impact_on_DOS: 0.08,
                  Delivery_CO2_emission_impact: 37.54,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Alternative_options: {
                  Original_Item: "SKU-213",
                  Standard_Cost_per_unit_shipped: "16.13€",
                  Substitute_Item: "SKU-995",
                  Cost_per_unit_shipped: "12.31€",
                  Substitute_Ratio: "1:1",
                  Customer_for_Substitution: "C0001",
                  Location_for_Substitution: 101,
                },
                Procurement_Shipping_Conditions: {
                  Route_Name: "R101C1",
                  Shipping_Location: "Orléans",
                  Receiving_Location: "Lyon",
                  Customer: "C0001",
                  Mode_of_Transport: "Road",
                  Distance: 500,
                  Lead_time_in_days: 1,
                  Cost_per_unit_shipped: 0.51,
                  CO2_emission: 0.09,
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Alternative DC",
                    Reason_Code: "No alternative DC",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                ],
                Customer_Order: {
                  Customer: "C0001",
                  Shipping_Location: 101,
                  Receiving_Location: 301,
                  Ship_Date: "2024-11-12",
                  Delivery_Date: "2024-12-12",
                  Quantity: 1896,
                  line: "4",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Planner Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4grgr58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Customer validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-95862-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf475afe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Partial Delivery",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Partial delivery",
                  Partial_delivery_quantity: 3760,
                  New_delivery_date: "2024-11-13",
                  Delivery_time: "12:00 PM",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 3760,
                  Revenue_at_risk: "105,994€",
                  Penalty_risk: "4,557€",
                  Delivery_delay: 8,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Inventory: [
                  { Date: "2024-11-03", Units: 394114 },
                  { Date: "2024-11-04", Units: 517622 },
                  { Date: "2024-11-05", Units: 378039 },
                  { Date: "2024-11-06", Units: 310736 },
                  { Date: "2024-11-07", Units: 404995 },
                  { Date: "2024-11-08", Units: 435398 },
                  { Date: "2024-11-09", Units: 390844 },
                  { Date: "2024-11-010", Units: 545550 },
                ],
                Procurement_Shipping_Conditions: {
                  Route_Name: "R101C4",
                  Shipping_Location: "Orléans",
                  Receiving_Location: "Valencia",
                  Customer: "C0004",
                  Mode_of_Transport: "Road",
                  Distance: 1200,
                  Lead_time_in_days: 3,
                  Cost_per_unit_shipped: "0.76€",
                  CO2_emission: 0.08,
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Alternative DC",
                    Reason_Code: "No alternative DC",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Substitute",
                    Reason_Code: "No finished goods substitute",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Express Delivery",
                    Reason_Code: "No route for express delivery",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                ],
                Customer_Order: {
                  Customer: "C0004",
                  Shipping_Location: 101,
                  Receiving_Location: 304,
                  Ship_Date: "2024-11-10",
                  Delivery_Date: "2024-11-13",
                  Quantity: 3760,
                  line: "3",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-212599119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Customer validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf8526xafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-dfre-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "cwas9836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Express Delivery",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Fulfillment_option: "Express delivery",
                  Alternate_route: "E101C4",
                  Express_delivery_quantity: 9127,
                  New_mode_of_transportation: "Express",
                  New_delivery_lead_time: 1,
                  Delivery_Date: "2024-11-05",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Quantity_at_risk: 9127,
                  Revenue_at_risk: "229909.13€",
                  Penalty_risk: "9,886€",
                  Express_delivery_cost: "6,758€",
                  Express_delivery_value_ratio: 0.029,
                  Delivery_CO2_emission_impact: 140.56,
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Procurement_Shipping_Conditions: {
                  Route_Name: "E101C4",
                  Shipping_Location: "Orléans",
                  Receiving_Location: "Valencia",
                  Customer: "C0004",
                  Mode_of_Transport: "Express",
                  Distance: 1100,
                  Lead_time_in_days: 1,
                  Cost_per_unit_shipped: "1.23€",
                  CO2_emission: 0.07,
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Alternative DC",
                    Reason_Code: "No alternative DC",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Substitute",
                    Reason_Code: "No finished goods substitute",
                    Execution_Stage: "Recommendation Validation",
                    User: "Exobrain",
                  },
                ],
                Customer_Order: {
                  Customer: "C0004",
                  Shipping_Location: 101,
                  Receiving_Location: 304,
                  Ship_Date: "2024-11-05",
                  Delivery_Date: "2024-11-06",
                  Quantity: 9127,
                  line: "3",
                },
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Delivery",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebpl859bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "caplok36-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      }
     
     ]
  },
];

export const Fulfillement_Start_Risk = {
  id: 1,
  risk_conf_name: "Fulfilment Shortage",
  due_date: "2024-11-08 00:00:00",
  copilot: "Procurement",
  model: "Fulfilment Shortage",
  reason: [
    { id: "fgfergger545645", name: "Missing Data" },
    { id: "fgfergge25255645", name: "Action Error" },
    { id: "fgfergger25255645", name: "Execution Error" },
  ],
  status: "WAIT_FOR_ACTION",
  context: {
    Date_of_risk: "2024-11-12",
    Item_Code: "SKU-213",
    Location_code: "Orléans",
    Customer: "C0001",
    SO_Number: "SO-000000036",
    Quantity: "1896",
    Revenue_at_risk: "60,463€",
    Penalty_risk: "3,144€",
    Impact_on_DOS: "0.08",
  },
  impact: "3,144€",
  current_action: {
    action_conf: null,
    action_conf_name: "Item substitution",
    action_conf_policy: {},
    action_conf_priority: 1,
    action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
    action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
    contexts: [
      {
        computed: false,
        created_at: "2024-08-14T17:25:07.443005+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        data: {
          DATA: {
            ACTION_DETAILS: {
              Fulfillment_option: "Item substitution",
              Substitute_item: "SKU-995",
              New_delivery_quantity: 1896,
            },

            ERRORS: {
              ERROR: [
                {
                  CODE: "MISSING_DATA",
                  DETAILS: {
                    DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                  },
                  MODULE: "Exobrain-actions",
                },
                {
                  CODE: "MISSING_DATA",
                  DETAILS: {
                    DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                  },
                  MODULE: "Exobrain-actions",
                },
              ],
              WARNING: [
                {
                  CODE: "UNPROCESSABLE_DATA",
                  DETAILS: {
                    DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                  },
                  MODULE: "Exobrain-actions",
                },
                {
                  CODE: "UNPROCESSABLE_DATA",
                  DETAILS: {
                    DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                  },
                  MODULE: "Exobrain-actions",
                },
              ],
            },
            IMPACT: {
              Quantity_at_risk: 1896,
              Revenue_at_risk: "60463€",
              Penalty_risk: "3,144€",
              Impact_on_DOS: 0.08,
              Delivery_CO2_emission_impact: 37.54,
            },
            MASTER: {
              ITEM_MASTER: [
                {
                  ITEM_CODE: "VGPSLWNL003",
                  ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                  ITEM_TYPE: "F",
                  UNIT_PER_PALLET: null,
                  WEIGHT_PER_UNIT_IN_KG: null,
                },
              ],
              LOCATION_ITEM_MASTER: [
                {
                  DAYS_OF_SUPPLY_THRESHOLD: null,
                  ITEM_CODE: "VGPSLWNL003",
                  LOCATION_CODE: "264",
                  STANDARD_COST_PER_UNIT: null,
                },
              ],
              LOCATION_MASTER: [
                {
                  APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                  LOCATION_CITY: "JEDDAH",
                  LOCATION_CONTACT_NAME: null,
                  LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                  LOCATION_DESCRIPTION: "JEDDAH PORT",
                  LOCATION_ID: "JEDDAH PORT",
                  LOCATION_PRIMARY_LANGUAGE: null,
                  LOCATION_TYPE: null,
                  LOCATION_ZIP_CODE: null,
                },
                {
                  APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                  LOCATION_CITY: "   RIYADH",
                  LOCATION_CONTACT_NAME: null,
                  LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                  LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                  LOCATION_ID: "264",
                  LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                  LOCATION_TYPE: "BRANCH",
                  LOCATION_ZIP_CODE: null,
                },
              ],
            },
            Alternative_options: {
              Original_Item: "SKU-213",
              Standard_Cost_per_unit_shipped: "16.13€",
              Substitute_Item: "SKU-995",
              Cost_per_unit_shipped: "12.31€",
              Substitute_Ratio: "1:1",
              Customer_for_Substitution: "C0001",
              Location_for_Substitution: 101,
            },
            Procurement_Shipping_Conditions: {
              Route_Name: "R101C1",
              Shipping_Location: "Orléans",
              Receiving_Location: "Lyon",
              Customer: "C0001",
              Mode_of_Transport: "Road",
              Distance: 500,
              Lead_time_in_days: 1,
              Cost_per_unit_shipped: 0.51,
              CO2_emission: 0.09,
            },
            OTHER_OPTIONS: [
              {
                Action_Name: "Alternative DC",
                Reason_Code: "No alternative DC",
                Execution_Stage: "Recommendation Validation",
                User: "Exobrain",
              },
            ],
            Customer_Order: {
              Customer: "C0001",
              Shipping_Location: 101,
              Receiving_Location: 301,
              Ship_Date: "2024-11-12",
              Delivery_Date: "2024-12-12",
              Quantity: 1896,
              line: "4",
            },
          },
        },
        decision_time: "2024-06-11T12:57:28.367528+00:00",
        id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
        inputs_data: null,
        is_deleted: false,
        note: "",
        outputs_data: null,
        reason: null,
        reason_comment: "",
        role: "",

        updated_at: "2024-09-03T12:57:28.367542+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
        user: {
          id: "602d5335-2be1-485f-a694-313d9428fafa",
        },
      },
    ],
    created_at: "2024-08-14T17:25:07.456366+00:00",
    created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
    current_execution: {
      action: {
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
      },
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.384630+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {},
          decision_time: null,
          id: "69fd7354-d60b-41bc-aa96-56515831adb3",
          inputs_data: {},
          is_deleted: false,
          note: "",
          outputs_data: {},
          reason: null,
          reason_comment: "",
          role: "",
          updated_at: "2024-08-14T17:25:07.384634+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          user: {
            id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.393646+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      execution_conf: null,
      execution_conf_name: "Truck Booking",
      execution_conf_policy: {
        automation: [],
      },
      execution_conf_priority: 1,
      execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
      execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
      id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
      is_deleted: false,
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      status: "OPEN",
      updated_at: "2024-08-14T17:25:07.393649+00:00",
      updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
    },
    executions: [
      {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.413239+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.413244+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.422537+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Planner Validation",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
        execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
        id: "4hylopj5b58-95c3-41ab-86e9-df259b36e9ca",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
          },
          {
            id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.422541+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Customer validation",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 2,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "e985hyyu6afe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Delivery",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 3,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "eplorfe-b469992-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
    ],
    feasible: true,
    id: "crrra0745136-804b-4718-8ac1-ca1705a5c8e9",
    is_deleted: false,
    risk: {
      id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
    },
    roles: [
      {
        id: "746fd295-827b-4568-9933-1f193cf8f13d",
      },
      {
        id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
      },
    ],
    // "status": "WAIT_FOR_EXECUTION",
    status: "OPEN",
    updated_at: "2024-09-03T12:57:28.379118+00:00",
    updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
  },
  actions:[
    {
      action_conf: null,
      action_conf_name: "Alternative DC",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Fulfillment_option: "Alternative DC",
                Alternate_shipping_DC: 101,
                Alternate_route: "R101C3",
                New_mode_of_transportation: "Road",
                New_delivery_lead_time: 2,
                Delivery_Date: "2024-11-12",
              },
              OTHER_OPTIONS: [],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Alternative_Source_Description:
                  "Finished Goods Descrip SKU-346 Alternative DC",
                SKU_Description: "SKU-346",
                Standard_price_per_unit: "18.91€",
                Primary_DC: 100,
                Alternative_DC: 101,
                Alternative_DC_City: "Orléans",
                Alternative_Cost_per_unit_shipped: "16.89€",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
  
              Procurement_Shipping_Conditions: {
                Route_Name: "101-Customer 3 Route",
                Shipping_Location: "Warsaw",
                Receiving_Location: "Berlin",
                Customer: "C0003",
                Mode_of_Transport: "Road",
                Distance: 1100,
                Lead_time_in_days: 2,
                Cost_per_unit_shipped: 0.69,
                CO2_emission: 0.08,
              },
  
              Customer_Order: {
                Customer: "C0003",
                Shipping_Location: 101,
                Receiving_Location: 303,
                Ship_Date: "12/4/2024",
                Delivery_Date: "12/6/2024",
                Quantity: 950,
                line: "3",
              },
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Planner Validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c7d995b58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Customer validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9ba454fe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Delivery",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "968ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    {
      action_conf: null,
      action_conf_name: "Item substitution",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Fulfillment_option: "Item substitution",
                Substitute_item: "SKU-995",
                New_delivery_quantity: 1896,
              },
  
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Quantity_at_risk: 1896,
                Revenue_at_risk: "60463€",
                Penalty_risk: "3,144€",
                Impact_on_DOS: 0.08,
                Delivery_CO2_emission_impact: 37.54,
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              Alternative_options: {
                Original_Item: "SKU-213",
                Standard_Cost_per_unit_shipped: "16.13€",
                Substitute_Item: "SKU-995",
                Cost_per_unit_shipped: "12.31€",
                Substitute_Ratio: "1:1",
                Customer_for_Substitution: "C0001",
                Location_for_Substitution: 101,
              },
              Procurement_Shipping_Conditions: {
                Route_Name: "R101C1",
                Shipping_Location: "Orléans",
                Receiving_Location: "Lyon",
                Customer: "C0001",
                Mode_of_Transport: "Road",
                Distance: 500,
                Lead_time_in_days: 1,
                Cost_per_unit_shipped: 0.51,
                CO2_emission: 0.09,
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "Alternative DC",
                  Reason_Code: "No alternative DC",
                  Execution_Stage: "Recommendation Validation",
                  User: "Exobrain",
                },
              ],
              Customer_Order: {
                Customer: "C0001",
                Shipping_Location: 101,
                Receiving_Location: 301,
                Ship_Date: "2024-11-12",
                Delivery_Date: "2024-12-12",
                Quantity: 1896,
                line: "4",
              },
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Planner Validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4grgr58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Customer validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-95862-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Delivery",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf475afe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    {
      action_conf: null,
      action_conf_name: "Partial Delivery",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Fulfillment_option: "Partial delivery",
                Partial_delivery_quantity: 3760,
                New_delivery_date: "2024-11-13",
                Delivery_time: "12:00 PM",
              },
  
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Quantity_at_risk: 3760,
                Revenue_at_risk: "105,994€",
                Penalty_risk: "4,557€",
                Delivery_delay: 8,
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              Inventory: [
                { Date: "2024-11-03", Units: 394114 },
                { Date: "2024-11-04", Units: 517622 },
                { Date: "2024-11-05", Units: 378039 },
                { Date: "2024-11-06", Units: 310736 },
                { Date: "2024-11-07", Units: 404995 },
                { Date: "2024-11-08", Units: 435398 },
                { Date: "2024-11-09", Units: 390844 },
                { Date: "2024-11-010", Units: 545550 },
              ],
              Procurement_Shipping_Conditions: {
                Route_Name: "R101C4",
                Shipping_Location: "Orléans",
                Receiving_Location: "Valencia",
                Customer: "C0004",
                Mode_of_Transport: "Road",
                Distance: 1200,
                Lead_time_in_days: 3,
                Cost_per_unit_shipped: "0.76€",
                CO2_emission: 0.08,
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "Alternative DC",
                  Reason_Code: "No alternative DC",
                  Execution_Stage: "Recommendation Validation",
                  User: "Exobrain",
                },
                {
                  Action_Name: "Substitute",
                  Reason_Code: "No finished goods substitute",
                  Execution_Stage: "Recommendation Validation",
                  User: "Exobrain",
                },
                {
                  Action_Name: "Express Delivery",
                  Reason_Code: "No route for express delivery",
                  Execution_Stage: "Recommendation Validation",
                  User: "Exobrain",
                },
              ],
              Customer_Order: {
                Customer: "C0004",
                Shipping_Location: 101,
                Receiving_Location: 304,
                Ship_Date: "2024-11-10",
                Delivery_Date: "2024-11-13",
                Quantity: 3760,
                line: "3",
              },
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-212599119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Customer validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf8526xafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Delivery",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-dfre-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "cwas9836-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    {
      action_conf: null,
      action_conf_name: "Express Delivery",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Fulfillment_option: "Express delivery",
                Alternate_route: "E101C4",
                Express_delivery_quantity: 9127,
                New_mode_of_transportation: "Express",
                New_delivery_lead_time: 1,
                Delivery_Date: "2024-11-05",
              },
  
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Quantity_at_risk: 9127,
                Revenue_at_risk: "229909.13€",
                Penalty_risk: "9,886€",
                Express_delivery_cost: "6,758€",
                Express_delivery_value_ratio: 0.029,
                Delivery_CO2_emission_impact: 140.56,
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
  
              Procurement_Shipping_Conditions: {
                Route_Name: "E101C4",
                Shipping_Location: "Orléans",
                Receiving_Location: "Valencia",
                Customer: "C0004",
                Mode_of_Transport: "Express",
                Distance: 1100,
                Lead_time_in_days: 1,
                Cost_per_unit_shipped: "1.23€",
                CO2_emission: 0.07,
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "Alternative DC",
                  Reason_Code: "No alternative DC",
                  Execution_Stage: "Recommendation Validation",
                  User: "Exobrain",
                },
                {
                  Action_Name: "Substitute",
                  Reason_Code: "No finished goods substitute",
                  Execution_Stage: "Recommendation Validation",
                  User: "Exobrain",
                },
              ],
              Customer_Order: {
                Customer: "C0004",
                Shipping_Location: 101,
                Receiving_Location: 304,
                Ship_Date: "2024-11-05",
                Delivery_Date: "2024-11-06",
                Quantity: 9127,
                line: "3",
              },
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Delivery",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebpl859bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "caplok36-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    }
   
   ]
};

export const Fulfillment_reasons = [
  { id: "customer request", name: "customer request" },
  { id: "ship in full", name: "ship in full" },
  { id: "delivery cost budget", name: "delivery cost budget" },
  {
    id: "preparation capacity constraint",
    name: "preparation capacity constraint",
  },
];




