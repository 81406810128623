import { DATA_NEEDED_TYPE as DN_TYPE } from "../../../../../../constants/DataNeededTypes";
import i18n from "../../../../../../parameters/i18n";

export const ACCEPT = "ACCEPT";
export const REJECT = "REJECT";
export const AND = "AND";
export const OR = "OR";

export const ruleActionOptions = [
  { value: ACCEPT, label: i18n.t("ACCEPT") },
  { value: REJECT, label: i18n.t("REJECT") },
];

export const ruleOperationOptions = [
  {
    label: i18n.t("AUTOMATION_EQUAL"),
    value: "=",
    types: [
      DN_TYPE.STRING,
      DN_TYPE.NUMBER,
      DN_TYPE.BOOLEAN,
      DN_TYPE.DATE,
      DN_TYPE.DATETIME,
      DN_TYPE.AMOUNT,
    ],
  },
  {
    label: i18n.t("AUTOMATION_DIFFERENT_THAN"),
    value: "!=",
    types: [
      DN_TYPE.STRING,
      DN_TYPE.NUMBER,
      DN_TYPE.DATE,
      DN_TYPE.DATETIME,
      DN_TYPE.AMOUNT,
    ],
  },
  {
    label: i18n.t("AUTOMATION_GREATER_THAN"),
    value: ">",
    types: [DN_TYPE.NUMBER, DN_TYPE.DATE, DN_TYPE.DATETIME, DN_TYPE.AMOUNT],
  },
  {
    label: i18n.t("AUTOMATION_GREATER_OR_EQUAL"),
    value: ">=",
    types: [DN_TYPE.NUMBER, DN_TYPE.DATE, DN_TYPE.DATETIME, DN_TYPE.AMOUNT],
  },
  {
    label: i18n.t("AUTOMATION_LOWER_THAN"),
    value: "<",
    types: [DN_TYPE.NUMBER, DN_TYPE.DATE, DN_TYPE.DATETIME, DN_TYPE.AMOUNT],
  },
  {
    label: i18n.t("AUTOMATION_LOWER_OR_EQUAL"),
    value: "<=",
    types: [DN_TYPE.NUMBER, DN_TYPE.DATE, DN_TYPE.DATETIME, DN_TYPE.AMOUNT],
  },
  {
    label: i18n.t("AUTOMATION_CONTAINS"),
    value: "HAS",
    types: [DN_TYPE.STRING],
  },
  {
    label: i18n.t("AUTOMATION_DOES_NOT_CONTAIN"),
    value: "NOT_HAS",
    types: [DN_TYPE.STRING],
  },
];
