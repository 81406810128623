import { ReloadOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, List, MenuProps, Spin, Tooltip } from "antd";
import { VscOpenPreview } from "react-icons/vsc";
import { BiMessageSquareError } from "react-icons/bi";
import { RisksItem } from "../../../../interfaces/risks";
import { createKey, getTranslation } from "../../../../utils/transaltion";
import SortingMenu from "../menus/SortingMenu";
import { useContext, useRef, useState } from "react";
import { SharedContext } from "../context/SharedContext";
import { useSelector } from "react-redux";
import { Sortingtypes } from "../../../../constants/sortingTypes";
import { RootState } from "../../../../redux/store/store";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../logic/runningShared.logic";
import React from "react";
import VirtualList from "rc-virtual-list";
import { getCopilotId } from "../../../../utils/functions";
import { FilterSection } from "./filters/FilterSection";
import { RunningHistoryVirtualListTitle } from "./risk-history/RunningHistoryVirtualListTitle";
import { RunningHistoryVirtualListDescription } from "./risk-history/RunningHistoryVirtualListDescription";
import { RUNNING_MENU } from "../../../../constants/riskMenus";
import { RunningOpenActionsVirtualListDescription } from "./open-actions/RunningOpenActionsVirtualListDescription";
import { RunningOpenActionsVirtualListTitle } from "./open-actions/RunningOpenActionsVirtualListTitle";
import { isPermission } from "../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../constants/permissions";
import { RISKS_SCOPES } from "../../../../constants/risksScopes";
import { UserCirclePlusBlue, Users } from "../../../../assets/images";
import { t } from "i18next";

export const RunningsVirtualList = (props: any) => {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );

  const {
    copilotName,
    menu,
    currentActionId,
    appendData,
    handleRowClick,
    isFirstLoad,
    filterparams,
    setFilterparams,
    setSortingOrder,
    sortingOrder,
    setAcceptRejectState,
    setLoading,
    setSortingField,
    sortingField,
    setIsSecondColumnOpen,
    isSecondColumnOpen,
  } = props;

  const [sortingMenuActive, setSortingMenuActive] = useState<boolean>(false);
  const [expandedRowAction, setExpandedRowAction] = useState<number | null>(
    null
  );
  const [getRiskLoading, setGetRiskLoading] = useState<boolean>(false);
  const [reloadState, setReloadState] = useState<boolean>(false);

  const formRef = useRef<any>();
  const ContainerHeight = window.innerHeight - 40;
  const {
    totalRunningRisk,
    getLoading,
    setCurrentAction,
    runningRisks,
    selectedRisk,
    setSelectedRisk,
    riskScope,
    setRiskScope,
  } = useContext(SharedContext);
  const { extractPositionAttributes, getCurrentActionFormatUI } =
    useSharedContextFunctions() as SharedContextFunctions;

  /** get copilots from redux */
  const copilotList = useSelector((state: RootState) => state.copilotReducer);

  const idCopilot = getCopilotId(
    copilotList,
    createKey(copilotName)
  )?.toString();

  /** column def */
  const columnsList = extractPositionAttributes(idCopilot)?.map(
    (item, index) => ({
      key: index,
      name: item.name,
    })
  );
  const handleSort = (fieldKey: string, sortOrder: Sortingtypes) => {
    setSortingOrder(sortOrder);
    setSortingField("CONTEXT." + fieldKey);
  };

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      Math.abs(
        e.currentTarget.scrollHeight -
          e.currentTarget.scrollTop -
          ContainerHeight
      ) <= 1 &&
      runningRisks.length < totalRunningRisk
    ) {
      appendData(runningRisks, filterparams);
    }
  };

  const onChangeScopeMenu = (value: string) => {
    setRiskScope(value);
  };

  const items: MenuProps["items"] = [
    {
      key: 1,
      label: <span>{t("USER_SCOPE")}</span>,
      icon: <Users style={{ color: "#004c97" }} />,
      onClick: () => {
        onChangeScopeMenu(RISKS_SCOPES.USER_SCOPE);
      },
      style:
        riskScope === RISKS_SCOPES.USER_SCOPE
          ? { color: "#1677ff" }
          : { color: "#000000" },
    },
    {
      key: 2,
      label: <span>{t("ADMIN_SCOPE")}</span>,
      icon: <UserCirclePlusBlue style={{ color: "#004c97" }} />,
      onClick: () => {
        onChangeScopeMenu(RISKS_SCOPES.ADMIN_SCOPE);
      },
      style:
        riskScope === RISKS_SCOPES.ADMIN_SCOPE
          ? { color: "#1677ff" }
          : { color: "#000000" },
    },
  ];

  if (menu === RUNNING_MENU.RISKS_HISTORY) {
    items.push({
      key: 3,
      label: <span>{t("ERROR_SCOPE")}</span>,
      icon: (
        <BiMessageSquareError
          style={{ color: "#004c97", height: 28, width: 32 }}
        />
      ),
      onClick: () => {
        onChangeScopeMenu(RISKS_SCOPES.ERRONEOUS_SCOPE);
      },
      style:
        riskScope === RISKS_SCOPES.ERRONEOUS_SCOPE
          ? { color: "#1677ff" }
          : { color: "#000000" },
    });
  }

  return (
    <>
      {" "}
      <Spin spinning={getLoading} size="large" tip="Loading...">
        {" "}
        <List
          header={
            <div className="header-content">
              <div className="header-title">
                <h4>
                  {menu !== RUNNING_MENU.RISKS_HISTORY
                    ? getTranslation("OPEN_ACTIONS", "labels")
                    : getTranslation("RISK_HISTORY", "labels")}{" "}
                </h4>
              </div>
              <div className="header-buttons">
                {isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) && (
                  <Dropdown menu={{ items }} placement="bottomRight">
                    <Button
                      icon={
                        <Tooltip
                          title={getTranslation("EDIT_FILTER_SCOPE", "labels")}
                        >
                          <VscOpenPreview />
                        </Tooltip>
                      }
                    ></Button>
                  </Dropdown>
                )}
                <FilterSection
                  setIsSecondColumnOpen={setIsSecondColumnOpen}
                  setExpandedRowAction={setExpandedRowAction}
                  reloadState={reloadState}
                  setReloadState={setReloadState}
                  copilotName={copilotName}
                  menu={menu}
                  appendData={appendData}
                  isFirstLoad={isFirstLoad}
                  filterParams={filterparams}
                  setFilterparams={setFilterparams}
                  setSortingOrder={setSortingOrder}
                  sortingOrder={sortingOrder}
                  setSortingField={setSortingField}
                  sortingField={sortingField}
                  setSortingMenuActive={setSortingMenuActive}
                  formRef={formRef}
                />
                {/* reload button */}
                <Button
                  onClick={() => {
                    setReloadState(true);
                    setFilterparams({});
                    setSortingOrder("desc");
                    setSortingField("created_at");
                    setSortingMenuActive(false);
                    appendData([], {});
                    // Update the URL
                    const currentUrl = window.location.href;
                    const baseUrl = currentUrl.split("?")[0];
                    window.history.replaceState({}, "", baseUrl);
                    if (menu === RUNNING_MENU.RISKS_HISTORY) {
                      setIsSecondColumnOpen(false);
                      setExpandedRowAction(null);
                    }
                  }}
                  icon={
                    <Tooltip title={getTranslation("RELOAD", "labels")}>
                      <ReloadOutlined />
                    </Tooltip>
                  }
                  shape="default"
                />
                {/* sorting button */}
                <Button
                  icon={
                    <SortingMenu
                      key="sorting-menu"
                      fields={columnsList}
                      onSort={handleSort}
                      sortingMenuActive={sortingMenuActive}
                      setSortingMenuActive={setSortingMenuActive}
                    />
                  }
                  shape="default"
                />
                {/* total badge */}
                <Badge
                  count={totalRunningRisk}
                  style={{
                    backgroundColor: "#004c97",
                    color: "white",
                    right: 0,
                    marginInlineStart: 10,
                    marginBottom: 4,
                    top: 0,
                  }}
                ></Badge>
              </div>
            </div>
          }
          bordered
          style={{
            borderRadius: "0px",
            background: "#fff",
            flex: 1,
          }}
        >
          {/* risk list */}
          <VirtualList
            data={runningRisks}
            height={ContainerHeight}
            // itemHeight={20}
            itemKey="id"
            onScroll={onScroll}
          >
            {(item: RisksItem, index: any) => (
              <List.Item
                key={item.id}
                className={
                  item.id === selectedRisk?.id
                    ? "run-card-selected"
                    : "run-card"
                }
                onClick={() => {
                  if (menu === RUNNING_MENU?.OPEN_ACTIONS) {
                    setIsSecondColumnOpen(true);
                  }
                  if (
                    item.id !== selectedRisk?.id &&
                    menu !== RUNNING_MENU?.OPEN_ACTIONS
                  ) {
                    setExpandedRowAction(null);
                    setIsSecondColumnOpen(false);
                  }
                  if (item.id !== selectedRisk?.id) {
                    setAcceptRejectState(false);
                    setLoading(true);
                    setCurrentAction(null);
                    getCurrentActionFormatUI(item?.id);
                    setSelectedRisk(item);
                  }
                }}
              >
                <List.Item.Meta
                  title={
                    menu === RUNNING_MENU.RISKS_HISTORY ? (
                      <RunningHistoryVirtualListTitle
                        copilotName={copilotName}
                        menu={menu}
                        handleRowClick={handleRowClick}
                        expandedRowAction={expandedRowAction}
                        item={item}
                        setGetRiskLoading={setGetRiskLoading}
                        setExpandedRowAction={setExpandedRowAction}
                        index={index}
                      />
                    ) : (
                      <RunningOpenActionsVirtualListTitle
                        copilotName={copilotName}
                        menu={menu}
                        item={item}
                        setAcceptRejectState={setAcceptRejectState}
                      />
                    )
                  }
                  //   risk details ( columns)
                  description={
                    menu === RUNNING_MENU.RISKS_HISTORY ? (
                      <RunningHistoryVirtualListDescription
                        isSecondColumnOpen={isSecondColumnOpen}
                        menu={menu}
                        item={item}
                        index={index}
                        expandedRowAction={expandedRowAction}
                        getRiskLoading={getRiskLoading}
                        handleRowClick={handleRowClick}
                        currentActionId={currentActionId}
                        setExpandedRowAction={setExpandedRowAction}
                        setGetRiskLoading={setGetRiskLoading}
                      />
                    ) : (
                      <RunningOpenActionsVirtualListDescription item={item} />
                    )
                  }
                />
              </List.Item>
            )}
          </VirtualList>
        </List>
      </Spin>
    </>
  );
};
