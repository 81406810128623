import { STATUS } from "../constants/status";
import { Permission } from "../interfaces/permissions";
import { Role } from "../interfaces/role";
import { addPermission } from "../redux/reducers/permissions.Reducer";
import { RUNNING_MENU } from "../constants/riskMenus";

export const setUserPermissions = (
  permissions: Permission[],
  userRoles: Role[],
  dispatch: any
) => {
  permissions.forEach((permission) => {
    permission.roles.forEach((role) => {
      //check if at leat one role in the userRoles matches the permission roles
      if (userRoles.some((userRole) => userRole.id === role.id)) {
        // Dispatch permission to add to Redux
        dispatch(addPermission(permission));
      }
    });
  });
};

export const isPermission = (
  permissionsList: Permission[],
  permissionToBeChecked: string
): boolean => {
  return permissionsList.some(
    (permission) => permission.name === permissionToBeChecked
  );
};

export const getPermissionShowAcceptReject = (
  currentActionId: string,
  clickedAction: any,
  userRoles: any,
  menu: string
): boolean => {
  if (menu !== RUNNING_MENU.RISKS_HISTORY) {
    return true;
  } else {
    if (clickedAction?.status === STATUS.OPEN) {
      // chck if the clicked action is the current action in the risk
      if (currentActionId === clickedAction?.id) {
        // check for in progress current action roles
        const hasRole: any[] = clickedAction?.roles?.map((item: any) =>
          userRoles.some((obj: any) => obj.id === item.id)
        );
        return hasRole?.includes(true);
      } else {
        return false;
      }
    } else {
      if (clickedAction?.status === STATUS.WAIT_FOR_EXECUTION) {
        //check for current execution roles
        const hasRole: any[] = clickedAction?.current_execution?.roles?.map(
          (item: any) => userRoles.some((obj: any) => obj.id === item.id)
        );
        return hasRole?.includes(true);
      }
    }
  }

  return false;
};
