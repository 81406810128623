import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";

const resource = { name: "roles", isPublic: true };

class RolesService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(resource, context, setContext, path);
  }
}

export default RolesService;
