import React, { useState } from "react";
import { Button, Col, Form, Row, Space, message } from "antd";
import { t } from "i18next";

import { RuleField } from "../../constants/rule";
import ExpressionForm from "./ExpressionForm";

export default function ChartFilterModal(props: any) {
  const {
    filterField,
    closeChartFilterModal,
    dataNeeded,
    fieldType,
    onChangeFilterField,
  } = props;

  console.log(filterField);

  const [rulesExpr, setRulesExpr] = useState<RuleField>(
    JSON.parse(
      JSON.stringify(
        filterField?.filter || {
          operator: "",
          field: filterField?.field || "",
          value: "",
        }
      )
    )
  );

  const onFinish = (values: any) => {
    form_execution
      .validateFields()
      .then(() => {
        console.log(rulesExpr);
        onChangeFilterField({ filter: rulesExpr });
        closeChartFilterModal();
      })
      .catch((err) => {
        console.log(err);
        message.warning(t("errors:REQUIRED_FIELDS"));
      });
  };

  const [form_execution] = Form.useForm();
  return (
    <>
      <Form
        name="wrap"
        form={form_execution}
        labelAlign="left"
        labelWrap
        style={{ marginInlineStart: "20px" }}
      >
        <>
          <Row>
            <Form.Item label={"Field Name"}>
              <span>{filterField?.field}</span>
            </Form.Item>
          </Row>
          {/* Expressions */}
          <ExpressionForm
            data={rulesExpr}
            id={""}
            rulesExpr={rulesExpr}
            setRulesExpr={setRulesExpr}
            dataNeeded={dataNeeded}
            formAutomation={form_execution}
          />
          <Row>
            <Col style={{ marginTop: "20px" }}>
              <Form.Item>
                <Space>
                  <Button
                    onClick={() => {
                      closeChartFilterModal();
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() =>
                      onFinish(form_execution.getFieldsValue(true))
                    }
                  >
                    {t("SAVE")}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </>
  );
}
