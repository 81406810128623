import React, { ReactNode, createContext, useRef, useState } from "react";

// Define the context
type CodifConfContextType = {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  searchedColumn: string;
  setSearchedColumn: React.Dispatch<React.SetStateAction<string>>;
  searchInput: React.MutableRefObject<null>;
};
export const SharedContext = createContext<CodifConfContextType>({
  // Provide default values that match the defined types
  searchText: "",
  setSearchText: () => {},
  searchedColumn: "",
  setSearchedColumn: () => {},
  searchInput: { current: null },
});

// Create a provider component
export const SharedContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  return (
    <SharedContext.Provider
      value={{
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput,
      }}
    >
      {children}
    </SharedContext.Provider>
  );
};
