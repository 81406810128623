import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";

const resource = { name: "connections", isPublic: false };

class ConnectionsService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(resource, context, setContext, path);
  }

  async connectionsTypes() {
    let { data } = await this.get(
      `/organizations/${this.context.orga.id}/connections/types`
    );

    return data;
  }

  async connect(id: string) {
    let { data } = await this.get(
      `/organizations/${this.context.orga.id}/connections/${id}/connect`
    );

    return data;
  }

  async check(id: string) {
    let { data } = await this.get(
      `/organizations/${this.context.orga.id}/connections/${id}/check`
    );

    return data;
  }
}

export default ConnectionsService;
