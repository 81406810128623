import { ProColumns } from "@ant-design/pro-components";
import { SharedContext } from "../../context/sharedContext";
import { useContext } from "react";
import MenuOptions from "../../../../common/menu/MenuOptions";
import { WORK_MODE } from "../../../../../constants/workModes";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import CustomTag from "../../../../common/tags/CustomTag";
import { useLocation, useNavigate } from "react-router-dom";

export const ActionsDndListDef: any = (workModeRisk: string) => {
  const location = useLocation();
  const navigate = useNavigate();
  const updateURL = (uuidAction: string) => {
    const currentPath = location.pathname; // Current URL path
    const newPath = `${currentPath}/action/${uuidAction}`; // Append '/action/id'

    // Replace the current URL without navigating
    navigate(newPath, { replace: true });
  };
  const { t } = useTranslation();
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /**confs shared context */
  const {
    riskActions,
    setRiskActions,
    setOpenActionModal,
    setActionRecord,
    setWorkModeAction,
  } = useContext(SharedContext);
  const columns: ProColumns<any>[] = [
    {
      key: "sort",
      width: 60,
    },
    {
      render: (text, record, index) => index + 1,
      width: 60,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "30%",
    },
    {
      title: "Enabled Status",
      width: "8%",
      render: (text, record, index) => {
        return record?.enabled ? (
          <CustomTag color={"#22A06B"} text={"Enabled"} />
        ) : (
          <CustomTag color={"#DE350B"} text={"Disabled"} />
        );
      },
    },
    {
      title: "Auto Status",
      width: "6%",
      render: (text, record) => {
        return record?.auto && <CustomTag color={"#0082BA"} text="Auto" />;
      },
    },
    {
      title: "Deleted Status",
      width: "8%",
      render: (text, record) => {
        return (
          record?.is_deleted && <CustomTag color={"#DE350B"} text={"Deleted"} />
        );
      },
    },
    isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)
      ? {
          title: "More Actions",
          valueType: "option",
          key: "option",
          width: "20%",
          align: "center",
          render: (text, record, index) => (
            <span>
              <MenuOptions
                viewRecord={() => {
                  updateURL(record?.id);
                  setActionRecord(record);
                  setWorkModeAction(WORK_MODE.VIEW);
                  setOpenActionModal(true);
                }}
                editRecord={async () => {
                  updateURL(record?.id);

                  setActionRecord(record);

                  setWorkModeAction(WORK_MODE.UPDATE);
                  setOpenActionModal(true);
                }}
                deleteRecord={() => {
                  setRiskActions(
                    riskActions?.filter((action: any) =>
                      action?.id
                        ? action?.id !== record.id
                        : action?.key !== record.key
                    )
                  );
                }}
                title={t("errors:CONFIRM_DELETE_MESSAGE")}
                mode={workModeRisk}
              />
            </span>
          ),
        }
      : {},
  ];

  return columns;
};
