import React from "react";

/* antd */
import { Footer } from "antd/es/layout/layout";

const FooterLayout: React.FC = () => {
  return (
    <Footer
      style={{
        padding: "10px 20px 20px 20px ",
        textAlign: "center",
        fontWeight: "300",
        opacity: "0.7",
        fontSize: 12,
        letterSpacing: "2px",
        height: 15,
        marginBottom: 25,
      }}
    >
      ©COPYRIGHT DFYA
    </Footer>
  );
};

export default FooterLayout;
