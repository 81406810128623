export const VIEW_TYPES = {
  pie: "Pie",
  bar_chart: "bar_chart",
  column_chart: "column_chart",
  inputs: "inputs",
  line_chart: "line_chart",
  map: "map",
  flow_chart: "flow_chart",
  mix_charts: "mix_charts",
  waterfall: "waterfall_chart",
  dynamic_table: "dynamic_table",
  dualAxes_chart: "dualAxes_chart",
  waterfall_chart: "waterfall_chart",
  map_sea: "map_sea",
};
