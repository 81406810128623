import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
/** labels labels json s*/
import labelsEN from "../assets/data/translation/en/labels.json";
import labelsFR from "../assets/data/translation/fr/labels.json";
import labelsAR from "../assets/data/translation/ar/labels.json";
/** error labels jsons */
import errorsEN from "../assets/data/translation/en/errors.json";
import errorsFR from "../assets/data/translation/fr/errors.json";
import errorsAR from "../assets/data/translation/ar/errors.json";
/** data labels */
import dataEN from "../assets/data/translation/en/data.json";
import dataFR from "../assets/data/translation/fr/data.json";
import dataAR from "../assets/data/translation/ar/data.json";

const resources = {
  "en-US": {
    labels: labelsEN,
    errors: errorsEN,
    data: dataEN,
  },
  "fr-FR": {
    labels: labelsFR,
    errors: errorsFR,
    data: dataFR,
  },
  "ar-DZ": {
    labels: labelsAR,
    errors: errorsAR,
    data: dataAR,
  },
};

i18n
  .use(Backend)

  .use(LanguageDetector)

  .use(initReactI18next)

  .init({
    resources,
    fallbackLng: "en-US",
    ns: ["labels"],
    debug: false,
    // lng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
