import { Card } from "antd";

import MenuOptions from "../../../../common/menu/MenuOptions";
import { StatConf } from "../interfaces/StatConf";

interface StatCardProps {
  value: number;
  statConf: StatConf;
  recordIndexStatConf: number;
  updateStatConfModal: (value: StatConf, index: number) => void;
  viewStatConfModal: (value: StatConf, index: number) => void;
  deleteStatConfig: (index: number) => void;
}

export default function StatCard(props: StatCardProps) {
  const {
    value,
    statConf,
    recordIndexStatConf,
    updateStatConfModal,
    viewStatConfModal,
    deleteStatConfig,
  } = props;

  return (
    <Card
      title={statConf?.title}
      size="small"
      extra={
        <MenuOptions
          viewRecord={() => {
            viewStatConfModal(statConf, recordIndexStatConf);
          }}
          editRecord={() => {
            updateStatConfModal(statConf, recordIndexStatConf);
          }}
          deleteRecord={() => {
            deleteStatConfig(recordIndexStatConf);
          }}
        />
      }
    >
      <span
        style={{
          color: "red",
          fontWeight: "bold",
          fontSize: "16px",
        }}
      >
        {`${value?.toFixed(2)} ${statConf?.unit || ""}`}
      </span>
    </Card>
  );
}
