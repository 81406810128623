import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Collapse, Form, Modal, Tooltip } from "antd";
import { ProColumns } from "@ant-design/pro-components";
import { useSelector } from "react-redux";

import { WORK_MODE } from "../../../../../constants/workModes";
import DndCxt from "../../../../common/dnd-list/DnDContext";
import MenuOptions from "../../../../common/menu/MenuOptions";
import { SharedContext } from "../../context/sharedContext";
import { RootState } from "../../../../../redux/store/store";
import { AddPolicyModal } from "../../common/autopolicies/AddRuleModal";
import { riskStringFormula } from "../../common/automation/risks/RiskStringFormula";
import { MainContext } from "../../../../../utils/context";
import { useTranslation } from "react-i18next";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";

const { Panel } = Collapse;

const RiskPolicies: React.FC<any> = (props: any) => {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /**translation */
  const { t } = useTranslation();
  const globalContext = useContext(MainContext);
  const orga = globalContext?.context?.orga;
  /** confs shared context */
  const { predefinedRiskId, riskRules, setRiskRules } =
    useContext(SharedContext);
  const predefinedRiskList: any[] = useSelector(
    (state: RootState) => state.predefinedRiskOrgaReducer
  );
  const { workModeRisk, record } = props;
  const [openRuleModal, setOpenRuleModal] = useState(false);
  const [workModePolicies, setWorkModePolicies] = useState(WORK_MODE.CREATE);
  const [ruleRecord, setRuleRecord] = useState([]);
  useEffect(() => {
    if (workModeRisk !== WORK_MODE.CREATE) {
      setRiskRules(record?.policy?.policy || []);
    } else {
      setRiskRules([]);
    }
  }, [record]);

  const [isAutoModalDirty, setIsAutoModalDirty] = useState(false);

  const AutomationRulesColumns: ProColumns<any>[] = [
    {
      key: "sort",
      width: 60,
    },
    {
      render: (text, record, index) => index + 1,
      width: 60,
    },
    {
      title: t("NAME", { item: t("") }),
      dataIndex: "name",
    },
    {
      title: t("NAME", { item: t("") }),
      render: (text, r) => {
        const dataNeeded =
          workModeRisk === WORK_MODE.CREATE
            ? predefinedRiskList?.find(
                (model: any) => model.id === predefinedRiskId
              )?.policyData || {}
            : record?.model?.policyData || {};
        /* [].concat(
            ...predefinedActionList
              ?.filter((action) =>
                record?.model?.actions
                  ?.map((action: any) => action?.id)
                  .includes(action?.id)
              )
              .map((action) => action?.dataNeeded?.INPUT)
              ?.filter((data: any) => data)
          ); */

        const f = riskStringFormula(r?.rules, dataNeeded, orga);
        return (
          <span style={{ color: "#0082ba" }}>
            {f.slice(0, 60) + (f.length > 60 ? "..." : "")}
          </span>
        );
      },
    },
    {
      title: t("MORE_ACTIONS"),
      valueType: "option",
      key: "option",
      width: "20%",
      align: "center",
      render: (text, record) => (
        <span>
          <MenuOptions
            viewRecord={() => {
              setRuleRecord(record);
              setWorkModePolicies(WORK_MODE.VIEW);
              showRuleModal();
            }}
            editRecord={async () => {
              setRuleRecord(record);
              setWorkModePolicies(WORK_MODE.UPDATE);
              showRuleModal();
            }}
            deleteRecord={() => {
              setRiskRules(
                riskRules.filter((rule: any) => rule?.key !== record.key)
              );
            }}
            title={t("errors:CONFIRM_DELETE_MESSAGE")}
            mode={workModeRisk}
          />
        </span>
      ),
    },
  ];

  const closePolicyModal = () => {
    setIsAutoModalDirty(false);
    setOpenRuleModal(false);
  };
  const showRuleModal = () => {
    setOpenRuleModal(true);
  };

  return (
    <div style={{ top: 30, width: "100%", marginBottom: "20px" }}>
      <Collapse
        className="panel-style"
        accordion
        expandIconPosition="end"
        size="large"
      >
        <Panel
          className="panel-style"
          header={<span> {t("POLICIES")} </span>}
          key="menu_policies"
        >
          <Form>
            <div style={{ marginBlockEnd: "5" }}>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  overflow: "auto",
                  maxHeight: "130px",
                }}
              >
                <Col>
                  <Form.Item name="risk_actions">
                    <DndCxt
                      columns={AutomationRulesColumns}
                      dataSource={riskRules}
                      setDataSource={setRiskRules}
                    />
                  </Form.Item>
                </Col>
              </div>
            </div>
            <div style={{ display: "flex", paddingTop: 5, marginTop: 0 }}>
              <Tooltip
                placement="left"
                title={
                  predefinedRiskId === ""
                    ? t("errors:SELECT_PREDEFINED_RISK_FIRST")
                    : ""
                }
              >
                {workModeRisk !== WORK_MODE.VIEW &&
                  isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) && (
                    <Button
                      style={{
                        marginLeft: "auto",
                      }}
                      className="button-primary"
                      type="primary"
                      onClick={() => {
                        setWorkModePolicies(WORK_MODE.CREATE);
                        showRuleModal();
                      }}
                      disabled={predefinedRiskId !== "" ? false : true}
                    >
                      {t("ADD_BUTTON_TEXT")}
                      {t("RULE")}
                    </Button>
                  )}
              </Tooltip>
            </div>
          </Form>
        </Panel>
        {/* Action Name */}
      </Collapse>

      {/* ADD Rule Modal */}
      <Modal
        destroyOnClose={true}
        title={t("POLICIES")}
        open={openRuleModal}
        onCancel={() => {
          if (isAutoModalDirty) {
            Modal.confirm({
              title: "Confirm",
              content: t("errors:UNSAVED_CHANGES_LEAVING_MESSAGE"),
              onOk: () => {
                closePolicyModal();
              },
              onCancel: () => {},
              okText: t("YES"),
              cancelText: t("NO"),
            });
          } else {
            closePolicyModal();
          }
        }}
        width={1000}
        footer={null}
      >
        <AddPolicyModal
          closeAutomationModal={closePolicyModal}
          actionAutomations={riskRules}
          setActionAutomations={setRiskRules}
          workModeAutomation={workModePolicies}
          setIsAutoModalDirty={setIsAutoModalDirty}
          isAutoModalDirty={isAutoModalDirty}
          automationRecord={ruleRecord}
          dataNeeded={
            workModeRisk === WORK_MODE.CREATE
              ? predefinedRiskList?.find(
                  (model: any) => model.id === predefinedRiskId
                )?.policyData || {}
              : record?.model?.policyData || {}
            /* [].concat(
              ...predefinedActionList
                ?.filter((action) =>
                  record?.model?.actions
                    ?.map((action: any) => action?.id)
                    .includes(action?.id)
                )
                .map((action) => action?.dataNeeded?.INPUT)
                ?.filter((data: any) => data)
            ) */
          }
        />
      </Modal>
    </div>
  );
};

export default RiskPolicies;
