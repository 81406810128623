import React, { useContext, useEffect } from "react";
import {
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import "dayjs/locale/en";
import {
  DATA_NEEDED_TYPE,
  DATA_NEEDED_TYPES,
} from "../../../../../constants/DataNeededTypes";
import { SharedContext } from "../../context/sharedContext";
import { WORK_MODE } from "../../../../../constants/workModes";
import { selectFilterOption } from "../../../../../utils/functions";
import { useTranslation } from "react-i18next";
const DataNeededDynamicFields: React.FC = () => {
  const { dynamicFields, setDynamicFields, workMode, formDataNeeded } =
    useContext(SharedContext);
  const { t } = useTranslation();

  const addDynamicField = async () => {
    // Validate the form field = name and type and position
    await formDataNeeded
      .validateFields([
        `Data_Name${dynamicFields.length}`,
        `Data_Type${dynamicFields.length}`,
        dynamicFields.length > 0 &&
          dynamicFields[dynamicFields.length - 1].hasOwnProperty("position") &&
          `Data_Position${dynamicFields.length}`,
      ])
      .then(() => {
        setDynamicFields([
          ...dynamicFields,
          {
            key: dynamicFields.length + 1,
            dataName: "",
            dataType: DATA_NEEDED_TYPE.STRING,
          },
        ]);
      })
      .catch((err) => {});
  };
  const removeDynamicField = (fieldKey: number) => {
    const updatedFields = dynamicFields.filter(
      (field) => field.key !== fieldKey
    );
    formDataNeeded.resetFields([
      `Data_Name${fieldKey}`,
      `Data_Type${fieldKey}`,
    ]);
    setDynamicFields(updatedFields);
  };

  const handleInputChange = (key: number, field: string, value: any) => {
    // Update the dynamicFields array based on the changed value
    const updatedFields = dynamicFields.map((fieldItem) =>
      fieldItem.key === key ? { ...fieldItem, [field]: value } : fieldItem
    );
    // Set the updated dynamicFields state
    setDynamicFields(updatedFields);
  };

  useEffect(() => {
    if (dynamicFields.length > 0) {
      const currentFormValues = formDataNeeded.getFieldsValue();

      const dynamicFieldValues: { [key: string]: any } = {};
      const dynamicFieldTypeValues: { [key: string]: any } = {};
      const dynamicFieldPositionValues: { [key: string]: number } = {};

      dynamicFields.forEach((field: any) => {
        const fieldName = `Data_Name${field.key}`;
        const currentValue = currentFormValues[fieldName];
        const newValue = field.dataName ? field.dataName : undefined;
        // Only update if the current value is different
        if (currentValue !== newValue) {
          dynamicFieldValues[fieldName] = newValue;
        }

        const typeFieldName = `Data_Type${field.key}`;
        const currentTypeValue = currentFormValues[typeFieldName];
        const newTypeValue = field.dataType
          ? field.dataType
          : DATA_NEEDED_TYPE.STRING;
        // Only update if the current value is different
        if (currentTypeValue !== newTypeValue) {
          dynamicFieldTypeValues[typeFieldName] = newTypeValue;
        }

        const positionFieldName = `Data_Position${field.key}`;
        const currentPositionValue = currentFormValues[positionFieldName];
        const newPositionValue = field.position ? field.position : undefined;
        // Only update if the current value is different
        if (
          currentPositionValue !== newPositionValue &&
          field.hasOwnProperty("position")
        ) {
          dynamicFieldPositionValues[positionFieldName] = newPositionValue;
        }
      });
      // Filter out null values to avoid unnecessary updates
      const filteredDynamicFieldValues = Object.fromEntries(
        Object.entries(dynamicFieldValues).filter(
          ([_, value]) => value !== null
        ) as [string, any][]
      );

      const filteredDynamicFieldTypeValues = Object.fromEntries(
        Object.entries(dynamicFieldTypeValues).filter(
          ([_, value]) => value !== null
        ) as [string, any][]
      );
      const filteredDynamicFieldPositionValues = Object.fromEntries(
        Object.entries(dynamicFieldPositionValues).filter(
          ([_, value]) => value !== null
        ) as [string, number][]
      );
      // Set the dynamic field values to formDataNeeded form
      formDataNeeded.setFieldsValue({
        ...filteredDynamicFieldValues,
        ...filteredDynamicFieldTypeValues,
        ...filteredDynamicFieldPositionValues,
      });
    }
    console.log(formDataNeeded.getFieldsValue());
  }, [dynamicFields]);

  return (
    <>
      <Form form={formDataNeeded}>
        {dynamicFields?.map((field: any, index) => (
          <Row key={field.key} gutter={16}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                {...{ labelCol: { style: { width: 100 } } }}
                name={`Data_Name${field.key}`}
                label={`${t("NAME_FIELD")}`}
                rules={[
                  {
                    required: true,
                    message: t(`errors:REQUIRED`, {
                      label: t(`labels:FIELD`),
                    }),
                  },
                  {
                    validator: (_, value) => {
                      const formValues = formDataNeeded.getFieldsValue();
                      const dataNameValues = Object.entries(formValues)
                        .filter(
                          ([key, val]) =>
                            key.startsWith("Data_Name") &&
                            key !== `Data_Name${field.key}` &&
                            val !== ""
                        )
                        .map(([key, val]) => val);

                      if (dataNameValues.includes(value)) {
                        return Promise.reject(t("NAME_USED"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  disabled={workMode === WORK_MODE.VIEW}
                  value={field.dataName || undefined}
                  onChange={(e) => {
                    handleInputChange(field.key, "dataName", e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                {...{ labelCol: { style: { width: 100 } } }}
                name={`Data_Type${field.key}`}
                label={`${t("TYPE")}`}
                rules={[
                  {
                    required: true,
                    message: t(`errors:REQUIRED`, {
                      label: t(`labels:FIELD`),
                    }),
                  },
                ]}
              >
                <Select
                  disabled={workMode === WORK_MODE.VIEW}
                  onChange={(value) => {
                    handleInputChange(field.key, "dataType", value);
                  }}
                  filterOption={(input, option) =>
                    selectFilterOption(input, option)
                  }
                  value={field.dataType ? field.dataType : undefined}
                >
                  {DATA_NEEDED_TYPES.map((type) => (
                    <Select.Option key={type.value} value={type.value}>
                      {type.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={4} sm={4} md={4} lg={4} xl={2}>
              <Space>
                <Popconfirm
                  placement="top"
                  key="delete"
                  style={{ zIndex: 9999999 }}
                  disabled={false}
                  title={t("errors:CONFIRM_DELETE_MESSAGE")}
                  onConfirm={async () => {
                    removeDynamicField(field.key);
                  }}
                  okText={t("YES")}
                  cancelText={t("NO")}
                >
                  {workMode === WORK_MODE.CREATE ||
                    (workMode === WORK_MODE.UPDATE && (
                      <Tooltip title={t("errors:REMOVE_DATA_NEEDED_LINE")}>
                        {" "}
                        <MinusCircleOutlined
                          style={{ fontSize: "20px", color: "#ff671f" }}
                        />
                      </Tooltip>
                    ))}
                </Popconfirm>
                <span>
                  {index + 1 === dynamicFields?.length &&
                    workMode !== WORK_MODE.VIEW && (
                      <Tooltip title={t("errors:ADD_DATA_NEEDED_LINE")}>
                        <PlusCircleOutlined
                          style={{ fontSize: "20px", color: "#1677ff" }}
                          onClick={addDynamicField}
                        />
                      </Tooltip>
                    )}
                </span>
              </Space>
            </Col>
          </Row>
        ))}
        <span>
          {dynamicFields?.length === 0 && workMode !== WORK_MODE.VIEW && (
            <Tooltip title={t("errors:ADD_DATA_NEEDED_LINE")}>
              <PlusCircleOutlined
                style={{ fontSize: "20px", color: "#1677ff" }}
                onClick={addDynamicField}
              />
            </Tooltip>
          )}
        </span>
      </Form>
    </>
  );
};

export default DataNeededDynamicFields;
