import { setPredefinedActionsGlobal } from "../../reducers/predefinedActionsGlobal.Reducer";

/** dispatch predefined risk */
export const InitActionsGlobal = async (
  actionsServices: any,
  dispatch: any
) => {
  return actionsServices
    .getPredefinedActionGlobal()
    .then(async (res: any) => {
      dispatch(setPredefinedActionsGlobal(res?.items));
    })
    .catch((err: any) => {
      console.log(err);
    });
};
