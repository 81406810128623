import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";

const resource = { name: "organizations", isPublic: true };

class OrganizationsService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(resource, context, setContext, path);
  }

  async getActionParameters(id: string) {
    const { data } = await this.get(`/organizations/${id}/parameters`);

    return data;
  }

  /** Organizaion settings related Apis */
  async updateSettingsOrganisation(id: string, args: any) {
    let data = await this.patch(`/organizations/${id}/org_settings/`, args);

    return data?.data;
  }
  /** get Organization settings  that exists in the dataBase */
  async getOrganizationExistingSettings(id: string) {
    const { data } = await this.get(`/organizations/${id}/org_settings/`);

    return data;
  }
  /** get all possible settings for an Organization   */
  async getOrganizationPossibleSettings(id: string) {
    const { data } = await this.get(
      `/organizations/${id}/org_settings/parameters`
    );

    return data;
  }
}

export default OrganizationsService;
