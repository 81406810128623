import { t } from "i18next";
import i18n from "../parameters/i18n";

export const createKey = (label: string) => {
  // Trim spaces from the beginning and end
  const trimmedString = label?.trim();

  // Replace spaces with underscores
  const stringWithUnderscores = trimmedString?.replace(/\s/g, "_");

  // Convert the string to uppercase
  const uppercaseString = stringWithUnderscores?.toUpperCase();
  //console.log(uppercaseString);
  return uppercaseString;
};

export const getTranslation = (key: string, file: string): string => {
  if (key && typeof key === "string") {
    const translatedValue = i18n.t(`${file}:${key}`, {
      defaultValue:
        key
          ?.toLowerCase()
          ?.replace(/_/g, " ")
          ?.replace(/(?:^|\s)\S/g, (a) => a?.toUpperCase()) || key,
    });

    return translatedValue;
  } else {
    return "";
  }
};

export const TranslateErrors = (input: any): any => {
  // Check if the TEXT key exists
  if (!input.TEXT) {
    return "-";
  }
  // Check if DATA exists and has keys
  if (input.DATA && Object.keys(input.DATA).length > 0) {
    // Construct an object for translation with DATA keys as variables
    const dataArgs = Object.keys(input.DATA).reduce((acc, key) => {
      acc[key] = input.DATA[key];
      return acc;
    }, {} as Record<string, any>);

    // Call the t function with the formatted string and data arguments
    return t(`errors:${input.TEXT}`, dataArgs);
  }

  // Return TEXT if DATA doesn't exist or is empty
  return input.TEXT;
};

// Example usage with a dummy `t` function
const tMock = (key: string, params?: Record<string, any>) => {
  return `${key} - ${JSON.stringify(params)}`;
};
