import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";

const resource = { name: "scopes", isPublic: false };

class ScopesService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(resource, context, setContext, path);
  }

  async attachScopesToUsers(args: any) {
    const { data } = await this.post(
      `/organizations/${this?.context?.orga?.id}/scopes/attach`,
      args
    );
    return { data };
  }

  async getUserScopes(userId: string): Promise<any> {
    const { data } = await this.get(
      `/organizations/${this?.context?.orga?.id}/users/${userId}?full=scopes`
    );
    return { items: data?.scopes, total: data?.scopes?.length };
  }
}

export default ScopesService;
