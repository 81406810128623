import dayjs from "dayjs";
import { LicenseItem } from "../../../../interfaces/license";

export const disableStartLicenseDate = (
  current: dayjs.Dayjs,
  record: LicenseItem,
  copilotsLicenses: LicenseItem[]
) => {
  if (!current) return true;
  const today = dayjs().startOf("day");
  // Disable past dates
  if (dayjs(current).isBefore(today)) {
    return true;
  }
  // Disable dates after the record expiry
  if (
    record?.expiry &&
    dayjs(current).isAfter(dayjs(record.expiry).endOf("day"))
  ) {
    return true;
  }
  // Disable dates overlapping with existing licenses
  const isOverlapping = copilotsLicenses?.some(
    (license) =>
      dayjs(current).isBefore(dayjs(license?.expiry).endOf("day")) &&
      dayjs(current).isAfter(dayjs(license?.start).startOf("day"))
  );
  if (isOverlapping) {
    return true;
  }
  return false;
};

export const disableExpiryLicenseDate = (
  current: dayjs.Dayjs,
  record: LicenseItem,
  copilotsLicenses: LicenseItem[]
) => {
  if (!current) return true;
  const today = dayjs().startOf("day");
  // Disable past dates
  if (dayjs(current).isBefore(today)) {
    return true;
  }
  // Disable dates before the record's start date
  if (
    record?.start &&
    dayjs(current).isBefore(dayjs(record.start).startOf("day"))
  ) {
    return true;
  }
  // Disable dates that overlap with existing licenses
  const isOverlapping = copilotsLicenses?.some(
    (license) =>
      dayjs(current).isBefore(dayjs(license?.expiry).endOf("day")) &&
      dayjs(current).isAfter(dayjs(license?.start).startOf("day"))
  );
  if (isOverlapping) {
    return true;
  }
  return false;
};
