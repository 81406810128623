import { IOptionDataNeeded } from "../../../../../../interfaces/dataNeededURL";

export const getDataNeededFromPath = (
  dataNeeded: IOptionDataNeeded[],
  path: string
): IOptionDataNeeded | undefined => {
  const treePath = path?.split(".");
  var data =
    dataNeeded && dataNeeded?.find((dn) => dn?.value === treePath?.at(0));

  if (treePath.length === 1) {
    return data;
  }
  if (data?.children && treePath.length > 1) {
    return getDataNeededFromPath(data?.children, treePath.slice(1)?.join("."));
  }

  return undefined;
};

export const extractDataNeededType = (type: string | undefined): string => {
  return type?.split("_")?.at(0) || "";
};
