import { setPredefinedRisksOrga } from "../../reducers/predefinedRisksOrga.Reducer";

/** dispatch predefined risk */
export const InitRisks = async (risksServices: any, dispatch: any) => {
  return risksServices
    .getPredefinedRisk()
    .then(async (res: any) => {
      dispatch(setPredefinedRisksOrga(res?.items));
    })
    .catch((err: any) => {
      console.log(err);
    });
};
