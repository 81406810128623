import { setReasonsRisks } from "../../reducers/reasonsRisks.Reducer";

/** dispatch predefined risk */
export const InitReasonsRisks = async (
  reasonsRisksServices: any,
  dispatch: any
) => {
  return reasonsRisksServices
    .getResources({ pageSize: 1000, sort: false })
    .then(async (res: any) => {
      dispatch(setReasonsRisks(res?.items));
    })
    .catch((err: any) => {
      console.log(err);
    });
};
