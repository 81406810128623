export default function onChartClickEvent(
  chart: any,
  event: any,
  showDrawer: any
) {
  if (event?.type === "element:click") {
    /* handleChartEvent(
          Object.keys(data?.[0])?.[0],
          setParams,
          event?.data?.data
        ); */
    showDrawer(event?.data);
  }
}
