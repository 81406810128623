import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useContext } from "react";
import { MainContext } from "../../../../../utils/context";
import { message, notification } from "antd";
import { WORK_MODE } from "../../../../../constants/workModes";
import { SharedContext } from "../../context/sharedContext";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { getTranslation } from "../../../../../utils/transaltion";
import i18n from "../../../../../parameters/i18n";
import { Scope } from "../../../../../interfaces/scope";
import UsersService from "../../../../../services/users.service";
import handleApiError from "../../../../../services/functions/handle-api-errors/handleApiError";
import { REQUEST } from "../../../../../services/functions/handle-api-errors/const";

export interface SharedContextFunctionsInterface {
  getEnums: (
    setValueEnumRoles: React.Dispatch<
      SetStateAction<{
        [key: string]: {
          text: string;
        };
      }>
    >
  ) => Promise<void>;
  filterUsers: (
    args: any,
    filters: any,
    setGetLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setTotalUser: React.Dispatch<React.SetStateAction<any>>
  ) => Promise<void>;
  handleCreateUpdateScope: (
    scope: any[],
    key: string | undefined,
    set_confirmLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  closeScopeModal: () => Promise<void>;
  onFinishSaveUser: (
    record: any,
    values: any,
    roles: any,
    workModeUser: any,
    setLoadingSave: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  goToUserConfiguration: (record: any, mode: string) => Promise<void>;
  goToUsers: () => Promise<void>;
  getUsers: (
    params: any,
    setGetLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setTotalUser: React.Dispatch<React.SetStateAction<any>>
  ) => Promise<void>;
  DeleteUser: (
    id: string,
    setGetLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setTotalUser: React.Dispatch<React.SetStateAction<any>>
  ) => Promise<void>;
}

export const useSharedContextFunctions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  /**confs shared conte(xt */
  const {
    userScopes,
    setUserScopes,
    setOpenScopeModal,
    userList,
    setUserList,
  } = useContext(SharedContext);
  /** roles from redux */
  const Roles = useSelector((state: RootState) => state.roleReducer);

  const globalContext = useContext(MainContext);
  if (!globalContext) {
    return <></>;
  }
  const userServices = new UsersService(
    globalContext?.context,
    globalContext?.setContext
  );
  const getEnums = async (
    setValueEnumRoles: React.Dispatch<
      SetStateAction<{
        [key: string]: {
          text: string;
        };
      }>
    >
  ): Promise<void> => {
    const updatedEnumRoles: { [key: string]: { text: string } } = {};
    Roles?.forEach((curr: any) => {
      updatedEnumRoles[curr.id] = {
        text: getTranslation(curr.name, "data"),
      };
    });

    setValueEnumRoles(updatedEnumRoles);
  };

  /** filter function */
  const filterUsers = async (
    params: any,
    filters: any,
    setGetLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setTotalUser: React.Dispatch<React.SetStateAction<any>>
  ) => {
    setGetLoading(true);
    await userServices
      .filterResource(params, filters)
      .then(async (response) => {
        setGetLoading(false);
        setUserList(response?.items);
        setTotalUser(response?.total);
      })
      .catch((err) => {
        setGetLoading(false);
        handleApiError(err);
      });
  };
  const goToUserConfiguration = async (
    record: any,
    mode: string
  ): Promise<void> => {
    navigate("/menu/users/config", {
      state: {
        record: record,
        workModeUser: mode,
      },
    });
  };

  const goToUsers = async (reload?: string): Promise<void> => {
    navigate("/menu/users");
    /* if (reload) {
      window.location.reload();
    } */
  };

  const getUsers = async (
    params: any,
    setGetLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setTotalUser: React.Dispatch<React.SetStateAction<any>>
  ): Promise<void> => {
    setGetLoading(true);
    await userServices
      .getResources(params)
      .then(async (res: any) => {
        setUserList(res?.items);
        setTotalUser(res?.total);
      })
      .catch((err: any) => {
        handleApiError(err);
      });
    setGetLoading(false);
  };
  const DeleteUser = async (
    id: string,
    setGetLoading: Dispatch<React.SetStateAction<boolean>>,
    setTotalUser: Dispatch<React.SetStateAction<any>>
  ): Promise<void> => {
    await userServices
      .deleteResource(id)
      .then((res: any) => {
        notification.success({
          message: t("errors:DELETE_SUCCESS"),
          placement: "bottomRight",
        });
        // /** delete the user logically  */
        // const updatedUserList = userList?.filter((user) => user.id !== id);
        // setUserList(updatedUserList);
        filterUsers(
          {
            current: 1,
            pageSize: 10,
          },
          undefined,
          setGetLoading,
          setTotalUser
        );
      })
      .catch((err: any) => {
        handleApiError(err, REQUEST.DELETE);
      });
  };
  const onFinishSaveUser = async (
    record: any,
    values: any,
    roles: any,
    workModeUser: any,
    setLoadingSave: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    values["roles"] = roles;
    if (workModeUser === WORK_MODE.CREATE) {
      const response = await userServices
        .createResource(values)
        .then((response) => {
          notification.success({
            message: t("errors:CREATE_SUCCESS"),
            placement: "bottomRight",
          });
          setLoadingSave(false);
          goToUsers();
          return response;
        })
        .catch((err) => {
          setLoadingSave(false);
          handleApiError(err, REQUEST.CREATE);
        });
      return response;
    } else {
      // update mode
      if (workModeUser === WORK_MODE.UPDATE) {
        delete values["email"];
        const response = await userServices
          .updateResource(record.id, values)
          .then((res) => {
            notification.success({
              message: t("errors:UPDATE_SUCCESS"),
              placement: "bottomRight",
            });
            if (
              globalContext?.context?.user?.id === res?.data?.data?.id &&
              workModeUser !== WORK_MODE.VIEWPROFILE
            ) {
              i18n.changeLanguage(values["preferredLanguage"]);
              goToUsers("reload");
            } else {
              goToUsers();
            }
            setLoadingSave(false);
            return res?.data;
          })
          .catch((err) => {
            setLoadingSave(false);
            handleApiError(err, REQUEST.UPDATE);
          });
        return response;
      }
    }
    if (workModeUser === WORK_MODE.VIEWPROFILE) {
      delete values["email"];
      await userServices
        .updateUserMe(values)
        .then((res) => {
          notification.success({
            message: t("errors:UPDATE_SUCCESS"),
            placement: "bottomRight",
          });

          setLoadingSave(false);
          return res?.data?.data;
        })
        .catch((err) => {
          setLoadingSave(false);
          handleApiError(err, REQUEST.UPDATE);
        });
    }
  };
  const handleCreateUpdateScope = async (
    scopes: Scope[],
    key: string,
    set_confirmLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    set_confirmLoading(true);
    setUserScopes(
      scopes.concat(userScopes.filter((scope) => scope.key !== key))
    );
    set_confirmLoading(false);
    closeScopeModal();
  };
  const closeScopeModal = () => {
    setOpenScopeModal(false);
  };
  return {
    getEnums,
    goToUsers,
    goToUserConfiguration,
    getUsers,
    DeleteUser,
    onFinishSaveUser,
    closeScopeModal,
    filterUsers,
    handleCreateUpdateScope,
  };
};
