import { PageHeader } from "@ant-design/pro-components";
import { Button, Card, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { WORK_MODE } from "../../../../constants/workModes";
import { useEffect, useContext } from "react";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/OrganizationLogic";
import { SharedContext } from "../context/sharedContext";
import { useTranslation } from "react-i18next";
import { PERMISSIONS_LIST } from "../../../../constants/permissions";
import { isPermission } from "../../../../utils/permissionsFunctions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/store";

const OrganisationHeader: React.FC<{
  fromExpiredLicense: boolean;
}> = (props) => {
  /**props  */
  const { fromExpiredLicense } = props;
  const { workMode, form, setWorkMode } = useContext(SharedContext);
  /**local vars */
  const { t } = useTranslation();
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /** get shared logic */
  const { goToTable } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  useEffect(() => {}, [workMode]);
  return (
    <div>
      <PageHeader
        title={
          !fromExpiredLicense ? (
            <span style={{ alignContent: "start", marginLeft: 0 }}>
              <Popconfirm
                placement="top"
                key="delete"
                style={{ zIndex: 9999999 }}
                disabled={false}
                title={t("errors:LEAVING_PAGE_MESSAGE")}
                onConfirm={async () => {
                  form.resetFields();
                  await goToTable();
                }}
                okText={t("NO")}
                cancelText={t("YES")}
              >
                <Link
                  style={{ fontSize: 16, color: "#0082BA" }}
                  to={"/menu/organization"}
                  onClick={(e) => {
                    if (form.isFieldsTouched() && workMode !== WORK_MODE.VIEW) {
                      e.preventDefault();
                    } else {
                      goToTable();
                      form.resetFields();
                    }
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "center",
                    }}
                  >
                    {t("ORGANIZATION_BACK_TO")}
                    <ArrowLeftOutlined
                      style={{
                        marginInlineStart: 0,
                        marginInlineEnd: 8,
                        color: "#0082BA",
                        transform:
                          getComputedStyle(document.body).direction === "rtl"
                            ? "scaleX(-1)"
                            : "none", // Flip arrow for RTL
                      }}
                    />
                  </span>
                </Link>
              </Popconfirm>
            </span>
          ) : null
        }
        style={{ marginInlineStart: -20 }}
      />

      <div
        style={{
          height: "3%",
          marginTop: -10,
          width: "100%",
          marginBottom: 10,
        }}
      >
        <Card>
          <p
            style={{
              fontWeight: "bold",
              fontSize: 16,
              color: "#2A333B",
            }}
          >
            {t("ORGANIZATION_CONFIGURATION")}
            {workMode === WORK_MODE.VIEW &&
            (isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN) ||
              isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)) ? (
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  marginRight: 10,
                }}
                icon={<EditOutlined style={{ color: "#004C97" }} />}
                onClick={() => setWorkMode(WORK_MODE.UPDATE)}
              ></Button>
            ) : null}
          </p>
        </Card>
      </div>
    </div>
  );
};

export default OrganisationHeader;
