import { useContext, useEffect, useState } from "react";
import { Card, Popover, Steps } from "antd";
import { SharedContext } from "../context/SharedContext";
import { MainContext } from "../../../../utils/context";
import { STATUS } from "../../../../constants/status";
import { transformStepsLogic } from "../logic/runningMapping.logic";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../utils/transaltion";

const StepsComponent = () => {
  /*local var*/
  const { t } = useTranslation();
  const [transformeditems, setTransformeditems] = useState<any>([]);
  const globalContext = useContext(MainContext);
  const { currentAction } = useContext(SharedContext);
  const [itemsteps, setItemSteps] = useState<any[]>([]);

  useEffect(() => {
    if (!globalContext || !globalContext.context.orga) {
      return;
    }
    const transformedStep = transformStepsLogic(currentAction);
    setTransformeditems(transformedStep);
  }, [currentAction, globalContext]);
  useEffect(() => {
    setItemSteps([
      {
        // Pass the initialItem properties directly here
        status:
          currentAction?.status === STATUS.WAIT_FOR_EXECUTION ||
          (currentAction?.status === STATUS.COMPLETE &&
            !currentAction?.contexts.at(0)?.reason &&
            currentAction?.reason?.name !== "UNUSED")
            ? "finish"
            : currentAction?.status === STATUS.OPEN
            ? "process"
            : "error",
        title: (
          <Popover
            content={
              <span>
                {getTranslation(currentAction?.action_conf_name, "data")}
              </span>
            }
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              opacity: "0.5",
            }}
          >
            <span>
              {currentAction?.status === STATUS.WAIT_FOR_EXECUTION ||
              (currentAction?.status === STATUS.COMPLETE &&
                !currentAction?.contexts?.at(0)?.reason)
                ? t("ACCEPTED")
                : currentAction?.status === STATUS.OPEN
                ? t("ACTION_TO_BE_ACCEPTED")
                : t("REJECTED")}
            </span>
          </Popover>
        ),
      },
      ...transformeditems,
    ]);
  }, [transformeditems]);

  return (
    <>
      {currentAction && (
        <Card>
          <Steps
            responsive={true}
            type="default"
            size="default"
            current={
              transformeditems.findIndex(
                (item: any) => item.status === "process"
              ) || 0
            }
            percent={100}
            labelPlacement="vertical"
            items={itemsteps}
          />
        </Card>
      )}
    </>
  );
};

export default StepsComponent;
