import L from "leaflet";
import axios from "axios";
import { FLOW_TYPES } from "../../../../constants/flowChartTypes";
import {
  checkAttributesValidity,
  currentActionFunction,
  findCurrentObj,
  getActionType,
  isDate,
} from "../../../../utils/functions";
import { CUSTOMER_ORDER_ITEM } from "../../../../interfaces/flowchart";
import { VIEW_TYPES } from "../../../../constants/viewtypes";
import { STATUS } from "../../../../constants/status";
import { LocationMaterItem } from "../../../../interfaces/locationMaster";
import { getTranslation } from "../../../../utils/transaltion";
import {
  formatDataNeededFromURLToList,
  formatValueBasedOnType,
} from "../../../../utils/datatypesFunctions";
import { Reason } from "../../../../interfaces/reasons";

export function transformDataToFlowChart(
  formatNumber: string,
  formatDate: string,
  currency: string,
  data: any,
  type: string,
  dataNeededAttributes: any,
  orgActionSettings: any,
  confsActionSettings: any,
  context?: any
): {
  nodes: any[];
  edges: any[];
  errorList: any[];
} {
  let errorList: any = [];
  let nodes: any = [];
  let edges: any = [];
  const jsonpath = require("jsonpath");
  if (type === FLOW_TYPES.TRANSFER_ORDER && data) {
    // Handle the TRANSFER_ORDER data structure
    const {
      DELIVERY_DATE,
      QUANTITY,
      RECEIVING_LOCATION,
      SHIPPING_LOCATION,
      SHIP_DATE,
      ITEM,
    } = data;

    /** get item description */
    const item_description = jsonpath
      .query(context, "$.DATA.MASTER.ITEM_MASTER")
      ?.at(0)?.ITEM_DESCRIPTION;

    /**get location master */
    const LOCATION_MASTER_JSON = jsonpath.query(
      context,
      "$.DATA.MASTER.LOCATION_MASTER"
    );

    // get shipping city
    const shipping_city = findCityByLocationId(
      LOCATION_MASTER_JSON,
      SHIPPING_LOCATION
    );

    // get receiving location
    const receiving_city = findCityByLocationId(
      LOCATION_MASTER_JSON,
      RECEIVING_LOCATION
    );

    errorList = checkAttributesValidity({
      LOCATION_MASTER: LOCATION_MASTER_JSON,
      DELIVERY_DATE: DELIVERY_DATE,
      QUANTITY: QUANTITY,
      RECEIVING_LOCATION_CODE: RECEIVING_LOCATION,
      SHIPPING_LOCATION_CODE: SHIPPING_LOCATION,
      SHIP_DATE: SHIP_DATE,
      SHIPPING_LOCATION_IN_LOCATION_MASTER: shipping_city,
      RECEIVING_LOCATION_IN_LOCATION_MASTER: receiving_city,
      //  ITEM_MASTER: item_description,
    });
    if (errorList.length === 0) {
      const startNode = {
        id: "startNode",
        value: {
          text:
            shipping_city +
            " \n" +
            formatValueBasedOnType(
              SHIP_DATE,
              "SHIP_DATE",
              formatNumber,
              formatDate,
              currency,
              dataNeededAttributes,
              orgActionSettings,
              confsActionSettings
            ),
        },
      };

      const endNode = {
        id: "endNode",
        value: {
          text:
            receiving_city +
            " \n " +
            formatValueBasedOnType(
              DELIVERY_DATE,
              "DELIVERY_DATE",
              formatNumber,
              formatDate,
              currency,
              dataNeededAttributes,
              orgActionSettings,
              confsActionSettings
            ),
        },
      };

      const edge = {
        source: "startNode",
        target: "endNode",
        value: {
          text: `Quantity: ${QUANTITY}`,
          subText: ` Item: ${ITEM} `,
        },
      };

      nodes = [startNode, endNode];
      edges = [edge];
    } else {
      nodes = [];
      edges = [];
    }

    return { nodes, edges, errorList };
  } else if (type === FLOW_TYPES.CUSTOMER_ORDER && data) {
    // Handle the CUSTOMER_ORDER data structure
    const nodes: any = [];
    const edges: any = [];

    data.forEach((item: CUSTOMER_ORDER_ITEM) => {
      const startNodeId = `startNode${item.Shipping_Location}`;
      const endNodeId = `endNode${item.Customer}`;

      // Check if the startNode with the same ID already exists
      const existingStartNode = nodes.find(
        (node: any) => node.id === startNodeId
      );
      const startNode = {
        id: startNodeId,
        value: {
          text: `${item.Shipping_Location}\n${item.Ship_Date}`,
        },
      };

      // Check if the endNode with the same ID already exists
      const existingEndNode = nodes.find((node: any) => node.id === endNodeId);
      const endNode = {
        id: endNodeId,
        value: {
          text: `${item.Customer}\n${item.Delivery_Date}`,
        },
      };

      const edge = {
        source: startNode.id,
        target: endNode.id,
        value: {
          text: `Quantity: ${item.Quantity}`,
          subText: `Line: ${item.line}`,
        },
      };

      // Push startNode and endNode if they are not already in the nodes array
      if (!existingStartNode) {
        nodes.push(startNode);
      }
      if (!existingEndNode) {
        nodes.push(endNode);
      }
      edges.push(edge);
    });

    return { nodes, edges, errorList };
  }
  if (type === FLOW_TYPES.PURCHASE_ORDER && data) {
    // Handle each object in the list separately

    const {
      DELIVERY_DATE,
      QUANTITY,
      RECEIVING_LOCATION_CODE,
      LINE_NUMBER,
      PURCHASE_COST_PER_UNIT,
      NUMBER,
      SHIPPING_DATE,
    } = data;

    /** get item description */
    const item_description = jsonpath
      .query(context, "$.DATA.MASTER.ITEM_MASTER")
      ?.at(0).ITEM_DESCRIPTION;

    /**get location master */
    const LOCATION_MASTER_JSON = jsonpath.query(
      context,
      "$.DATA.MASTER.LOCATION_MASTER"
    );
    // get shipping city
    const Supplier_city = jsonpath
      .query(context, "$.DATA.MASTER.SUPPLIER_MASTER")
      ?.at(0)?.SUPPLIER_CITY;

    // get receiving location
    const receiving_city = findCityByLocationId(
      LOCATION_MASTER_JSON,
      RECEIVING_LOCATION_CODE
    );
    errorList = checkAttributesValidity({
      LOCATION_MASTER: LOCATION_MASTER_JSON,
      DELIVERY_DATE: DELIVERY_DATE,
      QUANTITY: QUANTITY,
      RECEIVING_LOCATION_CODE: RECEIVING_LOCATION_CODE,
      SHIP_DATE: SHIPPING_DATE,
      SUPPLIER_CITY: Supplier_city,
      LOCATION_RECEIVING: receiving_city,
      ITEM_MASTER: item_description,
    });

    if (errorList.length === 0) {
      const startNode = {
        id: `startNode${Supplier_city}`,
        value: {
          text: `${Supplier_city} \n ${formatValueBasedOnType(
            SHIPPING_DATE,
            "SHIPPING_DATE",
            formatNumber,
            formatDate,
            currency,
            dataNeededAttributes,
            orgActionSettings,
            confsActionSettings
          )}`,
        },
      };

      const endNode = {
        id: `endNode${receiving_city}`,
        value: {
          text: `${receiving_city} \n ${formatValueBasedOnType(
            DELIVERY_DATE,
            "DELIVERY_DATE",
            formatNumber,
            formatDate,
            currency,
            dataNeededAttributes,
            orgActionSettings,
            confsActionSettings
          )}`,
        },
      };

      const edge = {
        source: `startNode${Supplier_city}`,
        target: `endNode${receiving_city}`,
        value: {
          text: `Quantity: ${QUANTITY}`,
          subText: `PO number: ${NUMBER}`,
        },
      };

      nodes = [startNode, endNode];
      edges = [edge];
    } else {
      nodes = [];
      edges = [];
    }

    return { nodes, edges, errorList };
  }

  // Handle other cases or return a default value if needed
  return { nodes: [], edges: [], errorList: [] };
}

export function transformObjectToChartData(obj: any) {
  const keys = Object.keys(obj[0]);
  const xfield = keys[0];
  const yfields = keys.slice(1);
  const data = obj.map((item: any) => {
    const transformedItem: any = {};
    for (const key of keys) {
      transformedItem[key] = key === xfield ? item[key] : parseInt(item[key]);
    }
    return transformedItem;
  });

  return {
    xfield,
    yfields,
    data,
  };
}

export function transformObjectToListInput(obj: Record<string, any>): {
  list: { value: any; AttributeName: string }[];
  errors: { status: number; message: string }[];
} {
  const list: any = [];
  const errors: any = [];
  if (Object.keys(obj).length === 0) {
    errors.push({
      status: 400,
      message: `Error processing, no data provided `,
    });
    return { list, errors };
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      list.push({
        value: obj[key],
        AttributeName: key,
      });
    }
  }

  return { list, errors };
}

export const createWaypoints = (
  shipping: number[] | undefined,
  receiving: number[] | undefined
) => {
  if (!shipping || !receiving) {
    return [];
  }

  const shippingLatLng = L.latLng(shipping[0], shipping[1]);
  const receivingLatLng = L.latLng(receiving[0], receiving[1]);
  return [shippingLatLng, receivingLatLng];
};
export const transformObjectToMap = async (
  formatNmber: string,
  formatDate: string,
  currency: string,
  type: string,
  obj: Record<string, any>,
  context?: any
): Promise<{ list: any[]; errors: any[] }> => {
  const list: any[] = [];
  let errors: any[] = [];

  try {
    await processMapData(obj, list, context, type);
  } catch (error) {
    errors.push({ error });
    console.error(`Error processing ${type}:`, error);
  }

  return { list, errors };
};

export const geocodeCity = async (city: string) => {
  try {
    const encodedCity = encodeURIComponent(city);

    const response = await axios.get(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodedCity}`
    );

    if (response.data.length > 0) {
      const result = response.data[0];
      const latitude = parseFloat(result.lat);
      const longitude = parseFloat(result.lon);
      return [latitude, longitude];
    } else {
      throw new Error(
        "City not found or an error occurred while getting lattitude and longitude of this city"
      );
    }
  } catch (error) {
    throw new Error("City not found or an error occurred");
  }
};

export const MapImpactByActionType = (PRedefinedActions: any, data: any) => {
  const errors: any = [];
  //data = data?.items;
  //
  const mappedData =
    data
      ?.map((risk: any) => {
        const actionType = getActionType(
          PRedefinedActions,
          risk?.current_action?.action_conf?.model?.id
        );
        const impact =
          risk?.current_action?.contexts?.at(0)?.data?.DATA?.IMPACT
            ?.FREIGHT_COST;
        //
        if (
          impact === undefined ||
          impact === null ||
          isNaN(parseFloat(impact))
        ) {
          errors.push({
            status: 400,
            message: `Error processing, impact data missing or invalid `,
          });
          return errors;
        }
        return {
          action_type: actionType?.name,
          action_type_Id: actionType?.id,
          Impact: impact,
        };
      })
      ?.reduce((accumulator: any, item: any) => {
        const { action_type, Impact, action_type_Id } = item;
        const key = `${action_type}_${Impact}`;
        if (!accumulator[key]) {
          accumulator[key] = {
            action_type,
            action_type_Id,
            Impact,
            value: 0,
          };
        }
        accumulator[key].value += 1;
        return accumulator;
      }, {}) || [];
  //
  // Convert the object to an array
  const mappedArray = Object.values(mappedData);
  // Sort the array by Impact in descending order
  const sortedArray = mappedArray.sort((a: any, b: any) => b.Impact - a.Impact);
  return sortedArray;
};

export const MapQuantityAtRiskByAction = (
  PRedefinedActions: any,
  data: any
) => {
  //
  try {
    if (data?.length === 0) {
      return {
        status: 500,
        message: "No valid data found for mapping",
      };
    }
    // Validate and filter out invalid data
    const validatedData =
      data?.filter((risk: any) => {
        const actionType = getActionType(
          PRedefinedActions,
          risk?.current_action?.action_conf?.model?.id
        );

        return (
          actionType?.name !== null &&
          actionType?.name !== undefined &&
          actionType?.name !== "" &&
          actionType?.id !== null &&
          actionType?.id !== undefined &&
          actionType?.id !== "" &&
          risk?.context?.QUANTITY_AT_RISK !== null &&
          risk?.context?.QUANTITY_AT_RISK !== undefined &&
          risk?.context?.QUANTITY_AT_RISK !== ""
        );
      }) || [];
    //
    if (validatedData.length !== data?.length) {
      return {
        status: 500,
        message: "No valid data found for mapping",
      };
    }

    // Map and group the validated data
    const mappedData = validatedData.map((risk: any) => {
      const actionType = getActionType(
        PRedefinedActions,
        risk?.current_action?.action_conf?.model?.id
      );

      return {
        action_type: actionType?.name,
        action_type_Id: actionType?.id,
        QUANTITY_AT_RISK: risk?.context?.QUANTITY_AT_RISK,
      };
    });
    //

    const groupedData: {
      [key: string]: { totalQuantity: number; action_type_Id: any };
    } = mappedData.reduce((accumulator: any, item: any) => {
      const { action_type, QUANTITY_AT_RISK, action_type_Id } = item;

      if (!QUANTITY_AT_RISK) {
        return accumulator;
      }

      if (!accumulator[action_type]) {
        accumulator[action_type] = {
          totalQuantity: 0,
          action_type_Id,
        };
      }
      accumulator[action_type].totalQuantity += parseFloat(QUANTITY_AT_RISK);
      return accumulator;
    }, {});

    const result = Object.entries(groupedData).map(
      ([action_type, { totalQuantity, action_type_Id }]) => ({
        action_type,
        totalQuantity,
        action_type_Id,
      })
    );
    //

    return { status: 200, result: result };
  } catch (err) {
    return { status: 500, message: err };
  }
};

export const MapQuantityAtRiskByLocation = (data: any) => {
  data = data.items;

  const mappedData =
    data
      ?.map((risk: any) => {
        return {
          quantity: risk?.context?.quantity_at_risk,
          Location_code:
            risk?.current_action?.contexts?.at(0)?.data?.DATA.MASTER
              ?.LOCATION_MASTER?.location_city,
        };
      })
      ?.reduce((accumulator: any, item: any) => {
        const { quantity, Location_code } = item;

        if (!accumulator[Location_code]) {
          accumulator[Location_code] = { quantity, Location_code, value: 0 };
        } else {
          accumulator[Location_code].quantity += quantity;
        }

        accumulator[Location_code].value += 1;

        return accumulator;
      }, {}) || [];

  // Convert the object to an array
  const mappedArray = Object.values(mappedData);

  // Sort the array by quantity in descending order
  const sortedArray = mappedArray.sort(
    (a: any, b: any) => b.quantity - a.quantity
  );

  return sortedArray;
};

export function transformObjectToGroupedBar(obj: any) {
  const keys = Object.keys(obj[0]);
  const xfield = keys[0];
  const yfields = keys.slice(1);
  const data = obj.map((item: any) => {
    const transformedItem: any = {};
    for (const key of keys) {
      transformedItem[key] = item[key];
    }
    return transformedItem;
  });

  return {
    xfield,
    yfields,
    data,
  };
}
const reduceInventoryData = (props: any) => {
  const { inventoryData, name, xField, yField } = props;
  return inventoryData?.map((item: any) => ({
    name: name,
    [xField]: item[xField],
    [yField]: item[yField],
  }));
};

export function transformObjectToMultipleLinesChart(
  dataConfigurations: any[],
  xField: string,
  yField: string
) {
  const reducedData: any = dataConfigurations.map((config) => {
    return reduceInventoryData({
      inventoryData: config?.data,
      name: config?.name,
      xField: xField,
      yField: yField,
    });
  });

  // Concatenate the reduced arrays
  const combinedData: any = [].concat(...reducedData);

  return combinedData;
}
export const getOtherOption = (
  idAction: any,
  actions: any,
  reasonsActions: Reason[],
  reasonsExecutions: Reason[]
) => {
  const otherOption: any[] = [];
  /** reasons actions from redux */
  actions.sort(
    (a: any, b: any) => a.action_conf_priority - b.action_conf_priority
  );
  // Find the index of the object with actionid == idAction
  const index = actions?.findIndex((action: any) => action?.id === idAction);
  // Return  all the objects before the found index which is the current one
  if (index !== -1) {
    const rejected_actions = actions?.slice(0, index);

    rejected_actions.map((action: any) => {
      // get stage executions
      const sorted_Exec = action?.executions?.sort(
        (a: any, b: any) =>
          a.execution_conf_priority - b.execution_conf_priority
      );
      const firstComplete = sorted_Exec?.find(
        (obj: any) => obj.status === "COMPLETE"
      );

      // get reason code/comment if reson=other from action or execution
      let reasonCodeany;
      let reasonOtherComment = null;

      if (action?.contexts?.at(0).reason?.id) {
        // here we take action reason code
        reasonCodeany = action?.contexts?.at(0).reason?.id
          ? reasonsActions?.find(
              (item: any) => item.id === action?.contexts?.at(0).reason?.id
            )?.name
          : null;
        if (action?.contexts?.at(0)?.reason_comment !== "")
          reasonOtherComment = action?.contexts?.at(0)?.reason_comment;
      } else {
        // here we take execution reason code
        reasonCodeany = firstComplete?.contexts?.at(0).reason?.id
          ? reasonsExecutions?.find(
              (item: any) =>
                item.id === firstComplete?.contexts?.at(0).reason?.id
            )?.name
          : null;

        if (firstComplete?.contexts?.at(0)?.reason_comment !== "")
          reasonOtherComment = firstComplete?.contexts?.at(0)?.reason_comment;
      }
      otherOption.push({
        Action_Name: getTranslation(action?.action_conf_name || "-", "data"),
        Reason_Code: getTranslation(
          !reasonCodeany && action?.status === STATUS.COMPLETE
            ? "ACCEPTED"
            : reasonCodeany || "-",
          "data"
        ),
        Execution_Stage: firstComplete
          ? firstComplete?.execution_conf_name
          : "-",
        User: action?.contexts?.at(0)?.user?.displayname || "-",
        ...(reasonOtherComment !== null && {
          Reason_Code_Other: reasonOtherComment, // push reasonOtherCommentonly if it's different from null
        }),
      });
    });
    return otherOption;
  }

  //  idAction is not found
  return null;
};

export async function Mappingfunction(
  formatNmber: string,
  formatDate: string,
  currency: string,
  currentAction: any,
  currentRisk: any,
  orgActionSettings: any,
  predefinedActionList: any,
  predefinedExecutionList: any
): Promise<any> {
  const context = currentAction.contexts;
  const idAction = currentAction?.id;

  let ACTION_DETAILS: any = [];
  let RISK_IMPACT: any = [];
  let OTHER_OPTIONS: any = [];
  let ACTION_CONTEXT: any = [];
  let ERROR_WARNINGS: any = [];
  const jsonpath = require("jsonpath");
  const DataNeededToListFormat = formatDataNeededFromURLToList(
    findCurrentObj(predefinedActionList, currentAction?.action_model)
      ?.dataNeeded
  );
  // use function thqt  gets element from redux list with an id
  const dataNeededAttributes = {
    dataNeeded: DataNeededToListFormat,
    actionParameters: findCurrentObj(
      predefinedActionList,
      currentAction?.action_model
    )?.actionParameters,
  };
  const confsActionSettings = currentAction?.action_conf_settings?.parameters;
  // have ACTION_DETAILS in input
  const ACTION_DETAILS_JSON = jsonpath
    .query(context?.at(0)?.data, "$.DATA.ACTION_DETAILS")
    ?.at(0);
  if (ACTION_DETAILS_JSON) {
    ACTION_DETAILS = {
      type: VIEW_TYPES.inputs,
      status: 200,
      InputList: transformObjectToListInput(ACTION_DETAILS_JSON),
    };
  } else {
    ACTION_DETAILS = {
      type: VIEW_TYPES.inputs,
      status: 400,
      InputList: transformObjectToListInput({}),
    };
  }

  //RISK_IMPACT
  const RISK_IMPACTS_JSON_LIST_PATHS = context?.at(0)?.data?.RISK_IMPACTS;

  RISK_IMPACT = await processRiskImpact(
    formatNmber,
    formatDate,
    currency,
    RISK_IMPACTS_JSON_LIST_PATHS,
    context,
    dataNeededAttributes,
    orgActionSettings,
    confsActionSettings
  );
  //OTHER_OPTIONS
  // OTHER_OPTIONS = getOtherOption(idAction, currentRisk?.actions);
  // if (OTHER_OPTIONS) {
  //   OTHER_OPTIONS = {
  //     type: VIEW_TYPES.DYNAMIC_TABLE,
  //     status: 200,
  //     InputList: getOtherOption(idAction, currentRisk?.actions),
  //   };
  // } else {
  OTHER_OPTIONS = {
    type: VIEW_TYPES.dynamic_table,
    status: 400,
    InputList: [{}],
  };
  //}
  // warnings and errors view

  const ERROR_WARNING_JSON = jsonpath
    .query(context?.at(0)?.data, "$.DATA.ERRORS")
    ?.at(0);

  ERROR_WARNINGS = getErrorWarnings(
    ERROR_WARNING_JSON ? ERROR_WARNING_JSON : {}
  );

  //ACTION_CONTExt
  const ACTION_CONTEXT_JSON_LIST_PATHS =
    context?.at(0)?.data?.ACTION_CONTEXT_VIEW;
  ACTION_CONTEXT = await processActionContext(
    formatNmber,
    formatDate,
    currency,
    ACTION_CONTEXT_JSON_LIST_PATHS,
    context,
    dataNeededAttributes,
    orgActionSettings,
    confsActionSettings
  );

  let list = {
    ACTION_DETAILS,
    RISK_IMPACT,
    OTHER_OPTIONS,
    ACTION_CONTEXT,
    ERROR_WARNINGS,
    dataNeededAttributes,
    orgActionSettings,
    confsActionSettings,
  };
  return list;
}

async function processRiskImpact(
  formatNmber: string,
  formatDate: string,
  currency: string,
  RISK_IMPACTS_JSON_LIST_PATHS: any,
  context: any,
  dataNeededAttributes: any,
  orgActionSettings: any,
  confsActionSettings: any
) {
  let RISK_IMPACT: any = [];
  const jsonpath = require("jsonpath");
  for (const key in RISK_IMPACTS_JSON_LIST_PATHS) {
    let consttype: any;
    let constInputList: any;
    let title: any;

    switch (RISK_IMPACTS_JSON_LIST_PATHS[key]) {
      case "Procurement_Shipping_Conditions":
        title = "Procurement Shipping Conditions";
        consttype = VIEW_TYPES.map;

        constInputList = await transformObjectToMap(
          formatNmber,
          formatDate,
          currency,
          key,
          context.RISK_IMPACT[key]
        );
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });
        break;
      case "Internal_Routes":
        title = "Internal Routes";
        consttype = VIEW_TYPES.map;

        constInputList = await transformObjectToMap(
          formatNmber,
          formatDate,
          currency,
          key,
          context.RISK_IMPACT[key]
        );
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });
        break;
      case "Procurement_Shortage_Risk":
        title = "Procurement Shortage Risk";
        consttype = VIEW_TYPES.inputs;
        constInputList = transformObjectToListInput(context.RISK_IMPACT[key]);
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });
        break;
      case "Inventory":
        title = "Inventory chart";
        consttype = VIEW_TYPES.line_chart;

        constInputList = transformObjectToChartData(context.RISK_IMPACT[key]);
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });
        break;
      case "$.DATA.TRANSFER_ORDER":
        title = "Transfer Order diagram";
        consttype = VIEW_TYPES.flow_chart;

        const RISK_IMPACTS_JSON = jsonpath
          .query(context?.at(0)?.data, "$.DATA.TRANSFER_ORDER")
          ?.at(0);

        if (!RISK_IMPACTS_JSON) {
          RISK_IMPACT.push({
            status: 400,
            message: "Error processing, transfer order does not exist ",
          });
        } else {
          constInputList = transformDataToFlowChart(
            formatNmber,
            formatDate,
            currency,
            RISK_IMPACTS_JSON,
            FLOW_TYPES.TRANSFER_ORDER,
            dataNeededAttributes,
            orgActionSettings,
            confsActionSettings,
            context?.at(0)?.data
          );

          if (constInputList.errorList.length !== 0) {
            // error in one of the attribute  but transfer order exist
            RISK_IMPACT.push({
              status: 400,
              title: title,
              type: consttype,
              InputList: constInputList.errorList,
            });
          } else {
            RISK_IMPACT.push({
              status: 200,
              title: title,
              type: consttype,
              InputList: constInputList,
            });
          }
        }

        break;
      case "Purchase_Order":
        title = "Purchase Order";
        consttype = VIEW_TYPES.flow_chart;

        constInputList = transformObjectToListInput(context.RISK_IMPACT[key]);
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Customer_Order":
        title = "Customer Order diagram";
        consttype = VIEW_TYPES.flow_chart;

        constInputList = transformDataToFlowChart(
          formatNmber,
          formatDate,
          currency,
          context.RISK_IMPACT[key],
          FLOW_TYPES.CUSTOMER_ORDER,
          dataNeededAttributes,
          orgActionSettings,
          confsActionSettings
        );
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });
        break;
      case "Demand_Fulfillment_Risk":
        title = "Demand Fulfillment Risk";
        consttype = VIEW_TYPES.inputs;

        constInputList = transformObjectToListInput(context.RISK_IMPACT[key]);
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Increase_Supply":
        title = "Increase Supply";
        consttype = VIEW_TYPES.inputs;

        constInputList = transformObjectToListInput(context.RISK_IMPACT[key]);
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Supply_Risk":
        title = "Supply Risk";
        consttype = VIEW_TYPES.inputs;

        constInputList = transformObjectToListInput(context.RISK_IMPACT[key]);
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });
        break;
      case "Expedite_Partial_Delivery":
        title = "Expedite Partial Delivery";
        consttype = VIEW_TYPES.inputs;

        constInputList = transformObjectToListInput(context.RISK_IMPACT[key]);
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Decrease_Supply":
        title = "Decrease Supply";
        consttype = VIEW_TYPES.inputs;

        constInputList = transformObjectToListInput(context.RISK_IMPACT[key]);
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Delay_Deployment":
        title = "DelayDeployment";
        consttype = VIEW_TYPES.inputs;

        constInputList = transformObjectToListInput(context.RISK_IMPACT[key]);
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Delay_Supply":
        title = "DelaySupply";
        consttype = VIEW_TYPES.inputs;

        constInputList = transformObjectToListInput(context.RISK_IMPACT[key]);
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Fulfillement_Shortage_Risk":
        title = "Fulfilment Shortage Risk";
        consttype = VIEW_TYPES.inputs;

        constInputList = transformObjectToListInput(context.RISK_IMPACT[key]);
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Alternative_DC":
        title = "Alternative DC";
        consttype = VIEW_TYPES.inputs;

        constInputList = transformObjectToListInput(context.RISK_IMPACT[key]);
        RISK_IMPACT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "$.DATA.IMPACT":
        title = getTranslation("IMPACT", "labels");
        consttype = VIEW_TYPES.inputs;
        const IMPACT_JSON = jsonpath
          .query(context?.at(0)?.data, "$.DATA.IMPACT")
          ?.at(0);

        if (!IMPACT_JSON) {
          RISK_IMPACT.push({
            status: 400,
            message: "Error processing , no impact found",
          });
        } else {
          const IMPACT = transformObjectToListInput(IMPACT_JSON);

          RISK_IMPACT.push({
            status: 200,
            title: title,
            type: consttype,
            InputList: IMPACT,
          });
        }

        break;
    }
  }
  return RISK_IMPACT;
}

async function processActionContext(
  formatNmber: string,
  formatDate: string,
  currency: string,
  ACTION_CONTEXT_JSON_LIST_PATHS: any,
  context: any,
  dataNeededAttributes: any,
  orgActionSettings: any,
  confsActionSettings: any
) {
  let ACTION_CONTEXT: any = [];
  const jsonpath = require("jsonpath");
  for (const key in ACTION_CONTEXT_JSON_LIST_PATHS) {
    let consttype: any;
    let constInputList: any;
    let title: any;

    switch (ACTION_CONTEXT_JSON_LIST_PATHS[key]) {
      case "Procurement_Shipping_Conditions":
        title = "Procurement Shipping Conditions";
        consttype = VIEW_TYPES.map;

        constInputList = await transformObjectToMap(
          formatNmber,
          formatDate,
          currency,
          key,
          context.ACTION_CONTEXT[key]
        );
        ACTION_CONTEXT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "$.DATA.INTERNAL_ROUTE":
        title = getTranslation("INTERNAL_ROUTES", "labels");
        consttype = VIEW_TYPES.map;
        const ACTION_CONTEXT_JSON = jsonpath
          .query(context?.at(0)?.data, "$.DATA.INTERNAL_ROUTE")
          ?.at(0);
        if (!ACTION_CONTEXT_JSON) {
          ACTION_CONTEXT.push({
            status: 400,
            message: "Error processing,internal routes not found ",
          });
        } else {
          constInputList = await transformObjectToMap(
            formatNmber,
            formatDate,
            currency,
            "Internal_Routes",
            ACTION_CONTEXT_JSON,
            context?.at(0)?.data
          );
          if (constInputList?.errors?.length !== 0) {
            ACTION_CONTEXT.push({
              status: 400,
              title: title,
              type: consttype,
              InputList: constInputList,
            });
          } else {
            ACTION_CONTEXT.push({
              status: 200,
              title: title,
              type: consttype,
              InputList: constInputList,
            });
          }
        }

        break;
      case "Procurement_Shortage_Risk":
        title = "Procurement Shortage Risk";
        consttype = VIEW_TYPES.inputs;
        constInputList = transformObjectToListInput(
          context.ACTION_CONTEXT[key]
        );
        ACTION_CONTEXT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Inventory":
        title = "Inventory chart";
        consttype = VIEW_TYPES.inputs;

        constInputList = transformObjectToChartData(
          context.ACTION_CONTEXT[key]
        );
        ACTION_CONTEXT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Inventory_Situation":
        title = "Inventory Situation";
        consttype = VIEW_TYPES.bar_chart;

        constInputList = transformObjectToGroupedBar(
          context.ACTION_CONTEXT[key]
        );
        ACTION_CONTEXT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Transfer_Order":
        title = "Transfer Order diagram";
        consttype = VIEW_TYPES.flow_chart;

        constInputList = transformDataToFlowChart(
          formatNmber,
          formatDate,
          currency,
          context.ACTION_CONTEXT[key],
          FLOW_TYPES.TRANSFER_ORDER,
          dataNeededAttributes,
          orgActionSettings,
          confsActionSettings
        );
        ACTION_CONTEXT.push({
          status: 200,
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Purchase_Order":
        title = "Purchase Order";
        consttype = VIEW_TYPES.inputs;

        constInputList = transformObjectToListInput(
          context.ACTION_CONTEXT[key]
        );
        ACTION_CONTEXT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });
        break;
      case "Demand_Supply_Matching":
        title = "Demand Supply Matching";
        consttype = VIEW_TYPES.mix_charts;

        let bar: any = [];
        let line: any = [];
        let area: any = [];
        let min: any = 0;
        let max: any = 0;

        context.ACTION_CONTEXT[key]?.forEach((item: any) => {
          const supplyPlan = item.Supply_Plan;
          const quantityInUnits = item.Quantity_in_units;
          const deltaQuantity = item.Delta_Quantity;
          min = Math.min(min, supplyPlan, quantityInUnits, deltaQuantity);
          max = Math.max(max, supplyPlan, quantityInUnits, deltaQuantity);
          bar.push({ date: item.date, Supply_Plan: item.Supply_Plan });
          line.push({
            date: item.date,
            Quantity_in_units: item.Quantity_in_units,
          });
          area.push({ date: item.date, Delta_Quantity: item.Delta_Quantity });
        });

        constInputList = {
          bar: transformObjectToChartData(bar),
          line: transformObjectToChartData(line),
          area: transformObjectToChartData(area),
          min: min,
          max: max,
        };

        ACTION_CONTEXT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Stock_Situaton":
        title = "Stock Situaton";
        consttype = VIEW_TYPES.waterfall;

        constInputList = transformObjectToChartData(
          context.ACTION_CONTEXT[key]
        );

        ACTION_CONTEXT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "$.DATA.NEW_INVENTORY_PLAN":
        title = getTranslation("INVENTORY", "labels");
        consttype = VIEW_TYPES.line_chart;
        const ACTION_CONTEXT_JSON_NEW_INVENTORY = jsonpath
          .query(context?.at(0)?.data, "$.DATA.NEW_INVENTORY_PLAN")
          ?.at(0);
        const ACTION_CONTEXT_JSON_INVENTORY = jsonpath
          .query(context?.at(0)?.data, "$.DATA.INVENTORY")
          ?.at(0);

        if (
          !ACTION_CONTEXT_JSON_NEW_INVENTORY &&
          !ACTION_CONTEXT_JSON_INVENTORY
        ) {
          ACTION_CONTEXT.push({
            status: 400,
            message: "Error processing inventory missing",
          });
        } else {
          constInputList = transformObjectToMultipleLinesChart(
            [
              ...(ACTION_CONTEXT_JSON_INVENTORY
                ? [
                    {
                      data: ACTION_CONTEXT_JSON_INVENTORY,
                      name: "INVENTORY",
                    },
                  ]
                : []),
              ...(ACTION_CONTEXT_JSON_NEW_INVENTORY
                ? [
                    {
                      data: ACTION_CONTEXT_JSON_NEW_INVENTORY,
                      name: "NEW_INVENTORY",
                    },
                  ]
                : []),
            ],
            "DATE",
            "QUANTITY_ON_HAND"
          );

          ACTION_CONTEXT.push({
            status: 200,
            title: title,
            type: consttype,
            InputList: constInputList,
          });
        }

        break;
      case "Fulfillement_Shipping_Conditions":
        title = "Shipping Conditions";
        consttype = VIEW_TYPES.map;

        constInputList = await transformObjectToMap(
          formatNmber,
          formatDate,
          currency,
          key,
          context.ACTION_CONTEXT[key]
        );
        ACTION_CONTEXT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });

        break;
      case "Alternative_options":
        title = "Alternative Options";
        consttype = VIEW_TYPES.inputs;

        constInputList = transformObjectToListInput(
          context.ACTION_CONTEXT[key]
        );
        ACTION_CONTEXT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });
        break;
      case "$.DATA.PURCHASE_ORDER":
        title = getTranslation("PURCHASE_ORDER", "labels");
        consttype = VIEW_TYPES.flow_chart;

        const PURCHASE_ORDER_JSON = jsonpath
          .query(context?.at(0)?.data, "$.DATA.PURCHASE_ORDER")
          ?.at(0);

        constInputList = transformDataToFlowChart(
          formatNmber,
          formatDate,
          currency,
          PURCHASE_ORDER_JSON,
          FLOW_TYPES.PURCHASE_ORDER,
          dataNeededAttributes,
          orgActionSettings,
          confsActionSettings,
          context?.at(0)?.data
        );

        if (constInputList?.errors?.length !== 0) {
          ACTION_CONTEXT.push({
            status: 400,
            title: title,
            type: consttype,
            InputList: constInputList,
          });
        } else {
          ACTION_CONTEXT.push({
            status: 200,
            title: title,
            type: consttype,
            InputList: constInputList,
          });
        }
        break;
      case "$.DATA.PROCUREMENT_SHIPPING_CONDITIONS":
        title = getTranslation("PROCUREMENT_SHIPPING_CONDITIONS", "labels");
        consttype = VIEW_TYPES.map;
        const PROCUREMENT_SHIPPING_CONDITIONS_JSON = jsonpath
          .query(context?.at(0)?.data, "$.DATA.PROCUREMENT_SHIPPING_CONDITIONS")
          ?.at(0);

        constInputList = await transformObjectToMap(
          formatNmber,
          formatDate,
          currency,
          "Procurement_Shipping_Conditions",
          PROCUREMENT_SHIPPING_CONDITIONS_JSON,
          context?.at(0)?.data
        );

        ACTION_CONTEXT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });
        break;
      case "$.DATA.PROCUREMENT_SUBSTITUTION":
        title = getTranslation("PROCUREMENT_SUBSTITUTION", "labels");

        consttype = VIEW_TYPES.inputs;
        const PROCUREMENT_SUBSTITUTION_JSON = jsonpath
          .query(context?.at(0)?.data, "$.DATA.PROCUREMENT_SUBSTITUTION")
          ?.at(0);
        /**   get risk item description from item master */
        const item_description = jsonpath
          .query(context?.at(0)?.data, "$.DATA.ITEM_MASTER")
          ?.at(0)?.ITEM_DESCRIPTION;

        const input = {
          item_description: item_description,
          substitute_item_code:
            PROCUREMENT_SUBSTITUTION_JSON?.SUBSTITUTE_ITEM_CODE,
          substitute_item_description:
            PROCUREMENT_SUBSTITUTION_JSON?.SUBSTITUTE_ITEM_DESCRIPTION,
        };
        constInputList = transformObjectToListInput(input);

        ACTION_CONTEXT.push({
          title: title,
          type: consttype,
          InputList: constInputList,
        });
        break;

      case "$.DATA.PROCUREMENT_SHIPPING_CONDITION":
        title = getTranslation("PROCUREMENT_SHIPPING_CONDITIONS", "labels");
        consttype = VIEW_TYPES.map;
        const PROCUREMENT_SHIPPING_CONDITION_JSON = jsonpath
          .query(context?.at(0)?.data, "$.DATA.PROCUREMENT_SHIPPING_CONDITION")
          ?.at(0);

        constInputList = await transformObjectToMap(
          formatNmber,
          formatDate,
          currency,
          "Procurement_Shipping_Conditions",
          PROCUREMENT_SHIPPING_CONDITION_JSON,
          context?.at(0)?.data
        );

        if (constInputList?.errors?.length !== 0) {
          ACTION_CONTEXT.push({
            status: 400,
            title: title,
            type: consttype,
            InputList: constInputList,
          });
        } else {
          ACTION_CONTEXT.push({
            status: 200,
            title: title,
            type: consttype,
            InputList: constInputList,
          });
        }
        break;
    }
  }
  return ACTION_CONTEXT;
}

export const transformStepsLogic = (currentAction: any) => {
  let transformedStep: any[] = [];
  let foundProcess = 0;
  let foundRejected = 0;
  if (currentAction?.status === STATUS.OPEN) {
    foundProcess = foundProcess + 1;
  }
  const sortedExecutions = currentAction?.executions
    ?.slice()
    .sort((a: any, b: any) => {
      return a.execution_conf_priority - b.execution_conf_priority;
    });
  sortedExecutions?.forEach((item: any) => {
    let status =
      item.status === "BLOCKED" ||
      (item?.status === STATUS.COMPLETE && item?.contexts?.at(0)?.reason?.id)
        ? "error"
        : item.status === "COMPLETE"
        ? "finish"
        : "process";

    if (status === "process") {
      foundProcess = foundProcess + 1;
    }
    if (foundRejected >= 1) {
      status = "wait";
    }
    if (status === "error") {
      foundRejected = foundRejected + 1;
    }
    if (status === "process" && foundProcess > 1) {
      status = "wait";
    }

    if (currentAction?.status === STATUS.BLOCKED) {
      status = "wait";
    }
    transformedStep.push({
      status: status,
      title: item?.execution_conf_name,
    });
  });
  foundProcess = 0;
  return transformedStep;
};

export const findCityByLocationId = (
  objects: LocationMaterItem[] | LocationMaterItem[][],
  code: string
): string => {
  const flattenedArray = Array.isArray(objects) ? objects.flat() : objects;
  const foundObject = flattenedArray.find(
    (obj: LocationMaterItem) => obj.LOCATION_ID === code
  );
  return foundObject ? foundObject.LOCATION_CITY : "";
};

const processMapData = async (
  item: any,
  list: any,
  context: any,
  type: string
) => {
  let errorList: any = [];
  try {
    const {
      CO2_EMISSION_PER_KG_SHIPPED,
      DISTANCE_KM,
      LEAD_TIME_DAYS,
      RECEIVING_LOCATION,
      ROUTE_DESCRIPTION,
      SHIPPING_LOCATION_CODE,
      SUPPLIER_CODE,
    } = item;

    const jsonpath = require("jsonpath");
    const LOCATION_MASER_JSON = jsonpath.query(
      context,
      "$.DATA.MASTER.LOCATION_MASTER"
    );
    const SUPPLIER_MASER_JSON = jsonpath.query(
      context,
      "$.DATA.MASTER.SUPPLIER_MASTER"
    );
    if (!LOCATION_MASER_JSON || !SUPPLIER_MASER_JSON) {
      if (!LOCATION_MASER_JSON) {
        errorList.push({ status: 400, message: "Error in location master" });
        throw errorList;
      } else {
        errorList.push({
          status: 400,
          message: "Error in supplier location master",
        });
        throw errorList;
      }
    } else {
      let shipping_location: any;
      if (type === "Procurement_Shipping_Conditions") {
        shipping_location = SUPPLIER_MASER_JSON?.at(0)?.SUPPLIER_CITY;
      } else if (type === "Internal_Routes") {
        shipping_location = findCityByLocationId(
          LOCATION_MASER_JSON,
          SHIPPING_LOCATION_CODE
        );
      }

      const receiving_location = findCityByLocationId(
        LOCATION_MASER_JSON,
        RECEIVING_LOCATION
      );
      const error = checkAttributesValidity({
        LOCATION_MASER: LOCATION_MASER_JSON,
        receiving_location: receiving_location,
        shiping_location: shipping_location,
      });

      if (error.length === 0) {
        const shiping = await geocodeCity(shipping_location);
        const receiving = await geocodeCity(receiving_location);

        if (shiping && receiving) {
          let waypoints = createWaypoints(shiping, receiving);

          let popupContent = `Distance: ${DISTANCE_KM}, Lead Time: ${LEAD_TIME_DAYS} days`;
          if (type === "Procurement_Shipping_Conditions") {
            popupContent += `, Cost per Unit: ${CO2_EMISSION_PER_KG_SHIPPED}`;
          } else if (type === "Internal_Routes") {
            const { COST_PER_UNIT_CURRENCY } = item;
            popupContent += `, Cost per Unit: ${COST_PER_UNIT_CURRENCY}, CO2 Emission: ${CO2_EMISSION_PER_KG_SHIPPED}`;
          }
          popupContent += `, Route description: ${ROUTE_DESCRIPTION}`;

          list.push({
            waypoints,
            popupContent,
          });
        } else {
          throw errorList;
        }
      } else {
        errorList.push(...error);
        throw errorList;
      }
    }
  } catch (error) {
    errorList.push(error);
    throw errorList;
  }
};

const getErrorWarnings = (DATA: any): any => {
  const result: any = {
    type: VIEW_TYPES.dynamic_table,
    status: 200,
    InputList: [],
  };

  const processEntries = (severity: string, entries: any[]) => {
    if (entries?.length > 0) {
      result.InputList.push(
        ...entries.map((entry: any) => ({
          SEVERITY: severity,
          CODE: entry?.CODE?.replace(/[-_]/g, " ")
            .toLowerCase()
            .replace(/(?:^|\s)\S/g, (c: any) => c.toUpperCase()),
          DETAILS: (entry?.DETAILS?.data || entry?.DETAILS?.DATA)
            .replace(/[-_]/g, " ")
            .toLowerCase()
            .replace(/(?:^|\s)\S/g, (c: any) => c.toUpperCase()),
          MODULE: entry?.MODULE?.replace(/[-_]/g, " ")
            .toLowerCase()
            .replace(/(?:^|\s)\S/g, (c: any) => c.toUpperCase()),
        }))
      );
    }
  };

  processEntries("ERROR", DATA?.ERROR);
  processEntries("WARNING", DATA?.WARNING);
  processEntries("INFO", DATA?.INFO);

  return result;
};
