import { ProColumns } from "@ant-design/pro-components";
import { DatePicker } from "antd";
import { DASHBOARDS } from "../../../../../constants/dashboards";
import { COPILOT_TYPES } from "../../../../../constants/copilottypes";

export const DefineTableColumns = (props: any) => {
  const { dashboard, copilot } = props;

  const ACTION_TYPE_OPTIONS =
    copilot === COPILOT_TYPES.PROCUREMENT
      ? {
          "Alternate supplier": {
            text: "Alternate supplier",
          },
          Substitution: { text: "Substitution" },
          "Stock Rebalancing": { text: "Stock Rebalancing" },
          "Express Delivery": { text: "Express Delivery" },
          "Partial Delivery": { text: "Partial Delivery" },
          "No action": { text: "No action" },
        }
      : copilot === COPILOT_TYPES.FULFILLEMENT
      ? {
          "Alternate DC": {
            text: "Alternate DC",
          },
          Substitution: { text: "Substitution" },
          "Express Delivery": { text: "Express Delivery" },
          "Partial Delivery": { text: "Partial Delivery" },
          "No action": { text: "No action" },
        }
      : copilot === COPILOT_TYPES.DEMAND_MATCHING
      ? {
          "Expedite production": {
            text: "Expedite production",
          },
          "Expedite deployment": { text: "Expedite deployment" },
          "Reallocate demand to markets": {
            text: "Reallocate demand to markets",
          },
          "No action": { text: "No action" },
        }
      : copilot === COPILOT_TYPES.INBOUND_FREIGHT
      ? {
          "Early custom clearance": {
            text: "Early custom clearance",
          },
          "Expedited custom clearance": {
            text: "Expedited custom clearance",
          },
          "Deferred custom clearance": {
            text: "Deferred custom clearance",
          },
          "Expedited truck transfer": {
            text: "Expedited truck transfer",
          },
          "Rerouted truck transfer": {
            text: "Rerouted truck transfer",
          },
          "No action": { text: "No action" },
        }
      : {
          "Delay production": {
            text: "Delay production",
          },
          "Delay deployment": { text: "Delay deployment" },
          "Reallocate demand to markets": {
            text: "Reallocate demand to markets",
          },
          "No action": { text: "No action" },
        };

  const columns: ProColumns[] = [
    {
      title: "Date Range",
      dataIndex: "dateRange",
      valueType: "dateRange",
      render: (text, record) => (
        <DatePicker.RangePicker
          onCalendarChange={(dates) => {
            console.log(dates);
          }}
        />
      ),
    },
    {
      title: "Risk Status",
      dataIndex: "risk_status",
      sorter: true,
      valueType: "select",
      ellipsis: true,
      valueEnum: {
        Open: { text: "Open", disabled: dashboard === DASHBOARDS.ACTION_ROI },
        "In progress": {
          text: "In progress",
          disabled: dashboard === DASHBOARDS.ACTION_ROI,
        },
        Accepted: { text: "Accepted" },
        Rejected: { text: "Rejected" },
      },
      fieldProps: {
        mode: "multiple",
      },
    },
    {
      title: "Risk Location",
      dataIndex: "risk_location",
      sorter: true,
      valueType: "text",
      ellipsis: true,
    },
    {
      title: "Risk Item",
      dataIndex: "risk_item",
      sorter: true,
      valueType: "text",
      ellipsis: true,
    },
    {
      title: "Action Type",
      dataIndex: "action_type",
      sorter: true,
      valueType: "select",
      ellipsis: true,
      valueEnum: ACTION_TYPE_OPTIONS,
      fieldProps: {
        mode: "multiple",
      },
    },
  ];

  return columns;
};
