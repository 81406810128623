import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, Card, Spin, Tabs, TabsProps } from "antd";
import "../runningCss.css";
import AddReasonCodeModal from "../view_tables/reasons-code/AddReasonCode";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../logic/runningShared.logic";
import { useSelector } from "react-redux";
import { SharedContext } from "../context/SharedContext";
import { MainContext } from "../../../../utils/context";
import StepsComponent from "./StepsComponent";
import { useTranslation } from "react-i18next";
import EmptyChartCard from "../../dashboards/common/EmptyChart";
import { RootState } from "../../../../redux/store/store";
import { MappingSideBarfunction } from "../logic/runningMappingFunctions";
import SideBarTabs from "./sideBarTabs";
import { createKey, getTranslation } from "../../../../utils/transaltion";
import { RUNNING_MENU } from "../../../../constants/riskMenus";
import StartRisk from "../StartRisk";
import { DEBUG_MODE } from "../../../../constants/server";
import { LeftOutlined } from "@ant-design/icons";
import { STATUS } from "../../../../constants/status";
import { Reason } from "../../../../interfaces/reasons";
import {
  demand_Matching_Configs,
  fulfillement_Configs,
  inbound_Freight_Configs,
  supply_Matching_Configs,
} from "../data/SideBarConfig";

const CurrentActionDetails = (props: any) => {
  const { t } = useTranslation();
  const {
    formatNmber,
    formatDate,
    currency,
    menu,
    copilotName,
    loading,
    setLoading,
    acceptRejectState,
    selectedRisk,
    handleRowClick,
  } = props;

  const {
    setSelectedRisk,
    setAcceptRejectState,
  }: {
    setSelectedRisk: Dispatch<any>;
    setAcceptRejectState: Dispatch<SetStateAction<boolean>>;
    setLoadingReject: React.Dispatch<React.SetStateAction<boolean>>;
  } = props;

  /** shared context */
  const globalContext = useContext(MainContext);
  const {
    data,
    setData,
    currentAction,
    setCurrentActionSettings,
    setDescriptorsList,
    setTypeReject,
    setCurrentActionExecutionRejectArguments,
  } = useContext(SharedContext);

  /** shared logic */
  const { filterCurrentActionreasons, acceptActionOrExecution } =
    useSharedContextFunctions() as SharedContextFunctions;

  /** local vars */
  const [listViews, setListViews] = useState<any>(null);
  const [activeTabKey, setActiveTabKey] = useState<string>("1");
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState<boolean>(false);
  let currentConfig: any;
  /** get predefinedActionList from redux */
  const predefinedActionList = useSelector(
    (state: RootState) => state.predefinedActionOrgaReducer
  );
  /** reasons actions from redux */
  const reasonsActions: Reason[] = useSelector(
    (state: RootState) => state.reasonsActionsReducer
  );
  /**reasonsexecutions from Redux */
  const reasonsExecutions: Reason[] = useSelector(
    (state: RootState) => state.reasonsExecutionsReducer
  );
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleMapping = async () => {
    setLoading(true);

    if (createKey(copilotName) === createKey("fulfillment")) {
      currentConfig = fulfillement_Configs?.find(
        (config: any) => config.name === currentAction?.action_conf_name
      );
    }
    if (createKey(copilotName) === createKey("demand matching")) {
      currentConfig = demand_Matching_Configs?.find(
        (config: any) => config.name === currentAction?.action_conf_name
      );
    }
    if (createKey(copilotName) === createKey("supply matching")) {
      currentConfig = supply_Matching_Configs?.find(
        (config: any) => config.name === currentAction?.action_conf_name
      );
    }
    if (createKey(copilotName) === createKey("inbound freight management")) {
      currentConfig = inbound_Freight_Configs?.find(
        (config: any) => config.name === currentAction?.action_conf_name
      );
    }

    if (currentAction?.contexts?.length > 0) {
     setLoading(true);
      await MappingSideBarfunction(
        currentAction,
        {},
        currentConfig,
        {
          formatNumber: formatNmber,
          currency: currency,
          formatDate: formatDate,
        },
        {}
      ).then((response: any) => {
        console.log(response);
        setLoading(false);
        setListViews(response);
      });
    } else {
      setLoading(false);
      setListViews(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      !globalContext ||
      !globalContext.context.orga ||
      !currentAction?.contexts
    ) {
      return;
    }
    console.log("currentAction", currentAction);

    handleMapping();
  }, [globalContext, currentAction]);

  useEffect(() => {
    if (acceptRejectState) {
      setActiveTabKey("0");
    } else {
      // Ensure activeTabKey defaults to a valid key
      setActiveTabKey((prevActiveKey) => {
        const availableKeys = Object.keys(listViews || {});
        // Default to the first available key or a valid key if available
        return availableKeys.length > 0 ? availableKeys[0] : "0";
      });
    }
  }, [acceptRejectState, listViews, currentAction]);

  useEffect(() => {
    if (!currentAction) {
      setLoading(false);
      setListViews(null);
    }
  }, [menu, copilotName, currentAction]);

  // Function to generate tabs items dynamically
  const generateTabsItems = () => {
    const items: TabsProps["items"] = Object.keys(listViews)
      ?.filter((key) => listViews[key] !== false)
      ?.map((key) => ({
        key,
        label: getTranslation(key, "data"),
        children: (
          <SideBarTabs
            isTabActive={activeTabKey === key}
            category={key}
            listViews={listViews[key]}
            formatDate={formatDate}
            formatNmber={formatNmber}
            currency={currency}
            dataNeededAttributes={listViews?.dataNeededAttributes}
            orgActionSettings={listViews?.orgActionSettings}
            confsActionSettings={listViews?.confsActionSettings}
          />
        ),
      }));

    if (acceptRejectState) {
      items.unshift({
        key: "0",
        label: getTranslation("REJECT", "labels"),
        children: (
          <div>
            <AddReasonCodeModal
              closeModal={() => {}}
              reasonsCode={data}
              setReasonsCode={setData}
              currentRiskId={selectedRisk?.id}
              setSelectedRisk={setSelectedRisk}
              menu={menu}
              copilotName={copilotName}
              setAcceptRejectState={setAcceptRejectState}
            />
          </div>
        ),
      });
    }

    return items;
  };

  return (
    <>
      <Card
        title={
          <>
            {/* <Row> */}{" "}
            <div className="header-title">
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <span
                  onClick={() => handleRowClick(false)}
                  style={{
                    fontSize: "1.6rem",
                    color: "#004c97",
                    cursor: "pointer",
                  }}
                >
                  <LeftOutlined />
                </span>
                <h4>{currentAction?.action_conf_name}</h4>
              </div>

              {DEBUG_MODE && (
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "#004C97",
                    borderColor: "#004C97",
                    marginBottom: window.innerWidth <= 600 ? "10px" : "0",
                    marginRight: "10px",
                  }}
                  onClick={handleOpenModal}
                  className="blue-gradient-btn"
                >
                  Start Risk
                </Button>
              )}
              <StartRisk
                visible={modalVisible}
                onClose={handleCloseModal}
                copilotName={copilotName}
              />
              {menu === RUNNING_MENU.RISKS_HISTORY &&
                selectedRisk?.status !== STATUS.COMPLETE && (
                  <div>
                    {/* accept button */}
                    <Button
                      // type="primary"
                      style={{
                        backgroundColor: "#004C97",
                        borderColor: "#004C97",
                        marginBottom: window.innerWidth <= 600 ? "10px" : "0",
                        marginRight: "10px",
                      }}
                      loading={loadingAccept ? true : false}
                      onClick={() => {
                        setLoadingAccept(true);

                        setAcceptRejectState(false);
                        const currentExec =
                          currentAction?.status === STATUS.WAIT_FOR_EXECUTION
                            ? currentAction?.current_execution
                            : null;
                        if (currentExec) {
                          acceptActionOrExecution(
                            copilotName,
                            selectedRisk?.id,
                            menu,
                            currentExec?.id,
                            currentExec?.contexts?.at(0)?.id,
                            "Execution",
                            setLoadingAccept
                          );
                        } else {
                          acceptActionOrExecution(
                            copilotName,
                            selectedRisk?.id,
                            menu,
                            currentAction?.id,
                            currentAction?.contexts?.at(0)?.id,
                            "Action",
                            setLoadingAccept
                          );
                        }
                      }}
                      className="blue-gradient-btn"
                    >
                      {t("ACCEPT")}
                    </Button>
                    {/* reject button */}
                    <Button
                      //key={item.id}
                      danger
                      className="orange-gradient-btn"
                      onClick={() => {
                        const currentExec =
                          currentAction?.status === STATUS.WAIT_FOR_EXECUTION
                            ? currentAction?.current_execution
                            : null;
                        setAcceptRejectState(true);
                        let currentActExec: any = null;
                        if (currentExec) {
                          setTypeReject("Execution");
                          filterCurrentActionreasons(
                            "EXECUTION",
                            reasonsExecutions,
                            currentAction?.current_execution
                              ?.execution_conf_src_id
                          );
                          currentActExec = currentExec;
                        } else {
                          setTypeReject("Action");
                          filterCurrentActionreasons(
                            "ACTION",
                            reasonsActions,
                            currentAction?.action_conf_src_id
                          );
                          currentActExec = currentAction;
                        }

                        setCurrentActionExecutionRejectArguments({
                          type: "Action",
                          id: currentActExec?.id,
                          contexts: {
                            id: currentActExec?.contexts?.at(0)?.id,
                          },
                        });
                      }}
                      style={{
                        backgroundColor: "#f5222d",
                        borderColor: "#f5222d",
                        color: "white",
                      }}
                    >
                      {t("REJECT")}
                    </Button>
                  </div>
                )}
            </div>
            {/* </Row> */}
          </>
        }
        bordered
        style={{
          // maxHeight: window.innerHeight + 55,
          height: "100%",
          marginBottom: 0,
          overflow: "auto",
          borderBlock: 1,
          borderInlineEnd: 1,
        }}
      >
        {selectedRisk ? (
          <div
            style={{
              height: "100%",
            }}
          >
            <Spin spinning={loading} size="large" tip="Loading...">
              {listViews ? (
                <>
                  <StepsComponent />

                  <Tabs
                    activeKey={activeTabKey}
                    onChange={(key: any) => setActiveTabKey(key)}
                    items={generateTabsItems()}
                  />
                </>
              ) : (
                <EmptyChartCard />
              )}
            </Spin>
          </div>
        ) : (
          <EmptyChartCard />
        )}
      </Card>
    </>
  );
};

export default CurrentActionDetails;
