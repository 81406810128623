import { useEffect, useState } from "react";
import { Form, Select, Spin, FormInstance, Tag } from "antd";
import { t } from "i18next";

import { getTranslation } from "../../../../../../utils/transaltion";
import { Scope } from "../../../../../../interfaces/scope";
import ScopeTag from "./ScopeTag";

const SELECT_ALL = "Select all";

interface ISelectWithAddScopeValue {
  options: string[];
  scopeValues: string[];
  userScopes: Scope[];
  scopeKey: string | undefined;
  isScopeValueLoading: boolean;
  setValues: (value: string[]) => void;
  form: FormInstance<any>;
}

export function SelectWithAddScopeValue(props: ISelectWithAddScopeValue) {
  const {
    options,
    scopeValues: values,
    scopeKey,
    isScopeValueLoading,
    userScopes,
    setValues,
    form,
  } = props;
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    const header = options?.some((v) => !v) ? [""] : [];
    const rest = options?.filter(Boolean);
    setItems(
      header.concat(rest)?.map((value) => {
        return {
          label: value || SELECT_ALL,
          value: value,
        };
      })
    );
    const defaultValues = userScopes
      ?.filter((scope) => scope.key === scopeKey)
      .map((scope) => scope.value);
    setValues(defaultValues);
    form.setFieldValue("items", defaultValues);
  }, [options]);

  return (
    <Form form={form}>
      <Form.Item
        label={getTranslation("VALUE", "labels")}
        name="items"
        labelCol={{ span: 4 }}
        labelAlign="left"
      >
        <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder={getTranslation("SELECT_SCOPE_VALUES", "labels")}
          onChange={(selected = [], option) => {
            let selectedValues: string[] = [];
            if (!selected.length) {
              selectedValues = [];
            } else {
              if (!!!selected.at(-1)) {
                selectedValues = [""];
              } else {
                selectedValues = selected.filter(Boolean);
              }
            }
            setValues(selectedValues);
            form.setFieldValue("items", selectedValues);
          }}
          tokenSeparators={[","]}
          options={items}
          value={values}
          dropdownRender={(menu) => (
            <Spin spinning={isScopeValueLoading}>{menu}</Spin>
          )}
          tagRender={(props) => {
            const { label, value, closable, onClose } = props;
            return (
              <ScopeTag
                closable={closable}
                onClose={onClose}
                scope={{
                  key: scopeKey || "",
                  value: value,
                  all: !!!value,
                  id: scopeKey + value,
                }}
              />
            );
          }}
          allowClear
        />
      </Form.Item>
    </Form>
  );
}

export default SelectWithAddScopeValue;
