export const supply_matching_data = [
  {
    running_risk_id: 20000,
    risk_date: 293 + 45270,
    risk_status: "Open",
    risk_location: 152,
    risk_item: "SKU-995",
    quantity_at_risk: 15694,
    supply_cost_at_risk: 152231.8,
    action_type: "Delay production",
    quantity_mitigated: 13810.72,
    supply_cost_mitigated: 133963.98399999997,
    production_cost: 11462.8976,
    freight_cost: 4833.7519999999995,
    cost_impact: 16296.6496,
  },
  {
    running_risk_id: 20001,
    risk_date: 293 + 45269,
    risk_status: "In progress",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 43521,
    supply_cost_at_risk: 382984.8,
    action_type: "Delay production",
    execution_stage: "production validation",
    quantity_mitigated: 36122.43,
    supply_cost_mitigated: 317877.38399999996,
    production_cost: 26369.373900000002,
    freight_cost: 10475.5047,
    cost_impact: 36844.878600000004,
  },
  {
    running_risk_id: 20002,
    risk_date: 293 + 45268,
    risk_status: "In progress",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 42885,
    supply_cost_at_risk: 338791.5,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 36881.1,
    supply_cost_mitigated: 291360.69,
    production_cost: 21391.037999999997,
    freight_cost: 15858.873,
    cost_impact: 37249.91099999999,
  },
  {
    running_risk_id: 20003,
    risk_date: 293 + 45232,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 42891,
    supply_cost_at_risk: 270213.3,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 40317.54,
    supply_cost_mitigated: 254000.50199999998,
    production_cost: 30641.3304,
    freight_cost: 11692.086599999999,
    cost_impact: 42333.417,
  },
  {
    running_risk_id: 20004,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 134,
    risk_item: "SKU-792",
    quantity_at_risk: 24375,
    supply_cost_at_risk: 199875,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 23887.5,
    supply_cost_mitigated: 195877.5,
    production_cost: 19587.75,
    freight_cost: 8838.375,
    cost_impact: 28426.125,
  },
  {
    running_risk_id: 20005,
    risk_date: 293 + 45237,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 134,
    risk_item: "SKU-995",
    quantity_at_risk: 7615,
    supply_cost_at_risk: 67012,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 6092,
    supply_cost_mitigated: 53609.6,
    production_cost: 4081.64,
    freight_cost: 2071.28,
    cost_impact: 6152.92,
  },
  {
    running_risk_id: 20006,
    risk_date: 293 + 45241,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 40640,
    supply_cost_at_risk: 308864,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 33731.2,
    supply_cost_mitigated: 256357.12,
    production_cost: 18552.159999999996,
    freight_cost: 12817.855999999998,
    cost_impact: 31370.015999999996,
  },
  {
    running_risk_id: 20007,
    risk_date: 293 + 45242,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 47979,
    supply_cost_at_risk: 302267.7,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 40782.15,
    supply_cost_mitigated: 256927.545,
    production_cost: 28139.6835,
    freight_cost: 13458.109499999999,
    cost_impact: 41597.793,
  },
  {
    running_risk_id: 20008,
    risk_date: 293 + 45246,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 36787,
    supply_cost_at_risk: 356833.9,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 33476.17,
    supply_cost_mitigated: 324718.849,
    production_cost: 19750.9403,
    freight_cost: 10042.851,
    cost_impact: 29793.791299999997,
  },
  {
    running_risk_id: 20009,
    risk_date: 293 + 45248,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 34025,
    supply_cost_at_risk: 241577.5,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 31643.25,
    supply_cost_mitigated: 224667.075,
    production_cost: 24365.3025,
    freight_cost: 13606.5975,
    cost_impact: 37971.9,
  },
  {
    running_risk_id: 20010,
    risk_date: 293 + 45249,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 43189,
    supply_cost_at_risk: 302323,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 36710.65,
    supply_cost_mitigated: 256974.55000000002,
    production_cost: 27532.9875,
    freight_cost: 14317.1535,
    cost_impact: 41850.141,
  },
  {
    running_risk_id: 20011,
    risk_date: 293 + 45252,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 18671,
    supply_cost_at_risk: 143766.7,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 15123.51,
    supply_cost_mitigated: 116451.027,
    production_cost: 8469.1656,
    freight_cost: 4688.2881,
    cost_impact: 13157.4537,
  },
  {
    running_risk_id: 20012,
    risk_date: 293 + 45255,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-792",
    quantity_at_risk: 25181,
    supply_cost_at_risk: 158640.3,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 23670.14,
    supply_cost_mitigated: 149121.88199999998,
    production_cost: 16332.3966,
    freight_cost: 11124.9658,
    cost_impact: 27457.362399999998,
  },
  {
    running_risk_id: 20013,
    risk_date: 293 + 45257,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 39351,
    supply_cost_at_risk: 326613.3,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 38170.47,
    supply_cost_mitigated: 316814.901,
    production_cost: 23665.6914,
    freight_cost: 11451.141000000001,
    cost_impact: 35116.8324,
  },
  {
    running_risk_id: 20014,
    risk_date: 293 + 45260,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 11881,
    supply_cost_at_risk: 91483.7,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 10098.85,
    supply_cost_mitigated: 77761.145,
    production_cost: 6867.218000000001,
    freight_cost: 2827.678,
    cost_impact: 9694.896,
  },
  {
    running_risk_id: 20015,
    risk_date: 293 + 45233,
    risk_status: "Rejected",
    rejection_code: "Raw material excess inventory",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-995",
    quantity_at_risk: 9247,
    supply_cost_at_risk: 69352.5,
    action_type: "Delay production",
    execution_stage: "production validation",
    quantity_mitigated: 8969.59,
    supply_cost_mitigated: 67271.925,
    production_cost: 7624.1515,
    freight_cost: 4126.0114,
    cost_impact: 11750.1629,
  },
  {
    running_risk_id: 20016,
    risk_date: 293 + 45235,
    risk_status: "Rejected",
    rejection_code: "Production capacity constraint",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 36210,
    supply_cost_at_risk: 325890,
    action_type: "Delay production",
    execution_stage: "production validation",
    quantity_mitigated: 35485.8,
    supply_cost_mitigated: 319372.2,
    production_cost: 20581.764000000003,
    freight_cost: 11000.598,
    cost_impact: 31582.362,
  },
  {
    running_risk_id: 20017,
    risk_date: 293 + 45237,
    risk_status: "Rejected",
    rejection_code: "Raw material excess inventory",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-995",
    quantity_at_risk: 29465,
    supply_cost_at_risk: 232773.5,
    action_type: "Delay production",
    execution_stage: "production validation",
    quantity_mitigated: 25045.25,
    supply_cost_mitigated: 197857.475,
    production_cost: 21288.4625,
    freight_cost: 9016.29,
    cost_impact: 30304.752500000002,
  },
  {
    running_risk_id: 20018,
    risk_date: 293 + 45242,
    risk_status: "Rejected",
    rejection_code: "Production capacity constraint",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 45986,
    supply_cost_at_risk: 298909,
    action_type: "Delay production",
    execution_stage: "production validation",
    quantity_mitigated: 38628.24,
    supply_cost_mitigated: 251083.56,
    production_cost: 25880.9208,
    freight_cost: 16223.860799999999,
    cost_impact: 42104.7816,
  },
  {
    running_risk_id: 20019,
    risk_date: 293 + 45245,
    risk_status: "Rejected",
    rejection_code: "Production capacity constraint",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 37873,
    supply_cost_at_risk: 265111,
    action_type: "Delay production",
    execution_stage: "production validation",
    quantity_mitigated: 36736.81,
    supply_cost_mitigated: 257157.66999999998,
    production_cost: 24613.6627,
    freight_cost: 16164.196399999999,
    cost_impact: 40777.8591,
  },
  {
    running_risk_id: 20020,
    risk_date: 293 + 45246,
    risk_status: "Rejected",
    rejection_code: "Production capacity constraint",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 13804,
    supply_cost_at_risk: 88345.6,
    action_type: "Delay production",
    execution_stage: "production validation",
    quantity_mitigated: 11595.36,
    supply_cost_mitigated: 74210.304,
    production_cost: 8232.705600000001,
    freight_cost: 4174.3296,
    cost_impact: 12407.035200000002,
  },
  {
    running_risk_id: 20021,
    risk_date: 293 + 45250,
    risk_status: "Rejected",
    rejection_code: "Freight forwarder calendar",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 9976,
    supply_cost_at_risk: 72824.8,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 9477.2,
    supply_cost_mitigated: 69183.56000000001,
    production_cost: 5970.636000000001,
    freight_cost: 2653.6160000000004,
    cost_impact: 8624.252000000002,
  },
  {
    running_risk_id: 20022,
    risk_date: 293 + 45251,
    risk_status: "Rejected",
    rejection_code: "No freight forwarder capacity",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-995",
    quantity_at_risk: 13610,
    supply_cost_at_risk: 95270,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 10888,
    supply_cost_mitigated: 76216,
    production_cost: 6315.04,
    freight_cost: 5117.36,
    cost_impact: 11432.4,
  },
  {
    running_risk_id: 20023,
    risk_date: 293 + 45254,
    risk_status: "Rejected",
    rejection_code: "Freight forwarder calendar",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 6844,
    supply_cost_at_risk: 46539.2,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 6091.16,
    supply_cost_mitigated: 41419.888,
    production_cost: 2801.9336,
    freight_cost: 2010.0828,
    cost_impact: 4812.0163999999995,
  },
  {
    running_risk_id: 20024,
    risk_date: 293 + 45258,
    risk_status: "Rejected",
    rejection_code: "Freight forwarder calendar",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 30647,
    supply_cost_at_risk: 223723.1,
    action_type: "Delay production",
    execution_stage: "Freight",
    quantity_mitigated: 29727.59,
    supply_cost_mitigated: 217011.407,
    production_cost: 15755.6227,
    freight_cost: 7134.6216,
    cost_impact: 22890.2443,
  },
  {
    running_risk_id: 20025,
    risk_date: 293 + 45270,
    risk_status: "Open",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 19359,
    supply_cost_at_risk: 158743.8,
    action_type: "Reallocate demand to markets",
    quantity_mitigated: 16067.97,
    supply_cost_mitigated: 131757.35399999996,
    cost_impact: 0,
  },
  {
    running_risk_id: 20026,
    risk_date: 293 + 45268,
    risk_status: "In progress",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 15685,
    supply_cost_at_risk: 144302,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 14587.05,
    supply_cost_mitigated: 134200.86,
    cost_impact: 0,
  },
  {
    running_risk_id: 20027,
    risk_date: 293 + 45231,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 22343,
    supply_cost_at_risk: 212258.5,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 18321.26,
    supply_cost_mitigated: 174051.96999999997,
    cost_impact: 0,
  },
  {
    running_risk_id: 20028,
    risk_date: 293 + 45232,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 27419,
    supply_cost_at_risk: 263222.4,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 27144.81,
    supply_cost_mitigated: 260590.17600000006,
    cost_impact: 0,
  },
  {
    running_risk_id: 20029,
    risk_date: 293 + 45232,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 44312,
    supply_cost_at_risk: 341202.4,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 39880.8,
    supply_cost_mitigated: 307082.16000000003,
    cost_impact: 0,
  },
  {
    running_risk_id: 20030,
    risk_date: 293 + 45236,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 29927,
    supply_cost_at_risk: 275328.4,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 25737.22,
    supply_cost_mitigated: 236782.42400000006,
    cost_impact: 0,
  },
  {
    running_risk_id: 20031,
    risk_date: 293 + 45237,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-995",
    quantity_at_risk: 19974,
    supply_cost_at_risk: 187755.6,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 18775.56,
    supply_cost_mitigated: 176490.264,
    cost_impact: 0,
  },
  {
    running_risk_id: 20032,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 25609,
    supply_cost_at_risk: 184384.8,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 22023.74,
    supply_cost_mitigated: 158570.92799999999,
    cost_impact: 0,
  },
  {
    running_risk_id: 20033,
    risk_date: 293 + 45242,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 29262,
    supply_cost_at_risk: 283841.4,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 24580.08,
    supply_cost_mitigated: 238426.77600000004,
    cost_impact: 0,
  },
  {
    running_risk_id: 20034,
    risk_date: 293 + 45245,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 48757,
    supply_cost_at_risk: 468067.2,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 42418.59,
    supply_cost_mitigated: 407218.4639999999,
    cost_impact: 0,
  },
  {
    running_risk_id: 20035,
    risk_date: 293 + 45248,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 15679,
    supply_cost_at_risk: 125432,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 12856.78,
    supply_cost_mitigated: 102854.24,
    cost_impact: 0,
  },
  {
    running_risk_id: 20036,
    risk_date: 293 + 45252,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 8414,
    supply_cost_at_risk: 72360.4,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 7656.74,
    supply_cost_mitigated: 65847.96399999999,
    cost_impact: 0,
  },
  {
    running_risk_id: 20037,
    risk_date: 293 + 45253,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 32511,
    supply_cost_at_risk: 211321.5,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 26984.13,
    supply_cost_mitigated: 175396.845,
    cost_impact: 0,
  },
  {
    running_risk_id: 20038,
    risk_date: 293 + 45257,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-792",
    quantity_at_risk: 26719,
    supply_cost_at_risk: 219095.8,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 24581.48,
    supply_cost_mitigated: 201568.13599999997,
    cost_impact: 0,
  },
  {
    running_risk_id: 20039,
    risk_date: 293 + 45233,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 30778,
    supply_cost_at_risk: 258535.2,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 24930.18,
    supply_cost_mitigated: 209413.51200000002,
    cost_impact: 0,
  },
  {
    running_risk_id: 20040,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 49960,
    supply_cost_at_risk: 354716,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 47961.6,
    supply_cost_mitigated: 340527.36,
    cost_impact: 0,
  },
  {
    running_risk_id: 20041,
    risk_date: 293 + 45238,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-995",
    quantity_at_risk: 35432,
    supply_cost_at_risk: 336604,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 31888.8,
    supply_cost_mitigated: 302943.6,
    cost_impact: 0,
  },
  {
    running_risk_id: 20042,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 44100,
    supply_cost_at_risk: 383670,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 37485,
    supply_cost_mitigated: 326119.5,
    cost_impact: 0,
  },
  {
    running_risk_id: 20043,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 19756,
    supply_cost_at_risk: 156072.4,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 16792.6,
    supply_cost_mitigated: 132661.53999999998,
    cost_impact: 0,
  },
  {
    running_risk_id: 20044,
    risk_date: 293 + 45251,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-995",
    quantity_at_risk: 10842,
    supply_cost_at_risk: 73725.6,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 10733.58,
    supply_cost_mitigated: 72988.344,
    cost_impact: 0,
  },
  {
    running_risk_id: 20045,
    risk_date: 293 + 45235,
    risk_status: "Rejected",
    rejection_code: "Demand change",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-346",
    quantity_at_risk: 48290,
    supply_cost_at_risk: 338030,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 42012.3,
    supply_cost_mitigated: 294086.10000000003,
    cost_impact: 0,
  },
  {
    running_risk_id: 20046,
    risk_date: 293 + 45239,
    risk_status: "Rejected",
    rejection_code: "Demand change",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 9046,
    supply_cost_at_risk: 65131.2,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 7960.48,
    supply_cost_mitigated: 57315.45599999999,
    cost_impact: 0,
  },
  {
    running_risk_id: 20047,
    risk_date: 293 + 45243,
    risk_status: "Rejected",
    rejection_code: "Impact on revenue too high",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-346",
    quantity_at_risk: 10151,
    supply_cost_at_risk: 97449.6,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 9541.94,
    supply_cost_mitigated: 91602.62400000001,
    cost_impact: 0,
  },
  {
    running_risk_id: 20048,
    risk_date: 293 + 45247,
    risk_status: "Rejected",
    rejection_code: "Demand change",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 12786,
    supply_cost_at_risk: 90780.6,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 11507.4,
    supply_cost_mitigated: 81702.54000000001,
    cost_impact: 0,
  },
  {
    running_risk_id: 20049,
    risk_date: 293 + 45254,
    risk_status: "Rejected",
    rejection_code: "Impact on revenue too high",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 16715,
    supply_cost_at_risk: 152106.5,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 16213.55,
    supply_cost_mitigated: 147543.305,
    cost_impact: 0,
  },
  {
    running_risk_id: 20050,
    risk_date: 293 + 45269,
    risk_status: "Open",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 45717,
    supply_cost_at_risk: 288017.1,
    action_type: "Delay deployment",
    quantity_mitigated: 45717,
    supply_cost_mitigated: 288017.1,
    freight_cost: 76347.39,
    cost_impact: 76347.39,
  },
  {
    running_risk_id: 20051,
    risk_date: 293 + 45268,
    risk_status: "In progress",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 45320,
    supply_cost_at_risk: 362560,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 38522,
    supply_cost_mitigated: 308176,
    freight_cost: 56627.34,
    cost_impact: 56627.34,
  },
  {
    running_risk_id: 20052,
    risk_date: 293 + 45233,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 37011,
    supply_cost_at_risk: 359006.7,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 30719.13,
    supply_cost_mitigated: 297975.56100000005,
    freight_cost: 64510.172999999995,
    cost_impact: 64510.172999999995,
  },
  {
    running_risk_id: 20053,
    risk_date: 293 + 45242,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 26599,
    supply_cost_at_risk: 255350.4,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 22343.16,
    supply_cost_mitigated: 214494.336,
    freight_cost: 44686.32,
    cost_impact: 44686.32,
  },
  {
    running_risk_id: 20054,
    risk_date: 293 + 45248,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 25660,
    supply_cost_at_risk: 238638,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 23350.6,
    supply_cost_mitigated: 217160.57999999996,
    freight_cost: 48802.75399999999,
    cost_impact: 48802.75399999999,
  },
  {
    running_risk_id: 20055,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 23142,
    supply_cost_at_risk: 159679.8,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 19670.7,
    supply_cost_mitigated: 135727.83,
    freight_cost: 32259.948000000004,
    cost_impact: 32259.948000000004,
  },
  {
    running_risk_id: 20056,
    risk_date: 293 + 45238,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 24782,
    supply_cost_at_risk: 173474,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 20321.24,
    supply_cost_mitigated: 142248.68000000002,
    freight_cost: 38203.9312,
    cost_impact: 38203.9312,
  },
  {
    running_risk_id: 20057,
    risk_date: 293 + 45241,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-792",
    quantity_at_risk: 22176,
    supply_cost_at_risk: 184060.8,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 19071.36,
    supply_cost_mitigated: 158292.288,
    freight_cost: 36044.8704,
    cost_impact: 36044.8704,
  },
  {
    running_risk_id: 20058,
    risk_date: 293 + 45243,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 23899,
    supply_cost_at_risk: 193581.9,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 20553.14,
    supply_cost_mitigated: 166480.434,
    freight_cost: 43983.7196,
    cost_impact: 43983.7196,
  },
  {
    running_risk_id: 20059,
    risk_date: 293 + 45245,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-346",
    quantity_at_risk: 21750,
    supply_cost_at_risk: 213150,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 20880,
    supply_cost_mitigated: 204624,
    freight_cost: 32572.8,
    cost_impact: 32572.8,
  },
  {
    running_risk_id: 20060,
    risk_date: 293 + 45249,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-792",
    quantity_at_risk: 39485,
    supply_cost_at_risk: 268498,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 39485,
    supply_cost_mitigated: 268498,
    freight_cost: 67124.5,
    cost_impact: 67124.5,
  },
  {
    running_risk_id: 20061,
    risk_date: 293 + 45254,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-792",
    quantity_at_risk: 47404,
    supply_cost_at_risk: 327087.6,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 38397.24,
    supply_cost_mitigated: 264940.956,
    freight_cost: 66811.1976,
    cost_impact: 66811.1976,
  },
  {
    running_risk_id: 20062,
    risk_date: 293 + 45257,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 39956,
    supply_cost_at_risk: 295674.4,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 37558.64,
    supply_cost_mitigated: 277933.936,
    freight_cost: 73239.348,
    cost_impact: 73239.348,
  },
  {
    running_risk_id: 20063,
    risk_date: 293 + 45235,
    risk_status: "Rejected",
    rejection_code: "No freight forwarder capacity",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 37166,
    supply_cost_at_risk: 293611.4,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 31962.76,
    supply_cost_mitigated: 252505.80400000003,
    freight_cost: 52418.9264,
    cost_impact: 52418.9264,
  },
  {
    running_risk_id: 20064,
    risk_date: 293 + 45238,
    risk_status: "Rejected",
    rejection_code: "Freight forwarder calendar",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-995",
    quantity_at_risk: 37486,
    supply_cost_at_risk: 266150.6,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 31488.24,
    supply_cost_mitigated: 223566.504,
    freight_cost: 45972.8304,
    cost_impact: 45972.8304,
  },
  {
    running_risk_id: 20065,
    risk_date: 293 + 45239,
    risk_status: "Rejected",
    rejection_code: "No freight forwarder capacity",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 9254,
    supply_cost_at_risk: 82360.6,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 7588.28,
    supply_cost_mitigated: 67535.692,
    freight_cost: 14341.849199999999,
    cost_impact: 14341.849199999999,
  },
  {
    running_risk_id: 20066,
    risk_date: 293 + 45242,
    risk_status: "Rejected",
    rejection_code: "Freight forwarder calendar",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 32346,
    supply_cost_at_risk: 232891.2,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 31375.62,
    supply_cost_mitigated: 225904.46399999998,
    freight_cost: 51769.773,
    cost_impact: 51769.773,
  },
  {
    running_risk_id: 20067,
    risk_date: 293 + 45248,
    risk_status: "Rejected",
    rejection_code: "Freight forwarder calendar",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-792",
    quantity_at_risk: 32347,
    supply_cost_at_risk: 278184.2,
    action_type: "Delay deployment",
    execution_stage: "Freight",
    quantity_mitigated: 29759.24,
    supply_cost_mitigated: 255929.464,
    freight_cost: 45829.2296,
    cost_impact: 45829.2296,
  },
  {
    running_risk_id: 20068,
    risk_date: 293 + 45269,
    risk_status: "Open",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 49985,
    supply_cost_at_risk: 399880,
    action_type: "No action",
    quantity_mitigated: 34514,
    supply_cost_mitigated: 391913.53350758535,
  },
  {
    running_risk_id: 20069,
    risk_date: 293 + 45233,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 18483,
    supply_cost_at_risk: 131229.3,
    action_type: "No action",
    quantity_mitigated: 10131,
    supply_cost_mitigated: 537678.2832248181,
  },
  {
    running_risk_id: 20070,
    risk_date: 293 + 45236,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 47941,
    supply_cost_at_risk: 441057.2,
    action_type: "No action",
    quantity_mitigated: 2821,
    supply_cost_mitigated: 550142.5282555283,
  },
  {
    running_risk_id: 20071,
    risk_date: 293 + 45239,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 17505,
    supply_cost_at_risk: 112032,
    action_type: "No action",
    quantity_mitigated: 8746,
    supply_cost_mitigated: 497955.5807799443,
  },
  {
    running_risk_id: 20072,
    risk_date: 293 + 45243,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 5037,
    supply_cost_at_risk: 39288.6,
    action_type: "No action",
    quantity_mitigated: 33507,
    supply_cost_mitigated: 453047.0428219894,
  },
  {
    running_risk_id: 20073,
    risk_date: 293 + 45249,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-792",
    quantity_at_risk: 28494,
    supply_cost_at_risk: 208006.2,
    action_type: "No action",
    quantity_mitigated: 19876,
    supply_cost_mitigated: 44134.69922593337,
  },
  {
    running_risk_id: 20074,
    risk_date: 293 + 45242,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 24925,
    supply_cost_at_risk: 199400,
    action_type: "No action",
    quantity_mitigated: 41206,
    supply_cost_mitigated: 28866.64,
  },
  {
    running_risk_id: 20075,
    risk_date: 293 + 45252,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 47720,
    supply_cost_at_risk: 386532,
    action_type: "No action",
    quantity_mitigated: 3850,
    supply_cost_mitigated: 126405.79960513327,
  },
];
