import { Tag, Tooltip } from "antd";
import { getTranslation } from "../../../../../../utils/transaltion";
import { Scope } from "../../../../../../interfaces/scope";
import { hexToRgba } from "../../../../../common/tags/functions";
interface ScopeTagProps {
  scope: Scope;
  closable: Boolean;
  onClose: (e: any) => void;
}
export default function ScopeTag(props: ScopeTagProps) {
  const { scope, closable, onClose } = props;
  const color = scope?.all ? "#22A06B" : "#0082BA";
  return (
    <Tooltip
      key={scope?.key + scope?.value}
      title={
        <>
          {scope?.key?.split(".")?.map((data) => (
            <div>{getTranslation(data, "data")}</div>
          ))}

          {
            <div>
              {getTranslation("VALUE", "labels")} :{" "}
              {scope?.value || getTranslation("ALL", "labels")}
            </div>
          }
        </>
      }
    >
      <Tag
        key={scope?.key + scope?.value}
        closable={!!closable}
        style={{
          marginBlock: "2px",
          paddingBlock: "2px",
          borderRadius: "12px",
          /*  */
          color: hexToRgba(color, 1),
          borderColor: hexToRgba(color, 0.6),
          backgroundColor: hexToRgba(color, 0.12),
        }}
        onClose={onClose}
      >
        {scope?.key}.{scope?.all ? "*" : scope?.value}
      </Tag>
    </Tooltip>
  );
}
