import { Column, ColumnConfig } from "@ant-design/plots";
import { riskStatusColors } from "../../../common/chartColors";
import EmptyChartCard from "../../../common/EmptyChart";

const StatusByAction = (props: any) => {
  let { data } = props;

  if (data.length === 0) {
    return <EmptyChartCard />;
  }

  const config: ColumnConfig = {
    data,
    xField: "action_type",
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
        formatter: (action_type) => {
          const words = action_type.split(" ");
          words.splice(1, 0, "\n");
          words.splice(3, 0, "\n");
          return words.join(" ");
        },
      },
    },
    yField: "value",
    color: ({ risk_status }) => {
      return riskStatusColors(risk_status);
    },
    seriesField: "risk_status",
    isStack: true,
    appendPadding: 10,
    tooltip: {
      shared: true,
      showMarkers: false,
      formatter: ({ risk_status, value }: any) => ({
        name: risk_status,
        value: `${value} risks`,
      }),
    },
    label: {
      position: "top",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    legend: {
      position: "bottom",
    },
    columnWidthRatio: 0.8,
  };

  return <Column {...config} height={300} />;
};

export default StatusByAction;
