import React, { useContext, useEffect, useRef, useState } from "react";
import { ProTable, ProColumns } from "@ant-design/pro-components";
import { Space, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import {
  CustomErrorIcon,
  CustomExclamationIcon,
  CustomWarningIcon,
} from "./CustomWarningsIcons";
import { getTranslation } from "../../../../utils/transaltion";
import { CATEGORY_VIEWS } from "../../../../constants/categoryviews";
import { MainContext } from "../../../../utils/context";
import EmptyChartCard from "../../../common/charts/EmptyChartCard";

interface Column {
  key: string;
  value: string;
}

interface DynamicTableProps {
  Type: string;
  Data: any[];
  InputList?: any[];
  dynamicColumns?: Column[];
}

const DynamicTable: React.FC<DynamicTableProps> = (props) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<typeof Input | null>(null);
  const { Data, Type } = props;
  const globalContext = useContext(MainContext);

  const extractDynamicColumnsAndData = (inputArray: any[]) => {
    if (!inputArray || inputArray.length === 0) {
      return { dynamicColumns: [], InputList: [] };
    }

    const uniqueKeysSet = new Set<string>();

    inputArray.forEach((obj) => {
      const objKeys = Object.keys(obj);

      objKeys.forEach((key) => {
        uniqueKeysSet.add(key);
      });
    });

    const uniqueKeysArray = Array.from(uniqueKeysSet);
    const dynamicColumns = uniqueKeysArray.map((key) => ({
      key,
      value: key.charAt(0).toUpperCase() + key.slice(1),
    }));
    // to ensure all objects in InputList have all keys from dynamicColumns
    const updatedInputList = inputArray.map((obj, index) => {
      const updatedObject: Record<string, any> = { ...obj };

      dynamicColumns.forEach((column) => {
        if (!updatedObject.hasOwnProperty(column.key)) {
          updatedObject[column.key] = undefined;
        }
      });

      // Ensure each object has a unique key
      if (!updatedObject.key) {
        updatedObject.key = `key-${index}`;
      }

      return updatedObject;
    });

    return { dynamicColumns, InputList: updatedInputList };
  };

  const { dynamicColumns, InputList } = extractDynamicColumnsAndData(Data);
  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm();
    setSearchText(selectedKeys[0] as string);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
      searchInput,
    }: {
      setSelectedKeys: (keys: React.Key[]) => void;
      selectedKeys: React.Key[];
      confirm: () => void;
      clearFilters: () => void;
      close: () => void;
      searchInput: React.MutableRefObject<typeof Input | null>;
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            {t("CLOSE")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value: any, record: any) => {
      const filterValue = value.toLowerCase();
      const dataIndexValue = record[dataIndex].toString().toLowerCase();
      return dataIndexValue.includes(filterValue);
    },
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => (searchInput.current as any)?.select(), 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const dynamicTableColumns: ProColumns<any>[] = dynamicColumns.map(
    (column: Column) => ({
      title: getTranslation(column.value, "labels"),
      dataIndex: column.key,
      valueType: "text",
      ...(getColumnSearchProps(column.key) as any),
    })
  );

  const getColumnSearchPropsWarning = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
      searchInput,
    }: {
      setSelectedKeys: any;
      selectedKeys: any;
      confirm: any;
      clearFilters: any;
      close: any;
      searchInput: React.MutableRefObject<null>;
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value: any, record: any) => {
      if (dataIndex === "is_system") {
        const filterValue = value;
        const dataIndexValue = record[dataIndex];
        return dataIndexValue === filterValue;
      } else {
        const filterValue = value.toLowerCase();
        const dataIndexValue = record[dataIndex].toString().toLowerCase();
        return dataIndexValue.includes(filterValue);
      }
    },
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible) {
        setTimeout(
          () => (searchInput.current as HTMLInputElement | null)?.select(),
          100
        );
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getRowClassName = (record: any) => {
    if (Type === CATEGORY_VIEWS.ERROR_WARNING_VIEW) {
      switch (record.SEVERITY) {
        case "INFO":
          return "info-row";
        case "ERROR":
          return "error-row";
        case "WARNING":
          return "warning-row";
        default:
          return "";
      }
    }
    return "";
  };
  const getColumnWarnings = (
    title: string,
    dataIndex: string,
    valueType: "text",
    width: string,
    render: any
  ) => {
    const column = {
      title,
      dataIndex,
      valueType,
      width,
      render,
    };

    if (dataIndex === "MODULE") {
      return {
        ...column,
        ...(getColumnSearchPropsWarning(dataIndex) as any),
        // Customize rendering for the "Module" column
        render: (text: any, record: any, index: any) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginTop: "5px" }}>
              {record.SEVERITY === "INFO" && <CustomExclamationIcon />}
              {record.SEVERITY === "ERROR" && <CustomErrorIcon />}
              {record.SEVERITY === "WARNING" && <CustomWarningIcon />}
            </span>
            <span style={{ marginInlineStart: "10px" }}>{text}</span>
          </div>
        ),
      };
    }

    return {
      ...column,
      ...(valueType === "text" && getColumnSearchProps(dataIndex)),
      render: (text: any, record: any, index: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginInlineStart: "10px" }}>
            {" "}
            {text.split(",").map((item: any, index: any) => (
              <span key={index} style={{ display: "block" }}>
                {item.trim()}
              </span>
            ))}
          </span>
        </div>
      ),
    } as ProColumns<any>;
  };
  const columnsWarnings: ProColumns<any>[] = [
    getColumnWarnings(t("MODULE"), "MODULE", "text", "30%", undefined),
    getColumnWarnings(t("CODE"), "CODE", "text", "30%", undefined),
    getColumnWarnings(
      t("DETAILS", { item: "" }),
      "DETAILS",
      "text",
      "40%",
      undefined
    ),
  ];

  return InputList ? (
    InputList.length > 0 ? (
      <div style={{ marginInlineStart: -35 }}>
        <ProTable
          dataSource={InputList}
          columns={
            Type === CATEGORY_VIEWS.ERROR_WARNING_VIEW
              ? columnsWarnings
              : dynamicTableColumns
          }
          rowKey="key"
          pagination={false}
          size="small"
          search={false}
          options={{
            density: false,
            setting: false,
            reload: false,
          }}
          showHeader={true}
          className="other-options"
          rowClassName={getRowClassName}
        />
      </div>
    ) : (
      <EmptyChartCard />
    )
  ) : null;
};

export default DynamicTable;
