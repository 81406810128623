import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { PreDefinedExecutionItem } from "../../interfaces/pre-defined-execution";

const initialState: Array<PreDefinedExecutionItem> = [];

export const predefinedExecutionOrgaSlice = createSlice({
  name: "predefined_Executions_Orga",
  initialState,
  reducers: {
    addPredefinedExecutionOrga: (
      state,
      action: PayloadAction<PreDefinedExecutionItem>
    ) => {
      state.push(action.payload);
    },
    setPredefinedExecutionOrga: (
      state,
      action: PayloadAction<Array<PreDefinedExecutionItem>>
    ) => {
      return action.payload;
    },
    updatePreDefinedExecutionOrga: (
      state,
      action: PayloadAction<PreDefinedExecutionItem>
    ) => {
      console.warn(action.payload);
      const { id } = action.payload;
      const index = state.findIndex(
        (preDefinedExecution) => preDefinedExecution.id === id
      );

      if (index !== -1) {
        const updatedState = state.map((item, i) =>
          i === index ? { ...item, ...action.payload } : item
        );

        // console.warn("Updated State:", updatedState);
        return updatedState;
      }

      return state;
    },
  },
});
export const {
  addPredefinedExecutionOrga,
  setPredefinedExecutionOrga,
  updatePreDefinedExecutionOrga,
} = predefinedExecutionOrgaSlice.actions;

export const PredefinedExecutionOrgaSelector = (state: RootState) =>
  state.predefinedExecutionOrgaReducer;

export default predefinedExecutionOrgaSlice.reducer;
