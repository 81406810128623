import { setCopilots } from "../../reducers/copilot.Reducer";

/** dispatch predefined risk */
export const InitCopilot = async (copilotServices: any, dispatch: any) => {
  return copilotServices
    .getResources({ pageSize: 1000 })
    .then(async (res: any) => {
      dispatch(setCopilots(res?.items));
    })
    .catch((err: any) => {
      console.log(err);
    });
};
