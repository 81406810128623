import { Button } from "antd";
import { STATUS } from "../../../../../constants/status";
import { Reason } from "../../../../../interfaces/reasons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../../logic/runningShared.logic";
import { SharedContext } from "../../context/SharedContext";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const RunningOpenActionsVirtualListTitle = (props: any) => {
  const {
    copilotName,
    menu,
    item,
    setAcceptRejectState,
    setSelectedRowButton,
  } = props;
  const { t } = useTranslation();
  const [loadingAccept, setLoadingAccept] = useState<boolean>(false);
  /**reasonsexecutions from Redux */
  const reasonsExecutions: Reason[] = useSelector(
    (state: RootState) => state.reasonsExecutionsReducer
  );
  /** reasons actions from redux */
  const reasonsActions: Reason[] = useSelector(
    (state: RootState) => state.reasonsActionsReducer
  );
  const {
    setTypeReject,
    selectedRisk,
    currentAction,
    setSelectedRisk,
    setCurrentActionExecutionRejectArguments,
  } = useContext(SharedContext);
  const { acceptActionOrExecution, filterCurrentActionreasons } =
    useSharedContextFunctions() as SharedContextFunctions;

  return (
    <div
      className="header-content"
      style={{
        display: "flex",
        flexDirection: window.innerWidth <= 600 ? "column" : "row",
        justifyContent:
          window.innerWidth <= 600 ? "flex-start" : "space-between",
        alignItems: window.innerWidth <= 600 ? "flex-start" : "center",
        width: "100%",
      }}
    >
      <h4
        style={{
          color: "black",
          marginBottom: window.innerWidth <= 600 ? "10px" : "0",
        }}
      >
        {item.risk_conf_name}
      </h4>
      <div
        className="button-container"
        style={{
          display: "flex",
          flexDirection: window.innerWidth <= 600 ? "column" : "row",
          gap: "10px",
          width: window.innerWidth <= 600 ? "100%" : "auto",
        }}
      >
        <Button
          // type="primary"
          style={{
            backgroundColor: "#004C97",
            borderColor: "#004C97",
            marginBottom: window.innerWidth <= 600 ? "10px" : "0",
          }}
          loading={loadingAccept && item.id === selectedRisk?.id ? true : false}
          onClick={() => {
            setLoadingAccept(true);
            setSelectedRowButton(true);
            setAcceptRejectState(false);
            setSelectedRisk(item);
            const currentExec =
              item?.current_action?.status === STATUS.WAIT_FOR_EXECUTION
                ? item?.current_action?.current_execution
                : null;
            if (currentExec) {
              acceptActionOrExecution(
                copilotName,
                item?.id,
                menu,
                currentExec?.id,
                currentExec?.contexts?.at(0)?.id,
                "Execution",
                setLoadingAccept
              );
            } else {
              acceptActionOrExecution(
                copilotName,
                item?.id,
                menu,
                item?.current_action?.id,
                item?.current_action?.contexts?.at(0)?.id,
                "Action",
                setLoadingAccept
              );
              //setSelectedRowButton(false);
            }
          }}
          className="blue-gradient-btn"
        >
          {t("ACCEPT")}
        </Button>
        <Button
          key={item.id}
          danger
          className="orange-gradient-btn"
          onClick={() => {
            setSelectedRowButton(true);

            setSelectedRisk(item);
            const currentExec =
              item?.current_action?.status === STATUS.WAIT_FOR_EXECUTION
                ? item?.current_action?.current_execution
                : null;
            setAcceptRejectState(true);
            let currentActExec: any = null;
            if (currentExec) {
              setTypeReject("Execution");
              filterCurrentActionreasons(
                "EXECUTION",
                reasonsExecutions,
                item?.current_action?.current_execution?.execution_conf_src_id
              );
              currentActExec = currentExec;
            } else {
              setTypeReject("Action");
              filterCurrentActionreasons(
                "ACTION",
                reasonsActions,
                item?.current_action?.action_conf_src_id
              );
              currentActExec = item?.current_action;
            }
            setCurrentActionExecutionRejectArguments({
              type: "Action",
              id: currentActExec?.id,
              contexts: {
                id: currentActExec?.contexts?.at(0)?.id,
              },
            });
          }}
          style={{
            backgroundColor: "#f5222d",
            borderColor: "#f5222d",
            color: "white",
          }}
        >
          {t("REJECT")}
        </Button>
      </div>
    </div>
  );
};
