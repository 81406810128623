import { notification } from "antd";
import { Dispatch, SetStateAction, useContext } from "react";
import { SharedContext } from "../context/SharedContext";
import { useSelector } from "react-redux";
import { MainContext } from "../../../../utils/context";
import { RootState } from "../../../../redux/store/store";
import { Reason } from "../../../../interfaces/reasons";
import { getCopilotId } from "../../../../utils/functions";
import { COPILOT_TYPES } from "../../../../constants/copilottypes";
import { STATUS } from "../../../../constants/status";
import { createKey } from "../../../../utils/transaltion";
import { Role } from "../../../../interfaces/role";
import { RUNNING_MENU } from "../../../../constants/riskMenus";
import { PAGES_SIZE } from "../../../../constants/pagination";
import { getOtherOption } from "./runningMapping.logic";
import RunningRisksService from "../../../../services/running-risks.service";
import ExecutionsService from "../../../../services/execution-confs.service";
import ActionsService from "../../../../services/action-confs.service";
import { REQUEST } from "../../../../services/functions/handle-api-errors/const";
import handleApiError from "../../../../services/functions/handle-api-errors/handleApiError";
import { Fulfillement_Risk } from "../data/fulfillment";
import { Demand_Matching_Risk } from "../data/demandMatching";
import { Supply_Matching_Risk } from "../data/supplyMatching";
import { Inbound_Freight_Risk } from "../data/inboundFreight";
/** interface custom hook */
interface FilterArguments {
  [key: string]: {
    value: any;
    operator: string; // Can be 'range', 'HAS', etc.
  };
}
export interface SharedContextFunctions {
  filterRunningRisks: (filters: any, sortingArgs: any) => void;
  getCurrentRiskActions: (
    setOtherOptions: React.Dispatch<any>,
    setLoadingOtherOptions: React.Dispatch<boolean>
  ) => void;

  stopRisk: (
    id: string,
    reason: string,
    typeCopilot: string,
    menu: string,
    setLoadingStop: Dispatch<SetStateAction<any>>
  ) => void;
  extractPositionAttributes: (id: string) => {
    name: string;
    type: string;
  }[];
  getEnums: (
    setValueEnumPredefinedAction: React.Dispatch<React.SetStateAction<any>>,
    setValueEnumPredefinedExecutions: React.Dispatch<React.SetStateAction<any>>
  ) => void;
  handleOnSelectReasonCode: (
    value: string,
    currentActionExecutionReasons: any[],
    setInputOtherVisible: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  reasonstoLowerCase: (reasons: any) => void;
  getAccessAcceptReject: (roles: any[], rolesContext: Role[]) => Boolean;
  filterRunningRisksHistory: (
    type: string,
    params: any,
    filters: any,
    fulls: any,
    runningRisk: any[]
  ) => Promise<void>;
  filterRunningOpenActions: (
    type: string,
    params: any,
    filters: any,
    fulls: any
  ) => Promise<void>;
  filterRunningOpenActionsAppend: (
    type: string,
    params: any,
    filters: any,
    fulls: any,
    runningRisk: any[]
  ) => Promise<void>;
  getRunningRiskById: (riskId: string) => Promise<void>;
  rejectActionOrExecution: (
    copilotName: string,
    RiskId: string,
    menu: string,
    id: string,
    args: any,
    typeReject: string,
    setLoadingReject: React.Dispatch<React.SetStateAction<boolean>>,

    setAcceptRejectState?: Dispatch<SetStateAction<boolean>>
  ) => void;
  showNotificationAccept: () => void;
  acceptActionOrExecution: (
    copilotName: string,
    RiskId: string,
    menu: string,
    id: string,
    contextId: string,
    type: string,
    setLoadingAccept: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  handleReasonCodeModal: (
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  showDrawer: (
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  closeAddReasonCodeModal: (
    setOpenAddReasonCodeModal: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  onClose: (
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  filterCurrentActionreasons: (
    type: string,
    reasons: Reason[],
    currentAct: any
  ) => void;
}
export const useSharedContextFunctions = () => {
  const globalContext = useContext(MainContext);
  /** get copilots from redux */
  const copilotList = useSelector(
    (state: RootState) => state.userCopilotReducer
  );
  const reasonsActions: Reason[] = useSelector(
    (state: RootState) => state.reasonsActionsReducer
  );
  /**reasonsexecutions from Redux */
  const reasonsExecutions: Reason[] = useSelector(
    (state: RootState) => state.reasonsExecutionsReducer
  );

  /** get predefined risks from redux */
  const predefinedRiskList = useSelector(
    (state: RootState) => state.predefinedRiskOrgaReducer
  );
  /** shared vars  */
  const {
    setOpenAddReasonCodeModal,
    setGetLoading,
    getLoading,
    setTotalRunningRisk,
    setRunningRisks,
    runningRisks,
    setRunningRisksFilter,
    runningRisksFilter,
    currentAction,
    setOpenDrawer,
    setCurrentAction,
    setCurrentActionExecutionReasons,
    actionConfs,
    setActionConfs,
    setCurrentRiskActions,
    setExecConfs,
    setSelectedRisk,
    selectedRisk,
  } = useContext(SharedContext);
  if (!globalContext) {
    return <></>;
  }

  const runningRisksService = new RunningRisksService(
    globalContext?.context,
    globalContext?.setContext,
    ""
  );
  const executionsServices = new ExecutionsService(
    globalContext.context,
    globalContext.setContext
  );
  const actionsServices = new ActionsService(
    globalContext.context,
    globalContext.setContext
  );

  const extractPositionAttributes = (
    copilotId: string
  ): {
    name: string;
    type: string;
  }[] => {
    // this function returns the 5 fields of the risk context that has a position property
    const positionAttributes: {
      name: string;
      type: string;
    }[] = [];
    let filteredPredefinedRiskList: any;
    // predefinedRiskList predefined risks from redux
    // Filter risks by copilot if a copilot ID is provided
    if (copilotId) {
      // if we have the copilot's id  then filter  the risk by copilot
      filteredPredefinedRiskList = predefinedRiskList?.filter(
        (obj: any) => obj.copilot.id === copilotId
      );
    } else {
      filteredPredefinedRiskList = predefinedRiskList;
    }
    // if  the same fields appear multiple time in different risks then only take one  occurrence
    // only take the fields that have the position property

    const uniqueNames: { [name: string]: boolean } = {};
    filteredPredefinedRiskList?.forEach((item: any) => {
      const { policyData } = item;
      const attributes = Object.keys(policyData);

      attributes?.forEach((attribute) => {
        const { POSITION, TYPE } = policyData[attribute];
        if (POSITION) {
          if (!uniqueNames[attribute]) {
            positionAttributes.push({ name: attribute, type: TYPE });
            uniqueNames[attribute] = true;
          }
        }
      });
    });
    return positionAttributes;
  };

  const getAccessAcceptReject = (
    roles: any[],
    rolesContext: Role[]
  ): boolean => {
    // Checking if any of the Ids from roles exist in rolesContext
    const hasCommonId = roles.some((roleId) =>
      rolesContext.some((role) => role.id === roleId.id)
    );
    return hasCommonId;
  };
  /** get running risks History  */

  const filterRunningRisksHistory = async (
    type: string,
    params: any,
    filters: any,
    fulls: any,
    runningRisk: any[]
  ): Promise<void> => {
    setGetLoading(true);
    let runningList: any = [];

    if (createKey(type) === createKey("fulfillment")) {
      runningList = Fulfillement_Risk;
    }
    if (createKey(type) === createKey("demand matching")) {
      runningList = Demand_Matching_Risk;
    }
    if (createKey(type) === createKey("supply matching")) {
      runningList = Supply_Matching_Risk;
    }
    if (createKey(type) === createKey("inbound freight management")) {
      runningList = Inbound_Freight_Risk;
    }
    setRunningRisks(runningList);
    setRunningRisksFilter(runningList);
    setTotalRunningRisk(runningList.length);
    if (runningList.length > 0) {
      setCurrentAction(runningList.at(0)?.current_action);
      setSelectedRisk(runningList.at(0));
    } else {
      setCurrentAction(null);
      setSelectedRisk(null);
    }

    setGetLoading(false);
  };
  /** get running risks OpenActions  */

  const filterRunningOpenActions = async (
    type: string,
    params: any,
    filters: any,
    fulls: any
  ): Promise<void> => {
    let idCopilot = "";
    //  setGetLoading(true);
    idCopilot = getCopilotId(copilotList, createKey(type))?.toString();

    idCopilot &&
      (await runningRisksService
        .getCurrent(params, { ...filters, copilot: idCopilot }, fulls)
        .then(async (res: any) => {
          setRunningRisks(res?.items);
          setRunningRisksFilter(res?.items);
          setTotalRunningRisk(res?.total);
        })
        .catch((err: any) => {
          handleApiError(err);
        }));
    setGetLoading(false);
  };

  const filterRunningOpenActionsAppend = async (
    type: string,
    params: any,
    filters: any,
    fulls: any,
    runningRisk: any[]
  ): Promise<void> => {
    setGetLoading(true);
    let runningList: any = [];

    if (createKey(type) === createKey("fulfillment")) {
      runningList = Fulfillement_Risk;
    }
    if (createKey(type) === createKey("demand matching")) {
      runningList = Demand_Matching_Risk;
    }
    if (createKey(type) === createKey("supply matching")) {
      runningList = Supply_Matching_Risk;
    }
    if (createKey(type) === createKey("inbound freight management")) {
      runningList = Inbound_Freight_Risk;
    }
    setRunningRisks(runningList);
    setRunningRisksFilter(runningList);
    setTotalRunningRisk(runningList.length);
    if (runningList.length > 0) {
      setCurrentAction(runningList.at(0)?.current_action);
      setSelectedRisk(runningList.at(0));
    } else {
      setCurrentAction(null);
      setSelectedRisk(null);
    }

    setGetLoading(false);
  };

  const filterRunningRisks = (filters: any, sortingArgs: any) => {
    const filteredObject = Object.fromEntries(
      Object.entries(filters).filter(([key, filterItem]) => {
        // Use type assertion to tell TypeScript that filterItem is of type FilterItem
        const { value } = filterItem as any;
        return value !== "";
      })
    );

    if (!filteredObject || Object.keys(filteredObject).length === 0) {
      if (Object.keys(sortingArgs).length > 0) {
        const { sortOrder, fieldKey } = sortingArgs;
        const listFilteredsorted = runningRisksFilter?.sort((a, b) => {
          const aValue = a.context?.[fieldKey];
          const bValue = b.context?.[fieldKey];

          // Handle sorting for existing values
          if (aValue !== undefined && bValue !== undefined) {
            // Both have the fieldKey
            return sortOrder === "asc"
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          } else if (aValue !== undefined) {
            // Only 'a' has the fieldKey, keep 'a' before 'b'
            return -1;
          } else if (bValue !== undefined) {
            // Only 'b' has the fieldKey, keep 'b' before 'a'
            return 1;
          }
          // If neither has the fieldKey, maintain relative order
          return 0;
        });
        setTotalRunningRisk(listFilteredsorted?.length);
        setRunningRisks(listFilteredsorted);
      } else {
        setTotalRunningRisk(runningRisksFilter?.length);

        setRunningRisks(runningRisksFilter);
      }
      // If no filters are provided, return the whole list
      setTotalRunningRisk(runningRisksFilter?.length);
      setRunningRisks(runningRisksFilter);
      //return runningRisks;
    } else {
      const listFiltered = runningRisks.filter((risk) => {
        return Object.keys(filteredObject).every((key) => {
          const filter: any = filteredObject[key];

          const value = risk?.context[key];
         

          if (key === "due_date") {
            if (Array.isArray(filter?.value) && filter?.value.length === 2) {
              const [min, max] = filter?.value;
              // Convert value to a number if it's a numeric comparison
              const startDate = new Date(min);
              const endDate = new Date(max);

              // Convert the value string to a Date object
              const valueDate = new Date(risk?.due_date);

              return valueDate >= startDate && valueDate <= endDate;
            }
            return false;
          }
          if (key === "action_conf.name") {
            return createKey(risk?.current_action?.action_conf_name).includes(
              createKey(filter.value)
            );
          }

          if (key === "execution_conf.name") {
            return risk?.current_action?.executions?.some((execution: any) =>
              execution?.execution_conf_name.includes(createKey(filter?.value))
            );
          }
          if (key === "risk_conf.name") {
            return createKey(risk?.risk_conf_name).includes(
              createKey(filter.value)
            );
          }
          if (!risk.context.hasOwnProperty(key)) {
            return false;
          }
          // Handle different operators
          switch (filter.operator) {
            case "range":
              // Check if the value is an array
              if (Array.isArray(filter.value) && filter.value.length === 2) {
                const [min, max] = filter.value;
                // Convert value to a number if it's a numeric comparison
                const numericValue = Number(value);
                return (
                  !isNaN(numericValue) &&
                  numericValue >= min &&
                  numericValue <= max
                );
              }
              return false; // Invalid range

            case "HAS":
              const sanitizedValue = createKey(value).replace(/,/g, ""); // Remove commas
             
              return createKey(sanitizedValue).includes(
                createKey(filter.value)
              );

            default:
              return true; // If operator is unknown, don't filter
          }
        });
      });

      if (Object.keys(sortingArgs).length > 0) {
        const { sortOrder, fieldKey } = sortingArgs;

        const listFilteredsorted = listFiltered?.sort((a, b) => {
          const aValue = a.context?.innerContext?.[fieldKey];
          const bValue = b.context?.innerContext?.[fieldKey];

          // Handle sorting for existing values
          if (aValue !== undefined && bValue !== undefined) {
            // Both have the fieldKey
            return sortOrder === "asc"
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          } else if (aValue !== undefined) {
            // Only 'a' has the fieldKey, keep 'a' before 'b'
            return -1;
          } else if (bValue !== undefined) {
            // Only 'b' has the fieldKey, keep 'b' before 'a'
            return 1;
          }
          // If neither has the fieldKey, maintain relative order
          return 0;
        });
        setRunningRisks(listFilteredsorted);
        setTotalRunningRisk(listFilteredsorted?.length);
      } else {
        setRunningRisks(listFiltered);
        setTotalRunningRisk(listFiltered?.length);
      }
    }
  };

  /** getcurrent Risk's actions */
  const getCurrentRiskActions = async (
    setOtherOptions: React.Dispatch<any>,
    setLoadingOtherOptions: React.Dispatch<boolean>
  ): Promise<void> => {
    const params = {
      current: 1,
      pageSize: PAGES_SIZE,
    };
    let otherOptionTable: any = [];
    const fullOptions = ["actions", "contexts", "executions", "user"]; // took out action conf and executon conf
    await runningRisksService
      .filterResource(params, { id: currentAction?.risk?.id }, fullOptions)
      .then(async (res: any) => {
        setCurrentRiskActions(res?.items?.at(0));
        // console.log("result other option", res);
        otherOptionTable = getOtherOption(
          currentAction.id,
          res?.items?.at(0)?.actions,
          reasonsActions,
          reasonsExecutions
        );
        setOtherOptions(otherOptionTable);
        setLoadingOtherOptions(false);
      })
      .catch((err: any) => {
        setLoadingOtherOptions(false);
        handleApiError(err);
      });
  };

  const getRunningRiskById = async (riskId: string): Promise<void> => {
    const risk = await runningRisksService
      .getRiskByID(riskId)
      .then(async (res: any) => {
        return res;
      })
      .catch((err: any) => {
        handleApiError(err);
      });
    return risk;
  };
  /**Accept  */
  function getCurrentExecution(executions: any[]): any | string | null {
    // Sort executions by execution_conf_priority in ascending order
    const sortedExecutions = [...executions].sort(
      (a, b) => a.execution_conf_priority - b.execution_conf_priority
    );

    let allComplete = true;

    for (const execution of sortedExecutions) {
      if (execution.status === "OPEN") {
        return execution; // Return the first OPEN execution
      }
      if (execution.status !== "COMPLETE") {
        allComplete = false;
      }
    }

    // If no executions are open, return null or "COMPLETE" if all have status "COMPLETE"
    return allComplete ? "COMPLETE" : null;
  }

  const updateCurrentAction = (array: any[], currentAction: any) => {
    // console.log("currentAction", currentAction);
    // console.log("array", array);

    return array?.map((item: any) => {
      // Check if the current-action.id matches currentAction.id
      // console.log("item[current-action]", item["current-action"]);

      if (item["current_action"]?.id === currentAction?.id) {
        // console.log("here match", currentAction?.id);

        // Replace the current-action object with the new currentAction value
        return {
          ...item,
          current_action: currentAction,
          actions: item?.actions.map((action: any) => 
            action.id === currentAction?.id
              ? { ...action, ...currentAction } // Update the matching action
              : action 
          ),
        };
      }
      // Return the original item if no match is found
      return item;
    });
  };
  const acceptActionOrExecution = async (
    copilotName: string,
    RiskId: string,
    menu: string,
    id: string,
    contextId: string,
    type: string,

    setLoadingAccept: React.Dispatch<React.SetStateAction<boolean>>
  ): Promise<void> => {
    setLoadingAccept(true);

    const _currentAction = JSON.parse(JSON.stringify(currentAction));

    if (
      selectedRisk?.status === STATUS.WAIT_FOR_ACTION ||
      selectedRisk?.status === STATUS.WAIT_FOR_EXECUTION
    ) {
      if (_currentAction?.status === STATUS.OPEN) {
        // accept the action  et set  the status
        const updatedCurrentAction = {
          ..._currentAction,
          status: STATUS.WAIT_FOR_EXECUTION,
        };
        setCurrentAction(updatedCurrentAction);
        setSelectedRisk({
          ...selectedRisk,
          status: STATUS.WAIT_FOR_EXECUTION,
        });
        // console.log("updatedRunningList", updatedRunningList);
        const updatedRiskListWaitExec = runningRisks?.map((item: any) => {
          // Check if the current-action.id matches currentAction.id
          if (item["current_action"]?.id === _currentAction?.id) {
            // Replace the current-action object with the new currentAction value
            return {
              ...item,
              status: STATUS.WAIT_FOR_EXECUTION,

              current_action: updatedCurrentAction,
              actions: item?.actions.map((action: any) => 
                action.id === updatedCurrentAction?.id
                  ? { ...action, ...updatedCurrentAction } // Update the matching action
                  : action 
              ),
            };
          }
          // Return the original item if no match is found
          return item;
        });
        if (menu === RUNNING_MENU.RISKS_HISTORY) {
          setRunningRisksFilter(updatedRiskListWaitExec);
          setRunningRisks(updatedRiskListWaitExec);
        } else {
          const filteredArray = updatedRiskListWaitExec?.filter(
            (obj) => obj.status !== "COMPLETE"
          );
          setRunningRisksFilter(filteredArray);
          setRunningRisks(filteredArray);
          if (updatedRiskListWaitExec?.length !== filteredArray?.length) {
         
            setTotalRunningRisk(filteredArray?.length);
            setCurrentAction(filteredArray?.at(0)?.current_action);
            setSelectedRisk(filteredArray?.at(0));

           
          }
        }
      }
      if (currentAction?.status === STATUS.WAIT_FOR_EXECUTION) {
        // find current execution
        const currentExecution = getCurrentExecution(currentAction?.executions);
    

        // set current execution status === complete if it's open
        const updatedCurrentActionExecution =
          !_currentAction ||
          !_currentAction.executions ||
          !currentExecution ||
          typeof currentExecution !== "object" ||
          currentExecution.status !== "OPEN"
            ? _currentAction
            : {
                ...currentAction,
                executions: currentAction?.executions.map((execution: any) =>
                  execution.id === currentExecution.id
                    ? { ...execution, status: "COMPLETE" } // Update only this execution's status
                    : execution
                ),
              };

        setCurrentAction(updatedCurrentActionExecution);
        const updatedRunningList = updateCurrentAction(
          runningRisks,
          updatedCurrentActionExecution
        );
        if (menu === RUNNING_MENU.RISKS_HISTORY) {
          setRunningRisksFilter(updatedRunningList);
          setRunningRisks(updatedRunningList);
        } else {
          const filteredArray = updatedRunningList?.filter(
            (obj) => obj.status !== "COMPLETE"
          );
          setRunningRisksFilter(filteredArray);
          setRunningRisks(filteredArray);
          if (updatedRunningList?.length !== filteredArray?.length) {
          
            setTotalRunningRisk(filteredArray?.length);
            setCurrentAction(filteredArray?.at(0)?.current_action);
            setSelectedRisk(filteredArray?.at(0));

           
          }
        }

        // checkif all executions status === complete
        const currentExecutionUpdated = getCurrentExecution(
          updatedCurrentActionExecution?.executions
        );

        if (currentExecutionUpdated === "COMPLETE") {
          //set action status === complete

          const currentActionComplete = {
            ...updatedCurrentActionExecution,
            status: STATUS.COMPLETE,
          };
          setCurrentAction(currentActionComplete);
          //set risk status === complete
          setSelectedRisk({
            ...selectedRisk,
            status: STATUS.COMPLETE,
          });
          const updatedRiskListComplete = runningRisks?.map((item: any) => {
            // Check if the current-action.id matches currentAction.id
            if (item["current_action"]?.id === currentAction?.id) {
              // Replace the current-action object with the new currentAction value
              return {
                ...item,
                status: STATUS.COMPLETE,

                current_action: currentActionComplete,
              };
            }
            // Return the original item if no match is found
            return item;
          });
          if (menu === RUNNING_MENU.RISKS_HISTORY) {
            setRunningRisksFilter(updatedRiskListComplete);

            setRunningRisks(updatedRiskListComplete);
          } else {
            const filteredArray = updatedRiskListComplete?.filter(
              (obj) => obj.status !== "COMPLETE"
            );
            setRunningRisksFilter(filteredArray);
            setRunningRisks(filteredArray);
            if (updatedRunningList?.length !== filteredArray?.length) {
              
              setTotalRunningRisk(filteredArray?.length);
              setCurrentAction(filteredArray?.at(0)?.current_action);
              setSelectedRisk(filteredArray?.at(0));

            
            }
          }
        }
      }
      notification.success({
        message: "Action successfully accepted",
        placement: "bottomRight",
        duration: 0.5,
      });
    }

    setLoadingAccept(false);
  };

  /**Stop Running risk */
  const stopRisk = async (
    id: string,
    reason: string,
    typeCopilot: string,
    menu: string,
    setLoadingStop: Dispatch<SetStateAction<any>>
  ): Promise<void> => {
    setLoadingStop(id);
    if (id) {
      const updatedRunningListComplete = runningRisks?.map((risk) => {
        // Check if the current risk matches the targetId
        if (risk.id === id) {
          // Update the risk's status
          const _currentAction = risk?.current_action;
          const UpdatedSelectedRisk = {
            ...risk,
            status: "COMPLETE",
            reason: { id: "RISK_STOPPED", name: "RISK_STOPPED" }, // Change status to COMPLETE
            current_action: {
              ..._currentAction,
              status: "COMPLETE",
              executions: _currentAction.executions.map((execution: any) => {
                // Update each execution's status
                return {
                  ...execution,
                  status: "COMPLETE", // Change execution status to COMPLETE
                  reason: { id: "UNUSED", name: "UNUSED" },
                };
              }),
            },
            actions: risk.actions.map((action: any) => {
              // Update each action's status
              return {
                ...action,
                status: "COMPLETE", // Change action status to COMPLETE
                reason: { id: "UNUSED", name: "UNUSED" },
                executions: action.executions.map((execution: any) => {
                  // Update each execution's status
                  return {
                    ...execution,
                    status: "COMPLETE", // Change execution status to COMPLETE
                    reason: { id: "UNUSED", name: "UNUSED" },
                  };
                }),
              };
            }),
          };
          setSelectedRisk(UpdatedSelectedRisk);
          setCurrentAction(UpdatedSelectedRisk?.actions?.at(0));
          return UpdatedSelectedRisk;
        }
        // Return the risk unmodified if it doesn't match
        return risk;
      });

   
      setRunningRisks(updatedRunningListComplete);
      notification.success({
        message: "Risk successfully stopped",
        placement: "bottomRight",
        duration: 0.5,
      });
      setLoadingStop(null);
    }
  };

  const filterCurrentActionreasons = (
    type: string,
    reasons: Reason[],
    confID: any
  ) => {
    if (type === "ACTION") {
      actionsServices.getResource(confID).then((res) => {
        setCurrentActionExecutionReasons(
          reasons.filter((reason) =>
            res?.data?.data?.reasons?.some(
              (actionReason: Reason) => actionReason.id === reason.id
            )
          )
        );
      });
    } else {
      executionsServices.getExecutionByTd(confID).then((res) => {
        setCurrentActionExecutionReasons(
          reasons.filter((reason) =>
            res?.data?.reasons?.some(
              (execReason: Reason) => execReason.id === reason.id
            )
          )
        );
      });
    }
  };
  //reject action
  const rejectActionOrExecution = async (
    copilotName: string,
    RiskId: string,
    menu: string,
    id: string,
    args: any,
    typeReject: string,
    setLoadingReject: React.Dispatch<React.SetStateAction<boolean>>,

    setAcceptRejectState?: Dispatch<SetStateAction<boolean>>
  ): Promise<void> => {
    setAcceptRejectState && setAcceptRejectState(true);

    const _currentAction = JSON.parse(JSON.stringify(currentAction));
    if (
      selectedRisk?.status === STATUS.WAIT_FOR_ACTION ||
      selectedRisk?.status === STATUS.WAIT_FOR_EXECUTION
    ) {
      if (_currentAction?.status === STATUS.OPEN) {
        // reject the action  et set  the status
        const updatedCurrentAction = {
          ..._currentAction,
          status: STATUS.COMPLETE,
          reason:{name:id},
          contexts: _currentAction.contexts.map((context: any, index: any) =>
            index === 0 ? { ...context, reason: id } : context
          ),
          executions: _currentAction.executions.map((execution: any) => {
            // Update each execution's status
            return {
              ...execution,
              status: "COMPLETE",
              reason: { name: id },
            };
          }),
        };
        setCurrentAction(updatedCurrentAction);
        setSelectedRisk({
          ...selectedRisk,
          status: STATUS.COMPLETE,
            reason:{name:id},
          actions: selectedRisk?.actions.map((action: any) => 
            action.id === updatedCurrentAction?.id
              ? { ...action, ...updatedCurrentAction } // Update the matching action
              : action 
          ),
        });
        // console.log("updatedRunningList", updatedRunningList);
        const updatedRiskListComplete = runningRisks?.map((item: any) => {
          // Check if the current-action.id matches currentAction.id
          if (item["current_action"]?.id === _currentAction?.id) {
            // Replace the current-action object with the new currentAction value
            return {
              ...item,
              status: STATUS.COMPLETE,

              current_action: updatedCurrentAction,
              actions: item?.actions.map((action: any) => 
                action.id === updatedCurrentAction?.id
                  ? { ...action, ...updatedCurrentAction } // Update the matching action
                  : action 
              ),
            };
          }
          // Return the original item if no match is found
          return item;
        });
        if (menu === RUNNING_MENU.RISKS_HISTORY) {
          setRunningRisksFilter(updatedRiskListComplete);
          setRunningRisks(updatedRiskListComplete);
        } else {
          const filteredArray = updatedRiskListComplete?.filter(
            (obj) => obj.status !== "COMPLETE"
          );
          setRunningRisks(filteredArray);
          setRunningRisksFilter(filteredArray);
          if (updatedRiskListComplete?.length !== filteredArray?.length) {
           
            setTotalRunningRisk(filteredArray?.length);
            setCurrentAction(filteredArray?.at(0)?.current_action);
            setSelectedRisk(filteredArray?.at(0));

           
          }
        }

        setLoadingReject(false);
        setAcceptRejectState && setAcceptRejectState(false);
      }
      if (currentAction?.status === STATUS.WAIT_FOR_EXECUTION) {
        // find current execution
        const currentExecution = getCurrentExecution(currentAction?.executions);

        // set current execution status === complete if it's open
        const updatedCurrentActionExecution =
          !_currentAction ||
          !_currentAction.executions ||
          !currentExecution ||
          typeof currentExecution !== "object" ||
          currentExecution.status !== "OPEN"
            ? _currentAction
            : {
                ...currentAction,
                status: "COMPLETE",
                reason:{name:id},
                executions: currentAction?.executions.map((execution: any) =>
                  execution.id === currentExecution.id
                    ? {
                        ...execution,
                        status: "COMPLETE",
                        reason:{name:id},
                        contexts: execution.contexts.map(
                          (context: any, index: any) =>
                            index === 0 ? { ...context, reason: id } : context
                        ),
                      } // Update only this execution's status
                    : execution
                ),
              };

        setCurrentAction(updatedCurrentActionExecution);
        const updatedRiskListComplete = runningRisks?.map((item: any) => {
          // Check if the current-action.id matches currentAction.id
          if (item["current_action"]?.id === _currentAction?.id) {
            // Replace the current-action object with the new currentAction value
            return {
              ...item,
              status: STATUS.COMPLETE,

              current_action: updatedCurrentActionExecution,
              actions: item?.actions.map((action: any) => 
                action.id === updatedCurrentActionExecution?.id
                  ? { ...action, ...updatedCurrentActionExecution } // Update the matching action
                  : action 
              ),
            };
          }
          // Return the original item if no match is found
          return item;
        });
        // const updatedRunningList = updateCurrentAction(
        //   runningRisks,
        //   updatedCurrentActionExecution
        // );


 
        if (menu === RUNNING_MENU.RISKS_HISTORY) {
          setRunningRisksFilter(updatedRiskListComplete);
          setSelectedRisk({
            ...selectedRisk,
            status: STATUS.COMPLETE,
            reason: id,
          });
          setRunningRisks(updatedRiskListComplete);
        } else {
          const filteredArray = updatedRiskListComplete?.filter(
            (obj) => obj.status !== "COMPLETE"
          );
          setRunningRisks(filteredArray);
          setRunningRisksFilter(filteredArray);
          if (updatedRiskListComplete?.length !== filteredArray?.length) {
        
            setTotalRunningRisk(filteredArray?.length);
            setCurrentAction(filteredArray?.at(0)?.current_action);
            setSelectedRisk(filteredArray?.at(0));

            
          }
        }

        setLoadingReject(false);
        setAcceptRejectState && setAcceptRejectState(false);
      }
      notification.success({
        message: "Action successfully rejected",
        placement: "bottomRight",
        duration: 0.5,
      });
    }
    setLoadingReject(false);
  };
  // show notification accept
  const showNotificationAccept = () => {
    notification.info({
      message: ``,
      description: "the Action has been accepted succesfully",
      placement: "bottomRight",
    });
  };
  //open modal reason code for procurement
  const handleReasonCodeModal = (
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    setOpenModal(true);
  };
  //to show the side bar
  const showDrawer = async (
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setOpenDrawer(true);
  };
  // close modal reasoncode
  const closeAddReasonCodeModal = (
    setOpenAddReasonCodeModal: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    setOpenAddReasonCodeModal(false);
  };
  // to close thsetOpenDrawere side bar
  const onClose = (
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    setOpenDrawer(false);
  };

  const reasonstoLowerCase = (reasons: any) => {
    return reasons?.map((item: any) => ({
      ...item,
      name: item?.name?.toLowerCase().replace(/_/g, " "),
    }));
  };
  const handleOnSelectReasonCode = (
    value: string,
    currentActionExecutionReasons: any[],
    setInputOtherVisible: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const is_other = currentActionExecutionReasons?.find(
      (obj) => obj.id === value
    )?.name;
    if (is_other.toLowerCase() === "other") {
      setInputOtherVisible(true);
    } else {
      setInputOtherVisible(false);
    }
  };
  const getEnums = async (
    setValueEnumPredefinedAction: React.Dispatch<React.SetStateAction<any>>,
    setValueEnumPredefinedExecutions: React.Dispatch<React.SetStateAction<any>>
  ): Promise<void> => {
    await executionsServices
      .getResources({ pageSize: 10000 }, ["action", "model"])
      .then((res) => {
        setExecConfs(res?.items);
        //  Executions enum
        const updatedEnumExecutions: { [key: string]: { text: string } } = {};
        res?.items?.forEach((curr: any) => {
          updatedEnumExecutions[curr.id] = { text: curr.name };
        });

        setValueEnumPredefinedExecutions(updatedEnumExecutions);
      });
    await actionsServices.getResources({ pageSize: 10000 }).then((res) => {
      setActionConfs(res?.items);
      // Actions enum
      const updatedEnumAction: { [key: string]: { text: string } } = {};
      res?.items?.forEach((curr: any) => {
        updatedEnumAction[curr.id] = { text: curr.name };
      });

      setValueEnumPredefinedAction(updatedEnumAction);
    });
  };

  return {
    filterRunningRisks,
    getCurrentRiskActions,
    extractPositionAttributes,
    stopRisk,
    getRunningRiskById,
    filterRunningOpenActions,
    filterRunningOpenActionsAppend,
    filterRunningRisksHistory,
    rejectActionOrExecution,
    showNotificationAccept,
    acceptActionOrExecution,
    handleReasonCodeModal,
    showDrawer,
    closeAddReasonCodeModal,
    onClose,
    getAccessAcceptReject,
    reasonstoLowerCase,
    filterCurrentActionreasons,
    handleOnSelectReasonCode,
    getEnums,
  };
};
