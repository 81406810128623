export const demand_matching_data: any[] = [
  {
    running_risk_id: 10000,
    risk_date: 293 + 45275,
    risk_status: "Open",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 41733,
    revenue_at_risk: 730327.5,
    action_type: "Expedite production",
    quantity_mitigated: 41733,
    revenue_mitigated: 730327.5,
    production_cost: 20449.17,
    freight_cost: 13354.56,
    cost_impact: 33803.729999999996,
  },
  {
    running_risk_id: 10001,
    risk_date: 293 + 45277,
    risk_status: "In progress",
    risk_location: 152,
    risk_item: "SKU-792",
    quantity_at_risk: 33269,
    revenue_at_risk: 535630.9,
    action_type: "Expedite production",
    execution_stage: "production validation",
    quantity_mitigated: 29942.1,
    revenue_mitigated: 482067.81,
    production_cost: 21857.732999999997,
    freight_cost: 13473.945,
    cost_impact: 35331.678,
  },
  {
    running_risk_id: 10002,
    risk_date: 293 + 45277,
    risk_status: "In progress",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 33605,
    revenue_at_risk: 379736.5,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 28900.3,
    revenue_mitigated: 326573.38999999996,
    production_cost: 22542.234,
    freight_cost: 7803.081,
    cost_impact: 30345.315000000002,
  },
  {
    running_risk_id: 10003,
    risk_date: 293 + 45231,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 12470,
    revenue_at_risk: 132182,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 12220.6,
    revenue_mitigated: 129538.36,
    production_cost: 6354.712,
    freight_cost: 5377.064,
    cost_impact: 11731.776000000002,
  },
  {
    running_risk_id: 10004,
    risk_date: 293 + 45231,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-792",
    quantity_at_risk: 22158,
    revenue_at_risk: 312427.8,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 19499.04,
    revenue_mitigated: 274936.46400000004,
    production_cost: 9359.539200000001,
    freight_cost: 4679.7696000000005,
    cost_impact: 14039.308800000003,
  },
  {
    running_risk_id: 10005,
    risk_date: 293 + 45232,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-792",
    quantity_at_risk: 9299,
    revenue_at_risk: 97639.5,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 8741.06,
    revenue_mitigated: 91781.13,
    production_cost: 4807.583,
    freight_cost: 2185.265,
    cost_impact: 6992.848,
  },
  {
    running_risk_id: 10006,
    risk_date: 293 + 45233,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 34015,
    revenue_at_risk: 431990.5,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 30953.65,
    revenue_mitigated: 393111.35500000004,
    production_cost: 20119.8725,
    freight_cost: 10214.7045,
    cost_impact: 30334.577,
  },
  {
    running_risk_id: 10007,
    risk_date: 293 + 45233,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 19547,
    revenue_at_risk: 218926.4,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 17396.83,
    revenue_mitigated: 194844.496,
    production_cost: 13917.464000000002,
    freight_cost: 4523.175800000001,
    cost_impact: 18440.639800000004,
  },
  {
    running_risk_id: 10008,
    risk_date: 293 + 45234,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 27603,
    revenue_at_risk: 460970.1,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 22634.46,
    revenue_mitigated: 377995.482,
    production_cost: 10411.8516,
    freight_cost: 5432.2704,
    cost_impact: 15844.122,
  },
  {
    running_risk_id: 10009,
    risk_date: 293 + 45236,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 17560,
    revenue_at_risk: 280960,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 17384.4,
    revenue_mitigated: 278150.4,
    production_cost: 12516.768,
    freight_cost: 6258.384,
    cost_impact: 18775.152000000002,
  },
  {
    running_risk_id: 10010,
    risk_date: 293 + 45236,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 39026,
    revenue_at_risk: 507338,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 37464.96,
    revenue_mitigated: 487044.48,
    production_cost: 25476.172799999997,
    freight_cost: 11614.1376,
    cost_impact: 37090.310399999995,
  },
  {
    running_risk_id: 10011,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 31394,
    revenue_at_risk: 467770.6,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 27940.66,
    revenue_mitigated: 416315.834,
    production_cost: 14529.1432,
    freight_cost: 10617.4508,
    cost_impact: 25146.594,
  },
  {
    running_risk_id: 10012,
    risk_date: 293 + 45241,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 5789,
    revenue_at_risk: 71204.7,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 4804.87,
    revenue_mitigated: 59099.901,
    production_cost: 2114.1428,
    freight_cost: 1201.2175,
    cost_impact: 3315.3603000000003,
  },
  {
    running_risk_id: 10013,
    risk_date: 293 + 45244,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 28977,
    revenue_at_risk: 414371.1,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 23181.6,
    revenue_mitigated: 331496.87999999995,
    production_cost: 16922.568,
    freight_cost: 8809.008,
    cost_impact: 25731.576,
  },
  {
    running_risk_id: 10014,
    risk_date: 293 + 45244,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 46029,
    revenue_at_risk: 616788.6,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 46029,
    revenue_mitigated: 616788.6,
    production_cost: 27157.11,
    freight_cost: 18411.6,
    cost_impact: 45568.71,
  },
  {
    running_risk_id: 10015,
    risk_date: 293 + 45246,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 36368,
    revenue_at_risk: 530972.8,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 33094.88,
    revenue_mitigated: 483185.248,
    production_cost: 24490.211199999998,
    freight_cost: 10921.310399999998,
    cost_impact: 35411.52159999999,
  },
  {
    running_risk_id: 10016,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 20043,
    revenue_at_risk: 316679.4,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 18840.42,
    revenue_mitigated: 297678.636,
    production_cost: 10173.826799999999,
    freight_cost: 7347.763799999999,
    cost_impact: 17521.590599999996,
  },
  {
    running_risk_id: 10017,
    risk_date: 293 + 45250,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 14215,
    revenue_at_risk: 180530.5,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 11798.45,
    revenue_mitigated: 149840.315,
    production_cost: 8848.8375,
    freight_cost: 3657.5195000000003,
    cost_impact: 12506.357,
  },
  {
    running_risk_id: 10018,
    risk_date: 293 + 45251,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 8976,
    revenue_at_risk: 120278.4,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 7719.36,
    revenue_mitigated: 103439.42399999998,
    production_cost: 4786.0032,
    freight_cost: 3319.3248,
    cost_impact: 8105.3279999999995,
  },
  {
    running_risk_id: 10019,
    risk_date: 293 + 45256,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 21120,
    revenue_at_risk: 259776,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 18585.6,
    revenue_mitigated: 228602.87999999998,
    production_cost: 9850.367999999999,
    freight_cost: 6504.96,
    cost_impact: 16355.327999999998,
  },
  {
    running_risk_id: 10020,
    risk_date: 293 + 45260,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 30953,
    revenue_at_risk: 371436,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 30643.47,
    revenue_mitigated: 367721.64,
    production_cost: 21143.994300000002,
    freight_cost: 13176.6921,
    cost_impact: 34320.686400000006,
  },
  {
    running_risk_id: 10021,
    risk_date: 293 + 45233,
    risk_status: "Rejected",
    rejection_code: "Limited raw material inventory",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-995",
    quantity_at_risk: 29064,
    revenue_at_risk: 482462.4,
    action_type: "Expedite production",
    execution_stage: "production validation",
    quantity_mitigated: 25285.68,
    revenue_mitigated: 419742.28800000006,
    production_cost: 12895.6968,
    freight_cost: 9608.5584,
    cost_impact: 22504.2552,
  },
  {
    running_risk_id: 10022,
    risk_date: 293 + 45235,
    risk_status: "Rejected",
    rejection_code: "Production capacity constraint",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 28799,
    revenue_at_risk: 368627.2,
    action_type: "Expedite production",
    execution_stage: "production validation",
    quantity_mitigated: 25919.1,
    revenue_mitigated: 331764.48000000004,
    production_cost: 19439.325,
    freight_cost: 9330.876,
    cost_impact: 28770.201,
  },
  {
    running_risk_id: 10023,
    risk_date: 293 + 45237,
    risk_status: "Rejected",
    rejection_code: "Limited raw material inventory",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-995",
    quantity_at_risk: 45961,
    revenue_at_risk: 574512.5,
    action_type: "Expedite production",
    execution_stage: "production validation",
    quantity_mitigated: 44582.17,
    revenue_mitigated: 557277.125,
    production_cost: 24520.1935,
    freight_cost: 18724.5114,
    cost_impact: 43244.7049,
  },
  {
    running_risk_id: 10024,
    risk_date: 293 + 45237,
    risk_status: "Rejected",
    rejection_code: "Production capacity constraint",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 10222,
    revenue_at_risk: 124708.4,
    action_type: "Expedite production",
    execution_stage: "production validation",
    quantity_mitigated: 10017.56,
    revenue_mitigated: 122214.23199999999,
    production_cost: 8314.5748,
    freight_cost: 4708.253199999999,
    cost_impact: 13022.828,
  },
  {
    running_risk_id: 10025,
    risk_date: 293 + 45242,
    risk_status: "Rejected",
    rejection_code: "Limited raw material inventory",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 32185,
    revenue_at_risk: 360472,
    action_type: "Expedite production",
    execution_stage: "production validation",
    quantity_mitigated: 26391.7,
    revenue_mitigated: 295587.04,
    production_cost: 21905.111,
    freight_cost: 11348.431,
    cost_impact: 33253.542,
  },
  {
    running_risk_id: 10026,
    risk_date: 293 + 45244,
    risk_status: "Rejected",
    rejection_code: "Limited raw material inventory",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 34976,
    revenue_at_risk: 552620.8,
    action_type: "Expedite production",
    execution_stage: "production validation",
    quantity_mitigated: 31828.16,
    revenue_mitigated: 502884.928,
    production_cost: 16232.361599999998,
    freight_cost: 10185.0112,
    cost_impact: 26417.372799999997,
  },
  {
    running_risk_id: 10027,
    risk_date: 293 + 45246,
    risk_status: "Rejected",
    rejection_code: "Freight cost budget constraint",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 30531,
    revenue_at_risk: 354159.6,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 28393.83,
    revenue_mitigated: 329368.428,
    production_cost: 17036.298,
    freight_cost: 11073.593700000001,
    cost_impact: 28109.8917,
  },
  {
    running_risk_id: 10028,
    risk_date: 293 + 45250,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 29543,
    revenue_at_risk: 372241.8,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 24520.69,
    revenue_mitigated: 308960.69399999996,
    production_cost: 11769.931199999999,
    freight_cost: 8582.241499999998,
    cost_impact: 20352.172699999996,
  },
  {
    running_risk_id: 10029,
    risk_date: 293 + 45251,
    risk_status: "Rejected",
    rejection_code: "Freight cost budget constraint",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-995",
    quantity_at_risk: 48137,
    revenue_at_risk: 683545.4,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 47655.63,
    revenue_mitigated: 676709.946,
    production_cost: 33835.4973,
    freight_cost: 21445.0335,
    cost_impact: 55280.53080000001,
  },
  {
    running_risk_id: 10030,
    risk_date: 293 + 45255,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 25549,
    revenue_at_risk: 275929.2,
    action_type: "Expedite production",
    execution_stage: "Freight",
    quantity_mitigated: 22738.61,
    revenue_mitigated: 245576.988,
    production_cost: 18190.888,
    freight_cost: 8640.6718,
    cost_impact: 26831.5598,
  },
  {
    running_risk_id: 10031,
    risk_date: 293 + 45266,
    risk_status: "Open",
    risk_location: 101,
    risk_item: "SKU-346",
    quantity_at_risk: 42781,
    revenue_at_risk: 628880.7,
    action_type: "Reallocate demand to markets",
    quantity_mitigated: 42781,
    revenue_mitigated: 628880.7,
    cost_impact: 0,
  },
  {
    running_risk_id: 10032,
    risk_date: 293 + 45266,
    risk_status: "In progress",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 19566,
    revenue_at_risk: 217182.6,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 17609.4,
    revenue_mitigated: 195464.34000000003,
    cost_impact: 0,
  },
  {
    running_risk_id: 10033,
    risk_date: 293 + 45233,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 47400,
    revenue_at_risk: 805800,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 46452,
    revenue_mitigated: 789684,
    cost_impact: 0,
  },
  {
    running_risk_id: 10034,
    risk_date: 293 + 45234,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 19885,
    revenue_at_risk: 262482,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 19686.15,
    revenue_mitigated: 259857.18000000002,
    cost_impact: 0,
  },
  {
    running_risk_id: 10035,
    risk_date: 293 + 45237,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 33486,
    revenue_at_risk: 415226.4,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 26788.8,
    revenue_mitigated: 332181.12,
    cost_impact: 0,
  },
  {
    running_risk_id: 10036,
    risk_date: 293 + 45238,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 22092,
    revenue_at_risk: 329170.8,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 18557.28,
    revenue_mitigated: 276503.472,
    cost_impact: 0,
  },
  {
    running_risk_id: 10037,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 21043,
    revenue_at_risk: 305123.5,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 18938.7,
    revenue_mitigated: 274611.14999999997,
    cost_impact: 0,
  },
  {
    running_risk_id: 10038,
    risk_date: 293 + 45241,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 43805,
    revenue_at_risk: 503757.5,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 39862.55,
    revenue_mitigated: 458419.325,
    cost_impact: 0,
  },
  {
    running_risk_id: 10039,
    risk_date: 293 + 45244,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 7332,
    revenue_at_risk: 109246.8,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 6525.48,
    revenue_mitigated: 97229.652,
    cost_impact: 0,
  },
  {
    running_risk_id: 10040,
    risk_date: 293 + 45244,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 134,
    risk_item: "SKU-792",
    quantity_at_risk: 47406,
    revenue_at_risk: 606796.8,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 37924.8,
    revenue_mitigated: 485437.44000000006,
    cost_impact: 0,
  },
  {
    running_risk_id: 10041,
    risk_date: 293 + 45246,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 40562,
    revenue_at_risk: 713891.2,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 36505.8,
    revenue_mitigated: 642502.08,
    cost_impact: 0,
  },
  {
    running_risk_id: 10042,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 30272,
    revenue_at_risk: 448025.6,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 28152.96,
    revenue_mitigated: 416663.80799999996,
    cost_impact: 0,
  },
  {
    running_risk_id: 10043,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 9883,
    revenue_at_risk: 138362,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 7906.4,
    revenue_mitigated: 110689.59999999999,
    cost_impact: 0,
  },
  {
    running_risk_id: 10044,
    risk_date: 293 + 45249,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 38268,
    revenue_at_risk: 631422,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 36354.6,
    revenue_mitigated: 599850.9,
    cost_impact: 0,
  },
  {
    running_risk_id: 10045,
    risk_date: 293 + 45253,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 43381,
    revenue_at_risk: 537924.4,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 38609.09,
    revenue_mitigated: 478752.71599999996,
    cost_impact: 0,
  },
  {
    running_risk_id: 10046,
    risk_date: 293 + 45254,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 45674,
    revenue_at_risk: 735351.4,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 40193.12,
    revenue_mitigated: 647109.2320000001,
    cost_impact: 0,
  },
  {
    running_risk_id: 10047,
    risk_date: 293 + 45257,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 22596,
    revenue_at_risk: 282450,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 22144.08,
    revenue_mitigated: 276801.00000000006,
    cost_impact: 0,
  },
  {
    running_risk_id: 10048,
    risk_date: 293 + 45260,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 27480,
    revenue_at_risk: 390216,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 26106,
    revenue_mitigated: 370705.2,
    cost_impact: 0,
  },
  {
    running_risk_id: 10049,
    risk_date: 293 + 45235,
    risk_status: "Rejected",
    rejection_code: "Demand change",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-346",
    quantity_at_risk: 31491,
    revenue_at_risk: 488110.5,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 29601.54,
    revenue_mitigated: 458823.87,
    cost_impact: 0,
  },
  {
    running_risk_id: 10050,
    risk_date: 293 + 45239,
    risk_status: "Rejected",
    rejection_code: "Customer emergencies",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 41908,
    revenue_at_risk: 578330.4,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 35202.72,
    revenue_mitigated: 485797.536,
    cost_impact: 0,
  },
  {
    running_risk_id: 10051,
    risk_date: 293 + 45246,
    risk_status: "Rejected",
    rejection_code: "Impact on revenue too high",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-346",
    quantity_at_risk: 15410,
    revenue_at_risk: 251183,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 13252.6,
    revenue_mitigated: 216017.38,
    cost_impact: 0,
  },
  {
    running_risk_id: 10052,
    risk_date: 293 + 45248,
    risk_status: "Rejected",
    rejection_code: "Impact on revenue too high",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 22054,
    revenue_at_risk: 377123.4,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 19186.98,
    revenue_mitigated: 328097.358,
    cost_impact: 0,
  },
  {
    running_risk_id: 10053,
    risk_date: 293 + 45251,
    risk_status: "Rejected",
    rejection_code: "Impact on revenue too high",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-995",
    quantity_at_risk: 29687,
    revenue_at_risk: 368118.8,
    action_type: "Reallocate demand to markets",
    execution_stage: "regional sales validation",
    quantity_mitigated: 28796.39,
    revenue_mitigated: 357075.236,
    cost_impact: 0,
  },
  {
    running_risk_id: 10054,
    risk_date: 293 + 45270,
    risk_status: "Open",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 22870,
    revenue_at_risk: 317893,
    action_type: "Expedite deployment",
    quantity_mitigated: 21269.1,
    revenue_mitigated: 295640.49,
    freight_cost: 42112.818,
    cost_impact: 42112.818,
  },
  {
    running_risk_id: 10055,
    risk_date: 293 + 45269,
    risk_status: "In progress",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 8341,
    revenue_at_risk: 138460.6,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 8007.36,
    revenue_mitigated: 132922.176,
    freight_cost: 15133.9104,
    cost_impact: 15133.9104,
  },
  {
    running_risk_id: 10056,
    risk_date: 293 + 45231,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-995",
    quantity_at_risk: 29002,
    revenue_at_risk: 449531,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 24071.66,
    revenue_mitigated: 373110.73,
    freight_cost: 51272.635800000004,
    cost_impact: 51272.635800000004,
  },
  {
    running_risk_id: 10057,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 20923,
    revenue_at_risk: 263629.8,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 19039.93,
    revenue_mitigated: 239903.118,
    freight_cost: 40555.0509,
    cost_impact: 40555.0509,
  },
  {
    running_risk_id: 10058,
    risk_date: 293 + 45237,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 25673,
    revenue_at_risk: 439008.3,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 25159.54,
    revenue_mitigated: 430228.13399999996,
    freight_cost: 39248.8824,
    cost_impact: 39248.8824,
  },
  {
    running_risk_id: 10059,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 29934,
    revenue_at_risk: 514864.8,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 25443.9,
    revenue_mitigated: 437635.08,
    freight_cost: 47580.093,
    cost_impact: 47580.093,
  },
  {
    running_risk_id: 10060,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-995",
    quantity_at_risk: 30448,
    revenue_at_risk: 459764.8,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 26185.28,
    revenue_mitigated: 395397.72799999994,
    freight_cost: 39277.92,
    cost_impact: 39277.92,
  },
  {
    running_risk_id: 10061,
    risk_date: 293 + 45243,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-792",
    quantity_at_risk: 9370,
    revenue_at_risk: 166786,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 8058.2,
    revenue_mitigated: 143435.96,
    freight_cost: 14504.76,
    cost_impact: 14504.76,
  },
  {
    running_risk_id: 10062,
    risk_date: 293 + 45248,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 6892,
    revenue_at_risk: 106826,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 5927.12,
    revenue_mitigated: 91870.36,
    freight_cost: 10846.6296,
    cost_impact: 10846.6296,
  },
  {
    running_risk_id: 10063,
    risk_date: 293 + 45251,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 16394,
    revenue_at_risk: 288534.4,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 13934.9,
    revenue_mitigated: 245254.24000000002,
    freight_cost: 25779.565,
    cost_impact: 25779.565,
  },
  {
    running_risk_id: 10064,
    risk_date: 293 + 45253,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-792",
    quantity_at_risk: 49271,
    revenue_at_risk: 832679.9,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 45329.32,
    revenue_mitigated: 766065.508,
    freight_cost: 82952.6556,
    cost_impact: 82952.6556,
  },
  {
    running_risk_id: 10065,
    risk_date: 293 + 45256,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 31871,
    revenue_at_risk: 490813.4,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 27409.06,
    revenue_mitigated: 422099.52400000003,
    freight_cost: 57833.1166,
    cost_impact: 57833.1166,
  },
  {
    running_risk_id: 10066,
    risk_date: 293 + 45259,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 27413,
    revenue_at_risk: 304284.3,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 23849.31,
    revenue_mitigated: 264727.341,
    freight_cost: 33866.0202,
    cost_impact: 33866.0202,
  },
  {
    running_risk_id: 10067,
    risk_date: 293 + 45234,
    risk_status: "Rejected",
    rejection_code: "No freight forwarder capacity",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 15722,
    revenue_at_risk: 202813.8,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 13835.36,
    revenue_mitigated: 178476.144,
    freight_cost: 26148.8304,
    cost_impact: 26148.8304,
  },
  {
    running_risk_id: 10068,
    risk_date: 293 + 45237,
    risk_status: "Rejected",
    rejection_code: "No freight forwarder capacity",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 10790,
    revenue_at_risk: 155376,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 10466.3,
    revenue_mitigated: 150714.72,
    freight_cost: 15385.461,
    cost_impact: 15385.461,
  },
  {
    running_risk_id: 10069,
    risk_date: 293 + 45241,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 20604,
    revenue_at_risk: 243127.2,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 18749.64,
    revenue_mitigated: 221245.752,
    freight_cost: 37686.776399999995,
    cost_impact: 37686.776399999995,
  },
  {
    running_risk_id: 10070,
    risk_date: 293 + 45243,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 11001,
    revenue_at_risk: 146313.3,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 9130.83,
    revenue_mitigated: 121440.03899999998,
    freight_cost: 18718.2015,
    cost_impact: 18718.2015,
  },
  {
    running_risk_id: 10071,
    risk_date: 293 + 45245,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 16172,
    revenue_at_risk: 229642.4,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 13261.04,
    revenue_mitigated: 188306.768,
    freight_cost: 18963.287200000002,
    cost_impact: 18963.287200000002,
  },
  {
    running_risk_id: 10072,
    risk_date: 293 + 45248,
    risk_status: "Rejected",
    rejection_code: "No freight forwarder capacity",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 16043,
    revenue_at_risk: 232623.5,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 13155.26,
    revenue_mitigated: 190751.27000000002,
    freight_cost: 17759.601000000002,
    cost_impact: 17759.601000000002,
  },
  {
    running_risk_id: 10073,
    risk_date: 293 + 45249,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-792",
    quantity_at_risk: 48946,
    revenue_at_risk: 807609,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 46988.16,
    revenue_mitigated: 775304.64,
    freight_cost: 65313.542400000006,
    cost_impact: 65313.542400000006,
  },
  {
    running_risk_id: 10074,
    risk_date: 293 + 45252,
    risk_status: "Rejected",
    rejection_code: "Freight cost budget constraint",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-792",
    quantity_at_risk: 41531,
    revenue_at_risk: 502525.1,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 38208.52,
    revenue_mitigated: 462323.09199999995,
    freight_cost: 77181.2104,
    cost_impact: 77181.2104,
  },
  {
    running_risk_id: 10075,
    risk_date: 293 + 45254,
    risk_status: "Rejected",
    rejection_code: "Freight cost budget constraint",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 42943,
    revenue_at_risk: 717148.1,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 37360.41,
    revenue_mitigated: 623918.8470000001,
    freight_cost: 68369.5503,
    cost_impact: 68369.5503,
  },
  {
    running_risk_id: 10076,
    risk_date: 293 + 45256,
    risk_status: "Rejected",
    rejection_code: "Freight forwarder calendar",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 28056,
    revenue_at_risk: 294588,
    action_type: "Expedite deployment",
    execution_stage: "Freight",
    quantity_mitigated: 27494.88,
    revenue_mitigated: 288696.24,
    freight_cost: 43991.808,
    cost_impact: 43991.808,
  },
  {
    running_risk_id: 10077,
    risk_date: 293 + 45270,
    risk_status: "Open",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 13722,
    revenue_at_risk: 237390.6,
    action_type: "No action",
    quantity_mitigated: 34514,
    revenue_mitigated: 391913.53350758535,
  },
  {
    running_risk_id: 10078,
    risk_date: 293 + 45231,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 46937,
    revenue_at_risk: 727523.5,
    action_type: "No action",
    quantity_mitigated: 10131,
    revenue_mitigated: 537678.2832248181,
  },
  {
    running_risk_id: 10079,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 46067,
    revenue_at_risk: 810779.2,
    action_type: "No action",
    quantity_mitigated: 2821,
    revenue_mitigated: 550142.5282555283,
  },
  {
    running_risk_id: 10080,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 44150,
    revenue_at_risk: 671080,
    action_type: "No action",
    quantity_mitigated: 32250,
    revenue_mitigated: 283888.81096383993,
  },
  {
    running_risk_id: 10081,
    risk_date: 293 + 45241,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 25250,
    revenue_at_risk: 292900,
    action_type: "No action",
    quantity_mitigated: 11959,
    revenue_mitigated: 307299.474319413,
  },
  {
    running_risk_id: 10082,
    risk_date: 293 + 45245,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 29345,
    revenue_at_risk: 308122.5,
    action_type: "No action",
    quantity_mitigated: 3260,
    revenue_mitigated: 76575.69452796152,
  },
  {
    running_risk_id: 10083,
    risk_date: 293 + 45246,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 36116,
    revenue_at_risk: 603137.2,
    action_type: "No action",
    quantity_mitigated: 8746,
    revenue_mitigated: 497955.5807799443,
  },
  {
    running_risk_id: 10084,
    risk_date: 293 + 45249,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 24713,
    revenue_at_risk: 274314.3,
    action_type: "No action",
    quantity_mitigated: 33507,
    revenue_mitigated: 453047.0428219894,
  },
  {
    running_risk_id: 10085,
    risk_date: 293 + 45253,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-792",
    quantity_at_risk: 6841,
    revenue_at_risk: 86880.7,
    action_type: "No action",
    quantity_mitigated: 19876,
    revenue_mitigated: 44134.69922593337,
  },
  {
    running_risk_id: 10086,
    risk_date: 293 + 45236,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 10492,
    revenue_at_risk: 124854.8,
    action_type: "No action",
    quantity_mitigated: 41206,
    revenue_mitigated: 28866.64,
  },
  {
    running_risk_id: 10087,
    risk_date: 293 + 45240,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 42457,
    revenue_at_risk: 450044.2,
    action_type: "No action",
    quantity_mitigated: 7702,
    revenue_mitigated: 333718.1315051418,
  },
  {
    running_risk_id: 10088,
    risk_date: 293 + 45248,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 12064,
    revenue_at_risk: 191817.6,
    action_type: "No action",
    quantity_mitigated: 3850,
    revenue_mitigated: 126405.79960513327,
  },
];
