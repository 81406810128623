/** @format */
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MainContext } from "../../../../utils/context";
import { SharedContext } from "../context/sharedContext";
import Header from "./Header";
import Footer from "./Footer";
import { WORK_MODE } from "../../../../constants/workModes";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/OrganizationLogic";
import { isPermission } from "../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../constants/permissions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/store";
import OrganizationDetails from "./details/OrganizationDetails";

const AddUpdateOrganization: React.FC = () => {
  const { state } = useLocation();
  const { record, mode, fromExpiredLicense } = state;
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /**props */
  /**local vars */
  const { form, setWorkMode, setRecord, workMode } = useContext(SharedContext);
  /** shared logic */
  const { getActionParameters } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  /** global app context */
  const globalContext = useContext(MainContext);
  useEffect(() => {
    // these two vars need to be set in this parent component  that is wrapped in the shared context so it can be used in the children components
    setWorkMode(mode || WORK_MODE.CREATE);
    setRecord(record || {});
    if (!globalContext || !globalContext.context.orga) {
      return;
    }
  }, [mode]);
  useEffect(() => {
    if (
      isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) &&
      globalContext?.context?.orga?.id === record?.id
    ) {
      getActionParameters(workMode, record);
    }
  }, [workMode]);
  if (!globalContext) {
    return <></>;
  }

  return (
    <>
      <div
        style={{
          top: 30,
          background: "transparent",
          display: "flex",
          flexDirection: "column",
          paddingBottom: 20,
          alignItems: "left",
        }}
      >
        {/**page header component */}
        <Header fromExpiredLicense={fromExpiredLicense} />

        {/**Organization details  */}
        {<OrganizationDetails />}
      </div>
      {/* FooterComponent */}
      {workMode && workMode !== WORK_MODE.VIEW && (
        <Footer form={form} fromExpiredLicense={fromExpiredLicense} />
      )}
    </>
  );
};

export default AddUpdateOrganization;
