/** @format */
import { PlusOutlined } from "@ant-design/icons";
import type { ActionType } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { Button, Spin } from "antd";
import { useContext, useRef, useState } from "react";
import { WORK_MODE } from "../../../../../constants/workModes";
import { DefineTableColumns } from "../columnsDef/UserTable.def";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/userLogic";
import { SharedContext } from "../../context/sharedContext";
import { useTranslation } from "react-i18next";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";

const UserTable: React.FC = () => {
  const PageSizeUser = 10;
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /** users logic */
  const { goToUserConfiguration, getUsers, filterUsers, getEnums } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  /** local vars  */
  const [getLoading, setGetLoading] = useState(true);
  const [totalUser, setTotalUser] = useState(0);
  const { userList, setUserList } = useContext(SharedContext);
  const { t } = useTranslation();
  const userRef = useRef<ActionType>();
  /** columns def */
  let columns = DefineTableColumns(setGetLoading, setTotalUser);

  return (
    <Spin spinning={getLoading} size="large" tip="Loading...">
      <ProTable<any>
        columns={columns}
        actionRef={userRef}
        cardBordered
        dataSource={userList}
        editable={{
          type: "multiple",
        }}
        columnsState={{
          persistenceKey: "pro-table-singe-demos",
          persistenceType: "localStorage",
        }}
        rowKey="id"
        search={{
          span: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6, xxl: 6 },
          filterType: "query",
          searchText: t("SEARCH_TEXT_BUTTON"),
          resetText: t("RESET_TEXT_BUTTON"),
          labelWidth: "auto",
          className: "custom-search-form",
        }}
        toolBarRender={() => [
          isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) && (
            <Button
              id="11"
              type="primary"
              className="button-add-risk"
              icon={<PlusOutlined />}
              onClick={() => {
                goToUserConfiguration("", WORK_MODE.CREATE);
              }}
            >
              {t("ADD_BUTTON_TEXT")}
              {t("USER")}
            </Button>
          ),
        ]}
        options={{
          density: true,
          reload: true,
          setting: {
            draggable: true,
            showListItemOption: true,
            listsHeight: 400,
          },
        }}
        form={{
          syncToUrl: (values, type) => {
            if (type === "get") {
              return {
                ...values,
              };
            }
            return values;
          },
        }}
        request={(params = {}): any => {
          const {
            firstname,
            lastname,
            role,
            preferredLanguage,
            current,
            pageSize,
          } = params;
          filterUsers(
            {
              current,
              pageSize,
            },
            {
              firstname: { value: firstname, operator: "HAS" },
              lastname: { value: lastname, operator: "HAS" },
              role,
              preferredLanguage,
            },
            setGetLoading,
            setTotalUser
          );

          return;
        }}
        onReset={() => {
          filterUsers(
            {
              current: 1,
              pageSize: PageSizeUser,
            },
            undefined,
            setGetLoading,
            setTotalUser
          );
        }}
        pagination={{
          pageSize: PageSizeUser,
          total: totalUser,
        }}
        dateFormatter="string"
        headerTitle={t("USERS")}
      />
    </Spin>
  );
};
export default UserTable;
