import { ProColumns } from "@ant-design/pro-components";
import {
  EllipsisOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm, Segmented, Space, Tag } from "antd";
import { Dispatch, useContext, useEffect, useState } from "react";
import { SharedContext } from "../../context/SharedContext";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../../logic/runningShared.logic";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { MainContext } from "../../../../../utils/context";
import { Reason } from "../../../../../interfaces/reasons";
import { RisksItem } from "../../../../../interfaces/risks";
import { getCopilotId, isDate } from "../../../../../utils/functions";
import { STATUS } from "../../../../../constants/status";
import { useTranslation } from "react-i18next";
import { displayContextRisk } from "../../../../../utils/datatypesFunctions";
import DefineDynamicColumns from "../../menus/DefineDynamicColumns";
import { createKey, getTranslation } from "../../../../../utils/transaltion";
import { RUNNING_MENU } from "../../../../../constants/riskMenus";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";
import AnimatedButton from "../../../../common/StopAnimatedButton";
import moment from "moment";
import { DATA_NEEDED_TYPE } from "../../../../../constants/DataNeededTypes";

export const DefineTableColumns = ({
  copilotName,
  menu,
  currentRiskId,
  setLoadingAccept,
}: {
  copilotName: string;
  menu: string;
  currentRiskId: string;
  setSelectedRisk: Dispatch<any>;
  setLoadingAccept: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const globalContext = useContext(MainContext);
  const orgaFormatDate =
    globalContext?.context?.orga?.formatDate || "DD/MM/YYYY";
  const orgaFormatNumber =
    globalContext?.context?.orga?.formatNumber || "en-US";
  const orgaCurrency = globalContext?.context?.orga?.currency || "EUR";
  const { t } = useTranslation();
  /**local vars  */
  const [selectedValues, setSelectedValues] = useState<{
    [key: string]: string | number;
  }>({});
  const [loadingStop, setLoadingStop] = useState<any>(null);

  /** shared context */
  const {
    setCurrentActionExecutionRejectArguments,
    setOpenAddReasonCodeModal,
    setOpenDrawer,
    setCurrentAction,
    setTypeReject,
    setCurrentActionExecutionReasons,
    runningRisks,
    runningRisksFilter,
  } = useContext(SharedContext);
  const {
    handleReasonCodeModal,
    showDrawer,
    acceptActionOrExecution,
    filterCurrentActionreasons,
    extractPositionAttributes,
    stopRisk,
  } = useSharedContextFunctions() as SharedContextFunctions;
  /** reasons actions from redux */
  const reasonsActions: Reason[] = useSelector(
    (state: RootState) => state.reasonsActionsReducer
  );
  const reasonsRisk: Reason[] = useSelector(
    (state: RootState) => state.reasonsRisksReducer
  );
  /**reasonsexecutions from Redux */
  const reasonsExecutions: Reason[] = useSelector(
    (state: RootState) => state.reasonsExecutionsReducer
  );
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /** get predefined risks from redux */
  const predefinedRiskList = useSelector(
    (state: RootState) => state.predefinedRiskOrgaReducer
  );

  const getUniqueKeysWithTypes = (array: any) => {
    const uniqueKeys = new Map();

    array.forEach((item: any) => {
      if (item.context) {
        Object.entries(item.context).forEach(([key, value]) => {
          if (!uniqueKeys.has(key)) {
            let type = DATA_NEEDED_TYPE.STRING; // Default type

            // Check the type of the value
            const valueStr = String(value);

            if (!isNaN(Number(valueStr)) && valueStr !== "") {
              type = DATA_NEEDED_TYPE.NUMBER;
            } else if (isDate(valueStr)) {
              type = DATA_NEEDED_TYPE.DATE;
            }

            uniqueKeys.set(key, { name: key, type });
          }
        });
      }
    });

    return Array.from(uniqueKeys.values()); // Convert Map back to an array
  };
  const columnsList = getUniqueKeysWithTypes(runningRisksFilter);
  const ColumnDynamic = DefineDynamicColumns({ columnsList });
  /** useeffect */
  useEffect(() => {
    if (!globalContext || !globalContext.context.orga) {
      return;
    }
  }, [globalContext]);

  const columns: ProColumns<RisksItem>[] = [
    {
      title: t("DUE_DATE"),
      width: "10%",
      dataIndex: "due_date",
      valueType: "dateRange",
      hideInTable: true,
      search: {
        transform: (value) => {
          if (value) {
            const formattedStartDate = new Date(value ? value[0] : undefined);
            const formattedSEndDate = new Date(value ? value[1] : undefined);
            return {
              due_date: {
                value: [
                  formattedStartDate?.toISOString(),
                  formattedSEndDate?.toISOString(),
                ],
                operator: "range",
              },
            };
          } else {
            return null;
          }
        },
      },
      sorter: (a, b) => {
        const dateA = new Date(a.due_date);
        const dateB = new Date(b.due_date);
        return dateA.getTime() - dateB.getTime();
      },
      render: (text, record) => {
        return record.due_date
          ? moment(record?.due_date).format(orgaFormatDate || "DD/MM/YYYY")
          : "-";
      },
    },
    {
      title: t("NAME", { item: t("RISK") }),
      dataIndex: "name",
      valueType: "text",
      render: (text, record) => {
        return <span>{record?.risk_conf_name}</span>;
      },
      search: {
        transform: (value) => {
          return {
            "risk_conf.name": {
              value: value,
              operator: "HAS",
            },
          };
        },
      },
      tip: "",
      formItemProps: {
        rules: [
          {
            required: true,
            message: "",
          },
        ],
      },
    },
    {
      title: t("CONTEXT"),
      dataIndex: "context",
      valueType: "text",
      width: menu === RUNNING_MENU.RISKS_HISTORY ? "50%" : "40%",
      hideInTable: false,
      search: false,
      render: (text, record) => {
        const policyData = predefinedRiskList?.find(
          (obj: any) => obj.id === record.risk_model
        )?.policyData;
        return displayContextRisk(
          record,
          orgaFormatNumber,
          orgaFormatDate,
          orgaCurrency,
          policyData
        );
      },
    },
    {
      title: t("ACTIONS"),
      dataIndex: "action_conf.name",
      valueType: "text",
      hidden: menu === "RISKS_HISTORY",
      hideInTable: false,
      search: {
        transform: (value) => {
          return {
            "action_conf.name": {
              value: value,
              operator: "HAS",
            },
          };
        },
      },
      render: (text, record) => {
        return <span>{record?.current_action?.action_conf_name}</span>;
      },
    },
    {
      title: t("EXECUTION"),
      hidden: menu === "RISKS_HISTORY",
      dataIndex: "execution_conf.name",
      valueType: "text",
      search: {
        transform: (value) => {
          return {
            "execution_conf.name": {
              value: value,
              operator: "HAS",
            },
          };
        },
      },
      hideInTable: false,
      render: (text, record) => {
        const ExecName =
          record?.current_action?.current_execution?.execution_conf_name;
        return <span>{ExecName}</span>;
      },
    },
    {
      title: t("STOP_RUNNING"),
      dataIndex: "stop",
      key: "stop",
      valueType: "text",
      search: false,
      hidden: !isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN),
      hideInTable: false,
      render: (text, record) => {
        const reasonsName = reasonsRisk?.find(
          (obj: any) => obj.id === record?.reason?.id
        )?.name;

        return (
          <div className="custom-stop-column">
            {reasonsName !== "RISK_STOPPED" &&
            record?.status !== STATUS.COMPLETE ? (
              <Popconfirm
                placement="topLeft"
                key="delete"
                disabled={false}
                title={"Are you sure you want to stop this Risk"}
                onConfirm={async () => {
                  stopRisk(
                    record.id,
                    "risk_stopped",
                    copilotName,
                    menu,
                    setLoadingStop
                  );
                }}
                okText={t("YES")}
                cancelText={t("NO")}
              >
                <Space>
                  <AnimatedButton loading={loadingStop} buttonKey={record.id} />
                </Space>
              </Popconfirm>
            ) : record?.status === STATUS.COMPLETE &&
              reasonsName === "RISK_STOPPED" ? (
              <Tag color="red" title="Risk already stopped">
                Stopped
              </Tag>
            ) : record?.status === STATUS.COMPLETE && !record.reason ? (
              <Tag title="Complete" color="blue">
                Complete
              </Tag>
            ) : (
              <Tag title="Rejected" color="red">
                {getTranslation(reasonsName || "", "data")}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: t("REJECT") + "/" + t("ACCEPT"),
      valueType: "option",
      key: "option",
      align: "center",
      hidden: menu === "RISKS_HISTORY",
      render: (text, record) => {
        const currentExec =
          record?.current_action?.status === STATUS.WAIT_FOR_EXECUTION
            ? record?.current_action?.current_execution
            : null;
        return (
          <Segmented
            options={[
              {
                value: "reject",
                icon:
                  selectedValues[record.id] === "reject" ? (
                    <CloseCircleOutlined style={{ color: "red" }} />
                  ) : (
                    <CloseCircleOutlined />
                  ),
              },

              {
                value: "none",
                icon: null,
              },

              {
                value: "Accept",
                icon:
                  selectedValues[record.id] === "Accept" ? (
                    <CheckCircleOutlined style={{ color: "green" }} />
                  ) : (
                    <CheckCircleOutlined />
                  ),
              },
            ]}
            value="none"
            onChange={(newValue: any) => {
              setCurrentActionExecutionReasons([]);
              // Update the selected value for this row
              if (newValue === "reject") {
                setSelectedValues({
                  ...selectedValues,
                  [record.id]: newValue,
                });
                let currentActExec: any = null;
                if (currentExec) {
                  /**reject execution */
                  setTypeReject("Execution");
                  filterCurrentActionreasons(
                    "EXECUTION",
                    reasonsExecutions,
                    record?.current_action?.current_execution
                      ?.execution_conf_src_id
                  );

                  currentActExec = currentExec;
                } else {
                  /**reject action */
                  setTypeReject("Action");
                  filterCurrentActionreasons(
                    "ACTION",
                    reasonsActions,
                    record?.current_action?.action_conf_src_id
                  );
                  currentActExec = record?.current_action;
                }

                setCurrentActionExecutionRejectArguments({
                  type: "Action",
                  id: currentActExec?.id,
                  contexts: { id: currentActExec?.contexts?.at(0)?.id },
                });
                handleReasonCodeModal(setOpenAddReasonCodeModal);
                setSelectedValues({ ...selectedValues, [record.id]: "none" });
              } else {
                if (newValue === "Accept") {
                  if (currentExec) {
                    acceptActionOrExecution(
                      copilotName,
                      currentRiskId,
                      menu,
                      currentExec?.id,
                      currentExec?.contexts?.at(0)?.id,
                      "Execution",
                      setLoadingAccept
                    );
                  } else {
                    acceptActionOrExecution(
                      copilotName,
                      currentRiskId,
                      menu,
                      record?.current_action?.id,
                      record?.current_action?.contexts?.at(0)?.id,
                      "Action",
                      setLoadingAccept
                    );
                  }
                  setSelectedValues({
                    ...selectedValues,
                    [record.id]: "none",
                  });
                }
              }
            }}
          />
        );
      },
    },
    {
      title: t("DETAILS", { item: "" }),
      hidden: menu === "RISKS_HISTORY",
      dataIndex: "details",
      valueType: "text",
      hideInSearch: true,
      hideInTable: false,
      render: (text, record) => {
        return (
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                setCurrentAction(null);
                // getCurrentActionFormatUI(record?.id);
                showDrawer(setOpenDrawer);
              }}
              type="dashed"
              icon={<EllipsisOutlined />}
              style={{
                height: "28px",
                color: "#aba7a7",
                border: "1px solid #aba7a7",
              }}
            />
          </span>
        );
      },
    },
  ];
  return columns.concat(ColumnDynamic);
};
