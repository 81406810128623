import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";

const resource = { name: "execconfs", isPublic: false };

class ExecutionsService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(resource, context, setContext, path);
  }

  async getExecutionByTd(args: any): Promise<any> {
    const data = await this.get(
      `/organizations/${this.context.orga.id}/execconfs/` + args
    );

    return data;
  }
}

export default ExecutionsService;
