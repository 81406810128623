import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { PreDefinedActionItem } from "../../interfaces/pre-defined-action";

const initialState: Array<PreDefinedActionItem> = [];

export const predefinedActionOrgaSlice = createSlice({
  name: "predefined_Actions_Orga",
  initialState,
  reducers: {
    addPredefinedActionOrga: (
      state,
      action: PayloadAction<PreDefinedActionItem>
    ) => {
      state.push(action.payload);
    },
    setPredefinedActionsOrga: (
      state,
      action: PayloadAction<Array<PreDefinedActionItem>>
    ) => {
      return action.payload;
    },
    updatePreDefinedActionOrga: (
      state,
      action: PayloadAction<PreDefinedActionItem>
    ) => {
      const { id } = action.payload;
      const index = state.findIndex((preDefined) => preDefined.id === id);

      if (index !== -1) {
        const updatedState = state.map((item, i) =>
          i === index ? { ...item, ...action.payload } : item
        );

        // console.warn("Updated State:", updatedState);
        return updatedState;
      }

      return state;
    },
  },
});
export const {
  addPredefinedActionOrga,
  setPredefinedActionsOrga,
  updatePreDefinedActionOrga,
} = predefinedActionOrgaSlice.actions;

export const PredefinedActionOrgaSelector = (state: RootState) =>
  state.predefinedActionOrgaReducer;

export default predefinedActionOrgaSlice.reducer;
